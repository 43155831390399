import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DialogBox from '../../Onboarding/DialogBox';

// Material UI
import { withStyles } from '@material-ui/core/styles';

import * as actions from '../../../store/actions/index';

const styles = () => ({
    background: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 103,
        display: 'flex',
        alignItems: 'center',
    },
    backgroundTrans: {
        position: 'absolute',
        zIndex: 90,
        display: 'flex',
        alignItems: 'center',
    }
});

class NotSyncedModal extends Component {

    handleBuyClick = () => {
        this.props.onMainMenuOpen(true);
        this.props.onAccountMenuOpen(true);
    }
    render() {
        const { classes } = this.props;
        // Header text
        let header = "Din konto afventer adgang";
        // Text
        let intro = "Din konto tilknyttet SLS A/S afventer adgang. Se status nedenfor.";
        let subText = "✅ Abonnement tilknyttet konto";
        let syncText = "✅ Skov linket til SLS A/S";
        // Buttons
        let btns = [];
        if (!this.props.hasSubscription) {
            subText = "🛑 Abonnement tilknyttet konto";
            btns = [{ label: "Køb Abonnement", clicked: this.handleBuyClick, color: 'primary' }];
        }
        if (!this.props.forestSynced) {
            syncText = "🛑 Skov linket til SLS A/S"
        }

        // Full text
        const fullText = intro + '\n\n' + subText + '\n' + syncText;

        if (this.props.show) {
            return (
                <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '35%' }}>
                    <DialogBox
                        header={header}
                        text={fullText}
                        mobile={this.props.mobile}
                        buttons={btns}
                    />
                </div>
            )
        } else {
            return null;
        }
    }
}

NotSyncedModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {

    };
}

const mapDispatchToProps = dispatch => {
    return {
        onMainMenuOpen: (state) => dispatch(actions.mainMenuOpen(state)),
        onAccountMenuOpen: (state) => dispatch(actions.accountMenuOpen(state)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotSyncedModal));
