import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

// Components
import ButtonWithSpinner from '../../components/UI/Buttons/ButtonWithSpinner';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: '0px 0px',
        width: '100%'
    },
    formHelperText: {
        marginBottom: 22
    },
    divider: {
        backgroundColor: theme.palette.divider,
        height: 1,
        flexGrow: 1,
    },
    link: {
        fontSize: 16,
    },
}));

export default function ForgotPasswordWindow(props) {
    const classes = useStyles();

    return (
        <div style={{ backgroundColor: 'white', padding: 28, borderRadius: 21, width: '100%' }}>
            <div className={classes.root}>
                <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                >
                    <TextField
                        required
                        id="forgotPasswordEmail"
                        label="E-mail"
                        type="email"
                        onChange={props.handleTextInput}
                        value={props.emailValue}
                        variant="outlined"
                        style={{ marginBottom: 22 }}
                    />

                    <ButtonWithSpinner
                        onClick={props.handleNewPassword}
                        text='Anmod om ny adgangskode'
                        color='primary'
                        variant='contained'
                        fullWidth={true}
                        disabled={props.buttonDisabled}
                    />

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 22 }}>
                        <Link href="/login" underline='always' className={classes.link}>
                            Klik her, for at vende tilbage til loginsiden
                        </Link>
                    </div>
                </FormControl>
            </div>
        </div>
    );
}