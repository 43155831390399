import React from "react";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMapRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextIcon from '@material-ui/icons/NavigateNextRounded';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        overflow: 'hidden',
        backgroundColor: 'transparent',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        fontSize: 11
    },
    titleBar: {
        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    iconButton: {
        margin: theme.spacing.unit,
        color: 'white'
    },
});

class NewImageCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            index: this.props.index,
            open: this.props.open,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
        };

        this.handleResize = this.handleResize.bind(this)
    }

    closeCarousel = () => {
        this.props.viewable();
    }

    handleResize() {
        this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const { classes, images } = this.props;
        const { windowHeight, windowWidth, index } = this.state;

        let N = images.length;

        let tileWidth = (((110 + 3) * (N - 1)) + 110) / N;
        let padding = 20 + 20;
        let gridlist = 110 * N + 3 * (N - 1);

        let leftover = windowWidth - padding - gridlist;
        let cols = (windowWidth - padding) / tileWidth;

        if (leftover > 0) {
            cols = (windowWidth - padding - leftover) / tileWidth;
        }

        return (
            <Dialog fullScreen open={this.props.open} style={{ padding: 20 }} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }} >
                <div className={classes.root}>

                    <IconButton aria-label="close" size="medium" onClick={this.closeCarousel} style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)', color: 'white', position: 'absolute', right: 0, top: 0 }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>

                    <IconButton aria-label="forward" size="medium" onClick={() => this.setState({ index: index === N - 1 ? N - 1 : index + 1 })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)', color: 'white', position: 'absolute', right: 0, top: windowHeight / 2 }}>
                        <NavigateNextIcon fontSize="small" />
                    </IconButton>

                    <IconButton aria-label="back" size="medium" onClick={() => this.setState({ index: index === 0 ? 0 : index - 1 })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)', color: 'white', position: 'absolute', left: 0, top: windowHeight / 2 }}>
                        <NavigateBeforeIcon fontSize="small" />
                    </IconButton>

                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 3, width: windowWidth - padding, height: windowHeight - 20 - 3 - 110 - 20 }}>
                        <img
                            style={{ objectFit: 'contain' }}
                            src={images[index] instanceof File ? URL.createObjectURL(images[index]) : images[index]}
                            alt={"billede"}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', backgroundColor: 'transparent' }}>
                        <GridList spacing={3} style={{ width: gridlist }} className={classes.gridList} cols={cols} cellHeight={110}>
                            {images.map((tile, i) => (
                                <GridListTile key={i}>
                                    <img
                                        src={tile instanceof File ? URL.createObjectURL(tile) : tile}
                                        alt={"billede"}
                                        key={i}
                                    />

                                    <GridListTileBar
                                        title={index === i ? 'Vises' : ''}
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                        actionIcon={
                                            <IconButton
                                                className={classes.iconButton}
                                                aria-label="Zoom-out"
                                                onClick={() => this.setState({ index: i })}
                                            >
                                                <ZoomOutMapIcon />
                                            </IconButton>
                                        }
                                    />
                                </GridListTile>
                            ))}
                        </GridList>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(NewImageCarousel);