
import env from './buildConfig';

// ... Dev ...
let configFacebook = {
    pixelID: 260396731605712
}

// Same as dev
if (env.stage === "production") {
}

export default configFacebook;