import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    filterState: null,
    filterCellIds: null,
    filterPointIds: null,
    showFilterInMap: false,
    estimationSteps: 0,
    futureThinning: true,
};

const setFilterState = ( state, action ) => {
    return updateObject( state, { filterState: action.filterState } );
};

const setFilterCellIds = ( state, action ) => {
    return updateObject( state, { filterCellIds: action.filterCellIds } );
};

const setFilterPointIds = ( state, action ) => {
    return updateObject( state, { filterPointIds: action.filterPointIds } );
};

const setShowFilterInMap = ( state, action ) => {
    return updateObject( state, { showFilterInMap: action.showFilterInMap } );
};

const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case actionTypes.SET_INVENTORY_FILTER_STATE: return setFilterState(state, action);
        case actionTypes.SET_INVENTORY_FILTER_CELL_IDS: return setFilterCellIds(state, action);
        case actionTypes.SET_INVENTORY_FILTER_POINT_IDS: return setFilterPointIds(state, action);
        case actionTypes.SET_SHOW_FILTER_IN_MAP: return setShowFilterInMap(state, action);
        
        default: return state;
    }
};

export default reducer;