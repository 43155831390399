const tasks = require('./tasks.json');
const taskGroups = require('./taskGroups.json');


// --- TASK FUNCTIONALITY --- //
export const getTaskList = () => {
    // Input checking

    // Return task list
    return Object.entries(tasks).filter(task => task[1].availability !== 'removed');//sort((a, b) => a[0].localeCompare(b[0], 'da-DK'));
}

export const getTaskProps = (taskName) => {
    return tasks[taskName];
}

// --- TASK GROUP FUNCTIONALITY --- //
export const getTaskGroupList = () => {
    // Input checking

    // Return task group list
    return Object.entries(taskGroups).filter(tg => tg[1].availability !== 'removed').sort((a, b) => a[0].localeCompare(b[0], 'da-DK'));
}