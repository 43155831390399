import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import SideBar from '../components/SideBar/SideBar';
import ActivityFeed from '../components/ActivityFeed/ActivityFeed';

class overviewMapCommunity extends Component {

    render() {
        return (
                <SideBar cardContent={
                    <div>
                        <ActivityFeed />
                    </div>
                } />
        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(overviewMapCommunity));