import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import moment from 'moment';
import Cookies from 'universal-cookie';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Material UI Icons
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ColumnIcon from '@material-ui/icons/ViewColumnRounded';
import ReplayIcon from '@material-ui/icons/ReplayRounded';
import SearchIcon from '@material-ui/icons/SearchRounded';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';

// Components
import * as actions from '../../store/actions/index';
import Filter from './InventoryList/Components/Filter';
import Download from './InventoryList/Components/Download';
import Print from './InventoryList/Components/Print';
import { sortSpecies, sortNumAlpha, stableSort, getSorting } from './Utilities/utils';
import { simulate, getAreaCO2Equiv, getSpecieCO2Equiv } from './Utilities/VolumeFunctionsDK';
import { formatEstInput } from './Utilities/utils';
import { getCategoryFromCodeName } from './Utilities/categoriesUtil';

// Get rows
import * as rowsList from './Utilities/listRows';

// Links
// Søg eks. https://codesandbox.io/s/eloquent-clarke-w1ehv?file=/src/index.js:2624-2634
// Material UI V3. https://v3.material-ui.com/demos/tables/#tables

// Auxillary libs
const _ = require("underscore");
const species = require('./Utilities/updatedSpeciesList.json');
const cookies = new Cookies();

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy, rows } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map(row => (
                        <React.Fragment key={row.id}>
                            {row.checked && <TableCell
                                style={{
                                    backgroundColor: 'white',
                                    minWidth: row.id === 'year' || row.id === 'coverage' ? 120 :
                                        row.id === 'area' || row.id === 'species' || row.id === 'thinning' ? 150 :
                                            row.id === 'CO2_e' ? 105 : 40
                                }}
                                key={row.id}
                                align='left'
                                padding='default'
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sorter"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                        IconComponent={ArrowDropDownIcon}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>}
                        </React.Fragment>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const styles = theme => ({
    paper: { // ok
        width: '100%',
    },
    root: { // ok
        zIndex: 102,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        padding: 20,
    },
    table: {
        width: '100%',
        borderBottom: '1px solid #E0E0E0'
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    // Toolbar
    toolbarRoot: {
        paddingRight: theme.spacing.unit,
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    title: {
        flex: '0 0 auto',
    },
    // Menu Containers
    menuContainer: {
        padding: 16,
    },
    naviContainer: {
        paddingLeft: 8,
        paddingRight: 8
    },
    // Search
    search: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0,
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    cellNoBorder: {
        borderBottom: "none",
        whiteSpace: 'pre-line'
    },
    cellStyle: {
        borderTop: '1px solid #E0E0E0',
        whiteSpace: 'pre-line'
    },
    cellStyleItalic: {
        borderTop: '1px solid #E0E0E0',
        fontStyle: 'italic',
    },
    bodyFontItalic: {
        fontStyle: 'italic',
    },
});

class EnhancedTable extends React.Component {

    state = {
        // Table setup
        order: 'asc',
        orderBy: 'section',
        numeric: true,
        page: 0,
        rowsPerPage: 10,
        anchorEl: null,
        anchorEb: null,
        anchorNa: {
            xPos: null,
            yPos: null,
        },
        naviData: null,
        searchText: '',
        height: window.innerHeight,

        rows: JSON.parse(JSON.stringify(rowsList[this.props.config.columnProfile]) || null),

        // Load list data from props
        listData: this.props.cells[this.props.selectedForest],
        listDataNew: null,
        listDataShow: null,
        listSummaryData: [],
        tableBodyContent: [],
        updateContent: true,

        // Summary data
        litraNum: 0,
        lengthTotal: 0,
        areaTotal: 0,
        forestAreaTotal: 0,
        areaTotalFoliage: 0,
        areaTotalNeedle: 0,
        treeCountTotal: 0,
        volumeTotal: 0,
        CO2Total: 0,
        intensiveAreaPrc: 0,
        biodiversityAreaPrc: 0,
        primavelAreaPrc: 0,
        showTreeCount: false,
        showCO2: false,
        showUsage: false,


        // Filter states
        jiFilterCategories: [],
        jiFilterSpecies: [],
        jiFilterRanges: [],
        jiFilterCategoriesActive: false,
        jiFilterSpeciesActive: false,
        jiFilterRangeActive: false,
        jiFilterSearchActive: false,
        jiEstimationSteps: 0,
        jiFutureThinning: true
    };

    componentWillMount() {
        // unpack data for list
        let catList = ["Drift"];
        let spcList = [];
        let list = [];
        this.state.listData.forEach((cell) => {
            // Set categories list
            if (cell.categories && cell.categories.length > 0 && !catList.includes(getCategoryFromCodeName(cell.categories[0]))) {
                catList.push(getCategoryFromCodeName(cell.categories[0]));
            }
            // check species and exclude non-relevant
            if (cell.cellData.species !== 'Fredskovsgrænse') {
                Object.values(cell.cellData.speciesObjs).sort((a, b) => b.mix - a.mix).forEach((el) => {
                    // Set entry data as combination of cell and species
                    let data = {
                        litra: cell.cellData.litra,
                        area: cell.cellData.area,
                        lineLength: cell.cellData.lineLength,
                        polyId: cell.cellData.id,
                        geoType: cell.cellData.type,
                        categories: cell.categories,
                        ...el
                    }
                    // Set note to data from cell inventory
                    data.note = cell.cellData.note;
                    // Convert data content
                    const itemContent = this.inventoryListItemContent(data);
                    // log all species type
                    if (!spcList.includes(itemContent.species)) {
                        spcList.push(itemContent.species)
                    }
                    // Find ranges of values
                    let tempRows = [...this.state.rows];
                    if (this.props.config.columnProfile === 'natureUnpayed' || (this.props.selectedForest && !this.props.customerList[this.props.selectedForest].hasPayedSubscription)) {
                        tempRows = tempRows.filter(el => { return el.id !== "CO2_e"; });
                    }
                    tempRows.forEach(el => {
                        if ('initRange' in el) {
                            if (itemContent[el.id] !== '-' && itemContent[el.id] !== '- (-)') {
                                // Check for thinning array
                                if (el.id === 'thinning') {
                                    // Find lowest and highest year
                                    itemContent[el.id].forEach(th => {
                                        const year = moment(th.date).year()
                                        if (el.initRange.length < 1) {
                                            el.initRange[0] = year;
                                            el.initRange[1] = year;
                                        } else {
                                            if (year < el.initRange[0]) {
                                                el.initRange[0] = year;
                                            }
                                            if (year > el.initRange[1]) {
                                                el.initRange[1] = year;
                                            }
                                        }
                                    })
                                } else {
                                    let tVar = parseFloat(itemContent[el.id].split(',').join('.'));
                                    if (el.id === 'year') {
                                        tVar = parseFloat(itemContent[el.id].split('(').pop().split(')')[0])
                                    } else if (el.id === 'area') {
                                        tVar = parseFloat(itemContent.polyArea.split(',').join('.'))
                                    }
                                    if (el.initRange.length < 1) {
                                        el.initRange[0] = tVar;
                                        el.initRange[1] = tVar;
                                    } else {
                                        if (tVar < el.initRange[0]) {
                                            el.initRange[0] = tVar;
                                        }
                                        if (tVar > el.initRange[1]) {
                                            el.initRange[1] = tVar;
                                        }
                                    }
                                }
                                el.newRange = el.initRange;
                            }
                        }
                    })
                    list.push(itemContent);
                })
            }
        });
        // Sort the list
        list = list.sort(sortNumAlpha);
        this.setState({ listDataNew: list })

        // Set list data to show
        this.setState({ listDataShow: list })

        // Init filters
        if (!this.props.filterState) {
            // Categories
            let cList = catList.map(cat => {
                return { label: cat, checked: true }
            });
            cList = cList.sort((a, b) => a.label.localeCompare(b.label, 'da-DK'));
            // Species
            let sList = spcList.map(spc => {
                // check for preload setting on specie
                let checked = true;
                if (species[spc][this.props.speciesScheme].showInList && species[spc][this.props.speciesScheme].showInList === 'disabled') {
                    checked = false;
                    if (!this.state.jiFilterSpeciesActive) this.setState({ jiFilterSpeciesActive: true });
                }
                return { label: spc, checked: checked }
            });
            sList = sortSpecies(sList)
            // Ranges
            const rList = this.state.rows.filter(row => {
                return row.initRange !== undefined && row.initRange.length > 0 && row.initRange[0] !== row.initRange[1];
            });
            // Set state
            this.setState({ jiFilterCategories: cList, jiFilterSpecies: sList, jiFilterRanges: rList })
        } else {
            // Set state from reducer state
            this.setState({
                jiFilterCategories: this.props.filterState.jiFilterCategories,
                jiFilterSpecies: this.props.filterState.jiFilterSpecies,
                jiFilterRanges: this.props.filterState.jiFilterRanges,
                searchText: this.props.filterState.searchText,
                jiFilterCategoriesActive: this.props.filterState.jiFilterCategoriesActive,
                jiFilterSpeciesActive: this.props.filterState.jiFilterSpeciesActive,
                jiFilterRangeActive: this.props.filterState.jiFilterRangeActive,
                jiFilterSearchActive: this.props.filterState.jiFilterSearchActive,
                jiEstimationSteps: this.props.filterState.jiEstimationSteps,
                jiFutureThinning: this.props.filterState.jiFutureThinning,
            })
        }
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        // Set reducer state when unmounting
        this.props.onSetFilterState({
            jiFilterCategories: [...this.state.jiFilterCategories],
            jiFilterSpecies: [...this.state.jiFilterSpecies],
            jiFilterRanges: JSON.parse(JSON.stringify(this.state.jiFilterRanges)),
            searchText: this.state.searchText,
            jiFilterCategoriesActive: this.state.jiFilterCategoriesActive,
            jiFilterSpeciesActive: this.state.jiFilterSpeciesActive,
            jiFilterRangeActive: this.state.jiFilterRangeActive,
            jiFilterSearchActive: this.state.jiFilterSearchActive,
            jiEstimationSteps: this.state.jiEstimationSteps,
            jiFutureThinning: this.state.jiFutureThinning
        })

        // Set cell ids in reducer state
        const cellIds = this.state.listSummaryData.map(el => {
            return el.id;
        })
        this.props.onSetFilterCellIds(cellIds)

        // Remove eventlistners 
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    componentDidMount() {
        // Update columns based on stored cookies
        let columnState = [...this.state.rows];
        // if (this.props.currentAuthUser.userRole === 'forester' && cookies.get('columnStateForester')) {
        //     columnState = cookies.get('columnStateForester');
        // } else if (this.props.currentAuthUser.userRole === 'forestOwner' && cookies.get('columnStateForestOwner')) {
        //     columnState = cookies.get('columnStateForestOwner');
        // }
        // Check if CO2 should be displayed (only for forests with a payed subscription)
        if (this.props.config.columnProfile === 'natureUnpayed' || (this.props.selectedForest && !this.props.customerList[this.props.selectedForest].hasPayedSubscription)) {
            columnState = columnState.filter(el => {
                return el.id !== "CO2_e";
            })
        }
        this.setState({ rows: columnState })

        // if (this.props.operationPlusActive || this.props.operationActive) {    
        //     columnState = cookies.get('columnStateForester');
        //     if (columnState) {
        //         if (this.props.selectedForest && !this.props.customerList[this.props.selectedForest].hasPayedSubscription) {
        //             columnState = columnState.filter(el => {
        //                 return el.id !== "CO2_e";
        //             })
        //         } else if (this.props.selectedForest && this.props.customerList[this.props.selectedForest].hasPayedSubscription) {
        //             let newColumnState = [...rowsList.profPayed];
        //             columnState.forEach(el => {
        //                 if (!el.checked) {
        //                     const idx = newColumnState.findIndex(row => row.id === el.id);
        //                     newColumnState[idx].checked = false;
        //                 }
        //             })
        //             columnState = newColumnState;
        //         }
        //     }
        // } else {
        //     columnState = cookies.get('columnStateForestOwner');
        //     if (columnState) {
        //         if (this.props.selectedForest && !this.props.customerList[this.props.selectedForest].hasPayedSubscription) {
        //             columnState = columnState.filter(el => {
        //                 return el.id !== "CO2_e";
        //             })
        //         } else if (this.props.selectedForest && this.props.customerList[this.props.selectedForest].hasPayedSubscription) {
        //             let newColumnState = [...rowsList.naturePayed];
        //             columnState.forEach(el => {
        //                 if (!el.checked) {
        //                     const idx = newColumnState.findIndex(row => row.id === el.id);
        //                     newColumnState[idx].checked = false;
        //                 }
        //             })
        //             columnState = newColumnState;
        //         }
        //     }
        // }
        // if (columnState) {
        //     this.setState({ rows: columnState })
        // }
    }

    componentDidUpdate() {
        if (this.state.updateContent) {
            this.renderTableRows()
            this.setState({ updateContent: false })
        }
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight });
    };

    applyFilters = () => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Filter ranges apply`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ updateContent: true })
    }

    resetFilters = () => {
        let updatedFilter = [...this.state.jiFilterRanges];
        for (const item of updatedFilter) {
            item.newRange = item.initRange;
        }
        this.setState({ updatedFilter, jiFilterRangeActive: false, jiEstimationSteps: 0, jiFutureThinning: true, updateContent: true })
    }

    onRangeChange(i, event, newValue) {
        let arr = [...this.state.jiFilterRanges];
        let currentFilterState = false;
        arr[i].newRange = newValue;
        for (const item of this.state.jiFilterRanges) {
            if (item.initRange[0] !== item.newRange[0] || item.initRange[1] !== item.newRange[1]) {
                currentFilterState = true;
            }
        }
        // this.setState({ arr, jiFilterRangeActive: currentFilterState })
        this.setState({ jiFilterRanges: arr, jiFilterRangeActive: currentFilterState })
    }

    onCategoryChange(i) {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Filter change specie: ${this.state.jiFilterCategories[i].label}, to state: ${!this.state.jiFilterCategories[i].checked}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        let arr = [...this.state.jiFilterCategories];
        let currentFilterState = false;
        arr[i].checked = !arr[i].checked;
        for (const item of this.state.jiFilterCategories) {
            if (!item.checked) {
                currentFilterState = true;
            }
        }

        this.setState({ arr, updateContent: true, jiFilterCategoriesActive: currentFilterState })
    }

    onCategoryChangeAll() {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Filter categories change all`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        let arr = [...this.state.jiFilterCategories];
        let currentFilterState = false;
        for (const item of arr) {
            if (this.state.jiFilterCategoriesActive) {
                item.checked = true
            } else {
                item.checked = false
                currentFilterState = true
            }
        }

        this.setState({ arr, updateContent: true, jiFilterCategoriesActive: currentFilterState })
    }

    onSpecieChange(i) {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Filter change specie: ${this.state.jiFilterSpecies[i].label}, to state: ${!this.state.jiFilterSpecies[i].checked}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        let arr = [...this.state.jiFilterSpecies];
        let currentFilterState = false;
        arr[i].checked = !arr[i].checked;
        for (const item of this.state.jiFilterSpecies) {
            if (!item.checked) {
                currentFilterState = true;
            }
        }

        this.setState({ arr, updateContent: true, jiFilterSpeciesActive: currentFilterState })
    }

    onSpecieChangeAll() {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Filter species change all`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        let arr = [...this.state.jiFilterSpecies];
        let currentFilterState = false;
        for (const item of arr) {
            if (this.state.jiFilterSpeciesActive) {
                item.checked = true
            } else {
                item.checked = false
                currentFilterState = true
            }
        }

        this.setState({ arr, updateContent: true, jiFilterSpeciesActive: currentFilterState })
    }

    onChangeEstimationSteps = (event, newValue) => {
        if (this.state.jiEstimationSteps !== newValue) {
            this.setState({ jiEstimationSteps: newValue })
        }
    }

    onChangeFutureThinning = () => {
        this.setState({ jiFutureThinning: !this.state.jiFutureThinning })
    }

    // Check all data entries and setup data for the list
    inventoryListItemContent = (itemData) => {
        const myArray = itemData.litra.split(/([0-9]+)/)
        // Section and litra
        let section = myArray[1] === "" ? "-" : parseInt(myArray[1]);
        let litra = myArray[2] === "" ? "-" : myArray[2];
        if (myArray.length > 3) {
            litra = litra + myArray[3];
        }

        if (Number.isNaN(section)) {
            section = 0;
            litra = itemData.litra;
        }
        section = section.toString();
        // Category
        let category = (itemData.categories && itemData.categories.length > 0) ? getCategoryFromCodeName(itemData.categories[0]) : 'Drift';
        // Year
        let year = itemData.year ? parseInt(itemData.year) : "-";
        if (year > moment().year() || year === 0 || year === 9999) {
            year = "-";
        }
        let age = "-";
        if (year !== "-") {
            age = (moment().year() - year).toString();
        }
        year = year.toString();
        year = year + ' (' + age + ')';
        // Area
        const areaRaw = itemData.area ? parseFloat(itemData.area).toFixed(2) : "-";
        let area = areaRaw.split('.').join(',');
        // if(itemData.areaBearing !== undefined && itemData.areaBearing === false) {
        //     area = "-";
        // }
        // Height
        let height = "*";
        if (typeof itemData.height === 'number') {
            height = (itemData.height !== 0 && !isNaN(itemData.height)) ? itemData.height.toFixed(1).split('.').join(',') : "-";
        } else {
            height = (itemData.height !== "" && itemData.height !== null) ? itemData.height : "-";
        }
        // Diamater
        let diameter = "*";
        if (typeof itemData.diameter === 'number') {
            diameter = (itemData.diameter !== 0 && !isNaN(itemData.diameter)) ? itemData.diameter.toFixed(1).split('.').join(',') : "-";
        } else {
            diameter = (itemData.diameter !== "" && itemData.diameter !== null) ? itemData.diameter : "-";
        }
        // Volume
        let volume = "*";
        if (itemData.mass !== '' && itemData.mass !== null && !isNaN(itemData.mass)) {
            if (typeof itemData.mass === 'number') {
                volume = itemData.mass !== 0 ? itemData.mass.toFixed(0).split('.').join(',') : "-";
            } else {
                volume = itemData.mass;
            }
        } else {
            volume = '-';
        }
        // Mix percentage
        let mix = "*";
        if (typeof itemData.mix === 'number') {
            mix = itemData.mix !== 0 ? itemData.mix.toFixed(0).split('.').join(',') : "-";
        } else {
            mix = (itemData.mix !== "" && itemData.mix !== null) ? itemData.mix : "-";
        }
        // Coverage percentage
        let coverage = "*";
        if (typeof itemData.coverage === 'number') {
            coverage = itemData.coverage !== 0 ? itemData.coverage.toFixed(0).split('.').join(',') : "-";
        } else {
            coverage = (itemData.coverage !== "" && itemData.coverage !== null) ? itemData.coverage : "-";
        }
        // Quality
        let quality = "*";
        if (typeof itemData.quality === 'number') {
            quality = itemData.quality !== 0 ? itemData.quality.toFixed(0).split('.').join(',') : "-";
        } else {
            quality = (itemData.quality !== "" && itemData.quality !== null) ? parseFloat(itemData.quality).toFixed(0) : "-";
        }
        // Tree count
        const fTreeCount = isNaN(parseFloat(itemData.treeCount)) ? 0 : parseFloat(itemData.treeCount.toString().split(',').join('.'));
        const fMix = isNaN(parseFloat(mix)) ? 0 : parseFloat(mix.toString().split(',').join('.'));
        const fArea = isNaN(parseFloat(area)) ? 0 : parseFloat(area.toString().split(',').join('.'));
        // let treeCount = ((fTreeCount / 100) * (fArea * ((fMix === 0 ? 100 : fMix) / 100) * 10000)).toFixed(0).split('.').join(',');
        let treeCount = (fTreeCount * 100 * fArea).toFixed(0).split('.').join(',');
        treeCount = treeCount === '0' ? '-' : treeCount;
        // Ground area
        let groundArea = '-';
        if ("groundArea" in itemData) {
            if (typeof itemData.groundArea === 'number') {
                groundArea = itemData.groundArea !== 0 ? itemData.groundArea.toFixed(1).split('.').join(',') : "-";
            } else {
                groundArea = (itemData.groundArea !== "" && itemData.groundArea !== null) ? itemData.groundArea : "-";
            }
        }
        // Calculate area acording to mix
        let spcArea = (fArea * ((fMix) / 100)).toFixed(2).split('.').join(',');
        if (itemData.mix === '' && species[itemData.species].type === 'other') {
            spcArea = fArea.toFixed(2).split('.').join(',');
        } else if (itemData.mix === '' && (itemData.height === '' || itemData.diameter === '' || itemData.year === '')) {
            spcArea = fArea.toFixed(2).split('.').join(',');
        }
        // CO2-e [ton]
        let CO2_e = '-';
        let CO2g_e = 0;
        let CO2tr_e = 0;
        if (itemData.geoType === 'Polygon') {
            const fAreaMix = fArea * (fMix === 0 ? 100 : fMix / 100);
            const fDiameter = isNaN(parseFloat(diameter)) ? 0 : parseFloat(diameter.toString().split(',').join('.'));
            const fHeight = isNaN(parseFloat(height)) ? 0 : parseFloat(height.toString().split(',').join('.'));
            const fVol = isNaN(parseFloat(volume)) ? 0 : parseFloat(volume.toString().split(',').join('.'));
            CO2g_e = getAreaCO2Equiv(fAreaMix, {});
            if (fVol > 0 && fDiameter > 0 && fHeight > 0 && species[itemData.species].estModel) {
                // Because we input total mass and not mass/ha we do not need to go from CO2-e/Ha to total CO2-e
                CO2tr_e = getSpecieCO2Equiv(this.props.speciesScheme, itemData.species, { Dg: fDiameter / 100, Hg: fHeight, V: fVol });
                // CO2_e = (CO2g_e + CO2tr_e).toFixed(0).split('.').join(',');
                CO2_e = CO2tr_e.toFixed(0).split('.').join(',');
            } else {
                // CO2_e = CO2g_e.toFixed(0).split('.').join(',');
                CO2_e = '-';
            }
        }
        // Line length
        let lineLength = "*";
        if (typeof itemData.lineLength === 'number') {
            lineLength = itemData.lineLength !== 0 ? itemData.lineLength.toFixed(0).split('.').join(',') : "-";
        } else {
            lineLength = "-";
        }
        // Thinning objects
        let thinning = "*";
        if (!_.isEmpty(itemData.thinningArr)) {
            const sortedThinningArr = Object.values(itemData.thinningArr).sort((a, b) => Date.parse(new Date(b.date)) - Date.parse(new Date(a.date)));
            // thinning = Object.values(sortedThinningArr).map(el => {
            //     const date = moment(new Date(el.date)).format('DD[-]MM[-]YYYY');
            //     let tt = el.treeCountThinning ? (!isNaN(parseInt(el.treeCountThinning)) ? parseInt(el.treeCountThinning) : 0) : 0;
            //     let vt = el.volumeThining ? (!isNaN(parseInt(el.volumeThining)) ? parseInt(el.volumeThining) : 0) : 0;
            //     let pt = el.percentageThinning ? (!isNaN(parseInt(el.percentageThinning)) ? parseInt(el.percentageThinning) : 0) : 0;
            //     let thinString = "-";
            //     if (moment(date).isSameOrBefore(moment().endOf("day"))) {
            //         if (tt === 0 || vt === 0 ) {
            //             thinString = date + '\n' + pt + ' % (!)';
            //         } else {
            //             thinString = date + '\n' + tt + ' stammer / ' + vt + ' m3';
            //         }
            //     } else {
            //         thinString = date + '\n' + pt + ' %';
            //     }
            //     // return moment(new Date(el.date)).format('DD[-]MM[-]YYYY');
            //     return thinString;
            // })
            // thinning = thinning.join(', ');
            thinning = sortedThinningArr;
        } else {
            thinning = "-";
        }
        // Note
        let note = "*";
        if (typeof itemData.note === "number") {
            note = itemData.note.toString();
        } else {
            note = itemData.note === "" ? "-" : itemData.note;
        }
        // Return item data
        return (
            {
                id: itemData.polyId,
                section: section,
                litra: litra,
                category: category,
                main: itemData.main,
                areaBearing: itemData.areaBearing !== undefined ? itemData.areaBearing : true,
                species: itemData.species,
                year: year,
                thinning: thinning,
                polyArea: fArea.toFixed(2).split('.').join(','),
                area: spcArea,
                lineLength: lineLength,
                mix: mix,
                coverage: coverage,
                quality: quality,
                height: height,
                diameter: diameter,
                treeCount: treeCount,
                groundArea: groundArea,
                volume: volume,
                CO2_e: CO2_e,
                CO2g_e: CO2g_e,
                CO2tr_e: CO2tr_e,
                note: note,
                geoType: itemData.geoType,
            }
        );
    }

    listSummaryItem(item, content, endAdornment) {
        return (
            <div style={{ marginRight: 24 }}>
                <Typography
                    variant="body1"
                    style={{ fontSize: 14, fontWeight: 500, marginRight: 8 }}
                >
                    {item}
                </Typography >
                <Typography
                    variant="body1"
                    style={{ fontSize: 14 }}
                >
                    {content}{endAdornment}
                </Typography >
            </div>
        )
    }

    listSummaryItemPaired(title, text, data) {
        return (
            <div style={{ marginRight: 24 }}>
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="body1"
                        style={{ fontSize: 14, fontWeight: 500, marginRight: 6 }}
                    >
                        {title}
                    </Typography >

                    <Typography
                        variant="body1"
                        style={{ fontSize: 14 }}
                    >
                        {text}
                    </Typography >
                </div>

                <Typography
                    variant="body1"
                    style={{ fontSize: 14 }}
                >
                    {data}
                </Typography >
            </div>
        )
    }

    listSummary(data) {
        // Set data
        let litraNum = 0;
        let lengthTotal = 0;
        let areaTotal = 0;
        let forestAreaTotal = 0;
        let areaIntensive = 0;
        let areaBiodiversity = 0;
        let areaPrimeval = 0;
        let areaTotalFoliage = 0;
        let areaTotalNeedle = 0;
        let volTotal = 0;
        let treeCountTotal = 0;
        let CO2Total = 0;

        // Find unique litras
        const unique = [];
        const map = new Map();
        for (const item of data) {
            if (!map.has(item.id)) {
                map.set(item.id, true);    // set any value to Map
                unique.push({
                    id: item.id,
                    area: item.polyArea,
                });
                if (item.areaBearing) {
                    areaTotal = areaTotal + parseFloat(item.polyArea.split(',').join('.'));
                    forestAreaTotal = forestAreaTotal + (item.category === 'Øvrige' ? 0 : parseFloat(item.polyArea.split(',').join('.')));
                }
            }
        }

        //Set number of litras/polygons
        litraNum = unique.length;

        // Set other data
        for (let i = 0; i < data.length; i++) {
            // Line length
            if (data[i].lineLength !== '-') {
                lengthTotal = lengthTotal + parseFloat(data[i].lineLength.split(',').join('.'));
            }

            // Area
            if (data[i].area !== '-' && data[i].areaBearing) {
                // areaTotal = areaTotal + parseFloat(data[i].area.split(',').join('.'));
                if (species[data[i].species].type === 'foliage') {
                    areaTotalFoliage = areaTotalFoliage + parseFloat(data[i].area.split(',').join('.'));
                } else if (species[data[i].species].type === 'needle') {
                    areaTotalNeedle = areaTotalNeedle + parseFloat(data[i].area.split(',').join('.'));
                }
                // Area according to category
                if (data[i].category === 'Intensiv drift') {
                    areaIntensive = areaIntensive + parseFloat(data[i].area.split(',').join('.'));
                } else if (data[i].category === 'Biodiversitet') {
                    areaBiodiversity = areaBiodiversity + parseFloat(data[i].area.split(',').join('.'));
                } else if (data[i].category === 'Urørt') {
                    areaPrimeval = areaPrimeval + parseFloat(data[i].area.split(',').join('.'));
                }
            }

            // Volume
            if (data[i].volume !== '-') {
                volTotal = volTotal + parseFloat(data[i].volume.split(',').join('.'));
            }

            // Tree count
            if (data[i].treeCount !== '-') {
                treeCountTotal = treeCountTotal + parseFloat(data[i].treeCount.split(',').join('.'));
            }

            // CO2-e
            if (data[i].CO2_e !== '-') {
                CO2Total = CO2Total + parseFloat(data[i].CO2_e.split(',').join('.'));
            }
        }

        // Calculate area percentages
        const intensiveAreaPrc = (forestAreaTotal === 0 ? 0 : 100 * areaIntensive / forestAreaTotal).toFixed(2).split('.').join(',');
        const biodiversityAreaPrc = (forestAreaTotal === 0 ? 0 : 100 * areaBiodiversity / forestAreaTotal).toFixed(2).split('.').join(',');
        const primavelAreaPrc = (forestAreaTotal === 0 ? 0 : 100 * areaPrimeval / forestAreaTotal).toFixed(2).split('.').join(',');

        // Set state
        if (this.state.litraNum !== litraNum.toFixed(0) ||
            this.state.lengthTotal !== lengthTotal.toFixed(0) ||
            this.state.areaTotal !== areaTotal.toFixed(2).split('.').join(',') ||
            this.state.forestAreaTotal !== forestAreaTotal.toFixed(2).split('.').join(',') ||
            this.state.areaTotalFoliage !== areaTotalFoliage.toFixed(2).split('.').join(',') ||
            this.state.areaTotalNeedle !== areaTotalNeedle.toFixed(2).split('.').join(',') ||
            this.state.treeCountTotal !== treeCountTotal.toFixed(0).split('.').join(',') ||
            this.state.volumeTotal !== volTotal.toFixed(0) ||
            this.state.CO2Total !== CO2Total.toFixed(0) ||
            this.state.intensiveAreaPrc !== intensiveAreaPrc ||
            this.state.biodiversityAreaPrc !== biodiversityAreaPrc ||
            this.state.primavelAreaPrc !== primavelAreaPrc) {
            this.setState({
                litraNum: litraNum.toFixed(0),
                lengthTotal: lengthTotal.toFixed(0),
                areaTotal: areaTotal.toFixed(2).split('.').join(','),
                forestAreaTotal: forestAreaTotal.toFixed(2).split('.').join(','),
                areaTotalFoliage: areaTotalFoliage.toFixed(2).split('.').join(','),
                areaTotalNeedle: areaTotalNeedle.toFixed(2).split('.').join(','),
                intensiveAreaPrc: intensiveAreaPrc,
                biodiversityAreaPrc: biodiversityAreaPrc,
                primavelAreaPrc: primavelAreaPrc,
                treeCountTotal: treeCountTotal.toFixed(0).split('.').join(','),
                volumeTotal: volTotal.toFixed(0),
                CO2Total: CO2Total.toFixed(0),
            })
        }

        // Set if item should be shown
        const showTreeCount = this.props.config.summaryProfile.treeCount === 'enabled';
        const showCO2 = (this.props.config.columnProfile !== 'natureUnpayed' && this.props.selectedForest && this.props.customerList[this.props.selectedForest].hasPayedSubscription) ? true : false;
        const showUsage = (this.props.config.summaryProfile.usage === 'enabled' ? true : false)

        if (showTreeCount !== this.state.showTreeCount || showCO2 !== this.state.showCO2 || showUsage !== this.state.showUsage) {
            this.setState({ showTreeCount: showTreeCount, showCO2: showCO2, showUsage: showUsage });
        }

        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {this.listSummaryItem('Litraer', litraNum)}
                    {this.listSummaryItem('Længde', lengthTotal.toFixed(0), ' m')}
                    {this.listSummaryItemPaired('Areal', '(samlet | skov | løv | nål)', [areaTotal.toFixed(2).split('.').join(','), ' ha | ', forestAreaTotal.toFixed(2).split('.').join(','), ' ha | ', areaTotalFoliage.toFixed(2).split('.').join(','), ' ha | ', areaTotalNeedle.toFixed(2).split('.').join(','), ' ha'])}
                    {/* {this.listSummaryItem('Antal ha:', areaTotal.toFixed(2).split('.').join(','))}
                    {this.listSummaryItem('Løv ha:', areaTotalFoliage.toFixed(2).split('.').join(','))}
                    {this.listSummaryItem('Nål ha:', areaTotalNeedle.toFixed(2).split('.').join(','))} */}
                    {showTreeCount ? this.listSummaryItem('Stamtal', treeCountTotal.toFixed(0).split('.').join(',')) : null}
                    {this.listSummaryItem('Vedmasse', volTotal.toFixed(0), ' m3')}
                    {showCO2 ? this.listSummaryItem('CO2-e', CO2Total.toFixed(0), ' ton') : null}
                    {showUsage ? this.listSummaryItemPaired('Anvendelse', '(intensivt | biodiversitet | urørt)', [intensiveAreaPrc, ' % | ', biodiversityAreaPrc, ' % | ', primavelAreaPrc, ' %']) : null}
                </div>
            </div>
        )
    }

    handleSearchTextChange = event => {
        if (event.target.value !== '') {
            this.setState({ jiFilterSearchActive: true });
        } else {
            this.setState({ jiFilterSearchActive: false });
        }
        this.setState({ searchText: event.target.value, updateContent: true })
    };

    handleRequestSort = (event, property) => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Rows sorting clicked, property: ${property}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        let numeric = this.state.rows.filter(r => { return r.id === property })
        numeric = numeric[0].numeric;
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy, numeric, updateContent: true });
    };

    handleColumnClick = event => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Coloumn choser opened`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ anchorEl: event.currentTarget });
    };

    handleColumnClose = () => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Coloumn choser closed`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ anchorEl: null });
    };

    handleFilterClick = event => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Filter opened`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ anchorEb: event.currentTarget });
    };

    handleFilterClose = () => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Filter closed`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ anchorEb: null });
    };

    handleRowClick = (event, data) => {
        event.preventDefault();
        const newAnchor = {
            xPos: event.clientX - 2,
            yPos: event.clientY - 4,
        }
        this.setState({ anchorNa: newAnchor, naviData: data });
    };

    handleRowClickAnchorButtonClose = () => {
        const newAnchor = {
            xPos: null,
            yPos: null
        }
        this.setState({ anchorNa: newAnchor, naviData: null });
    };

    handleNavigateToMapClick = () => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Navigate to list item: ${this.state.naviData.id}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.props.rowClick(this.state.naviData)
        this.handleCloseList();
    }

    handleShowFilteredInMapClick = () => {
        if (this.props.filterShowFilterInMap && this.props.filterCellIds.length === this.state.listSummaryData.length) {
            this.props.onSetShowFilterInMap(false);
            this.props.onRedrawCells();
        } else {
            this.props.onSetShowFilterInMap(true);
            this.handleCloseList();
            this.props.onRedrawCells();
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleCloseList = () => {
        this.props.onSetInventoryListActive(false);
        // Send event to Analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Inventory list open: ${false}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleResetList = () => {
        // Send event to analytics
        ReactGA.event({
            category: 'Inventory list',
            action: `Reset list`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        // Reset filters
        this.state.jiFilterRanges.forEach(r => {
            r.newRange = r.initRange;
        })
        this.state.jiFilterSpecies.forEach(s => {
            s.checked = true;
        })
        this.state.jiFilterCategories.forEach(c => {
            c.checked = true;
        })
        this.setState({ jiFilterCategoriesActive: false, jiFilterSpeciesActive: false, jiFilterRangeActive: false, jiFilterSearchActive: false, jiEstimationSteps: 0, jiFutureThinning: true })

        // Reset search text
        this.setState({ searchText: '' })

        // Reset columns
        this.state.rows.forEach((r, i) => {
            r.checked = true;
        })
        if (this.props.currentAuthUser.userRole === 'forester' || this.props.operationActive) {
            cookies.set('columnStateForester', this.state.rows)
        } else {
            cookies.set('columnStateForestOwner', this.state.rows)
        }

        // Reset sorting
        this.setState({ order: 'asc', orderBy: 'section' })

        // Reset show filter in map
        this.props.onSetShowFilterInMap(false);
        this.props.onSetFilterCellIds(null);
        this.props.onRedrawCells();

        // Update content
        this.setState({ updateContent: true })
    }

    categoryFilter = (cat) => {
        for (let i = 0; i < this.state.jiFilterCategories.length; i++) {
            if (cat === this.state.jiFilterCategories[i].label) {
                return !this.state.jiFilterCategories[i].checked;
            }
        }
    }

    speciesFilter = (spc) => {
        for (let i = 0; i < this.state.jiFilterSpecies.length; i++) {
            if (spc === this.state.jiFilterSpecies[i].label) {
                return !this.state.jiFilterSpecies[i].checked;
            }
        }
    }

    rangesFilter = (id, columnEntry) => {
        const N = this.state.jiFilterRanges.length;
        let tVar = '-';
        if (id === 'year') {
            tVar = parseFloat(columnEntry.split('(').pop().split(')')[0])
        } else if (id === 'thinning' && columnEntry !== '-') {
            tVar = columnEntry.map(el => {
                return moment(el.date).year();
            })
        } else {
            tVar = parseFloat(columnEntry.split(',').join('.'));
        }
        let filteredOut = false;
        for (let i = 0; i < N; i++) {
            if (this.state.jiFilterRanges[i].id === id && this.state.jiFilterRanges[i].checked && (this.state.jiFilterRanges[i].newRange[0] !== this.state.jiFilterRanges[i].initRange[0] || this.state.jiFilterRanges[i].newRange[1] !== this.state.jiFilterRanges[i].initRange[1])) {
                if (columnEntry === '-' || columnEntry === '- (-)' || tVar === '-') {
                    filteredOut = true;
                    break;
                } else if (Array.isArray(tVar)) {
                    filteredOut = true;
                    for (let j = 0; j < tVar.length; j++) {
                        if (tVar[j] >= this.state.jiFilterRanges[i].newRange[0] && tVar[j] <= this.state.jiFilterRanges[i].newRange[1]) {
                            filteredOut = false;
                            break;
                        }
                    }
                } else if (tVar < this.state.jiFilterRanges[i].newRange[0] || tVar > this.state.jiFilterRanges[i].newRange[1]) {
                    filteredOut = true;
                    break;
                }
            }
        }
        return filteredOut;
    }

    renderTableRows = () => {
        const { classes } = this.props;
        let lastId = '';
        let summaryData = [];
        let ids = [];
        let content = stableSort(this.state.listDataNew, getSorting(this.state.order, this.state.orderBy, this.state.numeric))
            //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((n, i, elements) => {
                // Filter and Search
                let searchFound = false;
                let isFilteredOutCategory = false;
                let isFilteredOutSpecie = false;
                let isFilteredOutRange = false;

                // Apply category filter
                isFilteredOutCategory = this.categoryFilter(n.category);

                // Apply species filter
                if (!isFilteredOutCategory) {
                    isFilteredOutSpecie = this.speciesFilter(n.species);
                }

                // Run through column values in row
                if (!isFilteredOutCategory && !isFilteredOutSpecie) {
                    for (let i = 0; i < this.state.rows.length; i++) {
                        // Apply filters on ranges
                        let tVar = n[this.state.rows[i].id];
                        if (this.state.rows[i].id === 'area') {
                            tVar = n.polyArea;
                        }
                        isFilteredOutRange = this.rangesFilter(this.state.rows[i].id, tVar)
                        if (isFilteredOutRange) { break }

                        // Apply search
                        if (this.state.rows[i].checked) {
                            if (this.state.searchText.length > 0 && !isFilteredOutSpecie && !isFilteredOutRange) {
                                const searchTxt = this.state.searchText.toLowerCase();
                                // Tokenice search text to check for litra search
                                let tokStr = searchTxt.split(';');
                                if (tokStr.length === 1) {
                                    if (typeof n[this.state.rows[i].id] === 'string') {
                                        let text = n[this.state.rows[i].id].toLowerCase();
                                        // Included for searching after section numberss
                                        let textSec = null;
                                        // If litra search append section to litra text before search
                                        if (this.state.rows[i].id === 'litra') {
                                            text = n[this.state.rows[i - 1].id].toLowerCase() + text;
                                            textSec = n[this.state.rows[i - 1].id].toLowerCase();
                                        }
                                        // Search for string i all columns
                                        if (text.indexOf(searchTxt) !== -1 || (textSec && searchTxt === textSec)) {
                                            searchFound = true;
                                            break;
                                        }
                                    }
                                } else if (tokStr.length > 1) {
                                    if (this.state.rows[i].id === 'litra') {
                                        let text = n[this.state.rows[i].id].toLowerCase();
                                        text = n[this.state.rows[i - 1].id].toLowerCase() + text;
                                        if (tokStr.includes(text) || tokStr.includes(n[this.state.rows[i - 1].id].toLowerCase())) {
                                            searchFound = true;
                                            break
                                        }
                                    }
                                }

                            } else {
                                searchFound = true;
                            }
                        }
                    }
                }

                // Display row data based on filters, search and selected rows
                let rowContent = null;
                if ((isFilteredOutCategory || isFilteredOutSpecie || isFilteredOutRange) || (this.state.searchText !== '' && !searchFound)) {
                    rowContent = null;
                } else {
                    // Check if values should be projected into the future
                    let shownHeight = n.height;
                    let shownDiameter = n.diameter;
                    let shownTreeCount = n.treeCount;
                    let shownGroundArea = n.groundArea;
                    let shownVol = n.volume;
                    let shownCO2 = n.CO2_e;
                    if (this.state.jiEstimationSteps > 0 && n.area !== '-' && n.volume !== '-' && n.height !== '-' && n.diameter !== '-' && n.groundArea !== '-' && n.treeCount !== '-' && n.year !== '- (-)' && species[n.species].estModel) {
                        // Check if thinning should be included in estimation
                        let thin = {};
                        if (this.state.jiFutureThinning && n.thinning !== '-') {
                            thin = { ...n.thinning }
                        }
                        // Simulate chosen steps into the future
                        const initCond = {
                            area: formatEstInput(n.area) / (formatEstInput(n.mix) / 100),
                            V: formatEstInput(n.volume) / (formatEstInput(n.area) / (formatEstInput(n.mix) / 100)),
                            H: formatEstInput(n.height),
                            D: formatEstInput(n.diameter) / 100,
                            G: formatEstInput(n.groundArea),
                            N: formatEstInput(n.treeCount) / formatEstInput(n.polyArea),
                            age: parseInt(moment().format("YYYY")) - parseInt(n.year.slice(0, 5))
                        }
                        const timeSpan = [0, this.state.jiEstimationSteps];
                        // Simulation
                        try {
                            const simOut = simulate(this.props.speciesScheme, n.species, timeSpan, initCond, thin);
                            // Update shown values based on steps
                            shownHeight = (simOut.H.slice(-1)[0]).toFixed(1);
                            shownDiameter = (simOut.D.slice(-1)[0] * 100).toFixed(1);
                            shownTreeCount = (simOut.N.slice(-1)[0] * formatEstInput(n.polyArea)).toFixed(0);
                            // shownTreeCount = (simOut.N.slice(-1)[0] * (initCond.area * (formatEstInput(n.mix) / 100))).toFixed(0);
                            shownGroundArea = (simOut.G.slice(-1)[0]).toFixed(1).replace('.', ',');
                            shownVol = (simOut.V.slice(-1)[0] * (initCond.area)).toFixed(0);
                            // shownCO2 = (simOut.CO2Equiv.slice(-1)[0]*initCond.area + n.CO2g_e).toFixed(0);
                            shownCO2 = (simOut.CO2Equiv.slice(-1)[0] * (initCond.area)).toFixed(0);
                        } catch (err) {
                            console.error("Inventory list estimation error: ", {el: n, error: err })
                        }
                    }

                    let newItem = { ...n };
                    newItem.height = shownHeight;
                    newItem.diameter = shownDiameter;
                    newItem.treeCount = shownTreeCount;
                    newItem.groundArea = shownGroundArea;
                    newItem.volume = shownVol;
                    newItem.CO2_e = shownCO2;
                    summaryData.push(newItem);

                    rowContent = this.state.rows.map((r, j) => {
                        // Check cell area and change if more species
                        let cellArea = '';
                        if (r.id === 'area') {
                            if (!n.areaBearing) {
                                cellArea = `${n.area} i.a.b.`
                            } else if (n.area === n.polyArea || n.polyArea === '-' || n.area === '-') {
                                cellArea = n.area
                            } else {
                                cellArea = `${n.polyArea} (${n.area})`
                            }
                        }
                        // Set table cell string
                        let tableCellInput = '*';
                        switch (r.id) {
                            case 'area': tableCellInput = cellArea; break;
                            case 'height': tableCellInput = shownHeight; break;
                            case 'diameter': tableCellInput = shownDiameter; break;
                            case 'treeCount': tableCellInput = shownTreeCount; break;
                            case 'groundArea': tableCellInput = shownGroundArea; break;
                            case 'volume': tableCellInput = shownVol; break;
                            case 'CO2_e': tableCellInput = shownCO2; break;
                            default: tableCellInput = n[r.id]; break;
                        }
                        // Check if entry is part of previous cell
                        if (n.id === lastId && (r.id === 'section' || r.id === 'litra' || r.id === 'note')) {
                            tableCellInput = '';
                        }
                        // Check thinning entry
                        if (r.id === 'thinning') {
                            if (n.thinning === '-') {
                                tableCellInput = '-';
                            } else {
                                tableCellInput = n.thinning.map(el => {
                                    const date = moment(new Date(el.date)).format('DD[-]MM[-]YYYY');
                                    let tt = el.treeCountThinning ? (!isNaN(parseInt(el.treeCountThinning)) ? parseInt(el.treeCountThinning) : 0) : 0;
                                    let vt = el.volumeThinning ? (!isNaN(parseInt(el.volumeThinning)) ? parseInt(el.volumeThinning) : 0) : 0;
                                    let pt = el.percentageThinning ? (!isNaN(parseInt(el.percentageThinning)) ? parseInt(el.percentageThinning) : 0) : 0;
                                    if (!this.state.jiFutureThinning && moment(el.date).isAfter(moment().endOf("day"))) {
                                        return null;
                                    } else if (moment(el.date).isSameOrBefore(moment().endOf("day"))) {
                                        if (tt === 0 && vt === 0) {
                                            return date + ':\n' + pt + ' % (Erstat hugstprocent med reelle tal.)';
                                        } else if (tt !== 0 && vt === 0) {
                                            return date + ':\n' + tt + ' stk';
                                        } if (tt === 0 && vt !== 0) {
                                            return date + ':\n' + vt + ' m3';
                                        } else {
                                            return date + ':\n' + tt + ' stk - ' + vt + ' m3';
                                        }
                                    } else {
                                        return date + ':\n' + pt + ' %';
                                    }
                                })
                                tableCellInput = tableCellInput.filter(el => el !== null).join(',\n');
                            }
                        }

                        return r.checked ? <TableCell
                            key={j}
                            align="left"
                            size="small"
                            classes={{
                                root: classes.cellNoBorder,
                                body: ids.length === 0 ? // Check if first line
                                    (!n.main ? (r.id === 'species' ? classes.bodyFontItalic : null) : null)
                                    : (n.id !== lastId ? // Check if new litra
                                        (!n.main ? (r.id === 'species' ? classes.cellStyleItalic : classes.cellStyle) : classes.cellStyle) // New litra. If not main then italic on specie
                                        : (r.id === 'section' || r.id === 'litra' || r.id === 'note' ? null : !n.main ? (r.id === 'species' ? classes.cellStyleItalic : classes.cellStyle) : classes.cellStyle)) // Same litra. Remove top border and add italic if not main
                            }}
                        >
                            {tableCellInput
                                // n.id === lastId ? (r.id === 'section' || r.id === 'litra' || r.id === 'note' ? '' : (r.id === 'area' ? cellArea : n[r.id])) : r.id === 'area' ? cellArea : n[r.id]
                            }
                        </TableCell> : null
                    })
                    // Save last id
                    lastId = n.id;
                    ids.push(n.id)
                }

                return (
                    <TableRow
                        hover
                        onClick={event => this.handleRowClick(event, n)}
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                    >
                        {rowContent}
                    </TableRow>
                );
            })

        // Set summary data and content in state
        this.setState({ listSummaryData: summaryData, tableBodyContent: content, updateContent: true })
    }

    renderToolbar(header) {
        const { classes } = this.props;
        const { anchorEl, anchorEb, litraNum,
            lengthTotal, areaTotal, forestAreaTotal, areaTotalFoliage,
            areaTotalNeedle, treeCountTotal, volumeTotal,
            intensiveAreaPrc, biodiversityAreaPrc, primavelAreaPrc,
            CO2Total, showTreeCount, showCO2, showUsage
        } = this.state;
        // Set rows and summary
        let rows = [...this.state.rows];
        let summary = [
            { label: 'Litraer', checked: true, value: litraNum },
            { label: 'Længde (m)', checked: true, value: lengthTotal },
            { label: 'Samlet (ha)', checked: true, value: areaTotal },
            { label: 'Skov (ha)', checked: true, value: forestAreaTotal },
            { label: 'Løv (ha)', checked: true, value: areaTotalFoliage },
            { label: 'Nål (ha)', checked: true, value: areaTotalNeedle },
            { label: 'Stamtal', checked: showTreeCount, value: treeCountTotal },
            { label: 'Vedmasse (m3)', checked: true, value: volumeTotal },
            { label: 'CO2-e (ton)', checked: showCO2, value: CO2Total },
            { label: 'Intensivt (%)', checked: showUsage, value: intensiveAreaPrc },
            { label: 'Biodiversitet (%)', checked: showUsage, value: biodiversityAreaPrc },
            { label: 'Urørt (%)', checked: showUsage, value: primavelAreaPrc }
        ]

        return (
            <Toolbar
                className={classNames(classes.toolbarRoot)}
            >
                <div className={classes.title}>
                    <Typography variant="h6" id="tableTitle">
                        {header}
                    </Typography>
                </div>

                <div className={classes.spacer} />

                <div className={classes.actions}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>

                        <Tooltip title="Søg i bevoksningsliste">
                            <InputBase
                                placeholder="Søg"
                                onChange={this.handleSearchTextChange}
                                value={this.state.searchText}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Tooltip>
                    </div>

                    <Tooltip title="Filtrere bevoksningsliste">
                        <IconButton
                            aria-label="Filtrere liste"
                            onClick={this.handleFilterClick}
                            color={this.state.jiFilterCategoriesActive || this.state.jiFilterSpeciesActive || this.state.jiFilterRangeActive ? "primary" : "default"}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={(this.props.filterShowFilterInMap && this.props.filterCellIds.length === this.state.listSummaryData.length) ? "Fjern filtrering i kortet" : "Se resultat i kort"}>
                        <IconButton
                            aria-label="Se resultat i kort"
                            onClick={this.handleShowFilteredInMapClick}
                            disabled={(!this.state.jiFilterRangeActive && !this.state.jiFilterCategoriesActive && !this.state.jiFilterSpeciesActive && !this.state.jiFilterSearchActive && !this.props.filterShowFilterInMap) || this.state.listSummaryData.length === 0}
                            color={(this.props.filterShowFilterInMap && this.props.filterCellIds.length === this.state.listSummaryData.length) ? "primary" : "default"}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Tooltip>

                    <div style={{ height: 36, width: 2, margin: "0px 6px", backgroundColor: '#5EBD3E' }} />

                    <Menu
                        id="simple-menu"
                        keepMounted
                        anchorEl={anchorEb}
                        open={Boolean(anchorEb)}
                        onClose={this.handleFilterClose}
                        classes={{
                            paper: classes.menuContainer,
                        }}
                    >
                        <Filter
                            categories={this.state.jiFilterCategories}
                            onCategoryChange={this.onCategoryChange.bind(this)}
                            categoriesAll={!this.state.jiFilterCategoriesActive}
                            onCategoryChangeAll={this.onCategoryChangeAll.bind(this)}
                            ranges={this.state.jiFilterRanges}
                            onRangeChange={this.onRangeChange.bind(this)}
                            species={this.state.jiFilterSpecies}
                            onSpecieChange={this.onSpecieChange.bind(this)}
                            speciesAll={!this.state.jiFilterSpeciesActive}
                            onSpecieChangeAll={this.onSpecieChangeAll.bind(this)}
                            applyFilters={this.applyFilters.bind(this)}
                            resetFilters={this.resetFilters.bind(this)}
                            estimationSteps={this.state.jiEstimationSteps}
                            onChangeEstimationSteps={this.onChangeEstimationSteps}
                            futureThinning={this.state.jiFutureThinning}
                            onChangeFutureThinning={this.onChangeFutureThinning}
                            estimationActive={this.props.currentAuthUser.userRole === 'forester' || this.props.operationActive}
                        />
                    </Menu>

                    {this.props.config.columnSelector === 'enabled' && <Tooltip title="Vælg kolonner">
                        <IconButton
                            aria-label="Column selection"
                            onClick={this.handleColumnClick}
                            color={this.state.rows.every(item => item.checked) ? "default" : "primary"}
                        >
                            <ColumnIcon />
                        </IconButton>
                    </Tooltip>}

                    <Menu
                        id="simple-menu"
                        keepMounted
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleColumnClose}
                        classes={{
                            paper: classes.menuContainer,
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1">
                                Kolonner
                            </Typography>

                            {this.state.rows.map((row, i) => (
                                <FormControlLabel
                                    key={i}
                                    control={
                                        <Checkbox
                                            checked={row.checked}
                                            onChange={() => (
                                                rows[i].checked = !rows[i].checked,
                                                this.setState({ rows, updateContent: true }),
                                                (this.props.currentAuthUser.userRole === 'forester' && this.props.selectedForest) || this.props.operationActive ? cookies.set('columnStateForester', rows) : cookies.set('columnStateForestOwner', rows))}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={row.label}
                                />
                            ))}
                        </div>
                    </Menu>

                    <Menu
                        id="navi-menu"
                        keepMounted
                        open={this.state.anchorNa.yPos !== null}
                        onClose={this.handleRowClickAnchorButtonClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            this.state.anchorNa.xPos !== null && this.state.anchorNa.yPos !== null
                                ? { top: this.state.anchorNa.yPos, left: this.state.anchorNa.xPos }
                                : undefined
                        }
                        classes={{
                            paper: classes.naviContainer,
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button
                                color="primary"
                                onClick={this.handleNavigateToMapClick}
                            >
                                Naviger til kort
                                <IconChevronRight />
                            </Button>
                        </div>
                    </Menu>

                    {this.props.config.printList !== 'hidden' && <Print
                        title={`Bevoksningsliste for ${this.props.customerList[this.props.selectedForest].forestName}${this.props.customerList[this.props.selectedForest].name !== "" ? ", " : ""}${this.props.customerList[this.props.selectedForest].name}`}
                        columns={this.state.rows}
                        data={this.state.listSummaryData}
                        summary={summary}
                        company={this.props.org ? this.props.org.name : ''}
                        printProfile={this.props.config.printProfile}
                        disabled={this.props.config.printList === 'disabled'}
                    />}

                    {this.props.config.downloadCSV !== 'hidden' && <Download
                        title={`Bevoksningsliste for ${this.props.customerList[this.props.selectedForest].forestName} ${this.props.customerList[this.props.selectedForest].name}`}
                        columns={this.state.rows}
                        data={this.state.listSummaryData}
                        disabled={this.props.config.downloadCSV === 'disabled'}
                    />}

                    <Tooltip title="Genload bevoksningsliste">
                        <IconButton aria-label="Reset list" onClick={this.handleResetList}>
                            <ReplayIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Luk bevoksningsliste">
                        <IconButton aria-label="Close list" onClick={this.handleCloseList}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </Toolbar>
        )
    }

    render() {
        const { classes } = this.props;
        const { rows, order, orderBy } = this.state;
        const header = this.props.customerList[this.props.selectedForest].forestName + (this.props.customerList[this.props.selectedForest].name !== "" ? ", " : "") + this.props.customerList[this.props.selectedForest].name;
        let content = <TableBody>
            {this.state.tableBodyContent}
        </TableBody>
        let contentNoData = null;
        if (this.state.listSummaryData.length === 0) {
            content = null
            contentNoData = <Typography style={{ padding: 16 }}>
                Listen har ingen indhold
            </Typography>
        }

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {this.renderToolbar(header)}

                    <div className={classes.tableWrapper} style={{ height: this.state.height - 150 }}>
                        <Table stickyHeader className={classes.table} aria-labelledby="bevoksningsliste">
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={this.state.listDataShow.length}
                                rows={rows}
                            />
                            {content}
                            {/* <TableBody>
                                {this.state.tableBodyContent}
                            </TableBody> */}
                        </Table>
                        {contentNoData}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            borderTop: '1px solid #E0E0E0',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 16,
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5
                        }}
                    >
                        {this.listSummary(this.state.listSummaryData)}
                    </div>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        speciesScheme: state.layout.speciesScheme,
        cells: state.maps.cells,
        currentAuthUser: state.user.currentAuthUser,
        selectedForest: state.customer.currentlySelectedForestId,
        customerList: state.customer.customerList,
        org: state.org.org,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        filterState: state.inventory.filterState,
        filterShowFilterInMap: state.inventory.showFilterInMap,
        filterCellIds: state.inventory.filterCellIds,
        config: state.appConfig.inventoryListForest,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetInventoryListActive: (s) => dispatch(actions.setInventoryListActive(s)),
        onSetFilterState: (s) => dispatch(actions.setFilterState(s)),
        onSetFilterCellIds: (s) => dispatch(actions.setFilterCellIds(s)),
        onSetShowFilterInMap: (s) => dispatch(actions.setShowFilterInMap(s)),
        onRedrawCells: () => dispatch(actions.redrawCells()),
    };
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(EnhancedTable)));
