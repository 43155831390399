import yieldTable from './yieldTables/YieldTable.json';
const speciesTable = require('../Utilities/updatedSpeciesList.json');

export const getSpecieParamFromHeight = (scheme, specie, height, quality) => {
    // Check input
    if (!("yieldTable" in speciesTable[specie])) {
        const errorMessage = {errText: "The specie has no associated yield table"};
        throw errorMessage;
    } 
    // Check for yield table compliance
    let yieldSpcType = speciesTable[specie].yieldTable;

    // Set parameters
    let warning = {type: 0, text: ''};
    const N = yieldTable[yieldSpcType][quality].height.length;
    let idx = 0;
    // Handle if height is out of bounds not found
    if (height > yieldTable[yieldSpcType][quality].height[N-1]) {
        idx = N-1;
        warning = {type: 1, text: 'Height above maximum. Returned data set to tables maximum values.'};
    } else if (height < yieldTable[yieldSpcType][quality].height[0]) {
        idx = 0;
        warning = {type: 2, text: 'Height below minimum. Returned data set to tables minimum values.'};
    } else {
        // Find index according to measured height - chose the one equalt to or above
        for (let i = 0; i < N; i++) {
            if (yieldTable[yieldSpcType][quality].height[i] >= height) {
                idx = i;
                break;
            }
        }
    }
    // Check if data is available in the yield table. Some species e.g., don't have formNum
    const diam = yieldTable[yieldSpcType][quality].diameter.length >= idx ? yieldTable[yieldSpcType][quality].diameter[idx] : '';
    const age = yieldTable[yieldSpcType][quality].age.length >= idx ? yieldTable[yieldSpcType][quality].age[idx] : '';
    const treeCount = yieldTable[yieldSpcType][quality].treeCount.length > idx ? yieldTable[yieldSpcType][quality].treeCount[idx] : '';
    const groundArea = yieldTable[yieldSpcType][quality].groundArea.length >= idx ? yieldTable[yieldSpcType][quality].groundArea[idx] : '';
    const formNum = yieldTable[yieldSpcType][quality].formNum.length >= idx ? yieldTable[yieldSpcType][quality].formNum[idx] : '';
    const vol = yieldTable[yieldSpcType][quality].volume.length >= idx ? yieldTable[yieldSpcType][quality].volume[idx] : '';
    // Find diameter, age and tree count from index and return
    // Diameter is in cm, age in years, treeCount in number pr. Ha
    return {
        warning: warning,
        diameter: diam,
        age: age,
        treeCount: treeCount,
        groundArea: groundArea,
        formNum: formNum,
        volume: vol
    }
}