import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';

// Material UI icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';

const styles = () => ({
    root: {
        width: '100%',
    },
    expansionPanelDetailsStyle: {
        padding: 0,
        position: 'relative',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '430px',
        overflow: 'auto',
    },
    expansionPanelSummaryExpandIcon: {
        marginRight: 0,
        maxHeight: 12,
        maxWidth: 12,
    },
});

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -10,
        top: 6,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        backgroundColor: '#5EBD3E',
        color: 'white',
    },
}))(Badge);

const EmbeddedExpansionList = (props) => {
    const [expanded, setExpanded] = React.useState(true);

    // const handleChange = panel => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    const handleChange = titel => () => {
        props.onPress(titel)
    };

    const expansionList = () => {
        return (
            <div className={props.classes.root}>
                <ExpansionPanel
                    style={{ marginBottom: 0, borderRadius: 0, boxShadow: 'none' }}
                    //expanded={expanded === 'panel1'}
                    expanded={props.expanded}
                    //onChange={handleChange('panel1')}
                    onChange={handleChange(props.title)}
                >
                    <ExpansionPanelSummary
                        style={{ paddingLeft: 0, paddingRight: 0, marginBottom: -10 }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{
                            expandIcon: props.classes.expansionPanelSummaryExpandIcon,
                        }}
                    >
                        <StyledBadge badgeContent={props.badgeCount}>
                            <Typography>
                                {props.title}
                            </Typography >
                        </StyledBadge>
                    </ExpansionPanelSummary>

                    <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0, }} />

                    <ExpansionPanelDetails className={props.classes.expansionPanelDetailsStyle}>
                        {props.children}
                    </ExpansionPanelDetails>

                    <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0, }} />
                </ExpansionPanel>
            </div>
        )
    }

    return (
        <div>
            {expansionList()}
        </div>
    )
};

EmbeddedExpansionList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmbeddedExpansionList);