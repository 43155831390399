import * as actionTypes from './actionTypes';
import firebase from 'firebase';

import moment from 'moment';

export const setCurrentlySelectedForestId = (forestId) => {
    return {
        type: actionTypes.SET_CURRENTLY_SELECTED_FOREST_ID,
        currentlySelectedForestId: forestId
    };
};

export const setUpdateCustomerIdListFlag = (state) => {
    return {
        type: actionTypes.SET_UPDATE_CUSTOMER_ID_LIST_FLAG,
        setUpdateCustomerIdListFlag: state
    };
};

export const setCustomerIdList = (idList) => {
    return {
        type: actionTypes.SET_CUSTOMER_ID_LIST,
        customerIdList: idList
    };
};

export const saveCustomerStart = () => {
    return {
        type: actionTypes.SAVE_CUSTOMER_START
    };
};

export const saveCustomerSuccess = () => {
    return {
        type: actionTypes.SAVE_CUSTOMER_SUCCESS
    };
};

export const unmountCustomerAction = () => {
    return {
        type: actionTypes.UNMOUNT_CUSTOMER,
    };
};

export const createNewForest = (customer, orgId) => async (dispatch) => {
    var db = firebase.firestore();
    var user = firebase.auth().currentUser;
    try {
        dispatch(saveCustomerStart());

        // Save forest
        // Check if forest is new or existing
        if (typeof customer.id === 'undefined') {
            const forestId = db.collection("forests").doc();
            await forestId.set({
                name: customer.name,
                forestName: customer.forestName,
                address: customer.address,
                city: customer.city,
                zip: customer.zip,
                id: forestId.id,
                phoneNumber: customer.phoneNumber ? customer.phoneNumber : '',
                email: customer.email ? customer.email : '',
                note: customer.note ? customer.note : '',
                date: moment().format(),
                creationDate: moment().format(),
            });
            // Append forestID to current user's list of forests
            const userId = db.collection("users").doc(user.uid);
            await userId.update({
                forests: firebase.firestore.FieldValue.arrayUnion(forestId.id)
            });

            // Add to org array
            if (orgId) {
                const refId = db.collection("org").doc(orgId);
                await refId.update({
                    forests: firebase.firestore.FieldValue.arrayUnion(forestId.id)
                });
            }
        } else {
            // Existing customer forest
            const forestId = db.collection("forests").doc(customer.id);
            await forestId.update({
                name: customer.name,
                forestName: customer.forestName,
                address: customer.address,
                city: customer.city,
                zip: customer.zip,
                phoneNumber: customer.phoneNumber ? customer.phoneNumber : '',
                email: customer.email ? customer.email : '',
                note: customer.note ? customer.note : '',
                date: moment().format(),
            });
        }
        dispatch(saveCustomerSuccess());
    } catch (error) {
        console.error('Save new customer error : ', error);
    }
};

export const attachCustomerToUserSuccess = () => {
    return {
        type: actionTypes.ATTACH_CUSTOMER_TO_USER_SUCCESS
    };
};

export const attachCustomerToUser = (forestId) => async (dispatch) => {
    var db = firebase.firestore();
    var user = firebase.auth().currentUser;

    try {
        // Append forestID to current user's list of forests
        const userId = db.collection("users").doc(user.uid);
        await userId.update({
            forests: firebase.firestore.FieldValue.arrayUnion(forestId)
        });
        dispatch(attachCustomerToUserSuccess());

    } catch (err) {
        console.error('Attach customer to user error : ', err);
    }
}

export const removeCustomerFromUserSuccess = (id) => {
    return {
        type: actionTypes.REMOVE_CUSTOMER_FROM_USER_SUCCESS,
        customerId: id,
    };
};

export const removeCustomerFromUser = (forestId) => async (dispatch) => {
    var db = firebase.firestore();
    var user = firebase.auth().currentUser;

    try {
        // Append forestID to current user's list of forests
        const userId = db.collection("users").doc(user.uid);
        await userId.update({
            forests: firebase.firestore.FieldValue.arrayRemove(forestId)
        });
        dispatch(removeCustomerFromUserSuccess(forestId));

    } catch (err) {
        console.error('Remove customer from user error : ', err);
    }
}

export const fetchCustomersSuccess = (customers, forestId) => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
        customerList: customers,
        id: forestId,
    };
};

export const fetchCustomersSuccessOrg = (customers, forestId) => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS_ORG,
        customerListOrg: customers,
        id: forestId,
    };
};

export const fetchCustomersFail = (error) => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_FAIL,
        error: error
    };
};

export const fetchCustomersFailOrg = (error) => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_FAIL_ORG,
        error: error
    };
};

export const fetchCustomersStart = () => {
    return {
        type: actionTypes.FETCH_CUSTOMERS_START
    };
};

export const fetchCustomers = (forestId, org) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    dispatch(fetchCustomersStart());
    // check for auth user
    if (user !== null) {
        let fetchedCustomer = null;
        try {
            db.collection("forests").doc(forestId).onSnapshot(function (doc) {
                fetchedCustomer = doc.data();
                if (org) {
                    dispatch(fetchCustomersSuccessOrg(fetchedCustomer, forestId));
                } else {
                    dispatch(fetchCustomersSuccess(fetchedCustomer, forestId));
                }
            });
        } catch (err) {
            console.error("Fetch Customers Error", err)
            dispatch(fetchCustomersFailOrg(err))
        }
    }
};

export const deleteCustomerSuccess = (id) => {
    return {
        type: actionTypes.DELETE_CUSTOMER_SUCCESS,
        customerId: id,
    }
}

export const deleteCustomerFail = (error) => {
    return {
        type: actionTypes.DELETE_CUSTOMER_FAIL,
        error: error
    };
};

export const deleteCustomerStart = () => {
    return {
        type: actionTypes.DELETE_CUSTOMER_START
    };
};

export const deleteCustomer = (forestId, orgId) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    // check for auth user
    if (user != null) {
        try {
            dispatch(deleteCustomerStart());
            // Remove forestID from current user's list of forests
            const userId = db.collection("users").doc(user.uid);
            userId.update({
                forests: firebase.firestore.FieldValue.arrayRemove(forestId.id)
            });
            // Remove forestID from org's list of forests
            const org = db.collection("org").doc(orgId);
            org.update({
                forests: firebase.firestore.FieldValue.arrayRemove(forestId.id)
            });

            dispatch(deleteCustomerSuccess(forestId.id));
        } catch (error) {
            console.error('Error :', error);
            dispatch(deleteCustomerFail(error));
        }
    }
}

export const setForestHasPayedSubscriptionStart = () => {
    return {
        type: actionTypes.SET_FOREST_HAS_PAYED_SUBSCRIPTION_START,
    }
}

export const setForestHasPayedSubscriptionSuccess = (forestId, state) => {
    return {
        type: actionTypes.SET_FOREST_HAS_PAYED_SUBSCRIPTION_SUCCESS,
        forestId: forestId,
        payedState: state,

    };
};

export const setForestHasPayedSubscriptionFail = (error) => {
    return {
        type: actionTypes.SET_FOREST_HAS_PAYED_SUBSCRIPTION_FAIL,
        error: error
    };
};

export const setForestHasPayedSubscription = (forestId, state) => async (dispatch) => {
    var db = firebase.firestore();
    dispatch(setForestHasPayedSubscriptionStart());
    try {
        const fId = db.collection('forests').doc(forestId);
        await fId.update({
            hasPayedSubscription: state
        })
        dispatch(setForestHasPayedSubscriptionSuccess(forestId, state));

    } catch (err) {
        dispatch(setForestHasPayedSubscriptionFail(err))
    }
}

export const unmountCustomer = () => {
    return dispatch => {
        dispatch(unmountCustomerAction());
    }
}