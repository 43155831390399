import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
// import ReactGA from 'react-ga';

// GetSteam
import { StreamApp } from 'react-activity-feed';
// import { StreamApp, FlatFeed, Activity, CommentList, InfiniteScrollPaginator, UserBar } from 'react-activity-feed';
// https://stackoverflow.com/questions/42101723/unable-to-preventdefault-inside-passive-event-listener
import 'react-activity-feed/dist/index.css';
import configGetStream from '../../backend/configGetStream';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';

// Components
import VideoBlock from './VideoBlock';
// import LikeAndCommentButtons from './LikeAndCommentButtons';
// import CommentField from './CommentField';
// import CommentItem from './CommentItem';
// import NewActivitiesNotification from './NewActivitiesNotification';
// import LoadMorePaginator from './LoadMorePaginator';
import * as actions from '../../store/actions/index';

// Graphics
// import logo from './skovkortet_logo_wo_text.svg';

const styles = () => ({

});

class ActivityFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: []
        };
    }

    componentDidMount() {
        fetch('https://www.googleapis.com/youtube/v3/search?key=AIzaSyDskQY_7s04pMzwyVO4awEzHXaXNqR-BvE&channelId=UCpoWr-fXWLqu5FwwWY1xJNw&part=snippet,id&order=date&maxResults=20')
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                videos: result
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                error
              });
            }
          )
      }

    callbackFunction = (childData) => {
        this.props.onSetNewNotifications(childData);
    }

    render() {
        const content = (this.props.currentAuthUser !== null && this.props.currentAuthUser.getStreamToken) ?
            <StreamApp
                apiKey={configGetStream.apiKey}
                appId={configGetStream.appId}
                token={this.props.currentAuthUser.getStreamToken}
            >
                <div style={{ marginBottom: -10 }}>
                    <VideoBlock
                        color='green'
                        title='Videoer'
                        snippet={'Intet illustrere en pointe bedre end videoer. Her er et udpluk af videoer beskriver hvordan produktet virker.\n\nSe mere på vores '}
                        videos={this.state.videos.items}
                    />

                    {/* <FlatFeed
                        notify
                        feedGroup="timeline"
                        Paginator={LoadMorePaginator}
                        Placeholder={() =>
                            <Card><CardContent><Typography variant="body1" style={{ color: '#717171', marginTop: 8 }}>
                                Ingen opslag at vise
                            </Typography></CardContent></Card>}
                        Notifier={(props) =>
                            <NewActivitiesNotification
                                labelPlural="opslag"
                                labelSingle="opslag"
                                parentCallback={this.callbackFunction}
                                {...props}
                            />}
                        Activity={(props) =>
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent>
                                    <Activity {...props}
                                        Header={() => (
                                            <div style={{ marginTop: -12 }}>
                                                <UserBar
                                                    username={'Skovkortet'}
                                                    avatar={logo}
                                                    timestamp={props.activity.time}
                                                    icon={props.icon}
                                                    Right={props.HeaderRight}
                                                />
                                                <Divider style={{ marginTop: 10 }} />
                                            </div>
                                        )}
                                        Footer={() => (
                                            <div style={{ marginBottom: -14 }}>
                                                <LikeAndCommentButtons {...props} />

                                                <Divider style={{ marginBottom: 4, marginTop: 4 }} />

                                                <CommentField
                                                    activity={props.activity}
                                                    onAddReaction={props.onAddReaction}
                                                    placeholder='Skriv en kommentar'
                                                    user={this.props.currentAuthUser}
                                                />

                                                <Divider style={{ marginTop: 12 }} />

                                                <CommentList
                                                    activityId={props.activity.id}
                                                    Paginator={InfiniteScrollPaginator}
                                                    CommentItem={CommentItem}
                                                />
                                            </div>
                                        )}
                                    />
                                </CardContent>
                            </Card>
                        }
                    /> */}
                </div>
            </StreamApp> : <Card><CardContent><Typography variant="body1" style={{ color: '#717171', marginTop: 8 }}>
                Indhold kunne ikke hentes
                </Typography></CardContent></Card>
        return (
            <div>
                {content}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        newNotifications: state.account.newNotifications,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetNewNotifications: (newNotifications) => dispatch(actions.setNewNotifications(newNotifications)),
    };
}

ActivityFeed.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActivityFeed)));