import * as actionTypes from './actionTypes';

export const setActivityFilterState = (filterState) => {
    return {
        type: actionTypes.SET_ACTIVITY_FILTER_STATE,
        filterState: filterState
    };
};

export const setActivityFilterObjectIds = (filterObjectIds) => {
    return {
        type: actionTypes.SET_ACTIVITY_FILTER_OBJECT_IDS,
        filterObjectIds: filterObjectIds
    };
};

export const setActivityShowFilterInMap = (state) => {
    return {
        type: actionTypes.SET_ACTIVITY_SHOW_FILTER_IN_MAP,
        showFilterInMap: state
    };
};