import env from './buildConfig';

// ... Dev ...
let configGoogleAnalytics = {
    id: 'UA-140449654-2'
};

// ... Prod ...
if (env.stage === "production") {
    configGoogleAnalytics = {
        id: 'UA-140449654-1'
    };
}

export default configGoogleAnalytics;