// Import functionality
import { getSpecieCategories } from '../../../../../Inventory/Utilities/speciesUtil';

// Set parameters
const OUT_CRS = "urn:ogc:def:crs:OGC:1.3:CRS84";

// Export functions
export const forestConvertToJson = (forestMeta, forestFeatures, forestPoints, activeLayers) => {
    // Check on inputs
    const categories = activeLayers.map(el => el.layerName);

    // Create json object
    let jsonObj = setupJsonObj(forestMeta);

    // Insert features
    const features = convertFeatures(forestFeatures, forestPoints, categories);

    // Form full Json object
    jsonObj.features = features;

    // Return object
    return jsonObj;
}

export const huntingConvertToJson = (huntingFeatures, huntingPoints, filter) => {

}

export const biotopePlanConvertToJson = (biotopPlanMeta, biotopeFeatures, biotopePoints) => {
    // Create json object
    let jsonObj = setupBiotopJsonObj(biotopPlanMeta);

    // Insert features
    const features = convertBiotopFeatures(biotopeFeatures, biotopePoints);
    // Form full Json object
    jsonObj.features = features;

    // Return object
    return jsonObj;

}

export const notesToJson = (notes, filter) => {

}

// Internal functions
const setupJsonObj = (forestMeta) => {
    // Filter out some data points
    const { HD_blok_id, emailForester, foresterId, hasPayedSubscription, imgUrl, ...data } = forestMeta;
    const jsonObj = {
        "type": "FeatureCollection",
        "name": data.forestName,
        "crs": {
            "type": "name",
            "properties": {
                "name": OUT_CRS,
            }
        },
        "properties": {
            ...data
        },
        "features": []
    }
    return jsonObj;
}

const setupBiotopJsonObj = (metaData) => {
    // Filter out some data points
    const jsonObj = {
        "type": "FeatureCollection",
        "name": metaData.planName,
        "crs": {
            "type": "name",
            "properties": {
                "name": OUT_CRS,
            }
        },
        "properties": {
            ...metaData
        },
        "features": []
    }
    return jsonObj;
}

const convertFeatures = (features, points, categories) => {
    let featuresOut = [];
    let pointsOut = [];
    let markNr = 0;
    // Run through features (polygons and lines)
    features.forEach(feat => {
        // Check category based on species type
        if (checkCategories(feat, categories)) {
            // Convert to GeoJSON format
            const featJson = convertCellToGeoJsonFeature(feat.cellData, markNr, 'forest');
            featuresOut.push(featJson)
            markNr += 1;
        }
    })
    // Run through points
    points.forEach(point => {
        if (checkCategories(point, categories)) {
            const pointJson = converPointToGeoJsonFeature(point, 'forest');
            pointsOut.push(pointJson)
        }
    })

    return [...featuresOut, ...pointsOut];
}

const convertBiotopFeatures = (features, points) => {
    let featuresOut = [];
    let pointsOut = [];

    // Run through features (polygons and lines)
    features.forEach(feat => {
        const featJson = convertCellToGeoJsonFeature(feat.cellData, 0, 'biotopPlan');
        featuresOut.push(featJson)
    })

    // Run through points
    points.forEach(point => {
        const pointJson = converPointToGeoJsonFeature(point, 'biotopPlan');
        pointsOut.push(pointJson)
    })

    return [...featuresOut, ...pointsOut];
}

const checkCategories = (feat, categories) => {
    // Check for static category
    let staticCat = null;
    if (feat.cellData) {
        staticCat = getSpecieCategories(feat.cellData.species);
    } else {

    }
    if (staticCat && categories.some(cat => staticCat.includes(cat))) return true;
    // Check if data includes categories. If not, the feature is assumed to be production.
    let featCat = feat.categories ? feat.categories : ['production'];
    if (categories.some(cat => featCat.includes(cat))) return true;
    // Return false if feature is not in the categories array
    return false;
}

const convertCellToGeoJsonFeature = (data, markNr, type) => {
    let feature = {};
    // Pull out coordinates for outerrim and holes from the data object
    const { lnglat, holes, age, ...properties } = data;

    // Form coordinates object
    let outerRimCoor = [];
    let holesCoor = [];
    // Outer rim
    for (let i = 0; i < lnglat.length; i = i + 2) {
        outerRimCoor.push([lnglat[i], lnglat[i + 1]]);
    }
    if (data.type === "Polygon") {
        const lastIdx = outerRimCoor.length - 1;
        if (outerRimCoor[0][0] !== outerRimCoor[lastIdx][0] || outerRimCoor[0][1] !== outerRimCoor[lastIdx][1]) {
            outerRimCoor.push([outerRimCoor[0][0], outerRimCoor[0][1]]);
        }
    }
    // Holes
    const hArray = Object.values(holes);
    for (let i = 0; i < hArray.length; i++) {
        let hole = [];
        if (hArray[i].length > 2) {
            for (let j = 0; j < hArray[i].length; j = j + 2) {
                hole.push([hArray[i][j], hArray[i][j + 1]])
            }
            const lastIdx = hole.length - 1;
            if (hole[0][0] !== hole[lastIdx][0] || hole[0][1] !== hole[lastIdx][1]) {
                hole.push([hole[0][0], hole[0][1]]);
            }
            holesCoor.push(hole)
        }
    }

    // Set feature type
    let feattype = "Polygon";
    let coords = [];
    if (data.type === "Line") {
        feattype = "LineString";
        coords = outerRimCoor; // outerrim represents the line
    } else if (data.type = "Polygon") {
        feattype = "Polygon";
        coords = [outerRimCoor, ...holesCoor]; // Cat outer rim and holes
    }

    // Form geometry object
    // Remove unwanted fields from original object:
    let newProperties = {};
    if (type === 'biotopPlan') {
        let { dashStyle, environment, biotopePlanId, type, weight, color, ...newProps } = properties;
        newProperties = newProps;
    } else {
        let { kwSync, lidarHeight, dashStyle, environment, forestId, kwData, diameter, coverage, mass, formNum, volume, mixpct, quality, treeCount, caution, height, type, weight, assesmentMethod, color, fillColor, ...newProps } = properties;
        // let newProps = properties;
        if (type === "Polygon") {
            newProps = { ...newProps, MARKNUMMER: markNr };
        }
        newProperties = newProps;
    }

    feature = {
        "type": "Feature",
        "properties": newProperties,
        "geometry": {
            "type": feattype,
            "coordinates": coords
        }
    }
    return feature;
}

const converPointToGeoJsonFeature = (point, type) => {
    // Get properties from point data
    let newProperties = {};
    let LatLng = [];
    if (type === 'biotopPlan') {
        const { kwSync, latlng, planId, environment, from, historyList, images, ...props } = point;
        LatLng = [latlng.lng, latlng.lat];
        newProperties = props;
    } else {
        const { kwSync, latlng, forestId, environment, ...props } = point;
        LatLng = [latlng.lng, latlng.lat];
        newProperties = props;
    }
    
    // Form feature object
    const feature = {
        "type": "Feature",
        "properties": newProperties,
        "geometry": {
            "type": "Point",
            "coordinates": LatLng
        }
    }
    return feature;
}