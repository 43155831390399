import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';

const TipMenu = (props) => {
    return (
        <div style={{ backgroundColor: '#EFEFEF', padding: '4px 8px', borderRadius: 5 }}>
            <Typography
                style={{ fontSize: 13, fontWeight: 500 }}
            >
                {props.title}
            </Typography>

            <Typography
                variant="caption"
                style={{whiteSpace: 'pre-line'}}
            >
                {props.content}
            </Typography>
        </div>
    );
}

export default TipMenu;