import React from 'react';
import moment from 'moment';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';

// Material UI Icons
import NoteDownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import NewNoteIcon from '@material-ui/icons/InsertDriveFileRounded';
import NoteIcon from '@material-ui/icons/InsertDriveFileOutlined';

// Components
import DeleteButton from '../Buttons/DeleteButton';

const useStyles = makeStyles(() => ({
    listItemAvatarRoot: {
        minWidth: 38,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
}));

const listRow = (props, document, divider, classes) => {
    // Set action menu
    const items = [
        {
            name: "Slet",
            text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
            onClickHandler: () => props.onDeleteDocument(document),
            buttonIcon: true
        },
    ]
    const prim =
        <Typography
            variant="subtitle1"
            style={{ fontWeight: document.read ? null : 500 }}
        >
            {document.fileName}
        </Typography>

    const sec =
        <Typography
            variant="caption"
            style={{ color: '#747474', fontWeight: document.readState ? null : 500 }}
        >
            {moment(document.creationDate).format('DD[-]MM[-]YYYY HH:mm')}
        </Typography>

    return (
        <ListItem
            divider={divider}
            style={{ paddingLeft: 0 }}
            button={true}
            onClick={() => props.onOpenDocument(document)}
        >
            <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
                {!document.readState && <NewNoteIcon style={{ color: '#2e2f30' }} />}
                {document.readState && <NoteIcon style={{ color: '#747474' }} />}

                {!document.readState && <Typography
                    variant="caption"
                    align="center"
                    style={{ fontWeight: 500 }}
                >
                    Ny
                </Typography>}
            </ListItemAvatar>

            <ListItemText style={{ maxWidth: '80%' }}
                primary={prim}
                secondary={sec}
            />

            <ListItemSecondaryAction style={{ marginRight: -16, padding: 0 }}>
                <div style={{ display: 'flex', alignItems: 'center' }} >
                    <IconButton aria-label="download" size="small">
                        <NoteDownloadIcon onClick={() => props.onOpenDocument(document)} style={{ color: '#5EBD3E' }} />
                    </IconButton>

                    <Tooltip title="Slet et dokument">
                        <DeleteButton
                            size="small"
                            items={items}
                            deleteLoading={props.loading}
                            disabled={false}
                        />
                    </Tooltip>
                </div>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

const documentsList = (props, documents, classes) => {
    // Sort list by date
    let sortedDocuments = documents.sort((a, b) => Date.parse(new Date(b.creationDate)) - Date.parse(new Date(a.creationDate)))
    const list = sortedDocuments.map((document, i) => {
        let divider = i < sortedDocuments.length - 1;
        
        if (document && document.fileName) {
            return (
                <div key={i}>
                    {listRow(props, document, divider, classes)}
                </div>
            )
        } else {
            return null;
        }

    });
    
    if (list.length !== 0) {
        return list;
    } else {
        return (
            <Typography style={{ padding: 8, color: '#747474' }}>
                Mappen er tom
            </Typography>
        )
    }
}

export default function FilesList(props) {
    const classes = useStyles();

    return (
        <div style={{ maxHeight: 400, overflow: 'auto' }}>
            <List dense={true} style={{ padding: 0 }}>
                {documentsList(props, props.documents, classes)}
            </List>
        </div >
    );
}