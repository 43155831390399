import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI icons
import GoBackIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

// Moment
import moment from 'moment';

// Components
import ChangeSaveButton from '../UI/Buttons/ChangeSaveButton';
import DeleteButton from '../UI/Buttons/DeleteButton';
import OutlinedButton from '../UI/Buttons/OutlinedButton';
import * as actions from '../../store/actions/index';

const styles = theme => ({
    root: {
        textAlign: 'left',
    },
    textField: {
        width: '100%',
    },
    card: {
        position: 'relative',
        margin: 'auto',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    nonEditTextStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

export class BiotopePlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            name: ("name" in this.props && this.props.name !== undefined) ? this.props.name : '',
            planName: ("planName" in this.props && this.props.planName !== undefined) ? this.props.planName : '',
            planId: ("id" in this.props) ? this.props.id : '',
            address: ("address" in this.props && this.props.address !== undefined) ? this.props.address : '',
            city: ("city" in this.props && this.props.city !== undefined) ? this.props.city : '',
            zip: ("zip" in this.props && this.props.zip !== undefined) ? this.props.zip : '',
            phoneNumber: ("phoneNumber" in this.props && this.props.phoneNumber !== undefined) ? this.props.phoneNumber : '',
            email: ("email" in this.props && this.props.email !== undefined) ? this.props.email : '',
            planType: ("planType" in this.props && this.props.planType !== undefined) ? this.props.planType : '1 årig',
            naturePlan: ("naturePlan" in this.props && this.props.naturePlan !== undefined) ? this.props.naturePlan : false,
            planYear: ("planYear" in this.props && this.props.planYear !== undefined) ? this.props.planYear : '',
            cultivatedArea: ("cultivatedArea" in this.props && this.props.cultivatedArea !== undefined) ? this.props.cultivatedArea : '',
            locked: ("locked" in this.props && this.props.locked !== undefined) ? this.props.locked : false,
            change: this.props.planName ? false : true,
            submit: false,
            newPlanState: this.props.planName ? false : true,
            saveLoading: false,
            deleteLoading: false,
            disabled: false//("locked" in this.props && this.props.locked) ? true : false,
        };
    }

    componentDidUpdate() {
        // Save navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.successBiotopePlan) {
            this.setState({
                change: false,
                saveLoading: false,
                disabled: false
            })

            // Route back
            this.props.history.replace({
                pathname: "/",
                state: {
                    prevPage: this.props.location.state.prevPage,
                }
            });
        }

        // Delete navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.deleteBiotopePlan) {
            this.setState({
                change: false,
                deleteLoading: false,
                disabled: false
            })

            this.goBackClickHandler();
        }
    }

    componentWillUnmount() {
        // Show snackbar if customer is saved
        if (this.props.successBiotopePlan) {
            this.props.onEnqueueSnackbar({
                message: 'Biotopplan er nu gemt!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            // Add event to log
            const eD = {
                event: this.props.id ? "Biotopplan gemt" : "Ny biotopplan oprettet",
                type: "Biotopplan",
                reference: this.state.name,
                private: false,
                planName: this.state.planName,
                userId: this.props.currentAuthUser.id,
                userName: this.props.currentAuthUser.name,
            }
            this.props.onAddEventToEventLog(this.props.id ? this.props.id : 'new', eD)
        }

        // Show snackbar if note is deleted
        if (this.props.deleteBiotopePlan) {
            this.props.onEnqueueSnackbar({
                message: 'Biotopplan er nu slettet!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            // Add event to log
            const eD = {
                event: "Biotopplan slettet",
                type: "Biotopplan",
                reference: this.state.name,
                private: false,
                forestName: this.state.planName,
                userId: this.props.currentAuthUser.id,
                userName: this.props.currentAuthUser.name,
            }
            this.props.onAddEventToEventLog(this.props.id, eD)
        }

        // Set reducer to initial state
        this.props.onUnmountBiotopePlan();
    }

    goBackClickHandler = () => {
        // Choose which route to go back to
        this.props.history.replace({
            pathname: "/",
            state: {
                prevPage: this.props.location.state.prevPage,
            }
        });
    }

    onDeleteClickHandler = () => {
        // Delete biotope plan on cloud
        this.props.onDeleteBiotopePlan(this.props.id, this.props.currentAuthUser.org);
        // Set selected forest to null if the selected forest is the one deleted
        if (this.props.id === this.props.selectedBiotopePlan) {
            this.props.onSetCurrentlySelectedBiotopePlanId(null);
        }

        // Send event to Analytics
        ReactGA.event({
            category: 'BiotopePlan',
            action: 'Biotope plan deleted',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        this.setState({
            deleteLoading: true,
            disabled: true,
        })
    }

    duplicateFullPlan = () => {
        const { name, planName, address, city, zip, phoneNumber, email, planType, planYear, naturePlan, cultivatedArea } = this.state;
        const { orgId, bbox } = this.props;
        // Set states for loading spinners, etc
        this.setState({
            saveLoading: true,
            disabled: true,
        })
        // Upload action
        const planData = {
            name,
            planName: planName + " (kopi)",
            address,
            city,
            zip,
            phoneNumber,
            email,
            planType,
            naturePlan,
            planYear,
            cultivatedArea,
            bbox,
            date: moment().format(),
            locked: false,
            copy: {
                copyDate: moment().format(),
                originalPlanId: this.props.id,
            }
        }
        this.props.onSaveBiotopePlan(planData, orgId, this.props.id);
        // Send event to Analytics
        ReactGA.event({
            category: 'BiotopePlan',
            action: 'Full biotope plan duplicated',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    duplicatePlanBorder = () => {
        const { name, planName, address, city, zip, phoneNumber, email, planType, planYear, naturePlan, cultivatedArea } = this.state;
        const { orgId, bbox } = this.props;
        this.setState({
            saveLoading: true,
            disabled: true,
        })
        // Upload action
        const planData = {
            name,
            planName: planName + " (kopi)",
            address,
            city,
            zip,
            phoneNumber,
            email,
            planType,
            naturePlan,
            planYear,
            cultivatedArea,
            bbox,
            date: moment().format(),
            locked: false,
            copy: {
                copyDate: moment().format(),
                originalPlanId: this.props.id,
            }
        }
        this.props.onSaveBiotopePlan(planData, orgId, this.props.id, "Afgrænsning");
        // Send event to Analytics
        ReactGA.event({
            category: 'BiotopePlan',
            action: 'Biotope plan border duplicated',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    changeSaveButton = () => {
        const { name, planName, address, city, zip, phoneNumber, email, planType, planYear, naturePlan, cultivatedArea, change } = this.state;
        const { orgId, bbox } = this.props;

        // Upload customer to cloud 
        if (change) {
            // Set submit state
            this.setState({
                submit: true
            })

            // Check if customer is incomplete
            if (name !== "" && planName !== "" && address !== "" && city !== "" && zip !== "" && planType !== "" && planYear !== "" && naturePlan !== "" && cultivatedArea !== "") {
                this.setState({
                    saveLoading: true,
                    disabled: true,
                })

                // Upload action
                const planData = {
                    name,
                    planName,
                    address,
                    city,
                    zip,
                    phoneNumber,
                    email,
                    planType,
                    naturePlan,
                    planYear,
                    cultivatedArea,
                    bbox: bbox ? bbox : [],
                    date: moment().format(),
                    id: this.props.id,
                    locked: this.state.locked,
                }
                this.props.onSaveBiotopePlan(planData, orgId);

                // Send event to Analytics
                ReactGA.event({
                    category: 'BiotopePlan',
                    action: 'Biotope plan created',
                    label: `User: ${this.props.currentAuthUser.name}`
                });

                // Switch states
                this.setState({
                    submit: false,
                })
            }

            // Make customer editable
        } else {
            this.setState({
                change: true
            })
        }
    };

    title() {
        // When note is in editable state 
        if (this.state.change) {
            return (
                <div>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
                    >
                        <IconButton
                            onClick={this.goBackClickHandler}
                            style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                        >
                            <GoBackIcon />
                        </IconButton>

                        <Typography
                            variant="h5"
                            gutterBottom
                        >
                            Biotopplan
                        </Typography >
                    </div>
                </div>
            );
        }

        // When note is in non-editable state
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
            >
                <IconButton
                    onClick={this.goBackClickHandler}
                    style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                >
                    <GoBackIcon />
                </IconButton>

                <Typography
                    variant="h5"
                    gutterBottom
                >
                    {this.state.name}
                </Typography >
            </div>
        );
    };

    handlePlanTypeChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    planType = () => {
        const { classes } = this.props;
        if (this.state.change) {
            const items = <Select
                value={this.state.planType}
                onChange={this.handlePlanTypeChange}
                inputProps={{
                    name: 'planType',
                    id: 'planType-simple',
                }}
            >
                <MenuItem value={'1 årig'}><div className={classes.speciesRow}><div className={classes.lineItem} />1 årig</div></MenuItem>
                <MenuItem value={'3 årig'}><div className={classes.speciesRow}><div className={classes.lineItem} />3 årig</div></MenuItem>
                <MenuItem value={'5 årig'}><div className={classes.speciesRow}><div className={classes.lineItem} />5 årig</div></MenuItem>
            </Select>

            return (
                <form className={classes.root} style={{ marginTop: 16 }} autoComplete="off">
                    <FormControl
                        error={(this.state.planType === "" && this.state.submit)}
                        fullWidth
                        required
                        disabled={this.props.locked}
                    >
                        <InputLabel htmlFor="specie-simple">Biotopplanstype</InputLabel>
                        {items}
                    </FormControl>
                </form>
            )
        }
        if (this.state.planType !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Biotopplanstype
                    </Typography >
                    <div>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {this.state.planType + ((this.state.planType === '1 årig' || this.state.planType === '3 årig') && this.state.naturePlan ? ", med naturplan" : ", uden naturplan")}
                        </Typography >
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    naturePlan = () => {
        if (this.state.planType === '1 årig' || this.state.planType === '3 årig') {
            if (this.state.change) {
                return (
                    <FormControlLabel
                        style={{ marginTop: 24 }}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ marginLeft: 8 }}
                                checked={this.state.naturePlan}
                                disabled={this.props.locked}
                                onClick={() => { this.setState({ naturePlan: !this.state.naturePlan }) }}
                            />
                        }
                        label="Naturplan for ejendom"
                    />
                )
            }
        } else {
            return null;
        }
    }

    lockPlan = () => {
        if (this.state.change) {
            return (
                <FormControlLabel
                    style={{ marginTop: 24 }}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ marginLeft: 8 }}
                            checked={this.state.locked}
                            disabled={this.props.locked}
                            onClick={() => { this.setState({ locked: !this.state.locked }) }}
                        />
                    }
                    label="Lås plan for periode"
                />
            )
        } else {
            return null;
        }
    }


    textField(type, key, label, content, errorEnabled, required, unit, changeDisabled, changeShow) {
        const { classes } = this.props;
        if (this.state.change && changeShow === 'hidden') return null;

        // When profile is in editable state
        if (this.state.change && !changeDisabled) {
            return (
                <TextField
                    id={label}
                    label={label}
                    className={classes.textField}
                    value={content}
                    type={type}
                    disabled={this.props.locked}
                    onChange={event => this.setState({ [key]: event.target.value })}
                    onWheelCapture={e => { e.target.blur() }}
                    InputProps={{ endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : null }}
                    error={this.state[key] === "" && this.state.submit && errorEnabled}
                    required={required === 'required' ? true : false}
                    margin="normal"
                />
            );
        }

        // When profile is in non-editable state
        if (key !== 'name') {
            if (content !== '') {
                return (
                    <div>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            {label}
                        </Typography >
                        <div className={classes.nonEditTextStyle}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {content}
                            </Typography >
                            {unit && <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ marginBottom: 8 }}
                            >
                                {unit}
                            </Typography >}
                        </div>
                    </div>
                );
            }
        }
    };

    renderContent() {
        const { name, planName, planId, address, city, zip, phoneNumber, email, planYear, cultivatedArea } = this.state;
        let errorEnabled = true;

        return (
            <div>
                {this.title()}
                {this.textField('text', 'name', 'Navn', name, errorEnabled, 'required')}
                {this.textField('text', 'planName', 'Biotopplan', planName, errorEnabled, 'required')}
                {this.textField('text', 'planId', ' Plan ID', planId, false, '', null, true, 'hidden')}
                {this.textField('text', 'address', 'Adresse', address, errorEnabled, 'required')}
                {this.textField('text', 'zip', 'Postnummer', zip, errorEnabled, 'required')}
                {this.textField('text', 'city', 'By', city, errorEnabled, 'required')}
                {this.textField('text', 'phoneNumber', 'Telefonnummer', phoneNumber)}
                {this.textField('text', 'email', 'Email', email)}
                {this.planType()}
                {this.textField('number', 'planYear', 'Etableringsår', planYear, errorEnabled, 'required')}
                {this.textField('number', 'cultivatedArea', 'Dyrket areal', cultivatedArea, errorEnabled, 'required', 'ha')}
                {this.naturePlan()}
                {this.lockPlan()}
            </div>
        );
    }

    planCancelDelete() {
        // When note is in non-editable state
        if (!this.state.change) {

        }
        // Set action menu items
        const items = [
            {
                name: "Dupliker",
                text: "Du er ved at duplikere biotopplanen. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.duplicateFullPlan,
                buttonIcon: false
            },
            {
                name: "Dupliker afgrænsning",
                text: "Du er ved at duplikere biotopplanens afgrænsningen. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.duplicatePlanBorder,
                buttonIcon: false
            },
            {
                name: "Slet",
                text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.onDeleteClickHandler,
                buttonIcon: true
            },

        ];
        // When note is in editable state
        return (
            <DeleteButton
                items={items}
                deleteLoading={this.state.deleteLoading || this.state.saveLoading}
                disabled={this.state.disabled}
            />
        );
    }

    customerChangeSave() {
        let disableButton = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skovejer"].includes(tag))) {
            disableButton = true;
        }
        // When customer is in editable state
        if (this.state.change) {
            return (
                <div style={{ display: 'flex' }}>
                    <OutlinedButton
                        buttonLabel='Annuller'
                        onPress={this.goBackClickHandler}
                        variant='text'
                        discrete={true}
                    />
                    <ChangeSaveButton
                        change={false}
                        onPress={this.changeSaveButton.bind(this)}
                        saveLoading={this.state.saveLoading}
                        disabled={this.state.disabled}
                    />
                </div>
            );
        }

        // When customer is in non-editable state
        return (
            <div>
                <ChangeSaveButton
                    change={true}
                    onPress={this.changeSaveButton.bind(this)}
                    saveLoading={this.state.saveLoading}
                    disabled={this.state.disabled || disableButton}
                />
            </div>
        );
    }

    handleChangeCustomer() {
        // Check if forest is already part of customers list
        if (this.props.biotopePlansUser) {
            if (Object.keys(this.props.biotopePlansUser).indexOf(this.props.id) === -1) {
                // Not part of, so attach
                this.props.onAttachBiotopePlanToUser(this.props.id);
            } else {
                // Part of, so remove
                this.props.onRemoveBiotopePlanFromUser(this.props.id);
                if (this.props.id === this.props.selectedBiotopePlan) {
                    this.props.onSetCurrentlySelectedBiotopePlanId(null);
                }
            }
        } else {
            this.props.onAttachBiotopePlanToUser(this.props.id);
        }
    }

    render() {
        const { classes } = this.props;
        const { title, text, change, newPlanState } = this.state;

        // Check if biotope plan is part of users list
        let checked = false;
        if (this.props.biotopePlansUser) {
            if (Object.keys(this.props.biotopePlansUser).indexOf(this.props.id) !== -1) {
                checked = true;
            }
        }
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        {this.renderContent()}
                    </CardContent>

                    <CardActions>
                        <div>
                            {!this.state.newPlanState && this.props.orgId && <FormControlLabel
                                style={{ marginBottom: 8 }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        style={{ marginLeft: 16 }}
                                        checked={checked}
                                        onClick={this.handleChangeCustomer.bind(this)}
                                        disabled={this.props.networkStatus === 'offline'}
                                    />
                                }
                                label="Tilføj til mine aktive biotopplaner"
                            />}

                            <div style={{ display: 'flex' }}>
                                {this.customerChangeSave()}
                                {!newPlanState && change && title !== "" && text !== "" && this.planCancelDelete()}
                                {/* {!newPlanState && !change && this.inviteCustomer()} */}
                            </div>
                        </div>
                    </CardActions>
                </Card>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        networkStatus: state.layout.networkStatus,
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        orgId: state.org.orgId,
        successBiotopePlan: state.biotopePlans.success,
        deleteBiotopePlan: state.biotopePlans.delete,
        selectedBiotopePlan: state.biotopePlans.currentlySelectedBiotopePlanId,
        biotopePlansUser: state.biotopePlans.biotopePlansUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveBiotopePlan: (planData, orgId, originId, filter) => dispatch(actions.saveBiotopePlan(planData, orgId, originId, filter)),
        onAttachBiotopePlanToUser: (planId) => dispatch(actions.attachBiotopePlanToUser(planId)),
        onRemoveBiotopePlanFromUser: (planId) => dispatch(actions.removeBiotopePlanFromUser(planId)),
        onUnmountBiotopePlan: () => dispatch(actions.unmountBiotopePlan()),
        onDeleteBiotopePlan: (planId, orgId) => dispatch(actions.deleteBiotopePlan(planId, orgId)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onSetCurrentlySelectedBiotopePlanId: (planId) => dispatch(actions.setCurrentlySelectedBiotopePlanId(planId)),
        onAddEventToEventLog: (forestId, data) => dispatch(actions.addEventToEventLog(forestId, data)),
    };
}

BiotopePlan.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BiotopePlan)));