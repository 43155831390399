import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Icons
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import MoreVertIcon from '@material-ui/icons/MoreVertRounded';

// Components
import OutlinedButton from './OutlinedButton';

// Styles
const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});

export class DeleteButton extends React.Component {
    state = {
        text: null,
        onClickHandler: null,
        open: false,
        anchorEb: null,
        closeAfterLoading: false,
    };

    componentDidUpdate() {
        if (!this.props.deleteLoading && this.state.closeAfterLoading && this.state.open) {
            this.setState({ open: false, closeAfterLoading: false })
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            anchorEb: null
        });
    };

    handleClick = (props) => {
        this.setState({
            text: props.text,
            onClickHandler: props.onClickHandler,
            buttonText: props.name,
            buttonIcon: props.buttonIcon,
            open: true,
            anchorEb: null,
        });
    };

    handleMenuClick = event => {
        this.setState({ anchorEb: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEb: null });
    };

    render() {
        const { classes, deleteLoading, disabled } = this.props;
        const { open } = this.state;

        // Setup menu items
        const items = this.props.items.map(item => {
            return <MenuItem onClick={() => this.handleClick(item)}>{item.name}</MenuItem>
        })

        return (
            <div className={classes.root}>
                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>
                        Bekræft handling
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            {this.state.text}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Annuller'
                                onPress={this.handleClose}
                                variant='text'
                                discrete={true}
                            />

                            <OutlinedButton
                                buttonLabel={this.state.buttonText}
                                buttonIcon={this.state.buttonIcon && <DeleteIcon
                                    className={classes.extendedIcon}
                                    style={{ color: disabled ? '#c5c5c5' : '#e75f5f' }}
                                />}
                                iconLeft={true}
                                onPress={() => { this.state.onClickHandler(); this.setState({ closeAfterLoading: true }) }}
                                loading={deleteLoading}
                                deleteBtn={this.state.buttonIcon ? true : false}
                                color='primary'
                            />
                        </div>
                    </DialogActions>
                </Dialog>

                <div>
                    <IconButton
                        aria-label="more"
                        onClick={this.handleMenuClick}
                        disabled={disabled}
                        size={(this.props.size && this.props.size === "small") ? "small" : "medium"}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </div>

                <Menu
                    id="simple-menu"
                    keepMounted
                    anchorEl={this.state.anchorEb}
                    open={Boolean(this.state.anchorEb)}
                    onClose={this.handleMenuClose}
                >
                    {items}
                </Menu>
            </div>
        );
    }
}

DeleteButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteButton);