import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Material UI Icons
import PrintIcon from '@material-ui/icons/PrintRounded';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabledRounded';

// Import logos
import { logoHD2 as logoHD, logoSD2 as logoSD } from "./logos";

// Styling
const styles = () => ({});

// Object
class Print extends React.Component {
    state = {
        columnStyles: {},
        columnCount: 0,
    };

    componentWillMount() {
        this.setColumnStyle(this.props.columns.length);
    }

    componentDidUpdate() {
        if (this.props.columns.length !== this.state.columnCount) {
            this.setColumnStyle(this.props.columns.length);
        }
    }

    setColumnStyle = (columnCount) => {
        this.setState({columnCount: columnCount});

        // Skovejeren-gratis = ingen print
        // Skovejeren-basis = 14
        // Skovejeren-udvidet = 17
        // Skovforvalteren-gratis = ingen print
        // Skovforvalteren-udvidet = 16 
        // Skovforvalteren-udvidet-co2 = 17

        switch (columnCount) {
            // case 13: // ok
            //     return this.setState({
            //         columnStyles: {
            //             0: { minCellWidth: 27, halign: 'right' }, //Afd
            //             1: { minCellWidth: 50 }, //Litra
            //             2: { minCellWidth: 54 }, //Anvendelse
            //             3: { minCellWidth: 80 }, //Træart
            //             4: { minCellWidth: 43, halign: 'right' }, //Årstal
            //             5: { minCellWidth: 50, halign: 'right' }, //Længde
            //             6: { minCellWidth: 50, halign: 'right' }, //Areal
            //             7: { minCellWidth: 32, halign: 'right' }, //Indb.
            //             8: { minCellWidth: 42, halign: 'right' }, //Højde
            //             9: { minCellWidth: 38, halign: 'right' }, //Diameter
            //             10: { minCellWidth: 50, halign: 'right' }, //Stamtal
            //             11: { minCellWidth: 40, halign: 'right' }, //Vedm
            //             12: { minCellWidth: 60 } //Bemærk.
            //         }
            //     });
            case 14: // ok
                return this.setState({
                    columnStyles: {
                        0: { minCellWidth: 27, halign: 'right' }, //Afd
                        1: { minCellWidth: 50 }, //Litra
                        2: { minCellWidth: 54 }, //Anvendelse
                        3: { minCellWidth: 80 }, //Træart
                        4: { minCellWidth: 43, halign: 'right' }, //Årstal
                        5: { minCellWidth: 50, halign: 'right' }, //Længde
                        6: { minCellWidth: 50, halign: 'right' }, //Areal
                        7: { minCellWidth: 32, halign: 'right' }, //Indb.
                        8: { minCellWidth: 42, halign: 'right' }, //Højde
                        9: { minCellWidth: 38, halign: 'right' }, //Diameter
                        10: { minCellWidth: 50, halign: 'right' }, //Stamtal
                        11: { minCellWidth: 40, halign: 'right' }, //Vedm
                        12: { minCellWidth: 40, halign: 'right' }, //CO2-e
                        13: { minCellWidth: 60 } //Bemærk.
                    }
                });
            case 16: // ok
                return this.setState({
                    columnStyles: {
                        0: { minCellWidth: 27, halign: 'right' }, //Afd
                        1: { minCellWidth: 50 }, //Litra
                        2: { minCellWidth: 54 }, //Anvendelse
                        3: { minCellWidth: 80 }, //Træart
                        4: { minCellWidth: 43, halign: 'right' }, //Årstal
                        5: { minCellWidth: 55, halign: 'right' }, //Udtyndet
                        6: { minCellWidth: 50, halign: 'right' }, //Længde
                        7: { minCellWidth: 50, halign: 'right' }, //Areal
                        8: { minCellWidth: 32, halign: 'right' }, //Indb.
                        9: { minCellWidth: 42, halign: 'right' }, //Bonitet
                        10: { minCellWidth: 42, halign: 'right' }, //Højde
                        11: { minCellWidth: 38, halign: 'right' }, //Diameter
                        12: { minCellWidth: 50, halign: 'right' }, //Stamtal
                        13: { minCellWidth: 48, halign: 'right' }, //Grundflade.
                        14: { minCellWidth: 40, halign: 'right' }, //Vedm
                        15: { minCellWidth: 60, } //Bemærk.
                    }
                });
            default: // ok
                return this.setState({
                    columnStyles: {
                        0: { minCellWidth: 27, halign: 'right' }, //Afd
                        1: { minCellWidth: 50 }, //Litra
                        2: { minCellWidth: 54 }, //Anvendelse
                        3: { minCellWidth: 80 }, //Træart
                        4: { minCellWidth: 43, halign: 'right' }, //Årstal
                        5: { minCellWidth: 55, halign: 'right' }, //Udtyndet
                        6: { minCellWidth: 50, halign: 'right' }, //Længde
                        7: { minCellWidth: 50, halign: 'right' }, //Areal
                        8: { minCellWidth: 32, halign: 'right' }, //Indb.
                        9: { minCellWidth: 42, halign: 'right' }, //Bonitet
                        10: { minCellWidth: 42, halign: 'right' }, //Højde
                        11: { minCellWidth: 38, halign: 'right' }, //Diameter
                        12: { minCellWidth: 50, halign: 'right' }, //Stamtal
                        13: { minCellWidth: 48, halign: 'right' }, //Grundflade.
                        14: { minCellWidth: 40, halign: 'right' }, //Vedm
                        15: { minCellWidth: 40, halign: 'right' }, //CO2-e
                        16: { minCellWidth: 60, } //Bemærk.
                    }
                });
        }
    }

    addFooters = (doc, company) => {
        const text = company === '' ? 'Skovkortet.dk' : company + ' - Skovkortet.dk';
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFont('helvetica', 'italic');
        doc.setFontSize(8);
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text('Side ' + String(i) + ' af ' + String(pageCount) + '\n' + text, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 30, {
                align: 'center'
            })
        }
    }

    exportPDF = (title, columns, data, summary, company) => {
        // If the print function is disabled just return
        if (this.props.disabled) return;
        // Export list as a pdf
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape";
        const marginLeft = 30;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(14);
        const columnStyles = {};

        const printColumns = [];
        const printData = [];

        const printSummaryHead = [];
        const printSummaryData = [];

        let j = 0;

        for (const [i, item] of columns.entries()) {
            if (item.checked) {
                printColumns.push(item.label)
                columnStyles[j] = this.state.columnStyles[i];
                j = j + 1;
            }
        }

        for (const [i, item] of summary.entries()) {
            if (item.checked) {
                printSummaryHead.push(item.label)
                printSummaryData.push(item.value)
            }
        }

        for (const n of data) {
            let row = [];
            for (const r of columns) {
                // Check thinning entry
                let cellThinning = '';

                if (r.id === 'thinning') {
                    if (n.thinning === '-') {
                        cellThinning = '-';
                    } else {
                        cellThinning = n.thinning.map(el => {
                            const date = moment(new Date(el.date)).format('DD[-]MM[-]YYYY');
                            let tt = el.treeCountThinning ? (!isNaN(parseInt(el.treeCountThinning)) ? parseInt(el.treeCountThinning) : 0) : 0;
                            let vt = el.volumeThinning ? (!isNaN(parseInt(el.volumeThinning)) ? parseInt(el.volumeThinning) : 0) : 0;
                            let pt = el.percentageThinning ? (!isNaN(parseInt(el.percentageThinning)) ? parseInt(el.percentageThinning) : 0) : 0;

                            if (moment(el.date).isSameOrBefore(moment().endOf("day"))) {
                                if (tt === 0 && vt === 0) {
                                    return date + ':\n' + pt + ' % (Erstat hugstprocent med reelle tal.)';
                                } else if (tt !== 0 && vt === 0) {
                                    return date + ':\n' + tt + ' stk';
                                } if (tt === 0 && vt !== 0) {
                                    return date + ':\n' + vt + ' m3';
                                } else {
                                    return date + ':\n' + tt + ' stk - ' + vt + ' m3';
                                }
                            } else {
                                return date + ':\n' + pt + ' %';
                            }
                        })

                        cellThinning = cellThinning.filter(el => el !== null).join(',\n');
                    }
                }

                let cellArea = '';
                if (r.id === 'area') {
                    if (n.area === n.polyArea || n.polyArea === '-' || n.area === '-') {
                        if (n.areaBearing) {
                            cellArea = n.area
                        } else {
                            cellArea = `${n.area} i.a.b.`
                        }
                    } else {
                        cellArea = `${n.polyArea} (${n.area})`
                    }
                }

                if (r.checked) {
                    r.id === 'area' ? row.push(cellArea) : r.id === 'thinning' ? row.push(cellThinning) : row.push(n[r.id])
                }
            }
            printData.push(row);
        }

        let content = {
            startY: 50,
            head: [printColumns],
            body: printData,
            styles: {
                fontSize: 9
            },
            theme: 'plain',
            margin: { top: 25, bottom: 43, left: 25, right: 25 },
            columnStyles: columnStyles,
        };

        let contentSummary = {
            startY: 50,
            head: [printSummaryHead],
            body: [printSummaryData],
            styles: {
                fontSize: 9
            },
            theme: 'plain',
            margin: { top: 25, bottom: 25, left: 25, right: 25 },
            columnStyles: {}
        };

        doc.text(`${title}`, marginLeft, 35);
        // Add third party logo to print
        { this.props.printProfile === 'HedeDanmark' && doc.addImage(logoHD, "PNG", 750, 16, 67, 21) };
        { this.props.printProfile === 'Skovdyrkerne' && doc.addImage(logoSD, "PNG", 750, 16, 67, 25)}
        doc.autoTable(content);
        doc.addPage();
        doc.text(`Opsummeret bevoksningsliste`, marginLeft, 35);
        // Add third party logo to print
        { this.props.printProfile === 'HedeDanmark' && doc.addImage(logoHD, "PNG", 750, 16, 67, 21) };
        { this.props.printProfile === 'Skovdyrkerne' && doc.addImage(logoSD, "PNG", 750, 16, 67, 25)}
        doc.autoTable(contentSummary);
        this.addFooters(doc, company);
        doc.save(`${title}.pdf`);
    }

    render() {
        const { title, columns, data, summary, company } = this.props;
        let toolTipTitle = this.props.disabled ? "Det er ikke muligt at printe bevoksningslisten med en gratis profil" : "Print bevoksningsliste"
        let icon = this.props.disabled ? <PrintDisabledIcon /> : <PrintIcon />;

        return (
            <Tooltip title={toolTipTitle}>
                <IconButton
                    aria-label="Print liste"
                    onClick={() => this.exportPDF(title, columns, data, summary, company)}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        );
    }
}

export default withStyles(styles)(Print);
