import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import GestureIcon from '@material-ui/icons/GestureRounded';
import UndoIcon from '@material-ui/icons/UndoRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

// Material UI Icons
import DirectionsWalk from '@material-ui/icons/DirectionsWalkRounded';

// Other
import * as actions from '../../../../store/actions/index';
import HeaderMenu from '../UIElements/HeaderMenu';
import CautionMenu from '../UIElements/CautionMenu';
import InfoMenu from '../UIElements/InfoMenu';
import UpgradeMenu from '../UIElements/UpgradeMenu';
import TipMenu from '../UIElements/TipMenu';
import SpaceMenu from '../UIElements/SpaceMenu';
import ExpansionPanelMenu from '../UIElements/ExpansionPanelMenu';

// Parameters
const isMobile = 1370;
const freeMaxHa = 5;
const freeNumPoints = 3;
const freeNumHunPoints = 3;

const styles = theme => ({
    fabBottom: {
        position: 'absolute',
        zIndex: 1,
        right: 487 - 410,
        bottom: 10,
        backgroundColor: 'white'
    },
    fabMenuBottom: {
        position: 'absolute',
        zIndex: 1,
        width: '180px',
        right: 487 - 410,
        bottom: 78,
        backgroundColor: theme.palette.background.paper,
    },
    fabMenuMobile: {
        position: 'absolute',
        zIndex: 1,
        width: '180px',
        right: 68,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
    },
    moveIn: {
        transform: 'translate3d(-410px, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    moveOut: {
        transform: 'translate3d(0, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    button: {
        margin: 0,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    drawButton: {
        margin: 0,
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#009CDF',
        borderColor: '#009CDF',
        '&:hover': {
            backgroundColor: '#006d9c',
            borderColor: '#006d9c',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    drawButtonLight: {
        margin: 0,
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#13b8ff',
        borderColor: '#13b8ff',
        '&:hover': {
            backgroundColor: '#006d9c',
            borderColor: '#006d9c',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    drawButtonLighter: {
        margin: 0,
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#59cdff',
        borderColor: '#59cdff',
        '&:hover': {
            backgroundColor: '#006d9c',
            borderColor: '#006d9c',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    cancelButton: {
        margin: 0,
        borderColor: "#e75f5f",
        color: '#e75f5f'
    },
});

class MapDrawingMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            zIdx: 99,
            disable: false,
        };
    }

    componentDidUpdate() {
        if (this.props.menuBringToFrontId === 'draw') {
            if (this.state.zIdx !== 101) {
                this.setState({ zIdx: 101 });
            }
        } else {
            if (this.state.zIdx !== 99) {
                this.setState({ zIdx: 99 });
            }
        }
    }

    handleClick() {
        this.setState({ open: (this.state.open ? false : true) })
        this.props.onSetMapMenuBringToFrontId('draw');

        // Send event to Analytics        
        ReactGA.event({
            category: 'Drawing tool',
            action: `Drawing tool open: ${!this.state.open}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    cardClickHandler() {
        this.props.onSetMapMenuBringToFrontId('draw');
    }

    upgradeButtonHandler = () => {
        // Open account handling in main menu
        this.props.onMainMenuOpen(true);
        this.props.onAccountMenuOpen(true);

        // Send event to analytics
        ReactGA.event({
            category: 'Sidebar',
            action: 'Upgrade button clicked',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    renderCautionMenu = () => {
        if (((this.props.selectedForest !== null || this.props.selectedBiotopePlan !== null) && (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))))) {
            if (!this.state.disable) this.setState({disable: true});
            return (
                <div>
                    <CautionMenu
                        title='Obs'
                        content='Skrivebeskyttet.'
                    />

                    <SpaceMenu />
                </div>
            )
        } else if (this.props.biotopePlanLocked) {
            return (
                <div>
                    <CautionMenu
                        title='Obs'
                        content="Biotopplanen er låst"
                    />

                    <SpaceMenu />
                </div>
            )
        } else if (this.props.selectedForest === null && this.props.selectedBiotopePlan === null && !this.props.disabledCut && this.props.disableCancel && this.props.undoDisabled && this.props.disabledDraw && this.props.drawingState.newLineFinished && !this.props.walkActive) {
            return (
                <div>
                    <CautionMenu
                        title='Obs'
                        content={`Vælg en ${this.props.chosenWorkEnvironment === 'biotopePlan' ? "biotopplan" : "skov"} for at aktivere tegneværktøjet.`}
                    />

                    <SpaceMenu />
                </div>
            )
        } else if ((this.props.currentAuthUser.userRole === 'forestOwner' && this.props.chosenWorkEnvironment === 'forest' && !this.props.forestOwnerAllowChange) || (this.props.chosenWorkEnvironment === 'hunting' && !this.props.forestOwnerAllowChangeHunting)) {
            if (!this.state.disable) this.setState({disable: true});
            return (
                <div>
                    <CautionMenu
                        title='Obs'
                        content='Skrivebeskyttet. Kan ophæves via hovedmenuen.'
                    />

                    <SpaceMenu />
                </div>
            )
        } else {
            if (this.state.disable) this.setState({disable: false});
            return null;
        }
    }

    renderTipMenu = () => {
        if (!this.props.disabledCut && !this.props.disableCancel && !this.props.undoDisabled && this.props.disabledDraw) {
            return (
                <div>
                    <TipMenu
                        title='Tip'
                        content='Slå snap fra ved at holde Alt-tasten nede mens du tegner.'
                    />

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderPoint = () => {
        const { classes, chosenWorkEnvironment } = this.props;

        // Check if user is free and limit accordingly
        const disableForestPoint = this.props.chosenWorkEnvironment === 'forest' && (this.props.config.mapDrawingMenu.forest.point !== "enabled" || this.props.onboardingActive || (this.props.config.inventorySidebar.point.pointNumber > 0 && this.props.numPoints >= this.props.config.inventorySidebar.point.pointNumber));
        const disableHuntingPoints = this.props.chosenWorkEnvironment === 'hunting' && (this.props.config.mapDrawingMenu.hunting.point !== "enabled" || (this.props.config.huntingSidebar.point.pointNumber > 0 && this.props.numHuntingPoints >= this.props.config.huntingSidebar.point.pointNumber));


        {/* TODO : remove check on hunting when point is available for other environments */ }
        if (!this.props.disabledDraw && !this.state.disable) {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Tooltip
                            title={chosenWorkEnvironment === 'hunting' ? "Placer jagtpunkt i kortet, f.eks. jagttårn eller fodreplads." : "Placer punkt i skovkortet, f.eks. stak."}
                            aria-label="Punkt"
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.props.disabledDraw || disableHuntingPoints || disableForestPoint}
                                className={classes.drawButtonLighter}
                                onClick={this.props.onPressNewPoint}
                                fullWidth
                            >
                                Placer punkt
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderLine = () => {
        const { classes, chosenWorkEnvironment } = this.props;

        // const disableHunting = this.props.chosenWorkEnvironment === 'hunting' && !this.props.huntingActive && !(this.props.config.mapDrawingMenu.hunting.line === 'enabled');
        const disableForest = this.props.chosenWorkEnvironment === 'forest' && this.props.config.mapDrawingMenu.forest.line !== "enabled";
        const disableHunting = this.props.chosenWorkEnvironment === 'hunting' && this.props.config.mapDrawingMenu.hunting.line !== "enabled";

        if (!this.props.disabledDraw && !this.state.disable) {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Tooltip title={chosenWorkEnvironment === 'hunting' ? "Tegn linje i kortet, f.eks. pürschsti, driverkæde, osv." : "Tegn linje i kortet, f.eks. skovvej, sti, vandløb, osv."} aria-label="Linje">
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.props.disabledDraw || disableForest || disableHunting}
                                className={classes.drawButtonLight}
                                onClick={this.props.onPressNewLine}
                                fullWidth
                            >
                                Tegn linje
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderPolygon = () => {
        const { classes, chosenWorkEnvironment } = this.props;

        const disableForest = this.props.chosenWorkEnvironment === 'forest' && (this.props.config.mapDrawingMenu.forest.polygon !== "enabled" || (this.props.config.inventorySidebar.polygon.areaSize > 0 && this.props.totalForestArea >= this.props.config.inventorySidebar.polygon.areaSize));
        const disableHunting = this.props.chosenWorkEnvironment === 'hunting' && this.props.config.mapDrawingMenu.hunting.polygon !== "enabled";

        if (!this.props.disabledDraw && !this.state.disable) {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Tooltip title={chosenWorkEnvironment === 'hunting' ? "Tegn polygon i kortet, f.eks. såtgrænse, tykning, vildtager, osv." : "Tegn polygon i kortet, f.eks. litra, sø, osv."} aria-label="Polygon">
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.props.disabledDraw || disableForest || disableHunting}
                                className={classes.drawButton}
                                onClick={this.props.onPressNewPolygon}
                                fullWidth
                            >
                                Tegn polygon
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderUpgrade = () => {
        const upgradeForest = this.props.chosenWorkEnvironment === 'forest' && !this.props.natureActive && !this.props.operationActive && !this.props.operationPlusActive && this.props.totalForestArea >= this.props.config.inventorySidebar.polygon.areaSize;
        // const upgradeHunting = this.props.chosenWorkEnvironment === 'hunting' && !this.props.natureActive && !this.props.operationActive && !this.props.operationPlusActive && this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.HD && !this.props.currentAuthUser.affiliation.HD.status === 'active';
        const upgradeForestPoint = this.props.chosenWorkEnvironment === 'forest' && !this.props.natureActive && !this.props.operationActive && !this.props.operationPlusActive && this.props.numPoints >= this.props.config.inventorySidebar.point.pointNumber;
        let upgradeHunting = false;
        if (this.props.chosenWorkEnvironment === 'hunting' && !this.props.natureActive && !this.props.operationActive && !this.props.operationPlusActive) {
            upgradeHunting = true;
            if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.HD && this.props.currentAuthUser.affiliation.HD.status === 'active') {
                upgradeHunting = false;
            }

        }

        if (upgradeForest) {
            return (
                <div>
                    <UpgradeMenu
                        title='Brug for mere areal?'
                        content='Du har opnået det maksimale areal tilladt på en gratis profil.'
                        cta='Opgrader'
                        onPress={() => this.upgradeButtonHandler()}
                    />

                    <SpaceMenu />
                </div>
            )
        } else if (upgradeForestPoint) {
            return (
                <div>
                    <UpgradeMenu
                        title='Brug for flere punkter?'
                        content='Du har opnået det maksimale antal punkter på en gratis profil.'
                        cta='Opgrader'
                        onPress={() => this.upgradeButtonHandler()}
                    />

                    <SpaceMenu />
                </div>
            )
        } else if (upgradeHunting) {
            return (
                <div>
                    <UpgradeMenu
                        title='Brug for mere jagt?'
                        content='Du kan ikke oprette polygoner og linjer til jagt uden abonnement.'
                        cta='Opgrader'
                        onPress={() => this.upgradeButtonHandler()}
                    />

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderMobileButtons = () => {
        const { classes } = this.props;

        // Set text etc based on some props
        let walkLineText = "Gå linje";
        let walkPolyText = "Gå polygon";
        if (this.props.walkActive) {
            walkLineText = "Afslut";
            walkPolyText = "Afslut";
        }

        // Check if user is free and limit accordingly
        const disableForest = this.props.chosenWorkEnvironment === 'forest' && !this.props.natureActive && !this.props.operationActive && !this.props.operationPlusActive && this.props.totalForestArea >= freeMaxHa;
        const disableHunting = this.props.chosenWorkEnvironment === 'hunting' && !this.props.huntingActive && !this.props.huntingPlusActive;

        let enableWalk = false;
        if (this.props.currentAuthUser.userRole === "forester" || this.props.operationActive) {
            enableWalk = true;
        }

        return (
            <div>
                {enableWalk && (!this.props.disabledWalkLine || !this.props.disabledWalkPoly) && <div>
                    <Divider />

                    <SpaceMenu />
                </div>}

                {enableWalk && !this.props.disabledWalkLine && <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.props.disabledWalkLine || disableHunting}
                            className={classes.drawButton}
                            onClick={this.props.onPressWalkLine}
                            fullWidth
                        >
                            <DirectionsWalk /> {walkLineText}
                        </Button>
                    </div>

                    <SpaceMenu />
                </div>}

                {enableWalk && !this.props.disabledWalkPoly && <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.props.disabledWalkPoly || disableForest || disableHunting}
                            className={classes.drawButton}
                            onClick={this.props.onPressWalkPolygon}
                            fullWidth
                        >
                            <DirectionsWalk /> {walkPolyText}
                        </Button>
                    </div>

                    <SpaceMenu />
                </div>}
            </div>
        )
    }

    renderHelpingCircles = () => {
        const { chosenWorkEnvironment } = this.props;
        if (chosenWorkEnvironment !== 'hunting' && (!this.props.disabledDrawHelpCircles && this.props.drawingState.newLineFinished)) {
            return (
                <div>
                    <Tooltip title="Hjælpecirkler til afsætning af f.eks. skovbryn" aria-label="Hjælpecirkel">
                        <ExpansionPanelMenu>
                            <div>
                                <FormControlLabel
                                    style={{ marginBottom: 0 }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={this.props.drawHelpCircles10}
                                            onClick={this.props.onPressDrawHelpCircles10}
                                            disabled={this.props.disabledDrawHelpCircles}
                                        />
                                    }
                                    label="10m radius"
                                />

                                <FormControlLabel
                                    style={{ marginBottom: 0 }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={this.props.drawHelpCircles20}
                                            onClick={this.props.onPressDrawHelpCircles20}
                                            disabled={this.props.disabledDrawHelpCircles}
                                        />
                                    }
                                    label="20m radius"
                                />

                                <SpaceMenu />
                            </div>
                        </ExpansionPanelMenu>
                    </Tooltip>

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderPolygonCut = () => {
        const { classes } = this.props;

        if (this.props.disabledCut) {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title="Klip i polygoner, f.eks. ved en sø placeret i en litra" aria-label="Klip">
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={!this.props.disabledCut}
                                className={classes.drawButtonLight}
                                onClick={this.props.onPressCutPolygon}
                                fullWidth
                            >
                                Klip i polygon
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderBack = () => {
        const { classes } = this.props;

        if (!this.props.undoDisabled) {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title="Fortryd seneste handling" aria-label="Fortryd">
                            <Button
                                className={classes.button}
                                aria-label="Undo"
                                disabled={this.props.undoDisabled}
                                onClick={this.props.onPressRemoveLastVortex}
                                fullWidth
                            >
                                <UndoIcon style={{ marginRight: '8px' }} />

                                Fortryd
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    renderCancel = () => {
        const { classes } = this.props;

        if (!this.props.disableCancel) {
            return (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Tooltip title="Annuller handlinger" aria-label="Fortryd alle handlinger">
                            <Button
                                variant="outlined"
                                disabled={this.props.disableCancel}
                                className={classes.cancelButton}
                                onClick={this.props.onPressCancelNewPolygon}
                                fullWidth
                            >
                                <CloseIcon className={classes.extendedIcon} />

                                Annuller
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>
            )
        } else {
            return null
        }
    }

    cardContent() {
        const { chosenWorkEnvironment } = this.props;
        // Set text
        let text = "Elementer tilføjet kortet, vil tilhøre dit skovkort.";
        if (this.props.chosenWorkEnvironment === 'hunting') {
            text = "Elementer tilføjet kortet, vil tilhøre dit jagtkort.";
        } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
            text = "Elementer tilføjet kortet, vil tilhøre dit biotopplanskort.";
        }

        return (
            <div>
                <div>
                    {/* Forest - drawing disabled */}
                    {this.props.config.mapDrawingMenu.forest.drawing !== 'enabled' && chosenWorkEnvironment === 'forest' && <div>
                        <CautionMenu
                            title='Obs'
                            content={this.props.config.mapDrawingMenu.forest.underManagementText}
                        />

                        <SpaceMenu />
                    </div>}

                    {/* Forest - drawing enabled */}
                    {(this.props.config.mapDrawingMenu.forest.drawing === 'enabled' && chosenWorkEnvironment === 'forest') && <div>
                        <InfoMenu
                            content={text}
                        />

                        <SpaceMenu />

                        {this.renderCautionMenu()}

                        {!this.props.mobile && this.renderTipMenu()}

                        {this.props.config.mapDrawingMenu.forest.point !== 'hidden' && this.renderPoint()}

                        {this.props.config.mapDrawingMenu.forest.line !== 'hidden' && this.renderLine()}

                        {this.props.config.mapDrawingMenu.forest.polygon !== 'hidden' && this.renderPolygon()}

                        <MediaQuery maxDeviceWidth={isMobile}>
                            {this.props.config.mapDrawingMenu.forest.walk !== 'hidden' && this.renderMobileButtons()}
                        </MediaQuery>

                        {this.renderHelpingCircles()}

                        {this.renderPolygonCut()}

                        {this.renderBack()}

                        {this.renderCancel()}

                        {this.renderUpgrade()}
                    </div>}

                    {/* Hunting - drawing enabled */}
                    {(this.props.config.mapDrawingMenu.hunting.drawing === 'enabled' && chosenWorkEnvironment === 'hunting' || chosenWorkEnvironment === 'biotopePlan') && <div>
                        <InfoMenu
                            content={text}
                        />

                        <SpaceMenu />

                        {this.renderCautionMenu()}

                        {!this.props.mobile && this.renderTipMenu()}

                        {this.props.config.mapDrawingMenu.hunting.point !== 'hidden' && this.renderPoint()}

                        {this.props.config.mapDrawingMenu.hunting.line !== 'hidden' && this.renderLine()}

                        {this.props.config.mapDrawingMenu.hunting.polygon !== 'hidden' && this.renderPolygon()}

                        {this.renderBack()}

                        {this.renderCancel()}

                        {this.renderUpgrade()}
                    </div>}
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        // Onboarding: Close drawing menu if not at correct step
        if (this.props.onboardingActive && (this.props.onboardingState === 'welcome' || this.props.onboardingState === 'drawCellEnd')) {
            if (this.state.open) {
                this.setState({ open: false })
            }
        }

        const impZidx = this.props.onboardingActive ? this.props.onboardingDrawToolZidx : this.state.zIdx;

        const fab = classNames(classes.fabBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);
        const fabMenu = classNames(classes.fabMenuBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);

        return (
            <div>
                {!this.props.mobile && <Tooltip title="Korttegner" aria-label="Korttegner">
                    <Fab
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick.bind(this)}
                        className={fab}
                        style={{ zIndex: this.props.onboardingDrawToolZidx, backgroundColor: this.props.onboardingDrawBtnColor }}
                        disabled={this.props.onboardingDisableDraw}
                    >
                        {this.state.open ? <CloseIcon style={{ color: '#808080' }} /> : <GestureIcon style={{ color: '#41842b' }} />}
                    </Fab>
                </Tooltip>}

                {/* NORMAL VERSION */}
                {this.state.open && <Card
                    className={fabMenu}
                    style={{ zIndex: impZidx }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Korttegner'
                            onPress={this.handleClick.bind(this)}
                        />

                        <SpaceMenu />

                        {this.cardContent()}
                    </CardContent>
                </Card>}

                {/* MOBILE VERSION */}
                {((this.props.mapDrawingShouldOpen && !this.props.onboardingActive) || (this.props.mapDrawingShouldOpen && this.props.onboardingActive && (this.props.onboardingState === 'drawCellIntro' || this.props.onboardingState === 'drawCellCreate'))) && <Card
                    className={classes.fabMenuMobile}
                    style={{ zIndex: impZidx }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Korttegner'
                            onPress={this.props.onPress}
                        />

                        <SpaceMenu />

                        {this.cardContent()}
                    </CardContent>
                </Card>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        onboardingDrawToolZidx: state.onboarding.drawToolZidx,
        onboardingDisableDraw: state.onboarding.disableDraw,
        onboardingDrawBtnColor: state.onboarding.drawBtnColor,
        menuBringToFrontId: state.maps.mapMenuBringToFrontId,
        sidebar: state.layout.sidebarOpen,
        forestOwnerAllowChange: state.maps.forestOwnerAllowChange,
        forestOwnerAllowChangeHunting: state.hunting.forestOwnerAllowChange,
        drawingState: state.maps,
        natureActive: state.account.natureActive,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        huntingActive: state.account.huntingActive,
        huntingPlusActive: state.account.huntingPlusActive,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
        totalForestArea: state.layout.totalForestArea,
        numHuntingPoints: state.layout.numHuntingPoints,
        numPoints: state.layout.numPoints,
        config: state.appConfig,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSetMapMenuBringToFrontId: (id) => dispatch(actions.setMapMenuBringToFrontId(id)),
        onMainMenuOpen: (state) => dispatch(actions.mainMenuOpen(state)),
        onAccountMenuOpen: (state) => dispatch(actions.accountMenuOpen(state)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapDrawingMenu));