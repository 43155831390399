import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import SaveIcon from '@material-ui/icons/SaveRounded';
import CreateIcon from '@material-ui/icons/CreateRounded';

// Components
import OutlinedButton from '../../Buttons/OutlinedButton';
import * as actions from '../../../../store/actions/index';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    textField: {
        width: '100%',
    },
    extendedIcon: {
        marginLeft: theme.spacing.unit,
    },
    extendedIconAdd: {
        marginRight: theme.spacing.unit,
    },
})

class Profile extends React.Component {
    constructor(props) {
        super(props);
        // Fields are pre-loaded and made editable if it
        // is a new one - e.g. accessed without props
        this.state = {
            expanded: false,
            change: false,
            name: ("name" in this.props) ? this.props.name : '',
            forest: ("forest" in this.props) ? this.props.forest : '',
            org: ("org" in this.props) ? this.props.org.name : '',
            address: ("address" in this.props) ? this.props.address : '',
            city: ("city" in this.props) ? this.props.city : '',
            zip: ("zip" in this.props) ? this.props.zip : '',
            email: ("email" in this.props) ? this.props.email : '',
            submit: false,
            loading: false,
        };
    }

    componentDidUpdate() {
        // Check for main menu expanded
        if (this.state.change && !this.props.mainMenuOpen) this.setState({ change: false });
        // disable spinner, navigate back, and show snackbar
        if (this.props.successUser && this.state.loading) {
            this.setState({
                loading: false,
                change: false,
                expanded: false
            })
            // Set reducer to initial state
            this.props.onUnmountSaveUser();
        }
        // Change data
        // if (this.props.updateUserProfile) {
        if (!this.state.change) {
            if (this.props.name !== this.state.name) this.setState({ name: this.props.name });
            if (this.props.org.name !== this.state.org) this.setState({ org: this.props.org.name });
            if (this.props.address !== this.state.address) this.setState({ address: this.props.address });
            if (this.props.city !== this.state.city) this.setState({ city: this.props.city });
            if (this.props.zip !== this.state.zip) this.setState({ zip: this.props.zip });
        }
        //     this.props.onSetUpdateUserProfile(false)
        // }
    }

    textField(key, label, content, mandatory, disabled) {
        const { classes } = this.props;

        // When profile is in editable state
        if (this.state.change) {
            return (
                <TextField
                    id={label}
                    label={label}
                    multiline
                    className={classes.textField}
                    value={content}
                    onChange={event => { this.setState({ [key]: event.target.value }); event.stopPropagation() }}
                    error={this.state[key] === "" && mandatory}
                    disabled={disabled}
                    margin="normal"
                    onClick={e => e.stopPropagation()}
                />
            );
        }

        // When profile is in non-editable state
        if (this.state[key] !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {label}
                    </Typography >
                    <div>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {content}
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    renderContent() {
        const { address, city, email, forest, org, name, zip } = this.state;
        const orgForestName = org !== '' ? org : forest;

        return (
            <div>
                {this.textField('name', 'Navn', name, this.state.submit)}
                {org === undefined ? null : this.textField('forest', 'Skov/firma', orgForestName, false, true)}
                {this.textField('address', 'Adresse', address, false)}
                {this.textField('zip', 'Postnummer', zip, false)}
                {this.textField('city', 'By', city, false)}
                {this.textField('email', 'Email', email, false, true)}
            </div>
        );
    }


    renderButton() {
        const { classes } = this.props;

        if (this.state.change) {
            return (
                <div style={{ display: 'flex' }}>
                    <OutlinedButton
                        buttonLabel='Annuller'
                        onPress={() => this.setState({ change: false })}
                        variant='text'
                        discrete={true}
                    />

                    <OutlinedButton
                        buttonLabel={'Gem'}
                        buttonIcon={<SaveIcon className={classes.extendedIconAdd} />}
                        iconLeft={true}
                        onPress={(e) => { this.onButtonPress(); e.stopPropagation() }}
                        loading={this.state.loading}
                        color="primary"
                    />
                </div>
            );
        }

        return (
            <OutlinedButton
                buttonLabel={'Ændre'}
                buttonIcon={<CreateIcon className={classes.extendedIconAdd} />}
                iconLeft={true}
                onPress={(e) => { this.onButtonPress(); e.stopPropagation() }}
                loading={this.state.loading}
                color="primary"
            />
        );
    }

    onButtonPress = () => {
        if (this.state.change) {
            const { address, city, name, zip } = this.state;

            this.setState({ submit: true })

            // if (address !== '' && city !== '' && name !== '' && zip !== '') {
            if (name !== '') {
                this.setState({ loading: true, })

                // Save to cloud
                const user = {
                    address, city, name, zip
                }
                this.props.onUpdateUserData(user);

                // Send event to Analytics
                ReactGA.event({
                    category: 'Profile',
                    action: 'Updated user',
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            }
        } else {
            this.setState({ change: true });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <ExpansionPanel
                        style={{ borderRadius: 0, borderWidth: 0, borderColor: 'white', boxShadow: 'none', }}
                        expanded={this.state.expanded}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => this.setState({ change: false, expanded: !this.state.expanded })}
                            style={{ paddingLeft: 16, paddingRight: 16 }}
                        >
                            <Typography className={classes.heading}>
                                Profil
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {this.renderContent()}
                        </ExpansionPanelDetails>

                        <ExpansionPanelActions style={{ justifyContent: 'flex-start', paddingLeft: 5, paddingBottom: 9, paddingTop: 0 }}>
                            {this.renderButton()}
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        successUser: state.user.successUser,
        currentAuthUser: state.user.currentAuthUser,
        updateUserProfile: state.user.updateUserProfile,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (data) => dispatch(actions.updateUserData(data)),
        onUnmountSaveUser: () => dispatch(actions.unmountSaveUser()),
        onSetUpdateUserProfile: (state) => dispatch(actions.setUpdateUserProfile(state)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));