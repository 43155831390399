import env from './buildConfig';

// ... Dev ...
let configDataFordeleren = {
    username: "QGYLQUQVZV",
    password: "SkovRock_21"
};

// Same as dev
if (env.stage === "production") { 
}

export default configDataFordeleren;