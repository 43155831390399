import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    simpleMapState: false,
    ortoMapState: false,
    ortoKfMapState: false,
    ortoKfTempMapState: false,
    contourKfMapState: false,
    shadowKfMapState: false,
    shadowSurfKfMapState: false,
    blueSpotMapState: false,
    protectedNatureMapState: false,
    fredskovState: false,
    natura2000State: false,
    huntingLayerMapState: false,
    cellsState: false,
    cellsOpacityState: true,
    linesState: false,
    pointsState: false,
    notesState: false,
    cadastralState: false,
    cadastralOwnerState: false,
    markblokkeState: false,
    protectedAncientAreasState: false,
    inventoryListActive: false,
    deleteMarker: false,
    deleteMarkerLatLng: null,
    newNoteFinished: true,
    cells: null,
    cellSaved: false,
    cellSaveFailed: false,
    error: null,
    newCellFinished: true,
    newLineFinished: true,
    loading: false,
    successCell: false,
    forestSuccess: false,
    deleteCell: false,
    deleteCell2: false,
    cellEditable: false,
    cellEditableId: null,
    cellEditableLatLng: null,
    redrawCells: false,
    mapDataUpdated: false,
    leafletLayerRemove: null,
    forestBbox: null,
    newestForestRevDate: "",
    forestMarkerClickId: null,
    mapMenuBringToFrontId: "",
    forestOwnerAllowChange: true,
    polyToolTipActive: true,
    lineLabelsActive: true,
    points: null,
    redrawPoints: false,
    notDisabledForestId: null,
    exportForestLayers: null,
    exportHuntingLayers: null,
    exportBiotopePlanLayers: null,
    exportNotesLayers: null,
};

const setExportLayers = ( state, action ) => {
    switch (action.layer) {
        case 'forest': return updateObject( state, { exportForestLayers: action.arr } );
        case 'hunting': return updateObject( state, { exportHuntingLayers: action.arr } );
        case 'biotopePlan': return updateObject( state, { exportBiotopePlanLayers: action.arr } );
        case 'notes': return updateObject( state, { exportNotesLayers: action.arr } );
    }
}
const setMapMenuBringToFrontId = ( state, action ) => {
    return updateObject( state, { mapMenuBringToFrontId: action.bringToFrontId } );
};

const setMapSimpleState = ( state, action ) => {
    return updateObject( state, { simpleMapState: action.simpleMapState, redrawCells: action.redraw } );
};

const setMapOrtoState = ( state, action ) => {
    return updateObject( state, { ortoMapState: action.ortoMapState, redrawCells: action.redraw } );
};

const setMapOrtoKfState = ( state, action ) => {
    return updateObject( state, { ortoKfMapState: action.ortoKfMapState , redrawCells: action.redraw} );
};

const setMapOrtoKfTempState = ( state, action ) => {
    return updateObject( state, { ortoKfTempMapState: action.ortoKfTempMapState, redrawCells: action.redraw } );
};

const setMapContourKfState = ( state, action ) => {
    return updateObject( state, { contourKfMapState: action.contourKfMapState } );
};

const setMapCadastralState = ( state, action ) => {
    return updateObject( state, { cadastralState: action.cadastralState } );
};

const setMapCadastralOwnerState = ( state, action ) => {
    return updateObject( state, { cadastralOwnerState: action.cadastralOwnerState } );
};

const setMapMarkblokkeState = ( state, action ) => {
    return updateObject( state, { markblokkeState: action.markblokkeState } );
};

const setMapProtectedAncientAreasState = ( state, action ) => {
    return updateObject( state, { protectedAncientAreasState: action.protectedAncientAreasState } );
};

const setMapShadowKfState = ( state, action ) => {
    return updateObject( state, { shadowKfMapState: action.shadowKfMapState, redrawCells: action.redraw } );
};

const setMapShadowSurfKfState = ( state, action ) => {
    return updateObject( state, { shadowSurfKfMapState: action.shadowSurfKfMapState, redrawCells: action.redraw } );
};

const setMapBlueSpotState = ( state, action ) => {
    return updateObject( state, { blueSpotMapState: action.blueSpotMapState } );
};

const setMapProtectedNatureState = ( state, action ) => {
    return updateObject( state, { protectedNatureMapState: action.protectedNatureMapState } );
};

const setMapFredskovState = ( state, action ) => {
    return updateObject( state, { fredskovState: action.fredskovState } );
};

const setMapNatura2000State = ( state, action ) => {
    return updateObject( state, { natura2000State: action.natura2000State } );
};

const setMapHuntingLayerState = ( state, action ) => {
    return updateObject( state, { huntingLayerMapState: action.huntingLayerMapState } );
};

const setMapCellsState = ( state, action ) => {
    return updateObject( state, { cellsState: action.cellsState } );
};

const setMapPointsState = ( state, action ) => {
    return updateObject( state, { pointsState: action.pointsState } );
};

const setCellsOpacityState = ( state, action ) => {
    return updateObject( state, { cellsOpacityState: action.cellsOpacityState, redrawCells: true } );
};

const setMapLinesState = ( state, action ) => {
    return updateObject( state, { linesState: action.linesState } );
};

const setMapNotesState = ( state, action ) => {
    return updateObject( state, { notesState: action.notesState } );
};

const setForestMarkerClickId = ( state, action ) => {
    return updateObject( state, { forestMarkerClickId: action.forestMarkerClickId } );
};

const setInventoryListActive = ( state, action ) => {
    return updateObject( state, { inventoryListActive: action.inventoryListActive } );
};

const setNotDisabledForestId = ( state, action ) => {
    return updateObject( state, { notDisabledForestId: action.notDisabledForestId } );
};

const setPolyToolTipActive = ( state, action ) => {
    return updateObject( state, { 
        polyToolTipActive: action.polyToolTipActive,
        redrawCells: true,
    } );
};

const setLineLabelsActive = ( state, action ) => {
    return updateObject( state, { 
        lineLabelsActive: action.lineLabelsActive,
        redrawCells: true,
    } );
};

const setNewestForestRevDate = ( state, action ) => {
    return updateObject( state, { newestForestRevDate: action.newestForestRevDate } );
};

const setCellEditable = ( state, action ) => {
    return updateObject( state, { 
        cellEditable: action.cellEditable,
        cellEditableId: action.cellEditableId 
    } );
};

const setCellEditableLatLng = ( state, action ) => {
    return updateObject( state, { 
        cellEditableLatLng: action.cellEditableLatLng, 
    } );
};

const setleafletLayerRemove = ( state, action ) => {
    return updateObject( state, { 
        leafletLayerRemove: action.leafletLayerRemove, 
    } );
};

const setDeleteMarker = ( state, action ) => {
    return updateObject( state, { 
        deleteMarker: action.deleteMarker,
        deleteMarkerLatLng: action.deleteMarkerLatLng
     } );
};

const setNewNoteFinished = ( state, action ) => {
    return updateObject( state, { newNoteFinished: action.newNoteFinished } );
};

const setDeleteCell= ( state, action ) => {
    return updateObject( state, { 
        deleteCell: action.deleteCell,
        deleteCellLatLngs: action.deleteCellLatLngs
     } );
};

const setNewCellFinished = ( state, action ) => {
    return updateObject( state, { newCellFinished: action.newCellFinished } );
};

const setNewLineFinished = ( state, action ) => {
    return updateObject( state, { newLineFinished: action.newLineFinished } );
};

const fetchCellsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchCellsSuccess = ( state, action ) => {
    return updateObject( state, {
        cells: {...state.cells, [action.id]: action.cells},
        loading: false,
        redrawCells: true,
    } );
};

const deleteCellsForest = ( state, action ) => {
    // Remove cells from forest list
    const updatedCells = { ...state.cells };
    delete updatedCells[action.customerId];
    // Remove points from forest list
    const updatedPoints = { ...state.points };
    delete updatedPoints[action.customerId];
    return updateObject(state, {
        cells: updatedCells,
        points: updatedPoints,
        redrawCells: true,
    });
}

const redrawCells = ( state, action ) => {
    return updateObject( state, {
        redrawCells: true,
    } );
};

const cellsRedrawn = ( state, action ) => {
    return updateObject( state, {
        redrawCells: false,
        mapDataUpdated: true,
    } );
};

const setMapDataUpdated = ( state, action ) => {
    return updateObject( state, {
        mapDataUpdated: action.mapDataUpdated,
    } );
};

const fetchCellsFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const saveCellSuccess = ( state, action ) => {   
    return updateObject( state, {
        loading: false,
        successCell: true,
    } );
};

const saveCellFail = ( state, action ) => {
    return updateObject( state, { cellSaveFailed: action.cellSaveFailed } );
};

const saveForestSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
    } );
};

const saveForestFail = ( state, action ) => {
    return updateObject( state, { 
        loading: false,
    } );
};

const saveForestBboxSuccess = ( state, action ) => { 
    return updateObject( state, {
        loading: false,
    } );
};

const saveForestComplete = (state, action) => {
    return updateObject( state, {
        loading: false,
        forestSuccess: true,
    })
};

const saveForestBboxFail = ( state, action ) => {
    return updateObject( state, { 
        loading: false,
    } );
};

const getForestBboxSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        forestBbox: {...state.forestBbox, [action.id]: action.forestBbox},
    } );
};

const getForestBboxFail = ( state, action ) => {
    return updateObject( state, { 
        loading: false,
    } );
};

const deleteBboxForest = ( state, action ) => {
    const updatedBbox = { ...state.forestBbox };
    delete updatedBbox[action.customerId];
    return updateObject(state, {
        forestBbox: updatedBbox,
    });
}

const deleteCellSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        deleteCell2: true,
    } );
};

const deleteCellFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const deleteCellStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const unmountCell = ( state, action ) => {   
    return updateObject( state, {
        error: null,
        successCell: false,
        deleteCell2: false,
        forestSuccess: false,
    } );
};

// Fetch the points from cloud
const fetchPointsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchPointsFail = ( state, action ) => {
    return updateObject( state, { loading: false, error: action.error } );
};

const fetchPointsSuccess = ( state, action ) => {
    return updateObject( state, {
        points: {...state.points, [action.id]: action.points},
        loading: false,
        redrawPoints: true,
    });
};

const setRedrawPoints = ( state, action ) => {
    return updateObject( state, { redrawPoints: action.redrawPointsState} );
};

const setForestOwnerChangeAllowed = ( state, action ) => {
    return updateObject( state, { forestOwnerAllowChange: action.forestOwnerAllowChange } );
};

const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case actionTypes.SET_EXPORT_LAYERS: return setExportLayers(state, action);

        case actionTypes.SET_MAP_MENU_BRING_TO_FRONT_ID: return setMapMenuBringToFrontId(state, action);
        case actionTypes.SET_MAP_SIMPLE_STATE: return setMapSimpleState(state, action);
        case actionTypes.SET_MAP_ORTO_STATE: return setMapOrtoState(state, action);
        case actionTypes.SET_MAP_ORTO_KF_STATE: return setMapOrtoKfState(state, action);
        case actionTypes.SET_MAP_ORTO_KF_TEMP_STATE: return setMapOrtoKfTempState(state, action);
        case actionTypes.SET_MAP_CONTOUR_KF_STATE: return setMapContourKfState(state, action);
        case actionTypes.SET_MAP_SHADOW_KF_STATE: return setMapShadowKfState(state, action);
        case actionTypes.SET_MAP_SHADOW_SURF_KF_STATE: return setMapShadowSurfKfState(state, action);
        case actionTypes.SET_MAP_BLUE_SPOT_STATE: return setMapBlueSpotState(state, action);
        case actionTypes.SET_MAP_PROTECTED_NATURE_STATE: return setMapProtectedNatureState(state, action);
        case actionTypes.SET_MAP_FREDSKOV_STATE: return setMapFredskovState(state, action);
        case actionTypes.SET_MAP_NATURA_2000_STATE: return setMapNatura2000State(state, action);
        case actionTypes.SET_MAP_CELLS_STATE: return setMapCellsState(state, action);
        case actionTypes.SET_MAP_CELLS_OPACITY_STATE: return setCellsOpacityState(state, action);
        case actionTypes.SET_MAP_LINES_STATE: return setMapLinesState(state, action);
        case actionTypes.SET_MAP_POINTS_STATE: return setMapPointsState(state, action);
        case actionTypes.SET_MAP_NOTES_STATE: return setMapNotesState(state, action);
        case actionTypes.SET_MAP_CADASTRAL_STATE: return setMapCadastralState(state, action);
        case actionTypes.SET_MAP_CADASTRAL_OWNER_STATE: return setMapCadastralOwnerState(state, action);
        case actionTypes.SET_MAP_MARKBLOKKE_STATE: return setMapMarkblokkeState(state, action);
        case actionTypes.SET_MAP_PROTECTED_ANCIENT_AREAS_STATE: return setMapProtectedAncientAreasState(state, action);
        case actionTypes.SET_MAP_HUNTING_LAYER_STATE: return setMapHuntingLayerState(state, action);
        case actionTypes.SET_NOT_DISABLED_FOREST_ID: return setNotDisabledForestId(state, action);

        case actionTypes.SET_FOREST_MARKER_CLICK_ID: return setForestMarkerClickId(state, action);
        case actionTypes.SET_POLY_TOOL_TIP_ACTIVE: return setPolyToolTipActive(state, action);
        case actionTypes.SET_LINE_LABELS_ACTIVE: return setLineLabelsActive(state, action);
        
        case actionTypes.SET_INVENTORY_LIST_ACTIVE: return setInventoryListActive(state, action);
        case actionTypes.SET_NEWEST_FOREST_REV_DATE: return setNewestForestRevDate(state, action);
        case actionTypes.SET_DELETE_MARKER: return setDeleteMarker(state, action);
        case actionTypes.SET_NEW_NOTE_FINISHED: return setNewNoteFinished(state, action);
        case actionTypes.SET_DELETE_CELL: return setDeleteCell(state, action);
        case actionTypes.SET_NEW_CELL_FINISHED: return setNewCellFinished(state, action);
        case actionTypes.SET_NEW_LINE_FINISHED: return setNewLineFinished(state, action);
        case actionTypes.FETCH_CELLS_START: return fetchCellsStart(state, action);
        case actionTypes.FETCH_CELLS_SUCCESS: return fetchCellsSuccess(state, action);
        case actionTypes.FETCH_CELLS_FAIL: return fetchCellsFail(state, action);
        case actionTypes.DELETE_CELLS_FOREST: return deleteCellsForest(state, action);
        case actionTypes.SAVE_CELL_SUCCESS: return saveCellSuccess(state, action);
        case actionTypes.SAVE_CELL_FAILED: return saveCellFail(state, action);
        case actionTypes.DELETE_CELL_SUCCESS: return deleteCellSuccess(state, action);
        case actionTypes.DELETE_CELL_START: return deleteCellStart(state, action);
        case actionTypes.DELETE_CELL_FAIL: return deleteCellFail(state, action);
        case actionTypes.UNMOUNT_CELL: return unmountCell(state, action);
        case actionTypes.SAVE_FOREST_SUCCESS: return saveForestSuccess(state, action);
        case actionTypes.SAVE_FOREST_FAIL: return saveForestFail(state, action);
        case actionTypes.SET_CELL_EDITABLE : return setCellEditable(state, action);
        case actionTypes.SET_CELL_EDITABLE_LATLNG : return setCellEditableLatLng(state, action);
        case actionTypes.SET_LEAFLET_LAYER_REMOVE : return setleafletLayerRemove(state, action);
        case actionTypes.REDRAW_CELLS : return redrawCells(state, action);
        case actionTypes.CELLS_REDRAWN : return cellsRedrawn(state, action);
        case actionTypes.SET_MAP_DATA_UPDATED : return setMapDataUpdated(state, action);
        case actionTypes.SAVE_NEW_FOREST_SUCCESS : return saveForestComplete(state, action);
        case actionTypes.SAVE_FOREST_BBOX_SUCCESS : return saveForestBboxSuccess(state, action);
        case actionTypes.SAVE_FOREST_BBOX_FAIL : return saveForestBboxFail(state, action);
        case actionTypes.GET_FOREST_BBOX_SUCCESS : return getForestBboxSuccess(state, action);
        case actionTypes.GET_FOREST_BBOX_FAIL : return getForestBboxFail(state, action);
        case actionTypes.DELETE_BBOX_FOREST : return deleteBboxForest(state, action);
        case actionTypes.SET_FOREST_OWNER_CHANGE_ALLOWED : return setForestOwnerChangeAllowed(state, action);

        case actionTypes.FETCH_POINTS_START : return fetchPointsStart(state, action);
        case actionTypes.FETCH_POINTS_FAIL : return fetchPointsFail(state, action);
        case actionTypes.FETCH_POINTS_SUCCESS : return fetchPointsSuccess(state, action);
        case actionTypes.SET_REDRAW_POINTS_STATE : return setRedrawPoints(state, action);
        
        
        default: return state;
    }
};

export default reducer;