import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import StraightenIcon from '@material-ui/icons/StraightenRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';

// Other
import * as actions from '../../../../store/actions/index';
import HeaderMenu from '../UIElements/HeaderMenu';
import TipMenu from '../UIElements/TipMenu';
import SpaceMenu from '../UIElements/SpaceMenu';

const styles = theme => ({
    fabBottom: {
        position: 'absolute',
        zIndex: 1,
        right: 554 - 410,
        bottom: 10,
        backgroundColor: 'white'
    },
    fabMenuBottom: {
        position: 'absolute',
        zIndex: 1,
        width: '180px',
        right: 554 - 410,
        bottom: 78,
        backgroundColor: theme.palette.background.paper,
    },
    fabMenuMobile: {
        position: 'absolute',
        zIndex: 1,
        width: '180px',
        right: 68,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
    },
    moveIn: {
        transform: 'translate3d(-410px, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    moveOut: {
        transform: 'translate3d(0, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    drawButton: {
        margin: 0,
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#009CDF',
        borderColor: '#009CDF',
        '&:hover': {
            backgroundColor: '#006d9c',
            borderColor: '#006d9c',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    drawButtonLight: {
        margin: 0,
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#13b8ff',
        borderColor: '#13b8ff',
        '&:hover': {
            backgroundColor: '#006d9c',
            borderColor: '#006d9c',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    cancelButton: {
        margin: 0,
        borderColor: "#e75f5f",
        color: '#e75f5f'
    }
});

class MapMeasurementMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            deleteEnabled: false,
            zIdx: 99,
        };
    }

    handleClick = () => {
        this.setState({ open: (this.state.open ? false : true) })
        this.props.onSetMapMenuBringToFrontId('meas');

        // Send event to Analytics        
        ReactGA.event({
            category: 'Measurement tool',
            action: `Measurement tool open: ${!this.state.open}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    };

    cardClickHandler() {
        this.props.onSetMapMenuBringToFrontId('meas');
    }

    componentDidUpdate() {
        if (this.props.menuBringToFrontId === 'meas') {
            if (this.state.zIdx !== 101) {
                this.setState({ zIdx: 101 });
            }
        } else {
            if (this.state.zIdx !== 99) {
                this.setState({ zIdx: 99 });
            }
        }
    }

    cardContent() {
        const { classes } = this.props;

        return (
            <div>
                {!this.props.disabledDraw && <div>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Tooltip title="Mål afstande i kortet, fx længder til hegn" aria-label="Mål afstande i kortet">
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.props.disabledDraw}
                                className={classes.drawButtonLight}
                                onClick={this.props.onPressNewDistance}
                                fullWidth
                            >
                                Mål afstand
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>}

                {!this.props.disabledDraw && <div>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Tooltip title="Mål arealer i kortet, fx litraarealer" aria-label="Mål areal i kortet">
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={this.props.disabledDraw}
                                className={classes.drawButton}
                                onClick={this.props.onPressNewArea}
                                fullWidth
                            >
                                Mål areal
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>}

                {!this.props.deleteDisabled && !this.props.disabledDraw && <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title="Klik på knappen og dernæst opmålingen i kortet, som ønskes slettet" aria-label="Slet mål i kortet">
                            <Button
                                variant="outlined"
                                className={classes.cancelButton}
                                aria-label="Undo"
                                disabled={this.props.deleteDisabled}
                                onClick={this.props.onPressDelete}
                                fullWidth
                            >
                                <DeleteIcon style={{ marginRight: '8px' }} />
                                
                                Slet mål
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>}

                {!this.props.disableCancel && <div>
                    <TipMenu
                        title='Tip'
                        content='Klik på første punkt for at afslutte.'
                    />

                    <SpaceMenu />
                    
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title="Annuller handling, fx påbegyndt opmåling" aria-label="Fortryd alle handlinger">
                            <Button
                                variant="outlined"
                                disabled={this.props.disableCancel}
                                className={classes.cancelButton}
                                onClick={this.props.onPressCancel}
                                fullWidth
                            >
                                <CloseIcon className={classes.extendedIcon} />
                                
                                Annuller
                            </Button>
                        </Tooltip>
                    </div>

                    <SpaceMenu />
                </div>}
            </div>
        )
    }

    render() {
        const { classes } = this.props;

        // Onboarding: Close drawing menu if not at correct step
        if (this.props.onboardingActive && (this.props.onboardingState === 'welcome' || this.props.onboardingState === 'measEnd')) {
            if (this.state.open) {
                this.setState({ open: false })
            }
        }

        const impZidx = this.props.onboardingActive ? this.props.onboardingDrawToolZidx : this.state.zIdx;
        const fab = classNames(classes.fabBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);
        const fabMenu = classNames(classes.fabMenuBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);

        return (
            <div>
                {!this.props.mobile && <Tooltip title="Måleværktøj" aria-label="Måleværktøj">
                    <Fab
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick.bind(this)}
                        className={fab}
                        style={{ zIndex: this.props.onboardingMeasToolZidx, backgroundColor: this.props.onboardingMeasBtnColor }}
                        disabled={this.props.onboardingDisableMeas}
                    >
                        {this.state.open ? <CloseIcon style={{ color: '#808080' }} /> : <StraightenIcon style={{ color: '#41842b' }} />}
                    </Fab>
                </Tooltip>}

                {/* NORMAL VERSION */}
                {this.state.open && <Card
                    className={fabMenu}
                    style={{ zIndex: impZidx }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Måleværktøj'
                            onPress={this.handleClick.bind(this)}
                        />

                        <SpaceMenu />

                        {this.cardContent()}
                    </CardContent>
                </Card>}

                {/* MOBILE VERSION */}
                {this.props.mapMeasurementShouldOpen && <Card className={classes.fabMenuMobile} style={{ zIndex: impZidx }} onClick={this.cardClickHandler.bind(this)}>
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Måleværktøj'
                            onPress={this.props.onPress}
                        />

                        <SpaceMenu />

                        {this.cardContent()}
                    </CardContent>
                </Card>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        onboardingMeasToolZidx: state.onboarding.measToolZidx,
        onboardingDisableMeas: state.onboarding.disableMeas,
        onboardingMeasBtnColor: state.onboarding.measBtnColor,
        menuBringToFrontId: state.maps.mapMenuBringToFrontId,
        sidebar: state.layout.sidebarOpen,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSetMapMenuBringToFrontId: (id) => dispatch(actions.setMapMenuBringToFrontId(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapMeasurementMenu));