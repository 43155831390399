import * as actionTypes from './actionTypes';
import firebase from 'firebase';
import moment from 'moment';
import { findMinMax } from '../../maps/Utility/UtilityFunctions';

export const setExportLayers = (layer, array) => {
    return {
        type: actionTypes.SET_EXPORT_LAYERS,
        layer: layer,
        arr: array
    };
};

export const setMapMenuBringToFrontId = (bringToFrontId) => {
    return {
        type: actionTypes.SET_MAP_MENU_BRING_TO_FRONT_ID,
        bringToFrontId: bringToFrontId
    };
};

export const setMapSimpleState = (simpleState) => {
    return {
        type: actionTypes.SET_MAP_SIMPLE_STATE,
        simpleMapState: simpleState,
    };
};

export const setMapOrtoState = (ortoState) => {
    return {
        type: actionTypes.SET_MAP_ORTO_STATE,
        ortoMapState: ortoState,
    };
};

export const setMapOrtoKfState = (ortoState) => {
    return {
        type: actionTypes.SET_MAP_ORTO_KF_STATE,
        ortoKfMapState: ortoState,
    };
};

export const setMapOrtoKfTempState = (ortoState) => {
    return {
        type: actionTypes.SET_MAP_ORTO_KF_TEMP_STATE,
        ortoKfTempMapState: ortoState,
    };
};

export const setMapContourKfState = (contourState) => {
    return {
        type: actionTypes.SET_MAP_CONTOUR_KF_STATE,
        contourKfMapState: contourState
    };
};

export const setMapShadowKfState = (shadowState) => {
    return {
        type: actionTypes.SET_MAP_SHADOW_KF_STATE,
        shadowKfMapState: shadowState,
    };
};

export const setMapShadowSurfKfState = (shadowState) => {
    return {
        type: actionTypes.SET_MAP_SHADOW_SURF_KF_STATE,
        shadowSurfKfMapState: shadowState,
    };
};


export const setMapBlueSpotState = (blueSpotState) => {
    return {
        type: actionTypes.SET_MAP_BLUE_SPOT_STATE,
        blueSpotMapState: blueSpotState
    };
};

export const setMapProtectedNatureState = (protectedNatureState) => {
    return {
        type: actionTypes.SET_MAP_PROTECTED_NATURE_STATE,
        protectedNatureMapState: protectedNatureState
    };
};

export const setMapFredskovState = (fredskovState) => {
    return {
        type: actionTypes.SET_MAP_FREDSKOV_STATE,
        fredskovState: fredskovState
    };
};

export const setMapNatura2000State = (natura2000State) => {
    return {
        type: actionTypes.SET_MAP_NATURA_2000_STATE,
        natura2000State: natura2000State
    };
};

export const setMapHuntingLayerState = (huntingLayerState) => {
    return {
        type: actionTypes.SET_MAP_HUNTING_LAYER_STATE,
        huntingLayerMapState: huntingLayerState
    };
};

export const setMapCellsState = (cellsState) => {
    return {
        type: actionTypes.SET_MAP_CELLS_STATE,
        cellsState: cellsState
    };
};

export const setMapCellsOpacityState = (cellsOpacityState) => {
    return {
        type: actionTypes.SET_MAP_CELLS_OPACITY_STATE,
        cellsOpacityState: cellsOpacityState
    };
};

export const setMapLinesState = (linesState) => {
    return {
        type: actionTypes.SET_MAP_LINES_STATE,
        linesState: linesState
    };
};

export const setMapPointsState = (pointsState) => {
    return {
        type: actionTypes.SET_MAP_POINTS_STATE,
        pointsState: pointsState
    };
};

export const setMapNotesState = (notesState) => {
    return {
        type: actionTypes.SET_MAP_NOTES_STATE,
        notesState: notesState
    };
};

export const setMapCadastralState = (cadastralState) => {
    return {
        type: actionTypes.SET_MAP_CADASTRAL_STATE,
        cadastralState: cadastralState
    };
};

export const setMapCadastralOwnerState = (cadastralOwnerState) => {
    return {
        type: actionTypes.SET_MAP_CADASTRAL_OWNER_STATE,
        cadastralOwnerState: cadastralOwnerState
    };
};

export const setMapMarkblokkeState = (markblokkeState) => {
    return {
        type: actionTypes.SET_MAP_MARKBLOKKE_STATE,
        markblokkeState: markblokkeState
    };
};

export const setMapProtectedAncientAreasState = (protectedAncientAreasState) => {
    return {
        type: actionTypes.SET_MAP_PROTECTED_ANCIENT_AREAS_STATE,
        protectedAncientAreasState: protectedAncientAreasState
    };
};

export const setForestMarkerClickId = (forestId) => {
    return {
        type: actionTypes.SET_FOREST_MARKER_CLICK_ID,
        forestMarkerClickId: forestId
    };
};

export const setInventoryListActive = (inventoryListState) => {
    return {
        type: actionTypes.SET_INVENTORY_LIST_ACTIVE,
        inventoryListActive: inventoryListState
    };
};

export const setNotDisabledForestId = (notDisabledForestId) => {
    return {
        type: actionTypes.SET_NOT_DISABLED_FOREST_ID,
        notDisabledForestId: notDisabledForestId
    };
};

export const setPolyToolTipActive = (polyToolTipState) => {
    return {
        type: actionTypes.SET_POLY_TOOL_TIP_ACTIVE,
        polyToolTipActive: polyToolTipState
    };
};

export const setLineLabelsActive = (lineLabelsState) => {
    return {
        type: actionTypes.SET_LINE_LABELS_ACTIVE,
        lineLabelsActive: lineLabelsState
    };
};

export const setNewestForestRevDate = (date) => {
    return {
        type: actionTypes.SET_NEWEST_FOREST_REV_DATE,
        newestForestRevDate: date
    };
};

export const setCellEditable = (cellEditable, cellEditableId) => {
    return {
        type: actionTypes.SET_CELL_EDITABLE,
        cellEditable: cellEditable,
        cellEditableId: cellEditableId,
    };
};

export const setCellEditableLatLng = (cellEditableLatLng) => {
    return {
        type: actionTypes.SET_CELL_EDITABLE_LATLNG,
        cellEditableLatLng: cellEditableLatLng,
    };
};

export const setDeleteMarker = (deleteMarker, deleteMarkerLatLng) => {
    return {
        type: actionTypes.SET_DELETE_MARKER,
        deleteMarker: deleteMarker,
        deleteMarkerLatLng: deleteMarkerLatLng
    };
};

export const setNewNoteFinished = (newNoteFinished) => {
    return {
        type: actionTypes.SET_NEW_NOTE_FINISHED,
        newNoteFinished: newNoteFinished
    };
};

export const setDeleteCell = (deleteCell, deleteCellLatLngs) => {
    return {
        type: actionTypes.SET_DELETE_CELL,
        deleteCell: deleteCell,
        deleteCellLatLngs: deleteCellLatLngs
    };
};

export const setNewCellFinished = (newCellFinished) => {
    return {
        type: actionTypes.SET_NEW_CELL_FINISHED,
        newCellFinished: newCellFinished
    };
};

export const setNewLineFinished = (newLineFinished) => {
    return {
        type: actionTypes.SET_NEW_LINE_FINISHED,
        newLineFinished: newLineFinished
    };
};

export const setLeafletLayerRemove = (state) => {
    return {
        type: actionTypes.SET_LEAFLET_LAYER_REMOVE,
        leafletLayerRemove: state
    };
};

export const redrawCells = () => {
    return {
        type: actionTypes.REDRAW_CELLS,
    };
};

export const cellsRedrawn = () => {
    return {
        type: actionTypes.CELLS_REDRAWN,
    };
};

export const setMapDataUpdated = (state) => {
    return {
        type: actionTypes.SET_MAP_DATA_UPDATED,
        mapDataUpdated: state
    };
};

export const fetchCellsStart = () => {
    return {
        type: actionTypes.FETCH_CELLS_START
    };
};

export const fetchCellsSuccess = (cells, forestId) => {
    return {
        type: actionTypes.FETCH_CELLS_SUCCESS,
        cells: cells,
        id: forestId
    };
};

export const fetchCellsFail = (error) => {
    return {
        type: actionTypes.FETCH_CELLS_FAIL,
        error: error
    };
};

export const deleteCellsForest = (forestId) => {
    return {
        type: actionTypes.DELETE_CELLS_FOREST,
        customerId: forestId
    };
};

export const fetchCells = (forestId, revId) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();

    dispatch(fetchCellsStart());

    // check for auth user
    if (user != null) {
        db.collection("forests").doc(forestId).collection("content").doc("rev: " + revId).collection("cells")
            .onSnapshot(function (querySnapshot) {
                var fetchedCells = [];
                for (let i = 0; i < 100; i++) { let z = z + i; }
                querySnapshot.forEach(function (doc) {
                    fetchedCells.push(doc.data());
                });

                // Dispatch notes
                dispatch(getForestDate(forestId, revId))
                dispatch(fetchCellsSuccess(fetchedCells, forestId));
            });
    }

    // TO DO
    // when not auth
    // return dispatch => {

    // }
};

export const saveForest = (forestId, cellsData, newCellData, envType) => (dispatch) => {
    const revId = moment().format();
    // Make backup revision of forest
    // try {
    //     for (let i = 0; i < cellsData.length; i++) {
    //         cellsData[i].cellData.id = null;
    //         dispatch(saveCell(forestId, revId, cellsData[i].cellData))
    //     }
    //     dispatch(saveForestSuccess());
    // } catch (error) {
    //     dispatch(saveForestFail(error));
    // }
    // // Save bounding box associated to forest
    // dispatch(saveForestBbox(forestId, revId, cellsData));
    // dispatch(saveForestDate(forestId, revId, revId));

    // Add new data to current forest revision
    if (newCellData) {
        try {
            if (envType === 'hunting') {
                dispatch(saveCell(forestId, 0, newCellData, envType))
            } else if (envType === 'biotopePlan') {
                dispatch(saveCell(forestId, 0, newCellData, envType))
                const cellData = newCellData;
                let data = [...cellsData, { cellData }];
                dispatch(saveForestBbox(forestId, 0, data, envType));
            } else {
                dispatch(saveCell(forestId, 0, newCellData))
                const cellData = newCellData;
                let data = [...cellsData, { cellData }];
                dispatch(saveForestBbox(forestId, 0, data));
                dispatch(saveForestDate(forestId, 0, revId));
            }
            dispatch(saveForestComplete(0))
        } catch (error) {
            dispatch(saveForestFail(error));
        }
    }

}

export const saveForestSuccess = () => {
    return {
        type: actionTypes.SAVE_FOREST_SUCCESS,
    };
};

export const saveForestFail = (error) => {
    return {
        type: actionTypes.SAVE_FOREST_FAIL,
        error: error
    };
};

export const saveForestDate = (forestId, revId, date) => async (dispatch) => {
    var db = firebase.firestore();

    try {
        const refId = db.collection("forests").doc(forestId).collection("content").doc("rev: " + revId).collection("date").doc("date");

        refId.set({
            date,
        })

    } catch (error) {
    }

}

export const getForestDate = (forestId, revId) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    let dateData = "";

    if (user != null) {
        db.collection("forests").doc(forestId).collection("content").doc("rev: " + revId).collection("date").doc("date")
            .onSnapshot(function (doc) {
                dateData = doc.data()
                if (dateData) {
                    dispatch(setNewestForestRevDate(dateData.date));
                } else {
                    dispatch(setNewestForestRevDate(null));
                }

            });

        // TO DO
        // Dispatch when empty to stop spinner
    }
}

export const saveForestBbox = (forestId, revId, cells, envType) => async (dispatch) => {
    var db = firebase.firestore();

    let bbox = [];
    if (cells) {
        const tempLngLat = cells.map(cell => {
            return cell.cellData.bbox;
        })

        const lng = tempLngLat.flat().filter((element, index) => {
            return index % 2 === 0;
        })
        const lat = tempLngLat.flat().filter((element, index) => {
            return index % 2 !== 0;
        })

        // Create bounding box
        const lngMinMax = findMinMax(lng);
        const latMinMax = findMinMax(lat);
        bbox = [lngMinMax[0], latMinMax[0], lngMinMax[1], latMinMax[1]];
    }

    try {
        if (envType && envType === 'biotopePlan') {
            const refId = db.collection("biotopePlans").doc(forestId);
            refId.update({
                bbox,
            })
        } else {
            const refId = db.collection("forests").doc(forestId).collection("content").doc("rev: " + revId).collection("bbox").doc("bbox");
            refId.set({
                bbox,
            })
        }


        dispatch(saveForestBboxSuccess(forestId));
        //dispatch(saveForestComplete(revId))
    } catch (error) {
        dispatch(saveForestBboxFail());
    }
}

export const saveForestBboxSuccess = (forestId) => {
    return {
        type: actionTypes.SAVE_FOREST_BBOX_SUCCESS,
        id: forestId
    };
};

export const saveForestComplete = (revId) => {
    if (revId === 0) {
        return {
            type: actionTypes.SAVE_NEW_FOREST_SUCCESS,
        };
    }
}

export const saveForestBboxFail = (error) => {
    return {
        type: actionTypes.SAVE_FOREST_BBOX_FAIL,
        error: error
    };
};

export const getForestBbox = (forestId, revId) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    let bboxData = [];

    if (user != null) {
        db.collection("forests").doc(forestId).collection("content").doc("rev: " + revId).collection("bbox").doc("bbox")
            .onSnapshot(function (doc) {
                bboxData = doc.data()
                dispatch(getForestBboxSuccess(bboxData, forestId));
            });

        // TO DO
        // Dispatch when empty to stop spinner
    }
}

export const getForestBboxSuccess = (data, forestId) => {
    return {
        type: actionTypes.GET_FOREST_BBOX_SUCCESS,
        forestBbox: data,
        id: forestId,
    };
};

export const getForestBboxFail = (error) => {
    return {
        type: actionTypes.GET_FOREST_BBOX_FAIL,
        error: error
    };
};

export const deleteBboxForest = (forestId) => {
    return {
        type: actionTypes.DELETE_BBOX_FOREST,
        customerId: forestId
    };
};

export const saveCell = (forestId, revId, cellData, envType) => async (dispatch) => {
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    const storage = firebase.storage();
    let colType = 'content';
    if (envType === 'hunting') {
        colType = 'hunting';
    }
    // Save new change to current version of forest
    if (user !== null) {
        try {
            // Check for images in thinnig arr
            const promisesTotal = Object.values(cellData.speciesObjs).map(async spcObj => {
                if (spcObj.thinningArr) {
                    const promisesAll = Object.values(spcObj.thinningArr).map(async el => {
                        let thinningImageUrls = [];
                        let promises = [];
                        if (el.images && el.images.length > 0) {
                            const path = `thinningImages/${forestId}`;

                            // Upload (files) and get urls
                            for (const [i, item] of el.images.entries()) {
                                // Check for instanceof files (new files to storage)
                                if (item instanceof File) {
                                    let timestamp = new Date().getTime().toString();
                                    const uploadTask = storage.ref(`${path}/${timestamp}`).put(item);

                                    await uploadTask.then(function (snapshot) {
                                    });

                                    const prom = await storage.ref(path).child(timestamp).getDownloadURL().then(url => {
                                        // Fill image-array with url
                                        thinningImageUrls.push(url);
                                    });
                                    promises.push(prom);
                                } else {
                                    // Add url to array 
                                    thinningImageUrls.push(item);
                                }

                            }
                            el.images = thinningImageUrls;
                        }
                        return promises;
                    });
                    await Promise.all(promisesAll);
                    return promisesAll;
                }
            });
            await Promise.all(promisesTotal);
            if (typeof cellData.id === 'undefined' || cellData.id === null) {
                cellData.creationDate = moment().format();
                if (envType === 'biotopePlan') {
                    // Save biotope plan feature
                    const refId = db.collection("biotopePlans").doc(forestId).collection('content').doc("rev: " + revId).collection("cells").doc();
                    cellData.id = refId.id;
                    refId.set({
                        cellData,
                    })
                } else {
                    // Save cell
                    const refId = db.collection("forests").doc(forestId).collection(colType).doc("rev: " + revId).collection("cells").doc();
                    cellData.id = refId.id;
                    refId.set({
                        cellData,
                        kwSync: false,
                        categories: cellData.categories,
                        registrations: cellData.registrations,
                        previousSpecies: cellData.previousSpecies,
                        futureSpecies: cellData.futureSpecies
                    })
                }

            } else {
                if (envType === 'biotopePlan') {
                    const refId = db.collection("biotopePlans").doc(forestId).collection('content').doc("rev: " + revId).collection("cells").doc(cellData.id);
                    refId.update({
                        cellData,
                    })
                } else {
                    const refId = db.collection("forests").doc(forestId).collection(colType).doc("rev: " + revId).collection("cells").doc(cellData.id);
                    refId.update({
                        cellData,
                        kwSync: false,
                        categories: cellData.categories,
                        registrations: cellData.registrations,
                        previousSpecies: cellData.previousSpecies,
                        futureSpecies: cellData.futureSpecies
                    })
                }
            }

            dispatch(saveCellSuccess());
        } catch (error) {
            dispatch(saveCellFail(error));
        }
    }
}

export const saveCellSuccess = () => {
    return {
        type: actionTypes.SAVE_CELL_SUCCESS,
    };
};

export const saveCellFail = (error) => {
    return {
        type: actionTypes.SAVE_CELL_FAILED,
        error: error
    };
};

export const deleteCell = (forestId, cells, cellId, envType) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();

    dispatch(deleteCellStart());
    let colType = 'content';
    if (envType === 'hunting') {
        colType = 'hunting';
    } else {

    }
    // check for auth user
    if (user != null) {
        try {
            if (envType === 'biotopePlan') {
                const refId = db.collection("biotopePlans").doc(forestId).collection('content').doc("rev: " + 0).collection("cells").doc(cellId);
                refId.delete().then(e => {
                    const cellsTemp = cells[forestId].filter(cell => {
                        return cell.cellData.id !== cellId;
                    })
                    // Remove deleted cell from cell array, to create new bbox
                    dispatch(saveForestBbox(forestId, 0, cellsTemp));
                    dispatch(deleteCellSuccess(cellId))
                })

            } else {
                const refId = db.collection("forests").doc(forestId).collection(colType).doc("rev: " + 0).collection("cells").doc(cellId);
                refId.delete().then(e => {
                    if (envType !== 'hunting') {
                        const cellsTemp = cells[forestId].filter(cell => {
                            return cell.cellData.id !== cellId;
                        })
                        // Remove deleted cell from cell array, to create new bbox
                        dispatch(saveForestBbox(forestId, 0, cellsTemp));
                    }
                    dispatch(deleteCellSuccess(cellId))
                })
            }

        } catch (error) {
            dispatch(deleteCellFail(error));
        }
    }

    // TO DO
    // When not auth
    // return dispatch => {

    // }
}

// Fetch points
export const fetchPointsStart = () => {
    return {
        type: actionTypes.FETCH_POINTS_START
    };
};

export const fetchPointsFail = (error) => {
    return {
        type: actionTypes.FETCH_POINTS_FAIL,
        error: error
    };
};

export const fetchPointsSuccess = (points, forestId) => {
    return {
        type: actionTypes.FETCH_POINTS_SUCCESS,
        points: points,
        id: forestId
    };
};

export const fetchPoints = (forestId, revId) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();

    dispatch(fetchPointsStart());

    // check for auth user
    if (user != null) {
        try {
            db.collection("forests").doc(forestId).collection("content").doc("rev: " + revId).collection("points")
                .onSnapshot(function (querySnapshot) {
                    var fetchedPoints = {};
                    querySnapshot.forEach(function (doc) {
                        // fetchedPoints.splice(doc.data().id, 0, doc.data());
                        fetchedPoints = { ...fetchedPoints, [doc.data().id]: doc.data() };
                    });

                    // Dispatch featurs to reducer
                    dispatch(fetchPointsSuccess(fetchedPoints, forestId));
                });
        } catch (error) {
            dispatch(fetchPointsFail(error));
        }
    } else {
        dispatch(fetchPointsFail("Error! User not authenticated"));
    }
};

export const setRedrawPoints = (state) => {
    return {
        type: actionTypes.SET_REDRAW_POINTS_STATE,
        redrawPointsState: state
    };
};

export const deleteCellSuccess = (id) => {
    return {
        type: actionTypes.DELETE_CELL_SUCCESS,
        cellId: id,
    }
}

export const deleteCellFail = (error) => {
    return {
        type: actionTypes.DELETE_CELL_FAIL,
        error: error
    };
};

export const deleteCellStart = () => {
    return {
        type: actionTypes.DELETE_CELL_START
    };
};

export const unmountCellAction = () => {
    return {
        type: actionTypes.UNMOUNT_CELL,
    };
};

export const unmountCell = () => {
    return dispatch => {
        dispatch(unmountCellAction());
    }
}

export const setForestOwnerChangeAllowed = (changeAllowedState) => {
    return {
        type: actionTypes.SET_FOREST_OWNER_CHANGE_ALLOWED,
        forestOwnerAllowChange: changeAllowedState
    };
};