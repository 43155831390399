import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

// Components
import ExoFont from './ExoFont';

// Fonts
import './style.css';

const useStyles = makeStyles((theme) => ({
    preHeaderStyle: {
        fontFamily: 'Exo',
        color: '#5abf34',
        fontWeight: 700,
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
    headerStyle: {
        fontFamily: 'Exo',
        overflowWrap: 'break-word',
        color: '#292f36',
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
            marginBottom: 8
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 50,
            marginBottom: 18
        },
        fontWeight: 700,
        lineHeight: '100%',
        marginBottom: 18
    },
    textStyle: {
        fontFamily: 'Exo',
        color: '#292f36',
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 18,
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    payOffStyle: {
        fontFamily: 'Exo',
        color: '#387125',
        fontSize: 22,
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    imageContainer: {
        marginBottom: 18, 
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            paddingRight: 8,
            display: 'flex',
        },
    }
}));

export default function SignUpCommercialWindow(props) {
    const classes = useStyles();

    return (
        <div>
            <ExoFont className={classes.preHeaderStyle} style={{ }}>
                {props.preHeader}
            </ExoFont>

            <ExoFont className={classes.headerStyle} style={{ }}>
                {props.header}
            </ExoFont>

            <ExoFont className={classes.textStyle} style={{ }}>
                {props.text}
            </ExoFont>

            {props.image && <div className={classes.imageContainer} style={{ }}>
                <img
                    src={props.image}
                    alt={'Skovkortet på en tablet'}
                    style={props.imageStyle}
                />
            </div>}

            <ExoFont className={classes.payOffStyle} style={{ }}>
                {props.payOff}
            </ExoFont>
        </div>
    );
}