import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoMenu from '../../UIElements/InfoMenu';

// Icons
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';

// Components
import SpaceMenu from '../../UIElements/SpaceMenu';

// Utilities
import { forestConvertToJson, biotopePlanConvertToJson } from './Utilities/convertToJson';

// Store
import * as actions from '../../../../../store/actions/index';

const styles = () => ({
    root: {
        width: '100%',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: '#5EBD3E',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

class ExportForestData extends React.Component {
    state = {
        loading: false,
    };

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    onExportDataHandler = () => {
        // Set loading state
        this.setState({ loading: true })
        // Create json object
        let jsonObj = null;
        try {
            if (this.props.selectedForest) {
                jsonObj = forestConvertToJson(this.props.customerList[this.props.selectedForest], this.props.cells[this.props.selectedForest], Object.values(this.props.points[this.props.selectedForest]), this.props.exportForestLayers);
            } else if (this.props.selectedBiotopePlan) {
                jsonObj = biotopePlanConvertToJson(this.props.biotopePlansUser[this.props.selectedBiotopePlan], this.props.biotopePlanFeatures[this.props.selectedBiotopePlan], Object.values(this.props.biotopePlanPoints[this.props.selectedBiotopePlan]), this.props.exportForestLayers);
            }
            
        } catch (error) {
            console.error(error)
            this.props.onEnqueueSnackbar({
                message: "Eksport af data fejlede!",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                },
            });
        }

        // Download as file
        if (jsonObj) {
            try {
                this.saveAsFile(jsonObj.name ? jsonObj.name : jsonObj.planName, jsonObj);
                // Send event to Analytics
                ReactGA.event({
                    category: 'Data export',
                    action: 'Forest data exported as GeoJSON',
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            } catch (error) {
                console.error(error)
                this.props.onEnqueueSnackbar({
                    message: "Eksport af data fejlede!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'warning',
                    },
                });
            }
        }

        this.setState({ loading: false })
    }

    saveAsFile = (filename, jsonToWrite) => {
        const blob = new Blob([JSON.stringify(jsonToWrite)], { type: "text/json" });
        const link = document.createElement("a");

        link.download = filename + '.json';
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        link.dispatchEvent(evt);
    };


    render() {
        const { classes } = this.props;
        // Check forest data
        let forestText = '';
        if (this.props.selectedForest && this.props.customerList && this.props.customerList[this.props.selectedForest]) {
            forestText = 'Eksporter: ' + this.props.customerList[this.props.selectedForest].forestName + ', ' + this.props.customerList[this.props.selectedForest].name;
        } else if (this.props.selectedBiotopePlan && this.props.biotopePlansUser && this.props.biotopePlansUser[this.props.selectedBiotopePlan]) {
            forestText = 'Eksporter: ' + this.props.biotopePlansUser[this.props.selectedBiotopePlan].planName + ', ' + this.props.biotopePlansUser[this.props.selectedBiotopePlan].name;
        }
        // Tooltip text
        let tooltipText = '';
        let contentText = this.props.chosenWorkEnvironment === 'forest' 
        ? "Eksporten vil indeholde skovdata jf. de elementer, som ses i kortet."
        : "Eksporten vil indeholde alle elementer af biotopplanen.";
        if (this.props.chosenWorkEnvironment === 'forest' && !this.props.selectedForest) {
            tooltipText = 'Vælg skov, som skal eksporteres';
        } else if (this.props.chosenWorkEnvironment === 'biotopePlan' && !this.props.selectedBiotopePlan) {
            tooltipText = 'Vælg biotopplan, som skal eksporteres';
        } else {
            if (this.props.chosenWorkEnvironment === 'forest') {
                tooltipText = 'Klik for at eksportere skov';
            } else {
                tooltipText = 'Klik for at eksportere biotopplan';
            }
            
        }
        return (
            <div>
                <div className={classes.root}>
                    {/* Eksport */}
                    <div>
                        <InfoMenu
                            content={contentText}
                        />

                        <SpaceMenu />
                        <SpaceMenu />

                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            GeoJSON
                        </Typography>

                        {(this.props.selectedForest || this.props.selectedBiotopePlan) && <Typography
                            variant="body1"
                            gutterBottom
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {forestText}
                        </Typography>}

                        <Tooltip title={tooltipText} aria-label="Forbind med KW-Plan">
                            <div className={classes.button}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => this.onExportDataHandler()}
                                        disabled={((!this.props.selectedForest && !this.props.selectedBiotopePlan )|| this.state.loading)}
                                    >
                                        Eksporter
                                        <IconChevronRight />
                                    </Button>
                                    {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        selectedForest: state.customer.currentlySelectedForestId,
        selectedBiotopePlan: state.biotopePlans.currentlySelectedBiotopePlanId,
        customerList: state.customer.customerList,
        biotopePlansUser: state.biotopePlans.biotopePlansUser,
        cells: state.maps.cells,
        points: state.maps.points,
        notes: state.notes.Notes,
        huntingFeatures: state.hunting.features,
        huntingPoints: state.hunting.points,
        biotopePlanFeatures: state.biotopePlans.features,
        biotopePlanPoints: state.biotopePlans.points,
        exportForestLayers: state.maps.exportForestLayers,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSendIntegrationEmail: (type, email, data) => dispatch(actions.sendIntegrationEmail(type, email, data)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExportForestData));