import moment from 'moment';
import 'moment/locale/da';

const html2pdf = require('html2pdf.js');

export const printHtmlToPdf = (orientation, forestData, printScale, qScale, legend, cellOpacityState, printProfile, environment) => {
    let mapData = document.querySelector('.grid-print-container');
    // HACK: in production the css style of the print object is changed, so the polygons are shifted. Solved på reverting package html2canvas back to 1.0.0.rc5 and fixing it to not change!
    // let transform = mapData.querySelector('.leaflet-map-pane')
    // let test = mapData.getElementsByTagName('svg')
    // test.forEach(el => {
    //     var c = el.style.transform.match(/\d+/g); ;
    //     var d = parseFloat(-c[1]);
    //     var h = parseFloat(-c[2]);
    //     el.style.transform = "none";
    //     el.style.left = d/2;
    //     el.style.top = h/2;
    // })

    let header = "Kort";

    // Check input
    let qualityScale = 1;
    if (qScale) {
        qualityScale = qScale;
    }

    // Forest
    if (forestData.forest) {
        header = `Skovkort - ${forestData.forest}${forestData.forestOwner !== "" ? ", " + forestData.forestOwner : ""}`
    }

    // Biotope
    if (forestData.biotopePlanName && forestData.biotopePlan) {
        header = `Biotopplanskort - ${forestData.biotopePlanName}, ${forestData.biotopePlan}`
    }

    // Create new container
    let containerDiv = document.createElement('div');

    // Create title
    let titleDiv = document.createElement('div');
    const titleText = header;
    titleDiv.setAttribute("style", "font-size:30px; font-family: helvetica; text-align:left; padding: 20px 0px 10px 23px");
    titleDiv.innerHTML = titleText;

    // Create footer
    let footerDiv = document.createElement('div');
    if (printProfile === "HedeDanmark") {
        // HD Image for footer
        let footerImg = document.createElement('img');
        footerImg.src = require("./HD-logo-long.png");
        footerImg.setAttribute("style", "height: 25px; width: 352px");
        footerDiv.setAttribute("style", "text-align:center; padding:13px")
        footerDiv.appendChild(footerImg);
    } else if (printProfile === "Skovdyrkerne") {
        let footerImg = document.createElement('img');
        // footerImg.src = require("./skovdyrkerne_only_logo.png");
        footerImg.src = require("./logoSDprintmap.png");
        footerImg.setAttribute("style", "height: 20px; width: 204px"); // h:35px, w:94px
        footerDiv.setAttribute("style", "text-align:center; padding:15px") //p:8px
        footerDiv.appendChild(footerImg);
    } else {
        footerDiv.setAttribute("style", "font-family: helvetica; font-style: italic; text-align:center; font-size:18px; padding:15px;")
        footerDiv.innerHTML = forestData.company === '' ? 'Skovkortet.dk' : forestData.company + ' - Skovkortet.dk';
    }

    // Create compass
    let imgPlacementDiv = document.createElement('div');
    imgPlacementDiv.setAttribute("style", "display:flex; position: absolute; top: 86px; right: 0px; padding: 8px; background-color: white; border-radius: 8px; margin: 0px 41px 72px 0px; z-index: 1000; font-size:18px; border:1px solid #d6d6d6");
    let compassImg = document.createElement('img');
    compassImg.src = require("./nordpil.png");
    compassImg.setAttribute("alt", "north-arrow");
    // Place imgage inside div
    imgPlacementDiv.appendChild(compassImg);

    // Create date stamp
    let dateContainerDiv = document.createElement('div');
    dateContainerDiv.setAttribute("style", "display:flex; position: absolute; bottom: 0px; left: 0px; padding: 8px; background-color: white; border-radius: 8px; margin: 0px 0px 68px 40px; z-index: 1000; font-size:18px; border:1px solid #d6d6d6");
    dateContainerDiv.innerHTML = `${moment().format('DD[-]MM[-]YYYY HH:mm')}`;

    // Create scale
    let scaleContainerDiv = document.createElement('div');
    scaleContainerDiv.setAttribute("style", "display:flex; position: absolute; bottom: 0px; right: 0px; background-color: white; border-radius: 8px; margin: 0px 41px 68px 0px; z-index: 1000; border:1px solid #d6d6d6");

    let scaleRulerDiv = document.createElement('div');
    scaleRulerDiv.innerHTML = printScale.rulerText;
    scaleRulerDiv.setAttribute("style", `font-size: 18px; font-family: sans-serif; font-color: #333333; margin: 9px 9px 9px 0px; padding: 2px 2px 2px 6px; border-style: hidden solid solid solid; border-width: 4px; border-color: #757575; width: ${printScale.rWidth}`);

    let scaleFactorDiv = document.createElement('div');
    scaleFactorDiv.innerHTML = printScale.sfText + " @A1";
    // scaleFactorDiv.innerHTML = "1:3.250";
    scaleFactorDiv.setAttribute("style", "font-size: 18px; font-family: sans-serif; font-color: #333333; margin: 9px 0px 9px 9px; padding: 2px 6px 2px 2px; border-style: hidden hidden solid hidden; border-width: 4px; border-color: #757575;");

    // Append the scalefactor and ruler to the main div.
    scaleContainerDiv.appendChild(scaleFactorDiv)
    scaleContainerDiv.appendChild(scaleRulerDiv)

    let tt = mapData.getAttribute("style");
    const mapHeight = parseFloat(mapData.style.height.replace(/[^0-9]/g, '')) * 2.83465;
    const mapWidth = parseFloat(mapData.style.width.replace(/[^0-9]/g, '')) * 2.83465;
    mapData.setAttribute("style", tt + " margin-left: 26px;");

    let ctt = mapData.firstElementChild.getAttribute("style");
    mapData.firstElementChild.setAttribute("style", ctt + " background-color: transparent;");

    // Create legend
    let legendContainerDiv = document.createElement('div');
    legendContainerDiv.setAttribute("style", "position: absolute; top: 86px; margin: 0px 0px 78px 40px; z-index: 1000;");

    legend.forEach(item => {
        // Create section to legend
        let section = document.createElement('div');
        section.setAttribute("style", "margin-bottom: 10px; background-color: white; padding: 8px 8px 2px 8px; border-radius: 8px; border:1px solid #d6d6d6")

        item.forEach((d, i) => {
            // Create row to section
            let row = document.createElement('div');

            let symbole = document.createElement('div');
            let innerSymbole = document.createElement('div');

            if (d.type === 'area' || d.type === 'solidArea') {
                if (!d.id || d.type === 'solidArea') {
                    // Litra & Skovrejsning
                    if (cellOpacityState) {
                        symbole.setAttribute("style", `height: 30px; width: 30px; margin-right: 8px; background-color: ${d.color}; border: 1px solid #CCCCCC; border-radius: 3px`);
                    } else if (i === 0) {
                        symbole.setAttribute("style", `height: 30px; width: 30px; margin-right: 8px; background-color: #F2EFE8; border: 1px solid #CCCCCC; border-radius: 3px; display: flex; justify-content: center; align-items: center`);
                        innerSymbole.setAttribute("style", `height: 24px; width: 24px; background-color: #F2EFE8; border: 3px solid white; border-radius: 3px`);
                        symbole.appendChild(innerSymbole);
                    }
                } else {
                    symbole.setAttribute("style", "height: 30px; width: 30px; margin-right: 8px");

                    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                    svg.setAttribute("version", "1.1");
                    svg.setAttribute("width", "30");
                    svg.setAttribute("height", "30");

                    let path = document.createElementNS("http://www.w3.org/2000/svg", "path");

                    // Beskyttet natur
                    if (d.id === 'beskyttetNaturtyper') {
                        path.setAttribute("d", "M0,0 L30,0 L30,30 L0,30 L0,0 Z M4.01985973,25.9917936 L20.033502,25.9917936 L4.01985973,10.070177 L4.01985973,25.9917936 Z M4.04770339,4.04910413 L4.04770339,7.99521165 L22,26.0352109 L26.0102138,26.0352109 L26.0102138,10.0708022 L19.9999028,4.04910413 L4.04770339,4.04910413 Z M26.0102138,4.04910413 L22.0292787,4.04910413 L26.0102138,8.04820837 L26.0102138,4.04910413 Z");
                        path.setAttribute('style', `fill: ${d.color}`);
                    }

                    // Fredskov
                    if (d.id === 'fredskov') {
                        path.setAttribute("d", "M0,0 L30,0 L30,30 L0,30 L0,0 Z M4,4 L4,26 L26,26 L26,4 L4,4 Z M9.97800666,14.2336895 L11.05498,11.8147678 C12.2269197,11.6766329 13.3634171,11.8572712 14.464472,12.3566827 C17.829524,13.841115 19.3929916,17.6631097 17.8391411,21.1531151 C16.2852905,24.6431205 12.424515,26.0288171 9.04680439,24.5249634 C7.98992046,24.0544084 7.09413863,23.3331142 6.3594589,22.3610808 L7.42941139,19.9579282 C7.87406796,21.2193913 8.69208777,22.1153419 9.88347084,22.6457798 C11.9624169,23.5713862 14.8420787,22.7262917 15.9224458,20.2997474 C17.0028129,17.8732031 15.6465867,15.2966054 13.5676406,14.370999 C12.3883253,13.845934 11.1917807,13.8001641 9.97800666,14.2336895 Z M18.1752013,7.41351478 L19.0321712,5.48872902 C19.7351457,5.4058704 20.4168604,5.51422421 21.0773155,5.81379042 C23.0958029,6.70420991 23.9936546,9.11208481 23.1015716,11.0902285 C22.2094887,13.0683722 19.8536709,14.0148618 17.8275903,13.1127926 C17.1936307,12.8305356 16.6563064,12.3978757 16.2156173,11.8148127 L16.9799893,10.098005 C17.3209422,10.6879539 17.8487374,11.1420168 18.5633747,11.4601938 C19.712381,11.9717644 21.0075093,11.5416298 21.5216682,10.3868101 C22.035827,9.23199049 21.4929137,7.97262248 20.3439074,7.4610519 C19.6365088,7.14609775 18.9136068,7.13025204 18.1752013,7.41351478 Z")
                        path.setAttribute('style', `fill: ${d.color}`);
                    }

                    svg.appendChild(path);
                    symbole.appendChild(svg);
                }
            } else if (d.id === 'natura2000') {
                symbole.setAttribute("style", "height: 30px; width: 30px; margin-right: 8px");

                let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("version", "1.1");
                svg.setAttribute("width", "30");
                svg.setAttribute("height", "30");

                let path = document.createElementNS("http://www.w3.org/2000/svg", "path");

                if (d.type === '0deglines') {
                    path.setAttribute("d", "M0,0 L30,0 L30,30 L0,30 L0,0 Z M4,4 L4,10 L26,10 L26,4 L4,4 Z M4,22 L4,26 L26,26 L26,22 L4,22 Z M4,12 L4,20 L26,20 L26,12 L4,12 Z");
                    path.setAttribute('style', `fill: ${d.color}`);
                }

                if (d.type === 'diagonalX') {
                    path.setAttribute("d", "M0,0 L30,0 L30,30 L0,30 L0,0 Z M4,14.0026394 L4,26 L9.24762211,26 L13.6116515,21.7223158 L4,12 L4,14.0026394 Z M26.0516515,14.0026394 L26.0516515,12 L16.44,21.7223158 L20.8040293,26 L26.0516515,26 L26.0516515,14.0026394 Z M4,4 L4,8.63005373 L8.69661446,4 L4,4 Z M26.0566145,4 L21.36,4 L26.0566145,8.63005373 L26.0566145,4 Z M14.9503599,4 L11.5981936,4 L5.1622293,10.3784501 L14.92,20.2214089 L14.92,20.2520339 L14.9351799,20.2367214 L14.9503599,20.2520339 L14.9503599,20.2214089 L24.7081306,10.3784501 L18.2721663,4 L14.9503599,4 Z M14.92,23.2163768 L11.9704632,26 L14.9503599,26 L17.8998967,26 L14.9503599,23.2163768 L14.9503599,23.1877247 L14.9351799,23.2020507 L14.92,23.1877247 L14.92,23.2163768 Z");
                    path.setAttribute('style', `fill: ${d.color}`);
                }

                svg.appendChild(path);
                symbole.appendChild(svg);
            } else if (d.type === 'point') {
                symbole.setAttribute("style", "height: 30px; width: 30px; margin-right: 8px; padding-left: 3px");

                let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("version", "1.1");
                svg.setAttribute("width", "30");
                svg.setAttribute("height", "30");

                let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
                path.setAttribute("d", d.path);
                path.setAttribute("width", "30");
                path.setAttribute("height", "30");
                path.setAttribute('style', `fill: #2D5C1E`);

                svg.appendChild(path);
                symbole.appendChild(svg);
            } else {
                if (d.type === 'line') {
                    symbole.setAttribute("style", `width: 30px; height: 7px; margin-right: 8px; background-color: ${d.color}; border: 1px solid #CCCCCC; border-radius: 3px`);
                } else {
                    let a = document.createElement('div');
                    let b = document.createElement('div');

                    if (d.type === 'dotted-line') {
                        a.setAttribute("style", `height: 5px; width: 12px; margin-right: 6px; background-color: ${d.color}`);
                        b.setAttribute("style", `height: 5px; width: 12px; background-color: ${d.color}`);
                    }

                    if (d.type === 'dotted-line-wide') {
                        a.setAttribute("style", `height: 5px; width: 20px; margin-right: 4px; background-color: ${d.color}`);
                        b.setAttribute("style", `height: 5px; width: 6px; background-color: ${d.color}`);
                    }

                    symbole.appendChild(a)
                    symbole.appendChild(b)
                    symbole.setAttribute("style", "display: flex; border: 1px solid #CCCCCC; border-radius: 3px; margin-right: 8px");
                }
            }

            row.appendChild(symbole);

            // Add only one legend if cells are transparent
            if (!d.id && d.type === 'area') {
                if (cellOpacityState) {
                    row.appendChild(document.createTextNode(d.name));
                    row.setAttribute("style", "display: flex; align-items: center; font-size: 20px; margin-bottom: 6px");
                } else if (i === 0) {
                    if (environment !== 'biotopePlan') {
                        row.appendChild(document.createTextNode('Litragrænse'));
                    } else {
                        row.appendChild(document.createTextNode('Tiltagsgrænse'));
                    }
                    row.setAttribute("style", "display: flex; align-items: center; font-size: 20px; margin-bottom: 6px");
                }
            } else {
                row.appendChild(document.createTextNode(d.name));
                row.setAttribute("style", "display: flex; align-items: center; font-size: 20px; margin-bottom: 6px");
            }
            section.appendChild(row);
        })

        legendContainerDiv.appendChild(section);
    })

    // Create final html for print
    containerDiv.appendChild(titleDiv);
    containerDiv.appendChild(imgPlacementDiv);
    containerDiv.appendChild(scaleContainerDiv);
    containerDiv.appendChild(dateContainerDiv);
    containerDiv.appendChild(mapData);
    containerDiv.appendChild(footerDiv);
    containerDiv.appendChild(legendContainerDiv);

    // Create PDF
    // Set format of PDF to fit with image
    let fmat = [mapHeight + 100, mapWidth + 40];
    // Set options for the print module

    let opt = {
        margin: 0,
        filename: titleText + '.pdf',
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: qualityScale, useCORS: true },
        jsPDF: { unit: 'pt', format: fmat, orientation: orientation } // portrait
    };
    try {
        html2pdf().set(opt).from(containerDiv).save();
    } catch (error) {
        console.error("Print error", error)
    }
    
}

export const printLabelParam = (zoomLevel, minFontSize, fontSize) => {
    // Parameters for scaling fontsize and placement parameters. Assume it to be linear dependent on zoom level
    // Font size
    const f_a = 2.5; //2.5;
    const f_b = -26; //-32;
    // Position top
    const pt_a = -5.3333;
    const pt_b = 86.6667;
    // Position left
    const pl_a = -2;
    const pl_b = 30;
    // Assume linear dependent on zoom level
    const fSize = fontSize === minFontSize ? f_a * zoomLevel + f_b : fontSize;
    const posTop = pt_a * zoomLevel + pt_b;
    const posLeft = pl_a * zoomLevel + pl_b;

    return { fSize, posTop, posLeft }
}