import env from './buildConfig';

// ... Dev ...
let reepayConfig = {
    env: 'development',
    auth: 'Basic cHJpdl8wZmNiY2QzZTZkYjg4ZjBiMTVlYjc5OGQxYmQ0MTNiMTo=',
    // Plans
    trialPlan: 'plan-b8625',
    dsTrialPlan: 'plan-1074e',
    freePlan: 'plan-a6ee8',
    naturePlanYear: 'plan-c8c68',
    naturePlanMonth: 'plan-37a6b',
    natureOperationPlusPlanYear: 'plan-b818b',
    natureOperationPlusPlanMonth: 'plan-1adbc',
    operationPlanYear: 'plan-88824',
    operationPlanMonth: 'plan-e3ed0',
    operationPlusPlanYear: 'plan-b818b',
    operationPlusPlanMonth: 'plan-1adbc',
    huntingPlanYear: 'plan-01ce1',
    huntingPlanMonth: 'plan-fb75d',
    huntingPlusPlanYear: 'plan-8a470',
    huntingPlusPlanMonth: 'plan-9e2a9',
    ownerUpToFiveForestsPlanYear: 'plan-6e8c8',
    ownerUpToFiveForestsPlanMonth: 'plan-aeca2',
    unlimitedUserAccessPlanYear: 'plan-83785',
    unlimitedUserAccessPlanMonth: 'plan-8f05a',
    // Discounts

    // pkey: 'pub_013af68427dc791b4ff19efe875de99e'
};

// ... Prod ...
if (env.stage === "production") {
    reepayConfig = {
        env: 'production',
        auth: 'Basic cHJpdl8wOTdjNTdhNGY3NzMyNTJhYzhkNjAxYjcyZGM2MmEyNjo=',  // <-- Live
        // Plans
        trialPlan: 'plan-3567b',
        dsTrialPlan: 'plan-2b71b',
        freePlan: 'plan-b4f2b',
        naturePlanYear: 'plan-8b8ce',
        naturePlanMonth: 'plan-99023',
        natureOperationPlusPlanYear: 'plan-7a8df',
        natureOperationPlusPlanMonth: 'plan-0d74a',
        operationPlanYear: 'plan-929aa',
        operationPlanMonth: 'plan-641e1',
        operationPlusPlanYear: 'plan-7a8df',
        operationPlusPlanMonth: 'plan-0d74a',
        huntingPlanYear: 'plan-a7767',
        huntingPlanMonth: 'plan-148d8',
        huntingPlusPlanYear: 'plan-dbd04',
        huntingPlusPlanMonth: 'plan-1282c',
        ownerUpToFiveForestsPlanYear: 'plan-7739f',
        ownerUpToFiveForestsPlanMonth: 'plan-954eb',
        unlimitedUserAccessPlanYear: 'plan-7ab23',
        unlimitedUserAccessPlanMonth: 'plan-3a016',
        // pkey: 'pub_96c87e057044e32960fa2b88188b5cab'
    };
}

export default reepayConfig;