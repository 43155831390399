import lineIntersect from '@turf/line-intersect';
import lineSplit from '@turf/line-split';

import union from '@turf/union';
import polylabel from 'polylabel';

//import pointInPolygon from '@turf/boolean-point-in-polygon';
const _ = require("underscore");


///////////////////////////////
// Exported functions
///////////////////////////////
export const scaleFactor = (map, scale) => {
    // Get the y,x dimensions of the map
    const y = map.getSize().y,
        x = map.getSize().x;
    // calculate the distance the one side of the map to the other using the haversine formula
    const maxMeters = map.containerPointToLatLng([0, y]).distanceTo(map.containerPointToLatLng([x, y]));
    // calculate how many meters each pixel represents
    const MeterPerPixel = maxMeters / x;
    // Format scale and return 
    return "1:" + Math.round((MeterPerPixel * scale.options.maxWidth) * 10).toString();
}

export const findMinMax = (arr) => {
    let min = arr[0], max = arr[0];
    for (let i = 1, len = arr.length; i < len; i++) {
        let v = arr[i];
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    }
    return [min, max];
}

export const distPointToBox = (bbox, p) => {
    var dx = Math.max(bbox.minLng - p.lng, 0, p.lng - bbox.maxLng);
    var dy = Math.max(bbox.minLat - p.lat, 0, p.lat - bbox.maxLat);

    return Math.sqrt(dx * dx + dy * dy);
}

export const placeLabelInsidePoly = (poly, bbox) => {
    // Calculate first guess of label placement
    let tempLabelCoords = polylabel(poly.slice(0, 4), 0.5) // Only include the first 4 holes in the polygon
    // Check if point is inside polygon and return point if true
    if (pointInsidePolygon(tempLabelCoords, poly)) {
        return tempLabelCoords;
    } else {
        // Point is not inside the polygon and needs to be moved
        // Create horizontal line that "cuts" through the polygon
        const line = [
            [tempLabelCoords[0], bbox[0]], [tempLabelCoords[0], bbox[2]]
        ]
        // Create geoJson features for the Turf library
        // Line geojson feature
        const geoJsonFeatLine = {
            type: 'Feature',
            geometry: {
                type: "LineString",
                coordinates: line,
            },
            properties: {}
        }
        // Polygon geojson feature
        const geoJsonFeatPoly = {
            type: 'Feature',
            geometry: {
                type: "Polygon",
                coordinates: poly,
            },
            properties: {}
        }
        // Split line with polygon and find lines segments inside the polygon
        let labelPoint = tempLabelCoords;
        try {
            let split = lineSplit(geoJsonFeatLine, geoJsonFeatPoly);
            let lineLength = 0;
            split.features.forEach((splitedPart, i) => {
                const lineMidPoint = [tempLabelCoords[0], (splitedPart.geometry.coordinates[0][1] + splitedPart.geometry.coordinates[1][1]) / 2];
                const tempLineLength = Math.abs(splitedPart.geometry.coordinates[0][1] - splitedPart.geometry.coordinates[1][1]);
                if (pointInsidePolygon(lineMidPoint, poly) && tempLineLength > lineLength) {
                    lineLength = tempLineLength;
                    labelPoint = lineMidPoint;
                }
            });
        } catch (error) {
            console.error("Error splitting to find new placement: ", error)
        }

        // Return label coordinates
        return labelPoint;
    }
}

export const pointInsidePolygon = (point, poly) => {
    let inside = false;
    let x = point[0], y = point[1];
    for (let ii = 0; ii < poly.length; ii++) {
        let polyPoints = poly[ii];
        for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            let xi = polyPoints[i][0], yi = polyPoints[i][1];
            let xj = polyPoints[j][0], yj = polyPoints[j][1];

            let intersect = ((yi > y) !== (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
    }
    return inside;
};

export const lineIntersectsPoly = (line, poly) => {
    if (line === undefined || poly === undefined) return;
    // Create geojson feature of line
    const geoLine = convertNewCellToGeoJson([line], 'Line');
    const geoJsonFeatLine = {
        type: 'Feature',
        geometry: {
            type: geoLine.type,
            coordinates: geoLine.coordinates,
        },
        properties: {}
    }
    // Check if line intersect any of the lines in the polygon
    const intersection = poly.map(el => {
        // Create line segments from polygon
        let linSeg = [];
        for (let i = 0; i < el.length; i++) {
            if (i === el.length - 1) {
                linSeg[i] = [el[i], el[0]]
            } else {
                linSeg[i] = [el[i], el[i + 1]]
            }
        }
        // Check if line of new polygon intersect any line of the existing polygon
        const intSect = linSeg.map(lin => {
            const geoLineSeg = convertNewCellToGeoJson([lin], 'Line');
            const geoJsonFeatLineSeg = {
                type: 'Feature',
                geometry: {
                    type: geoLineSeg.type,
                    coordinates: geoLineSeg.coordinates,
                },
                properties: {}
            }
            // Check if new line of polygon intersects with line of other polygon
            const linIntsect = lineIntersect(geoJsonFeatLine, geoJsonFeatLineSeg)
            if (linIntsect.features.length > 0) {
                const testPoint = geoLineSeg.coordinates.filter(el => {
                    return _.isEqual(linIntsect.features[0].geometry.coordinates, el)
                })
                // Ignore points on end of polygon lines
                if (testPoint.length > 0) {
                    return 0
                } else {
                    return 1
                }
            } else {
                return 0
            }
        })
        return intSect;
    })
    if (intersection.flat().reduce((a, b) => a + b, 0) > 0) {
        return true
    } else {
        return false
    }

}

export const convertNewCellToGeoJson = (data, type) => {
    let geo;
    let coords = [];
    if (type === 'Polygon' || type === 'Poly') {
        let coorMain = data[0].map(c => { return [c.lng, c.lat]; })
        // Check that the last coordinate set is equal to the first to form a ring
        const lastIdx = coorMain.length - 1;
        if (coorMain[0][0] !== coorMain[lastIdx][0] || coorMain[0][1] !== coorMain[lastIdx][1]) {
            coorMain.push([coorMain[0][0], coorMain[0][1]]);
        }
        // Fillin holes
        const len = data.length;
        coords[0] = coorMain;
        if (len > 1) {
            for (let i = 1; i < len; i++) {
                let hole = data[i].map(c => { return [c.lng, c.lat]; })
                if (hole.length > 0) {
                    const lastIdx = hole.length - 1;
                    if (hole[0][0] !== hole[lastIdx][0] || hole[0][1] !== hole[lastIdx][1]) {
                        hole.push([hole[0][0], hole[0][1]]);
                    }
                    coords[i] = hole;
                }
            }
        }
        // Return geojson object
        geo = {
            type: "Polygon",
            coordinates: coords
        }
    } else if (type === 'Line') {
        coords = data[0].map(c => {
            return [c.lng, c.lat];
        })
        geo = {
            type: "LineString",
            coordinates: coords
        }
    } else {
        geo = null;
    }
    return geo;
}

export const convexHullPoly = (polygonsLayer) => {
    try {
        const collection = Object.values(polygonsLayer._layers).map(poly => {
            const geoPoly = convertNewCellToGeoJson(poly._latlngs, 'Poly');
            // Close the polygon ring
            const coords = geoPoly.coordinates[0];
            geoPoly.coordinates[0].push(coords[0])
            const geoJsonFeatPoly = {
                type: 'Feature',
                geometry: {
                    type: geoPoly.type,
                    coordinates: geoPoly.coordinates,
                },
                properties: {}
            }
            return geoJsonFeatPoly;
        })
        let polygon = collection[0];
        for (let i = 1; i < collection.length; i++) {
            polygon = union(polygon, collection[i]);
        }
        return polygon;

    } catch (err) {
        console.error("Create convex hull around forest polygons, failed: ", err)
        return "error";
    }
}

export const LightenDarkenColor = (col, amt) => {
    let usePound = false;
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    let num = parseInt(col, 16);
    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

// Calculate the distance between two latitude/longitude points in km using the Haversine function
// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export const getDistanceFromLatLngInKm = (p1, p2) => {
    // Input
    //  - p1 is an object p1 = { lat: , lng: }
    //  - p2 is an object p2 = { lat: , lng: }
    const R = 6371; // Mean earth radius
    const dLat = deg2rad(p2.lat - p1.lat);  // deg2rad below
    const dLon = deg2rad(p2.lng - p1.lng);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(p1.lat)) * Math.cos(deg2rad(p2.lat)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
}
