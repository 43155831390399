import React, { Component } from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FlightTakeOffIcon from '@material-ui/icons/FlightTakeoffRounded';

import Grid from './components/Grid/Grid';
import ColorPickers from './components/ColorPickers/ColorPickers';
import { Graph } from './Graph';

import './index.css';

const SIZE = 12;
const COLORS = ['green', 'yellow', 'orange', 'red', 'blue',];

class ColorFlood extends Component {
  constructor(props) {
    super(props);
    this.incrementCount = this.incrementCount.bind(this);
    this.sliderInput = this.sliderInput.bind(this);
    this.restart = this.restart.bind(this);
    this.colorFill = this.colorFill.bind(this);
    this.state = {
      size: SIZE,
      graph: new Graph(SIZE),
      colors: COLORS,
      count: 0,
    }
  }

  incrementCount() {
    this.setState({
      count: this.state.count + 1
    });
  }

  restart() {
    this.setState({
      graph: new Graph(this.state.size),
      count: 0
    });
  }

  sliderInput(value) {
    this.setState({
      size: value,
      graph: this.newGrid(value, this.state.colors),
      count: 0
    });
  }

  colorFill(color) {
    this.state.graph.colorFill(color);
  }

  render() {

    return (
      <div className="content">
        <div style={{ marginTop: 15, marginBottom: 12, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', height: 55 }}>
          <ColorPickers colors={this.state.colors} clickHandler={this.colorFill} incrementCount={this.incrementCount} />
          <div>
            <Typography
              variant="body1"
              style={{ color: '#747474', marginBottom: -6 }}
            >
              Tryk
          </Typography>
            <Typography
              variant="h6"
            >
              {this.state.count}
            </Typography>
          </div>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginBottom: '8px', marginTop: '4px' }}
            onClick={(e) => this.restart()}
          >
            <FlightTakeOffIcon style={{ marginRight: '8px' }} />
            Prøv igen
          </Button>
        </div>
        <div style={{ justifyContent: 'center' }}>
          <Grid grid={this.state.graph} colors={this.state.colors} size={this.state.size} />
        </div>
      </div>
    );
  }
}

export default ColorFlood;
