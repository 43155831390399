import * as actionTypes from './actionTypes';

export const setFilterState = (filterState) => {
    return {
        type: actionTypes.SET_INVENTORY_FILTER_STATE,
        filterState: filterState
    };
};

export const setFilterCellIds = (filterCellIds) => {
    return {
        type: actionTypes.SET_INVENTORY_FILTER_CELL_IDS,
        filterCellIds: filterCellIds
    };
};

export const setFilterPointIds = (filterPointIds) => {
    return {
        type: actionTypes.SET_INVENTORY_FILTER_POINT_IDS,
        filterPointIds: filterPointIds
    };
}

export const setShowFilterInMap = (state) => {
    return {
        type: actionTypes.SET_SHOW_FILTER_IN_MAP,
        showFilterInMap: state
    };
};