import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material UI Icons
import InfoIcon from '@material-ui/icons/InfoRounded';

// Components
import MicrosoftButton from '../../components/UI/Buttons/MicrosoftButton';
import Terms from '../../components/Terms/Terms';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: '32px 0px',
        width: '100%'
    },
    divider: {
        backgroundColor: theme.palette.divider,
        height: 1,
        flexGrow: 1,
    },
    textFieldProgress: {
        position: 'absolute',
    },
}));

const SignUpStepWindowContent = (props) => {
    const classes = useStyles();

    const renderCheckBox = (title, text) => {
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Checkbox
                    checked={props.solution === title.toLowerCase()}
                    onChange={props.handleSolution}
                    name={title}
                    color="primary"
                />

                <div>
                    <Typography style={{ fontSize: 18, fontWeight: 500 }}>{title}</Typography>
                    <Typography>{text}</Typography>
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

            {/* ------- pick-solution ------- */}
            {props.step === 'pick-solution' && <div>
                <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormGroup>
                        <div style={{ marginBottom: 12 }}>{renderCheckBox('Skovejeren', 'Nøje udvalgte funktioner, skræddersyet til skovejeren')}</div>
                        <div style={{ marginBottom: 12 }}>{renderCheckBox('Skovforvalteren', 'Værktøjer, der sikrer en effektiv forvaltning af dine kunders ejendomme')}</div>
                        {renderCheckBox('Skovenheden', 'Dine unikke kundebehov, sammensat i en unik løsning')}
                    </FormGroup>
                </FormControl>
            </div>}

            {/* ------- terms-and-auth-provider ------- */}
            {props.step === 'terms-and-auth-provider' && <div>
                <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormGroup style={{ marginBottom: 24 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={props.terms}
                                    onChange={props.handleTerms}
                                    name='Brugervilkår'
                                    color="primary"
                                />

                                <Typography>Brugervilkår</Typography>
                            </div>

                            <IconButton
                                aria-label="info"
                                size="medium"
                                onClick={props.handleTermsView}
                            >
                                <InfoIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    </FormGroup>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <TextField
                            required
                            id="signupEmail"
                            label="E-mail"
                            type="email"
                            fullWidth
                            onChange={props.handleTextInput}
                            value={props.signupEmail}
                            variant="outlined"
                            disabled={props.loading}
                        />

                        {props.loading && <CircularProgress size={24} className={classes.textFieldProgress} />}
                    </div>

                    {props.MSButtonShown && <div><div style={{ display: 'flex', alignItems: 'center', margin: '24px 0px' }}>
                        <div className={classes.divider} />

                        <Typography style={{ margin: '0px 8px' }}>eller</Typography>

                        <div className={classes.divider} />
                    </div>

                        <MicrosoftButton
                            text='Opret konto med Microsoft'
                            disabled={props.MSButtonDisabled}
                            onClick={props.handleThirdPartyLogin}
                        />
                    </div>}
                </FormControl>
            </div>}

            {/* ------- set-password ------- */}
            {props.step === 'set-password' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl
                    required
                    error={props.passwordError}
                    component="fieldset"
                    className={classes.formControl}
                >
                    <TextField
                        required
                        id="signupPassword"
                        label="Adgangskode"
                        type="password"
                        autoComplete="current-password"
                        onChange={props.handleTextInput}
                        value={props.signupPassword}
                        variant="outlined"
                        style={{ marginBottom: 22 }}
                    />

                    <TextField
                        required
                        id="signupPasswordRepeat"
                        label="Gentag adgangskode"
                        type="password"
                        autoComplete="current-password"
                        onChange={props.handleTextInput}
                        value={props.signupPasswordRepeat}
                        variant="outlined"
                    />

                    {props.passwordError && <FormHelperText>{props.passwordErrorString}</FormHelperText>}
                </FormControl>
            </div>}

            <Terms
                terms={props.termsText}
                openTerms={props.openTerms}
                handleTermsClose={props.handleTermsView}
                showCloseButton={true}
                showAcceptButton={true}
                handleAcceptTerms={props.handleTerms}
            />
        </div>
    );
}

export default SignUpStepWindowContent;