import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseRounded';

const HeaderMenu = (props) => {
    return (
        <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <Typography
                variant="body1"
                style={{ fontWeight: 500 }}
            >
                {props.title}
            </Typography >

            <IconButton
                onClick={props.onPress}
                style={{ height: 30, width: 30, marginRight: -8 }}
            >
                <CloseIcon style={{ color: '#808080' }} />
            </IconButton>
        </div>
    );
}

export default HeaderMenu;