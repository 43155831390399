import React from 'react';
import { UserBar } from 'react-activity-feed';

// Material UI
// import GridList from '@material-ui/core/GridList';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

// Components
import CircularSpinner from '../UI/CircularSpinner/CircularSpinner';

// Components
import VideoTile from './VideoTile';

// Graphics
// import logo from './skovkortet_logo_wo_text.svg';
import logo from '../../assets/logos/logo.png';

const VideoBlock = props => {
    let time = null

    if (props.videos) {
        time = props.videos[0].snippet.publishedAt;
    }

    return (
        <Card style={{ marginBottom: 10 }}>
            <CardContent style={{ padding: 20 }}>
                <div style={{ margin: '-8px -1px 10px' }}>
                    <UserBar
                        username={'Skovkortets videokanal'}
                        avatar={logo}
                        timestamp={time}
                    />
                </div>

                <Divider style={{ margin: '0px -5px 16px' }} />

                {/* <GridList
                    style={{
                        flexWrap: 'nowrap',
                        transform: 'translateZ(0)',
                    }}
                    cols={2.5}
                    cellHeight='auto'
                    spacing={10}
                > */}
                <div style={{ margin: '0px -5px 0px' }}>
                    {props.videos ? props.videos.map((item, i) => {
                        return (
                            <VideoTile key={i} item={item} i={i} length={props.videos.length} />
                        )
                    }) : <div style={{ height: 60, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '-4px -5px 0px' }}>
                            <CircularSpinner />
                        </div>}
                </div>
                {/* </GridList> */}

                <Divider style={{ margin: '16px -5px -4px' }} />
            </CardContent>
        </Card>
    );
}

export default VideoBlock;