// import firebase from 'firebase';

// log data on firestore
// async function logDataFirebase(logId, data) {
//     var db = firebase.firestore();
//     try {
//         // Append data to log
//         const id = db.collection("testLog").doc(logId);
//         await id.update({
//             logData: firebase.firestore.FieldValue.arrayUnion(data)
//         });
//         // const refId = db.collection("testLog").doc(log).collection(logId).doc();
//         // await refId.set({
//         //     data,
//         // })
//     } catch (error) {
//         console.error(error, error.code);
//         if (error.code === "not-found") {
//             const refId = db.collection("testLog").doc(logId)
//             await refId.set({
//                 logData: []
//             })
//         }
//     }
// }

// Kalman filtering
class GPSKalmanFilter {
    constructor(decay = 3) {
        this.decay = decay
        this.variance = -1
        this.minAccuracy = 1
    }

    process (lat, lng, accuracy, timestampInMs) {
        if (accuracy < this.minAccuracy) accuracy = this.minAccuracy
    
        if (this.variance < 0) {
          this.timestampInMs = timestampInMs
          this.lat = lat
          this.lng = lng
          this.variance = accuracy * accuracy
        } else {
          const timeIncMs = timestampInMs - this.timestampInMs
    
          if (timeIncMs > 0) {
            this.variance += (timeIncMs * this.decay * this.decay) / 1000
            this.timestampInMs = timestampInMs
          }
    
          const _k = this.variance / (this.variance + (accuracy * accuracy))
          this.lat += _k * (lat - this.lat)
          this.lng += _k * (lng - this.lng)
    
          this.variance = (1 - _k) * this.variance
        }
    
        return {lat: this.lat, lng: this.lng}
      }
}

// Handle position data
const kalmanFilter = new GPSKalmanFilter();
export const handlePosData = (pos) => {
    //const coords = latLngMAFilter(pos.coords.latitude, pos.coords.longitude)

    // Filter coordinates with Kalman Filter
    const coords = kalmanFilter.process(pos.coords.latitude, pos.coords.longitude, pos.coords.accuracy, pos.timestamp)
    // const d = {
    //     timestamp: pos.timestamp,
    //     lat: pos.coords.latitude,
    //     mAvgLat: coords.lat,
    //     lng: pos.coords.longitude,
    //     mAvgLng: coords.lng,
    //     accuracy: pos.coords.accuracy,
    //     altitude: pos.coords.altitude,
    //     altitudeAccuracy: pos.coords.altitudeAccuracy,
    //     heading: pos.coords.heading,
    //     speed: pos.coords.speed,
    // }
    // logDataFirebase("test", d);
    // Calculate moving average
    return coords;
}

// Moving average filter
// let maData = [{ lat: 57.06, lng: 9.93 }, { lat: 57.06, lng: 9.93 }, { lat: 57.06, lng: 9.93 }, { lat: 57.06, lng: 9.93 }];
// const N = maData.length;
// const latLngMAFilter = (lat, lng) => {
//     // Update array
//     let meanLat = 0;
//     let meanLng = 0;
//     for (let i = N - 2; i >= 0; i--) {
//         maData[i + 1] = maData[i];
//         meanLat = meanLat + maData[i + 1].lat;
//         meanLng = meanLng + maData[i + 1].lng;
//     }
//     // Insert new data and calculate mean
//     maData[0] = { lat: lat, lng: lng };
//     meanLat = (meanLat + maData[0].lat) / N;
//     meanLng = (meanLng + maData[0].lng) / N;
//     return { lat: meanLat, lng: meanLng }
// }

