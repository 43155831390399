import React from 'react';

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Inventory from '../components/Inventory/Inventory';

const overviewMapInventory = (props) => {
    const lidarHeight = props.location.state.data.lidarHeight ? props.location.state.data.lidarHeight : '';
    const kwData = props.location.state.data.kwData ? props.location.state.data.kwData : {};
    const categories = props.location.state.categories ? props.location.state.categories : [];
    const registrations = props.location.state.registrations ? props.location.state.registrations : [];
    const previousSpecies = props.location.state.previousSpecies ? props.location.state.previousSpecies : '';
    const futureSpecies = props.location.state.futureSpecies ? props.location.state.futureSpecies : '';
    const parentId = props.location.state.data.biotopePlanId ? props.location.state.data.biotopePlanId : props.location.state.data.forestId;

    return (
        <Aux>
            <SideBar cardContent={
                <div>
                    <Inventory 
                        area = {props.location.state.data.area}
                        lineLength = {props.location.state.data.lineLength }
                        litra = {props.location.state.data.litra}
                        mass = {props.location.state.data.mass}
                        year = {props.location.state.data.year}
                        species = {props.location.state.data.species}
                        caution = {props.location.state.data.caution}
                        note = {props.location.state.data.note}
                        GeoJsonFeature = {props.location.state.geo}
                        lidarHeight = {lidarHeight}
                        id = {props.location.state.id}
                        speciesObjs = {props.location.state.data.speciesObjs}
                        forestId = {parentId}
                        environment = {props.location.state.data.environment}
                        kwData = {kwData}
                        categories = {categories}
                        registrations = {registrations}
                        previousSpecies = {previousSpecies}
                        futureSpecies = {futureSpecies}
                        />
                </div>
            } />
        </Aux>
    );
}

export default overviewMapInventory;