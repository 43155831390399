import React from 'react';

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Person from '../components/Person/Person';


const overviewMapPerson = (props) => {
    return (
        <Aux>
            <SideBar cardContent={
                <div>
                    <Person 
                    {...props.location.state}
                    />
                </div>
            } />
        </Aux>
    );
}

export default overviewMapPerson;