import env from './buildConfig';

// ... Dev ...
let configTools = {
    decryptEndPoint: "https://europe-west1-skovlivprod.cloudfunctions.net/tools-decrypt_v1"
};

// Same as dev
if (env.stage === "production") {
}

export default configTools;