import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Linkify from 'react-linkify';

// Material UI icons
import GoBackIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import SendIcon from '@material-ui/icons/SendRounded';

// Moment
import moment from 'moment';

// Components
import ChangeSaveButton from '../UI/Buttons/ChangeSaveButton';
import DeleteButton from '../UI/Buttons/DeleteButton';
import OutlinedButton from '../UI/Buttons/OutlinedButton';
import * as actions from '../../store/actions/index';

const styles = theme => ({
    root: {
        textAlign: 'left',
    },
    textField: {
        width: '100%',
    },
    card: {
        position: 'relative',
        margin: 'auto',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});

export class Customer extends React.Component {
    constructor(props) {
        super(props);
        // Fields are pre-loaded and note is made editable if it
        // is a new one - e.g. accessed without props
        this.state = {
            open: false,
            name: ("name" in this.props) ? this.props.name : '',
            forestName: ("forestName" in this.props) ? this.props.forestName : '',
            forestId: ("id" in this.props) ? this.props.id : '',
            address: ("address" in this.props) ? this.props.address : '',
            city: ("city" in this.props) ? this.props.city : '',
            zip: ("zip" in this.props) ? this.props.zip : '',
            phoneNumber: ("phoneNumber" in this.props) ? this.props.phoneNumber : '',
            email: ("email" in this.props) ? this.props.email : '',
            note: ("note" in this.props) ? this.props.note : '',
            change: this.props.forestName ? false : true,
            submit: false,
            newCustomerState: this.props.forestName ? false : true,
            saveLoading: false,
            deleteLoading: false,
            disabled: false,
            wantToInviteErr: false,
        };
    }

    componentDidUpdate() {
        // Save navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.successCustomer) {
            this.setState({
                change: false,
                saveLoading: false,
                disabled: false
            })

            // Route back
            let path = "/"
            if (this.props.location.state.prevPage === "/Customers") {
                path = "/Customers"
            }
            this.props.history.replace({
                pathname: path,
                state: {
                    prevPage: this.props.location.state.prevPage,
                }
            });
        }

        // Delete navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.deleteCustomer) {
            this.setState({
                change: false,
                deleteLoading: false,
                disabled: false
            })

            this.goBackClickHandler();
        }
    }

    componentWillUnmount() {
        // Show snackbar if customer is saved
        if (this.props.successCustomer) {
            this.props.onEnqueueSnackbar({
                message: 'Kunde er nu gemt!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            // Add event to log
            const eD = {
                event: this.props.id ? "Kunde gemt" : "Ny kunde oprettet",
                type: "Kunder",
                reference: this.state.name,
                private: false,
                forestName: this.state.forestName,
                userId: this.props.currentAuthUser.id,
                userName: this.props.currentAuthUser.name,
            }
            this.props.onAddEventToEventLog(this.props.id ? this.props.id : 'new', eD)
        }

        // Show snackbar if note is deleted
        if (this.props.deleteCustomer) {
            this.props.onEnqueueSnackbar({
                message: 'Kunde er nu slettet!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            // Add event to log
            const eD = {
                event: "Kunde slettet",
                type: "Kunder",
                reference: this.state.name,
                private: false,
                forestName: this.state.forestName,
                userId: this.props.currentAuthUser.id,
                userName: this.props.currentAuthUser.name,
            }
            this.props.onAddEventToEventLog(this.props.id, eD)
        }

        // Set reducer to initial state
        this.props.onUnmountCustomer();
        // Onboarding action if active
        if (this.props.onboardingActive && this.props.onboardingState === 'newCustomerCreate') {
            this.props.onSetOnboardingState('newCustomerEnd');
        }
    }

    goBackClickHandler = () => {
        // Choose which route to go back to
        let path = "/"
        if (this.props.location.state.prevPage === "/Customers") {
            path = "/Customers"
        }
        this.props.history.replace({
            pathname: path,
            state: {
                prevPage: this.props.location.state.prevPage,
            }
        });
    }

    onDeleteClickHandler = () => {
        // Delete customer on cloud
        const customerData = {
            id: this.props.id,
        }
        this.props.onDeleteCustomer(customerData, this.props.currentAuthUser.org);
        // Set selected forest to null if the selected forest is the one deleted
        if (customerData.id === this.props.selectedForest) {
            this.props.onSetCurrentlySelectedForestId(null);
        }

        // Send event to Analytics
        ReactGA.event({
            category: 'Customer',
            action: 'Customer deleted',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        this.setState({
            deleteLoading: true,
            disabled: true,
        })
    }

    onInvite = () => {
        if (this.state.email === '') {
            // Send snackbar
            this.props.onEnqueueSnackbar({
                message: 'Indtast email på kunde for at sende invitation!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                },
            });
            // Set to change mode
            this.setState({ change: true, submit: true })
        } else {
            // Register on analytics
            ReactGA.event({
                category: 'Customer',
                action: `Customer invite: ${this.state.name}`,
                label: `User: ${this.props.currentAuthUser.name}`
            });

            // Send email to us
            let content = {
                userData: this.props.currentAuthUser
            };
            this.props.onSendEmail('inviteCustomer', this.props.currentAuthUser.id, this.state.email, content);

            // Send snackbar
            this.props.onEnqueueSnackbar({
                message: 'Invitation af kunde igangsat!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            // Add event to log
            const eD = {
                event: "Kunde inviteret",
                type: "Kunder",
                reference: this.state.name,
                private: false,
                forestName: this.staet.forestName,
                userId: this.props.currentAuthUser.id,
                userName: this.props.currentAuthUser.name,
            }
            this.props.onAddEventToEventLog(this.props.id, eD)

            // Set state
            this.setState({ submit: false })
        }
    }

    changeSaveButton = () => {
        const { name, forestName, address, city, zip, phoneNumber, email, note, change } = this.state;
        const { orgId } = this.props;

        // Upload customer to cloud 
        if (change) {
            // Set submit state
            this.setState({
                submit: true
            })

            // Check if customer is incomplete
            if (name !== "" && forestName !== "" && address !== "" && city !== "" && zip !== "") {
                this.setState({
                    saveLoading: true,
                    disabled: true,
                })

                // Upload action
                const customerData = {
                    name,
                    forestName,
                    address,
                    city,
                    zip,
                    phoneNumber,
                    email,
                    note,
                    date: moment().format(),
                    id: this.props.id,
                }
                this.props.onCreateNewForest(customerData, orgId, this.props.currentAuthUser);

                // Send event to Analytics
                ReactGA.event({
                    category: 'Customer',
                    action: 'Customer created',
                    label: `User: ${this.props.currentAuthUser.name}`
                });

                // Switch states
                this.setState({
                    submit: false,
                    //newCustomerState: false,
                })
            }

            // Make customer editable
        } else {
            this.setState({
                change: true
            })
        }
    };

    noteTitle() {
        // When note is in editable state 
        if (this.state.change) {
            return (
                <div>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
                    >
                        <IconButton
                            onClick={this.goBackClickHandler}
                            style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                        >
                            <GoBackIcon />
                        </IconButton>

                        <Typography
                            variant="h5"
                            gutterBottom
                        >
                            Kunde
                        </Typography >
                    </div>
                </div>
            );
        }

        // When note is in non-editable state
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
            >
                <IconButton
                    onClick={this.goBackClickHandler}
                    style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                >
                    <GoBackIcon />
                </IconButton>

                <Typography
                    variant="h5"
                    gutterBottom
                >
                    {this.state.name}
                </Typography >
            </div>
        );
    };

    textField(key, label, content, errorEnabled, required, changeDisabled, changeShow) {
        const { classes } = this.props;
        if (this.state.change && changeShow === 'hidden') return null;

        // When profile is in editable state
        if (this.state.change && !changeDisabled) {
            return (
                <TextField
                    id={label}
                    label={label}
                    multiline
                    className={classes.textField}
                    value={content}
                    onChange={event => this.setState({ [key]: event.target.value })}
                    error={this.state[key] === "" && this.state.submit && errorEnabled}
                    required={required === 'required' ? true : false}
                    margin="normal"
                />
            );
        }

        // When profile is in non-editable state
        if (key !== 'name') {
            if (content !== '') {
                return (
                    <div style={{ marginTop: (this.state.change && changeDisabled) ? 18 : null }}>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            {label}
                        </Typography >
                        <div>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="_blank" href={decoratedHref} key={key}>{decoratedText}</a>
                            )}>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    {content}
                                </Typography >
                            </Linkify>
                        </div>
                    </div>
                );
            }
        }
    };

    renderContent() {
        const { name, forestName, forestId, address, city, zip, phoneNumber, email, note } = this.state;
        let errorEnabled = true;

        return (
            <div>
                {this.noteTitle()}
                {this.textField('name', 'Navn', name, errorEnabled, 'required')}
                {this.textField('forestName', 'Skov', forestName, errorEnabled, 'required')}
                {this.textField('forestId', 'Skov ID', forestId, false, '', true, 'hidden')}
                {this.textField('address', 'Adresse', address, errorEnabled, 'required')}
                {this.textField('zip', 'Postnummer', zip, errorEnabled, 'required')}
                {this.textField('city', 'By', city, errorEnabled, 'required')}
                {this.textField('phoneNumber', 'Telefonnummer', phoneNumber)}
                {this.textField('email', 'Email', email, errorEnabled)}
                {this.textField('note', 'Bemærkning', note, true, '', false)}
            </div>
        );
    }

    customerCancelDelete() {
        // When note is in non-editable state
        if (!this.state.change) {

        }
        // Set action menu
        const items = [
            {
                name: "Slet",
                text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.onDeleteClickHandler,
                buttonIcon: true
            },
        ]
        // When note is in editable state
        return (
            <DeleteButton
                items={items}
                deleteLoading={this.state.deleteLoading}
                disabled={this.state.disabled}
            />
        );
    }

    customerChangeSave() {
        let disableButton = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))) {
            disableButton = true;
        }
        // When customer is in editable state
        if (this.state.change) {
            return (
                <div style={{ display: 'flex' }}>
                    <OutlinedButton
                        buttonLabel='Annuller'
                        onPress={this.goBackClickHandler}
                        variant='text'
                        discrete={true}
                    />

                    <ChangeSaveButton
                        change={false}
                        onPress={this.changeSaveButton.bind(this)}
                        saveLoading={this.state.saveLoading}
                        disabled={this.state.disabled}
                    />
                </div>
            );
        }

        // When customer is in non-editable state
        return (
            <ChangeSaveButton
                change={true}
                onPress={this.changeSaveButton.bind(this)}
                saveLoading={this.state.saveLoading}
                disabled={this.state.disabled || disableButton}
            />
        );
    }

    inviteCustomer() {
        const { classes } = this.props;
        let disableButton = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))) {
            disableButton = true;
        }
        return (
            <OutlinedButton
                buttonLabel={'​Inviter kunde'}
                buttonIcon={<SendIcon className={classes.extendedIcon} />}
                iconLeft={true}
                onPress={this.onInvite.bind(this)}
                color="primary"
                disabled={disableButton}
            />
        )
    }

    handleChangeCustomer() {
        // Check if forest is already part of customers list
        if (this.props.customerIdList.indexOf(this.props.id) === -1) {
            // Not part of, so attach
            this.props.onAttachCustomerToUser(this.props.id)
        } else {
            // Part of, so remove
            this.props.onRemoveCustomerFromUser(this.props.id)
            if (this.props.id === this.props.selectedForest) {
                this.props.onSetCurrentlySelectedForestId(null);
            }
        }
        this.props.onSetTaskPlanningFilterState(null)
    }

    render() {
        const { classes } = this.props;
        const { title, text, change, newCustomerState } = this.state;

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        {this.renderContent()}
                    </CardContent>

                    <CardActions>
                        <div>
                            {!this.state.newCustomerState && <FormControlLabel
                                style={{ marginBottom: 8 }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        style={{ marginLeft: 16 }}
                                        checked={this.props.customerIdList.indexOf(this.props.id) === -1 ? false : true}
                                        onClick={this.handleChangeCustomer.bind(this)}
                                        disabled={this.props.networkStatus === 'offline'}
                                    />
                                }
                                label="Tilføj til mine aktive kunder"
                            />}

                            <div style={{ display: 'flex' }}>
                                {this.props.config.customer.changeButton !== 'hidden' && this.customerChangeSave()}
                                {!newCustomerState && change && title !== "" && text !== "" && this.customerCancelDelete()}
                                {this.props.config.customer.inviteButton !== 'hidden' && !newCustomerState && !change && this.inviteCustomer()}
                            </div>
                        </div>
                    </CardActions>
                </Card>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        config: state.appConfig.customerSidebar,
        networkStatus: state.layout.networkStatus,
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        orgId: state.org.orgId,
        successCustomer: state.customer.successCustomer,
        deleteCustomer: state.customer.deleteCustomer,
        selectedForest: state.customer.currentlySelectedForestId,
        customerIdList: state.customer.customerIdList,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateNewForest: (userData, orgId, currentUser) => dispatch(actions.createNewForest(userData, orgId, currentUser)),
        onAttachCustomerToUser: (forestId) => dispatch(actions.attachCustomerToUser(forestId)),
        onRemoveCustomerFromUser: (forestId) => dispatch(actions.removeCustomerFromUser(forestId)),
        onUnmountCustomer: () => dispatch(actions.unmountCustomer()),
        onDeleteCustomer: (customerData, orgId) => dispatch(actions.deleteCustomer(customerData, orgId)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onSetCurrentlySelectedForestId: (forestId) => dispatch(actions.setCurrentlySelectedForestId(forestId)),
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSendEmail: (type, userId, customerEmail, content) => dispatch(actions.sendEmail(type, userId, customerEmail, content)),
        onAddEventToEventLog: (forestId, data) => dispatch(actions.addEventToEventLog(forestId, data)),
        onSetTaskPlanningFilterState: (s) => dispatch(actions.setActivityFilterState(s)),
    };
}

Customer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Customer)));