import React from "react";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

// Other Components
import InnerList from './InnerList';
import { Droppable } from 'react-beautiful-dnd';

const styles = () => ({
    container: {
        margin: '8px',
        width: '1000px',
        display: 'flex',
        flexDirection: 'column',
    },
    taskList: {
        padding: '8px',
        transition: 'background-color 0.2s ease',
        flexGrow: '1',
        minHeight: '100px',
    }
});

const Column = (props) => {
    const { classes } = props;
    return (
        <div className={classes.container}>
            {/* Title of column */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Typography variant="h6">
                    {props.column.title}
                </Typography >

                <Typography variant="caption" style={{ marginBottom: 2 }}>
                    {props.tasks.filter(t => (t && t.display)).length}
                </Typography >
            </div>

            {/* Task list */}
            <Droppable droppableId={props.column.id}>
                {(provided, snapshot) => {
                    const style = {
                        backgroundColor: snapshot.isDraggingOver ? '#D7D7D7' : '#EFEFEF',
                        ...provided.droppableProps.style,
                    };
                    return (
                        <Card className={classes.taskList}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={style}
                        >
                            <InnerList
                                tasks={props.tasks}
                                dragDisabled={props.dragDisabled}
                                navigateToTaskOnClickHandler={props.navigateToTaskOnClickHandler}
                                deleteTaskOnClickHandler={props.deleteTaskOnClickHandler}
                            />

                            {provided.placeholder}
                        </Card>
                    );
                }}
            </Droppable>
        </div>
    )
};

Column.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Column);