import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    features: null,
    points: null,
    loading: false,
    success: false,
    attachSuccess: false,
    delete: false,
    error: null,
    redrawFeatures: false,
    redrawPoints: false,
    biotopePlansUser: {},
    biotopePlansOrg: {},
    currentlySelectedBiotopePlanId: null,
    updateBiotopePlanFlag: false,
    biotopePlanListActive: false,
};

const setCurrentlySelectedBiotopePlanId = (state, action) => {
    return updateObject(state, { currentlySelectedBiotopePlanId: action.currentlySelectedBiotopePlanId });
};

const setBiotopePlanListActive = ( state, action ) => {
    return updateObject( state, { biotopePlanListActive: action.state } );
};

const unmount = (state, action) => {
    return updateObject(state, {
        error: null,
        success: false,
        delete: false,
        attachSuccess: false,
    });
};

// Fetch the features from cloud
const fetchBiotopePlanFeaturesStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchBiotopePlanFeaturesFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchBiotopePlanFeaturesSuccess = (state, action) => {
    return updateObject(state, {
        features: { ...state.features, [action.id]: action.features },
        loading: false,
        redrawFeatures: true,
    });
};

// Fetch points from cloud
const fetchBiotopePlanPointsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchBiotopePlanPointsFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchBiotopePlanPointsSuccess = (state, action) => {
    return updateObject(state, {
        points: { ...state.points, [action.id]: action.points },
        loading: false,
        redrawPoints: true,
    });
};

// Fetch plans from cloud
const fetchBiotopePlanStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchBiotopePlanUserSuccess = (state, action) => {
    return updateObject(state, {
        biotopePlansUser: { ...state.biotopePlansUser, [action.id]: action.biotopePlan },
        loading: false
    });
};

const fetchBiotopePlanOrgSuccess = (state, action) => {
    return updateObject(state, {
        biotopePlansOrg: { ...state.biotopePlansOrg, [action.id]: action.biotopePlan },
        loading: false
    });
};

const fetchBiotopePlanFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const setRedrawBiotopePlanFeatures = (state, action) => {
    return updateObject(state, { redrawFeatures: action.state });
}

const setRedrawBiotopePlanPoints = ( state, action ) => {
    return updateObject( state, { redrawPoints: action.redrawPointsState } );
};

const saveBiotopePlanStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const saveBiotopePlanSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: true,
        error: null,
        updateBiotopePlanFlag: true,
    });
};

const saveBiotopePlanFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: false,
        error: action.error,
    });
}

const deleteBiotopePlanStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const deleteBiotopePlanSuccess = (state, action) => {
    const updatedBiotopePlansUser = { ...state.biotopePlansUser };
    delete updatedBiotopePlansUser[action.planId];

    const updatedBiotopePlansOrg = { ...state.biotopePlansOrg }
    delete updatedBiotopePlansOrg[action.planId];
    return updateObject(state, {
        loading: false,
        error: null,
        biotopePlansUser: updatedBiotopePlansUser,
        biotopePlansOrg: updatedBiotopePlansOrg,
        delete: true,
        updateBiotopePlanFlag: true,
    });
};

const deleteBiotopePlanFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const attachBiotopePlanToUser = (state, action) => {
    return updateObject(state, {
        loading: false,
        attachSuccess: true,
        updateBiotopePlanFlag: true,
    });
};

const removeBiotopePlanFromUser = (state, action) => {
    const updatedBiotopePlansUser = { ...state.biotopePlansUser };
    delete updatedBiotopePlansUser[action.planId];
    return updateObject(state, {
        biotopePlansUser: updatedBiotopePlansUser,
        loading: false,
        updateBiotopePlanFlag: true,
    });
};

const setUpdateBiotopePlanFlag = (state, action) => {
    return updateObject(state, { updateBiotopePlanFlag: action.updateBiotopePlanFlag });
};

// Delete features from state
const deleteBiotopePlanFeatures = (state, action) => {
    // Remove features from biotope plan list
    const updatedFeatures = { ...state.features };
    delete updatedFeatures[action.planId];
    // Remove points
    const updatedPoints = { ...state.points };
    delete updatedPoints[action.planId];
    return updateObject(state, {
        features: updatedFeatures,
        points: updatedPoints,
        redrawFeatures: true,
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENTLY_SELECTED_BIOTOPE_PLAN_ID: return setCurrentlySelectedBiotopePlanId(state, action);
        case actionTypes.SET_BIOTOPE_PLAN_LIST_ACTIVE: return setBiotopePlanListActive(state, action);

        case actionTypes.UNMOUNT_BIOTOPE_PLAN: return unmount(state, action);

        case actionTypes.SET_REDRAW_BIOTOPE_PLANS_FEATURES: return setRedrawBiotopePlanFeatures(state, action);
        case actionTypes.SET_REDRAW_BIOTOPE_PLAN_POINTS: return setRedrawBiotopePlanPoints(state, action);

        case actionTypes.FETCH_BIOTOPE_FEATURES_START: return fetchBiotopePlanFeaturesStart(state, action);
        case actionTypes.FETCH_BIOTOPE_FEATURES_SUCCESS: return fetchBiotopePlanFeaturesSuccess(state, action);
        case actionTypes.FETCH_BIOTOPE_FEATURES_FAIL: return fetchBiotopePlanFeaturesFail(state, action);

        case actionTypes.FETCH_BIOTOPE_POINTS_START: return fetchBiotopePlanPointsStart(state, action);
        case actionTypes.FETCH_BIOTOPE_POINTS_SUCCESS: return fetchBiotopePlanPointsSuccess(state, action);
        case actionTypes.FETCH_BIOTOPE_POINTS_FAIL: return fetchBiotopePlanPointsFail(state, action);
        

        case actionTypes.FETCH_BIOTOPE_PLAN_START: return fetchBiotopePlanStart(state, action);
        case actionTypes.FETCH_BIOTOPE_PLAN_USER_SUCCESS: return fetchBiotopePlanUserSuccess(state, action);
        case actionTypes.FETCH_BIOTOPE_PLAN_ORG_SUCCESS: return fetchBiotopePlanOrgSuccess(state, action);
        case actionTypes.FETCH_BIOTOPE_PLAN_FAIL: return fetchBiotopePlanFail(state, action);

        case actionTypes.SAVE_BIOTOPE_PLAN_START: return saveBiotopePlanStart(state,action);
        case actionTypes.SAVE_BIOTOPE_PLAN_SUCCESS: return saveBiotopePlanSuccess(state,action);
        case actionTypes.SAVE_BIOTOPE_PLAN_FAIL: return saveBiotopePlanFail(state,action);

        case actionTypes.DELETE_BIOTOPE_PLAN_START: return deleteBiotopePlanStart(state,action);
        case actionTypes.DELETE_BIOTOPE_PLAN_SUCCESS: return deleteBiotopePlanSuccess(state,action);
        case actionTypes.DELETE_BIOTOPE_PLAN_FAIL: return deleteBiotopePlanFail(state,action);

        case actionTypes.ATTACH_BIOTOPE_PLAN_TO_USER: return attachBiotopePlanToUser(state,action);
        case actionTypes.REMOVE_BIOTOPE_PLAN_FROM_USER: return removeBiotopePlanFromUser(state,action);

        case actionTypes.SET_UPDATE_BIOTOPE_PLAN_FLAG: return setUpdateBiotopePlanFlag(state,action);

        case actionTypes.DELETE_BIOTOPE_PLAN_FEATURES: return deleteBiotopePlanFeatures(state,action);

        default: return state;
    }
};

export default reducer;