import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

// Material UI Icons
import IconAutorenew from '@material-ui/icons/AutorenewRounded';
import ReplayIcon from '@material-ui/icons/ReplayRounded';


// Components

const styles = () => ({
    // Menu Containers
    menuContainer: {
        padding: 16,
    },
    // Label
    valueLabel: {
        top: -16,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    }
});

const usage = [{ label: 'Drift', checked: true }, { label: 'Intensiv drift', checked: true }, { label: 'Biodiversitet', checked: true }, { label: 'Urørt', checked: true }]

class Filter extends React.Component {
    valueLabelFormat(value) {
        return `${value.toString().split('.').join(',')}`;
    }

    render() {
        const {
            classes,
            categories,
            onCategoryChange,
            onCategoryChangeAll,
            categoriesAll,
            species,
            onSpecieChange,
            onSpecieChangeAll,
            speciesAll,
            ranges,
            onRangeChange,
            applyFilters,
            resetFilters,
            estimationSteps,
            onChangeEstimationSteps,
            futureThinning,
            onChangeFutureThinning,
        } = this.props; 

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                    <div>
                        {/* Anvendelse */}
                        {categories && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32, marginBottom: 16 }}>
                            <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                                Anvendelse
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={categoriesAll}
                                        onChange={onCategoryChangeAll}
                                        name="checkedAll"
                                        color="primary"
                                    />
                                }
                                label="Alle"
                            />

                            <Divider />

                            {categories.map((category, i) => (
                                <FormControlLabel
                                    key={i}
                                    control={
                                        <Checkbox
                                            checked={category.checked}
                                            onChange={onCategoryChange.bind(null, i)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={category.label}
                                />
                            ))}
                        </div>}

                        {/* Artvælger */}
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                            <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            {this.props.type === 'biotopePlan' ? "Tiltag" : "Arter"}
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={speciesAll}
                                        onChange={onSpecieChangeAll}
                                        name="checkedAll"
                                        color="primary"
                                    />
                                }
                                label="Alle"
                            />

                            <Divider />

                            {species.map((species, i) => (
                                <FormControlLabel
                                    key={i}
                                    control={
                                        <Checkbox
                                            checked={species.checked}
                                            onChange={onSpecieChange.bind(null, i)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={species.label}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Rangevælger */}
                    <div>
                        <Typography align='left' variant="body1" style={{ marginRight: 12, marginBottom: 12 }}>
                            {this.props.type === 'biotopePlan' ? "Tiltagsdata" : "Bevoksningsdata"}
                        </Typography>

                        <Button
                            color="primary"
                            onClick={applyFilters}
                            variant="outlined"
                            style={{ marginBottom: 8 }}
                            fullWidth={true}
                        >
                            Anvend filter

                            <IconAutorenew style={{ marginLeft: 6 }} />
                        </Button>

                        <div style={{ marginTop: 4, marginBottom: 8 }}>
                            <Divider />

                            {this.props.estimationActive && <div>
                                <div style={{ display: 'flex', alignItems: 'center', margin: '7px 12px 14px 0px' }}>
                                    <Typography align='left' variant="body1">
                                        Fremskrivning
                                    </Typography>

                                    <Chip size="small" variant="outlined" color="primary" label="beta" style={{ marginLeft: 5 }} />
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', width: 180, marginLeft: 7, marginRight: 10 }}>
                                    <Slider
                                        value={estimationSteps}
                                        onChange={onChangeEstimationSteps}
                                        valueLabelDisplay="on"
                                        valueLabelFormat={this.valueLabelFormat}
                                        aria-labelledby="range-slider"
                                        step={1}
                                        min={0}
                                        max={10}
                                        classes={{ valueLabel: classes.valueLabel }}
                                    />
                                </div>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={futureThinning}
                                            onChange={onChangeFutureThinning}
                                            name="checkedA"
                                            color="primary"
                                        />
                                    }
                                    label="Planlagte tyndinger"
                                />

                                <Divider />
                            </div>}
                        </div>

                        {ranges.map((item, i) => {
                            let content = null;
                            if (item.checked) {
                                // Setup data
                                let stepSize = 1;
                                let txtLabel = item.label;
                                if (item.id === 'area') {
                                    stepSize = 0.1;
                                    txtLabel = 'Areal [ha]'
                                }
                                if (item.id === 'year') {
                                    txtLabel = 'Alder'
                                }
                                content = <div
                                    key={i}
                                    style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}
                                >
                                    <Typography align='left' variant="body1" style={{ marginRight: 12, marginBottom: 14 }}>
                                        {txtLabel}
                                    </Typography>

                                    <div style={{ display: 'flex', alignItems: 'center', width: 180, marginLeft: 7, marginRight: 10 }}>
                                        <Slider
                                            value={item.newRange}
                                            onChange={onRangeChange.bind(null, i)}
                                            valueLabelDisplay="on"
                                            valueLabelFormat={this.valueLabelFormat}
                                            aria-labelledby="range-slider"
                                            step={stepSize}
                                            min={ranges[i].initRange[0]}
                                            max={ranges[i].initRange[1]}
                                            classes={{ valueLabel: classes.valueLabel }}
                                        />
                                    </div>
                                </div>
                            }
                            return content;
                        })}

                        <Divider style={{ marginBottom: 12 }} />

                        <Button
                            color="primary"
                            onClick={resetFilters}
                            fullWidth={true}
                        >
                            Nulstil filter

                            <ReplayIcon style={{ marginLeft: 6 }} />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

Filter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Filter)));
