import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import configTools from '../../backend/configTools';

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// Components
import SignUpCommercialWindow from './SignUpCommercialWindow';
import SignUpStepWindow from './SignUpStepWindow';
import SignInHDWindow from './SignInHDWindow';
import SignInWindow from './SignInWindow';
import ForgotPasswordWindow from './ForgotPasswordWindow';

// Store
import * as actions from '../../store/actions/index';

// Image
import image from './Skovkortet-ipad.jpg';
import imageHD from './HedeDanmark_logo_RGB_reduced_padding.png';
import imageSLS from './sls_logo.png';
import imageSD from './skovdyrkerne_logo.png';

// Other stuff
let regex_email = /^\S+@\S+\.\S+$/;

// Parameters
const isMobile = 760;

const styles = (theme) => ({
    pageContainer: {
        position: 'absolute',
        right: '0px',
        left: '0px',
        top: '0px',
        bottom: '0px',
        overflow: 'auto',
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 8px 8px 8px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '28px 28px 10px 28px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '68px 68px 10px 68px',
        },
    },
    boxContainer: {
        backgroundColor: '#f4f5f6',
        borderRadius: 28,

        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
        [theme.breakpoints.up('md')]: {
            padding: 40,
        },

        maxWidth: 1180,
        margin: 'auto'
    },
});

export class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // Login 
            loginEmail: '',
            loginPassword: '',
            loginLoading: false,
            loginThirdPartyLoading: false,
            loginThirdPartyError: false,
            loginThirdPartyAlreadyExist: false,
            loginErrorMessage: '',
            loginLoading: false,

            // Signup
            steps: ['Vælg en løsning', 'Vælg login-metode', 'Vælg password'],
            activeStep: 0,
            solution: this.props.urlParamSolution !== '' ? this.props.urlParamSolution : '',
            solutionVersion: this.props.urlParamSolutionVersion !== '' ? this.props.urlParamSolutionVersion : '',
            affiliation: this.props.affiliation,
            signupEmail: '',
            terms: false,
            openTerms: false,
            signupPassword: '',
            signupPasswordRepeat: '',

            // Forgot password
            forgotPasswordEmail: '',
        };
    }

    componentDidMount() {
        this.props.onFetchTerms();
        // Check for error code - used when third-party login is atempted without a user
        if (this.props.errorCode !== '') {
            this.setState({
                loginThirdPartyError: true,
                loginErrorMessage: this.getErrorCode(this.props.errorCode)
            });
        }
        // Check for third party affiliations
        if (this.props.urlParam === 'sls' || this.props.urlParam === 'skovdyrkerne') {
            this.setState({
                solution: 'skovejeren',
                steps: ['Angiv email', 'Vælg password'],
            })
        }

        // Check for email in url parameters
        if (this.props.urlParamEmail !== '') {
            this.fetchDecryptedString(this.props.urlParamEmail);
        }
    }

    componentDidUpdate() {
        // Disable spinner and set error code
        if (this.props.errorCode !== "" && this.state.loginLoading) {
            this.setState({
                loginLoading: false,
                loginErrorMessage: this.getErrorCode(this.props.errorCode)
            })
        } else if (this.props.errorCode !== "" && this.state.loginThirdPartyLoading) {
            this.setState({
                loginThirdPartyLoading: false,
                loginThirdPartyError: true,
                loginErrorMessage: this.getErrorCode(this.props.errorCode),
            })
        }
    }

    fetchDecryptedString = async (inputString) => {
        this.setState({loginLoading: true, loginThirdPartyLoading: true});
        try {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            const url = configTools.decryptEndPoint + "?input_string=" + inputString;
            const response = await fetch(url, requestOptions);
            const respJson = await response.json();
            // Check validity of email
            if (regex_email.test(respJson.data.decryptedString)) {
                // Preload by setState
                this.setState({ signupEmail: respJson.data.decryptedString, loginEmail: respJson.data.decryptedString})
            }
            this.setState({loginLoading: false, loginThirdPartyLoading: false});
        } catch (error) {
            console.error(error);
            this.setState({loginLoading: false, loginThirdPartyLoading: false});
        }
    }

    getErrorCode(code) {
        switch (code) {
            case "auth/invalid-email":
                return "Fejl i e-mail. Prøv igen!";
            case "auth/user-not-found":
                return "Forkert e-mail. Prøv igen!";
            case "auth/wrong-password":
                return "Forkert adgangskode. Prøv igen!";
            case "auth/third-party-user-not-found":
                return "Konto eksistere ikke. Opret konto først!";
            case "auth/no-hd-user-affiliation-data-available":
                return "Din portal-konto er endnu ikke tilknyttet Skovkortet.dk. Kontakt venligst HedeDanmark."
            case "auth/popup-closed-by-user":
                return "Tredjeparts login-boks blev lukket før login var gennemført. Prøv igen!"
            case "auth/email-already-in-use":
                return "E-mail adresse er allerede tilknyttet en konto. Kontakt os!";
            case "auth/invalid-credential":
                return "Du har muligvis ikke givet Skovkortet.dk lov til at anvende din Microsoft konto!"
            case "auth/account-exists-with-different-credential":
                this.setState({ loginThirdPartyAlreadyExist: true })
                return "E-mail adresse er allerede tilknyttet en konto. Kontakt os!"
            default:
                return "Fejl ved login. Kontakt os!";
        }
    }

    handleThirdPartyLogin = () => {
        // Set login loading
        this.setState({ loginThirdPartyLoading: true });
        // Set user role according to urlParam
        let userRole = this.state.solution === 'skovejeren' ? "forestOwner" : "forester"
        if (this.props.urlParam === 'hededanmark') userRole = "forestOwner";

        // Create additional data object
        const additionalData = {
            originUrl: this.props.urlParam,
            userRole: userRole,
            acceptedTerms: this.state.terms,
            email: this.state.loginEmail,
            signUpObject: {
                affiliation: this.state.affiliation,
                solution: this.state.solution,
                solutionVersion: this.state.solutionVersion
            }
        };
        // Send event to Analytics 
        ReactGA.event({
            category: 'SignIn/SignUp',
            action: 'User signing in/up using third party provider',
            label: `User: not yet set (signing in/up)`
        });
        // Send event to Facebook Pixels
        ReactPixel.track("CompleteRegistration", { currency: "DKK", value: 0.00 });
        // Dispatch 
        this.props.onSignInUserThirdParty(additionalData);
    }

    handleThirdPartyIgnoreUserExist = () => {

    }

    handleLogin = () => {
        // User login
        const { loginEmail, loginPassword } = this.state;

        // Send event to Analytics 
        ReactGA.event({
            category: 'SignIn/SignUp',
            action: 'User signing in with email and password',
            label: `User: not yet set (signing in/up)`
        });

        if (loginEmail !== '' && loginPassword !== '') {
            this.setState({
                loginLoading: true,
            });
            this.props.onSignInUser(loginEmail, loginPassword);
        }
    }

    handleCreateNewUser = () => {
        // Set login loading
        this.setState({ loginLoading: true });
        // TODO : Fix when new user role has been introduced
        // Map solution choice to current userRole element
        let userRole = this.state.solution === 'skovejeren' ? "forestOwner" : "forester";
        // Create user data object
        let data = {
            email: this.state.signupEmail,
            passwordNew: this.state.signupPassword,
            userRole: userRole,
            acceptedTerms: this.state.terms,
            signUpObject: {
                affiliation: this.state.affiliation,
                solution: this.state.solution,
                solutionVersion: this.state.solutionVersion
            }
        }
        // Create affiliation data if any
        let affData = [];
        // SLS Specific
        if (this.props.urlParam === 'sls') {
            affData.push({ name: 'sls', status: 'active' });
            data.signUpObject.affiliation = 'sls';
        }
        // Skovdyrkerne specific
        if (this.props.urlParam === 'skovdyrkerne') {
            affData.push({ name: 'skovdyrkerne', status: 'active' });
            data.signUpObject.affiliation = 'skovdyrkerne';
        }
        // Send event to Facebook Pixels
        ReactPixel.track("CompleteRegistration", { currency: "DKK", value: 0.00 });
        // Send event to Analytics 
        ReactGA.event({
            category: 'SignIn/SignUp',
            action: 'Create new user with email and password',
            label: `User: not yet set (signing in/up)`
        });
        this.props.onCreateNewUser(data, null, affData);
    }

    handleTextInput = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleSolution = (event) => {
        this.setState({ solution: this.state.solution === event.target.name.toLowerCase() ? '' : event.target.name.toLowerCase() });
    }

    handleTerms = () => {
        this.setState({ terms: this.state.openTerms ? true : !this.state.terms, openTerms: false });
    }

    handleTermsView = () => {
        this.setState({ openTerms: !this.state.openTerms });

        // Send event to Analytics
        ReactGA.event({
            category: 'SignIn/SignUp',
            action: 'Terms opened',
            label: `User: not yet set (terms opened)`
        });
    }

    handleNewPassword = () => {
        if (this.state.forgotPasswordEmail !== '') {
            // Send event to Analytics 
            ReactGA.event({
                category: 'SignIn/SignUp',
                action: 'Forgot password',
                label: `User: not yet set (signing in/up)`
            });
            this.props.onResetPassword(this.state.forgotPasswordEmail)
        }
    }

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    }

    handleNext = () => {
        if (this.state.activeStep + 1 === this.state.steps.length) {
            this.handleCreateNewUser();
        } else {
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
    }

    getStepContent(step, urlParam) {
        if (urlParam === 'sls' || urlParam === 'skovdyrkerne') {
            switch (step) {
                case 0:
                    return 'terms-and-auth-provider';
                case 1:
                    return 'set-password';
                default:
                    return 'Unknown step';
            }
        } else {
            switch (step) {
                case 0:
                    return 'pick-solution';
                case 1:
                    return 'terms-and-auth-provider';
                case 2:
                    return 'set-password';
                default:
                    return 'Unknown step';
            }
        }
    }

    renderCommercialSide(mobile, urlParam) {
        return (
            <div style={{ width: '100%' }}>
                {urlParam === 'opret-konto' && <SignUpCommercialWindow
                    preHeader='Ikke medlem af Skovkortet.dk endnu?'
                    header='Opret en konto i 3 nemme trin'
                    text={'Har du endnu ikke en konto på Skovkortet.dk? Intet problem. Det tager få minutter at komme i gang. Det er gratis at oprette en konto, og du kan altid opgradere til en af vores betalte løsninger senere.'}
                    image={image}
                    imageStyle={{ width: '100%', maxHeight: 200, borderRadius: 28, objectFit: 'cover' }}
                    payOff={null}
                    mobile={mobile}
                />}

                {(urlParam === 'login' || urlParam === '') && <SignUpCommercialWindow
                    preHeader='Login'
                    header='Login på din konto'
                    text={'Skal skovkortet opdateres, eller vil du følge op på et par opgaver i skoven?\n\nNår du har oprettet dig i Skovkortet.dk, skal du blot Indtaste din valgte e-mail og din adgangskode eller logge ind med Microsoft – så er du allerede godt i gang.'}
                    image={null}
                    payOff={null}
                    mobile={mobile}
                />}

                {urlParam === 'glemt-adgangskode' && <SignUpCommercialWindow
                    preHeader='Glemt adgangskode'
                    header='Sådan nulstiller du din adgangskode'
                    text={'Har du glemt din adgangskode, da du oprettede dig?\n\nIndtast din e-mail. Findes din e-mail i vores system, vil du snarest modtage en e-mail, hvor du kan nulstille din adgangskode.'}
                    image={null}
                    payOff={null}
                    mobile={mobile}
                />}

                {urlParam === 'hededanmark' && <SignUpCommercialWindow
                    preHeader='Login'
                    header='HedeDanmark til Skovkortet.dk'
                    text='Login med din Microsoft-konto, og kom direkte til dit skovkort.'
                    image={imageHD}
                    imageStyle={{ width: '50%', maxHeight: 200, borderRadius: 0, objectFit: 'cover' }}
                    payOff={null}
                    mobile={mobile}
                />}

                {urlParam === 'sls' && <SignUpCommercialWindow
                    preHeader='Skovejer ved SLS A/S'
                    header='Opret login til Skovkortet.dk'
                    text='Her opretter du en konto, som er tilknyttet SLS A/S. Indtast e-mail og vælg din personlige adgangskode.'
                    image={imageSLS}
                    imageStyle={{ width: '55%', maxHeight: 200, borderRadius: 0, objectFit: 'cover' }}
                    payOff={null}
                    mobile={mobile}
                />}

                {urlParam === 'skovdyrkerne' && <SignUpCommercialWindow
                    preHeader='Skovejer ved Skovdyrkerne'
                    header='Opret login til Skovkortet.dk'
                    text='Her opretter du en konto, som er tilknyttet Skovdyrkerne. Indtast e-mail og vælg din personlige adgangskode.'
                    image={imageSD}
                    imageStyle={{ width: '55%', maxHeight: 200, borderRadius: 0, objectFit: 'cover' }}
                    payOff={null}
                    mobile={mobile}
                />}
            </div>
        )
    }

    renderActionsSide(mobile, urlParam, passwordError, passwordErrorString, MSButtonDisabled, nextButtonDisabled, loginButtonDisabled, forgotPasswordButtonDisabled) {
        let newLink = "/login";
        if (this.props.urlParamEmail) {
            newLink = "/login:" + this.props.urlParamEmail;
        }
        return (
            <div style={{ width: '100%' }}>
                {(urlParam === 'opret-konto' || urlParam === 'sls' || urlParam === 'skovdyrkerne') && <div>
                    <SignUpStepWindow
                        handleBack={this.handleBack}
                        handleNext={this.handleNext}
                        step={this.getStepContent(this.state.activeStep, urlParam)}
                        steps={this.state.steps}
                        activeStep={this.state.activeStep}
                        handleTextInput={this.handleTextInput}
                        handleSolution={this.handleSolution}
                        solution={this.state.solution}
                        handleTerms={this.handleTerms}
                        handleTermsView={this.handleTermsView}
                        terms={this.state.terms}
                        termsText={this.props.termsText}
                        openTerms={this.state.openTerms}
                        signupEmail={this.state.signupEmail}
                        passwordError={passwordError}
                        passwordErrorString={passwordErrorString}
                        MSButtonShown={(urlParam === 'sls' || urlParam === 'skovdyrkerne') ? false : true}
                        MSButtonDisabled={MSButtonDisabled}
                        nextButtonDisabled={nextButtonDisabled}
                        signupPassword={this.state.signupPassword}
                        signupPasswordRepeat={this.state.signupPasswordRepeat}
                        handleThirdPartyLogin={this.handleThirdPartyLogin}
                        mobile={mobile}
                        loading={this.state.loginLoading}
                    />

                    <div style={{ marginTop: 16, marginLeft: 28 }}>
                        <Link href={newLink} align='center' underline='always' style={{ fontSize: 16 }}>
                            Klik her, hvis du allerede har en konto
                        </Link>
                    </div>
                </div>}

                {urlParam === 'hededanmark' && <div>
                    <SignInHDWindow
                        handleThirdPartyLogin={this.handleThirdPartyLogin}
                        loadingThirdParty={this.state.loginThirdPartyLoading}  
                        loginThirdPartyError={this.state.loginThirdPartyError}
                        loginErrorText={this.state.loginErrorMessage}
                        loginThirdPartyAlreadyExist={this.state.loginThirdPartyAlreadyExist}
                        handleThirdPartyIgnoreUserExist={this.handleThirdPartyIgnoreUserExist}
                        mobile={mobile}
                    />
                </div>}

                {(urlParam === 'login' || urlParam === '') && <div>
                    <SignInWindow
                        handleThirdPartyLogin={this.handleThirdPartyLogin}
                        handleTextInput={this.handleTextInput}
                        emailValue={this.state.loginEmail}
                        passwordValue={this.state.loginPassword}
                        handleLogin={this.handleLogin}
                        loading={this.state.loginLoading}
                        loadingThirdParty={this.state.loginThirdPartyLoading}
                        buttonDisabled={loginButtonDisabled}
                        loginError={this.props.errorCode !== ''}
                        loginThirdPartyError={this.state.loginThirdPartyError}
                        loginErrorText={this.state.loginErrorMessage}
                        mobile={mobile}
                    />

                    <div style={{ marginTop: 16, marginLeft: 28 }}>
                        <Link href="/opret-konto" align='center' underline='always' style={{ fontSize: 16 }}>
                            Klik her, hvis du ønsker at oprette en konto
                        </Link>
                    </div>
                </div>}

                {urlParam === 'glemt-adgangskode' && <div>
                    <ForgotPasswordWindow
                        handleTextInput={this.handleTextInput}
                        emailValue={this.state.forgotPasswordEmail}
                        handleNewPassword={this.handleNewPassword}
                        buttonDisabled={forgotPasswordButtonDisabled}
                        mobile={mobile}
                    />
                </div>}
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        // Error and button logic for signup
        let passwordError = false;
        let passwordErrorString = '';
        let nextButtonDisabled = false;
        let MSButtonDisabled = false;

        if (this.state.activeStep === 0 && this.state.solution === '') {
            nextButtonDisabled = true
        }
        if (this.state.activeStep === 1 || ((this.props.urlParam === 'sls' || this.props.urlParam === 'skovdyrkerne') && this.state.activeStep === 0)) {
            if (!this.state.terms) {
                MSButtonDisabled = true;
            }
            if (!(this.state.terms && this.state.signupEmail !== '' && regex_email.test(this.state.signupEmail))) {
                nextButtonDisabled = true;
            }

        }
        if (this.state.activeStep === this.state.steps.length - 1) {
            if (this.state.signupPassword === '') {
                nextButtonDisabled = true;
            } else if (this.state.signupPassword.length < 6) {
                nextButtonDisabled = true;
                passwordError = true;
                passwordErrorString = 'Adgangskoden skal være minimum 6 tegn';
            } else {
                if (this.state.signupPassword !== this.state.signupPasswordRepeat) {
                    nextButtonDisabled = true;
                    passwordError = true;
                    passwordErrorString = 'Adgangskoderne skal være ens';
                }
            }
            if (this.state.loginErrorMessage !== '') {
                passwordError = true;
                passwordErrorString = this.state.loginErrorMessage;
            }

        }

        // Error and button logic for signin
        let loginButtonDisabled = false;
        if (this.state.loginEmail === '' || this.state.loginPassword === '' || !regex_email.test(this.state.loginEmail)) {
            loginButtonDisabled = true;
        }

        // Error logic for new password
        let forgotPasswordButtonDisabled = false;
        if (!regex_email.test(this.state.forgotPasswordEmail)) {
            forgotPasswordButtonDisabled = true;
        }

        return (
            <div className={classes.pageContainer}>
                <div className={classes.boxContainer}>
                    <Container maxWidth="md" style={{ padding: 0 }}>
                        <CssBaseline />

                        <Grid container spacing={isMobile ? 2 : 6}>
                            {/* desktop */}
                            <MediaQuery minWidth={isMobile + 1}>
                                <Grid item xs={6} container>
                                    {this.renderCommercialSide(false, this.props.urlParam)}
                                </Grid>

                                <Grid item xs={6} container>
                                    {this.renderActionsSide(false, this.props.urlParam, passwordError, passwordErrorString, MSButtonDisabled, nextButtonDisabled, loginButtonDisabled, forgotPasswordButtonDisabled)}
                                </Grid>
                            </MediaQuery>

                            {/* mobile */}
                            <MediaQuery maxWidth={isMobile}>
                                <Grid item xs={12} container>
                                    {this.renderCommercialSide(true, this.props.urlParam)}
                                </Grid>

                                <Grid item xs={12} container>
                                    {this.renderActionsSide(true, this.props.urlParam, passwordError, passwordErrorString, MSButtonDisabled, nextButtonDisabled, loginButtonDisabled, forgotPasswordButtonDisabled)}
                                </Grid>
                            </MediaQuery>
                        </Grid>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        termsText: state.user.userTerms,
        errorCode: state.user.errorCode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchTerms: () => dispatch(actions.fetchTerms()),
        onSignInUserThirdParty: (data) => dispatch(actions.signInUserThirdParty(data)),
        onSignInUser: (email, password) => dispatch(actions.signInUser(email, password)),
        onCreateNewUser: (data, userId, affiliationData) => dispatch(actions.createNewUser(data, userId, affiliationData)),
        onResetPassword: (email) => dispatch(actions.resetPassword(email)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn)));