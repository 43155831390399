import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Note from '../components/Note/NewNote';

class overviewMapNote extends Component {
    render() {
        const img = this.props.location.state.images ? this.props.location.state.images : [];
        const tag = this.props.location.state.tag ? this.props.location.state.tag : 'andet';
        const todoStatus = this.props.location.state.todoStatus ? this.props.location.state.todoStatus : 'Ikke startet';
        const todoTeam = this.props.location.state.todoTeam ? this.props.location.state.todoTeam : 'Ikke tildelt';
        const tasks = this.props.location.state.tasks ? this.props.location.state.tasks : [];
        const responsible = this.props.location.state.responsible ? this.props.location.state.responsible : [];
        const taskTypes = this.props.location.state.taskTypes ? this.props.location.state.taskTypes : [];
        const taskDeadline = this.props.location.state.taskDeadline ? this.props.location.state.taskDeadline : null;
        const priv = this.props.location.state.private !== undefined ? this.props.location.state.private : true;
        const userUID = this.props.location.state.userUID !== undefined ? this.props.location.state.userUID : '';
        const priority = this.props.location.state.priority !== undefined ? this.props.location.state.priority : 0;
        const creationDate = this.props.location.state.creationDate !== undefined ? this.props.location.state.creationDate : this.props.location.state.date;
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <Note
                            title={this.props.location.state.title}
                            date={this.props.location.state.date}
                            creationDate={creationDate}
                            text={this.props.location.state.text}
                            tag={tag}
                            todoStatus={todoStatus}
                            todoTeam={todoTeam}
                            tasks={tasks}
                            responsible={responsible}
                            taskTypes={taskTypes}
                            taskDeadline={taskDeadline}
                            private={priv}
                            from={this.props.location.state.from}
                            images={img}
                            id={this.props.location.state.id}
                            userUID={userUID}
                            priority={priority}
                            latlng={this.props.location.state.latlng}
                            markerId={this.props.location.state.markerId}
                            forestId={this.props.location.state.forestId}
                            litraNr={this.props.location.state.litraNr}
                        />
                    </div>
                } />
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(overviewMapNote));