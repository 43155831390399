import env from './buildConfig';

// ... Dev ...
let configMailChimp = {
    audKeyNews: "5b7123858b",
    audKeyGetStarted: "7e1734ed46",
    addUserToAudienceURL: "https://europe-west1-skovliv.cloudfunctions.net/mailChimpAddUserToAudience",
    removeUserFromAudienceURL: "https://europe-west1-skovliv.cloudfunctions.net/mailChimpRemoveUserFromAudience",
    addTagToUserFromAudienceURL: "https://europe-west1-skovliv.cloudfunctions.net/mailChimpAddTagToUserFromAudience",
    getUserTagFromAudienceURL: "https://europe-west1-skovliv.cloudfunctions.net/mailChimpGetAudienceUserTags"
};

// ... Prod ...
if (env.stage === "production") {
    configMailChimp = {
        audKeyNews: "c077de4c46",
        audKeyGetStarted: "5f0e63b485",
        addUserToAudienceURL: "https://europe-west1-skovlivprod.cloudfunctions.net/mailChimpAddUserToAudience",
        removeUserFromAudienceURL: "https://europe-west1-skovlivprod.cloudfunctions.net/mailChimpRemoveUserFromAudience",
        addTagToUserFromAudienceURL: "https://europe-west1-skovlivprod.cloudfunctions.net/mailChimpAddTagToUserFromAudience",
        getUserTagFromAudienceURL: "https://europe-west1-skovlivprod.cloudfunctions.net/mailChimpGetAudienceUserTags"
    };
}

export default configMailChimp;