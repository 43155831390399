import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Slider from '@material-ui/core/Slider';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Icons
import PrintIcon from '@material-ui/icons/PrintRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';

const styles = theme => ({
    fabBottom: {
        position: 'absolute',
        zIndex: 1,
        left: '179px',
        top: '10px',
        backgroundColor: 'white'
    },
    fabMenuBottom: {
        position: 'absolute',
        zIndex: 1,
        width: '200px',
        left: '179px',
        top: 60,
        backgroundColor: theme.palette.background.paper,
    },
    fabMenuMobile: {
        position: 'absolute',
        zIndex: 1,
        width: '190px',
        right: 68,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
    },
    drawButton: {
        margin: theme.spacing.unit,
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#009CDF',
        borderColor: '#009CDF',
        '&:hover': {
            backgroundColor: '#006d9c',
            borderColor: '#006d9c',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    drawButtonLight: {
        margin: theme.spacing.unit,
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#13b8ff',
        borderColor: '#13b8ff',
        '&:hover': {
            backgroundColor: '#006d9c',
            borderColor: '#006d9c',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    // Label
    valueLabel: {
        top: -16,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    }
});

class MapPrintMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            zIdx: 99,
        };
    }

    handleClick() {
        this.setState({ open: (this.state.open ? false : true) })

        // Send event to Analytics        
        ReactGA.event({
            category: 'Map',
            action: `Map print open: ${!this.state.open}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    render() {
        const { classes } = this.props;
        // Set text based on environment
        let infoText = "Litrainformation";
        let typeText = "Art";
        if (this.props.environment === "biotopePlan") {
            infoText = "Tiltagsinformation";
            typeText = "Tiltagstype";
        }
        // Check print orientation
        let orientationLabel = '';
        if (this.props.printOrientation === 'Portrait') {
            orientationLabel = 'Stående'
        } else if (this.props.printOrientation === 'Landscape') {
            orientationLabel = 'Liggende'
        } else if (this.props.printOrientation === 'Custom') {
            orientationLabel = 'Markering'
        } else {
            orientationLabel = 'Automatisk'
        }

        // Onboarding: Close print menu
        if (this.props.onboardingActive && this.props.onboardingState === 'welcome') {
            if (this.state.open) {
                this.setState({ open: false })
            }
        }

        const impZidx = this.props.onboardingActive ? this.props.onboardingDrawToolZidx : this.state.zIdx;

        // Disable according to account or HD
        const disablePrint =  this.props.config.print !== 'enabled';

        return (
            <div>
                <Tooltip title="Udskriv kort" aria-label="Udskriv kort">
                    <Fab
                        aria-label="PrintMap"
                        size="small"
                        style={{
                            position: 'absolute',
                            left: '179px',
                            top: '10px',
                            backgroundColor: this.props.listButtonColor,
                            zIndex: this.props.zIndex,
                        }}
                        onClick={this.handleClick.bind(this)}
                        disabled={this.props.listButtonDisable}
                    >
                        {this.state.open ? <CloseIcon style={{ color: '#808080' }} /> : <PrintIcon style={{ color: '#41842b' }} />}
                    </Fab>
                </Tooltip>

                {/* NORMAL VERSION */}
                {this.state.open && <Card
                    className={classes.fabMenuBottom}
                    style={{ zIndex: impZidx, paddingBottom: 4 }}
                >
                    <Typography
                        variant="body1"
                        style={{ fontWeight: 500, marginTop: 16, marginLeft: 16, marginRight: 16 }}
                    >
                        Udskrivning
                    </Typography >

                    <Typography
                        variant="body2"
                        style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}
                    >
                        Indstillinger
                    </Typography >

                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                style={{ marginLeft: 16 }}
                                checked={this.props.highQuality}
                                onClick={this.props.onClickHighQuality}
                                disabled={disablePrint}
                            />
                        }
                        label="Høj kvalitet"
                    />

                    <Divider style={{ margin: '0px 16px' }} />

                    <Typography
                        variant="body2"
                        style={{ margin: '8px 16px 0px 16px' }}
                    >
                        {infoText}
                    </Typography >

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    style={{ marginLeft: 16 }}
                                    checked={this.props.sectionAndNumberLabelsActive}
                                    onClick={() => this.props.onClickLabelsActive("sectionAndNumber")}
                                    disabled={disablePrint}
                                />
                            }
                            label="Afd. & nummer"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    style={{ marginLeft: 16 }}
                                    checked={this.props.speciesLabelsActive}
                                    onClick={() => this.props.onClickLabelsActive("species")}
                                    disabled={disablePrint}
                                />
                            }
                            label={typeText}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    style={{ marginLeft: 16 }}
                                    checked={this.props.yearLabelsActive}
                                    onClick={() => this.props.onClickLabelsActive("year")}
                                    disabled={disablePrint}
                                />
                            }
                            label="Årstal"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    style={{ marginLeft: 16 }}
                                    checked={this.props.lineLabelActive}
                                    onClick={this.props.onClickLineLabelsActive}
                                    disabled={disablePrint}
                                />
                            }
                            label="Linjelabels"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="secondary"
                                    style={{ marginLeft: 16 }}
                                    checked={this.props.whiteBackground}
                                    onClick={() => this.props.onClickLabelsActive("background")}
                                    disabled={disablePrint}
                                />
                            }
                            label="Labelbaggrund"
                        />
                    </div>

                    <Divider style={{ margin: '0px 16px' }} />

                    <Typography
                        variant="body2"
                        style={{ margin: '8px 16px 14px 16px' }}
                    >
                        Skriftstørrelse
                    </Typography >

                    <Fade in={this.props.fontSizeValue === this.props.minFontSize ? true : false}>
                        <Typography
                            variant="body2"
                            style={{ fontSize: 12, color: 'black', margin: '-12px 16px -4px 16px' }}
                        >
                            Auto
                        </Typography >
                    </Fade>

                    <div style={{ display: 'flex', alignItems: 'center', width: 156, marginLeft: 22, marginRight: 22, marginBottom: 4 }}>
                        <Slider
                            value={this.props.fontSizeValue}
                            onChange={this.props.onFontSize}
                            valueLabelDisplay={this.props.fontSizeValue === this.props.minFontSize ? "off" : "on"}
                            aria-labelledby="range-slider"
                            step={1}
                            min={this.props.minFontSize}
                            max={this.props.maxFontSize}
                            classes={{ valueLabel: classes.valueLabel }}
                            color="secondary"
                            disabled={disablePrint}
                        />
                    </div>

                    <Typography
                        variant="body2"
                        style={{ margin: '8px 16px 14px 16px' }}
                    >
                        Linjetykkelse
                    </Typography >

                    <Fade in={this.props.lineWeightValue === this.props.minLineWeight ? true : false}>
                        <Typography
                            variant="body2"
                            style={{ fontSize: 12, color: 'black', margin: '-12px 16px -4px 16px' }}
                        >
                            Auto
                        </Typography >
                    </Fade>

                    <div style={{ display: 'flex', alignItems: 'center', width: 156, marginLeft: 22, marginRight: 22, marginBottom: 4 }}>
                        <Slider
                            value={this.props.lineWeightValue}
                            onChange={this.props.onChangeLineWeight}
                            valueLabelDisplay={this.props.lineWeightValue === this.props.minLineWeight ? "off" : "on"}
                            aria-labelledby="range-slider"
                            step={1}
                            min={this.props.minLineWeight}
                            max={this.props.maxLineWeight}
                            classes={{ valueLabel: classes.valueLabel }}
                            color="secondary"
                            disabled={disablePrint}
                        />
                    </div>

                    <Divider style={{ margin: '0px 16px' }} />

                    <div>
                        <Typography
                            variant="body2"
                            style={{ margin: '8px 16px 0px 16px' }}
                        >
                            Layout
                        </Typography >

                        <div 
                            onClick={disablePrint ? null : this.props.onPrintOrientation}
                            style={{ display: 'flex', margin: '0px 16px', padding: '8px 0px', cursor: 'pointer', alignItems: 'flex-end' }}
                        >
                            <Typography style={{ color: disablePrint ? '#C5C5C5' : 'black' }}>
                                {orientationLabel}
                            </Typography>

                            <ChevronRightIcon style={{ marginLeft: 4, color: disablePrint ? '#C5C5C5' : 'black' }} />
                        </div>

                        {this.props.printOrientation === 'Custom' && <div style={{ margin: '-4px 16px 6px 16px' }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Klik Udskriv og marker efterfølgende området, der skal udskrives
                            </Typography >
                        </div>}

                        <Menu
                            id="basic-orientation-menu"
                            anchorEl={this.props.anchorEl}
                            open={Boolean(this.props.anchorEl)}
                            onClose={this.props.onClosePrintOrientation}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => this.props.onSelectOrientation('Auto')} selected={this.props.printOrientation === 'Auto'}>Automatisk</MenuItem>
                            <MenuItem onClick={() => this.props.onSelectOrientation('Custom')} selected={this.props.printOrientation === 'Custom'}>Markering</MenuItem>
                            <MenuItem onClick={() => this.props.onSelectOrientation('Portrait')} selected={this.props.printOrientation === 'Portrait'}>Stående</MenuItem>
                            <MenuItem onClick={() => this.props.onSelectOrientation('Landscape')} selected={this.props.printOrientation === 'Landscape'}>Liggende</MenuItem>
                        </Menu>
                    </div>

                    {/* {(this.props.heavyLayers && !disablePrint) && <Typography
                        variant="body2"
                        style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}
                    >
                        Obs: Nogen af de valgte baggrundslag kan tage lang tid at hente.
                    </Typography >} */}

                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.drawButtonLight}
                            //onClick={this.props.onPressAuto}
                            onClick={this.props.onPressPrint}
                            disabled={disablePrint}
                        >
                            Udskriv
                        </Button>
                    </div>

                    {/* <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.drawButton}
                            onClick={this.props.onPressCustom}
                            disabled={disablePrint}
                        >
                            Udskriv markering
                        </Button>
                    </div> */}

                    {disablePrint && <div style={{ marginLeft: 16, marginRight: 16 }}>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            Kort kan ikke udskrives med en gratis profil.
                        </Typography >
                    </div>}
                </Card>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        lineLabelsActive: state.maps.lineLabelsActive,
        config: state.appConfig.printMap,
    };
}

const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapPrintMenu));