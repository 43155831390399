import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import NaturePeopleIcon from '@material-ui/icons/NaturePeopleRounded';

const styles = theme => ({
    icon: {
        color: "white",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
});

class NaturePeopleAvatar extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Avatar className={classes.avatar}>
                    <NaturePeopleIcon className={classes.icon} />
                </Avatar>
            </div>
        );
    }
}

NaturePeopleAvatar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NaturePeopleAvatar);
