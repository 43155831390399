import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Configuration
import configMailChimp from '../../backend/configMailChimp';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Icons
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import IconChevronLeft from '@material-ui/icons/ChevronLeftRounded';
import SearchIcon from '@material-ui/icons/SearchRounded';

// Components
import DialogBox from '../Onboarding/DialogBox';
import * as actions from '../../store/actions/index';

// Other libs
const _ = require("underscore");

// Use google maps functionality from script loaded in index.html
const google = window.google = window.google ? window.google : {}

// Text
const Text = require('./Text.json');

// Parameters
const numStepsForester = 2;
const numStepsForestOwner = 3;
const numStepsForesterMobile = numStepsForester;
const numStepsForestOwnerMobile = numStepsForestOwner;
const isMobile = 760;

const styles = () => ({
    background: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
    },
});

export class Profile extends React.Component {
    state = {
        checked: true,
        userRole: this.props.currentAuthUser !== null ? this.props.currentAuthUser.userRole : "free",
        stepNum: 1,
        numSteps: 0,
        stage: 'welcome',
        prevStage: null,

        // New stuf
        signupName: this.props.currentAuthUser.name,
        signupCVR: '',
        signupCVRError: false,
        signupOrg: '',
        signupOrgErr: false,
        signupLoadingOrg: false,
        signupOrgConfirmed: false,
        signupForestSize: 0,
        signupForestName: '',
        signupForestNote: '',

        signupAddress: '',
        signupCity: '',
        signupZip: '',
        signupPhoneNumber: '',
        signupReason: '',
        signupLocationError: true,
    };

    componentWillMount() {
        // Set number of steps in state
        if (this.props.currentAuthUser.userRole === 'forester' || this.props.currentAuthUser.affiliation.HD || this.props.currentAuthUser.affiliation.sls || this.props.currentAuthUser.affiliation.skovdyrkerne) {
            if (window.innerWidth <= isMobile) {
                this.setState({ numSteps: numStepsForesterMobile });
            } else {
                this.setState({ numSteps: numStepsForester });
            }
        } else if (this.props.currentAuthUser.userRole === 'forestOwner') {
            if (window.innerWidth <= isMobile) {
                this.setState({ numSteps: numStepsForestOwnerMobile });
            } else {
                this.setState({ numSteps: numStepsForestOwner });
            }
        }
        // Route to root if not already there
        this.props.history.replace({
            pathname: "/",
        });
    }

    componentDidUpdate() {
        // Setup address auto complete when input is loaded
        if (this.state.stage === "location") {
            // Setup google places autocomplete
            const options = {
                language: 'da', // render results in DK
                region: 'dk', // prioritize matches within DK
                componentRestrictions: { country: "dk" },
                strictBounds: false,
                // fields: ["geometry", "name"],
            };

            try {
                this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('signup_address'), options)
                this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
            } catch (err) {
                console.error("Error: creating google places autocomplete instance", err)
            }
        }
    }

    componentWillUnmount() {

    }

    getCVR = () => {
        // Send event to Analytics 
        ReactGA.event({
            category: 'Profile',
            action: 'Get CVR',
            label: "CVR number: " + this.state.signupCVR
        });
        if (this.state.signupCVR.length < 8) {
            this.setState({ signupCVRError: true })
        } else {
            fetch("//cvrapi.dk/api?search=" + this.state.signupCVR + "&country=dk")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        signupOrg: data,
                        signupLoadingOrg: false,
                        signupOrgErr: data.error ? true : false,
                        signupCVRError: data.error ? true : false
                    })
                }).catch(err => {
                    console.error("Fetch CVR error", err)
                });
        }
    }

    handlePlaceSelect = () => {
        // Get place data from the search input
        let placeObject = null;
        try {
            placeObject = this.autocomplete.getPlace();
        } catch (err) {
            console.error("Error in fetching place", err)
        }
        if (!placeObject) {
            return;
        }
        // Send event to Analytics 
        ReactGA.event({
            category: 'Profile',
            action: 'Address search',
            label: "User: " + this.state.signupName !== '' ? this.state.signupName : "not yet set (creating profile)"
        });
        // If place object exists
        if (placeObject.address_components) {
            // Get address object
            const address_components = placeObject.address_components;
            // Find relevant values
            let city = '';
            let zip = '';
            let number = '';
            let road = '';
            address_components.forEach(item => {
                if (item.types.includes('street_number')) number = item.long_name;
                if (item.types.includes('route')) road = item.long_name;
                if (item.types.includes('postal_code')) zip = item.long_name;
                if (item.types.includes('sublocality')) city = item.long_name;
                if (item.types.includes('locality') && city === '') city = item.long_name;
            })
            // Set wrong address if we don't have enough information
            if (road === '' || city === '') {
                document.getElementById('signup_address').value = 'Ugyldig adresse';
            } else {
                // Collect data and set state
                let address = road;
                if (number !== '') address = address + ' ' + number;
                document.getElementById('signup_address').value = address + ', ' + zip + ' ' + city;
                this.setState({
                    signupAddress: address,
                    signupCity: city,
                    signupZip: zip,
                    signupLocationError: false,
                })
            }
        }
    }

    setLocation = (address, city, zip) => {
        // TODO : Can't preload google search element as it does not yet exist
        // document.getElementById('signup_address').value = address + ', ' + city + ', ' + zip;
        this.setState({
            signupAddress: address,
            signupCity: city,
            signupZip: zip,
        })
    }

    goToStage = (stage) => {
        switch (stage) {
            case 'welcome': this.setState({ stepNum: 1 }); break;
            case 'location': this.setState({ stepNum: 2 });
                if (this.state.signupAddress !== '' || this.state.signupCity !== '' || this.state.signupZip !== '') {
                    this.setLocation('', '', '')
                }
                break;
            case 'reason':
                if (this.props.currentAuthUser.userRole === 'forester' || this.props.currentAuthUser.affiliation.HD || this.props.currentAuthUser.affiliation.sls || this.props.currentAuthUser.affiliation.skovdyrkerne) {
                    this.setState({ stepNum: 2 }); 
                } else {
                    this.setState({ stepNum: 3 }); 
                }
                break;
            default:
        }

        this.setState({ stage: stage, prevStage: this.state.stage });
    }

    handleTextInput = (event) => {
        // Reset fetched org if changing string 
        if (event.target.id === "signupCVR" && this.state.signupOrg !== '') {
            this.setState({ signupOrg: '' });
            if (this.state.signupOrgErr) {
                this.setState({ signupOrgErr: false });
            }
        }
        this.setState({ [event.target.id]: event.target.value })
    }

    handleConfirmCVR = () => {
        // Send event to Analytics 
        ReactGA.event({
            category: 'Profile',
            action: 'Confirm error on CVR',
            label: "CVR number: " + this.state.signupCVR
        });
        if (this.state.signupCVRError) {
            this.setState({ signupOrgConfirmed: true, signupCVRError: false, signupOrgErr: false, signupOrg: {} })
        }
    }

    handleFinalise = () => {
        // Send event to Analytics 
        ReactGA.event({
            category: 'Profile',
            action: 'Finalize profile',
            label: "User: " + this.state.signupName
        });
        // Crate user data object
        let userData = {
            name: this.state.signupName,
            address: this.state.signupAddress,
            city: this.state.signupCity,
            zip: this.state.signupZip,
            phoneNumber: this.state.signupPhoneNumber,
            newUser: false,
        }
        // Create reepay data
        let reepayData = {
            ...userData,
            email: this.props.currentAuthUser.email,
            id: this.props.currentAuthUser.id,
            cvr: this.state.signupCVR,
            orgName: (this.state.signupOrg !== '' && !_.isEmpty(this.state.signupOrg)) ? this.state.signupOrg.name : '',
            dsStatus: (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.ds && this.props.currentAuthUser.affiliation.ds.status === 'member') ? true : false,
        }
        // MailChimp data
        let mailChimpData = {
            name: this.state.signupName,
            email: this.props.currentAuthUser.email,
        }

        // --- Forest Owner --- //
        // Create forest for owner
        if (this.state.userRole === 'forestOwner') {
            if (this.state.signupCVR !== '') {
                userData = { ...userData, cvrNumber: this.state.signupCVR };
            }
            if (!this.props.currentAuthUser.affiliation.HD && !this.props.currentAuthUser.affiliation.sls && !this.props.currentAuthUser.affiliation.skovdyrkerne) {
                const forestData = {
                    forestName: this.state.signupForestName,
                    name: this.state.signupName,
                    address: this.state.signupAddress,
                    city: this.state.signupCity,
                    zip: this.state.signupCity,
                    email: this.props.currentAuthUser.email,
                    phoneNumber: this.state.signupPhoneNumber,
                    note: this.state.signupForestNote
                }
                // Create forest for user
                this.props.onCreateNewForest(forestData);
            }
        } else {
            // --- Forester --- //
            // Create org for forester
            const orgData = {
                name: !_.isEmpty(this.state.signupOrg) ? (this.state.signupOrg.name ? this.state.signupOrg.name : '') : '',
                address: !_.isEmpty(this.state.signupOrg) ? (this.state.signupOrg.address ? this.state.signupOrg.address : '') : '',
                city: !_.isEmpty(this.state.signupOrg) ? (this.state.signupOrg.city ? this.state.signupOrg.city : '') : '',
                zip: !_.isEmpty(this.state.signupOrg) ? (this.state.signupOrg.zip ? this.state.signupOrg.zip : '') : '',
                phoneNumber: !_.isEmpty(this.state.signupOrg) ? (this.state.signupOrg.phone ? this.state.signupOrg.phone : '') : '',
                cvrData: this.state.signupOrg,
                email: !_.isEmpty(this.state.signupOrg) ? (this.state.signupOrg.email ? this.state.signupOrg.email : this.props.currentAuthUser.email) : this.props.currentAuthUser.email,
                cvr: this.state.signupCVR,
                dsStatus: (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.ds && this.props.currentAuthUser.affiliation.ds.status === 'member') ? true : false,
            }
            // Create org for forester
            this.props.onLinkUserAndOrg(orgData);
        }


        // --- Attach MailChimp to user --- //
        // Check for tags
        let tags = [];
        if (this.props.currentAuthUser.signUpObject && this.props.currentAuthUser.signUpObject.affiliation && this.props.currentAuthUser.signUpObject.affiliation !== '') {
            tags.push(this.props.currentAuthUser.signUpObject.affiliation);
        }
        if (this.props.currentAuthUser.signUpObject && this.props.currentAuthUser.signUpObject.solution && this.props.currentAuthUser.signUpObject.solution !== '') {
            tags.push(this.props.currentAuthUser.signUpObject.solution);
        }

        // Add user to news list
        this.props.onAttachMailChimp(configMailChimp.audKeyNews, mailChimpData, tags)
        // Check affiliation logic (HD users should not be added to get started audience)
        if (this.props.currentAuthUser.affiliation && (this.props.currentAuthUser.affiliation.HD)) { // NOTE: removed again - skovdyrkerne should go into here. || this.props.currentAuthUser.affiliation.skovdyrkerne)) {
        } else {
            // Add user to get startet list
            this.props.onAttachMailChimp(configMailChimp.audKeyGetStarted, mailChimpData, tags)
        }

        // --- Attach reepay to user --- //
        this.props.onAttachReepay(reepayData);

        // --- Update user data on cloud --- //
        this.props.onUpdateUserData(userData);

        // --- Save signup reason on cloud --- //
        this.props.onSetSignupReason(this.state.signupReason);

        // --- Update app state --- /
        this.props.onSetUpdateUserProfile(true);
    }

    signupOrgErrText() {
        if (this.state.signupOrgErr) {
            const { signupCVR, signupOrg } = this.state;
            if ((signupOrg.error && signupOrg.error === 'REQUIRES_PAID_SUBSCRIPTION')) {
                return "Fejl i hentning af virksomhedsoplysninger fra CVR-registeret! Bekræft at CVR-nummer er korrekt."
            }
            if (signupOrg.error && signupOrg.error !== 'REQUIRES_PAID_SUBSCRIPTION') {
                return "Fejl i hentning af virksomhedsoplysninger fra CVR-registeret! Tjek eventuelt CVR-nummer."
            }
            if ((signupOrg === '' || signupCVR === '')) {
                return "Virksomhedsoplysninger er ikke blevet hentet!"
            } else {
                return "Fejl i hentning af virksomhedsoplysninger fra CVR-registeret! Kontakt os for yderligere hjælp."
            }
        } else {
            if (this.state.signupCVR.length === 8) {
                return this.state.signupOrg.name
            } else {
                return null
            }
        }
    }

    render() {
        const { classes } = this.props;
        let welcomeBtnDisabled = false;
        let locationBtnDisabled = false;
        let reasonBtnDisabled = false;

        // --- Logic for welcome screen --- //
        if (this.state.signupName === '') {
            // If no name
            welcomeBtnDisabled = true;
        } else if (this.state.signupCVR !== '' && (this.state.signupCVR.length < 8 || this.state.signupCVR.length > 8)) {
            // If CVR doesn't have the right format
            welcomeBtnDisabled = true;
        } else if ((this.state.signupOrg === '' || !this.state.signupOrg.name) && this.state.signupCVR.length === 8 && !this.state.signupOrgConfirmed) {
            welcomeBtnDisabled = true;
            // If manual input is needed for CVR
            if (this.state.signupCVRError && this.state.signupOrg.error === 'REQUIRES_PAID_SUBSCRIPTION') {
                // Org is not okay
                welcomeBtnDisabled = false;
            }
        }
        // If user role is forester additional check on CVR
        if (this.state.userRole !== 'forestOwner' && this.state.signupOrg === '' && !welcomeBtnDisabled) {
            welcomeBtnDisabled = true;
        }

        // --- Logic for location screen --- //
        if (this.state.signupForestName === '' || this.state.signupLocationError) {
            locationBtnDisabled = true;
        }

        // --- Logic for signup reason screen --- //
        if (this.state.signupReason === '') {
            reasonBtnDisabled = true;
        }

        // Switch content based on onboarding state
        let content = <div> Error </div>
        let btns;
        let txtInput;

        switch (this.state.stage) {
            case 'welcome':
                // Signup. CVR should be required for "Skovforvalteren" and "Skovenheder"
                // Set button text and functionality
                let aff = (this.props.currentAuthUser.affiliation.HD || this.props.currentAuthUser.affiliation.sls || this.props.currentAuthUser.affiliation.skovdyrkerne);
                // Set buttons
                btns = this.state.signupCVR === '' ? [{ disabled: welcomeBtnDisabled, label: 'Næste', clicked: this.goToStage, stage: aff ? 'reason' : 'location', icon: <IconChevronRight />, iconLeft: false, color: 'primary' }] :
                    [{ loading: this.state.signupLoadingOrg, disabled: this.state.signupCVR.length < 8, label: 'Søg CVR-nummer', clicked: this.getCVR, icon: <SearchIcon />, iconLeft: false, color: 'primary' },
                    { disabled: welcomeBtnDisabled, label: (this.state.signupOrg.error && this.state.signupOrg.error === 'REQUIRES_PAID_SUBSCRIPTION') ? 'Bekræft' : 'Næste', clicked: (this.state.signupOrg.error && this.state.signupOrg.error === 'REQUIRES_PAID_SUBSCRIPTION') ? this.handleConfirmCVR : this.goToStage, stage: (aff || this.state.userRole === 'forester') ? 'reason' : 'location', icon: <IconChevronRight />, iconLeft: false, color: 'primary' }];
                txtInput = [
                    { id: 'signupName', label: 'Skriv dit fulde navn', onChange: this.handleTextInput, value: this.state.signupName, required: true, type: "text", textA: this.state.userRole === 'forestOwner' ? Text.welcome.ownerName : Text.welcome.foresterName, textB: this.state.userRole === 'forestOwner' ? Text.welcome.ownerCVR : Text.welcome.foresterCVR },
                    { id: 'signupCVR', label: 'Skriv din virksomheds CVR-nummer', helperText: this.signupOrgErrText(), error: this.state.signupOrgErr, onChange: this.handleTextInput, value: this.state.signupCVR, required: true, type: "number" }
                ];
                content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                    <DialogBox
                        header={Text.welcome.header}
                        step={[this.state.stepNum, this.state.numSteps]}
                        text={this.state.userRole === 'forestOwner' ? Text.welcome.ownerIntro : Text.welcome.foresterIntro}
                        buttons={btns}
                        textInput={txtInput}
                        mobile={this.props.mobile}
                    />
                </div>
                break;

            case 'location':
                btns = [
                    { label: 'Tilbage', clicked: this.goToStage, stage: 'welcome', icon: <IconChevronLeft />, iconLeft: true, color: 'primary' },
                    { disabled: locationBtnDisabled, label: 'Næste', clicked: this.goToStage, stage: 'reason', color: 'primary' },
                    // { label: 'test', clicked: this.testClick}
                ];
                txtInput = [
                    { id: 'signupForestName', label: 'Skriv navnet på din skov her', onChange: this.handleTextInput, value: this.state.signupForestName, required: true, type: "text", textA: Text.location.ownerForestName, textB: Text.location.ownerForestLocation },
                    { id: 'signup_address', label: 'Skriv din skovs adresse', required: true, type: "text", }
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.location.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={Text.location.ownerIntro}
                            buttons={btns}
                            mobile={this.props.mobile}
                            textInput={txtInput}
                        />
                    </div >
                }
                break;
            case 'reason':
                btns = [
                    { label: 'Tilbage', clicked: this.goToStage, stage: (this.state.userRole === 'forester' || this.props.currentAuthUser.affiliation.HD || this.props.currentAuthUser.affiliation.sls || this.props.currentAuthUser.affiliation.skovdyrkerne) ? 'welcome' : 'location', icon: <IconChevronLeft />, iconLeft: true, color: 'primary' },
                    { disabled: reasonBtnDisabled, label: 'Afslut', clicked: this.handleFinalise, color: 'primary' },
                    // { label: 'test', clicked: this.testClick}
                ];
                txtInput = [
                    { id: 'signupReason', label: 'Skriv dit svar her', onChange: this.handleTextInput, value: this.state.signupReason, required: true, type: "text", multiline: true },
                ];
                content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                    <DialogBox
                        header={Text.reason.header}
                        step={[this.state.stepNum, this.state.numSteps]}
                        text={Text.reason.reasonIntro}
                        buttons={btns}
                        textInput={txtInput}
                        mobile={this.props.mobile}
                        // onExit={this.handleFinalise}
                    />
                </div>;
                break;

            default: content = <div> Error </div>
        }

        // Return content from render
        return (
            content
        );
    }
};

const mapStateToProps = state => {
    return {
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        currentAuthUser: state.user.currentAuthUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (data) => dispatch(actions.updateUserData(data)),
        onLinkUserAndOrg: (data) => dispatch(actions.linkUserAndOrg(data)),
        onAttachMailChimp: (listId, data, tags) => dispatch(actions.attachMailChimp(listId, data, tags)),
        onAttachReepay: (data) => dispatch(actions.attachReepay(data)),
        onCreateNewForest: (data) => dispatch(actions.createNewForest(data)),
        onSetUpdateUserProfile: (state) => dispatch(actions.setUpdateUserProfile(state)),
        onSetSignupReason:  (reason) => dispatch(actions.setSignupReason(reason)),
    };
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile)));