import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/da';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

// Other Components
import { Draggable } from 'react-beautiful-dnd';

// Material UI Icons
import ClockIcon from '@material-ui/icons/AccessTimeRounded';
import LocationOnIcon from '@material-ui/icons/LocationOnRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';

// Components
import ImageGridList from '../../UI/DisplayImages/ImageGridList';
import DeleteButton from '../../UI/Buttons/DeleteButton';

const styles = theme => ({
    container: {
        // border: '1px solid lightgray',
        // borderRadius: '2px',
        padding: '8px',
        marginBottom: '8px',
        // backgroundColor: 'white',
    }
});

const renderChip = (value, variant, avatar) => {
    return (
        <div>
            {value.map((item, i) => (
                <Chip
                    size="small"
                    label={item}
                    color="primary"
                    key={i}
                    variant={variant}
                    avatar={avatar && <Avatar>{item.charAt(0)}</Avatar>}
                    style={{ marginRight: 6, marginBottom: 3 }}
                />
            ))}
        </div>
    );
}

const renderDateChip = (value) => {
    return (
        <div>
            <Chip
                size="small"
                icon={<ClockIcon style={{ color: 'black' }} />}
                label={`${moment(value).format('DD[-]MM[-]YYYY')}`}
                style={{
                    backgroundColor: moment(value).isAfter(moment().add(3, 'days').startOf('day')) ?
                        '#FAFAFA' : (moment(value).isAfter(moment().add(1, 'days').startOf('day')) ? '#FFB900' : '#E23838'),
                    color: moment(value).isAfter(moment().add(3, 'days').startOf('day')) ?
                        'black' : (moment(value).isAfter(moment().add(1, 'days').startOf('day')) ? 'black' : 'white'),
                    marginBottom: 3
                }}
                variant={moment(value).isAfter(moment().add(3, 'days').startOf('day')) ? "outlined" : "default"}
            />
        </div>
    );
}

const Task = (props) => {
    const { classes } = props;
    // Set action menu
    const items = [
        {
            name: "Slet",
            text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
            onClickHandler: () => props.deleteTaskOnClickHandler(props.task),
            buttonIcon: true
        },
    ]
    return (
        <Draggable draggableId={props.task.id} index={props.index} isDragDisabled={props.dragDisabled}>
            {(provided, snapshot) => {
                const style = {
                    backgroundColor: props.dragDisabled ? 'white' : snapshot.isDragging ? '#FAFAFA' : 'white',
                    ...provided.draggableProps.style,
                };
                return (
                    <Card className={classes.container}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={style}
                    >
                        <Typography variant="h6" style={{ marginBottom: 2 }}>
                            {props.task.title}
                        </Typography>

                        {/* <div style={{ width: '100%', height: 2, backgroundColor: 'lightblue' }} /> */}

                        {props.task.forestName && <div style={{ marginTop: 0, marginBottom: 4 }}>
                            <Typography variant="body2" style={{ color: '#41842b', fontWeight: 500 }}>
                                {props.task.forestName}
                            </Typography>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <LocationOnIcon style={{ color: '#41842b', height: 14, width: 14 }} />

                                <Typography variant="caption" style={{ color: '#41842b', marginBottom: -2 }}>
                                    {props.task.latlng.lat.toFixed(6)}, {props.task.latlng.lng.toFixed(6)}
                                </Typography>
                            </div>
                        </div>}

                        {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}

                        {props.task.text && <div style={{ marginTop: 6, marginBottom: 6, borderTop: '1px solid #A2A2A2', borderBottom: '1px solid #A2A2A2' }}>
                            <ExpansionPanel
                                style={{ borderRadius: 0, boxShadow: 'none', backgroundColor: 'lightred' }}
                            >
                                <ExpansionPanelSummary
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <div>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            Opgavedetaljer
                                        </Typography>

                                        <Typography variant="body1" noWrap style={{ width: 200 }}>
                                            {props.task.text}
                                        </Typography>
                                    </div>
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails>
                                    <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                        {props.task.text}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>}

                        {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}

                        {(props.task.images && props.task.images.length > 0) && <div style={{ marginBottom: 6 }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Billede(r)
                            </Typography>

                            <ImageGridList
                                images={props.task.images}
                                noPreview={true}
                            />
                        </div>}
                        {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}

                        {props.task.taskDeadline && <div style={{ marginBottom: 6 }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Skal gøres inden
                            </Typography>

                            {renderDateChip(props.task.taskDeadline)}
                        </div>}
                        {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}

                        {(props.task.taskCategories && props.task.taskCategories.length > 0) && <div style={{ marginBottom: 6 }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Kategori(er)
                            </Typography>

                            {renderChip(props.task.taskCategories, "outlined")}

                            {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}
                        </div>}

                        {(props.task.tasks && props.task.tasks.length > 0) && <div style={{ marginBottom: 6 }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Opgavetype(r)
                            </Typography>

                            {renderChip(props.task.tasks, "outlined")}

                            {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}
                        </div>}

                        {(props.task.responsible && props.task.responsible.length > 0) && <div style={{ marginBottom: 6 }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Ansvarlig(e)
                            </Typography>

                            {renderChip(props.task.responsible, "default", "avatar")}

                            {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}
                        </div>}

                        {(props.task.taskTypes && props.task.taskTypes.length > 0) && <div style={{ marginBottom: 6 }}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Gruppe(r)
                            </Typography>

                            {renderChip(props.task.taskTypes, "outlined")}

                            {/* <div style={{ width: '100%', height: 6, backgroundColor: 'grey' }} /> */}
                        </div>}

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {props.task.from && <div>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Oprettet af
                                </Typography>

                                <Typography variant="body2" style={{ marginTop: -3 }}>
                                    {props.task.from}
                                </Typography>
                            </div>}

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title="Se i kort">
                                    <IconButton
                                        aria-label="Se i kort"
                                        onClick={() => props.navigateToTaskOnClickHandler(props.task)}
                                        color="primary"
                                        size="small"
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Slet opgave">
                                    <DeleteButton
                                        items={items}
                                        disabled={props.dragDisabled}
                                        size="small"
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </Card>
                );
            }}
        </Draggable>
    )
};

Task.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Task);