import React from 'react';
import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import BiotopePlan from '../components/BiotopePlan/BiotopePlan';

const overviewMapNewBiotopePlan = (props) => {
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <BiotopePlan
                        />
                    </div>
                } />
            </Aux>
        );

}

export default overviewMapNewBiotopePlan;