var L = window.L;
L.DashedStripePattern = L.Pattern.extend({

	options: {
		weight: 4,
		spaceWeight: 4,
		color: '#000000',
		spaceColor: '#ffffff',
		opacity: 1.0,
		spaceOpacity: 0.0,
        dashArray: null,
	},

	_addShapes: function () {
		this._stripe = new L.PatternPath({
			stroke: true,
			weight: this.options.weight,
			color: this.options.color,
			opacity: this.options.opacity,
            dashArray:this.options.dashArray
		});

		this._space = new L.PatternPath({
            fill: true,
			// stroke: true,
			// weight: this.options.spaceWeight,
			fillColor: this.options.spaceColor,
            color: this.options.spaceColor,
            fillOpacity: this.options.spaceOpacity,
			// opacity: this.options.spaceOpacity
		});

        this.addShape(this._space);
		this.addShape(this._stripe);
		

		this._update();
	},

	_update: function () {
		this._stripe.options.d = 'M0 ' + this._stripe.options.weight / 2 + ' H ' + this.options.width;
        this._space.options.d = "M 0 0 H 24 V 24 H -100 Z";
		//this._space.options.d = 'M0 ' + (this._stripe.options.weight + this._space.options.weight / 2) + ' H ' + this.options.width;
	},

	setStyle: L.Pattern.prototype.setStyle
});

L.dashedStripePattern = function (options) {
	return new L.DashedStripePattern(options);
};