

// --- Check for third party subscription handling --- //
// e.g., skovdyrkerne controls their active members subscriptions.
// Therefore the subscription information is captured on the user and not in reepay
export const checkThirdPartySubscriptionHandling = (user) => {
    // --- Skovdyrkerne --- //
    // Internal foresters - still handled through reepay
    if (user.affiliation && user.affiliation.skovdyrkerne && user.affiliation.skovdyrkerne.isForester) {
        return false;
    }
    // Users handled through third-party (Firestore) subscriptions
    if (user.affiliation &&
        user.affiliation.skovdyrkerne &&
        (user.affiliation.skovdyrkerne.status &&
        user.affiliation.skovdyrkerne.status === 'active')) {
        return true;
    }
    return false;
}

