import React from "react";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';

// Icons
import CloseIcon from '@material-ui/icons/CloseRounded';

// Components
import OutlinedButton from '../UI/Buttons/OutlinedButton';

const styles = theme => ({
    root: {
        textAlign: 'left',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    card: {
        margin: 'auto',
        zIndex: '101',

    },
    container: {
        display: 'flex',
    }
});

const dialogBox = (props) => {
    const { classes, onboarded } = props;
    // Enable all needed buttons
    const btns = props.buttons.map((btn,i) => {
        return <div key={btn.label + i}>
            <OutlinedButton
                buttonLabel={btn.label}
                buttonIcon={btn.icon}
                iconLeft={btn.iconLeft}
                onPress={() => btn.clicked(btn.stage)}
                variant={btn.variant}
                color={btn.color}
                discrete={btn.discrete}
                disabled={btn.disabled}
                loading={btn.loading}
            />
        </div>
    });

    const txtInput = (props.textInput !== undefined) ? props.textInput.map((input, i) => {
        return <div key={"onboarding-dialog-text-input: " + input.label + i}>
            {(i === 0) && input.textA && <Typography style={{ marginTop: props.mobile ? 12 : 32 }}>
                {input.textA}
            </Typography>}

            <TextField
                key={input.label + i}
                required={input.required}
                id={input.id}
                placeholder={input.label}
                type={input.type}
                fullWidth
                onChange={input.onChange}
                value={input.value}
                style={{ marginTop: 12 }}
                variant="outlined"
                color='primary'
                helperText={input.helperText}
                error={input.error}
                margin={props.mobile ? "dense" : "normal"}
                multiline={input.multiline ? true : false}
            />

            {(i === 0) && input.textB && <Typography style={{ marginTop: props.mobile ? 12 : 32 }}>
                {input.textB}
            </Typography>}
        </div>
    }) : null;

    let boxWidth = 480;
    if (props.mobile) {
        boxWidth = window.innerWidth - 12 - 12
    }

    if (props.boxWidth) {
        boxWidth = props.boxWidth;
    }

    let margin = '0px';
    if (props.margin) {
        margin = props.margin;
    }

    let textVariant = 'body1';
    if (props.textVariant) {
        textVariant = props.textVariant;
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Fade in={true} timeout={800}>
                    <Card className={classes.card} style={{ width: boxWidth, maxHeight: 650, overflow: 'auto', margin: margin }}>
                        <CardContent>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'top', justifyContent: 'space-between' }}>
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                >
                                    {props.header}
                                </Typography >
                                {onboarded &&
                                    <IconButton aria-label="Close" size="medium" style={{ marginTop: -6, marginLeft: 4, height: 36, width: 36 }} onClick={props.onExit}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>}
                            </div>

                            <Typography
                                variant={textVariant} // "caption" // Terms mode
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {props.text}
                            </Typography>

                            {txtInput}
                        </CardContent>

                        <CardActions style={{ flexDirection: 'column' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                {btns}
                            </div>

                            {props.step && <div style={{ display: 'flex', marginTop: 4 }}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    {"Trin "} {props.step[0]} {" af "} {props.step[1]}
                                </Typography>
                            </div>}
                        </CardActions>
                    </Card>
                </Fade>
            </div>
        </div>
    )
};

dialogBox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(dialogBox);