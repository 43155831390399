// Import configuration files
const configHDGratis = require('./configHDGratis.json');
const configHDInternal = require('./configHDInternal.json');
const configSDGratis = require('./configSDGratis.json');
const configSDBasis = require('./configSDBasis.json');
const configSDUdvidet = require('./configSDUdvidet.json');
const configSDInternalLite = require("./configSDInternalLite.json");
const configSLSGratis = require('./configSLSGratis.json');
const configForvalterGratis = require('./configForvalterGratis.json');
const configHunting = require('./configHunting.json');
const configHuntingPlus = require('./configHuntingPlus.json');

// Set default output
const defOut = { specieScheme: null, configs: null, subs: null };

// Set user configuration main
export const setAppUserConfiguration = (user) => {
    // Input checking
    if (!user) throw new Error('config/no-user-object');

    // Switch depending on user affiliation
    if (user.affiliation) {
        // HedeDanmark
        if (user.affiliation.HD && user.affiliation.HD.status === 'active') return hdUserConfiguration(user);
        // Skovdyrkerne
        if (user.affiliation.skovdyrkerne && user.affiliation.skovdyrkerne.status === 'active') return sdUserConfiguration(user);
        // SLS
        if (user.affiliation.sls && user.affiliation.sls.status === 'active') return slsUserConfiguration(user);
    }

    // Check for normal forester free
    if (user.userRole === 'forester') return { specieScheme: null, configs: [configForvalterGratis], subs: null }

    // Return default if no information on the user leads to configuration
    return defOut;
}

// --- HedeDanmark --- //
const hdUserConfiguration = (user) => {
    // Check conditions
    if ((!user.org || user.org === '')) {
        // HedeDanmark forest owner free
        return { specieScheme: configHDGratis.mainMenu.settings.speciesScheme, configs: [configHDGratis], subs: null };
    } else if (user.org && user.org !== '') {
        // HedeDanmark internal
        return { specieScheme: configHDInternal.mainMenu.settings.speciesScheme, configs: [configHDInternal], subs: null };
    }
    // If no conditions are met, return default
    return defOut;
}

// --- Skovdyrkerne --- //
const sdUserConfiguration = (user) => {
    // Check for subscriptions on user (not handled individually through reepay)
    let configs = [configSDGratis];
    let subs = [];
    let speciesScheme = 'skovkortet';
    // Check for species scheme
    speciesScheme = mapMemberColorSchemeToSpeciesScheme(user.affiliation.skovdyrkerne);

    if ((user.org && user.org !== "" && user.affiliation.skovdyrkerne.internalUserType && user.affiliation.skovdyrkerne.internalUserType === "lite")) {
      // SD internal-lite
      return { specieScheme: speciesScheme, configs: [configSDInternalLite], subs: null};
    } else if (user.subscriptions && user.subscriptions.length > 0) {
        // User has active subscriptions - set accordingly
        user.subscriptions.forEach(sub => {
            if (sub.state === 'active') {
                const confSub = mapSubscriptionToConfig(sub.name, 'skovdyrkerne');
                if (confSub.config) configs.push(confSub.config);
                if (confSub.sub) subs.push(confSub.sub)
            }
        })

    }
    return { specieScheme: speciesScheme, configs: configs, subs: subs };
}

// --- SLS A/S --- //
const slsUserConfiguration = (user) => {
    // Check conditions
    if (user.userRole === 'forestOwner') {
        // SLS forest owner Free
        return { specieScheme: null, configs: [configSLSGratis], subs: null };
    } else {
        return { specieScheme: null, configs: [configForvalterGratis], subs: null }
    }
}

// --- Helper functions --- //
// Skovdyrkerne
const mapSubscriptionToConfig = (sub, affiliation) => {
    switch (sub) {
        case "nature": if (affiliation === 'skovdyrkerne') return { config: configSDBasis, sub: 'nature' }; return null;
        case "operation": if (affiliation === 'skovdyrkerne') return { config: configSDUdvidet, sub: 'operation' }; return null;
        case "hunting": return { config: configHunting, sub: 'hunting' };
        case "huntingPlus": return { config: configHuntingPlus, sub: 'huntingPlus' };
        case "ownerUpToFiveForests": return { config: null, sub: "ownerUpToFiveForests" };
        case "unlimitedUserAccess": return { config: null, sub: "unlimitedUserAccess" };
        default: return null;
    }
}

const mapMemberColorSchemeToSpeciesScheme = (par) => {
    const section = par.section ? par.section.toLowerCase() : '';
    const colorScheme = par.colorScheme ? par.colorScheme.toLowerCase() : '';
    let scheme = "skovdyrkerne-smi";
    // Check for section and color scheme
    try {
        if (section === "snø") {
            scheme = "skovdyrkerne-snø";
        } else if (section === "svj") {
            scheme = "skovdyrkerne-svj-løv";
            if (colorScheme === "plantage") scheme = "skovdyrkerne-svj-nål";
        } else if (section === "smi") {
            scheme = "skovdyrkerne-smi";
        } else if (section === "syd") {
            scheme = "skovdyrkerne-syd";
        } else if (section === "øer") {
            scheme = "skovdyrkerne-øer";
        }
    } catch (error) {
        console.error(error);
    }
    return scheme;
}
