import L from "leaflet";
// import 'proj4leaflet';
// import 'proj4';
import configDataFordeleren from '../../backend/configDataFordeleren';

// Setup
const kftoken = '3c270ce374b4b8306ffb3d95f8fa2608'; // Token kortforsyningen
// Define the DK source CRS for the WMS layer
// var dkCRS = L.Proj.CRS('EPSG:25832', "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");

/////////////////////////
// Google
/////////////////////////
export const simpleLayer = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
    maxZoom: 20,
    //edgeBufferTiles: 5,
    attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
});

export const satelliteLayer = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
    maxZoom: 20,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
});

// export const terrainLayer = L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
//     maxZoom: 20,
//     subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
// });

// export const hybridLayer = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
//     maxZoom: 20,
//     subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
// });

// export const streetsLayer = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
//     maxZoom: 20,
//     subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
// });

/////////////////////////
// Kortforsyningen OBS!!!!!! ------ ER skiftet til Datafordeleren hvor muligt ------ !!!!!
/////////////////////////
export const kfAttributionText = '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering</a>';
// Matrikelskel overlay [WMS:mat]
// export const cadastralLayer = L.tileLayer.wms('https://services.kortforsyningen.dk/mat', {
//     transparent: true,
//     layers: 'MatrikelSkel,Centroide',
//     styles: 'Roede_skel',
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     //minZoom: 0,
//     maxZoom: 20,
// });
export const cadastralLayer = L.tileLayer.wms('https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'MatrikelSkel_Gaeldende,Centroide_Gaeldende',
    styles: 'Roede_skel,Roede_centroider',
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
// Matrikel ejerlav overlay
// export const cadastralOwnerGroupLayer = L.tileLayer.wms('https://services.kortforsyningen.dk/mat', {
//     transparent: true,
//     layers: 'Ejerlav',
//     styles: 'Sorte_Ejerlav', // Roede_Ejerlav, Gule_Ejerlav, Sorte_Ejerlav, Hvide_Ejerlav
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     maxZoom: 20,
// });
export const cadastralOwnerGroupLayer = L.tileLayer.wms('https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    opacity: 0.8,
    layers: 'Ejerlav_Gaeldende',
    styles: 'ejerlav_gaeldende_transparent',
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
// Ortokort from "kortforsyningn" - BETA 2021
export const ortoKfTempLayer = L.tileLayer.wms('https://api.dataforsyningen.dk/service?request=GetCapabilities&servicename=orto_foraar_temp&service=WMS&version=1.3.0', {
    transparent: true,
    layers: 'orto_foraar_temp',
    token: kftoken,
    format: 'image/png',
    attribution: kfAttributionText,
    continuousWorld: true,
    maxZoom: 20
});
export const ortoKfLayer = L.tileLayer.wms('https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'orto_foraar', // 2019, other layers: geodanmark_2019_12_5cm, 
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
export const ortoKfLayer2015 = L.tileLayer.wms('https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'geodanmark_2015_12_5cm', // 2019, other layers: geodanmark_2019_12_5cm, 
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
export const ortoKfLayer2008 = L.tileLayer.wms('https://api.dataforsyningen.dk/orto_sommer_2008?', {
    transparent: true,
    layers: 'orto_sommer_2008',
    token: kftoken,
    format: 'image/png',
    attribution: kfAttributionText,
    continuousWorld: true,
    maxZoom: 20
});

// Contour layer from "kortforsyningen"
// export const contourKfLayer_2_5_m = L.tileLayer.wms('https://services.kortforsyningen.dk/dhm', {
//     transparent: true,
//     layers: 'dhm_kurver_2_5_m',//,dhm_terraen_skyggekort_transparent,dhm_overflade_skyggekort_transparent', //dhm_kurver_0_25_m, dhm_kurver_0_5_m, dhm_kurver_2_5_m
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     //minZoom: 9,
//     maxZoom: 20
// });
export const contourKfLayer_2_5_m = L.tileLayer.wms('https://services.datafordeler.dk/DHMNedboer/dhm/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'dhm_kurve_traditionel', //,dhm_terraen_skyggekort_transparent,dhm_overflade_skyggekort_transparent', //dhm_kurver_0_25_m, dhm_kurver_0_5_m, dhm_kurver_2_5_m
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
// export const contourKfLayer_0_5_m = L.tileLayer.wms('https://services.kortforsyningen.dk/dhm', {
//     transparent: true,
//     layers: 'dhm_kurver_0_5_m',
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     //minZoom: 9,
//     maxZoom: 20
// });
export const contourKfLayer_0_5_m = L.tileLayer.wms('https://services.datafordeler.dk/DHMNedboer/dhm/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'dhm_kurve_0_5_m', //,dhm_terraen_skyggekort_transparent,dhm_overflade_skyggekort_transparent', //dhm_kurver_0_25_m, dhm_kurver_0_5_m, dhm_kurver_2_5_m
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
// export const contourKfLayer_0_25_m = L.tileLayer.wms('https://services.kortforsyningen.dk/dhm', {
//     transparent: true,
//     layers: 'dhm_kurver_0_25_m',
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     //minZoom: 9,
//     maxZoom: 20
// });
export const contourKfLayer_0_25_m = L.tileLayer.wms('https://services.datafordeler.dk/DHMNedboer/dhm/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'dhm_kurve_0_25_m', //,dhm_terraen_skyggekort_transparent,dhm_overflade_skyggekort_transparent', //dhm_kurver_0_25_m, dhm_kurver_0_5_m, dhm_kurver_2_5_m
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
// Shadow layer from "kortforsyningen"
// export const shadowKfLayer = L.tileLayer.wms('https://services.kortforsyningen.dk/dhm', {
//     transparent: true,
//     layers: 'dhm_terraen_skyggekort_transparent,dhm_overflade_skyggekort_transparent', //dhm_kurver_0_25_m, dhm_kurver_0_5_m, dhm_kurver_2_5_m
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     //minZoom: 9,
//     maxZoom: 20
// });
export const shadowKfLayer = L.tileLayer.wms('https://services.datafordeler.dk/DHMNedboer/dhm/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'dhm_terraen_skyggekort', //,dhm_terraen_skyggekort_transparent,dhm_overflade_skyggekort_transparent', //dhm_kurver_0_25_m, dhm_kurver_0_5_m, dhm_kurver_2_5_m
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
export const shadowKfLayerSurf = L.tileLayer.wms('https://services.datafordeler.dk/DHMNedboer/dhm/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'dhm_overflade_skyggekort', //,dhm_terraen_skyggekort_transparent,dhm_overflade_skyggekort_transparent', //dhm_kurver_0_25_m, dhm_kurver_0_5_m, dhm_kurver_2_5_m
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});
// Bluespot Dataforsyningen
export const blueSpotLayer = L.tileLayer.wms('https://api.dataforsyningen.dk/dhm?', {
    transparent: true,
    opacity: 0.6,
    layers: 'dhm_bluespot_ekstremregn',
    styles: 'bluespot_ekstremregn_0_075', //bluespot_ekstremregn_0_015, bluespot_ekstremregn_0_030, ..., bluespot_ekstremregn_0_150, bluespot_ekstremregn_0_150plus
    token: kftoken,
    format: 'image/png',
    attribution: kfAttributionText,
    continuousWorld: true,
    //minZoom: 9,
    maxZoom: 20,
});
// Blue spot from "kortforsyningn"
// export const blueSpotLayer = L.tileLayer.wms('https://services.kortforsyningen.dk/dhm', {
//     transparent: true,
//     opacity: 0.6,
//     layers: 'dhm_bluespot_ekstremregn',
//     styles: 'bluespot_ekstremregn_0_075', //bluespot_ekstremregn_0_015, bluespot_ekstremregn_0_030, ..., bluespot_ekstremregn_0_150, bluespot_ekstremregn_0_150plus
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     //minZoom: 9,
//     maxZoom: 20,
// });
// Fredskov
// export const fredskovLayer = L.tileLayer.wms('https://services.kortforsyningen.dk/mat', {
//     transparent: true,
//     layers: 'Fredskov',
//     token: kftoken,
//     format: 'image/png',
//     attribution: kfAttributionText,
//     continuousWorld: true,
//     //minZoom: 0,
//     maxZoom: 20,
// });
export const fredskovLayer = L.tileLayer.wms('https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS?', {
    username: configDataFordeleren.username,
    password: configDataFordeleren.password,
    transparent: "TRUE",
    layers: 'FredskovFlade_Gaeldende',
    format: 'image/png',
    continuousWorld: true,
    uppercase: true,
    maxZoom: 20,
});

// Old Kortforsyning layers
// const blueSpotLayer = L.tileLayer.wms('https://services.kortforsyningen.dk/dhm_historik', {
//     transparent: true,
//     opacity: 0.6,
//     layers: 'dhm2007_bluespot',
//     token: kftoken,
//     format: 'image/png',
//     attribution: myAttributionText,
//     continuousWorld: true,
//     minZoom: 9,
//     maxZoom: 20
// });


//////////////////////////////////////
// Miljø Portal - Arealinformation
//////////////////////////////////////
const mpAttributionText = '&copy; <a target="_blank" href="https://arealinformation.miljoeportal.dk/html5/index.html?viewer=distribution">Danmarks Miljøportal</a>';
// export const hnvLayer = L.tileLayer.wms('https://9.tilecache6-miljoegis.mim.dk/gwc/service/wms?', {
//     transparent: true,
//     layers: 'theme-plangroen_hnvskov',
//     format: 'image/png',
//     attribution: mpAttributionText,
//     continuousWorld: true,
//     maxZoom: 20,
//     uppercase: true,
//     crs: dkCRS
// });
export const protectedOakGrove = L.tileLayer.wms('https://miljoegis.mim.dk/wms?servicename=nsv-skov_wms', {
    transparent: true,
    layers: 'theme-egekrat',
    format: 'image/png',
    attribution: mpAttributionText,
    continuousWorld: true,
    maxZoom: 20,
});
export const redListSpeciesLayer = L.tileLayer.wms('https://naturarealdatageo.miljoeportal.dk/geoserver/wms', {
    transparent: true,
    layers: 'ART_ROEDLISTEARTER',
    format: 'image/png',
    attribution: mpAttributionText,
    continuousWorld: true,
    maxZoom: 20,
});
export const appendixSpeciesLayer = L.tileLayer.wms('https://naturarealdatageo.miljoeportal.dk/geoserver/wms', {
    transparent: true,
    layers: 'ART_BILAGSARTER',
    format: 'image/png',
    attribution: mpAttributionText,
    continuousWorld: true,
    maxZoom: 20,
});
export const protectedNatureLayer = L.tileLayer.wms("https://arealeditering-dist-geo.miljoeportal.dk/geoserver/wms", {
    transparent: true,
    layers: "dai:fredede_omr,dai:bes_vandloeb,dai:bes_sten_jorddiger,dai:bes_naturtyper",
    format: 'image/png',
    attribution: mpAttributionText,
    continuousWorld: true,
    maxZoom: 20,
});
export const hnvForestLayer = L.tileLayer.wms('https://miljoegis.mim.dk/wms?servicename=miljoegis-plangroendk_wms', {
    transparent: true,
    layers: 'theme-plangroen_hnvskov',
    format: 'image/png',
    attribution: mpAttributionText,
    continuousWorld: true,
    maxZoom: 20,
});

export const natura2000HabitatLayer = L.tileLayer.wms("https://arealeditering-dist-geo.miljoeportal.dk/geoserver/wms", {
    transparent: true,
    layers: "dai:fugle_bes_omr,dai:habitat_omr,dai:ramsar_omr",
    format: 'image/png',
    attribution: mpAttributionText,
    continuousWorld: true,
    maxZoom: 20,
});

export const bnboLayer = L.tileLayer.wms('https://miljoegis.mim.dk/wms?servicename=grundvand_wms', {
    transparent: true,
    layers: 'theme-gvkort_bnbo',
    format: 'image/png',
    // attribution: mpAttributionText,
    continuousWorld: true,
    //minZoom: 9,
    maxZoom: 20,
});

//////////////////////////////////////
// Landbrugsstyrelsen
//////////////////////////////////////
export const markblokke = L.tileLayer.wms('https://geodata.fvm.dk/geoserver/ows', {
    transparent: true,
    // opacity: 0.6,
    layers: 'Markblokke',
    // styles: 'bluespot_ekstremregn_0_075', //bluespot_ekstremregn_0_015, bluespot_ekstremregn_0_030, ..., bluespot_ekstremregn_0_150, bluespot_ekstremregn_0_150plus
    format: 'image/png',
    continuousWorld: true,
    minZoom: 13,
    maxZoom: 20,
});

//////////////////////////////////////
// Slots og kulturstyrrelsen
//////////////////////////////////////
export const protectedAncientAreas = L.tileLayer.wms('https://www.kulturarv.dk/ffpublic/wms/ows?', {
    transparent: true,
    layers: 'fundogfortidsminder_areal_fredet,fundogfortidsminder_linje_fredet,fundogfortidsminder_punkt_fredet',
    format: 'image/png',
    attribution: "test",
    continuousWorld: true,
    maxZoom: 20,
});

//////////////////////////////////////
// Plandata (Kommuneplaner) Digitaliseringsstyrelsen
//////////////////////////////////////
export const forestErection = L.tileLayer.wms('https://geoserver.plandata.dk/geoserver/wms?servicename=wms', {
    transparent: true,
    layers: 'theme_pdk_skovrejsningsomraade_vedtaget_v',
    format: 'image/png',
    continuousWorld: true,
    maxZoom: 20,
});

/////////////////
// TEST LAYERS
////////////////
// var options = {'transparent': true, format: 'image/png', continuousWorld: true, minZoom: 9, maxZoom: 20, identify: false};
// var sourceAreal = L_WMS.source("https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer", options);
// var protectedNatureLayer = sourceAreal.getLayer('BES_NATURTYPER,FREDEDE_OMR,BES_STEN_JORDDIGER,BES_VANDLOEB');

// var options = { 'transparent': true, format: 'image/png', continuousWorld: true, minZoom: 9, maxZoom: 20, identify: false };
// var source = L_WMS.source("http://geodata.fvm.dk/geoserver/wms", options);
// var testLayer = source.getLayer('HNV_5_13_2019');
//source.addTo(map);

// var options = { 'transparent': true, format: 'image/png', continuousWorld: true, minZoom: 9, maxZoom: 20, identify: false };
// var source2 = L_WMS.source("http://miljoegis.mim.dk/wms?servicename=nsv-skov_wms", options);
// var testLayer4 = source2.getLayer('theme-p25_offentligareal');

// export const testLayer = L.tileLayer.wms('http://geodata.fvm.dk/geoserver/wms', {
//     transparent: true,
//     layers: 'HNV_5_13_2019',
//     //styles: 'default',
//     format: 'image/png',
//     attribution: "test",
//     //version: '1.3.0',
//     continuousWorld: true,
//     minZoom: 9,
//     maxZoom: 19,
// });

export const testLayer = L.tileLayer.wms('http://tilecache4-miljoegis.mim.dk/gwc/service/wms', {
    transparent: true,
    //crs: "EPSG:25832",
    layers: 'theme-np2_2016-natura2000omraader',
    //styles: 'default',
    format: 'image/png',
    attribution: "test",
    //version: '1.3.0',
    continuousWorld: true,
    // minZoom: 9,
    // maxZoom: 19,
});

export const testLayer2 = L.tileLayer.wms('http://geodata.fvm.dk/geoserver/wms?request=getcapabilities&', {
    transparent: true,
    layers: 'HNV_5_13_2019',
    //styles: 'default',
    format: 'image/png',
    attribution: "test",
    //version: '1.3.0',
    continuousWorld: true,
    minZoom: 9,
    maxZoom: 19,
});

export const testLayer3 = L.tileLayer.wms('http://geodata.fvm.dk/geoserver/wms', {
    transparent: true,
    layers: 'GLM_Fortidsminder',
    //styles: 'default',
    format: 'image/png',
    attribution: "test",
    //version: '1.3.0',
    continuousWorld: true,
    minZoom: 9,
    maxZoom: 19,
});

// export const testLayer = L.tileLayer.wms('http://www.kulturarv.dk/ffgeoserver/public/wms', {
//     transparent: true,
//     layers: 'fundogfortidsminder_punkt_fredet,fundogfortidsminder_linje_fredet,fundogfortidsminder_areal_fredet,fundogfortidsminder_areal_ka',
//     //styles: 'default',
//     format: 'image/png',
//     attribution: "test",
//     //version: '1.3.0',
//     continuousWorld: true,
//     minZoom: 9,
//     maxZoom: 19,
// });

// export const test2Layer = new L.WFS({
//     url: 'https://www.kulturarv.dk/ffpublic/wfs?version=1.0.0&request=GetCapabilities',
//     // typeNS: 'public',
//     typeName: 'public:fundogfortidsminder_areal_adm',
//     crs: L.CRS.EPSG4326,
//     style: {
//         color: 'blue',
//         weight: 2
//     }
// })

//http://www.kulturarv.dk/ffgeoserver/public/wms?service=WMS&request=GetCapabilities
//https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer?request=GetCapabilities&service=WMS
//http://www.kulturarv.dk/ffgeoserver/public/wms/ows?service=WMS
//http://www.kulturarv.dk/ffgeoserver/public/wms/ows
//http://www.kulturarv.dk/ffgeoserver/public/wms 

// WORKING - need to be in the EPSG:4326 - WGS 84 - Geographic format to work with the current Leaflet map.
// https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WMSServer
// https://danmarksmiljoeportal.zendesk.com/hc/da/articles/206950129-Webservices-Arealinformation-Arealinformation-og-Webservices
// https://www.kortforsyningen.dk/indhold/webservice-liste

