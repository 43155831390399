import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    features: null,
    points: null,
    loading: false,
    error: null,
    redrawFeatures: false,
    redrawPoints: false,
    successFeature: false,
    deleteFeature: false,
    forestOwnerAllowChange: true,
};

// Fetch the features from cloud
const fetchHuntingFeaturesStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchHuntingFeaturesFail = ( state, action ) => {
    return updateObject( state, { loading: false, error: action.error } );
};

const fetchHuntingFeaturesSuccess = ( state, action ) => {
    return updateObject( state, {
        features: {...state.features, [action.id]: action.features},
        loading: false,
        redrawFeatures: true,
    } );
};

// Fetch the points from cloud
const fetchHuntingPointsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchHuntingPointsFail = ( state, action ) => {
    return updateObject( state, { loading: false, error: action.error } );
};

const fetchHuntingPointsSuccess = ( state, action ) => {
    return updateObject( state, {
        points: {...state.points, [action.id]: action.points},
        loading: false,
        redrawPoints: true,
    });
};

// Save feature on cloud
const saveHuntingFeatureStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const saveHuntingFeatureFail = ( state, action ) => {
    return updateObject( state, { loading: false, error: action.error } );
};

const saveHuntingFeatureSuccess = ( state, action ) => {   
    return updateObject( state, {
        loading: false,
        successFeature: true
    } );
};

// Delete feature from cloud
const deleteHuntingFeatureStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const deleteHuntingFeatureFail = ( state, action ) => {
    return updateObject( state, { loading: false, error: action.error } );
};

const deleteHuntingFeatureSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        deleteFeature: true,
    } );
};

// Set redraw features
const setRedrawHuntingFeatures = ( state, action ) => {
    return updateObject( state, {
        redrawFeatures: action.redrawFeaturesState,
    } );
};

const setRedrawHuntingPoints = ( state, action ) => {
    return updateObject( state, { redrawPoints: action.redrawPointsState } );
};

// Unmount feature in sidebar
const unmountHuntingFeature = ( state, action ) => {   
    return updateObject( state, {
        error: null,
        successFeature: false,
    } );
};

// Delete hunting features from reducer
const deleteAllHuntingFeatures = ( state, action ) => {
    const updatedFeatures = { ...state.features };
    delete updatedFeatures[action.customerId];
    return updateObject(state, {
        features: updatedFeatures,
        redrawFeatures: true,
    });
}

// Delete hunting points from reducer
const deleteAllHuntingPoints = ( state, action ) => {
    const updatedPoints = { ...state.points };
    delete updatedPoints[action.customerId];
    return updateObject(state, {
        points: updatedPoints,
        redrawPoints: true,
    });
}

const setForestOwnerChangeAllowed = ( state, action ) => {
    return updateObject( state, { forestOwnerAllowChange: action.forestOwnerAllowChange } );
};

// Reducer
const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case actionTypes.FETCH_HUNTING_FEATURES_START: return fetchHuntingFeaturesStart(state, action);
        case actionTypes.FETCH_HUNTING_FEATURES_FAIL: return fetchHuntingFeaturesFail(state, action);
        case actionTypes.FETCH_HUNTING_FEATURES_SUCCESS: return fetchHuntingFeaturesSuccess(state, action);

        case actionTypes.FETCH_HUNTING_POINTS_START: return fetchHuntingPointsStart(state, action);
        case actionTypes.FETCH_HUNTING_POINTS_FAIL: return fetchHuntingPointsFail(state, action);
        case actionTypes.FETCH_HUNTING_POINTS_SUCCESS: return fetchHuntingPointsSuccess(state, action);

        case actionTypes.SAVE_HUNTING_FEATURE_START: return saveHuntingFeatureStart(state, action);
        case actionTypes.SAVE_HUNTING_FEATURE_FAIL: return saveHuntingFeatureFail(state, action);
        case actionTypes.SAVE_HUNTING_FEATURE_SUCCESS: return saveHuntingFeatureSuccess(state, action);

        case actionTypes.DELETE_HUNTING_FEATURE_START: return deleteHuntingFeatureStart(state, action);
        case actionTypes.DELETE_HUNTING_FEATURE_FAIL: return deleteHuntingFeatureFail(state, action);
        case actionTypes.DELETE_HUNTING_FEATURE_SUCCESS: return deleteHuntingFeatureSuccess(state, action);

        case actionTypes.SET_REDRAW_HUNTING_FEATURES: return setRedrawHuntingFeatures(state, action);
        case actionTypes.REDRAW_HUNTING_POINTS: return setRedrawHuntingPoints(state, action);
        case actionTypes.SET_HUNTING_ALLOW_CHANGE: return setForestOwnerChangeAllowed(state, action);

        case actionTypes.UNMOUNT_HUNTING_FEATURE: return unmountHuntingFeature(state, action);

        case actionTypes.DELETE_ALL_HUNTING_FEATURES: return deleteAllHuntingFeatures(state, action);
        case actionTypes.DELETE_ALL_HUNTING_POINTS: return deleteAllHuntingPoints(state, action);

        


        
        
        default: return state;
    }
};

export default reducer;