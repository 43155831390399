import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Customer from '../components/Customer/Customer';

class overviewMapCustomer extends Component {

    render() {
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <Customer
                            change={true}
                            new={true}
                            name={this.props.location.state.name}
                            forestName={this.props.location.state.forestName}
                            address={this.props.location.state.address}
                            city={this.props.location.state.city}
                            zip={this.props.location.state.zip}
                            phoneNumber={this.props.location.state.phoneNumber}
                            email={this.props.location.state.email}
                            note={this.props.location.state.note}
                            date={this.props.location.state.date}
                            id={this.props.location.state.id}
                        />
                    </div>
                } />
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(overviewMapCustomer));