import React from "react";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
    container: {
        position: 'absolute',
        zIndex: 80,
        width: 184, // 184
        left: 10,
        top: 76,
        backgroundColor: theme.palette.background.paper,
    },
    cardContent: {
        padding: 0,
        marginTop: -12,
    },
});

class Legend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            // zIdx: 99,
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                {this.props.open && <ExpansionPanel
                    expanded={this.state.open}
                    className={classes.container}
                    onChange={() => this.setState({ open: this.state.open ? false : true })}
                >
                    <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                        <div style={{}}>
                            <Typography
                                variant="body1"
                                style={{ fontWeight: 500 }}
                            >
                                Signaturforklaring
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={classes.cardContent}>
                        <List dense={true}>
                            {this.props.legendData.map((value, i) => (
                                <ListItem key={i}>
                                    {value.type === 'area' && <div
                                        style={{
                                            height: 15,
                                            width: 15,
                                            marginRight: 8,
                                            border: `2px solid ${value.color}`,
                                            background: `repeating-linear-gradient(45deg, transparent, transparent 6px, ${value.color} 6px, ${value.color} 7px)`,
                                        }}
                                    />}

                                    {value.type === 'solidArea' && <div
                                        style={{
                                            height: 15,
                                            width: 15,
                                            marginRight: 8,
                                            border: `2px solid ${value.color}`,
                                            backgroundColor: `${value.color}`,
                                        }}
                                    />}

                                    {value.type === '0deglines' && <div
                                        style={{
                                            height: 15,
                                            width: 15,
                                            marginRight: 8,
                                            border: `2px solid ${value.color}`,
                                            background: `repeating-linear-gradient(white 4px, white 7px, ${value.color} 9px)`,
                                        }}
                                    />}

                                    {value.type === 'diagonalX' && <div
                                        style={{
                                            height: 15,
                                            width: 15,
                                            marginRight: 8,
                                            border: `2px solid ${value.color}`,
                                            background: `repeating-linear-gradient(45deg, transparent, transparent 6px, ${value.color} 6px, ${value.color} 7px), repeating-linear-gradient(-45deg, transparent, transparent 6px, ${value.color} 6px, ${value.color} 7px)`,
                                        }}
                                    />}

                                    {value.type === 'line' && <div
                                        style={{
                                            height: 3,
                                            width: 15,
                                            marginRight: 8,
                                            backgroundColor: `${value.color}`,
                                        }}
                                    />}

                                    {value.type === 'dotted-line-wide' && <div style={{ display: 'flex' }}>
                                        <div
                                            style={{
                                                height: 3,
                                                width: 10,
                                                marginRight: 2,
                                                backgroundColor: `${value.color}`,
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: 3,
                                                width: 3,
                                                marginRight: 8,
                                                backgroundColor: `${value.color}`,
                                            }}
                                        />
                                    </div>}

                                    <ListItemText
                                        primary={value.name}
                                    />
                                </ListItem>))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>}
            </div>
        );
    }
}

export default withStyles(styles)(Legend);