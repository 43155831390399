import L from "leaflet";

//////////////////////
// Marker icons
//////////////////////
export const DefaultIcon = L.icon({
    iconUrl: require('./note_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

// ----------- Forest Icons --------------- //
export const ForestIcon = L.icon({
    iconUrl: require('./customer_tool_tip.png'),
    iconSize: [56, 63],
    iconAnchor: [28, 50],
    popupAnchor: [-3, -76]
});

export const ForestIconBlue = L.icon({
    iconUrl: require('./customer_tool_tip_blue.png'),
    iconSize: [56, 63],
    iconAnchor: [28, 50],
    popupAnchor: [-3, -76]
});

export const ForestIconGrey = L.icon({
    iconUrl: require('./customer_tool_tip_grey.png'),
    iconSize: [56, 63],
    iconAnchor: [28, 50],
    popupAnchor: [-3, -76]
});

export const markerForestIcon = L.icon({
    iconUrl: require('./forest_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const ForestDisabled = L.icon({
    iconUrl: require('./customer_tool_tip_disabled.png'),
    iconSize: [56, 63],
    iconAnchor: [28, 50],
    popupAnchor: [-3, -76]
});

// ----------- Note Icons --------------- //
export const HDIcon = L.icon({
    iconUrl: require('./note_hd_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const ownersIcon = L.icon({
    iconUrl: require('./owners_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const markerHuntingIcon = L.icon({
    iconUrl: require('./hunting_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const markerImagesIcon = L.icon({
    iconUrl: require('./images_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const markerTodoIconNotPlanned = L.icon({
    iconUrl: require('./todo_tool_tips_white.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const markerTodoIcon = L.icon({
    iconUrl: require('./todo_tool_tips_grey.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const markerTodoIconInProgress = L.icon({
    iconUrl: require('./todo_tool_tips_blue.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const markerTodoIconDone = L.icon({
    iconUrl: require('./todo_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

export const markerNoteIcon = L.icon({
    iconUrl: require('./note_tool_tips.png'),
    iconSize: [40, 45],
    iconAnchor: [20, 35],
    popupAnchor: [-3, -76]
});

// ----------- Position Icon --------------- //
export const posIcon = L.icon({
    iconUrl: require('./location_small.png'),
    iconSize: [36, 46],
    iconAnchor: [16, 30],
});

export const posIconWalk = L.icon({
    iconUrl: require('./GPSLocationDot.png'),
    iconSize: [15, 15],
    iconAnchor: [0, 0],
    // iconAnchor: [10, 20],
});

// ----------- Points Icon --------------- //
export const pointIcon = L.icon({
    iconUrl: require('./point_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointOtherIcon = L.icon({
    iconUrl: require('./point_other.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointLeveYngleRasteIcon = L.icon({
    iconUrl: require('./LeveYngleRasteIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointNaturIcon = L.icon({
    iconUrl: require('./NaturIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointFortidsKulturIcon = L.icon({
    iconUrl: require('./FortidKulturIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointStoreTraerIcon = L.icon({
    iconUrl: require('./StoreTraerIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointSaerligeTraerIcon = L.icon({
    iconUrl: require('./SaerligeTraerIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointDoedtVedIcon = L.icon({
    iconUrl: require('./DoedtVedIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointVandIcon = L.icon({
    iconUrl: require('./VandIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointStakkeIcon = L.icon({
    iconUrl: require('./StakkeIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointShelterIcon = L.icon({
    iconUrl: require('./ShelterIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointBaalstedIcon = L.icon({
    iconUrl: require('./BaalstedIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointUdsigtsIcon = L.icon({
    iconUrl: require('./UdsigtsIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const pointLegepladsIcon = L.icon({
    iconUrl: require('./LegepladsIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

// ----------- Hunting Icon --------------- //
export const huntingBoxIcon = L.icon({
    iconUrl: require('./box_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingBucketIcon = L.icon({
    iconUrl: require('./bucket_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingCamIcon = L.icon({
    iconUrl: require('./cam_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingGroundIcon = L.icon({
    iconUrl: require('./ground_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingGroupIcon = L.icon({
    iconUrl: require('./group_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingHouseIcon = L.icon({
    iconUrl: require('./house_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingLatterIcon = L.icon({
    iconUrl: require('./latter_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingSaltStoneIcon = L.icon({
    iconUrl: require('./salt_stone_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const huntingTowerIcon = L.icon({
    iconUrl: require('./tower_tool_tip.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

// ----------- Biotop Icon --------------- //
export const birdhouse1Icon = L.icon({
    iconUrl: require('./Redekasse1Ikon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const birdhouse2Icon = L.icon({
    iconUrl: require('./Redekasse2Ikon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const stoneIcon = L.icon({
    iconUrl: require('./StenIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const vibeIcon = L.icon({
    iconUrl: require('./VibelavningerIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})

export const laerkIcon = L.icon({
    iconUrl: require('./LaerkepletterIkon.png'),
    iconSize: [28, 28],
    iconAnchor: [14, 14],
    //popupAnchor: [-3, -76]
})