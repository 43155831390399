import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import classNames from 'classnames';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

// Material UI icons
import OpenInNewIcon from '@material-ui/icons/OpenInNewRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ReorderIcon from '@material-ui/icons/ReorderRounded';
import SupervisorIcon from '@material-ui/icons/SupervisorAccountRounded';
import ReportProblemIcon from '@material-ui/icons/ReportProblemRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';

// Components 
import CircularSpinner from '../UI/CircularSpinner/CircularSpinner';
import ActivityFeed from '../ActivityFeed/ActivityFeed';

// Images
import NaturImg from './Natur_billede_app.jpg';
import JagtImg from './Jagt_billede_app.jpg';

import configGetStream from '../../backend/configGetStream';

import * as actions from '../../store/actions/index';
var stream = require('getstream');

const cookies = new Cookies();

// Parameters
const isMobile = 760;

const styles = (theme) => ({
    fab: {
        position: 'absolute',
        right: 10,
        top: 10,
        backgroundColor: '#41842b',
        '&:hover': {
            backgroundColor: '#41842b',
        },
    },
    fabClose: {
        marginTop: -16,
        marginRight: -16,
        height: 58,
        width: 58,
        //backgroundColor: '#41842b',
        backgroundColor: 'transparent',
        '&:hover': {
            //backgroundColor: '#41842b',
            backgroundColor: 'transparent'
        },
    },
    card: {
        position: 'absolute',
        right: 10,
        top: 10,
        bottom: 10,
        backgroundColor: '#EFEFEF',
        paddingBottom: 126,
        zIndex: 1,
    },
    contentSection: {
        width: '100%',
        overflow: 'auto',
        height: '100%',
        padding: 10,
    },
    sidebarIn: {
        transition: 'width 0.2s',
    },
    sidebarOut: {
        transition: 'width 0.1s',
        height: '0px',
    },
    indicator: {
        backgroundColor: 'white',
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
    customBadge: {
        backgroundColor: "white",
        color: "#252525",
    }
});

class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            tempValue: 0,
            numNewAct: 0,
            notificationsSetup: false,
            numRetrys: 0,
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    componentDidMount() {
        const sidebarState = cookies.get('sidebarState');
        let tempSidebar = sidebarState === 'open' ? true : false;
        // Catch mismatch between reducer state and cookie
        // Check for mobile device
        if (this.state.width > isMobile) {
            if (this.props.sidebar && !tempSidebar) {
                // set sidebar cookie
                cookies.set('sidebarState', 'open');
                tempSidebar = true;
            }
        } else {
            if (this.props.location.state !== undefined) {
                if (this.props.location.state.prevPage === 'noteList' || this.props.location.state.prevPage === 'customerList' || this.props.location.state.prevPage === "newCustomer" || this.props.location.pathname === "/Cells/NewCell") {
                    tempSidebar = true;
                } else {
                    tempSidebar = false;
                }
            } else if (this.props.history.location.pathname === '/') {
                tempSidebar = false;
            }
        }
        // set initial sidebar state
        this.props.onSidebarOpen(tempSidebar);
    }

    componentDidUpdate() {
        if (this.state.width > isMobile && this.props.onboardingActive && !this.props.sidebar) {
            this.props.onSidebarOpen(true);
        } else if (this.state.width <= isMobile && this.props.onboardingActive && this.props.onboardingState === 'newNoteIntro') {
            this.props.onSidebarOpen(false);
        }
        if (this.props.currentAuthUser !== null && !this.state.notificationsSetup) {
            const streamClient = stream.connect(configGetStream.apiKey, this.props.currentAuthUser.getStreamToken, configGetStream.appId);
            const notifications = streamClient.feed('timeline', this.props.currentAuthUser.id)
            notifications.subscribe(this.callback).then(this.successCallback, this.failCallback);
            this.setState({ notificationsSetup: true })
        }

    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    callback = () => {
        this.setState({ numNewAct: this.state.numNewAct + 1 })
    }

    successCallback = () => {
    }

    failCallback = (data) => {
        // TODO : We need to ensure that the getStream client and connection is only established once. But because SideBar component
        // is mounted in different pages, it is re-initialized often. Needs to be moved or getStream client moved to reducer. 
        // if (this.state.notificationsSetup && this.state.numRetrys < 5) {
        //     this.setState({ notificationsSetup: false, numRetrys: this.state.numRetrys+1 });
        // }
    }

    handleClick() {
        this.props.onSidebarOpen(this.props.sidebar ? false : true);

        // set sidebar cookie
        cookies.set('sidebarState', this.props.sidebar ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'Sidebar',
            action: `Sidebar open: ${!this.props.sidebar}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleChange = (event, valueInput) => {
        let tempVal = valueInput;
        // Handle onboarding
        if (this.props.onboardingActive) {
            if (tempVal) {
                // Send event to Analytics
                ReactGA.event({
                    category: 'Community',
                    action: `Community tap clicked during onboarding`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            }
            tempVal = 0;
        }
        if (this.state.tempValue !== tempVal) {
            if (tempVal === 1) {
                // Send event to Analytics
                ReactGA.event({
                    category: 'Community',
                    action: `Community tap open`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            } else {
                // Send event to Analytics
                ReactGA.event({
                    category: 'Community',
                    action: `Community tap closed`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            }
        }
        this.setState({ value: tempVal, numNewAct: 0, tempValue: tempVal });
    };

    renderSubheader() {
        const { classes } = this.props;
        const { width } = this.state;

        let sidebarWidth = width > 420 ? 400 : (width - 20);
        let name = null;
        let address = null;
        let zip = null;
        let city = null;
        if (this.props.org) {
            name = this.props.org.name;
            address = this.props.org.address;
            zip = this.props.org.zip;
            city = this.props.org.city;
        } else if (this.props.currentAuthUser) {
            name = this.props.currentAuthUser.name;
            address = this.props.currentAuthUser.address;
            zip = this.props.currentAuthUser.zip;
            city = this.props.currentAuthUser.city;
        }
        let addressStr = null;
        if (address !== '') addressStr = address;
        if (zip !== '' && city !== '') addressStr = addressStr + ', ' + zip + ' ' + city;
        if (zip === '' && city !== '') addressStr = addressStr + ', ' + city;
        if (name !== null) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ height: 38 }}>
                        <Typography
                            variant="h6"
                            style={{ color: "white", maxWidth: sidebarWidth - 16 - 16 - 42 }}
                            noWrap
                        >
                            {`${name}`}
                        </Typography>

                        <Typography
                            variant="body2"
                            gutterBottom
                            style={{ color: "white", maxWidth: sidebarWidth - 16 - 16 - 42 }}
                            noWrap
                        >
                            {addressStr}
                        </Typography>
                    </div>
                    <Tooltip title="Luk sidebaren" aria-label="Luk sidebaren">
                        <IconButton
                            onClick={this.handleClick.bind(this)}
                            className={classes.fabClose}
                        >
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: width - 16 - 16, height: 38, }}>
                <CircularSpinner size={19} />
            </div>
        )
    }

    renderHeaderImg() {
        const { chosenWorkEnvironment } = this.props;

        if (chosenWorkEnvironment === 'forest') {
            return (
                <img src={NaturImg}
                    alt={''}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: 140,
                        borderRadius: '5px 5px 0px 0px'
                    }}
                />
            )
        } else {
            return (
                <img src={JagtImg}
                    alt={''}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: 140,
                        borderRadius: '5px 5px 0px 0px'
                    }}
                />
            )
        }
    }

    upgradeButtonHandler = () => {
        // Open account handling in main menu
        this.props.onMainMenuOpen(true);
        this.props.onAccountMenuOpen(true);
        // Send event to analytics
        ReactGA.event({
            category: 'Sidebar',
            action: 'Upgrade button clicked',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    renderButton(state) {
        const { classes } = this.props;

        if (!state) {
            return (
                <Tooltip title="Åben sidebaren" aria-label="Åben sidebaren">
                    <Fab
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick.bind(this)}
                        className={classes.fab}
                        style={{
                            zIndex: this.props.onboardingSideBarZidx,
                        }}
                        size={this.state.width > isMobile ? 'large' : 'medium'}
                    >
                        <OpenInNewIcon style={{ color: 'white', transform: 'rotate(180deg)', }} />
                    </Fab>
                </Tooltip>
            )
        }
    }

    renderUpgradeBox(headlineText, text, ctaText) {
        return (
            <div style={{ backgroundColor: '#2c2d36', borderRadius: 5, marginBottom: 10 }}>
                <CardContent style={{ padding: 20 }}>
                    <Typography
                        variant="h6"
                        style={{ color: 'white' }}
                    >
                        {headlineText}
                    </Typography>

                    {text}

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => this.upgradeButtonHandler()}
                            style={{ color: 'white', border: '1px solid white', marginTop: 16 }}
                            variant='outlined'
                        >
                            {ctaText}

                            <ChevronRightIcon style={{ color: 'white' }} />
                        </Button>
                    </div>
                </CardContent>
            </div>
        )
    }

    upgradeContentRender() {
        const { showAtLimit, headline, currentHaUsage, currentNoteUsage, currentHuntingPointsUsage } = this.props.config.sideBar.upgradeWidget;
        let headlineText = "Gratis funktionalitet";
        // Calculate numbers for upgrade
        let numHuntingPoints = '';
        if (this.props.huntingPoints && this.props.huntingPoints[this.props.selectedForest]) {
            numHuntingPoints = Object.keys(this.props.huntingPoints[this.props.selectedForest]).length;
        }

        if (this.props.inTrial && this.props.trialSub !== '' && !this.props.onboardingActive) {
            headlineText = "Gratis adgang";
            if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.ds.status === 'member') {
                headlineText = "Dansk Skovforening gratis adgang"
            }

            const text = <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0px' }}>
                <InfoIcon style={{ color: '#009CDF', marginRight: 6 }} />
                <Typography
                    variant="body2"
                    align="left"
                    style={{ color: 'white' }}
                >
                    {"Udløber " + moment(this.props.trialSub.trial_end).fromNow()}
                </Typography>
            </div>

            return (
                this.renderUpgradeBox(headlineText, text, 'Køb adgang her')
            )
        } else if (((!(this.props.natureActive || this.props.operationActive || this.props.operationPlusActive)) && !this.props.onboardingActive)) {
            // Set free user text 
            let freeTextArea = null;
            let freeTextNumNotes = null;
            let freeTextNumHuntingPoints = null;

            // Sections
            let haLimitReached = false;
            let noteLimitReached = false;
            let pointsLimitReached = false;

            // Sections
            let haSection = false;
            let noteSection = false;
            let pointsSection = false;

            // Headline
            if (headline !== '') {
                headlineText = headline;
            }

            // HA section ---
            if (this.props.totalForestArea !== '' && !(this.props.natureActive || this.props.operationActive || this.props.operationPlusActive || !(currentHaUsage === 'enabled'))) {
                freeTextArea = "Skov: " + this.props.totalForestArea.toFixed(1).split('.').join(',') + " ud af " + this.props.config.inventorySidebar.polygon.areaSize + " ha er nu brugt";
                haSection = true;
                haLimitReached = this.props.totalForestArea <= this.props.config.inventorySidebar.polygon.areaSize ? false : true;
            }

            // Note section ---
            if (this.props.numNotes !== '' && !(this.props.natureActive || this.props.operationActive || this.props.operationPlusActive || !(currentNoteUsage === 'enabled'))) {
                freeTextNumNotes = "Noter: " + this.props.numNotes + " ud af " + this.props.config.newNote.noteNumber + " noter er nu gemt";
                noteSection = true;
                noteLimitReached = this.props.numNotes < this.props.config.newNote.noteNumber ? false : true;
            }

            // Points section ---
            if (numHuntingPoints !== '' && currentHuntingPointsUsage === 'enabled') {
                freeTextNumHuntingPoints = "Jagtpunkter: " + numHuntingPoints + " ud af " + this.props.config.huntingSidebar.point.pointNumber + " punkter er nu sat";
                pointsSection = true;
                pointsLimitReached = numHuntingPoints < this.props.config.huntingSidebar.point.pointNumber ? false : true;
            }

            // Content block ---
            const text = <div>
                {haSection && <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0px' }}>
                    {!haLimitReached && <CheckCircleIcon style={{ color: '#5EBD3E', marginRight: 6 }} />}
                    {haLimitReached && <ReportProblemIcon style={{ color: '#FFB900', marginRight: 6 }} />}
                    <Typography
                        variant="body2"
                        align="left"
                        style={{ color: 'white' }}
                    >
                        {freeTextArea}
                    </Typography>
                </div>}

                {noteSection && <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0px' }}>
                    {!noteLimitReached && <CheckCircleIcon style={{ color: '#5EBD3E', marginRight: 6 }} />}
                    {noteLimitReached && <ReportProblemIcon style={{ color: '#FFB900', marginRight: 6 }} />}
                    <Typography
                        variant="body2"
                        align="left"
                        style={{ color: 'white' }}
                    >
                        {freeTextNumNotes}
                    </Typography>
                </div>}

                {pointsSection && <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0px' }}>
                    {!pointsLimitReached && <CheckCircleIcon style={{ color: '#5EBD3E', marginRight: 6 }} />}
                    {pointsLimitReached && <ReportProblemIcon style={{ color: '#FFB900', marginRight: 6 }} />}
                    <Typography
                        variant="body2"
                        align="left"
                        style={{ color: 'white' }}
                    >
                        {freeTextNumHuntingPoints}
                    </Typography>
                </div>}
            </div>

            if (showAtLimit !== 'enabled') {
                // Normal
                return (
                    this.renderUpgradeBox(headlineText, text, 'Opgrader her for flere funktioner')
                )
            } else {
                // e.g. "HD" or "skovdyrkerne"
                if (haLimitReached || noteLimitReached || pointsLimitReached) {
                    return (
                        this.renderUpgradeBox(headlineText, text, 'Opgrader her for flere funktioner')
                    )
                } else {
                    return null
                }
            }
        }
    }

    render() {
        const { classes, chosenWorkEnvironment } = this.props;
        const { value, width } = this.state;
        // During onboarding disable content in some of the steps
        let sideBarContent = this.props.cardContent;
        if (!this.props.onboardingActive) {
            if (value === 1) {
                sideBarContent = <ActivityFeed />
            }
        } else if (value !== 0) {
            this.handleChange(null, 0)
        }

        const sidebarStyling = classNames(classes.card, this.props.sidebar ? classes.sidebarIn : classes.sidebarOut);

        return (
            <div style={{ overflow: 'hidden' }}>
                {this.renderButton(this.props.sidebar)}

                <Card
                    className={sidebarStyling}
                    style={{
                        zIndex: width > isMobile ? this.props.onboardingSideBarZidx : 103,
                        overflow: 'hidden',
                        width: this.props.sidebar ? (width > 420 ? 400 : (width - 20)) : 0,
                    }}
                >
                    <div style={{ height: 140 }}>
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: 140,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                backgroundColor: chosenWorkEnvironment === 'forest' ? 'rgba(46,88,36,0.6)' : 'rgba(10,95,23,0.5)',
                                borderStyle: 'solid',
                                borderWidth: '1px 1px 0px 1px',
                                borderColor: '#EFEFEF',
                                borderRadius: '5px 5px 0px 0px'
                            }}
                        >
                            <CardHeader
                                subheader={this.renderSubheader()}
                            />

                            <Tabs
                                value={value}
                                onChange={this.handleChange}
                                disabled={this.props.onboardingActive}
                                variant="fullWidth"
                                classes={{
                                    indicator: classes.indicator
                                }}
                                textColor="primary"
                                aria-label="icon tabs example"
                                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                            >
                                {this.props.config.sideBar.community === 'enabled' && <Tab
                                    icon={<ReorderIcon style={{ color: 'white' }} />}
                                    aria-label="reorder"
                                />}

                                {this.props.config.sideBar.community === 'enabled' && <Tab
                                    icon={<SupervisorIcon style={{ color: 'white' }} />}
                                    // icon={
                                    //     <Badge
                                    //         className={classes.padding}
                                    //         classes={{ badge: classes.customBadge }}
                                    //         overlap='circlular'
                                    //         badgeContent={value === 1 ? this.props.newNotifications : this.state.numNewAct}
                                    //     >
                                    //         <SupervisorIcon style={{ color: 'white' }} />
                                    //     </Badge>
                                    // }
                                    aria-label="supervisor"
                                />}
                            </Tabs>
                        </div>

                        {this.renderHeaderImg()}
                    </div>

                    <CardContent className={classes.contentSection}>
                        {this.props.config.sideBar.upgradeWidget.enabled && this.props.selectedForest && this.props.displayUpgradeBox && this.upgradeContentRender()}
                        {sideBarContent}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        org: state.org.org,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        onboardingSideBarZidx: state.onboarding.sideBarZidx,
        inventoryListActive: state.maps.inventoryListActive,
        sidebar: state.layout.sidebarOpen,
        displayUpgradeBox: state.layout.displayUpgradeBox,
        newNotifications: state.account.newNotifications,
        customerList: state.customer.customerList,
        selectedForest: state.customer.currentlySelectedForestId,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
        natureActive: state.account.natureActive,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        huntingActive: state.account.huntingActive,
        huntingPlusActive: state.account.huntingPlusActive,
        totalForestArea: state.layout.totalForestArea,
        numNotes: state.layout.numNotes,
        numHuntingPoints: state.layout.numHuntingPoints,
        inTrial: state.account.inTrial,
        trialSub: state.account.trialSub,
        config: state.appConfig,
        huntingPoints: state.hunting.points,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSidebarOpen: (state) => dispatch(actions.sidebarOpen(state)),
        onMainMenuOpen: (state) => dispatch(actions.mainMenuOpen(state)),
        onAccountMenuOpen: (state) => dispatch(actions.accountMenuOpen(state)),
    };
}

SideBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SideBar)));
