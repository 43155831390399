import React from 'react';

import SideBar from '../components/SideBar/SideBar';
import CustomerWidget from '../components/UI/Widgets/Customer/CustomerWidget';

const overviewMapCustomer = (props) => {
    return (
        <SideBar cardContent={
            <div>
                <CustomerWidget full={true} showBackButton={true} />
            </div>
        }/>
    );
}

export default overviewMapCustomer;

