import * as actionTypes from './actionTypes';
import firebase from 'firebase';
import moment from 'moment';


// Fetch document list
const fetchDocumentListStart = () => {
    return {
        type: actionTypes.FETCH_DOCUMENT_LIST_START
    };
};

const fetchDocumentListSuccess = (forestId, fileList) => {
    return {
        type: actionTypes.FETCH_DOCUMENT_LIST_SUCCESS,
        id: forestId,
        fileList: fileList,
    };
};

const fetchDocumentListFail = (error) => {
    return {
        type: actionTypes.FETCH_DOCUMENT_LIST_FAIL,
        error: error
    };
};

export const fetchDocumentList = (forestId) => async (dispatch) => {
    const db = firebase.firestore();
    dispatch(fetchDocumentListStart());
    try {
        db.collection("forests").doc(forestId).collection("files")
            .onSnapshot(querySnapshot => {
                let fetchedFiles = [];
                querySnapshot.forEach(doc => {
                    fetchedFiles.push(doc.data());
                })
                dispatch(fetchDocumentListSuccess(forestId, fetchedFiles));
            })
        
    } catch (error) {
        dispatch(fetchDocumentListFail());
    }
}

// Remove document list from reducer
export const removeDocumentList = (forestId) => {
    return {
        type: actionTypes.REMOVE_DOCUMENT_LIST,
        id: forestId,
    };
}

// Upload documents
const uploadDocumentStart = () => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT_START
    };
};

const uploadDocumentSuccess = () => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT_SUCCESS
    };
};

const uploadDocumentFail = (error) => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT_FAIL,
        error: error
    };
};

export const uploadDocument = (docs, bucket, forestId, folderName) => async (dispatch) => {
    const db = firebase.firestore();
    // const storage = firebase.storage();
    dispatch(uploadDocumentStart());
    // Dispatch error if file upload list is empty
    if (docs.length === 0) dispatch(uploadDocumentFail("File list is empty"));
    // Upload files and create associated documents on firestore
    try {
        // Get ref to storage bucket
        const bucketName = "gs://" + bucket;
        const storage = firebase.app().storage(bucketName);
        // Set path to file folder
        const folderPath = forestId + "/" + folderName;
        // Run through all documents
        for (const item of docs) {
            // Check that item is a file
            if (item instanceof File) {
                // Unpack file data
                const fileName = item.name;
                const type = item.type ? item.type : "";
                const size = item.size ? item.size : "";
                const lastModifiedDate = item.lastModifiedDate ? item.lastModifiedDate : "";
                // Upload file to storage
                
                const filePath = folderPath + "/" + fileName;
                await storage.ref(filePath).put(item);
                // .then(snapshot => {
                // });
                // Get file url from storage
                const fileUrl = await storage.ref(folderPath).child(fileName).getDownloadURL();

                // Save file data and state on firestore - check if file already exist
                const fileId = 'file:'+folderName+':'+fileName;
                const fRef = await db.collection("forests").doc(forestId).collection("files").where('id', '==', fileId).get();
                if (fRef.empty) {
                    // File does not exist so create new file document
    
                    const refId = db.collection("forests").doc(forestId).collection("files").doc(fileId);
                    await refId.set({
                        id: fileId,
                        forestId: forestId,
                        fileName: fileName,
                        folderName: folderName,
                        url: fileUrl,
                        creationDate: moment().format(),
                        lastModifiedDate,
                        type,
                        size,
                        readState: false
                    })
                } else {
                    // File does exist so update file document
                    const refId = db.collection("forests").doc(forestId).collection("files").doc(fileId);
                    await refId.update({
                        url: fileUrl,
                        lastModifiedDate,
                        type,
                        size,
                        readState: false
                    })
                }
            }
        }
        dispatch(uploadDocumentSuccess())
    } catch (error) {
        console.error(error)
        dispatch(uploadDocumentFail(error))
    }
}

// Delete document
const deleteDocumentStart = () => {
    return {
        type: actionTypes.DELETE_DOCUMENT_START
    };
}

const deleteDocumentSuccess = () => {
    return {
        type: actionTypes.DELETE_DOCUMENT_SUCCESS
    };
}

const deleteDocumentFail = () => {
    return {
        type: actionTypes.DELETE_DOCUMENT_FAIL
    };
}

export const deleteDocument = (forestId, docId) => async (dispatch) => {
    const db = firebase.firestore();
    dispatch(deleteDocumentStart())
    try {
        const refId = db.collection("forests").doc(forestId).collection("files").doc(docId);
        await refId.delete();
        // TODO : Documents on storage are not deleted
        dispatch(deleteDocumentSuccess())
    } catch (error) {
        dispatch(deleteDocumentFail(error))
    }

}

// Set document read state
const setDocumentReadStateStart = () => {
    return {
        type: actionTypes.SET_DOCUMENT_READ_STATE_START
    };
};

const setDocumentReadStateSuccess = () => {
    return {
        type: actionTypes.SET_DOCUMENT_READ_STATE_SUCCESS
    };
};

const setDocumentReadStateFail = (error) => {
    return {
        type: actionTypes.SET_DOCUMENT_READ_STATE_FAIL,
        error: error
    };
};

export const setDocumentReadState = (forestId, docId) => async (dispatch) => {
    const db = firebase.firestore();
    dispatch(setDocumentReadStateStart());

    try {
        const refId = db.collection("forests").doc(forestId).collection("files").doc(docId);
        await refId.update({
            readState: true
        })
        dispatch(setDocumentReadStateSuccess())
    } catch (error) {
        dispatch(setDocumentReadStateFail(error));
    }
}