// File containing functions for calculating volume for different tree species only in DK.
// Source: Skovbrugstabeller, Statens forstlige Forsøgsvæsen

// Import libs
import moment from 'moment';
import { getSpecieParameters } from './speciesUtil';
const speciesTable = require('../Utilities/updatedSpeciesList.json');

// Set model parameters based on specie
// Based on: Vidar for the estimation progression and DET FORSTLIGE FORSØGSVÆSEN I DANMARK BOG 1987 (only standard function parameters) for volume computation
const modelPar = (scheme, specie) => {
    // Estimation parameters
    let a_01 = 0, a_02 = 0, a1 = 0, a2 = 0, a3 = 0;
    let b_01 = 0, b_02 = 0, b_03 = 0, b1 = 0, b2 = 0, b3 = 0, b4 = 0;
    let y1 = 0, y2 = 0, y3 = 0;
    // Volume model parameters
    let sb1 = 0, sb2 = 0, sb3 = 0;
    let vbA = 0, vbw = 0, vb1 = 0, vb2 = 0, vb3 = 0, vb4 = 0, vb5 = 0, vb6 = 0, vb7 = 0, vb8 = 0, vb9 = 0, vb10 = 0, vb11 = 0, vb12 = 0, vb13 = 0,
        vb14 = 0, vb15 = 0, vb16 = 0, vb17 = 0, vb18 = 0, vb19 = 0, vb20 = 0, vb21 = 0, vb22 = 0, vb23 = 0, vb24 = 0, vb25 = 0;
    let treeCountCorr = 1;
    let t = 50;
    // Carbon parameters
    let density = 0;
    let carbonFactor = 0.47; // Conversion factor from biomass to carbon (47% is wood rest is water)
    let CO2Factor = 3.67; // Conversion factor from carbon to CO2 equivalent (outset in molar mass)       
    let bg_a0 = 0, bg_a1 = 0, bg_a2 = 0, bg_a3 = 0, bg_a4 = 0; // Below ground parameters for expansion factor
    let ag_a0 = 0, ag_a1 = 0, ag_a2 = 0, ag_a3 = 0, ag_a4 = 0; // Above ground parameters for expansion factor

    // Set parameters based on specie
    // Check input
    if (!("estModel" in speciesTable[specie])) {
        throw { errText: "The specie has no associated estimation model", Input: specie };
    }
    switch (speciesTable[specie].estModel) {
        // Beech +
        case "Bøg - BØG": 
            // Estimation parameters
            a_02 = 2.089e-3; a1 = 1.7623; a2 = -0.1764; a3 = 0.0151;
            b_02 = 0.0457; b1 = 0.6510; b2 = -0.0166; b3 = -0.1616; b4 = 0.7705;
            sb1 = 1.7644; sb2 = 3472.7999; sb3 = 36.8037;
            y1 = 1.0264e-3; y2 = 1; y3 = 0.0317;
            // Volume model parameters
            vbA = 0.467065; vbw = 0.002777;
            vb1 = 2.100761; vb2 = 0.715347; vb3 = 0.235416; vb5 = 0.064329;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.56;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Oak +
        case "Eg - EG":
            // Estimation parameters
            a_02 = 3.3847e-3; a1 = 1.7565; a2 = -0.2105; a3 = 6.9736e-3;
            b_02 = 0.02747; b1 = 1.0144; b2 = -0.0524; b3 = -0.1204; b4 = 0.9290;
            sb1 = 1.6277; sb2 = 1.0000; sb3 = 31.9667;
            y1 = 8.7927e-5; y2 = 1; y3 = 0.0555;
            // Volume model parameters
            vbA = -0.172852; vbw = 0.003174;
            vb1 = 2.08447; vb2 = 0.801886; vb4 = 1.079690; vb24 = 0.280564;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.57;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Rødel +
        case "Rødel - REL":
            // Estimation parameters
            a_02 = 3.3847e-3; a1 = 1.7565; a2 = -0.2105; a3 = 6.9736e-3;
            b_02 = 0.02747; b1 = 1.0144; b2 = -0.0524; b3 = -0.1204; b4 = 0.9290;
            sb1 = 1.6277; sb2 = 1.0000; sb3 = 31.9667;
            y1 = 8.7927e-5; y2 = 1; y3 = 0.0555;
            // Volume model parameters
            vbA = -0.1556; vbw = 0.0025;
            vb1 = 2.2512; vb2 = 0.9222; vb4 = 0.5398; vb24 = 0.280564;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.44;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Ash (Ask)
        case "Ask - ASK":
            // Estimation parameters
            a_02 = 3.3847e-3; a1 = 1.7565; a2 = -0.2105; a3 = 6.9736e-3;
            b_02 = 0.02747; b1 = 1.0144; b2 = -0.0524; b3 = -0.1204; b4 = 0.9290;
            sb1 = 1.6277; sb2 = 1.0000; sb3 = 31.9667;
            y1 = 8.7927e-5; y2 = 1; y3 = 0.0555;
            // Volume model parameters
            vbA = -1.088694; vbw = 0.002518;
            vb1 = 2.002341; vb2 = 1.022051; vb4 = 2.174706; vb24 = 0.697865; vb25 = -0.614073;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.562;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Ahorn
        case "Ahorn - ÆR":
            // Estimation parameters
            a_02 = 2.089e-3; a1 = 1.7623; a2 = -0.1764; a3 = 0.0151;
            b_02 = 0.0457; b1 = 0.6510; b2 = -0.0166; b3 = -0.1616; b4 = 0.7705;
            sb1 = 1.7644; sb2 = 3472.7999; sb3 = 36.8037;
            y1 = 1.0264e-3; y2 = 1; y3 = 0.0317;
            // Volume model parameters
            vbA = 0.4204; vbw = 0.0056;
            vb1 = 1.9747; vb2 = 0.6367; vb3 = 0.2590; vb5 = 0.0193;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.492;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Pil
        case "Pil - PIL":
            // Estimation parameters
            a_02 = 2.089e-3; a1 = 1.7623; a2 = -0.1764; a3 = 0.0151;
            b_02 = 0.0457; b1 = 0.6510; b2 = -0.0166; b3 = -0.1616; b4 = 0.7705;
            sb1 = 1.7644; sb2 = 3472.7999; sb3 = 36.8037;
            y1 = 1.0264e-3; y2 = 1; y3 = 0.0317;
            // Volume model parameters
            vbA = 0.4204; vbw = 0.0056;
            vb1 = 1.9747; vb2 = 0.6367; vb3 = 0.2590; vb5 = 0.0193;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.35;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Birk
        case "Birk - BIR":
            // Estimation parameters
            a_02 = 3.3847e-3; a1 = 1.7565; a2 = -0.2105; a3 = 6.9736e-3;
            b_02 = 0.02747; b1 = 1.0144; b2 = -0.0524; b3 = -0.1204; b4 = 0.9290;
            sb1 = 1.6277; sb2 = 1.0000; sb3 = 31.9667;
            y1 = 8.7927e-5; y2 = 1; y3 = 0.0555;
            // Volume model parameters
            vbA = -0.1763; vbw = 0.003174;
            vb1 = 2.3033; vb2 = 0.8556; vb4 = 1.1229; vb24 = 0.1739;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.525;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Hybridasp
        case "Hybrid asp - HAS":
            // Estimation parameters
            a_02 = 3.3847e-3; a1 = 1.7565; a2 = -0.2105; a3 = 6.9736e-3;
            b_02 = 0.02747; b1 = 1.0144; b2 = -0.0524; b3 = -0.1204; b4 = 0.9290;
            sb1 = 1.6277; sb2 = 1.0000; sb3 = 31.9667;
            y1 = 8.7927e-5; y2 = 1; y3 = 0.0555;
            // Volume model parameters
            vbA = -0.0778; vbw = 0.0016;
            vb1 = 2.0949; vb2 = 0.7818; vb4 = 1.079690; vb24 = 0.1403;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.366;
            bg_a0 = 1.2;
            ag_a0 = 1;
            break;
        // Norway spruce
        case "Rødgran - RGR":
            // Estimation parameters
            a_01 = -1.7229e-3; a_02 = 4.0060e-4; a1 = 3.0205; a2 = -0.2541; a3 = -6.4661e-4;
            b_01 = -0.0513; b_02 = 0.0135; b1 = 1.1248; b2 = -0.0429; b3 = -0.0185; b4 = 1.2595;
            sb1 = 2.0323; sb2 = 6448.8721; sb3 = 32.5165;
            y1 = 2.7832e-3; y2 = 1; y3 = 0.0176;
            // Volume model parameters
            vbA = -1.753576; vbw = 0.001411;
            vb1 = 1.775814; vb2 = 1.136852; vb4 = 0.524851; vb24 = 0.725722; vb25 = -1.108216;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.38;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 0.82250; ag_a1 = 0.07306; ag_a2 = 0.76060; ag_a3 = 0.56920; ag_a4 = -0.01029;
            break;
        // Skovfyr
        case "Skovfyr - SKF":
            // Estimation parameters
            a_01 = -1.7229e-3; a_02 = 4.0060e-4; a1 = 3.0205; a2 = -0.2541; a3 = -6.4661e-4;
            b_01 = -0.0513; b_02 = 0.0135; b1 = 1.1248; b2 = -0.0429; b3 = -0.0185; b4 = 1.2595;
            sb1 = 2.0323; sb2 = 6448.8721; sb3 = 32.5165;
            y1 = 2.7832e-3; y2 = 1; y3 = 0.0176;
            // Volume model parameters
            vbA = -1.753576; vbw = 0.001411;
            vb1 = 1.5982; vb2 = 1.0232; vb4 = 0.524851; vb24 = 0.4354; vb25 = -1.108216;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.43;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 1.45380; ag_a1 = 0.01683; ag_a2 = -0.14640; ag_a3 = 0.81390; ag_a4 = -0.01956;
            break;
        // Contartafyr
        case "Contortafyr - COF":
            // Estimation parameters
            a_01 = -1.7229e-3; a_02 = 4.0060e-4; a1 = 3.0205; a2 = -0.2541; a3 = -6.4661e-4;
            b_01 = -0.0513; b_02 = 0.0135; b1 = 1.1248; b2 = -0.0429; b3 = -0.0185; b4 = 1.2595;
            sb1 = 2.0323; sb2 = 6448.8721; sb3 = 32.5165;
            y1 = 2.7832e-3; y2 = 1; y3 = 0.0176;
            // Volume model parameters
            vbA = -1.753576; vbw = 0.0007055;
            vb1 = 1.7581; vb2 = 1.1482; vb24 = 0.3629; vb25 = -1.108216;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.37;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 1.45380; ag_a1 = 0.01683; ag_a2 = -0.14640; ag_a3 = 0.81390; ag_a4 = -0.01956;
            break;
        // Nobilis
        case "Nobilis - NOB":
            // Estimation parameters
            a_01 = -1.7229e-3; a_02 = 4.0060e-4; a1 = 3.0205; a2 = -0.2541; a3 = -6.4661e-4;
            b_01 = -0.0513; b_02 = 0.0135; b1 = 1.1248; b2 = -0.0429; b3 = -0.0185; b4 = 1.2595;
            sb1 = 2.0323; sb2 = 6448.8721; sb3 = 32.5165;
            y1 = 2.7832e-3; y2 = 1; y3 = 0.0176;
            // Volume model parameters
            vbA = -1.753576; vbw = 0.001411;
            vb1 = 1.775814; vb2 = 1.136852; vb4 = 0.524851; vb24 = 0.5806;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.38;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 1.45380; ag_a1 = 0.01683; ag_a2 = -0.14640; ag_a3 = 0.81390; ag_a4 = -0.01956;
            break;
        // Sitka spruce +
        case "Sitkagran - SGR":
            // Estimation parameters
            a_01 = 4.8390e-3; a_02 = 1.0496e-3; a1 = 2.0415; a2 = -0.1615; a3 = -1.3841e-3;
            b_01 = -0.0411; b_02 = 7.2996e-3; b1 = 1.4101; b2 = -0.0395; b3 = -0.0290; b4 = 1.2075;
            sb1 = 2.1538; sb2 = -2870.2666; sb3 = 38.8609;
            y1 = 1.4612e-4; y2 = 1; y3 = 0.0422;
            // Volume model parameters
            vbA = -2.713511; vbw = 0.001750;
            vb1 = 1.759844; vb2 = 1.442070; vb4 = 1.917824; vb24 = 0.252296; vb25 = -0.813636;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.37;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 1.90918; ag_a1 = -0.02402; ag_a2 = -2.06985; ag_a3 = 0.23245;
            break;
        // Silver fir +
        case "Alm. ædelgran - ÆGR":
            // Estimation parameters
            a_01 = -3.1727e-3; a_02 = 2.5511e-3; a1 = 1.7164; a2 = -0.1493; a3 = 2.4291e-4;
            b_01 = 5.7007e-3; b_02 = 0.0689; b1 = 0.3191; b2 = -2.3131e-3; b3 = -8.2618e-3; b4 = 1.6346;
            sb1 = 2.1708; sb2 = 140562.7517; sb3 = 20.4927;
            y1 = 8.5411e-4; y2 = 1; y3 = 0.0280;
            // Volume model parameters
            vbA = -3.058860; vbw = 0.001046;
            vb1 = 1.789127; vb2 = 1.459622; vb4 = 4.226077; vb24 = 1.564662; vb25 = -2.573884;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.38;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 0.68203; ag_a1 = 0.10808; ag_a2 = 0.48894; ag_a3 = 0.23245;
            break;
        // Douglas fir +
        case "Douglas - DGR":
            // Estimation parameters
            a_01 = 0.0889; a_02 = 2.4227e-3; a1 = 1.0194; a2 = -0.1141; a3 = 0.0164;
            b_01 = 1.2289; b_02 = 0.0404; b1 = 0.03027; b2 = 0.0143; b3 = -0.0126; b4 = 1.3966;
            sb1 = 1.8000; sb2 = 1.0000; sb3 = 44.4553;
            y1 = 5.3639e-4; y2 = 1; y3 = 0.0322;
            // Volume model parameters
            vbA = -1.202507; vbw = 0.002160;
            vb1 = 1.832615; vb2 = 0.991312; vb25 = 0.259585;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.41;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 2.00953; ag_a1 = -0.03819; ag_a2 = -2.13067; ag_a3 = 0.23245;
            break;
        // Japansk lærk
        case "Lærk - LÆR":
            // Estimation parameters
            a_01 = -1.7229e-3; a_02 = 4.0060e-4; a1 = 3.0205; a2 = -0.2541; a3 = -6.4661e-4;
            b_01 = -0.0513; b_02 = 0.0135; b1 = 1.1248; b2 = -0.0429; b3 = -0.0185; b4 = 1.2595;
            sb1 = 2.0323; sb2 = 6448.8721; sb3 = 32.5165;
            y1 = 2.7832e-3; y2 = 1; y3 = 0.0176;
            // Volume model parameters
            vbA = -2.131983; vbw = 0.001300;
            vb1 = 1.774789; vb2 = 1.302453; vb4 = 0.764979; vb25 = -0.282231;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.42;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 1.48133; ag_a1 = -0.00618; ag_a2 = -1.09853; ag_a3 = 0.23245;
            break;
        // Grandis
        case "Grandis - GRA":
            // Estimation parameters
            a_01 = -3.1727e-3; a_02 = 2.5511e-3; a1 = 1.7164; a2 = -0.1493; a3 = 2.4291e-4;
            b_01 = 5.7007e-3; b_02 = 0.0689; b1 = 0.3191; b2 = -2.3131e-3; b3 = -8.2618e-3; b4 = 1.6346;
            sb1 = 2.1708; sb2 = 140562.7517; sb3 = 20.4927;
            y1 = 8.5411e-4; y2 = 1; y3 = 0.0280;
            // Volume model parameters
            vbA = -1.836055; vbw = 0.002599;
            vb1 = 1.750469; vb2 = 1.116252; vb24 = 0.811030; vb25 = -0.599227;
            treeCountCorr = 1;
            t = 50;
            // Carbon parameters
            density = 0.305;
            bg_a0 = 1.0835; bg_a1 = 0.008218; bg_a2 = 0.333200; bg_a3 = -0.007530;
            ag_a0 = 0.95244; ag_a1 = 0.07234; ag_a2 = -0.17824; ag_a3 = 0.23245;
            break;
        default:
            console.error("Specie for parameters not yet defined")
    }

    const par = {
        a_01, a_02, a1, a2, a3,
        b_01, b_02, b_03, b1, b2, b3, b4,
        y1, y2, y3,
        sb1, sb2, sb3,
        vbA, vbw, vb1, vb2, vb3, vb4, vb5, vb6, vb7, vb8, vb9, vb10, vb11, vb12, vb13, vb14, vb15, vb16, vb17, vb18, vb19, vb20, vb21, vb22, vb23, vb24, vb25,
        treeCountCorr,
        t,
        density, carbonFactor, CO2Factor,
        bg_a0, bg_a1, bg_a2, bg_a3, bg_a4,
        ag_a0, ag_a1, ag_a2, ag_a3, ag_a4,
    }

    return par;
}

export const simulate = (scheme, specie, timeSpan, initCond, thinningData) => {
    // Input:
    //  - specie,           stand specie (string)
    //  - timeSpan,         years backwards and forwards in time Array [yearsBackward, yearsForward], ex: [10, 10];
    //  - initCond (obj):
    //      - area [ha],                cell area,
    //      - V [m3/ha],                stand volume pr. ha 
    //      - H [m],                    stand height
    //      - D [m],                    stand diameter
    //      - G [m2/ha],                stand ground area pr. ha
    //      - N [#/ha],                 stand tree count pr. ha
    //      - age [years],              stand age
    //  - ThinningData (obj of obj). Each obj:
    //      - volumeThinning [m3],      tree volume taking out at thinning
    //      - treeCountThinning [#],    trees taking out at thinning
    //      - percentageThinning [%],   thinning percentage
    //      - date [-],                 thinning date

    // Input checking
    // Unpack initial conditions object
    const area = initCond.area ? (initCond.area !== "" ? parseFloat(initCond.area) : "") : "";
    const V = initCond.V ? (initCond.V !== "" ? parseFloat(initCond.V) : "") : "";
    const H = initCond.H ? (initCond.H !== "" ? parseFloat(initCond.H) : "") : "";
    const D = initCond.D ? (initCond.D !== "" ? parseFloat(initCond.D) : "") : "";
    const G = initCond.G ? (initCond.G !== "" ? parseFloat(initCond.G) : "") : "";
    const N = initCond.N ? (initCond.N !== "" ? parseFloat(initCond.N) : "") : "";
    const age = initCond.age ? (initCond.age !== "" ? parseInt(initCond.age) : "") : "";

    // Simulation horison
    let n_stepsBack = 0;
    let n_stepsFor = 0;
    if (typeof timeSpan === "number") {
        n_stepsFor = timeSpan;
    } else {
        n_stepsBack = timeSpan[0];
        n_stepsFor = timeSpan[1];
    }
    const n_steps = n_stepsBack + n_stepsFor;

    // Allocate arrays
    let H_arr = new Array(n_steps + 1).fill(0);
    let G_arr = new Array(n_steps + 1).fill(0);
    let N_arr = new Array(n_steps + 1).fill(0);
    let D_arr = new Array(n_steps + 1).fill(0);
    let V_arr = new Array(n_steps + 1).fill(0);
    let CO2_arr = new Array(n_steps + 1).fill(0);
    let F_arr = new Array(n_steps + 1).fill(0);
    let Felling_arr = new Array(n_steps + 1).fill({ Vf: 0, Nf: 0, Pf: 0, year: 0 });

    // Set initial conditions
    H_arr[n_stepsBack] = H;
    G_arr[n_stepsBack] = G;
    N_arr[n_stepsBack] = N;
    D_arr[n_stepsBack] = D;
    V_arr[n_stepsBack] = V;
    CO2_arr[n_stepsBack] = getSpecieCO2Equiv(scheme, specie, {Dg: D, Hg: H, V: V});
    F_arr[n_stepsBack] = Fnum(V_arr[n_stepsBack], H_arr[n_stepsBack], G_arr[n_stepsBack]);

    // Insert thinning data
    for (let i = 0; i < Felling_arr.length; i++) {
        Object.values(thinningData).forEach(el => {
            // Retrieve only year from thinning date
            const date = new Date(el.date);
            let year = '';
            if (!isNaN(date)) {
                year = date.getFullYear();
            }
            if ((parseInt(moment().format("YYYY")) - n_stepsBack + i) === year) {
                Felling_arr[i] = {
                    Vf: Felling_arr[i].Vf + (isNaN(parseInt(el.volumeThinning)) ? 0 : parseInt(el.volumeThinning)),
                    Nf: Felling_arr[i].Nf + (isNaN(parseInt(el.treeCountThinning)) ? 0 : parseInt(el.treeCountThinning)),
                    Pf: Felling_arr[i].Pf + (isNaN(parseInt(el.percentageThinning)) ? 0 : parseInt(el.percentageThinning)),
                    year: year
                }
            }
            // else {
            //     Felling_arr[i] = { Vf: 0, Nf: 0, Pf: 0, year: 0 }
            // }
        })
    }
    // Simulate
    let zeroCrossing = false;
    try {
        // Simulate backward steps
        let age_i = age;
        for (let i = n_stepsBack; i > 0; i--) {
            const data_next = estimationStep(scheme, specie, 'backward', {
                area: area,
                V: V_arr[i],
                H: H_arr[i],
                D: D_arr[i],
                G: G_arr[i],
                N: N_arr[i],
                age: age_i,
            }, i === n_stepsBack ? 0 : Felling_arr[i]); // Only simulate thinning/felling for current year once. Done in forward estimation step
            // Check for values crossing zero
            if (data_next.N_next <= 0) {
                zeroCrossing = true;
                break;
            }
            // Set output data in arrays
            H_arr[i - 1] = data_next.H_next;
            G_arr[i - 1] = data_next.G_next;
            N_arr[i - 1] = data_next.N_next;
            D_arr[i - 1] = data_next.D_next;
            V_arr[i - 1] = data_next.V_next;
            F_arr[i - 1] = data_next.F_next;
            CO2_arr[i - 1] = data_next.CO2_next;
            // Decrement age
            age_i = age_i - 1;
        }

        // Simulate forward steps
        age_i = age;
        for (let i = n_stepsBack; i < n_steps; i++) {
            const data_next = estimationStep(scheme, specie, 'forward', {
                area: area,
                V: V_arr[i],
                H: H_arr[i],
                D: D_arr[i],
                G: G_arr[i],
                N: N_arr[i],
                age: age_i,
            }, Felling_arr[i]);
            // Check for values crossing zero
            if (data_next.N_next <= 0) {
                zeroCrossing = true;
                break;
            }
            // Set output data in arrays
            H_arr[i + 1] = data_next.H_next;
            G_arr[i + 1] = data_next.G_next;
            N_arr[i + 1] = data_next.N_next;
            D_arr[i + 1] = data_next.D_next;
            V_arr[i + 1] = data_next.V_next;
            F_arr[i + 1] = data_next.F_next;
            CO2_arr[i + 1] = data_next.CO2_next;
            // Increment age
            age_i = age_i + 1;
        }
    } catch (err) {
        throw err;
    }


    return { H: H_arr, G: G_arr, N: N_arr, D: D_arr, V: V_arr, F: F_arr, CO2Equiv: CO2_arr, zeroCrossing }
}

export const estimationStep = (scheme, specie, type, initCond, felling) => {
    // Input:
    //  - specie,           stand specie (string)
    //  - type,             forward or backward estimation step (string "forward"/"backward")
    //  - initCond (obj):
    //      - area [ha],    cell area,
    //      - V [m3/ha],    stand volume pr. ha 
    //      - H [m],        stand height
    //      - D [m],        stand diameter
    //      - G [m2/ha],    stand ground area pr. ha
    //      - N [#/ha],     stand tree count pr. ha
    //      - age [years],  stand age
    //  - felling (obj):
    //      - Vf [m3],      tree volume taking out at felling
    //      - Nf [#],       trees taking out at felling
    //      - Pf [%],       felling percentage
    //
    // Output (obj):
    //  - H_next [m],       estimated stand height
    //  - G_next [m2/ha],   estimated stand ground area
    //  - N_next [#/ha],    estimated stand tree count 
    //  - D_next [m],       estimated stand diameter
    //  - V_next [m3/ha],   estimated stand volume
    //  - F_next [-],       estimated stand form number  

    // Cehck input and unpack
    if (specie === '') {
        throw { errType: 0, errText: "Error: specie input can not be an empty string." }
    }
    if (type !== 'forward' && type !== 'backward') {
        throw { errType: 1, errText: "Error: type can only be 'forward' or 'backward'." }
    }
    // Unpack initial conditions object
    const area = initCond.area ? (initCond.area !== "" ? parseFloat(initCond.area) : "") : "";
    const V = initCond.V ? (initCond.V !== "" ? parseFloat(initCond.V) : "") : "";
    const H = initCond.H ? (initCond.H !== "" ? parseFloat(initCond.H) : "") : "";
    const D = initCond.D ? (initCond.D !== "" ? parseFloat(initCond.D) : "") : "";
    const G = initCond.G ? (initCond.G !== "" ? parseFloat(initCond.G) : "") : "";
    const N = initCond.N ? (initCond.N !== "" ? parseFloat(initCond.N) : "") : "";
    const age = initCond.age ? (initCond.age !== "" ? parseInt(initCond.age) : "") : "";

    // Unpack felling object
    let Nf = felling.Nf ? (felling.Nf !== "" ? parseInt(felling.Nf) : 0) : 0;
    let Vf = felling.Vf ? (felling.Vf !== "" ? parseInt(felling.Vf) : 0) : 0;
    const Pf = felling.Pf ? (felling.Pf !== "" ? parseInt(felling.Pf) : 0) : 0;
    // Check if percentages are within bounds
    if (Pf < 0) {
        Pf = 0;
    } else if (Pf > 100) {
        Pf = 100;
    }

    // Check felling input. If Nf and/or Vf is given use these, else calculate them based on Pf, if Pf is present.
    if (Nf === 0 && Vf === 0 && Pf !== 0) {
        // Calculate new values for Nf and Vf based on felling percentage
        Nf = (Pf / 100) * N;
        Vf = (Pf / 100) * V;
    } else if (Nf !== 0 && Vf === 0) {
        Vf = V / N * Nf;
    }

    // Get model parameters
    const par = modelPar(scheme, specie);

    // Calculate current form number. Used for calculating felling volume
    const Fn = Fnum(V, H, G);

    // Get felling
    const FVN = FVn(Nf);
    const FVG = FVg(H, Fn, Vf);

    // Start estimation step
    let H_next = 0;
    let G_next = 0;
    let N_next = 0;
    let D_next = 0;
    let V_next = 0;
    let CO2_next = 0;
    let F_next = 0;
    // Check if estimation step is forward or backward
    try {
        if (type === "forward") {
            H_next = H + dH(H, G, age, par);
            G_next = G + dG(H, G, FVG, age, par);
            N_next = N + dN(H, N, FVN, par);
            D_next = N_next <= 0 ? 0 : 2 * Math.sqrt(G_next / (Math.PI * N_next));
            V_next = N_next <= 0 ? 0 : getSpecieVolume(scheme, specie, { d: D_next, h: H_next, T: age + 1 }).vb * N_next;
            F_next = N_next <= 0 ? 0 : Fnum(V_next, H_next, G_next);
            CO2_next = N_next <= 0 ? 0 : getSpecieCO2Equiv(scheme, specie, {Dg: D_next, Hg: H_next, V: V_next})
        } else {
            H_next = H - dH(H, G, age, par);
            G_next = G - dG(H, G, FVG, age, par);
            N_next = N - dN(H, N, FVN, par);
            D_next = N_next <= 0 ? 0 : 2 * Math.sqrt(G_next / (Math.PI * N_next));
            V_next = N_next <= 0 ? 0 : getSpecieVolume(scheme, specie, { d: D_next, h: H_next, T: age - 1 }).vb * N_next;
            F_next = N_next <= 0 ? 0 : Fnum(V_next, H_next, G_next);
            CO2_next = N_next <= 0 ? 0 : getSpecieCO2Equiv(scheme, specie, {Dg: D_next, Hg: H_next, V: V_next});
        }
        H_next = H_next < 0 ? 0 : H_next;
        G_next = G_next < 0 ? 0 : G_next;
        N_next = N_next < 0 ? 0 : N_next;
        D_next = D_next < 0 ? 0 : D_next;
        V_next = V_next < 0 ? 0 : V_next;
        F_next = F_next < 0 ? 0 : F_next;
        CO2_next = CO2_next < 0 ? 0 : CO2_next;

    } catch (err) {
        // console.warn("Error in estimation step: ", err)
        throw err;
    }


    // Return updated state
    return { H_next, G_next, N_next, D_next, V_next, F_next, CO2_next }
}

// Estimated change in height
const dH = (H, G, age, par) => {
    // Unpack parameters
    const a_01 = par.a_01;
    const a_02 = par.a_02;
    const S = Sj(H, age, par);
    const a1 = par.a1;
    const a2 = par.a2;
    const a3 = par.a3;
    
    const dH = (a_01 + a_02 * S) * Math.pow(H, a1) * Math.exp(a2 * H + a3 * G);
    return dH;
}

// Estimated change in g area
const dG = (H, G, FVg, age, par) => {
    // Unpack parameters
    const b_01 = par.b_01;
    const b_02 = par.b_02;
    const S = Sj(H, age, par);
    const b1 = par.b1;
    const b2 = par.b2;
    const b3 = par.b3;
    const b4 = par.b4;

    return (b_01 + b_02 * S) * Math.pow(G, b1) * Math.exp(b2 * G + b3 * Math.pow(H, b4)) + FVg;
}

// Estimated change in tree count
const dN = (H, N, FVn, par) => {
    // Unpack parameters
    const y1 = par.y1;
    const y2 = par.y2;
    const y3 = par.y3;
    const treeCountCorr = par.treeCountCorr;

    // Check that there is at least one tree in the stand
    if (N <= 0.1) {
        return 0;
    }
    // Calculate the decrement in tree count
    let dN = -y1 * Math.pow(N, y2) * Math.exp(y3 * Math.sqrt(N * H)) * treeCountCorr + FVn;
    // Check that the decrement in tree count is not below the actual tree count
    let out = Math.abs(dN) > N ? -N/2 : dN;
    return out
}

// Felling tree count
const FVn = (Nf) => {
    return -Nf;
}
// Felling volume
const FVg = (H, F, Vf) => {
    return -Vf / (H * F);
}

const Fnum = (V, H, G) => {
    return V / (H * G);
}

const gArea = (Dg, N) => {
    return Math.pow((Dg / 2), 2) * Math.PI * N;
}

const Sj = (H, age, par) => {
    // Unpack parameters
    const sb1 = par.sb1;
    const sb2 = par.sb2;
    const Rt = R(H, age, par);
    const t = par.t;

    let Sj = H * ((Math.pow(t, sb1) * (Math.pow(age, sb1) * Rt + sb2)) / (Math.pow(age, sb1) * (Math.pow(t, sb1) * Rt + sb2)));
    // console.log("SJ", Sj)
   //  Sj = 30; 
    return Sj;
}

const R = (H, age, par) => {
    // Unpack parameters
    const sb1 = par.sb1;
    const sb2 = par.sb2;
    const Zt = Z(H, par);

    // Original equation:  R = Zt + Math.sqrt(Math.pow(Zt, 2) + ((2 * sb2 * H) / Math.pow(age, sb1)));
    // Chech if the value inside the sqrt becomes negative. If so replace by small positive number
    let sq = Math.pow(Zt, 2) + ((2 * sb2 * H) / Math.pow(age, sb1));
    sq = sq <= 0 ? 0 : sq;

    const R = Zt + Math.sqrt(sq);

    return R;
}

const Z = (H, par) => {
    // Unpack parameters
    const sb3 = par.sb3;

    return H - sb3;
}

////////////////////////////
// Volume functions
///////////////////////////
// From the book: ..., pages 8-94, parameters pages 95-132
export const getSpecieVolume = (scheme, specie, initCond) => {
    // Input:
    //  - specie [-]    =   tree specie.
    //  initCond:
    //  - d [m]         =   chest height diameter of single tree (if only Dg available, then use that. Typical, for full stand estimation).
    //  - d3 [m]        =   diameter of single tree measured at 3 meters above ground (only used for salable volume calculations). Could also be mean of stand.
    //  - d6 [m]        =   diameter of single tree measured at 6 meters above ground (only used for salable volume calculations). Could also be mean of stand.
    //  - a [m]         =   height of cutting limit from ground.
    //  - h [m]         =   height of individual tree measuret from ground to stem top (if only Hg available, then use that. Typical, for full stand estimation).
    //  - Dg [m]        =   mean weighted stand diameter at base. Represented before thinning. Dg = sqrt((d_tree:1^2 + d_tree:2^2 + ... + d_tree:n^2)/n)
    //  - Hg [m]        =   stand height corresponding to Dg. Often replaced with Hdom (H100), whish is the mean height of the 100 most dominating trees.
    //                      !!! --- In this work we use Hdom --- !!!
    //  - T [years]     =   Age of tree from seed. 
    //
    // Output:
    //  - vb [m3]       =   above ground total volume of individual tree.
    //  - v5b [m3]      =   salable tree volume above cutting height of 5cm. (NOT IMPLEMENTED YET)
    //  - v7b [m3]      =   salable tree volume above cutting height of 7cm. (NOT IMPLEMENTED YET)
    //  - v10b [m3]     =   salable tree volume above cutting height of 10cm. (NOT IMPLEMENTED YET)

    // Input checking 
    // All input values must be positive.
    if (typeof initCond !== 'object') {
        throw { type: 0, text: "Error: input with initial conditions is not an object" };
    } else {
        // Check if all initial conditions are positive
        Object.values(initCond).forEach(el => {
            if (el <= 0) {
                throw { type: 1, text: `Error: input element (${el}) is less than or equal to 0. Input elements must be positive` };
            }
            if (isNaN(el)) {
                throw { type: 1, text: `Error: input element (${el}) is NaN.` };
            }
        })
        // Check that at least height (h) or (Hg) and diameter (d) or (Dg) is present
        if ((!initCond.d && !initCond.Dg) || (!initCond.h && !initCond.Hg)) {
            throw { type: 2, text: "Error: initial conditions needs to contain either d or Dg and h or Hg", initialConditions: {specie: specie, initCond: initCond} }
        }
    }

    // There are four additional constraints: TODO: (NOT FULLY IMPLEMENTED AT THE MOMENT - we only check constraint 1)
    //  1.  Function only works for h > 1.3, Hg > 1.3
    //  2.  The variables y2, y3, y4, X14, X15, ...,X23 are only computed for d > a, i.e., selable volume function is only computed
    //      for trees where breast height diameter is greater than deposition limit. For y2 (a=0.05), y3 (a=0.07) and y4 (a=0.10) to calculate
    //      the variables X14, X15, ..., X23.
    //  3.  The variables X10, X11, X20 and X21 are only calculated for 6.0 <= h <= 15.0, i.e., the functions containing d3 are only valid for
    //      tree height between 6-15 meters.
    //  4.  The variables X12, X13, X22 and X23 are only calculated for h >= 12.0, i.e., the functions containing d6 are only valid for
    //      tree heights above 12 meters.

    // Check constraint 1
    if ((initCond.h && initCond.h < 1.3) || (initCond.Hg && initCond.Hg < 1.3)) {
        //throw { type: 3, text: "Error: estimation only permitted for trees and stands with h > 1.3 and Hg (Hdom) > 1.3." }
        console.warn("Warning: estimation only permitted for trees and stands with h > 1.3 and Hg (Hdom) > 1.3.")
        return { vb: 0, v5b: 0, v7b: 0, v10b: 0 }
    }

    // Check for specie volume function
    let cSpc = getSpecieParameters(specie).volFunction ? getSpecieParameters(specie).volFunction : specie;

    // Get specie volume
    let v = { vb: 0, v5b: 0, v7b: 0, v10b: 0 };
    switch (cSpc) {
        // Foliage
        case "Avnbøg - AVN":
        case "Rødeg - REG":
        case "Fuglekirsebær - KIR":
        case "Lind - LIN":
        case "Bøg - BØG": v = volBeech(initCond, modelPar(scheme, "Bøg - BØG")); break;
        case "Poppel - POP":
        case "Kastanie - KAS":
        case "Eg - EG": v = volOak(initCond, modelPar(scheme, "Eg - EG")); break;
        case "Rødel - REL": v = volRodel(initCond, modelPar(scheme, "Rødel - REL")); break;
        case "Ask - ASK": v = volAsh(initCond, modelPar(scheme, "Ask - ASK")); break;
        case "Birk - BIR": v = volBirk(initCond, modelPar(scheme, "Birk - BIR")); break;
        case "Hybrid asp - HAS": v = volHybridAsp(initCond, modelPar(scheme, "Hybrid asp - HAS")); break;
        case "Andet løv - ALØ":
        case "Værnskov - VÆR":
        case "Urørt løvskov - ULS":
        case "Biodiversitetsskov løv - BSL":
        case "Blandet løv - BLØ":
        case "Pil - PIL":
        case "Hjertetræ - HJE":
        case "Skovbryn - SKB":
        case "Læhegn - LÆH":
        case "Læhegn":
        case "Hyldetræ - HYT":
        case "Krat - KRA":
        case "Krat":
        case "Asp - ASP":
        case "Slåen - SLÅ":
        case "Ahorn - ÆR": v = volAhorn(initCond, modelPar(scheme, "Ahorn - ÆR")); break;
        // Needle
        case "Omorika - OMO":
        case "Østrisk fyr - ØSF":
        case "Bjergfyr - BJF":
        case "Fransk bjergfyr - FBF":
        case "Rødgran - RGR": v = volNorwaySpruce(initCond, modelPar(scheme, "Rødgran - RGR")); break;
        case "Skovfyr - SKF": v = volSkovfyr(initCond, modelPar(scheme, "Skovfyr - SKF")); break;
        case "Contortafyr - COF": v = volContartafyr(initCond, modelPar(scheme, "Contortafyr - COF")); break;
        case "Andet nål - ANÅ":
        case "Urørt nåleskov - UNS":
        case "Biodiversitetsskov nål - BSN":
        case "Blandet nål - BLN":
        case "Abies - ABI":
        case "Nobilis - NOB": v = volNobilis(initCond, modelPar(scheme, "Nobilis - NOB")); break;
        case "Sitkagran - SGR": v = volSitkaSpruce(initCond, modelPar(scheme, "Sitkagran - SGR")); break;
        case "Thuja - THU":
        case "Tsuga - TSU":
        case "Cypres - CYP":
        case "Cryptomeria - CRY":
        case "Nordmannsgran - NGR":
        case "Alm. ædelgran - ÆGR": v = volSilverFir(initCond, modelPar(scheme, "Alm. ædelgran - ÆGR")); break;
        case "Douglas - DGR": v = volDouglasFir(initCond, modelPar(scheme, "Douglas - DGR")); break;
        case "Europæisk lærk - EUL":
        case "Hybrid lærk - HYL":
        case "Lærk - LÆR": v = volLarch(initCond, modelPar(scheme, "Lærk - LÆR")); break;
        case "Grandis - GRA": v = volGiantSpruce(initCond, modelPar(scheme, "Grandis - GRA")); break;
        default: v = { vb: 0, v5b: 0, v7b: 0, v10b: 0 }; break;
    }
    return v;
}

// Volume function Beech (bøg)
const volBeech = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb3 = par.vb3;
    const vb5 = par.vb5;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    const X3 = Math.log(Dg);
    // const X4 = Math.log(h / (h - 1.3));
    const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    // const X24 = Dg;
    // const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb3 * X3 + vb5 * X5 + vbw / w1);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Oak (Eg)
const volOak = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    // const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vbw / w1);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Rodel (Rødel)
const volRodel = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    // const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vbw / w1);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Hybrid Asp (Eg)
const volHybridAsp = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    // const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vbw / w1);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Birk (Birk)
const volBirk = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    // const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vbw / w1);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Ahorn (Ahorn - ær)
const volAhorn = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb3 = par.vb3;
    const vb5 = par.vb5;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    const X3 = Math.log(Dg);
    // const X4 = Math.log(h / (h - 1.3));
    const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    // const X24 = Dg;
    // const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb3 * X3 + vb5 * X5 + vbw / w1);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Norway spruce (Rødgran)
const volNorwaySpruce = (input, par) => {
    // Unpack input
    let d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    let Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vb25 * X25 + vbw / w3);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Skovfyr (Skovfyr)
const volSkovfyr = (input, par) => {
    // Unpack input
    let d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    let Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vb25 * X25 + vbw / w3);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Contartafyr 
const volContartafyr = (input, par) => {
    // Unpack input
    let d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    let Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb24 = par.vb24;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    // const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb24 * X24 + vb25 * X25 + vbw / w3);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Nobilis
const volNobilis = (input, par) => {
    // Unpack input
    let d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    let Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vbw / w3);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Sitka spruce (Sitkagran)
const volSitkaSpruce = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vb25 * X25 + vbw / w3);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Silver fir (Ædelgran)
const volSilverFir = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vb25 * X25 + vbw / w3);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Douglas fir (Douglasgran)
const volDouglasFir = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    // const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    // const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb25 * X25 + vbw / w2);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Larch (Lærk - baseret på japansk lærk)
const volLarch = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    // const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb25 * X25 + vbw / w2);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Giant Spuce (Kæmpegran/Grandis)
const volGiantSpruce = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb24 = par.vb24;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    // const w1 = 1;
    const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    // const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb24 * X24 + vb25 * X25 + vbw / w2);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Volume function Ash (Ask)
const volAsh = (input, par) => {
    // Unpack input
    const d = input.d ? input.d : input.Dg;
    const h = input.h ? input.h : input.Hg;
    const Hg = input.Hg ? input.Hg : input.h;
    const Dg = input.Dg ? input.Dg : input.d;
    const d3 = input.d3 ? input.d3 : 0;
    const d6 = input.d6 ? input.d6 : 0;
    const a = input.a ? input.a : 0;
    const T = input.T ? input.T : 0;

    // Unpack parameters
    const vbA = par.vbA;
    const vbw = par.vbw;
    const vb1 = par.vb1;
    const vb2 = par.vb2;
    const vb4 = par.vb4;
    const vb24 = par.vb24;
    const vb25 = par.vb25;

    // Internal parameters
    // const a = 0;
    // const a5 = 0.05;
    // const a7 = 0.07;
    // const a10 = 0.10;

    // Weights
    const w1 = 1;
    // const w2 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * (1 - (d / 2));
    // const w3 = (1 - Math.pow(a, 2) / Math.pow(d, 2)) * Math.pow((1 - (d / 2)), 2);
    // const w4 = (1 - Math.pow(a, 2) / Math.pow(d, 2));

    // Calculate terms
    // Compute terms
    const X1 = Math.log(d);
    const X2 = Math.log(h);
    // const X3 = Math.log(Dg);
    const X4 = Math.log(h / (h - 1.3));
    // const X5 = Math.pow(Math.log(Dg), 2);
    // const X6 = Math.log(Hg);
    // const X7 = Math.pow(Math.log(Hg), 2);
    // const X8 = Math.log(T);
    // const X9 = Math.pow(Math.log(T), 2);
    // const X10 = Math.log(d3);
    // const X11 = Math.log(h / Math.sqrt((h - 1.3) * (h - 3.0)));
    // const X12 = Math.log(d6);
    // const X13 = Math.log(h / Math.sqrt((h - 1.3) * (h - 6.0)));
    // const X14 = Math.log(1 - (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X15 = Math.log(1 + (Math.pow(a / d, 2) * (h - 1.3)) / h);
    // const X16 = Math.log(1 - Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X17 = Math.log(1 + Math.pow((a / d) * (h - 1.3), 3 / 2) / h);
    // const X18 = Math.log(1 - Math.pow(a / d, 4) * Math.pow((h - 1.3) / h, 2));
    // const X19 = Math.log(1 - Math.pow(a / d, 3) * Math.pow((h - 1.3) / h, 3));
    // const X20 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d3, 2))) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2));
    // const X21 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d3)) * (h - 1.3) * (h - 3.0) / Math.pow(h, 2), 3 / 2));
    // const X22 = Math.log(1 - (Math.pow(a, 4) / (Math.pow(d, 2) * Math.pow(d6, 2))) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2));
    // const X23 = Math.log(1 - Math.pow((Math.pow(a, 2) / (d * d6)) * (h - 1.3) * (h - 6.0) / Math.pow(h, 2), 3 / 2));
    const X24 = Dg;
    const X25 = Math.pow(Dg, 2);

    // Set output 
    // TODO: Only vb implemented for now.
    let vb = 0;
    let v5b = 0;
    let v7b = 0;
    let v10b = 0;

    vb = Math.exp(vbA + vb1 * X1 + vb2 * X2 + vb4 * X4 + vb24 * X24 + vb25 * X25 + vbw / w1);

    // Return volume
    return { vb, v5b, v7b, v10b }
}

// Carbon is based on four things
// 1. ground musk, BMg
// 2. Litter layer, BMl
// 3. Dead wood, BMdw
// 4. Living wood (roots and tree), BMtr
export const getSpecieCO2Equiv = (scheme, specie, initCond) => {
    // Input:
    //  - specie [-]      =   tree specie.
    //  initCond:
    //  - Dg [m]          =   chest height diameter of single tree (if only Dg available, then use that. Typical, for full stand estimation).
    //  - Hg [m]          =   stand height corresponding to Dg. Often replaced with Hdom (H100), whish is the mean height of the 100 most dominating trees.
    //  - V [m^3]         =   volume of specific tree (only single tree)
    //  - geoP [lat,lng]  =   geo location point in latitude and longitude
    //
    // Output:
    //  - CO2tr_e [t CO2-e/Ha]    =   CO2 equivalent in living tree (trunck, roots, branches) in tons pr. hectar

    // Input checking 
    if (typeof initCond !== 'object') {
        throw { type: 0, text: "Error: input with initial conditions is not an object" };
    } else {
        
        // Check that height (Hg), diameter (Dg) and volume (V) have positiv values
        if (initCond.Dg <= 0 || initCond.Hg <= 0 || initCond.V <= 0) {
            throw { type: 2, text: "Get CO-e Error: initial conditions Dg, Hg and V all need to have positive values" }
        }
        // Check that height (Hg), diameter (Dg) and volume (V) is present
        if (!initCond.Dg || !initCond.Hg || !initCond.V) {
            throw { type: 1, text: "Get CO-e Error: initial conditions needs to contain Dg, Hg and V" }
        }
    }

    // Get model parameters
    const par = modelPar(scheme, specie);

    // Unpack initial conditions and parameters
    const Dg = initCond.Dg;
    const Hg = initCond.Hg;
    const V = initCond.V;

    const bg_a0 = par.bg_a0, bg_a1 = par.bg_a1, bg_a2 = par.bg_a2, bg_a3 = par.bg_a3, bg_a4 = par.bg_a4;
    const ag_a0 = par.ag_a0, ag_a1 = par.ag_a1, ag_a2 = par.ag_a2, ag_a3 = par.ag_a3, ag_a4 = par.ag_a4;
    const den = par.density;
    const cF = par.carbonFactor;
    const co2F = par.CO2Factor;

    // Initialize output variables
    let CO2tr_e = 0;

    // Calculate CO2 equivalent for living tree (roots, trunct, branches). Based on IPCC model from 2003
    // First we find the expansion factors for below and above ground
    let Ebg = bg_a0 + bg_a1*1/Dg + bg_a2*Dg - bg_a3*Dg + bg_a4*Hg;
    let Eag = ag_a0 + ag_a1*1/Dg + ag_a2*Dg - ag_a3*Dg + ag_a4*Hg;

    // Check if the values goes below 1.1. If so truncate to 1.1
    Ebg = Ebg <= 1.1 ? 1.1 : Ebg;
    Eag = Eag <= 1.1 ? 1.1 : Eag;

    // Then we find the total biomass of the tree (roots, trunck and branches)
    const BMtr = V*den*Ebg*Eag;

    // Then transform from biomass to CO2-e
    CO2tr_e = BMtr*cF*co2F;
    
    return CO2tr_e
}

export const getAreaCO2Equiv = (area, initCond) => {
    // Input:
    //  - area [ha]       =   Area in ha.
    //  initCond:
    //  - geoP [lat,lng]  =   geo location point in latitude and longitude
    //
    // Output:
    //  - CO2area_e [t CO2-e]     =   CO2 equivalent of the area excluding living biomass in tons

    // Parameters
    const Cg_meanDK = 168; // Landsgennemsnit for mineral jorden i skov områder [t C/ha]
    const Cl_meanDK = 15; // Landsgennemsnit for litter [t C/ha]
    const Cdw_meanDK = 1.3; // Landsgennemsnit for dødt ved [t C/ha]
    const CO2_factor = 3.67; // Conversion factor from carbon to CO2 equivalent (outset in molar mass)   
    
    // Input checking 
    if (typeof initCond !== 'object') {
        throw { type: 0, text: "Error: input with initial conditions is not an object" };
    }

    // Initialize output
    let CO2area_e = 0;

    // Set output (only based on mean values for the moment)
    CO2area_e =  CO2_factor*(Cg_meanDK + Cl_meanDK + Cdw_meanDK)*area;

    // Return output
    return CO2area_e;
}