import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material UI Icons
import NoteAddIcon from '@material-ui/icons/CloudUploadRounded';

// Store
import configFirebase from '../../../../backend/configFirebase';
import * as actions from '../../../../store/actions/index';

// Components
import StatLine from '../../Display/StatLine';
import SpaceMenu from '../../Menus/UIElements/SpaceMenu';
import FilesList from '../../Lists/FilesList';

const cookies = new Cookies();

const styles = () => ({
    cardContent: {
        paddingTop: '4px',
        position: 'relative',
        margin: 'auto',
        width: '100%',
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    addDocumentBtnIcon: {
        marginRight: 6
    },
    buttonProgress: {
        color: '5EBD3E',
        position: 'absolute',
    },
});

class DocumentsWidget extends React.Component {
    constructor(props) {
        super(props);
        const documentsWidgetState = cookies.get('documentsWidgetState');
        this.state = {
            open: documentsWidgetState === 'close' ? false : true,
            delete: false,
            budget: {
                title: "Budgetopfølgning",
                name: "budget",
                folderName: "budgetDocuments",
                open: false,
                unread: false,
            },
            account: {
                title: "Kontoudtog",
                name: "account",
                folderName: "accountDocuments",
                open: false,
                unread: false,
            },
            settlements: {
                title: "Afregninger",
                name: "settlements",
                folderName: "settlementDocuments",
                open: false,
                unread: false,
            },
            invoice: {
                title: "Faktura",
                name: "invoice",
                folderName: "invoiceDocuments",
                open: false,
                unread: false,
            },
            other: {
                title: "Øvrige",
                name: "other",
                folderName: "otherDocuments",
                open: false,
                unread: false,
            }
        };
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onDeleteDocument = (doc) => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Documentswidget',
            action: 'Deleted document',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        // Delete document
        this.props.onDeleteDocument(doc.forestId, doc.id)

    }

    onOpenDocument = (doc) => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Documentswidget',
            action: 'Opened document',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        // Create link and download file in new window
        const link = document.createElement("a");
        link.href = doc.url;
        link.target = "_blank";
        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        link.dispatchEvent(evt);

        // Set file read state on firestore
        this.props.onSetDocumentReadState(doc.forestId, doc.id)
    }

    onAddDocument = documentState => event => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Documentswidget',
            action: 'Add document',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        // Add new documents
        let files = []
        for (var i = 0; i < event.target.files.length; i++) {
            files[i] = event.target.files.item(i);
        }

        this.props.onUploadDocument(files, configFirebase.documentsBucket, this.props.selectedForest, documentState)
    }

    expandAction() {
        this.setState({ open: this.state.open ? false : true });

        cookies.set('documentsWidgetState', this.state.open ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'Documentswidget',
            action: `Documentswidget: ${this.state.open ? 'close' : 'open'}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    expandStat(obj) {
        let updatedObj = { ...this.state[obj.name] };
        updatedObj.open = !updatedObj.open;
        this.setState({ [obj.name]: updatedObj })
    }

    renderExpansionContent = (documents, docObj) => {
        const { classes } = this.props;
        const documentList = documents.filter(doc => doc.folderName === docObj.folderName);
        const unread = documentList.filter(el => !el.readState).length > 0;
        if (unread !== docObj.unread) {
            let updatedObj = { ...this.state[docObj.name] };
            updatedObj.unread = unread;
            this.setState({ [docObj.name]: updatedObj })
        }
        const lastModifiedDate = documentList[0] ? documentList[0].creationDate : null;

        return (
            <div style={{ paddingLeft: 10 }}>
                <SpaceMenu multiplier={2} />

                <input
                    accept=".docx,.pdf,.xlsx"
                    style={{ display: 'none' }}
                    id={docObj.folderName}
                    multiple
                    type="file"
                    disabled={!this.props.selectedForest}
                    onChange={this.onAddDocument(docObj.folderName)}
                />
                <label htmlFor={docObj.folderName}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                component="span"
                                disabled={this.props.loading}
                                color="primary"
                                variant="outlined"
                            >
                                <NoteAddIcon className={classes.addDocumentBtnIcon} />

                                Tilføj dokumenter
                            </Button>

                            {this.props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </label>

                <SpaceMenu multiplier={2} />

                <Divider />

                <div style={{ maxHeight: 400, overflow: 'auto' }}>
                    <List dense={true} style={{ padding: 0 }}>
                        <FilesList
                            documents={documentList}
                            loading={this.props.loading}
                            onDeleteDocument={this.onDeleteDocument}
                            onOpenDocument={this.onOpenDocument}
                        />
                    </List>
                </div>

                <Divider />

                <SpaceMenu />

                {lastModifiedDate && <div>
                    {this.renderDate("Opdateret: ", lastModifiedDate)}

                    <SpaceMenu multiplier={2} />
                </div>}
            </div>
        )
    }

    renderDate = (title, timestamp) => {
        if (!timestamp) return null;
        return (
            <Typography
                variant="caption"
                style={{ color: '#747474' }}
            >
                {title + moment(timestamp).format('DD[-]MM[-]YYYY HH:mm')}
            </Typography>
        )
    }

    render() {
        const { classes } = this.props;
        // Return null in case of no forest
        if (this.props.fileList && !this.props.selectedForest) return null;
        // Return spinner if list is loading or list
        if (!this.props.fileList || !this.props.fileList[this.props.selectedForest]) {
            return (
                <div style={{ height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={24} className={classes.buttonProgress} />
                </div>
            )
        } else {
            // Filter and sort document list
            let sortedDocuments = this.props.fileList[this.props.selectedForest].filter(doc => doc.folderName !== "financialStatsDocuments").sort((a, b) => Date.parse(new Date(b.creationDate)) - Date.parse(new Date(a.creationDate)));
            // Set overall last updated 
            const lastModifiedDate = sortedDocuments[0] ? sortedDocuments[0].creationDate : null;
            return (
                <div>
                    <ExpansionPanel
                        expanded={this.state.open}
                        onChange={() => this.expandAction()}
                    >
                        <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h5">
                                Dokumenter
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails className={classes.cardContent}>
                            <Divider />

                            <SpaceMenu multiplier={2} />

                            {true && <StatLine
                                title={this.state.budget.title}
                                bold={this.state.budget.unread}
                                expansionContent={this.renderExpansionContent(sortedDocuments, this.state.budget)}
                                onExpandStat={() => this.expandStat(this.state.budget)}
                                open={this.state.budget.open}
                            />}

                            <SpaceMenu />

                            {true && <StatLine
                                title={this.state.account.title}
                                bold={this.state.account.unread}
                                expansionContent={this.renderExpansionContent(sortedDocuments, this.state.account)}
                                onExpandStat={() => this.expandStat(this.state.account)}
                                open={this.state.account.open}
                            />}

                            <SpaceMenu />

                            {true && <StatLine
                                title={this.state.settlements.title}
                                bold={this.state.settlements.unread}
                                expansionContent={this.renderExpansionContent(sortedDocuments, this.state.settlements)}
                                onExpandStat={() => this.expandStat(this.state.settlements)}
                                open={this.state.settlements.open}
                            />}

                            <SpaceMenu />

                            {true && <StatLine
                                title={this.state.invoice.title}
                                bold={this.state.invoice.unread}
                                expansionContent={this.renderExpansionContent(sortedDocuments, this.state.invoice)}
                                onExpandStat={() => this.expandStat(this.state.invoice)}
                                open={this.state.invoice.open}
                            />}

                            <SpaceMenu />

                            {true && <StatLine
                                title={this.state.other.title}
                                bold={this.state.other.unread}
                                expansionContent={this.renderExpansionContent(sortedDocuments, this.state.other)}
                                onExpandStat={() => this.expandStat(this.state.other)}
                                open={this.state.other.open}
                            />}

                            <SpaceMenu multiplier={2} />

                            <Divider />

                            {lastModifiedDate && <div>
                                <SpaceMenu />

                                {this.renderDate("Opdateret: ", lastModifiedDate)}
                            </div>}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            );
        }

    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        selectedForest: state.customer.currentlySelectedForestId,
        loading: state.documents.loading,
        error: state.documents.error,
        fileList: state.documents.fileList,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUploadDocument: (docs, bucket, forestId, folderName) => dispatch(actions.uploadDocument(docs, bucket, forestId, folderName)),
        onSetDocumentReadState: (forestId, docId) => dispatch(actions.setDocumentReadState(forestId, docId)),
        onDeleteDocument: (forestId, docId) => dispatch(actions.deleteDocument(forestId, docId)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentsWidget)));