import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const backZidx = 99;
const frontZidx = 101;
const btnColorNormal = 'white';
const btnColorDisabled = '#F5F5F5';

const initialState = {
    skipOnboarding: false,
    onboardingActive: false,
    onboardingState: 'welcome',
    zoomBtnZidx: backZidx,
    mapLayerZidx: backZidx,
    forestSelecterZidx: backZidx,
    mapSelecterZidx: backZidx,
    drawToolZidx: backZidx,
    measToolZidx: backZidx,
    newNoteBtnZidx: backZidx,
    sideBarZidx: backZidx,
    invListZidx: backZidx,
    printMapZidx: backZidx,
    findPosZidx: backZidx,
    searchBtnZidx: backZidx,

    onboardingBtnColor: btnColorNormal,
    mapLayerBtnColor: btnColorNormal,
    newNoteBtnColor: btnColorNormal,
    drawBtnColor: btnColorNormal,
    measBtnColor: btnColorNormal,
    customerSelectBtnColor: btnColorNormal,
    mapSelectBtnColor: btnColorNormal,
    findPosBtnColor: btnColorNormal,

    disableMapLayer: false,
    disableNewNote: false,
    disableDraw: false,
    disableMeas: false,
    disableCustomerSelect: false,
    disableMapSelect: false,
    disableFindPos: false,

    customerWidgetActive: true,
    notesWidgetActive: true,
    weatherWidgetActive: true,
    newsWidgetActive: true,
};


const setSkipOnboardingState = (state, action) => {
    return updateObject(state, { skipOnboarding: action.skipState });
};

const setOnboardingActive = (state, action) => {
    return updateObject(state, { onboardingActive: action.onboardingActive });
};

const setOnboardingState = (state, action) => {
    return updateObject(state, { onboardingState: action.onboardingState });
};

// Z-index toggle functions 
const zoomBtnZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { zoomBtnZidx: frontZidx });
    } else {
        return updateObject(state, { zoomBtnZidx: backZidx });
    }
};

const mapLayerZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { mapLayerZidx: frontZidx });
    } else {
        return updateObject(state, { mapLayerZidx: backZidx });
    }
};

const forestSelecterZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { forestSelecterZidx: frontZidx });
    } else {
        return updateObject(state, { forestSelecterZidx: backZidx });
    }
};

const mapSelecterZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { mapSelecterZidx: frontZidx });
    } else {
        return updateObject(state, { mapSelecterZidx: backZidx });
    }
};

const drawToolZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { drawToolZidx: frontZidx });
    } else {
        return updateObject(state, { drawToolZidx: backZidx });
    }
};

const measToolZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { measToolZidx: frontZidx });
    } else {
        return updateObject(state, { measToolZidx: backZidx });
    }
};

const newNoteBtnZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { newNoteBtnZidx: frontZidx });
    } else {
        return updateObject(state, { newNoteBtnZidx: backZidx });
    }
};

const sideBarZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { sideBarZidx: frontZidx });
    } else {
        return updateObject(state, { sideBarZidx: backZidx });
    }
};

const invListZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { invListZidx: frontZidx });
    } else {
        return updateObject(state, { invListZidx: backZidx });
    }
};

const printMapZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { printMapZidx: frontZidx });
    } else {
        return updateObject(state, { printMapZidx: backZidx });
    }
};

const findPosZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { findPosZidx: frontZidx });
    } else {
        return updateObject(state, { findPosZidx: backZidx });
    }
};

const searchBtnZidxToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { searchBtnZidx: frontZidx });
    } else {
        return updateObject(state, { searchBtnZidx: backZidx });
    }
};

// Color toggle functions
const onboardingBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { onboardingBtnColor: btnColorNormal });
    } else {
        return updateObject(state, { onboardingBtnColor: btnColorDisabled });
    }
};

const mapLayerBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { mapLayerBtnColor: btnColorNormal, disableMapLayer: false });
    } else {
        return updateObject(state, { mapLayerBtnColor: btnColorDisabled, disableMapLayer: true });
    }
};

const newNoteBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { newNoteBtnColor: btnColorNormal, disableNewNote: false });
    } else {
        return updateObject(state, { newNoteBtnColor: btnColorDisabled, disableNewNote: true });
    }
};

const drawBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { drawBtnColor: btnColorNormal, disableDraw: false });
    } else {
        return updateObject(state, { drawBtnColor: btnColorDisabled, disableDraw: true });
    }
};

const measBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { measBtnColor: btnColorNormal, disableMeas: false });
    } else {
        return updateObject(state, { measBtnColor: btnColorDisabled, disableMeas: true });
    }
};

const customerSelectBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { customerSelectBtnColor: btnColorNormal, disableCustomerSelect: false });
    } else {
        return updateObject(state, { customerSelectBtnColor: btnColorDisabled, disableCustomerSelect: true });
    }
};

const mapSelectBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { mapSelectBtnColor: btnColorNormal, disableMapSelect: false });
    } else {
        return updateObject(state, { mapSelectBtnColor: btnColorDisabled, disableMapSelect: true });
    }
};

const findPosBtnColorToggle = (state, action) => {
    if (action.state === 'front') {
        return updateObject(state, { findPosBtnColor: btnColorNormal, disableFindPos: false });
    } else {
        return updateObject(state, { findPosBtnColor: btnColorDisabled, disableFindPos: true });
    }
};

// Disable buttons functions
const setDisableMapLayer = (state, action) => {
    return updateObject(state, { disableMapLayer: action.state });
};

const setDisableDraw = (state, action) => {
    return updateObject(state, { disableDraw: action.state });
};

const setDisableNewNote = (state, action) => {
    return updateObject(state, { disableNewNote: action.state });
};

const setDisableMeas = (state, action) => {
    return updateObject(state, { disableMeas: action.state });
};

const setDisableCustomerSelect = (state, action) => {
    return updateObject(state, { disableCustomerSelect: action.state });
};

const setDisableFindPos = (state, action) => {
    return updateObject(state, { disableFindPos: action.state });
};

// Sidebar widget active toggle functions
const setCustomerWidgetActive = (state, action) => {
    return updateObject(state, { customerWidgetActive: action.state });
};

const setNotesWidgetActive = (state, action) => {
    return updateObject(state, { notesWidgetActive: action.state });
};

const setWeatherWidgetActive = (state, action) => {
    return updateObject(state, { weatherWidgetActive: action.state });
};

const setNewsWidgetActive = (state, action) => {
    return updateObject(state, { newsWidgetActive: action.state });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SKIP_ONBOARDING_STATE: return setSkipOnboardingState(state, action);
        case actionTypes.SET_ONBOARDING_ACTIVE: return setOnboardingActive(state, action);
        case actionTypes.SET_ONBOARDING_STATE: return setOnboardingState(state, action);
        case actionTypes.ZOOM_BTN_Z_IDX_TOGGLE: return zoomBtnZidxToggle(state, action);
        case actionTypes.MAP_LAYER_Z_IDX_TOGGLE: return mapLayerZidxToggle(state, action);
        case actionTypes.FOREST_SELECTER_Z_IDX_TOGGLE: return forestSelecterZidxToggle(state, action);
        case actionTypes.MAP_SELECTER_Z_IDX_TOGGLE: return mapSelecterZidxToggle(state, action);
        
        case actionTypes.DRAW_TOOL_Z_IDX_TOGGLE: return drawToolZidxToggle(state, action);
        case actionTypes.MEAS_TOOL_Z_IDX_TOGGLE: return measToolZidxToggle(state, action);
        case actionTypes.NEW_NOTE_BTN_Z_IDX_TOGGLE: return newNoteBtnZidxToggle(state, action);
        case actionTypes.SIDE_BAR_Z_IDX_TOGGLE: return sideBarZidxToggle(state, action);
        case actionTypes.INV_LIST_Z_IDX_TOGGLE: return invListZidxToggle(state, action);
        case actionTypes.PRINT_MAP_Z_IDX_TOGGLE: return printMapZidxToggle(state, action);
        case actionTypes.FIND_POS_Z_IDX_TOGGLE: return findPosZidxToggle(state, action);
        
        case actionTypes.SEARCH_BTN_Z_IDX_TOGGLE: return searchBtnZidxToggle(state, action);

        case actionTypes.ONBOARDING_BTN_COLOR_TOGGLE: return onboardingBtnColorToggle(state, action);
        case actionTypes.MAP_LAYER_BTN_COLOR_TOGGLE: return mapLayerBtnColorToggle(state, action);
        case actionTypes.DRAW_BTN_COLOR_TOGGLE: return drawBtnColorToggle(state, action);
        case actionTypes.MEAS_BTN_COLOR_TOGGLE: return measBtnColorToggle(state, action);
        case actionTypes.CUSTOMER_SELECT_BTN_COLOR_TOGGLE: return customerSelectBtnColorToggle(state, action);
        case actionTypes.MAP_SELECT_BTN_COLOR_TOGGLE: return mapSelectBtnColorToggle(state, action);
        case actionTypes.FIND_POS_BTN_COLOR_TOGGLE: return findPosBtnColorToggle(state, action);
        case actionTypes.NEW_NOTE_BTN_COLOR_TOGGLE: return newNoteBtnColorToggle(state, action);
        
        case actionTypes.SET_DISABLE_MAP_LAYER: return setDisableMapLayer(state, action);
        case actionTypes.SET_DISABLE_DRAW: return setDisableDraw(state, action);
        case actionTypes.SET_DISABLE_MEAS: return setDisableMeas(state, action);
        case actionTypes.SET_DISABLE_CUSTOMER_SELECT: return setDisableCustomerSelect(state, action);
        case actionTypes.SET_DISABLE_FIND_POS: return setDisableFindPos(state, action);
        case actionTypes.SET_DISABLE_NEW_NOTE: return setDisableNewNote(state, action);

        case actionTypes.SET_CUSTOMER_WIDGET_ACTIVE: return setCustomerWidgetActive(state, action);
        case actionTypes.SET_NOTES_WIDGET_ACTIVE: return setNotesWidgetActive(state, action);
        case actionTypes.SET_WEATHER_WIDGET_ACTIVE: return setWeatherWidgetActive(state, action);
        case actionTypes.SET_NEWS_WIDGET_ACTIVE: return setNewsWidgetActive(state, action);
        default: return state;
    }
};

export default reducer;