import * as actionTypes from './actionTypes';
import firebase from 'firebase';
import moment from 'moment';

export const fetchNotesSuccess = (notes, forestId) => {
    return {
        type: actionTypes.FETCH_NOTES_SUCCESS,
        Notes: notes,
        id: forestId,
    };
};

export const fetchNotesFail = (error) => {
    return {
        type: actionTypes.FETCH_NOTES_FAIL,
        error: error
    };
};

export const fetchNotesStart = () => {
    return {
        type: actionTypes.FETCH_NOTES_START
    };
};

export const unmountNoteAction = () => {
    return {
        type: actionTypes.UNMOUNT_NOTE,
    };
};

export const fetchNotes = (forestId) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    dispatch(fetchNotesStart());

    // check for auth user
    if (user != null) {
        db.collection("notes").doc(forestId).collection("content")
            .onSnapshot(function (querySnapshot) {
                var fetchedNotes = [];

                querySnapshot.forEach(function (doc) {
                    fetchedNotes.push(doc.data());
                });

                // Dispatch notes
                dispatch(fetchNotesSuccess(fetchedNotes, forestId));
            });
    }

    // TO DO
    // when not auth
    // return dispatch => {

    // }
};

export const saveNoteSuccess = (id, noteData) => {
    return {
        type: actionTypes.SAVE_NOTE_SUCCESS,
    };
};

export const saveNoteFail = (error) => {
    return {
        type: actionTypes.SAVE_NOTE_FAIL,
        error: error
    };
};

export const saveNoteStart = () => {
    return {
        type: actionTypes.SAVE_NOTE_START
    };
};

export const saveNote = (noteData, forestId, envType, parentModule) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    const storage = firebase.storage();
    let imageUrls = [];
    const revId = 0; // TODO : set revId as input
    if (parentModule !== 'kanban') {
        dispatch(saveNoteStart());
    }
    try {
        // See if there any images - and save to Storage - and get urls into imageUrls
        if (noteData.images.length > 0) {
            const path = `noteImages/${forestId}`;

            // Upload (files) and get urls
            for (const [i, item] of noteData.images.entries()) {

                // Check for instanceof files (new files to storage)
                if (item instanceof File) {
                    let timestamp = new Date().getTime().toString();
                    const uploadTask = storage.ref(`${path}/${timestamp}`).put(item);

                    await uploadTask.then(function (snapshot) {
                    });

                    await storage.ref(path).child(timestamp).getDownloadURL().then(url => {
                        // Fill image-array with url
                        imageUrls.push(url);
                    });
                } else {
                    // Add url to array 
                    imageUrls.push(item);
                }
            }
        }
        // For points check for images in history list
        if (noteData.historyList) {
            const promisesAll = Object.values(noteData.historyList).map(async el => {
                let pointImageUrls = [];
                let promises = [];
                if (el.images && el.images.length > 0) {
                    const path = `pointImages/${forestId}`;

                    // Upload (files) and get urls
                    for (const [i, item] of el.images.entries()) {
                        // Check for instanceof files (new files to storage)
                        if (item instanceof File) {
                            let timestamp = new Date().getTime().toString();
                            const uploadTask = storage.ref(`${path}/${timestamp}`).put(item);

                            await uploadTask.then(function (snapshot) {
                            });

                            const prom = await storage.ref(path).child(timestamp).getDownloadURL().then(url => {
                                // Fill image-array with url
                                pointImageUrls.push(url);
                            });
                            promises.push(prom);
                        } else {
                            // Add url to array 
                            pointImageUrls.push(item);
                        }

                    }
                    el.images = pointImageUrls;
                }
                return promises;
            });
            await Promise.all(promisesAll);
        }
        // data is a point not a note
        // TODO : align notation throuhgout code point/note, etc.
        if (noteData.pointType) {
            if (envType === 'biotopePlan') {
                if (typeof noteData.id === 'undefined') {
                    // If new point
                    delete noteData.id;
                    const refId = db.collection("biotopePlans").doc(forestId).collection('content').doc("rev: " + revId).collection("points").doc();
                    refId.set({
                        id: refId.id,
                        kwSync: false,
                        creationDate: moment().format(),
                        ...noteData
                    });
                } else {
                    // Existing point
                    const refId = db.collection("biotopePlans").doc(forestId).collection('content').doc("rev: " + revId).collection("points").doc(noteData.id);
                    refId.update({
                        kwSync: false,
                        ...noteData
                    })
                }
            } else {
                let colType = 'content';
                if (envType === 'hunting') {
                    colType = 'hunting';
                }

                // Check state of point: new or existing
                if (typeof noteData.id === 'undefined') {
                    // If new point
                    delete noteData.id;
                    const refId = db.collection("forests").doc(forestId).collection(colType).doc("rev: " + revId).collection("points").doc();
                    refId.set({
                        id: refId.id,
                        kwSync: false,
                        creationDate: moment().format(),
                        ...noteData
                    });
                } else {
                    // Existing point
                    const refId = db.collection("forests").doc(forestId).collection(colType).doc("rev: " + revId).collection("points").doc(noteData.id);
                    refId.update({
                        kwSync: false,
                        ...noteData
                    })
                }
            }
        } else {
            // Note
            // Check state of note: new or existing
            if (typeof noteData.id === 'undefined') {
                // If new note
                const refId = db.collection("notes").doc(forestId).collection("content").doc();
                refId.set({
                    date: noteData.date,
                    id: refId.id,
                    images: imageUrls,
                    latlng: noteData.latlng,
                    text: noteData.text,
                    title: noteData.title,
                    tag: noteData.tag,
                    todoStatus: noteData.todoStatus,
                    todoTeam: noteData.todoTeam,
                    tasks: noteData.tasks,
                    responsible: noteData.responsible,
                    taskTypes: noteData.taskTypes,
                    taskDeadline: noteData.taskDeadline,
                    private: noteData.private,
                    from: noteData.from,
                    forestId: noteData.forestId,
                    userUID: user.uid,
                    creationDate: moment().format(),
                    priority: noteData.priority,
                    kwSync: false
                })
            } else {
                // Existing note
                const refId = db.collection("notes").doc(forestId).collection("content").doc(noteData.id);
                refId.update({
                    date: noteData.date,
                    id: refId.id,
                    images: imageUrls,
                    latlng: noteData.latlng,
                    text: noteData.text,
                    title: noteData.title,
                    tag: noteData.tag,
                    todoStatus: noteData.todoStatus,
                    todoTeam: noteData.todoTeam,
                    tasks: noteData.tasks,
                    responsible: noteData.responsible,
                    taskTypes: noteData.taskTypes,
                    taskDeadline: noteData.taskDeadline,
                    private: noteData.private,
                    from: noteData.from,
                    forestId: noteData.forestId,
                    userUID: user.uid,
                    priority: noteData.priority,
                    kwSync: false
                });
            }
        }
        if (parentModule !== 'kanban') {
            dispatch(saveNoteSuccess());
        }
    } catch (error) {
        console.error('Error :', error);
        dispatch(saveNoteFail(error))
    }
};

export const deleteNoteSuccess = (id) => {
    return {
        type: actionTypes.DELETE_NOTE_SUCCESS,
        noteId: id,
    }
}

export const deleteNoteFail = (error) => {
    return {
        type: actionTypes.DELETE_NOTE_FAIL,
        error: error
    };
};

export const deleteNoteStart = () => {
    return {
        type: actionTypes.DELETE_NOTE_START
    };
};

export const deleteNote = (noteData, envType, parentModule) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    const revId = 0; // TODO : solve when revision control is implemented
    if (parentModule !== 'kanban') {
        dispatch(deleteNoteStart());
    }
    // check for auth user
    try {
        if (user !== null) {
            // Check for point
            if (noteData.pointType) {
                if (envType === 'biotopePlan') {
                    // Delete point
                    const refId = db.collection("biotopePlans").doc(noteData.forestId).collection('content').doc("rev: " + revId).collection("points").doc(noteData.id);
                    refId.delete();
                } else {
                    // Check for point type
                    let colType = 'content';
                    if (envType === 'hunting') {
                        colType = 'hunting';
                    }
                    // Delete point
                    const refId = db.collection("forests").doc(noteData.forestId).collection(colType).doc("rev: " + revId).collection("points").doc(noteData.id);
                    refId.delete();
                }

                // Offline 
                if (parentModule !== 'kanban') {
                    dispatch(deleteNoteSuccess());
                }
            } else {
                const refId = db.collection("notes").doc(noteData.forestId).collection("content").doc(noteData.id);
                refId.delete()
                    .then(e => {
                        // TODO : Images are still stored on storage
                        // Online
                        // dispatch(deleteNoteSuccess());
                    })
                    .catch(error => {
                        console.error("Error removing document: ", error);
                        // Dispatch error 
                        dispatch(deleteNoteFail(error))
                    });
                // Offline
                if (parentModule !== 'kanban') {
                    dispatch(deleteNoteSuccess());
                }
            }
        }
    } catch (error) {
        dispatch(deleteNoteFail(error))
    }
}

export const setPointEditable = (state, pointEditableId) => {
    return {
        type: actionTypes.SET_POINT_EDITABLE,
        pointEditable: state,
        pointEditableId: pointEditableId,
    };
};

export const setPointEditableLatLng = (pointEditableLatLng) => {
    return {
        type: actionTypes.SET_POINT_EDITABLE_LATLNG,
        pointEditableLatLng: pointEditableLatLng,
    };
};

export const deleteNotesForest = (forestId) => {
    return {
        type: actionTypes.DELETE_NOTES_FOREST,
        customerId: forestId
    };
};

export const setInsertNotesState = (state) => {
    return {
        type: actionTypes.SET_INSERT_NOTES,
        insertNotesState: state
    };
};

export const setInsertPointsState = (state) => {
    return {
        type: actionTypes.SET_INSERT_POINTS,
        insertPointsState: state
    };
};

export const setNoteEditable = (noteEditable, id) => {
    return {
        type: actionTypes.SET_NOTE_EDITABLE,
        noteEditable: noteEditable,
        noteEditableId: id
    };
};

export const setNoteEditableLatLng = (latlng) => {
    return {
        type: actionTypes.SET_NOTE_EDITABLE_LATLNG,
        noteEditableLatLng: latlng,
    };
};

export const setNoteToolTipActive = (noteToolTipState) => {
    return {
        type: actionTypes.SET_NOTE_TOOL_TIP_ACTIVE,
        noteToolTipActive: noteToolTipState
    };
};

export const setFilterTag = (filterTag) => {
    return {
        type: actionTypes.SET_FILTER_TAG,
        filterTag: filterTag
    };
};

export const setFilterTodoMyTasks = (filterTodoMyTasks) => {
    return {
        type: actionTypes.SET_FILTER_TODO_MY_TASKS,
        filterTodoMyTasks: filterTodoMyTasks
    };
};

export const setFilterTodoDistanceToMe = (filterTodoDistanceToMe) => {
    return {
        type: actionTypes.SET_FILTER_TODO_DISTANCE_TO_ME,
        filterTodoDistanceToMe: filterTodoDistanceToMe
    };
};


export const setFilterTodoStatus = (filterTodoStatus) => {
    return {
        type: actionTypes.SET_FILTER_TODO_STATUS,
        filterTodoStatus: filterTodoStatus
    };
};

export const unmountNote = () => {
    return dispatch => {
        dispatch(unmountNoteAction());
    }
}

export const setFlyToNoteData = (noteData) => {
    return {
        type: actionTypes.SET_FLY_TO_NOTE_DATA,
        flyToNoteData: noteData
    }
}

