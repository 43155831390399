import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import reepayConfig from '../../backend/configReepay';

const initialState = {
    reepayState: null,
    subscription: null,
    inTrial: false,
    trialSub: '',
    natureSub: '',
    operationSub: '',
    operationPlusSub: '',
    huntingSub: '',
    huntingPlusSub: '',
    ownerUpToFiveForestsSub: '',
    unlimitedUserAccessSub: '',
    plan: null,
    loading: false,
    error: null,
    planList: null,
    discountCodesList: null,
    fbDiscountCodesList: null,
    paymentDetails: null,
    subscriptionActive: true,
    natureActive: false,
    operationActive: false,
    operationPlusActive: false,
    huntingActive: false,
    huntingPlusActive: false,
    ownerUpToFiveForestsActive: false,
    unlimitedUserAccessActive: false,
    taskPlanningActive: false,
    kwIntegrationActive: false,
    inTrialOperationActive: false,
    inTrialHuntingActive: false,
    newNotifications: 0,
};

const setInTrialOperationActive = (state, action) => {
    return updateObject(state, { inTrialOperationActive: action.inTrialOperationActive });
};

const setInTrialHuntingActive = (state, action) => {
    return updateObject(state, { inTrialHuntingActive: action.inTrialHuntingActive });
};

const setTaskPlanningActive = (state, action) => {
    return updateObject(state, { taskPlanningActive: action.taskPlanningActive });
};

const setKwIntegrationActive = (state, action) => {
    return updateObject(state, { kwIntegrationActive: action.kwIntegrationActive });
};

const setSubscriptionState = (state, action) => {
    switch (action.sub) {
        case 'nature': return updateObject(state, { natureActive: action.state });
        case 'operation': return updateObject(state, { operationActive: action.state });
        case 'operationPlus': return updateObject(state, { operationPlusActive: action.state });
        case 'hunting': return updateObject(state, { huntingActive: action.state });
        case 'huntingPlus': return updateObject(state, { huntingPlusActive: action.state });
        case 'ownerUpToFiveForests': return updateObject(state, { ownerUpToFiveForestsActive: action.state });
        case 'unlimitedUserAccess': return updateObject(state, { unlimitedUserAccessActive: action.state });
        default: return updateObject(state, state);
    }
};

const fetchReepayStateStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchReepayStateSuccess = (state, action) => {
    return updateObject(state, {
        reepayState: action.reepayState,
        subscriptionActive: action.subscriptionActive,
        subscription: action.subscription,
        trialSub: action.trialSub,
        inTrial: action.inTrial,
        natureSub: action.natureSub,
        operationSub: action.operationSub,
        operationPlusSub: action.operationPlusSub,
        huntingSub: action.huntingSub,
        huntingPlusSub: action.huntingPlusSub,
        ownerUpToFiveForestsSub: action.ownerUpToFiveForestsSub,
        unlimitedUserAccessSub: action.unlimitedUserAccessSub,
        natureActive: action.natureActive,
        operationActive: action.operationActive,
        operationPlusActive: action.operationPlusActive,
        huntingActive: action.huntingActive,
        huntingPlusActive: action.huntingPlusActive,
        ownerUpToFiveForestsActive: action.ownerUpToFiveForestsActive,
        unlimitedUserAccessActive: action.unlimitedUserAccessActive,
        loading: false,
    });
};

const fetchReepayStateFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        subscriptionActive: false,
        reepayState: "not fetched"
    });
};

const fetchReepayPlanStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchReepayPlanSuccess = (state, action) => {
    return updateObject(state, {
        plan: action.plan,
        loading: false
    });
};

const fetchReepayPlanFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchReepayPlanListStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchReepayPlanListSuccess = (state, action) => {
    return updateObject(state, {
        planList: action.planList,
        loading: false
    });
};

const fetchReepayPlanListFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const attachReepayStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const attachReepayFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const attachReepaySuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        trialSub: action.trialSub,
        inTrial: action.trialSub !== '' ? true : false,
    });
};

const setSubscriptionOnHoldStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const setSubscriptionOnHoldSuccess = (state, action) => {
    return updateObject(state, {
        loading: false
    });
};

const setSubscriptionOnHoldFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const reactiveSubscriptionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const reactiveSubscriptionSuccess = (state, action) => {
    return updateObject(state, {
        loading: false
    });
};

const reactiveSubscriptionFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const cancelSubscriptionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const cancelSubscriptionSuccess = (state, action) => {
    if (action.subObject.plan === reepayConfig.naturePlanYear || action.subObject.plan === reepayConfig.naturePlanMonth) {
        return updateObject(state, {
            loading: false,
            natureSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.operationPlanYear || action.subObject.plan === reepayConfig.operationPlanMonth) {
        return updateObject(state, {
            loading: false,
            operationSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.operationPlusPlanYear || action.subObject.plan === reepayConfig.operationPlusPlanMonth) {
        return updateObject(state, {
            loading: false,
            operationPlusSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.huntingPlanYear || action.subObject.plan === reepayConfig.huntingPlanMonth) {
        return updateObject(state, {
            loading: false,
            huntingSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.huntingPlusPlanYear || action.subObject.plan === reepayConfig.huntingPlusPlanMonth) {
        return updateObject(state, {
            loading: false,
            huntingPlusSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.ownerUpToFiveForestsPlanYear || action.subObject.plan === reepayConfig.ownerUpToFiveForestsPlanMonth) {
        return updateObject(state, {
            loading: false,
            ownerUpToFiveForestsSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.unlimitedUserAccessPlanYear || action.subObject.plan === reepayConfig.unlimitedUserAccessPlanMonth) {
        return updateObject(state, {
            loading: false,
            unlimitedUserAccessSub: action.subObject,
        });
    } else {
        console.error("Error in canceling subscription")
        return updateObject(state, {
            loading: false,
        });
    }
};

const cancelSubscriptionFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const uncancelSubscriptionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const uncancelSubscriptionSuccess = (state, action) => {
    if (action.subObject.plan === reepayConfig.naturePlanYear || action.subObject.plan === reepayConfig.naturePlanMonth) {
        return updateObject(state, {
            loading: false,
            natureSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.operationPlanYear || action.subObject.plan === reepayConfig.operationPlanMonth) {
        return updateObject(state, {
            loading: false,
            operationSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.operationPlusPlanYear || action.subObject.plan === reepayConfig.operationPlusPlanMonth) {
        return updateObject(state, {
            loading: false,
            operationPlusSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.huntingPlanYear || action.subObject.plan === reepayConfig.huntingPlanMonth) {
        return updateObject(state, {
            loading: false,
            huntingSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.huntingPlusPlanYear || action.subObject.plan === reepayConfig.huntingPlusPlanMonth) {
        return updateObject(state, {
            loading: false,
            huntingPlusSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.ownerUpToFiveForestsPlanYear || action.subObject.plan === reepayConfig.ownerUpToFiveForestsPlanMonth) {
        return updateObject(state, {
            loading: false,
            ownerUpToFiveForestsSub: action.subObject,
        });
    } else if (action.subObject.plan === reepayConfig.unlimitedUserAccessPlanYear || action.subObject.plan === reepayConfig.unlimitedUserAccessPlanMonth) {
        return updateObject(state, {
            loading: false,
            unlimitedUserAccessSub: action.subObject,
        });
    } else {
        console.error("Error in canceling subscription")
        return updateObject(state, {
            loading: false,
        });
    }
};

const uncancelSubscriptionFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const createSubscriptionStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const createSubscriptionSuccess = (state, action) => {
    if (action.packageType === 'nature') {
        return updateObject(state, {
            loading: false,
            natureSub: action.subObject,
            natureActive: action.subObject.state === 'active' ? true : false,
        });
    } else if (action.packageType === 'operation') {
        return updateObject(state, {
            loading: false,
            operationSub: action.subObject,
            operationActive: action.subObject.state === 'active' ? true : false,
        });
    } else if (action.packageType === 'operationPlus') {
        return updateObject(state, {
            loading: false,
            operationPlusSub: action.subObject,
            operationPlusActive: action.subObject.state === 'active' ? true : false,
        });
    } else if (action.packageType === 'hunting') {
        return updateObject(state, {
            loading: false,
            huntingSub: action.subObject,
            huntingActive: action.subObject.state === 'active' ? true : false,
        });
    } else if (action.packageType === 'huntingPlus') {
        return updateObject(state, {
            loading: false,
            huntingPlusSub: action.subObject,
            huntingPlusActive: action.subObject.state === 'active' ? true : false,
        });
    } else if (action.packageType === 'ownerUpToFiveForests') {
        return updateObject(state, {
            loading: false,
            ownerUpToFiveForestsSub: action.subObject,
            ownerUpToFiveForestsActive: action.subObject.state === 'active' ? true : false,
        });
    } else if (action.packageType === 'unlimitedUserAccess') {
        return updateObject(state, {
            loading: false,
            unlimitedUserAccessSub: action.subObject,
            unlimitedUserAccessActive: action.subObject.state === 'active' ? true : false,
        });
    } else {
        console.error("Wrong package type!")
        return updateObject(state, {
            loading: false,
        });
    }
};

const createSubscriptionFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const setNewNotifications = (state, action) => {
    return updateObject(state, { newNotifications: action.newNotifications });
};

const fetchDiscountCodesListFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchDiscountCodesListStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchDiscountCodesListSuccess = (state, action) => {
    return updateObject(state, {
        discountCodesList: action.codesList,
        loading: false
    });
};

const fetchFbDiscountCodesListFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchFbDiscountCodesListStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchFbDiscountCodesListSuccess = (state, action) => {
    return updateObject(state, {
        fbDiscountCodesList: action.codesList,
        loading: false
    });
};

const fetchPaymentDetailsFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchPaymentDetailsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchPaymentDetailsSuccess = (state, action) => {
    return updateObject(state, {
        paymentDetails: action.paymentDetails,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_REEPAY_STATE_START: return fetchReepayStateStart(state, action);
        case actionTypes.FETCH_REEPAY_STATE_SUCCESS: return fetchReepayStateSuccess(state, action);
        case actionTypes.FETCH_REEPAY_STATE_FAIL: return fetchReepayStateFail(state, action);

        case actionTypes.FETCH_REEPAY_PLAN_START: return fetchReepayPlanStart(state, action);
        case actionTypes.FETCH_REEPAY_PLAN_SUCCESS: return fetchReepayPlanSuccess(state, action);
        case actionTypes.FETCH_REEPAY_PLAN_FAIL: return fetchReepayPlanFail(state, action);

        case actionTypes.FETCH_REEPAY_PLAN_LIST_START: return fetchReepayPlanListStart(state, action);
        case actionTypes.FETCH_REEPAY_PLAN_LIST_SUCCESS: return fetchReepayPlanListSuccess(state, action);
        case actionTypes.FETCH_REEPAY_PLAN_LIST_FAIL: return fetchReepayPlanListFail(state, action);

        case actionTypes.ATTACH_REEPAY_START: return attachReepayStart(state, action);
        case actionTypes.ATTACH_REEPAY_FAIL: return attachReepayFail(state, action);
        case actionTypes.ATTACH_REEPAY_SUCCESS: return attachReepaySuccess(state, action);

        case actionTypes.SET_SUBSCRIPTION_ON_HOLD_START: return setSubscriptionOnHoldStart(state, action);
        case actionTypes.SET_SUBSCRIPTION_ON_HOLD_SUCCESS: return setSubscriptionOnHoldSuccess(state, action);
        case actionTypes.SET_SUBSCRIPTION_ON_HOLD_FAIL: return setSubscriptionOnHoldFail(state, action);

        case actionTypes.REACTIVATE_SUBSCRIPTION_START: return reactiveSubscriptionStart(state, action);
        case actionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS: return reactiveSubscriptionSuccess(state, action);
        case actionTypes.REACTIVATE_SUBSCRIPTION_FAIL: return reactiveSubscriptionFail(state, action);

        case actionTypes.CANCEL_SUBSCRIPTION_START: return cancelSubscriptionStart(state, action);
        case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS: return cancelSubscriptionSuccess(state, action);
        case actionTypes.CANCEL_SUBSCRIPTION_FAIL: return cancelSubscriptionFail(state, action);

        case actionTypes.UNCANCEL_SUBSCRIPTION_START: return uncancelSubscriptionStart(state, action);
        case actionTypes.UNCANCEL_SUBSCRIPTION_SUCCESS: return uncancelSubscriptionSuccess(state, action);
        case actionTypes.UNCANCEL_SUBSCRIPTION_FAIL: return uncancelSubscriptionFail(state, action);

        case actionTypes.CREATE_SUBSCRIPTION_START: return createSubscriptionStart(state, action);
        case actionTypes.CREATE_SUBSCRIPTION_SUCCESS: return createSubscriptionSuccess(state, action);
        case actionTypes.CREATE_SUBSCRIPTION_FAIL: return createSubscriptionFail(state, action);

        case actionTypes.SET_NEW_NOTIFICATIONS: return setNewNotifications(state, action);

        case actionTypes.FETCH_DISCOUNT_CODES_LIST_START: return fetchDiscountCodesListStart(state, action);
        case actionTypes.FETCH_DISCOUNT_CODES_LIST_SUCCESS: return fetchDiscountCodesListSuccess(state, action);
        case actionTypes.FETCH_DISCOUNT_CODES_LIST_FAIL: return fetchDiscountCodesListFail(state, action);

        case actionTypes.FETCH_FB_DISCOUNT_CODES_LIST_START: return fetchFbDiscountCodesListStart(state, action);
        case actionTypes.FETCH_FB_DISCOUNT_CODES_LIST_SUCCESS: return fetchFbDiscountCodesListSuccess(state, action);
        case actionTypes.FETCH_FB_DISCOUNT_CODES_LIST_FAIL: return fetchFbDiscountCodesListFail(state, action);

        case actionTypes.FETCH_PAYMENT_DETAILS_START: return fetchPaymentDetailsStart(state, action);
        case actionTypes.FETCH_PAYMENT_DETAILS_SUCCESS: return fetchPaymentDetailsSuccess(state, action);
        case actionTypes.FETCH_PAYMENT_DETAILS_FAIL: return fetchPaymentDetailsFail(state, action);

        case actionTypes.SET_IN_TRIAL_OPERATION_ACTIVE: return setInTrialOperationActive(state, action);
        case actionTypes.SET_IN_TRIAL_HUNTING_ACTIVE: return setInTrialHuntingActive(state, action);

        case actionTypes.SET_TASK_PLANNING_ACTIVE: return setTaskPlanningActive(state, action);
        case actionTypes.SET_KW_INTEGRATION_ACTIVE: return setKwIntegrationActive(state, action);

        case actionTypes.SET_SUBSCRIPTION_STATE: return setSubscriptionState(state, action);

        default: return state;
    }
};

export default reducer;