import env from './buildConfig';

// ... Dev ...
let configOpenWeather = {
    apiKey: "b49d79e760d5912c6d30861fd0cdb2e1"
};

// Same as dev
if (env.stage === "production") {
}

export default configOpenWeather;