var L = window.L;
L.LabelsMarker = L.Marker.extend({
  initialize: function (latlng, options) {

    let self = this;

    L.Marker.prototype.initialize.call(this, latlng, options);

    this.zoomContent = options.zoomContent;

    this.updateCallback = (function (e) {
      self._updateLabelContent(this);
    });
  },

  onAdd: function (map) {
    L.Marker.prototype.onAdd.call(this, map);
    map.on('zoomend', this.updateCallback);
    this._updateLabelContent(map);
  },

  onRemove: function (map) {
    L.Marker.prototype.onRemove.call(this, map);
    map.off('zoomend', this.updateCallback);
  },

  // Update marker label content based on zoom level
  _updateLabelContent: function (map) {
    let content = this._createLabelIcon('default',"");
    for (let el of this.zoomContent) {
      if (map._zoom > el.level) {
        content = this._createLabelIcon(el.type, el.text, el.fontSize);
      }
    }
    this.setIcon(content)
  },

  _createLabelIcon: function (type, text, fontSize) {
    let fs = 10.5;
    if (fontSize) {
      fs = fontSize;
    }
    switch (type) {
      case 'default': return L.divIcon({
        className: {},
        html: `<p style=color:#252525;font-size:${fs}px>` + text + "</p>",
        iconAnchor: [6, 18],
      });
      case 'point': return L.divIcon({
        className: {},
        html: `<p style=color:#252525;font-size:${fs}px>` + text + "</p>",
        iconAnchor: [10, -22],
      });
      default: return L.divIcon({
        className: {},
        html: `<p style=color:#252525;font-size:${fs}px>` + text + "</p>",
        iconAnchor: [6, 18],
      });
    }
  }

});

L.labelsMarker = function (latlng, options) {
  return new L.LabelsMarker(latlng, options || { type: 'default', zoomContent: [{ level: 0, text: '', fontSize: 10.5 }] });
}