import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
const merge = require('lodash.merge');
// const merge = require('deepmerge')
const configFree = require('../../config/configEjerGratis.json');

const initialState = {
    loading: false,
    error: null,
    setAppConfig: true,
    ...configFree
};

const setFullAppConfigState = (state, action) => {
    return updateObject(state, action.state);
};

const deepMergeAppConfigState = (state, action) => {
    return merge(state, action.newState);
}

const setAppConfigState = (state, action) => {
    return updateObject(state, { [action.key]: action.value });
};

const fetchAppConfigStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const fetchAppConfigFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
}

const fetchAppConfigSuccess = (state, action) => {
    return updateObject(state, { ...action.state, loading: false });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FULL_APP_CONFIG_STATE: return setFullAppConfigState(state, action);
        case actionTypes.DEEP_MERGE_APP_CONFIG_STATE: return deepMergeAppConfigState(state,action);
        case actionTypes.SET_APP_CONFIG_STATE: return setAppConfigState(state, action);
        case actionTypes.FETCH_APP_CONFIG_START: return fetchAppConfigStart(state, action);
        case actionTypes.FETCH_APP_CONFIG_FAIL: return fetchAppConfigFail(state, action);
        case actionTypes.FETCH_APP_CONFIG_SUCCESS: return fetchAppConfigSuccess(state, action);
        default: return state;
    }
};

export default reducer;