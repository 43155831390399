import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';

// Material UI icons
import PersonIcon from '@material-ui/icons/PersonRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';

// Components
import CircularSpinner from '../../CircularSpinner/CircularSpinner';

const cookies = new Cookies();

const styles = theme => ({
    cardContent: {
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        marginBottom: 0,
        position: 'relative',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '400px',
        overflow: 'auto',
    },
    indicator: {
        backgroundColor: '#41842b'
    },
    button: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    icon: {
        color: "white",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
});

class PeopleWidget extends Component {
    constructor(props) {
        super(props);
        const peopleWidgetState = cookies.get('peopleWidgetState');
        this.state = {
            secondary: true,
            dense: true,
            rerender: false,
            open: peopleWidgetState === 'close' ? false : true,
        };
    }

    componentDidUpdate() {
    }

    onButtonPress = () => {
        // Navigate to new customer page
        this.props.history.push({
            pathname: '/People/NewPerson',
            state: {
                prevPage: "newPerson",
            }
        });
    };

    createPeopleListContent = (props, divider, disable) => {
        const { classes } = this.props;
        // Set primary text
        let primText = props.name;

        const prim =
            <Typography
                variant="subtitle1"
                noWrap
            >
                {primText}
            </Typography>

        const sec = props.orgAccess && props.orgAccess !== 'granted' ? (<Fragment>
            <Typography
                variant="caption"
                color="textSecondary"
            >
                (Afventer adgang til virksomheden)
            </Typography >
        </Fragment>) : (props.userTags && props.userTags.length > 0 ?
            <Fragment>
                <Typography
                    variant="subtitle1"
                    noWrap
                    style={{ color: '#000000', paddingBottom: - 2 }}
                    component={'span'}
                >
                    {props.userTags.join(', ')}
                </Typography>
            </Fragment> : <Fragment>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    (Tilhører endnu ikke en gruppe)
                </Typography >
            </Fragment>)

        return (
            <ListItem
                button
                divider={divider}
                component={Link}
                disabled={disable}
                to={{
                    pathname: `/People/${props.id}`,
                    state: {
                        ...props,
                        prevPage: "peopleList",
                    }
                }}
                style={{ paddingLeft: 6 }}
            >
                <ListItemAvatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: props.id === this.props.currentAuthUser.id ? '#41842b' : null }}>
                        <PersonIcon className={classes.icon}/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={prim}
                    secondary={sec}
                />
            </ListItem>
        );
    }

    displayPeopleList = (peopleList, disable) => {
        // Check how many to show
        let N = peopleList.length;
        // Sort customer list by alphabetic order
        let sortedPeopleList = peopleList.sort((a, b) => a.name.localeCompare(b.name, 'da-DK'))

        const list = sortedPeopleList.map((person, i) => {
            let divider = i < N - 1;
            if (person && person.id) {
                return (
                    <div key={i}>
                        {this.createPeopleListContent(person, divider, disable)}
                    </div>
                )
            } else {
                return null;
            }

        });
        return list;
    }

    peopleList() {
        const { classes } = this.props;
        if (this.props.orgUsers === null) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
                        <CircularSpinner />
                    </div>
                </ExpansionPanelDetails>
            );
        }

        const list = Object.values(this.props.orgUsers).map(u => u);
        if (list.length === 0) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 53, display: 'flex', alignItems: 'center' }}>
                        <DialogContentText
                            style={{ marginTop: 8 }}
                        >
                            Der er ingen ansatte tilknyttet din konto
                        </DialogContentText>
                    </div>
                </ExpansionPanelDetails>
            )
        }

        // Check for user tags to indicate if the user type can change the profile of people in the org
        let disablePeople = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))) {
            disablePeople = true;
        }
        if (this.props.currentAuthUser.orgAccess && this.props.currentAuthUser.orgAccess !== 'granted') {
            disablePeople = true;
        }

        return (
            <div>
                <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />

                <ExpansionPanelDetails className={classes.cardContent}>
                    <List dense={this.state.dense} style={{ padding: 0 }}>
                        {this.displayPeopleList(list, disablePeople)}
                    </List>
                </ExpansionPanelDetails>

                <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />

                {/* {(list.length > 5) && */}
                <Typography
                    variant="body2"
                    align="center"
                    style={{ color: '#6A6A6A', marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 16 }}
                >
                    Listen er scrollbar <InfoIcon style={{ marginBottom: -8, color: '#9A9A9A' }} />
                </Typography>
                {/* } */}

                {/* {(list.length <= 3) && <div style={{ height: 16 }} />} */}
            </div>
        )
    }

    expandAction() {
        this.setState({ open: this.state.open ? false : true });

        cookies.set('peopleWidgetState', this.state.open ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'PeopleWidgetState',
            action: `PeopleWidgetState: ${this.state.open ? 'close' : 'open'}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    render() {
        // Check for user tags to indicate if the user type can change the profile of people in the org
        let disablePeople = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))) {
            disablePeople = true;
        }
        if (this.props.currentAuthUser.orgAccess && this.props.currentAuthUser.orgAccess !== 'granted') {
            disablePeople = true;
        }

        return (
            <div>
                <ExpansionPanel
                    expanded={this.state.open}
                    onChange={() => this.expandAction()}
                >
                    <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="h5"
                        >
                            Ansatte
                        </Typography >
                    </ExpansionPanelSummary>

                    {this.peopleList()}

                    {disablePeople && <div style={{ margin: '0px 16px 8px 16px' }}>
                        <Typography variant="caption" color="textSecondary">
                            Din konto har ikke adgang, til at ændre listen af ansatte.
                        </Typography >
                    </div>}
                </ExpansionPanel>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        networkStatus: state.layout.networkStatus,
        orgId: state.org.orgId,
        orgUsers: state.org.orgUsers,
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        operationPlusActive: state.account.operationPlusActive,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

PeopleWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PeopleWidget)));