import React from 'react';

// Material UI
import { Typography } from '@material-ui/core';
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function StatLine(props) {
    return (
        <div>
            <div onClick={props.onExpandStat} style={{ justifyContent: 'space-between', display: 'flex', cursor: props.onExpandStat ? 'pointer' : 'default' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {props.icon}

                    <Typography style={{ marginLeft: props.icon ? 10 : 0, fontWeight: props.bold ? 500 : null, fontStyle: props.italic ? 'italic' : 'normal' }}>
                        {props.title}
                    </Typography>

                    {props.expansionContent && <IconChevronRight style={{ transform: props.open ? 'rotate(-90deg)' : null, transition: 'transform 0.2s' }} />}
                </div>

                <div>
                    <Typography noWrap style={{ fontWeight: 500 }}>
                        {props.stat} {props.statAdornment}
                    </Typography>
                </div>
            </div>

            {(props.progress && props.progress !== '-' && props.progress !== '') && <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                <LinearProgress variant="determinate" value={props.progress > 100 ? 100 : props.progress} style={{ flexGrow: 1, marginRight: 10 }} />

                <Typography variant="body2" color="textSecondary">
                    {props.progress} %
                </Typography>
            </div>}

            <div style={{ height: props.open ? null : 0, visibility: props.open ? 'visible' : 'hidden' }}>
                {props.expansionContent}
            </div>
        </div>
    );
}