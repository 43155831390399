import registrations from './registrations.json';
import { getCategorySubCategories } from './categoriesUtil';


export const getRegistrationsList = (category, geometryType) => {
    // Get input category sub categories
    const subCategories = getCategorySubCategories(category);
    if (subCategories.length === 0) {
        return false;
    }
    let list = [];
    Object.entries(registrations).forEach( el => {
        // Check for geometry type
        const geoCheck = el[1].geometries && el[1].geometries.includes(geometryType);
        // Check for sub categories
        const subCatCheck = el[1].subCategories && el[1].subCategories.some( r => subCategories.includes(r));
        if (geoCheck && subCatCheck) {
            list.push(el);
        }
    })
    return list.sort((a, b) => a[0].localeCompare(b[0], 'da-DK'));;  
}