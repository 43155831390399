import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CardContent from '@material-ui/core/CardContent';

// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';

// Components
import ButtonWithSpinner from '../UI/Buttons/ButtonWithSpinner';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'top',
        justifyContent: 'space-between',
    },
}));

const Terms = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            // onClose={props.handleTermsClose}
            aria-labelledby="simple-dialog-title"
            open={props.openTerms}
        >
            <CardContent style={{ paddingBottom: props.showAcceptButton ? 0 : 16 }}>
                <div className={classes.modal}>
                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        Brugervilkår og betingelser
                    </Typography >

                    {/* {!props.showAcceptButton && <IconButton */}
                    {props.showCloseButton && <IconButton
                        aria-label="Close"
                        size="medium"
                        style={{ marginBottom: 8, marginTop: -8 }}
                        onClick={props.handleTermsClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>}
                </div>

                <Typography
                    variant={"caption"}
                    gutterBottom
                    style={{ whiteSpace: 'pre-line' }}
                >
                    {props.terms && props.terms.replace(/\\n/g, "\n")}
                </Typography>

                {props.showAcceptButton && <div style={{ position: 'sticky', bottom: 0, width: '100%', justifyContent: 'center', padding: 16 }}>
                    <ButtonWithSpinner
                        onClick={props.handleAcceptTerms}
                        text='Godkend'
                        loading={props.loading}
                        color='primary'
                        variant='contained'
                        icon={<CheckIcon style={{ marginRight: 6 }} />}
                    />
                </div>}
            </CardContent>
        </Dialog>
    );
}

export default Terms;