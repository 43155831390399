import firebase from 'firebase';
import "firebase/remote-config";
import * as actionTypes from './actionTypes';

export const setAppFullConfigState = (state) => {
    return {
        type: actionTypes.SET_FULL_APP_CONFIG_STATE,
        state: state
    };
}

export const deepMergeAppConfigState = (newState) => {
    return {
        type: actionTypes.DEEP_MERGE_APP_CONFIG_STATE,
        newState: newState
    }
}

export const setAppConfigState = (key, value) => {
    return {
        type: actionTypes.SET_APP_CONFIG_STATE,
        key: key,
        value: value,
    };
}

const fetchAppConfigStart = () => {
    return {
        type: actionTypes.FETCH_APP_CONFIG_START,
    }
}

const fetchAppConfigFail = (error) => {
    return {
        type: actionTypes.FETCH_APP_CONFIG_FAIL,
        error: error
    }
}

const fetchAppConfigSuccess = (state) => {
    return {
        type: actionTypes.FETCH_APP_CONFIG_SUCCESS,
        state: state
    }
}

export const fetchAppConfig = (configFile) => async (dispatch) => {
    dispatch(fetchAppConfigStart());
    const remoteConfig = firebase.remoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = 15000;
    remoteConfig.fetchAndActivate().then(() => {
        const val = remoteConfig.getValue(configFile);
        const profile = JSON.parse(val._value);
        dispatch(fetchAppConfigSuccess(profile));
    }).catch(error => {
        dispatch(fetchAppConfigFail(error));
    });

}