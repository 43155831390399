import { getFullSpecieParameterList } from './speciesUtil';

// Utility functions for biotope plan

export const getActionUnitsAndPoints = (action, type, amount, cultivatedArea, planType) => {
    // Init parameters
    let units = 0;
    let points = 0;
    let maxReached = false;
    // Input checking
    // Get action parameters
    const par = getFullSpecieParameterList(action);
    // Calculate based on type
    const typeCheck = type.toLowerCase();
    if (typeCheck === 'point') {
        units = amount;
        if ( action === "24: Redekassetype 1 - museædere" || action === "24: Redekassetype 2 - eng- & agerfugle") {
            const p = units * par.pointsPrUnit100ha;
            if (planType !== 5) {
                points = p > par.maxPoints ? par.maxPoints : p;
            } else {
                points = p;
            }
        } else {
            if (action === "11: Lærkepletter") {
                units = units/2;
            }
            const p = units * par.pointsPrUnit100ha * (100/cultivatedArea);
            if (planType !== 5) {
                points = p > par.maxPoints ? par.maxPoints : p;
            } else {
                points = p;
            }
        }
    } else if (typeCheck === 'line') {
        // Calculate units
        units = amount/par.unit;
        // Calculate points
        const p = units * par.pointsPrUnit100ha * (100/cultivatedArea);
        if (planType !== 5) {
            points = p > par.maxPoints ? par.maxPoints : p;
        } else {
            points = p;
        }
    } else if (typeCheck === 'polygon') {
        // Get polygon units
        units = getPolygonUnits(par.unitExplanation, par.unit, amount);
        // Calculate points
        const p = units * par.pointsPrUnit100ha * (100/cultivatedArea);
        if (planType !== 5) {
            points = p > par.maxPoints ? par.maxPoints : p;
        } else {
            points = p;
        }
    }
    if (planType !== 5) {
        maxReached = points >= par.maxPoints ? true : false;
    }
    return {units, points, maxReached};
}

const getPolygonUnits = ( unitType, unit, area ) => {
    let points = 0;
    if (unitType === "2/ha = 1 enhed") {
        points = 0;
    } else if (unitType === "Pr. lavning") {
        points = 1;
    } else if (unitType === "Pr. sø") {
        points = 1;
    } else {
        points = area/unit;
    }

    return points;
}