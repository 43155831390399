// App Configuratiom
export const SET_FULL_APP_CONFIG_STATE = 'SET_FULL_APP_CONFIG_STATE';
export const DEEP_MERGE_APP_CONFIG_STATE = 'DEEP_MERGE_APP_CONFIG_STATE';
export const SET_APP_CONFIG_STATE = 'SET_APP_CONFIG_STATE';
export const FETCH_APP_CONFIG_START = 'FETCH_APP_CONFIG_START';
export const FETCH_APP_CONFIG_FAIL = 'FETCH_APP_CONFIG_FAIL';
export const FETCH_APP_CONFIG_SUCCESS = 'FETCH_APP_CONFIG_SUCCESS';

// User actions
export const AUTH_USER = 'AUTH_USER';
export const CURRENT_USER = 'CURRENT_USER';
export const NO_AUTH_USER = 'NO_AUTH_USER';
export const SIGN_IN_STARTED = 'SIGN_IN_STARTED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SET_UPDATE_USER_PROFILE = 'SET_UPDATE_USER_PROFILE';
//export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const UNMOUNT_SAVE_USER = 'UNMOUNT_SAVE_USER';
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS';
export const UPDATE_USER_DATA_START = 'UPDATE_USER_DATA_START';
export const UPDATE_USER_DATA_FAIL = 'UPDATE_USER_DATA_FAIL';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const SET_SIGNUP_REASON_START = 'SET_SIGNUP_REASON_START';
export const SET_SIGNUP_REASON_FAIL = 'SET_SIGNUP_REASON_FAIL';
export const SET_SIGNUP_REASON_SUCCESS = 'SET_SIGNUP_REASON_SUCCESS';
export const SET_ACCEPT_TERMS_STATE_START = 'SET_ACCEPT_TERMS_STATE_START';
export const SET_ACCEPT_TERMS_STATE_FAIL = 'SET_ACCEPT_TERMS_STATE_FAIL';
export const SET_ACCEPT_TERMS_STATE_SUCCESS = 'SET_ACCEPT_TERMS_STATE_SUCCESS';
export const CREATE_NEW_USER_START = 'CREATE_NEW_USER_START';
export const CREATE_NEW_USER_FAIL = 'CREATE_NEW_USER_FAIL';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const LINK_USER_AND_ORG_START = 'LINK_USER_AND_ORG_START';
export const LINK_USER_AND_ORG_FAIL = 'LINK_USER_AND_ORG_FAIL';
export const LINK_USER_AND_ORG_SUCCESS = 'LINK_USER_AND_ORG_SUCCESS';
export const SEND_TODO_EMAIL_START = 'SEND_TODO_EMAIL_START';
export const SEND_TODO_EMAIL_FAIL = 'SEND_TODO_EMAIL_FAIL';
export const SEND_TODO_EMAIL_SUCCESS = 'SEND_TODO_EMAIL_SUCCESS';
export const SEND_INTEGRATION_EMAIL_START = 'SEND_INTEGRATION_EMAIL_START';
export const SEND_INTEGRATION_EMAIL_FAIL = 'SEND_INTEGRATION_EMAIL_FAIL';
export const SEND_INTEGRATION_EMAIL_SUCCESS = 'SEND_INTEGRATION_EMAIL_SUCCESS';
export const USER_RESET_ERROR = 'USER_RESET_ERROR';
export const ATTACH_MAIL_CHIMP_START = 'ATTACH_MAIL_CHIMP_START';
export const ATTACH_MAIL_CHIMP_FAIL = 'ATTACH_MAIL_CHIMP_FAIL';
export const ATTACH_MAIL_CHIMP_SUCCESS = 'ATTACH_MAIL_CHIMP_SUCCESS';
export const REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START = 'REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START';
export const REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL = 'REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL';
export const REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS = 'REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS';
export const ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START = 'ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START';
export const ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL = 'ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL';
export const ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS = 'ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS';

// Notes actions
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAIL = 'FETCH_NOTES_FAIL';
export const FETCH_NOTES_START = 'FETCH_NOTES_START';
export const UPDATE_NOTES = 'UPDATE_NOTES';
export const SET_INSERT_NOTES = 'SET_INSERT_NOTES';
export const SET_INSERT_POINTS = 'SET_INSERT_POINTS';
export const SAVE_NOTE_SUCCESS = 'SAVE_NOTE_SUCCESS';
export const SAVE_NOTE_FAIL = 'SAVE_NOTE_FAIL';
export const SAVE_NOTE_START = 'SAVE_NOTE_INIT';
export const DELETE_NOTES_FOREST = 'DELETE_NOTES_FOREST';
export const UNMOUNT_NOTE = 'UNMOUNT_NOTE';
export const SET_NOTE_EDITABLE = 'SET_NOTE_EDITABLE';
export const SET_NOTE_EDITABLE_LATLNG = 'SET_NOTE_EDITABLE_LATLNG';
export const SET_POINT_EDITABLE = 'SET_POINT_EDITABLE';
export const SET_POINT_EDITABLE_LATLNG = 'SET_POINT_EDITABLE_LATLNG';
export const SET_NOTE_TOOL_TIP_ACTIVE = 'SET_NOTE_TOOL_TIP_ACTIVE';
export const SET_NOTES_ID_TO_DISP = 'SET_NOTES_ID_TO_DISP';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL';
export const DELETE_NOTE_START = 'DELETE_NOTE_START';
export const SET_FILTER_TAG = 'SET_FILTER_TAG';
export const SET_FILTER_TODO_MY_TASKS = 'SET_FILTER_TODO_MY_TASKS';
export const SET_FILTER_TODO_DISTANCE_TO_ME = 'SET_FILTER_TODO_DISTANCE_TO_ME';
export const SET_FILTER_TODO_STATUS = 'SET_FILTER_TODO_STATUS';
export const SET_FLY_TO_NOTE_DATA = 'SET_FLY_TO_NOTE_DATA';

// Forest map actions
export const SET_EXPORT_LAYERS = 'SET_EXPORT_LAYERS';
export const SET_MAP_MENU_BRING_TO_FRONT_ID = 'SET_MAP_MENU_BRING_TO_FRONT_ID';
export const SET_MAP_SIMPLE_STATE = 'SET_MAP_SIMPLE_STATE';
export const SET_MAP_ORTO_STATE = 'SET_MAP_ORTO_STATE';
export const SET_MAP_ORTO_KF_STATE = 'SET_MAP_ORTO_KF_STATE';
export const SET_MAP_ORTO_KF_TEMP_STATE = 'SET_MAP_ORTO_KF_TEMP_STATE';
export const SET_MAP_CONTOUR_KF_STATE = 'SET_MAP_CONTOUR_KF_STATE';
export const SET_MAP_SHADOW_KF_STATE = 'SET_MAP_SHADOW_KF_STATE';
export const SET_MAP_SHADOW_SURF_KF_STATE = 'SET_MAP_SHADOW_SURF_KF_STATE';
export const SET_MAP_BLUE_SPOT_STATE = 'SET_MAP_BLUE_SPOT_STATE';
export const SET_MAP_PROTECTED_NATURE_STATE = 'SET_MAP_PROTECTED_NATURE_STATE';
export const SET_MAP_FREDSKOV_STATE = 'SET_MAP_FREDSKOV_STATE';
export const SET_MAP_NATURA_2000_STATE = 'SET_MAP_NATURA_2000_STATE';
export const SET_MAP_HUNTING_LAYER_STATE = 'SET_MAP_HUNTING_LAYER_STATE';
export const SET_MAP_CELLS_STATE = 'SET_MAP_CELLS_STATE';
export const SET_MAP_CELLS_OPACITY_STATE = 'SET_MAP_CELLS_OPACITY_STATE';
export const SET_MAP_LINES_STATE = 'SET_MAP_LINES_STATE';
export const SET_MAP_POINTS_STATE = 'SET_MAP_POINTS_STATE';
export const SET_MAP_NOTES_STATE = 'SET_MAP_NOTES_STATE';
export const SET_MAP_CADASTRAL_STATE = 'SET_MAP_CADASTRAL_STATE';
export const SET_MAP_CADASTRAL_OWNER_STATE = 'SET_MAP_CADASTRAL_OWNER_STATE';
export const SET_MAP_MARKBLOKKE_STATE = 'SET_MAP_MARKBLOKKE_STATE';
export const SET_MAP_PROTECTED_ANCIENT_AREAS_STATE = 'SET_MAP_PROTECTED_ANCIENT_AREAS_STATE';
export const SET_NOT_DISABLED_FOREST_ID = 'SET_NOT_DISABLED_FOREST_ID';
export const SET_FOREST_MARKER_CLICK_ID = 'SET_FOREST_MARKER_CLICK_ID';
export const SET_POLY_TOOL_TIP_ACTIVE = 'SET_POLY_TOOL_TIP_ACTIVE';
export const SET_LINE_LABELS_ACTIVE = 'SET_LINE_LABELS_ACTIVE';
export const SET_INVENTORY_LIST_ACTIVE = 'SET_INVENTORY_LIST_ACTIVE';
export const SET_NEWEST_FOREST_REV_DATE = 'SET_NEWEST_FOREST_REV_DATE';
export const SET_CELL_EDITABLE = 'SET_CELL_EDITABLE';
export const SET_CELL_EDITABLE_LATLNG = 'SET_CELL_EDITABLE_LATLNG';
export const SET_DELETE_MARKER = 'SET_DELETE_MARKER';
export const SET_NEW_NOTE_FINISHED = 'SET_NEW_NOTE_FINISHED';
export const SET_DELETE_CELL = 'SET_DELETE_CELL';
export const SET_NEW_CELL_FINISHED = 'SET_NEW_CELL_FINISHED';
export const SET_NEW_LINE_FINISHED = 'SET_NEW_LINE_FINISHED';
export const FETCH_CELLS_START = 'FETCH_CELLS_START';
export const FETCH_CELLS_SUCCESS = 'FETCH_CELLS_SUCCESS';
export const FETCH_CELLS_FAIL = 'FETCH_CELLS_FAIL';
export const DELETE_CELLS_FOREST = 'DELETE_CELLS_FOREST';
export const SET_LEAFLET_LAYER_REMOVE = 'SET_LEAFLET_LAYER_REMOVE';
export const REDRAW_CELLS = 'REDRAW_CELLS';
export const CELLS_REDRAWN = 'CELLS_REDRAWN';
export const SET_MAP_DATA_UPDATED = 'SET_MAP_DATA_UPDATED';
export const CELL_SAVED = 'CELL_SAVED';
export const SAVE_CELL_SUCCESS = 'SAVE_CELL_SUCCESS';
export const SAVE_CELL_FAILED = 'SAVE_CELL_FAILED';
export const DELETE_CELL_SUCCESS = 'DELETE_CELL_SUCCESS';
export const DELETE_CELL_FAIL = 'DELETE_CELL_FAIL';
export const DELETE_CELL_START = 'DELETE_CELL_START';
export const UNMOUNT_CELL = 'UNMOUNT_CELL';
export const SAVE_FOREST_SUCCESS = 'SAVE_FOREST_SUCCESS';
export const SAVE_NEW_FOREST_SUCCESS = 'SAVE_NEW_FOREST_SUCCESS';
export const SAVE_FOREST_FAIL = 'SAVE_FOREST_FAIL';
export const SAVE_FOREST_BBOX_SUCCESS = 'SAVE_FOREST_BBOX_SUCCESS';
export const SAVE_FOREST_BBOX_FAIL = 'SAVE_FOREST_BBOX_FAIL';
export const GET_FOREST_BBOX_SUCCESS = 'GET_FOREST_BBOX_SUCCESS';
export const GET_FOREST_BBOX_FAIL = 'GET_FOREST_BBOX_FAIL';
export const DELETE_BBOX_FOREST = 'DELETE_BBOX_FOREST';
export const SET_FOREST_OWNER_CHANGE_ALLOWED = 'SET_FOREST_OWNER_CHANGE_ALLOWED';
export const FETCH_POINTS_START = 'FETCH_POINTS_START';
export const FETCH_POINTS_FAIL = 'FETCH_POINTS_FAIL';
export const FETCH_POINTS_SUCCESS = 'FETCH_POINTS_SUCCESS';
export const SET_REDRAW_POINTS_STATE = 'SET_REDRAW_POINTS_STATE';

// New feature request actions
export const NEW_FEATURE_REQUEST_SUCCESS = 'NEW_FEATURE_REQUEST_SUCCESS';
export const NEW_FEATURE_REQUEST_FAIL = 'NEW_FEATURE_REQUEST_FAIL';
export const UNMOUNT_NEW_FEATURE_REQUEST = 'UNMOUNT_NEW_FEATURE_REQUEST';

// Onboarding actions
export const SET_SKIP_ONBOARDING_STATE = 'SET_SKIP_ONBOARDING_STATE';
export const SET_ONBOARDING_ACTIVE = 'SET_ONBOARDING_ACTIVE';
export const SET_ONBOARDING_STATE = 'SET_ONBOARDING_STATE';
export const ZOOM_BTN_Z_IDX_TOGGLE = 'ZOOM_BTN_Z_IDX_TOGGLE';
export const MAP_LAYER_Z_IDX_TOGGLE = 'MAP_LAYER_Z_IDX_TOGGLE';
export const FOREST_SELECTER_Z_IDX_TOGGLE = 'FOREST_SELECTER_Z_IDX_TOGGLE';
export const MAP_SELECTER_Z_IDX_TOGGLE = 'MAP_SELECTER_Z_IDX_TOGGLE';
export const DRAW_TOOL_Z_IDX_TOGGLE = 'DRAW_TOOL_Z_IDX_TOGGLE';
export const MEAS_TOOL_Z_IDX_TOGGLE = 'MEAS_TOOL_Z_IDX_TOGGLE';
export const NEW_NOTE_BTN_Z_IDX_TOGGLE = 'NEW_NOTE_BTN_Z_IDX_TOGGLE';
export const SIDE_BAR_Z_IDX_TOGGLE = 'SIDE_BAR_Z_IDX_TOGGLE';
export const INV_LIST_Z_IDX_TOGGLE = 'INV_LIST_Z_IDX_TOGGLE';
export const PRINT_MAP_Z_IDX_TOGGLE = 'PRINT_MAP_Z_IDX_TOGGLE';
export const FIND_POS_Z_IDX_TOGGLE = 'FIND_POS_Z_IDX_TOGGLE';
export const SEARCH_BTN_Z_IDX_TOGGLE = 'SIDEBAR_BTN_Z_IDX_TOGGLE';
export const ONBOARDING_BTN_COLOR_TOGGLE = 'ONBOARDING_BTN_COLOR_TOGGLE';
export const MAP_LAYER_BTN_COLOR_TOGGLE = 'MAP_LAYER_BTN_COLOR_TOGGLE';
export const DRAW_BTN_COLOR_TOGGLE = 'DRAW_BTN_COLOR_TOGGLE';
export const MEAS_BTN_COLOR_TOGGLE = 'MEAS_BTN_COLOR_TOGGLE';
export const CUSTOMER_SELECT_BTN_COLOR_TOGGLE = 'CUSTOMER_SELECT_BTN_COLOR_TOGGLE';
export const MAP_SELECT_BTN_COLOR_TOGGLE = 'MAP_SELECT_BTN_COLOR_TOGGLE';
export const FIND_POS_BTN_COLOR_TOGGLE = 'FIND_POS_BTN_COLOR_TOGGLE';
export const NEW_NOTE_BTN_COLOR_TOGGLE = 'NEW_NOTE_BTN_COLOR_TOGGLE';
export const SET_DISABLE_MAP_LAYER = 'SET_DISABLE_MAP_LAYER';
export const SET_DISABLE_DRAW = 'SET_DISABLE_DRAW';
export const SET_DISABLE_MEAS = 'SET_DISABLE_MEAS';
export const SET_DISABLE_CUSTOMER_SELECT = 'SET_DISABLE_CUSTOMER_SELECT';
export const SET_DISABLE_FIND_POS = 'SET_DISABLE_FIND_POS';
export const SET_DISABLE_NEW_NOTE = 'SET_DISABLE_NEW_NOTE';
export const SET_CUSTOMER_WIDGET_ACTIVE = 'SET_CUSTOMER_WIDGET_ACTIVE';
export const SET_NOTES_WIDGET_ACTIVE = 'SET_NOTES_WIDGET_ACTIVE';
export const SET_WEATHER_WIDGET_ACTIVE = 'SET_WEATHER_WIDGET_ACTIVE';
export const SET_NEWS_WIDGET_ACTIVE = 'SET_NEWS_WIDGET_ACTIVE';

// Customer/forest select actions
export const SET_CURRENTLY_SELECTED_FOREST_ID = 'SET_CURRENTLY_SELECTED_FOREST_ID';
export const SET_UPDATE_CUSTOMER_ID_LIST_FLAG = 'SET_UPDATE_CUSTOMER_ID_LIST_FLAG';
export const SET_CUSTOMER_ID_LIST = 'SET_CUSTOMER_ID_LIST';
export const SAVE_CUSTOMER_START = 'SAVE_CUSTOMER_START';
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
export const ATTACH_CUSTOMER_TO_USER_SUCCESS = 'ATTACH_CUSTOMER_TO_USER_SUCCESS';
export const REMOVE_CUSTOMER_FROM_USER_SUCCESS = 'REMOVE_CUSTOMER_FROM_USER_SUCCESS';
export const UNMOUNT_CUSTOMER = 'UNMOUNT_CUSTOMER';
export const FETCH_CUSTOMERS_START = 'FETCH_CUSTOMERS_START';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_SUCCESS_ORG = 'FETCH_CUSTOMERS_SUCCESS_ORG';
export const FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL';
export const FETCH_CUSTOMERS_FAIL_ORG = 'FETCH_CUSTOMERS_FAIL_ORG';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL';
export const DELETE_CUSTOMER_START = 'DELETE_CUSTOMER_START';
export const SET_FOREST_HAS_PAYED_SUBSCRIPTION_START = 'SET_FOREST_HAS_PAYED_SUBSCRIPTION_START';
export const SET_FOREST_HAS_PAYED_SUBSCRIPTION_SUCCESS = 'SET_FOREST_HAS_PAYED_SUBSCRIPTION_SUCCESS';
export const SET_FOREST_HAS_PAYED_SUBSCRIPTION_FAIL = 'SET_FOREST_HAS_PAYED_SUBSCRIPTION_FAIL';

// Biotope plans actions
export const SET_CURRENTLY_SELECTED_BIOTOPE_PLAN_ID = 'SET_CURRENTLY_SELECTED_BIOTOPE_PLAN_ID';
export const SET_BIOTOPE_PLAN_LIST_ACTIVE = 'SET_BIOTOPE_PLAN_LIST_ACTIVE';
export const UNMOUNT_BIOTOPE_PLAN = 'UNMOUNT_BIOTOPE_PLAN';
export const SET_REDRAW_BIOTOPE_PLANS_FEATURES = 'SET_REDRAW_BIOTOPE_PLANS_FEATURES';
export const SET_REDRAW_BIOTOPE_PLAN_POINTS = 'SET_REDRAW_BIOTOPE_PLAN_POINTS';
export const FETCH_BIOTOPE_FEATURES_START = 'FETCH_BIOTOPE_FEATURES_START';
export const FETCH_BIOTOPE_FEATURES_SUCCESS = 'FETCH_BIOTOPE_FEATURES_SUCCESS';
export const FETCH_BIOTOPE_FEATURES_FAIL = 'FETCH_BIOTOPE_FEATURES_FAIL';
export const FETCH_BIOTOPE_POINTS_START = 'FETCH_BIOTOPE_POINTS_START';
export const FETCH_BIOTOPE_POINTS_SUCCESS = 'FETCH_BIOTOPE_POINTS_SUCCESS';
export const FETCH_BIOTOPE_POINTS_FAIL = 'FETCH_BIOTOPE_POINTS_FAIL';
export const FETCH_BIOTOPE_PLAN_START = 'FETCH_BIOTOPE_PLAN_START';
export const FETCH_BIOTOPE_PLAN_USER_SUCCESS = 'FETCH_BIOTOPE_PLAN_USER_SUCCESS';
export const FETCH_BIOTOPE_PLAN_ORG_SUCCESS = 'FETCH_BIOTOPE_PLAN_ORG_SUCCESS';
export const FETCH_BIOTOPE_PLAN_FAIL = 'FETCH_BIOTOPE_PLAN_FAIL';
export const SAVE_BIOTOPE_PLAN_START = 'SAVE_BIOTOPE_PLAN_START';
export const SAVE_BIOTOPE_PLAN_SUCCESS = 'SAVE_BIOTOPE_PLAN_SUCCESS';
export const SAVE_BIOTOPE_PLAN_FAIL = 'SAVE_BIOTOPE_PLAN_FAIL';
export const DELETE_BIOTOPE_PLAN_START = 'DELETE_BIOTOPE_PLAN_START';
export const DELETE_BIOTOPE_PLAN_SUCCESS = 'DELETE_BIOTOPE_PLAN_SUCCESS';
export const DELETE_BIOTOPE_PLAN_FAIL = 'DELETE_BIOTOPE_PLAN_FAIL';
export const ATTACH_BIOTOPE_PLAN_TO_USER = 'ATTACH_BIOTOPE_PLAN_TO_USER';
export const REMOVE_BIOTOPE_PLAN_FROM_USER = 'REMOVE_BIOTOPE_PLAN_FROM_USER';
export const SET_UPDATE_BIOTOPE_PLAN_FLAG = 'SET_UPDATE_BIOTOPE_PLAN_FLAG';
export const DELETE_BIOTOPE_PLAN_FEATURES = 'DELETE_BIOTOPE_PLAN_FEATURES';

// Organizations actions
export const FETCH_ORG_START = 'FETCH_ORG_START';
export const FETCH_ORG_SUCCESS = 'FETCH_ORG_SUCCESS';
export const FETCH_ORG_LIST_START = 'FETCH_ORG_LIST_START';
export const FETCH_ORG_LIST_FAIL = 'FETCH_ORG_LIST_FAIL';
export const FETCH_ORG_LIST_SUCCESS = 'FETCH_ORG_LIST_SUCCESS';
export const FETCH_ORG_USERS_START = 'FETCH_ORG_USERS_START';
export const FETCH_ORG_USERS_FAIL = 'FETCH_ORG_USERS_FAIL';
export const FETCH_ORG_USERS_SUCCESS = 'FETCH_ORG_USERS_SUCCESS';
export const FETCH_ORG_TEAMS_START = 'FETCH_ORG_TEAMS_START';
export const FETCH_ORG_TEAMS_FAIL = 'FETCH_ORG_TEAMS_FAIL';
export const FETCH_ORG_TEAMS_SUCCESS = 'FETCH_ORG_TEAMS_SUCCESS';

// Snackbar actions
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';

// Layout actions
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS';
export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const MAIN_MENU_OPEN = 'MAIN_MENU_OPEN';
export const ACCOUNT_MENU_OPEN = 'ACCOUNT_MENU_OPEN';
export const SET_CHOSEN_WORK_ENVIRONMENT = 'SET_CHOSEN_WORK_ENVIRONMENT';
export const SET_KANBAN_BOARD_ACTIVE = 'SET_KANBAN_BOARD_ACTIVE';
export const SET_DISPLAY_UPGRADE_BOX = 'SET_DISPLAY_UPGRADE_BOX';
export const SET_TOTAL_FOREST_AREA = 'SET_TOTAL_FOREST_AREA';
export const SET_NUMBER_OF_POINTS = 'SET_NUMBER_OF_POINTS';
export const SET_NUMBER_OF_HUNTING_POINTS = 'SET_NUMBER_OF_HUNTING_POINTS';
export const SET_NUMBER_OF_NOTES = 'SET_NUMBER_OF_NOTES';
export const SET_SPECIES_SCHEME = 'SET_SPECIES_SCHEME';

// Subscription actions
export const FETCH_REEPAY_STATE_START = 'FETCH_REEPAY_STATE_START';
export const FETCH_REEPAY_STATE_SUCCESS = 'FETCH_REEPAY_STATE_SUCCESS';
export const FETCH_REEPAY_STATE_FAIL = 'FETCH_REEPAY_STATE_FAIL';
export const FETCH_REEPAY_PLAN_START = 'FETCH_REEPAY_PLAN_START';
export const FETCH_REEPAY_PLAN_SUCCESS = 'FETCH_REEPAY_PLAN_SUCCESS';
export const FETCH_REEPAY_PLAN_FAIL = 'FETCH_REEPAY_PLAN_FAIL';
export const FETCH_REEPAY_PLAN_LIST_START = 'FETCH_REEPAY_PLAN_LIST_START';
export const FETCH_REEPAY_PLAN_LIST_SUCCESS = 'FETCH_REEPAY_PLAN_LIST_SUCCESS';
export const FETCH_REEPAY_PLAN_LIST_FAIL = 'FETCH_REEPAY_PLAN_LIST_FAIL';
export const ATTACH_REEPAY_START = 'ATTACH_REEPAY_START';
export const ATTACH_REEPAY_FAIL = 'ATTACH_REEPAY_FAIL';
export const ATTACH_REEPAY_SUCCESS = 'ATTACH_REEPAY_SUCCESS';
export const SET_SUBSCRIPTION_ON_HOLD_START = 'SET_SUBSCRIPTION_ON_HOLD_START';
export const SET_SUBSCRIPTION_ON_HOLD_SUCCESS = 'SET_SUBSCRIPTION_ON_HOLD_SUCCESS';
export const SET_SUBSCRIPTION_ON_HOLD_FAIL = 'SET_SUBSCRIPTION_ON_HOLD_FAIL';
export const REACTIVATE_SUBSCRIPTION_START = 'REACTIVATE_SUBSCRIPTION_START';
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_FAIL = 'REACTIVATE_SUBSCRIPTION_FAIL';
export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export const UNCANCEL_SUBSCRIPTION_START = 'UNCANCEL_SUBSCRIPTION_START';
export const UNCANCEL_SUBSCRIPTION_SUCCESS = 'UNCANCEL_SUBSCRIPTION_SUCCESS';
export const UNCANCEL_SUBSCRIPTION_FAIL = 'UNCANCEL_SUBSCRIPTION_FAIL';
export const CREATE_SUBSCRIPTION_START = 'CREATE_SUBSCRIPTION_START';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAIL = 'CREATE_SUBSCRIPTION_FAIL';
export const SET_NEW_NOTIFICATIONS = 'SET_NEW_NOTIFICATIONS';
export const FETCH_DISCOUNT_CODES_LIST_START = 'FETCH_DISCOUNT_CODES_LIST_START';
export const FETCH_DISCOUNT_CODES_LIST_SUCCESS = 'FETCH_DISCOUNT_CODES_LIST_SUCCESS';
export const FETCH_DISCOUNT_CODES_LIST_FAIL = 'FETCH_DISCOUNT_CODES_LIST_FAIL';
export const FETCH_FB_DISCOUNT_CODES_LIST_START = 'FETCH_FB_DISCOUNT_CODES_LIST_START';
export const FETCH_FB_DISCOUNT_CODES_LIST_SUCCESS = 'FETCH_FB_DISCOUNT_CODES_LIST_SUCCESS';
export const FETCH_FB_DISCOUNT_CODES_LIST_FAIL = 'FETCH_FB_DISCOUNT_CODES_LIST_FAIL';
export const FETCH_PAYMENT_DETAILS_START = 'FETCH_PAYMENT_DETAILS_START';
export const FETCH_PAYMENT_DETAILS_SUCCESS = 'FETCH_PAYMENT_DETAILS_SUCCESS';
export const FETCH_PAYMENT_DETAILS_FAIL = 'FETCH_PAYMENT_DETAILS_FAIL';
export const SET_IN_TRIAL_OPERATION_ACTIVE = 'SET_IN_TRIAL_OPERATION_ACTIVE';
export const SET_IN_TRIAL_HUNTING_ACTIVE = 'SET_IN_TRIAL_HUNTING_ACTIVE';
export const SET_TASK_PLANNING_ACTIVE = 'SET_TASK_PLANNING_ACTIVE';
export const SET_KW_INTEGRATION_ACTIVE = 'SET_KW_INTEGRATION_ACTIVE';
export const SET_SUBSCRIPTION_STATE = 'SET_SUBSCRIPTION_STATE';

// Hunting map actions
export const FETCH_HUNTING_FEATURES_START = 'FETCH_HUNTING_FEATURES_START';
export const FETCH_HUNTING_FEATURES_FAIL = 'FETCH_HUNTING_FEATURES_FAIL';
export const FETCH_HUNTING_FEATURES_SUCCESS = 'FETCH_HUNTING_FEATURES_SUCCESS';
export const FETCH_HUNTING_POINTS_START = 'FETCH_HUNTING_POINTS_START';
export const FETCH_HUNTING_POINTS_FAIL = 'FETCH_HUNTING_POINTS_FAIL';
export const FETCH_HUNTING_POINTS_SUCCESS = 'FETCH_HUNTING_POINTS_SUCCESS';
export const SAVE_HUNTING_FEATURE_START = 'SAVE_HUNTING_FEATURE_START';
export const SAVE_HUNTING_FEATURE_FAIL = 'SAVE_HUNTING_FEATURE_FAIL';
export const SAVE_HUNTING_FEATURE_SUCCESS = 'SAVE_HUNTING_FEATURE_SUCCESS';
export const DELETE_HUNTING_FEATURE_START = 'DELETE_HUNTING_FEATURE_START';
export const DELETE_HUNTING_FEATURE_FAIL = 'DELETE_HUNTING_FEATURE_FAIL';
export const DELETE_HUNTING_FEATURE_SUCCESS = 'DELETE_HUNTING_FEATURE_SUCCESS';
export const SET_REDRAW_HUNTING_FEATURES = 'SET_REDRAW_HUNTING_FEATURES';
export const REDRAW_HUNTING_POINTS = 'REDRAW_HUNTING_POINTS';
export const SET_HUNTING_ALLOW_CHANGE = 'SET_HUNTING_ALLOW_CHANGE';
export const UNMOUNT_HUNTING_FEATURE = 'UNMOUNT_HUNTING_FEATURE';
export const DELETE_ALL_HUNTING_FEATURES = 'DELETE_ALL_HUNTING_FEATURES';
export const DELETE_ALL_HUNTING_POINTS = 'DELETE_ALL_HUNTING_POINTS';

// Inventory actions
export const SET_INVENTORY_FILTER_STATE = 'SET_INVENTORY_FILTER_STATE';
export const SET_INVENTORY_FILTER_CELL_IDS = 'SET_INVENTORY_FILTER_CELL_IDS';
export const SET_SHOW_FILTER_IN_MAP = 'SET_SHOW_FILTER_IN_MAP';
export const SET_INVENTORY_FILTER_POINT_IDS = 'SET_INVENTORY_FILTER_POINT_IDS';

// Activity actions
export const SET_ACTIVITY_FILTER_STATE = 'SET_ACTIVITY_FILTER_STATE';
export const SET_ACTIVITY_FILTER_OBJECT_IDS = 'SET_ACTIVITY_FILTER_OBJECT_IDS';
export const SET_ACTIVITY_SHOW_FILTER_IN_MAP = 'SET_ACTIVITY_SHOW_FILTER_IN_MAP';

// Documents actions
export const FETCH_DOCUMENT_LIST_START = 'FETCH_DOCUMENT_LIST_START';
export const FETCH_DOCUMENT_LIST_SUCCESS = 'FETCH_DOCUMENT_LIST_SUCCESS';
export const FETCH_DOCUMENT_LIST_FAIL = 'FETCH_DOCUMENT_LIST_FAIL';
export const REMOVE_DOCUMENT_LIST = 'REMOVE_DOCUMENT_LIST';
export const UPLOAD_DOCUMENT_START = 'UPLOAD_DOCUMENT_START';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'UPLOAD_DOCUMENT_FAIL';
export const DELETE_DOCUMENT_START = 'DELETE_DOCUMENT_START';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL';
export const LIST_DOCUMENTS_START = 'LIST_DOCUMENTS_START';
export const LIST_DOCUMENTS_SUCCESS = 'LIST_DOCUMENTS_SUCCESS';
export const LIST_DOCUMENTS_FAIL = 'LIST_DOCUMENTS_FAIL';
export const SET_DOCUMENT_READ_STATE_START = 'SET_DOCUMENT_READ_STATE_START';
export const SET_DOCUMENT_READ_STATE_SUCCESS = 'SET_DOCUMENT_READ_STATE_SUCCESS';
export const SET_DOCUMENT_READ_STATE_FAIL = 'SET_DOCUMENT_READ_STATE_FAIL';


