import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Icons
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';

// Store
import * as actions from '../../../../../store/actions/index';

const styles = () => ({
    root: {
        width: '100%',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: '#5EBD3E',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

class IntegrationKWplan extends React.Component {
    state = {
        tempSendEmailLoading: false,
    };

    componentDidMount() {
    }

    componentDidUpdate() {
        // Show snackbar
        if (this.state.tempSendEmailLoading !== this.props.loadingSendIntegrationEmail) {
            if (!this.props.loadingSendIntegrationEmail) {
                if (this.props.errorSendIntegrationEmail) {
                    this.props.onEnqueueSnackbar({
                        message: "Det er ikke muligt at sende en integrations e-mail på nuværende tidspunkt. Prøv igen senere eller kontakt Skovkortet.",
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'info',
                        },
                    });
                } else {
                    this.props.onEnqueueSnackbar({
                        message: "En integrations e-mail er hermed afsendt til din e-mail!",
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                        },
                    });
                }
            }
            this.setState({ tempSendEmailLoading: this.props.loadingSendIntegrationEmail });
        }
    }

    onSendEmailClickHandler = () => {
        this.props.onSendIntegrationEmail('kw', this.props.currentAuthUser.email, this.props.customerList[this.props.selectedForest]);

        // Send event to Analytics
        ReactGA.event({
            category: 'Integrations',
            action: 'Integration request initiated',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }


    render() {
        const { classes } = this.props;

        // Check forest data
        let forestText = '';
        if (this.props.customerList && this.props.customerList[this.props.selectedForest]) {
            forestText = 'Forbind: ' + this.props.customerList[this.props.selectedForest].forestName + ', ' + this.props.customerList[this.props.selectedForest].name + ', med KW-Plan';
        }

        // Tooltip text
        let tooltipText = '';
        if (!this.props.selectedForest) {
            tooltipText = 'Vælg skov, som skal forbindes med KW-Plan'
        } else {
            tooltipText = 'Klik for at forbinde med KW-Plan'
        }

        return (
            <div>
                <div className={classes.root}>
                    {/* KW-Plan */}
                    <div>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            KW-Plan
                        </Typography>

                        {this.props.selectedForest && <Typography
                            variant="body1"
                            gutterBottom
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {forestText}
                        </Typography>}

                        <Tooltip title={tooltipText} aria-label="Forbind med KW-Plan">
                            <div className={classes.button}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => this.onSendEmailClickHandler()}
                                        disabled={(!this.props.selectedForest)}
                                    >
                                        Forbind med KW-Plan
                                        <IconChevronRight />
                                    </Button>
                                    {this.props.loadingSendIntegrationEmail && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        loadingSendIntegrationEmail: state.user.loadingSendIntegration,
        errorSendIntegrationEmail: state.user.errorSendIntegration,
        selectedForest: state.customer.currentlySelectedForestId,
        customerList: state.customer.customerList,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSendIntegrationEmail: (type, email, data) => dispatch(actions.sendIntegrationEmail(type, email, data)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IntegrationKWplan));