import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Icons
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';

// Fonts
import './style.css';

// Components
import DialogBox from './DialogBox';
import * as actions from '../../store/actions/index';

// Text
const Text = require('./Text.json');

// Parameters
const numStepsForester = 19;
const numStepsForestOwner = 16;
const numStepsForestOwnerUnderManagement = 15;
const numStepsForesterMobile = 17;
const numStepsForestOwnerMobile = 13;
const isTablet = 1080;
const isMobile = 760;

const styles = () => ({
    background: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
    },
    backgroundTrans: {
        position: 'absolute',
        zIndex: 90,
        display: 'flex',
        alignItems: 'center',
    },
    zoomBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        left: 20,
        bottom: 105,
        zIndex: 200,
    },
    layerBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    drawBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    measureBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    mapSelectBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    createCustomerBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    customerBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    noteBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    invListBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        left: 58,
        top: 53,
        zIndex: 200,
    },
    printMapBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        left: 109,
        top: 53,
        zIndex: 200,
    },
    findPosMapBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
    searchMapBox: {
        fontFamily: 'Permanent Marker',
        fontSize: '20px',
        color: "#6499BE",
        position: 'absolute',
        zIndex: 200,
    },
});

export class Onboarding extends React.Component {
    state = {
        checked: true,
        prevState: -1,
        onboarded: true, //this.props.currentAuthUser !== null ? this.props.currentAuthUser.onboarded : true,
        userRole: this.props.currentAuthUser !== null ? this.props.currentAuthUser.userRole : "free",
        stepNum: 0,
        numSteps: 0,
    };

    goForward = () => {
        this.props.onSetOnboardingState(this.props.onboardingState + 1)

        // Send event to Analytics
        ReactGA.event({
            category: 'Onboarding',
            action: `Onboarding step: ${this.props.onboardingState + 1}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    goBack = () => {
        this.props.onSetOnboardingState(this.props.onboardingState - 1)

        // Send event to Analytics
        ReactGA.event({
            category: 'Onboarding',
            action: `Onboarding step: ${this.props.onboardingState - 1}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    goToStage = (stage) => {
        this.setState({ prevState: this.props.onboardingState })
        this.props.onSetOnboardingState(stage);

        // Send event to Analytics
        ReactGA.event({
            category: 'Onboarding',
            action: `Onboarding go to stage: ${stage} (${this.state.stepNum}/${this.state.numSteps})`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    exit = () => {
        this.props.onSetOnboardingState('welcome');
        this.props.onSetOnboardingActive(false);
        this.props.onSetSkipOnboardingState(true);
        this.props.history.replace({
            pathname: '/',
        });

        // Send event to Analytics
        ReactGA.event({
            category: 'Onboarding',
            action: `Onboarding open: ${false}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    componentWillMount() {
        // Set onboarding state to the beginning
        this.props.onSetOnboardingState('welcome');

        // Set number of steps in state
        if (this.props.currentAuthUser.userRole === 'forester') {
            if (window.innerWidth <= isMobile) {
                this.setState({ numSteps: numStepsForesterMobile });
            } else {
                this.setState({ numSteps: numStepsForester });
            }
        } else if (this.props.currentAuthUser.userRole === 'forestOwner') {
            if (window.innerWidth <= isMobile) {
                this.setState({ numSteps: numStepsForestOwnerMobile });
            } else {
                if (this.props.currentAuthUser.affiliation && (this.props.currentAuthUser.affiliation.skovdyrkerne || this.props.currentAuthUser.affiliation.HD || this.props.currentAuthUser.affiliation.sls)) {
                    this.setState({ numSteps: numStepsForestOwnerUnderManagement });
                } else {
                    this.setState({ numSteps: numStepsForestOwner });
                }
            }
        }

        // Route to root
        this.props.history.replace({
            pathname: "/",
        });

        // Send event to Analytics
        ReactGA.event({
            category: 'Onboarding',
            action: `Onboarding open: ${true}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    componentWillUnmount() {
        // Reset z-index of all affected components
        this.props.onZoomBtnZidxToggle('back');
        this.props.onMapLayerZidxToggle('back');
        this.props.onSideBarZidxToggle('back');
        this.props.onNewNoteBtnZidxToggle('back');
        this.props.onDrawToolZidxToggle('back');
        this.props.onMeasToolZidxToggle('back');
        this.props.onInvListZidxToggle('back');
        this.props.onPrintMapZidxToggle('back');
        this.props.onFindPosZidxToggle('back');
        this.props.onForestSelecterZidxToggle('back');
        this.props.onMapSelecterZidxToggle('back');

        // Reset color of all affected components
        this.props.onOnboardingBtnColorToggle('front')

        // Reset disabled buttons
        this.props.onSetDisableDraw(false);
        this.props.onSetDisableMeas(false);
        this.props.onSetDisableCustomerSelect(false);
        this.props.onSetDisableMapSelect(false)
        this.props.onSetDisableFindPos(false);
        this.props.onSetDisableNewNote(false);

        // Reset sidebar widget active
        this.props.onSetCustomerWidgetActive(true);
        this.props.onSetNotesWidgetActive(true);
        this.props.onSetWeatherWidgetActive(true);
        this.props.onSetNewsWidgetActive(true);
    }

    render() {
        const { classes } = this.props;
        const { onboarded, userRole } = this.state;

        if (this.state.prevState !== this.props.onboardingState) {
            this.setState({ checked: false, prevState: this.props.onboardingState })
            if (this.props.onboardingState !== 'drawCellCreate' && this.props.onboardingState !== 'newNoteCreate') {
                if (this.props.onboardingState === 'measIntro' && this.props.config.newNote.noteNumber && this.props.numNotes >= this.props.config.newNote.noteNumber) {
                    this.setState({ stepNum: this.state.stepNum + 3 })
                } else {
                    this.setState({ stepNum: this.state.stepNum + 1 })
                }
            }
        }

        // Check for mobile and set exit in dialog box accordingly.
        // Onboarding can be skipped in mobile version
        const showDialogboxExit = this.props.mobile ? true : onboarded;

        // Switch content based on onboarding state
        let content = <div> Error </div>
        let btns = [];
        switch (this.props.onboardingState) {
            case 'owner-no-forest':
                btns = [{ label: 'Afbryd', clicked: this.exit, variant: 'text', discrete: true }];
                return <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                    <DialogBox
                        header={Text.ownerNoForest.header}
                        text={Text.ownerNoForest.text}
                        onboarded={showDialogboxExit}
                        buttons={btns}
                        mobile={this.props.mobile}
                        onExit={this.exit} />
                </div>;

            case 'welcome':
                // Z index, color logic and disable stuff
                this.props.onOnboardingBtnColorToggle('back')
                this.props.onSetCustomerWidgetActive(false);
                this.props.onSetNotesWidgetActive(false);
                this.props.onSetWeatherWidgetActive(false);
                this.props.onSetNewsWidgetActive(false);
                this.props.onSetDisableDraw(true);
                this.props.onSetDisableMeas(true);
                this.props.onSetDisableCustomerSelect(true);
                this.props.onSetDisableMapSelect(true);
                this.props.onSetDisableFindPos(true);
                this.props.onSetDisableNewNote(true);
                { this.props.mobile && this.props.onSidebarOpen(false) }

                let header = Text.welcome.header;
                if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.HD && this.props.currentAuthUser.affiliation.HD.status === 'active') {
                    header = Text.welcome.headerHD;
                } else if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.sls && this.props.currentAuthUser.affiliation.sls.status === 'active') {
                    header = Text.welcome.headerSLS;
                } else if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.skovdyrkerne && this.props.currentAuthUser.affiliation.skovdyrkerne.status === 'active') {
                    header = Text.welcome.headerSkovdyrkerne;
                }

                // Has user been onboarded?
                onboarded ?
                    btns = [
                        { label: 'Spring over', clicked: this.exit, variant: 'text', discrete: true },
                        { label: 'Start introduktionen her', clicked: this.goToStage, stage: 'introMap', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                    ] :
                    btns = [
                        { label: 'Start introduktionen her', clicked: this.goToStage, stage: 'introMap', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                    ];
                content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                    <DialogBox
                        header={header}
                        step={[this.state.stepNum, this.state.numSteps]}
                        text={userRole === "forester" ?
                            this.props.mobile ? Text.welcome.foresterMobile : Text.welcome.forester :
                            this.props.mobile ? Text.welcome.ownerMobile : Text.welcome.owner}
                        onboarded={showDialogboxExit}
                        buttons={btns}
                        mobile={this.props.mobile}
                        onExit={this.exit} />
                </div>
                break;

            case 'introMap':
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'welcome', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                    { label: 'Næste', clicked: this.goToStage, stage: 'changeMap', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.introMap.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.introMap.foresterMobile : Text.introMap.forester :
                                this.props.mobile ? Text.introMap.ownerMobile : Text.introMap.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div >
                }
                break;

            case 'changeMap':
                // Z index and color logic
                // this.props.onZoomBtnZidxToggle('front');
                this.props.onMapLayerZidxToggle('front');

                // Buttons
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'introMap', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                ];
                // For implementing transition
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.changeMap.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.changeMap.foresterMobile : Text.changeMap.forester :
                                this.props.mobile ? Text.changeMap.ownerMobile : Text.changeMap.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            margin={this.props.mobile ? '0px 0px 13px 0px' : '0px'}
                            onExit={this.exit} />
                        {/* <MediaQuery minDeviceWidth={isTablet + 1}>
                            <div className={classes.zoomBox}>
                                ⬇️ TRYK HER
                            </div>
                        </MediaQuery> */}
                        <div
                            className={classes.layerBox}
                            style={{ right: this.props.mobile ? 63 : 437, bottom: this.props.mobile ? 87 : 70, }}
                        >
                            {this.props.mobile ? "TRYK HER ➡️" : "TRYK HER ⬇️"}
                        </div>
                    </div>
                }
                break;

            case 'newCustomerIntro':
                let stageName = this.state.userRole === 'forester' ? 'newCustomerCreate' : 'forestSelectIntro';
                if (this.props.currentAuthUser.affiliation && (this.props.currentAuthUser.affiliation.HD || this.props.currentAuthUser.affiliation.sls || this.props.currentAuthUser.affiliation.skovdyrkerne)) {
                    stageName = 'forestSelectIntro';
                }
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'changeMap', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                    { label: 'Næste', clicked: this.goToStage, stage: stageName, icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.backgroundTrans} style={{ paddingLeft: this.props.mobile ? 12 : '8%', bottom: this.props.mobile ? '12%' : 78, }}>
                        <DialogBox
                            header={Text.newCustomerIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                (this.props.mobile || this.props.tablet) ? Text.newCustomerIntro.foresterMobile : Text.newCustomerIntro.forester :
                                (this.props.mobile || this.props.tablet) ? Text.newCustomerIntro.ownerMobile : Text.newCustomerIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            boxWidth={320}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }
                break;

            case 'newCustomerCreate':
                // Z index, color logic and sidebar content
                // this.props.onZoomBtnZidxToggle('back');
                this.props.onMapLayerZidxToggle('back');
                this.props.onSideBarZidxToggle('front');
                this.props.onSetCustomerWidgetActive(true);
                // Buttons
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'newCustomerIntro', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.newCustomerCreate.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.newCustomerCreate.foresterMobile : Text.newCustomerCreate.forester :
                                this.props.mobile ? Text.newCustomerCreate.ownerMobile : Text.newCustomerCreate.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                        {this.props.mobile && <div
                            className={classes.createCustomerBox}
                            style={{ right: 68, top: 20, }}
                        >
                            TRYK HER ➡️
                        </div>}
                    </div>
                }
                break;

            case 'newCustomerEnd':
                // Z index, color logic and sidebar content
                this.props.onSideBarZidxToggle('back');
                this.props.onSetCustomerWidgetActive(false);
                { this.props.mobile && this.props.onSidebarOpen(false) }

                // Buttons
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'newCustomerCreate', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                    { label: 'Næste', clicked: this.goToStage, stage: 'customerSelectIntro', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.newCustomerEnd.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.newCustomerEnd.foresterMobile : Text.newCustomerEnd.forester :
                                this.props.mobile ? Text.newCustomerEnd.ownerMobile : Text.newCustomerEnd.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }
                break;

            case 'customerSelectIntro':
                // Z index, color logic and sidebar content
                this.props.onMapLayerZidxToggle('back');
                this.props.onForestSelecterZidxToggle('front');
                this.props.onSetDisableCustomerSelect(false);

                // Buttons
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'newCustomerCreate', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.customerSelectIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.customerSelectIntro.foresterMobile : Text.customerSelectIntro.forester :
                                this.props.mobile ? Text.customerSelectIntro.ownerMobile : Text.customerSelectIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            margin={this.props.mobile ? '0px 0px 0px 0px' : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.customerBox}
                            style={{ right: this.props.mobile ? 63 : 788 + 5 + 10, bottom: this.props.mobile ? 310 : 70, }}
                        >
                            {this.props.mobile ? "TRYK HER ➡️" : "TRYK HER ⬇️"}
                        </div>
                    </div>
                }
                break;

            case 'forestSelectIntro':
                //--- Check if no forest are on the customer ---//
                if (this.props.currentAuthUser.userRole === 'forestOwner' && this.props.currentAuthUser.forests.length === 0) {
                    this.props.onSetOnboardingState('owner-no-forest');
                    this.props.onMapLayerZidxToggle('back');
                    break;
                }
                // Z index, color logic and sidebar content
                this.props.onMapLayerZidxToggle('back');
                this.props.onForestSelecterZidxToggle('front');
                this.props.onSetDisableCustomerSelect(false);

                // Buttons
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'newCustomerCreate', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.forestSelectIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.forestSelectIntro.foresterMobile : Text.forestSelectIntro.forester :
                                this.props.mobile ? Text.forestSelectIntro.ownerMobile : Text.forestSelectIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            margin={this.props.mobile ? '0px 0px 0px 0px' : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.customerBox}
                            style={{ right: this.props.mobile ? 63 : 788 + 5 + 10, bottom: this.props.mobile ? 310 : 70, }}
                        >
                            {this.props.mobile ? "TRYK HER ➡️" : "TRYK HER ⬇️"}
                        </div>
                    </div>
                }
                break;

            case 'navigateMap':
                // Buttons
                btns = [
                ];

                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.backgroundTrans} style={{ paddingLeft: this.props.mobile ? 12 : '8%', bottom: this.props.mobile ? '12%' : 78, }}>
                        <DialogBox
                            header={Text.navigateMap.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.navigateMap.foresterMobile : Text.navigateMap.forester :
                                this.props.mobile ? Text.navigateMap.ownerMobile : Text.navigateMap.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }
                break;

            case 'litraInfo':
                this.props.onForestSelecterZidxToggle('back');
                // Check if the forest has to many notes and skip the note state
                let goToStage = 'newNoteIntro';
                if (this.props.config.newNote.noteNumber && this.props.numNotes >= this.props.config.newNote.noteNumber) {
                    goToStage = 'measIntro';
                }
                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: goToStage, icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];

                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.backgroundTrans} style={{ zIndex: 1000, paddingLeft: this.props.mobile ? 12 : '8%', top: this.props.mobile ? 10 : 78, }}>
                        <DialogBox
                            header={Text.litraInfo.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.litraInfo.foresterMobile : Text.litraInfo.forester :
                                this.props.mobile ? Text.litraInfo.ownerMobile : Text.litraInfo.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }
                break;

            case 'drawingIntro':
                // Z index, color logic and sidebar content
                // this.props.onZoomBtnZidxToggle('back');
                this.props.onMapLayerZidxToggle('back');
                this.props.onForestSelecterZidxToggle('back');
                // this.props.onDrawToolZidxToggle('front');
                // this.props.onSetDisableDraw(false);
                // this.props.onSetDisableCustomerSelect(true);

                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: 'drawCellIntro', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.drawingIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.drawingIntro.foresterMobile : Text.drawingIntro.forester :
                                this.props.mobile ? Text.drawingIntro.ownerMobile : Text.drawingIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }
                break;

            case 'drawCellIntro':
                // Z index, color logic and sidebar content
                this.props.onDrawToolZidxToggle('front');
                this.props.onSetDisableDraw(false);
                this.props.onSetDisableCustomerSelect(true);

                // Buttons
                btns = [
                    // { label: 'Tilbage', clicked: this.goToStage, stage: 'customerSelectIntro', icon: <IconChevronLeft />, iconLeft: true, variant: 'text', discrete: true },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.drawCellIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.drawCellIntro.foresterMobile : Text.drawCellIntro.forester :
                                this.props.mobile ? Text.drawCellIntro.ownerMobile : Text.drawCellIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            margin={this.props.mobile ? '0px 0px 46px 0px' : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.drawBox}
                            style={{ right: this.props.mobile ? 63 : 504, bottom: this.props.mobile ? 144 : 70, }}
                        >
                            {this.props.mobile ? "TRYK HER ➡️" : "TRYK HER ⬇️"}
                        </div>
                    </div>
                }
                break;

            case 'drawCellCreate':
                // Z index, color logic and sidebar content
                this.props.onDrawToolZidxToggle('back');
                // Content of stage
                content = <div></div>
                break;

            case 'drawCellEnd':
                // Z index, color logic and sidebar content
                this.props.onForestSelecterZidxToggle('back');
                this.props.onDrawToolZidxToggle('back');
                this.props.onSideBarZidxToggle('front');

                //
                this.props.onSetDisableDraw(true);

                // Buttons
                btns = [
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.drawCellEnd.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.drawCellEnd.foresterMobile : Text.drawCellEnd.forester :
                                this.props.mobile ? Text.drawCellEnd.ownerMobile : Text.drawCellEnd.owner}
                            onboarded={false}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }
                break;

            case 'newNoteIntro':
                // Z index, color logic and sidebar content
                this.props.onSideBarZidxToggle('back');
                this.props.onNewNoteBtnZidxToggle('front');
                this.props.onSetDisableNewNote(false);
                if (window.innerWidth <= isMobile) {
                    this.props.onSidebarOpen(false);
                }

                // Buttons
                btns = [
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.newNoteIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.newNoteIntro.foresterMobile : Text.newNoteIntro.forester :
                                this.props.mobile ? Text.newNoteIntro.ownerMobile : Text.newNoteIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            //margin={this.props.mobile ? `${(userRole === "forester" ? '304px' : '212px')} 0px 0px 0px` : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.noteBox}
                            style={{
                                right: this.props.mobile ? null : 467,
                                left: this.props.mobile ? 123 : null,
                                top: this.props.mobile ? (userRole === "forester" ? 256 : 199) : 15,
                            }}
                        >
                            {this.props.mobile ? "⬅️ TRYK HER" : "TRYK HER ➡️"}
                        </div>
                    </div>
                }
                break;
            case 'newNoteCreate':
                // Z index, color logic and sidebar content
                this.props.onNewNoteBtnZidxToggle('back')
                // Content of stage
                content = <div></div>
                break;

            case 'newNoteEnd':
                // Z index, color logic and sidebar content
                this.props.onSideBarZidxToggle('front');

                // Buttons
                btns = [
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.newNoteEnd.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.newNoteEnd.foresterMobile : Text.newNoteEnd.forester :
                                this.props.mobile ? Text.newNoteEnd.ownerMobile : Text.newNoteEnd.owner}
                            onboarded={false}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }
                break;

            case 'measIntro':
                // Z index, color logic and sidebar content
                this.props.onSideBarZidxToggle('back');
                this.props.onMeasToolZidxToggle('front');
                //this.props.onSetDisableMeas(false);
                //this.props.onSetNotesWidgetActive(true);
                if (window.innerWidth <= isMobile) {
                    this.props.onSidebarOpen(false);
                }

                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: 'mapSelectIntro', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.measIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.measIntro.foresterMobile : Text.measIntro.forester :
                                this.props.mobile ? Text.measIntro.ownerMobile : Text.measIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            //margin={this.props.mobile ? '0px 0px 166px 0px' : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.measureBox}
                            style={{ right: this.props.mobile ? 63 : 570, bottom: this.props.mobile ? 201 : 70, }}
                        >
                            {this.props.mobile ? "SE HER ➡️" : "SE HER ⬇️"}
                        </div>
                    </div>
                }
                break;

            case 'measCreate':
                // Z index, color logic and sidebar content
                this.props.onMeasToolZidxToggle('back');
                // Content of stage
                content = <div></div>
                break;

            // case 'measEnd':
            //     // Buttons
            //     btns = [
            //         { label: 'Næste', clicked: this.goToStage, stage: 'inventoryListIntro', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
            //     ];
            //     if (!this.state.checked) {
            //         content = null;
            //         this.setState({ checked: true })
            //     } else {
            //         content = <div className={classes.backgroundTrans}>
            //             <DialogBox
            //                 header="Måleværktøj"
            //                 step={[this.state.stepNum, this.state.numSteps]}
            //                 text={userRole === "forester" ?
            //                     "Målinger vil ikke blive gemt på samme måde som litraer og noterne. Det er derfor vigtigt ikke at opdatere browseren imens man foretager opmålinger." :
            //                     "Målinger vil ikke blive gemt på samme måde som litraer og noterne. Det er derfor vigtigt ikke at opdatere browseren imens man foretager opmålinger."
            //                 }
            //                 onboarded={onboarded}
            //                 buttons={btns}
            //                 mobile={this.props.mobile}
            //                 onExit={this.exit} />
            //         </div>
            //     }
            //     break;

            case 'mapSelectIntro':
                // Z index, color logic and sidebar content
                this.props.onMeasToolZidxToggle('back');
                this.props.onMapSelecterZidxToggle('front')

                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: this.props.mobile ? 'findPosIntro' : 'inventoryListIntro', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.mapSelectIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.mapSelectIntro.foresterMobile : Text.mapSelectIntro.forester :
                                this.props.mobile ? Text.mapSelectIntro.ownerMobile : Text.mapSelectIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            //margin={this.props.mobile ? '0px 0px 150px 0px' : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.mapSelectBox}
                            style={{ right: this.props.mobile ? 63 : 638, bottom: this.props.mobile ? 258 : 70, }}
                        >
                            {this.props.mobile ? "SE HER ➡️" : "SE HER ⬇️"}
                        </div>
                    </div>
                }
                break;

            case 'inventoryListIntro':
                // Z index, color logic and sidebar content
                this.props.onInvListZidxToggle('front');
                this.props.onMapSelecterZidxToggle('back');
                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: 'printMapIntro', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.inventoryListIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.inventoryListIntro.foresterMobile : Text.inventoryListIntro.forester :
                                this.props.mobile ? Text.inventoryListIntro.ownerMobile : Text.inventoryListIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                        <div
                            className={classes.invListBox}
                        >
                            SE HER ⬆️
                        </div>
                    </div>
                }
                break;

            case 'printMapIntro':
                // Z index, color logic and sidebar content
                this.props.onInvListZidxToggle('back');
                this.props.onPrintMapZidxToggle('front');

                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: 'findPosIntro', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.printMapIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.printMapIntro.foresterMobile : Text.printMapIntro.forester :
                                this.props.mobile ? Text.printMapIntro.ownerMobile : Text.printMapIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                        <div
                            className={classes.printMapBox}
                        >
                            SE HER ⬆️
                        </div>
                    </div>
                }
                break;

            case 'findPosIntro':
                // Skip next step if not forester
                stageName = userRole === 'forester' ? 'searchIntro' : 'end';
                // Z index, color logic and sidebar content
                this.props.onPrintMapZidxToggle('back');
                this.props.onMapSelecterZidxToggle('back');
                this.props.onFindPosZidxToggle('front');

                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: stageName, icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.findPosIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.findPosIntro.foresterMobile : Text.findPosIntro.forester :
                                this.props.mobile ? Text.findPosIntro.ownerMobile : Text.findPosIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            //margin={this.props.mobile ? `${(userRole === "forester" ? '284px' : '172px')} 0px 0px 0px` : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.findPosMapBox}
                            style={{ left: this.props.mobile ? 123 : 160, top: this.props.mobile ? 142 : 53, }}
                        >
                            {this.props.mobile ? "⬅️ SE HER" : "SE HER ⬆️"}
                        </div>
                    </div>
                }
                break;

            case 'searchIntro':
                // Z index, color logic and sidebar content
                this.props.onFindPosZidxToggle('back');
                this.props.onSearchBtnZidxToggle('front');

                // Buttons
                btns = [
                    { label: 'Næste', clicked: this.goToStage, stage: 'end', icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.searchIntro.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.searchIntro.foresterMobile : Text.searchIntro.forester :
                                this.props.mobile ? Text.searchIntro.ownerMobile : Text.searchIntro.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            //margin={this.props.mobile ? '263px 0px 0px 0px' : '0px'}
                            onExit={this.exit} />
                        <div
                            className={classes.searchMapBox}
                            style={{ left: this.props.mobile ? 123 : 262, top: this.props.mobile ? 199 : 53, }}
                        >
                            {this.props.mobile ? "⬅️ SE HER" : "SE HER ⬆️"}
                        </div>
                    </div >
                }
                break;

            case 'end':
                // Z index, color logic and sidebar content
                this.props.onSearchBtnZidxToggle('back');
                this.props.onFindPosZidxToggle('back');
                this.props.onSetWeatherWidgetActive(true);
                this.props.onSetNewsWidgetActive(true);
                this.props.onSetNotesWidgetActive(true);
                this.props.onSetCustomerWidgetActive(true);
                // Buttons
                btns = [
                    { label: 'Luk', clicked: this.exit, color: 'primary' },
                    //{ label: 'Næste', clicked: this.goForward, icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
                ];
                if (!this.state.checked) {
                    content = null;
                    this.setState({ checked: true })
                } else {
                    content = <div className={classes.background} style={{ paddingLeft: this.props.mobile ? 12 : '8%' }}>
                        <DialogBox
                            header={Text.end.header}
                            step={[this.state.stepNum, this.state.numSteps]}
                            text={userRole === "forester" ?
                                this.props.mobile ? Text.end.foresterMobile : Text.end.forester :
                                this.props.mobile ? Text.end.ownerMobile : Text.end.owner}
                            onboarded={showDialogboxExit}
                            buttons={btns}
                            mobile={this.props.mobile}
                            onExit={this.exit} />
                    </div>
                }

                // Set onboarded to true on Firebase
                const user = {
                    onboarded: true
                }
                this.props.onUserOnboarded(user);
                // Send event to Analytics 
                ReactGA.event({
                    category: 'Onboarding',
                    action: `User has been onboarded`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
                break;

            default: content = <div> Error </div>
        }

        // Return content from render
        return (
            content
        );
    }
};

const mapStateToProps = state => {
    return {
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        currentAuthUser: state.user.currentAuthUser,
        numNotes: state.layout.numNotes,
        config: state.appConfig,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetSkipOnboardingState: (s) => dispatch(actions.setSkipOnboardingState(s)),
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSetOnboardingActive: (s) => dispatch(actions.setOnboardingActive(s)),
        onZoomBtnZidxToggle: (s) => dispatch(actions.zoomBtnZidxToggle(s)),
        onMapLayerZidxToggle: (s) => dispatch(actions.mapLayerZidxToggle(s)),
        onForestSelecterZidxToggle: (s) => dispatch(actions.forestSelecterZidxToggle(s)),
        onMapSelecterZidxToggle: (s) => dispatch(actions.mapSelecterZidxToggle(s)),
        onDrawToolZidxToggle: (s) => dispatch(actions.drawToolZidxToggle(s)),
        onMeasToolZidxToggle: (s) => dispatch(actions.measToolZidxToggle(s)),
        onNewNoteBtnZidxToggle: (s) => dispatch(actions.newNoteBtnZidxToggle(s)),
        onSideBarZidxToggle: (s) => dispatch(actions.sideBarZidxToggle(s)),
        onInvListZidxToggle: (s) => dispatch(actions.invListZidxToggle(s)),
        onPrintMapZidxToggle: (s) => dispatch(actions.printMapZidxToggle(s)),
        onFindPosZidxToggle: (s) => dispatch(actions.findPosZidxToggle(s)),
        onSearchBtnZidxToggle: (s) => dispatch(actions.searchBtnZidxToggle(s)),

        onOnboardingBtnColorToggle: (s) => dispatch(actions.onboardingBtnColorToggle(s)),

        onSetDisableMapLayer: (s) => dispatch(actions.setDisableMapLayer(s)),
        onSetDisableDraw: (s) => dispatch(actions.setDisableDraw(s)),
        onSetDisableMeas: (s) => dispatch(actions.setDisableMeas(s)),
        onSetDisableCustomerSelect: (s) => dispatch(actions.setDisableCustomerSelect(s)),
        onSetDisableMapSelect: (s) => dispatch(actions.setDisableMapSelect(s)),
        onSetDisableFindPos: (s) => dispatch(actions.setDisableFindPos(s)),
        onSetDisableNewNote: (s) => dispatch(actions.setDisableNewNote(s)),

        onUserOnboarded: (user) => dispatch(actions.userOnboarded(user)),

        onSetCustomerWidgetActive: (s) => dispatch(actions.setCustomerWidgetActive(s)),
        onSetNotesWidgetActive: (s) => dispatch(actions.setNotesWidgetActive(s)),
        onSetWeatherWidgetActive: (s) => dispatch(actions.setWeatherWidgetActive(s)),
        onSetNewsWidgetActive: (s) => dispatch(actions.setNewsWidgetActive(s)),

        onSidebarOpen: (state) => dispatch(actions.sidebarOpen(state)),
    };
}

Onboarding.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Onboarding)));