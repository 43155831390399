import React, { useState } from "react";

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/InfoRounded';

import { getSpeciesList, getSpecieParameters } from '../../Inventory/Utilities/speciesUtil';


const styles = () => ({
    nonEditTextStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    speciesRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    speciesItem: {
        height: 14,
        width: 14,
        marginRight: 6,
        marginBottom: 2,
        borderRadius: 3,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 8px -8px 8px',
    },
    expandMoreContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '8px 16px 16px 16px',
    },
});

const createSpeciesList = (type, currentSpecie, scheme, extendedState, classes) => {
    // Get species list from full list based on type
    const spcList = getSpeciesList(type, scheme);
    // Format each entry and return list
    const newList = spcList.map(spc => {
        return speciesMenuItem(type, currentSpecie, spc, scheme, extendedState, classes);
    })
    return newList;
}

const speciesMenuItem = (type, currentSpecie, specie, scheme, extendedState, classes) => {
    // Get current specie data
    const curSpcPar = getSpecieParameters(currentSpecie, scheme);
    // Set info data
    let info = '';
    if (type === 'foliage' || type === 'needle') {
        info = 'trees';
    } else if (type === 'line') {
        info = 'line'
    }
    // Set default menu item to current specie
    let menuItem = currentSpecie === '' ? null : <MenuItem key={currentSpecie} info={info} value={currentSpecie}><div className={classes.speciesRow}><div className={type === 'line' ? classes.lineItem : classes.speciesItem} style={{ backgroundColor: curSpcPar.color }} />{currentSpecie}</div></MenuItem>
    // Check for all cases where the specie form the list is not the same as the current specie
    if (currentSpecie !== specie[0]) {
        if (!extendedState && specie[1].availability !== 'simple') {
            menuItem = null;
        } else {
            menuItem = <MenuItem key={specie[0]} info={info} value={specie[0]}><div className={classes.speciesRow}><div className={type === 'line' ? classes.lineItem : classes.speciesItem} style={{ backgroundColor: specie[1][scheme].color }} />{specie[0]}</div></MenuItem>
        }
    }
    return menuItem;
}

const SpeciesList = (props) => {
    const [openFoliage, setOpenFoliage] = useState(false);
    const [openNeedle, setOpenNeedle] = useState(false);
    const [openOther, setOpenOther] = useState(false);
    // Check for editable
    if (!props.editable) {
        if (props.selected !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {props.label}
                    </Typography >

                    <div className={props.classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {props.selected}
                        </Typography >

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            {props.endAdornment}
                        </Typography >
                    </div>
                </div>
            )
        } else {
            return null
        }
    } else {
        // Create species list
        const foliageList = createSpeciesList('foliage', props.selected, props.speciesScheme, openFoliage, props.classes);
        const needleList = createSpeciesList('needle', props.selected, props.speciesScheme, openNeedle, props.classes);
        const otherList = createSpeciesList('other', props.selected, props.speciesScheme, openOther, props.classes);
        return (
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControl
                    margin="normal"
                    fullWidth
                    required={props.required}
                >
                    <InputLabel htmlFor="select-content">
                        {props.inputLabel}
                    </InputLabel>
                    <Select
                        value={props.selected}
                        onChange={e => props.onChange(e.target.value)}
                        inputProps={{
                            name: 'species',
                            id: 'specie-simple',
                        }}
                    >
                        {(props.withDefault && props.selected !== '') && <MenuItem key={'default'} value={''}><i>Ingen</i></MenuItem>}
                        {/* Løv */}
                        {foliageList}
                        <div className={props.classes.expandMoreContainer}>
                            <Button
                                onClick={(e) => {
                                    setOpenFoliage(!openFoliage);
                                    e.stopPropagation();
                                }}
                                disabled={props.disableSpcBtn}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                {openFoliage ? "Se færre løvtræsarter" : "Se flere løvtræsarter"}
                                <ExpandMoreIcon style={{ transform: openFoliage ? 'rotate(180deg)' : null }} />
                            </Button>
                        </div>
                        {/* Nål */}
                        {needleList}
                        <div className={props.classes.expandMoreContainer}>
                            <Button
                                onClick={(e) => {
                                    setOpenNeedle(!openNeedle);
                                    e.stopPropagation();
                                }}
                                disabled={props.disableSpcBtn}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                {openNeedle ? "Se færre nåletræsarter" : "Se flere nåletræsarter"}
                                <ExpandMoreIcon style={{ transform: openNeedle ? 'rotate(180deg)' : null }} />
                            </Button>
                        </div>
                        {/* Andet */}
                        {otherList}
                        <div className={props.classes.expandMoreContainer}>
                            <Button
                                onClick={(e) => {
                                    setOpenOther(!openOther);
                                    e.stopPropagation()
                                }}
                                disabled={props.disableSpcBtn}
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                {openOther ? "Se færre muligheder" : "Se flere muligheder"}
                                <ExpandMoreIcon style={{ transform: openOther ? 'rotate(180deg)' : null }} />
                            </Button>
                        </div>
                    </Select>
                </FormControl>

                {props.toolTipText && <Tooltip key="tooltip-info" title={props.toolTipText} aria-label="spc-info">
                    <InfoIcon style={{ marginLeft: 6, marginBottom: 6, color: '#9A9A9A' }} />
                </Tooltip>}
            </div>
        )

    }
}

export default withStyles(styles)(SpeciesList);