import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    orgId: null,
    org: null,
    orgCustomers: null,
    orgTeams: null,
    orgUsers: null,
    orgTasks: null,
    orgTaskTypes: null,
    loading: false,
    orgList: null,
    error: null,
};



const fetchOrgStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchOrgSuccess = (state, action) => {
    return updateObject(state, {
        org: action.Org,
        orgId: action.orgId,
        orgCustomers: (action.Org && action.Org.customers) ? action.Org.customers : null,
        orgTasks: (action.Org && action.Org.taskPlanning) ? action.Org.taskPlanning.tasks : null,
        orgTaskTypes: (action.Org && action.Org.taskPlanning) ? action.Org.taskPlanning.taskTypes : null,
        loading: false
    });
};

const fetchOrgListStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchOrgListFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchOrgListSuccess = (state, action) => {
    return updateObject(state, {
        orgList: action.orgList,
        loading: false
    });
};

const fetchOrgUsersStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchOrgUsersFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchOrgUsersSuccess = (state, action) => {
    return updateObject(state, {
        orgUsers: { ...state.orgUsers, [action.orgUser.id]: action.orgUser },
        loading: false
    });
};

const fetchOrgTeamsStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchOrgTeamsFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const fetchOrgTeamsSuccess = (state, action) => {
    return updateObject(state, {
        orgTeams: action.orgTeams,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ORG_START: return fetchOrgStart(state, action);
        case actionTypes.FETCH_ORG_SUCCESS: return fetchOrgSuccess(state, action);

        case actionTypes.FETCH_ORG_LIST_START: return fetchOrgListStart(state, action);
        case actionTypes.FETCH_ORG_LIST_FAIL: return fetchOrgListFail(state, action);
        case actionTypes.FETCH_ORG_LIST_SUCCESS: return fetchOrgListSuccess(state, action);

        case actionTypes.FETCH_ORG_USERS_START: return fetchOrgUsersStart(state, action);
        case actionTypes.FETCH_ORG_USERS_FAIL: return fetchOrgUsersFail(state, action);
        case actionTypes.FETCH_ORG_USERS_SUCCESS: return fetchOrgUsersSuccess(state, action);

        case actionTypes.FETCH_ORG_TEAMS_START: return fetchOrgTeamsStart(state, action);
        case actionTypes.FETCH_ORG_TEAMS_FAIL: return fetchOrgTeamsFail(state, action);
        case actionTypes.FETCH_ORG_TEAMS_SUCCESS: return fetchOrgTeamsSuccess(state, action);

        default: return state;
    }
};

export default reducer;