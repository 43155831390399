import env from './buildConfig';

// ... Dev ...
let configGetStream = {
    apiKey: "kervs7h9z785",
    appId: "65445",
    adminId: "m2WLXVGnqUhhqE5y8nI5BFQQMAQ2",
    getTokenURL: "https://europe-west1-skovliv.cloudfunctions.net/getStreamToken",
};

// ... Prod ...
if (env.stage === "production") {
    configGetStream = {
        apiKey: "hyt7yzq82js7",
        appId: "65569",
        adminId: "m2WLXVGnqUhhqE5y8nI5BFQQMAQ2", //"Ch9hsMaH0NhGlNcRZA8MATF4FwJ2",
        getTokenURL: "https://europe-west1-skovlivprod.cloudfunctions.net/getStreamToken",
    };
}

export default configGetStream;

