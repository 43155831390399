import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import GoBackIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import SaveIcon from '@material-ui/icons/SaveRounded';

// Components
import OutlinedButton from '../UI/Buttons/OutlinedButton';
import * as actions from '../../store/actions/index';

const styles = theme => ({
    root: {
        textAlign: 'left',
    },
    textField: {
        width: '100%',
    },
    card: {
        position: 'relative',
        margin: 'auto',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});

export class NewFeatures extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            wish: '',
            submit: false,
            loading: false,
        };
    }

    componentDidUpdate() {
        // disable spinner, navigate back, and show snackbar
        if (this.props.successFeature) {
            this.setState({
                loading: false,
            })

            let path = "/"
            this.props.history.replace({
                pathname: path,
            });
        }
    }

    componentWillUnmount() {
        // Show snackbar if feature is saved
        if (this.props.successFeature) {
            this.props.onEnqueueSnackbar({
                message: 'Ønske er nu gemt!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
        }        
        
        // Set reducer to initial state
        this.props.onUnmountNewFeature();
    }

    goBackClickHandler = () => {
        // Choose which route to go back to
        let path = "/"
        this.props.history.replace({
            pathname: path,
        });
    }

    onButtonPress = () => {
        const { headline, wish } = this.state;

        this.setState({
            submit: true,
        })

        // Check if feature request is complete
        if (headline !== "" && wish !== "") {
            this.setState({
                loading: true,
            })

            // Upload feature to cloud 
            this.props.onSaveNewFeature(headline, wish, this.props.currentAuthUser.name, this.props.currentAuthUser.email);
            // Form email
            let content = {};
            content.title = headline;
            content.text = wish;
            content.userData = this.props.currentAuthUser; 
            this.props.onSendEmail('newFeatureRequest', this.props.currentAuthUser.id, '', content);

            // Send event to Analytics
            ReactGA.event({
                category: 'New feature',
                action: 'New feature request created',
                label: `User: ${this.props.currentAuthUser.name}`
            });
        }
    };

    navigation() {
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
            >
                <IconButton
                    onClick={this.goBackClickHandler}
                    style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                >
                    <GoBackIcon />
                </IconButton>

                <Typography
                    variant="h5"
                    gutterBottom
                >
                    Ønske
                </Typography >
            </div>
        )
    };

    intro() {
        return (
            <Typography
                variant="body1"
                gutterBottom
                style={{ whiteSpace: 'pre-line' }}
            >
                Her kan du tilføje ønsker til programmet. Disse ønsker vil blive gemt i systemet og evalueret på undervejs.
            </Typography>
        )
    }

    headline() {
        const { classes } = this.props;
        const { headline, submit } = this.state;

        return (
            <div>
                <TextField
                    id="headline"
                    label="Overskrift på ønske"
                    className={classes.textField}
                    value={headline}
                    onChange={event => this.setState({ headline: event.target.value })}
                    error={headline === "" && submit}
                    margin="normal"
                />
            </div>
        )
    };

    wish() {
        const { classes } = this.props;
        const { wish, submit } = this.state;

        return (
            <TextField
                id="wish"
                label="Beskrivelse af ønske"
                multiline
                className={classes.textField}
                value={wish}
                onChange={event => this.setState({ wish: event.target.value })}
                error={wish === "" && submit}
                margin="normal"
            />
        );
    };

    save() {
        const { classes } = this.props;
        return (
            <OutlinedButton
                buttonLabel={'Gem'}
                buttonIcon={<SaveIcon className={classes.extendedIcon} />}
                iconLeft={true}
                onPress={this.onButtonPress.bind(this)}
                loading={this.state.loading}
                color="primary"
            />
        );
    }

    render() {
        const { classes } = this.props;
        
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        {this.navigation()}
                        {this.intro()}
                        {this.headline()}
                        {this.wish()}
                    </CardContent>
                    <CardActions>
                        {this.save()}
                    </CardActions>
                </Card>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        successFeature: state.feature.successFeature,
        currentAuthUser: state.user.currentAuthUser,
        sidebarState: state.layout.sidebarOpen,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveNewFeature: (headline, wish, userName, userEmail) => dispatch(actions.saveNewFeature(headline, wish, userName, userEmail)),
        onUnmountNewFeature: () => dispatch(actions.unmountNewFeature()),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onSendEmail: (type, userId, customerEmail, content) => dispatch(actions.sendEmail(type, userId, customerEmail, content)),
        onSidebarOpen: (state) => dispatch(actions.sidebarOpen(state)),
    };
}

NewFeatures.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(NewFeatures)));