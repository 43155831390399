
import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';

// Material UI Icons
import InfoIcon from '@material-ui/icons/InfoRounded';

const InfoMenu = (props) => {
    return (
        <div style={{ display: 'flex' }}>
            <InfoIcon style={{ marginRight: 4, color: '#9A9A9A' }} />

            <Typography
                variant="caption"
                color="textSecondary"
                style={{ margin: props.margin }}
            >
                {props.content}
            </Typography>
        </div>
    );
}

export default InfoMenu;





