import env from './buildConfig';

// ... Dev ...
let configFirebase = {
    apiKey: "AIzaSyCkaONsSRzheP84wXwzUwFVT-w-2NEJZEg",
    authDomain: "skovliv.firebaseapp.com",
    databaseURL: "https://skovliv.firebaseio.com",
    projectId: "skovliv",
    storageBucket: "skovliv.appspot.com",
    economyDocsBucket: "skovliv_forest_economy_docs",
    documentsBucket: "skovliv_forestdocs",
    messagingSenderId: "1001848781966",
    appId: "1:1001848781966:web:9a5b20fce6ebc625"
};

// ... Prod ...
if (env.stage === "production") {
    configFirebase = {
        apiKey: "AIzaSyAai-cvIdodAM3T5GYibYbxFmjedI-hh9Q",
        authDomain: "skovlivprod.firebaseapp.com",
        databaseURL: "https://skovlivprod.firebaseio.com",
        projectId: "skovlivprod",
        storageBucket: "skovlivprod.appspot.com",
        economyDocsBucket: "skovlivprod_forest_economy_docs",
        documentsBucket: "skovlivprod_forestdocs",
        messagingSenderId: "256893011801",
        appId: "1:256893011801:web:aeae03a466275608",
    };
}

export default configFirebase;