import React from "react";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// Material UI Icons
import InfoIcon from '@material-ui/icons/InfoRounded';

import { getRegistrationsList } from "./Utilities/registrationsUtil";

import UpgradeMenu from '../UI/Menus/UIElements/UpgradeMenu';

const styles = () => ({
    nonEditTextStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

const DropDownMenu = (props) => {
    // Check for editable
    if (!props.editable) {
        if (props.selected !== '' && props.selected !== 'Ingen') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {props.label}
                    </Typography >

                    <div className={props.classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {props.selected}
                        </Typography >

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            {props.endAdornment}
                        </Typography >
                    </div>
                </div>
            )
        } else {
            return null
        }
    } else {
        if (props.topic === 'categories') {
            const menuItemsCategories = Object.entries(props.content).map((item, idx) => {
                if (props.type === 'Polygon' && item[1].geometries.includes('polygon')) {
                    return (
                        <MenuItem key={'cat-' + idx}
                            value={item[0]}
                            disabled={props.freeAccount && item[0] !== 'Drift'}
                        >
                            {item[0]}
                        </MenuItem>
                    )
                } else if (props.type === 'LineString' && item[1].geometries.includes('line')) {
                    return (
                        <MenuItem key={'cat-' + idx}
                            value={item[0]}
                            disabled={props.freeAccount && item[0] !== 'Drift'}
                        >
                            {item[0]}
                        </MenuItem>
                    )
                } else if (props.type === 'Point' && item[1].geometries.includes('point')) {
                    return (
                        <MenuItem key={'cat-' + idx}
                            value={item[0]}
                            disabled={props.freeAccount && item[0] !== 'Drift'}
                        >
                            {item[0]}
                        </MenuItem>
                    )
                }
            })
            return (
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <FormControl
                        margin="normal"
                        fullWidth
                        required={props.required}
                    >
                        <InputLabel htmlFor="select-content">
                            {props.inputLabel}
                        </InputLabel>

                        <Select
                            value={props.selected}
                            onChange={event => { props.onPress(event.target.value) }}
                            displayEmpty
                            inputProps={{
                                name: props.topic + '-content',
                                id: props.topic + '-select'
                            }}
                        >
                            {menuItemsCategories}

                            {(props.freeAccount && props.upgradeBox !== 'hidden') && <MenuItem
                                value='upgrade'
                            >
                                <div style={{ maxWidth: '100%' }}>
                                    <UpgradeMenu
                                        title='Brug for flere anvendelsesformer?'
                                        content='Opgrader og få adgang til flere anvendelsesformer.'
                                        cta='Opgrader'
                                    />
                                </div>
                            </MenuItem>}
                        </Select>
                    </FormControl>

                    <Tooltip key="categories" title={props.type === 'Point' ? "Anvendelsesform for punkt. Valget vil være udslagsgivende ift. hvilket lag punktet optræder i" : "Anvendelsesform for litra. Alle former, bortset fra ”Øvrige”, vil tælle med i det samlede skovareal"} aria-label="categories">
                        <InfoIcon style={{ marginLeft: 6, marginBottom: 6, color: '#9A9A9A' }} />
                    </Tooltip>
                </div>
            )

        } else {
            // Check geometry type
            let geoType = 'polygon';
            if (props.type === 'LineString') {
                geoType = 'line';
            } else if (props.type === 'Point') {
                geoType = 'point';
            }
            if (props.categoryType) {
                const fullList = getRegistrationsList(props.categoryType, geoType)
                // Find list based on category type
                const list = fullList.map((item, idx) => {
                    return (
                        <MenuItem key={'reg-' + idx}
                            value={item[0]}
                        >
                            {item[0]}
                        </MenuItem>
                    )
                })
                let displayList = list;
                if (props.selected !== '') {
                    displayList = [<MenuItem key={'reg-non'}
                        value={''}
                    >
                        <i>Ingen</i>
                    </MenuItem>, ...list];
                }
                // Form output
                return (
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl
                            margin="normal"
                            fullWidth
                            required={props.required}
                        >
                            <InputLabel htmlFor="select-content">
                                {props.inputLabel}
                            </InputLabel>

                            <Select
                                value={props.selected}
                                onChange={event => { props.onPress(event.target.value) }}
                                displayEmpty
                                inputProps={{
                                    name: props.topic + '-content',
                                    id: props.topic + '-select'
                                }}
                            >
                                {displayList}
                                {/* 
                                {keyBiotopeList.length > 0 && <Typography
                                    variant="body1"
                                    style={{ fontWeight: 500, marginLeft: 16, marginRight: 16 }}
                                >
                                    Nøglebiotoper
                                </Typography>}
                                {keyBiotopeList}

                                {keyBiotopeList.length > 0 && (keyElementList.length !== 0 || protectedNatureList.length !== 0 || ancientList.length !== 0) && <Divider style={{ marginBottom: 6 }} />}

                                {keyElementList.length > 0 && <Typography
                                    variant="body1"
                                    style={{ fontWeight: 500, marginLeft: 16, marginRight: 16 }}
                                >
                                    Nøgleelementer
                                </Typography>}
                                {keyElementList}

                                {keyElementList.length > 0 && (protectedNatureList.length !== 0 || ancientList.length !== 0) && <Divider style={{ marginBottom: 6 }} />}


                                {protectedNatureList.length > 0 && <Typography
                                    variant="body1"
                                    style={{ fontWeight: 500, marginLeft: 16, marginRight: 16 }}
                                >
                                    Beskyttet natur
                                </Typography>}
                                {protectedNatureList}

                                {protectedNatureList.length > 0 && ancientList.length !== 0 && <Divider style={{ marginBottom: 6 }} />}


                                {ancientList.length > 0 && <Typography
                                    variant="body1"
                                    style={{ fontWeight: 500, marginLeft: 16, marginRight: 16 }}
                                >
                                    Fortidsminder
                                </Typography>}
                                {ancientList}*/}
                            </Select>
                        </FormControl>

                        <Tooltip key="registrations" title="Tilføj eventuelt registreringsinfo til anvendelsesformer som; urørt skov, biodiversitetsskov, værdifuld skov, nøglebiotoper, nøgleelementer, …" aria-label="registrations">
                            <InfoIcon style={{ marginLeft: 6, marginBottom: 6, color: '#9A9A9A' }} />
                        </Tooltip>
                    </div>

                )
            }
        }
    }
}

export default withStyles(styles)(DropDownMenu);