import React from 'react';

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import NewFeatures from '../components/NewFeatures/NewFeatures';

const overviewMapNewFeatures = (props) => {
    return (
        <Aux>
            <SideBar cardContent={
                <div>
                    <NewFeatures />
                </div>
            } />
        </Aux>
    );
}

export default overviewMapNewFeatures;