import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
})

class Contact extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <ExpansionPanel style={{ borderRadius: 0, borderWidth: 0, borderColor: 'white', boxShadow: 'none', }}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ paddingLeft: 16, paddingRight: 16 }}
                        >
                            <Typography className={classes.heading}>
                                Kontakt
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails style={{ paddingLeft: 16, paddingRight: 16 }}>
                            <div style={{ flexDirection: 'column' }}>
                                <div>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        E-mail
                                    </Typography >
                                    <div>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            style={{ whiteSpace: 'pre-line' }}
                                        >
                                            info@skovkortet.dk
                                        </Typography >
                                    </div>
                                </div>

                                <div>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Telefon
                                    </Typography >
                                    <div>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            style={{ whiteSpace: 'pre-line' }}
                                        >
                                            +45 61606496
                                        </Typography >
                                    </div>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Contact);