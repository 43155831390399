import React from 'react';

import Aux from '../hoc/Aux/Aux';
//import OverviewMap from '../maps/OverviewMap';
import SideBar from '../components/SideBar/SideBar';
import Notes from '../components/Notes/NotesWidget';

const overviewMapNotes = (props) => {
    return (
        <Aux>
            <SideBar cardContent={
                <div>
                    <Notes full={true} showBackButton={true}/>
                </div>
            }/>
        </Aux>
    );
}

export default overviewMapNotes;

