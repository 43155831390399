
import React from 'react';
// import ReactWeather, { useOpenWeather } from 'react-open-weather';
import ReactWeather, { useOpenWeather } from '../../../../components/WeatherWidget/index';
import 'moment/locale/da';
import configOpenWeather from '../../../../backend/configOpenWeather';

const customStyles = {
    fontFamily: 'Helvetica, sans-serif',
    gradientStart: '#5abf34',
    gradientMid: '#387125',
    gradientEnd: '#1C3812',
    locationFontColor: '#FFF',
    todayTempFontColor: '#FFF',
    todayDateFontColor: '#FFF',
    todayRangeFontColor: '#FFF',
    todayDescFontColor: '#FFF',
    todayInfoFontColor: '#FFF',
    todayIconColor: '#FFF',
    forecastBackgroundColor: '#FFF',
    forecastSeparatorColor: '#DDD',
    forecastDateColor: '#777',
    forecastDescColor: '#777',
    forecastRangeColor: '#777',
    forecastIconColor: '#387125',
};

const weatherApp = (props) => {
    // Input checking
    let label = props.locationLabel ? props.locationLabel : 'Danmark';
    let lat = props.lat ? props.lat : '55.727618';
    let lon = props.lon ? props.lon : '9.473497';

    const { data, isLoading, errorMessage } = useOpenWeather({
        key: configOpenWeather.apiKey,
        lat: lat,
        lon: lon,
        lang: 'da',
        unit: 'metric',
    });

    return (
        <ReactWeather
            theme={customStyles}
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="da"
            locationLabel={label}
            unitsLabels={{ temperature: 'C', windSpeed: 'm/s' }}
            showForecast
        />
    )
}

export default weatherApp;