import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    User: null,
    currentAuthUser: null,
    successUser: false,
    userTerms: null,
    error: null,
    errorCode: '',
    errorCodeSignUp: '',
    loading: false,
    loadingSendTodo: false,
    sendTodoSuccess: false,
    errorSendTodo: null,
    loadingSendIntegration: false,
    sendIntegrationSuccess: false,
    errorSendIntegration: null,
    signupInProgress: false,
    loadingRemoveUserFromAudience: false,
    errorRemoveUserFromAudience: null,
    updateUserProfile: false,
};

const authUser = (state, action) => {
    return updateObject(state, {
        User: action.user,
    });
};

const currentUser = (state, action) => {
    return updateObject(state, {
        currentAuthUser: action.currentAuthUser,
    });
}

const noAuthUser = (state, action) => {
    return updateObject(state, {
        User: action.user,
    });
};

const setUpdateUserProfile = (state, action) => {
    return updateObject(state, {
        updateUserProfile: action.state,
    })
}

const userSignInStarted = (state, action) => {
    return updateObject(state, {
        errorCode: '',
        errorCodeSignUp: '',
    })
}

const loginFail = (state, action) => {
    return updateObject(state, {
        errorCode: action.errorCode,
    })
}

const unmountSaveUserProfile = ( state, action ) => {
    return updateObject( state, {
        successUser: false
    });
};

const fetchTermsSuccess = ( state, action ) => {
    return updateObject( state, {
        userTerms: action.userTerms
    });
}

const setAcceptTermsStateStart = ( state, action ) => {
    return updateObject( state, {
        loading: true
    });
}

const setAcceptTermsStateFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
}

const setAcceptTermsStateSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false
    });
}

const setSignupReasonStart = ( state, action ) => {
    return updateObject( state, {
        loading: true
    });
}

const setSignupReasonFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
}

const setSignupReasonSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false
    });
}

// Create new user
const createNewUserStart = (state, action) => {
    return updateObject( state, {
        loading: true,
        signupInProgress: true,
    });
};

const createNewUserFail = (state, action) => {
    return updateObject( state, {
        errorCode: action.error,
        loading: false,
        signupInProgress: false,
    });
};

const createNewUserSuccess = (state, action) => {
    return updateObject( state, {
        loading: false,
        signupInProgress: false,
    });
};

// Update user data
const updateUserDataStart = (state, action) => {
    return updateObject( state, {
        loading: true,
    });
}

const updateUserDataFail = (state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
}

const updateUserDataSuccess = (state, action) => {
    return updateObject( state, {
        loading: false,
        successUser: true
    });
}

// Link user and org
const linkUserAndOrgStart = (state, action) => {
    return updateObject( state, {
        loading: true,
    });
}

const linkUserAndOrgFail = (state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
}

const linkUserAndOrgSuccess = (state, action) => {
    return updateObject( state, {
        loading: false,
    });
}

// Mail chimp functionality
const attachMailChimpStart = (state, action) => {
    return updateObject( state, {
        loading: true,
    });
}

const attachMailChimpFail = (state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error,
    });
}

const attachMailChimpSuccess = (state, action) => {
    return updateObject( state, {
        loading: false,
    });
}

const removeUserfromMailChimpAudienceListStart = (state, action) => {
    return updateObject( state, {
        loadingRemoveUserFromAudience: true,
    });
}

const removeUserfromMailChimpAudienceListSuccess = (state, action) => {
    return updateObject( state, {
        loadingRemoveUserFromAudience: false,
    });
}

const removeUserfromMailChimpAudienceListFail = (state, action) => {
    return updateObject( state, {
        loadingRemoveUserFromAudience: false,
        errorRemoveUserFromAudience: action.error,
    });
}

const addTagToUserfromMailChimpAudienceListStart = (state, action) => {
    return updateObject( state, {
        loadingRemoveUserFromAudience: true,
    });
}

const addTagToUserfromMailChimpAudienceListSuccess = (state, action) => {
    return updateObject( state, {
        loadingRemoveUserFromAudience: false,
    });
}

const addTagToUserfromMailChimpAudienceListFail = (state, action) => {
    return updateObject( state, {
        loadingRemoveUserFromAudience: false,
        errorRemoveUserFromAudience: action.error,
    });
}

// Todo email
const sendTodoEmailStart = (state, action) => {
    return updateObject( state, {
        loadingSendTodo: true,
    });
}

const sendTodoEmailSuccess = (state, action) => {
    return updateObject( state, {
        loadingSendTodo: false,
        sendTodoSuccess: true,
    });
}

const sendTodoEmailFail = (state, action) => {
    return updateObject( state, {
        loadingSendTodo: false,
        errorSendTodo: action.error,
    });
}

// Integration email
const sendIntegrationEmailStart = (state, action) => {
    return updateObject( state, {
        loadingSendIntegration: true,
    });
}

const sendIntegrationEmailSuccess = (state, action) => {
    return updateObject( state, {
        loadingSendIntegration: false,
        sendIntegrationSuccess: true,
    });
}

const sendIntegrationEmailFail = (state, action) => {
    return updateObject( state, {
        loadingSendIntegration: false,
        errorSendIntegration: action.error,
    });
}

const userResetError = (state, action) => {
    return updateObject( state, {
        error: null,
        errorSendTodo: null,
        sendTodoSuccess: false,
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_USER: return authUser(state, action);
        case actionTypes.CURRENT_USER: return currentUser(state, action);
        case actionTypes.NO_AUTH_USER: return noAuthUser(state, action);
        case actionTypes.SIGN_IN_STARTED: return userSignInStarted(state, action);
        case actionTypes.LOGIN_FAILED: return loginFail(state, action);
        case actionTypes.SET_UPDATE_USER_PROFILE: return setUpdateUserProfile(state, action);

        case actionTypes.FETCH_TERMS_SUCCESS: return fetchTermsSuccess(state, action);

        case actionTypes.UPDATE_USER_DATA_START: return updateUserDataStart(state, action);
        case actionTypes.UPDATE_USER_DATA_FAIL: return updateUserDataFail(state, action);
        case actionTypes.UPDATE_USER_DATA_SUCCESS: return updateUserDataSuccess(state, action);
        case actionTypes.UNMOUNT_SAVE_USER: return unmountSaveUserProfile(state, action);

        case actionTypes.SET_SIGNUP_REASON_START: return  setSignupReasonStart(state, action);
        case actionTypes.SET_SIGNUP_REASON_FAIL: return setSignupReasonFail(state, action);
        case actionTypes.SET_SIGNUP_REASON_SUCCESS: return setSignupReasonSuccess(state, action);

        case actionTypes.SET_ACCEPT_TERMS_STATE_START: return setAcceptTermsStateStart(state, action);
        case actionTypes.SET_ACCEPT_TERMS_STATE_FAIL: return setAcceptTermsStateFail(state, action);
        case actionTypes.SET_ACCEPT_TERMS_STATE_SUCCESS: return setAcceptTermsStateSuccess(state, action);

        case actionTypes.CREATE_NEW_USER_START: return createNewUserStart(state, action);
        case actionTypes.CREATE_NEW_USER_FAIL: return createNewUserFail(state, action);
        case actionTypes.CREATE_NEW_USER_SUCCESS: return createNewUserSuccess(state, action);

        case actionTypes.LINK_USER_AND_ORG_START: return linkUserAndOrgStart(state, action);
        case actionTypes.LINK_USER_AND_ORG_FAIL: return linkUserAndOrgFail(state, action);
        case actionTypes.LINK_USER_AND_ORG_SUCCESS: return linkUserAndOrgSuccess(state, action);

        case actionTypes.SEND_TODO_EMAIL_START: return sendTodoEmailStart(state, action);
        case actionTypes.SEND_TODO_EMAIL_FAIL: return sendTodoEmailFail(state, action);
        case actionTypes.SEND_TODO_EMAIL_SUCCESS: return sendTodoEmailSuccess(state, action);

        case actionTypes.SEND_INTEGRATION_EMAIL_START: return sendIntegrationEmailStart(state, action);
        case actionTypes.SEND_INTEGRATION_EMAIL_FAIL: return sendIntegrationEmailFail(state, action);
        case actionTypes.SEND_INTEGRATION_EMAIL_SUCCESS: return sendIntegrationEmailSuccess(state, action);

        case actionTypes.REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START: return removeUserfromMailChimpAudienceListStart(state, action);
        case actionTypes.REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL: return removeUserfromMailChimpAudienceListFail(state, action);
        case actionTypes.REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS: return removeUserfromMailChimpAudienceListSuccess(state, action);

        case actionTypes.ATTACH_MAIL_CHIMP_START: return attachMailChimpStart(state, action);
        case actionTypes.ATTACH_MAIL_CHIMP_FAIL: return attachMailChimpFail(state, action);
        case actionTypes.ATTACH_MAIL_CHIMP_SUCCESS: return attachMailChimpSuccess(state, action);

        case actionTypes.ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START: return addTagToUserfromMailChimpAudienceListStart(state, action);
        case actionTypes.ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL: return addTagToUserfromMailChimpAudienceListFail(state, action);
        case actionTypes.ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS: return addTagToUserfromMailChimpAudienceListSuccess(state, action);

        case actionTypes.USER_RESET_ERROR: return userResetError(state, action);
        
        default: return state;
    }
};

export default reducer;