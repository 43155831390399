import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

// Material UI icons
import NaturePeopleIcon from '@material-ui/icons/NaturePeopleRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import SearchIcon from '@material-ui/icons/SearchRounded';

// Components
import CircularSpinner from '../../CircularSpinner/CircularSpinner';
import NaturePeopleAvatar from '../../Avatars/NaturePeopleAvatar';
import OutlinedButton from '../../Buttons/OutlinedButton';
import { searchStringData } from '../../../DataSearch/Utility';
import * as actions from '../../../../store/actions/index';

const cookies = new Cookies();

const freeMaxNumberOfCustomers = 1;

const styles = theme => ({
    cardContent: {
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        marginBottom: 0,
        position: 'relative',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '400px',
        overflow: 'auto',
    },
    indicator: {
        backgroundColor: '#41842b'
    },
    button: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    // Search
    search: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0,
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
});

class CustomerWidget extends Component {
    constructor(props) {
        super(props);
        const customerWidgetState = cookies.get('customerWidgetState');
        this.state = {
            secondary: true,
            dense: true,
            rerender: false,
            tabValue: 0,
            tempValue: 0,
            numberOfCustomers: 0,
            searchString: '',
            open: customerWidgetState === 'close' ? false : true,
        };
    }

    onButtonPress = () => {
        // Navigate to new customer page
        this.props.history.push({
            pathname: '/Customers/NewCustomer',
            state: {
                prevPage: "newCustomer",
            }
        });
    };

    handleChangeCustomer(id) {
        // Check if forest is already part of customers list
        if (this.props.customerIdList.indexOf(id) === -1) {
            // Not part of, so attach
            this.props.onAttachCustomerToUser(id)
        } else {
            // Part of, so remove
            if (id === this.props.selectedForest) {
                this.props.onSetCurrentlySelectedForestId(null);
            }
            this.props.onRemoveCustomerFromUser(id)
        }
        this.props.onSetTaskPlanningFilterState(null)
    }

    createCustomerListContent = (props, divider) => {
        let inUserList = null;
        if (this.props.customerIdList.indexOf(props.id) !== -1 && this.state.tabValue === 1) {
            inUserList = "Tilføjet: Mine aktive kunder"
        }
        const prim =
            <Typography
                variant="subtitle1"
                noWrap
            >
                {props.forestName}
            </Typography>

        const sec =
            <Fragment>
                <Typography
                    variant="subtitle1"
                    noWrap
                    style={{ color: '#000000', paddingBottom: - 2 }}
                    component={'span'}
                >
                    {props.name}
                </Typography>
                <Typography
                    variant="caption"
                    noWrap
                    style={{ color: '#747474', display: 'block' }}
                    component={'span'}
                >
                    {inUserList}
                </Typography>
            </Fragment>

        return (
            <ListItem
                button
                divider={divider}
                component={Link}
                to={{
                    pathname: `/Customers/${props.id}`,
                    state: {
                        name: props.name,
                        forestName: props.forestName,
                        address: props.address,
                        city: props.city,
                        zip: props.zip,
                        phoneNumber: props.phoneNumber,
                        email: props.email,
                        note: props.note ? props.note : '',
                        date: props.date,
                        id: props.id,
                        prevPage: "customerList",
                    }
                }}
                style={{ paddingLeft: 6 }}
            >
                <ListItemAvatar>
                    <NaturePeopleAvatar />
                </ListItemAvatar>
                <ListItemText
                    primary={prim}
                    secondary={sec}
                />
                <ListItemSecondaryAction>
                    <Checkbox
                        color="primary"
                        style={{ marginLeft: 16 }}
                        checked={this.props.customerIdList.indexOf(props.id) === -1 ? false : true}
                        onClick={() => this.handleChangeCustomer(props.id)}
                        disabled={this.props.networkStatus === 'offline'}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        );
    }

    displayCustomersList = (customersList) => {
        // Check how many to show
        let N = customersList.length;
        // Sort customer list by alphabetic order
        let sortedCustomersList = customersList.sort((a, b) => a.forestName.localeCompare(b.forestName, 'da-DK'))

        sortedCustomersList = sortedCustomersList.slice(0, N);

        const list = sortedCustomersList.map((customer, i) => {
            let divider = i < N - 1;
            if (customer && customer.id) {
                return (
                    <div key={i}>
                        {this.createCustomerListContent(customer, divider)}
                    </div>
                )
            } else {
                return null;
            }

        });
        return list;
    }

    customersList(customersListDisplay) {
        const { classes, customerList, customerListOrg } = this.props;
        let cstArray = [];
        // Check if the user has any customers/forests associated
        if (this.state.tabValue === 0 && customerList === null && this.props.customerIdList.length < 1) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 53, display: 'flex', alignItems: 'center' }}>
                        <DialogContentText
                            style={{ marginTop: 8 }}
                        >
                            Der er ingen kunder tilknyttet din bruger
                        </DialogContentText>
                    </div>
                </ExpansionPanelDetails>
            )
        } else if (this.state.tabValue === 1 && customerListOrg !== null && Object.keys(customerListOrg).length < 1) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 53, display: 'flex', alignItems: 'center' }}>
                        <DialogContentText
                            style={{ marginTop: 8 }}
                        >
                            Der er ingen kunder tilknytte virksomheden
                        </DialogContentText>
                    </div>
                </ExpansionPanelDetails>
            )
        }
        // Check if data is being loaded
        if (this.state.tabValue === 0 && customerList === null) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
                        <CircularSpinner />
                    </div>
                </ExpansionPanelDetails>
            );
        } else if (this.state.tabValue === 1 && customerListOrg === null) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
                        <CircularSpinner />
                    </div>
                </ExpansionPanelDetails>
            );
        }
        // Return if no customers present
        if (this.state.tabValue === 0 && customerList !== null) {
            cstArray = Object.values(customersListDisplay).map(el => el);
            if (cstArray.length < 1) {
                return (
                    <ExpansionPanelDetails className={classes.cardContent}>
                        <div style={{ height: 53, display: 'flex', alignItems: 'center' }}>
                            <DialogContentText
                                style={{ marginTop: 8 }}
                            >
                                Der er ingen gemte kunder
                            </DialogContentText>
                        </div>
                    </ExpansionPanelDetails>
                )
            }
        } else if (this.state.tabValue === 1 && customerListOrg !== null) {
            cstArray = Object.values(customersListDisplay).map(el => el);
        }
        // If search string has more than 0ne character apply search filter
        cstArray = searchStringData(this.state.searchString, cstArray, ["forestName", "name"])

        return (
            <div>
                <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />

                <ExpansionPanelDetails className={classes.cardContent}>
                    <List dense={this.state.dense} style={{ padding: 0 }}>
                        {this.displayCustomersList(cstArray)}
                    </List>
                </ExpansionPanelDetails>

                <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />

                {/* {(cstArray.length > 5) && */}
                <Typography
                    variant="body2"
                    align="center"
                    style={{ color: '#6A6A6A', marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: this.props.config.newCustomerButton !== 'hidden' ? 0 : 16 }}
                >
                    Listen er scrollbar <InfoIcon style={{ marginBottom: -8, color: '#9A9A9A' }} />
                </Typography>
                {/* } */}

                {/* {(cstArray.length <= 3) && <div style={{ height: 16 }} />} */}
            </div>
        )
    }

    cardAction(disabled) {
        const { classes } = this.props;
        // Check if free user and disable add customers if needed
        let disableAddCustomer = !this.props.operationPlusActive && this.state.numberOfCustomers >= freeMaxNumberOfCustomers;
        let disableAddCustomerWefri = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))) {
            disableAddCustomerWefri = true;
        }

        return (
            <CardActions style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <OutlinedButton
                    buttonLabel={'Opret ny kunde'}
                    buttonIcon={<NaturePeopleIcon className={classes.extendedIcon} />}
                    iconLeft={true}
                    onPress={this.onButtonPress.bind(this)}
                    loading={this.state.loading}
                    color="primary"
                    disabled={disabled || disableAddCustomer || disableAddCustomerWefri || this.props.networkStatus === 'offline'}
                />
                {disabled && <Typography variant="caption" color="textSecondary">
                    Din konto afventer adgang fra jeres administrator.
                </Typography >}

                {disableAddCustomer && <Typography variant="caption" color="textSecondary">
                    Du kan kun oprette én kunde via gratis-abonnement.
                </Typography >}
                {disableAddCustomerWefri && <Typography variant="caption" color="textSecondary">
                    Kun skovfogeder har mulighed for at oprette kunder.
                </Typography >}
                {this.props.networkStatus === 'offline' && <Typography variant="caption" color="textSecondary">
                    Ikke muligt. Ingen forbindelse til internet.
                </Typography >}
            </CardActions>
        )
    }

    expandAction() {
        this.setState({ open: this.state.open ? false : true });

        cookies.set('customerWidgetState', this.state.open ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'CustomerWidget',
            action: `CustomerWidget: ${this.state.open ? 'close' : 'open'}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleChange = (event, valueInput) => {
        let tempVal = valueInput;
        // Disable in onboarding is active or user functionality is limited
        if (this.props.onboardingActive) {
            this.setState({ tabValue: 0, tempValue: 0 });
            return;
        }
        // Else continue
        if (this.state.tempValue !== tempVal) {
            if (tempVal === 1) {
                // Send event to Analytics
                ReactGA.event({
                    category: 'Customers',
                    action: `Org customers tap open`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            } else {
                // Send event to Analytics
                ReactGA.event({
                    category: 'Customers',
                    action: `User customers tap open`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            }
        }
        this.setState({ tabValue: tempVal, tempValue: tempVal, searchString: '' });
    };

    handleSearchStringChange = event => {
        this.setState({ searchString: event.target.value })
    };

    render() {
        const { classes } = this.props;
        // Set number of customers
        if (this.props.customerList) {
            if (this.state.numberOfCustomers !== Object.keys(this.props.customerList).length) {
                this.setState({ numberOfCustomers: Object.keys(this.props.customerList).length })
            }
        }

        let custList = this.props.customerList;
        if (this.state.tabValue === 1) {
            custList = this.props.customerListOrg;
        }

        // Disable org forest until user has been verified
        let disableOrgForests = false;
        if (this.props.currentAuthUser && this.props.currentAuthUser.orgAccess && this.props.currentAuthUser.orgAccess !== 'granted') {
            disableOrgForests = true;
        }

        if (this.props.onboardingActive && this.props.onboardingState === "newCustomerCreate" && !this.state.open) {
            this.setState({ open: true })
        }

        return (
            <div>
                <ExpansionPanel
                    expanded={this.state.open}
                    onChange={() => this.expandAction()}
                >
                    <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="h5"
                        >
                            {this.props.config.title ? this.props.config.title : "Kunder"}
                        </Typography >
                    </ExpansionPanelSummary>

                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        classes={{
                            indicator: classes.indicator
                        }}
                        textColor="primary"
                        aria-label="tabs kunder"
                        disabled={true}
                        style={{ marginLeft: 16, marginRight: 16 }}
                    >
                        <Tab
                            label={this.props.config.tabTitles ? this.props.config.tabTitles[0] : "Mine aktive kunder"}
                        />
                        <Tab
                            label={this.props.config.tabTitles ? this.props.config.tabTitles[1] : "Virksomhedens kunder"}
                            disabled={disableOrgForests}
                        />
                    </Tabs>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>

                        <Tooltip title="Søg efter kunder eller skove">
                            <InputBase
                                style={{ margin: '2px 0px' }}
                                placeholder="Søg"
                                onChange={this.handleSearchStringChange}
                                value={this.state.searchString}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Tooltip>
                    </div>

                    {this.customersList(custList)}

                    {this.props.config.newCustomerButton !== 'hidden' && this.cardAction(disableOrgForests)}
                </ExpansionPanel>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        config: state.appConfig.customerSidebar,
        networkStatus: state.layout.networkStatus,
        org: state.org.org,
        orgId: state.org.orgId,
        selectedForest: state.customer.currentlySelectedForestId,
        currentAuthUser: state.user.currentAuthUser,
        customerListOrg: state.customer.customerListOrg,
        customerList: state.customer.customerList,
        customerIdList: state.customer.customerIdList,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        operationPlusActive: state.account.operationPlusActive,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetCustomerIdList: (idList) => dispatch(actions.setCustomerIdList(idList)),
        onSetCurrentlySelectedForestId: (forestId) => dispatch(actions.setCurrentlySelectedForestId(forestId)),
        onRemoveCustomerFromUser: (forestId) => dispatch(actions.removeCustomerFromUser(forestId)),
        onAttachCustomerToUser: (forestId) => dispatch(actions.attachCustomerToUser(forestId)),
        onSetTaskPlanningFilterState: (s) => dispatch(actions.setActivityFilterState(s)),
    };
}

CustomerWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerWidget)));


