import L from "leaflet";
import moment from 'moment';
import 'leaflet.pattern/dist/leaflet.pattern-src.js';
import '../Utility/leaflet-corridor';
import './leafletExtensions/LabelsMarker';
import './leafletExtensions/BoxPattern';
import './leafletExtensions/DashedStripesPattern';
import buffer from '@turf/buffer';
import getLineLength from '@turf/length';
import { getFullSpecieParameterList, getSpecieCategories } from '../../components/Inventory/Utilities/speciesUtil';
import { convertNewCellToGeoJson, placeLabelInsidePoly, LightenDarkenColor, lineIntersectsPoly } from "./UtilityFunctions";
import * as mapIcons from './mapIcons';
import 'leaflet-measure-path';
import 'leaflet.measure';

let geojsonArea = require('@mapbox/geojson-area');

export const createLayer = (map, par) => {
    // const pane = par.pane ? par.pane : 'default';
    const layerName = par.layerName ? par.layerName : 'default';
    const featSnapIgnore = par.featSnapIgnore ? par.featSnapIgnore : true;
    const pointsSnapIgnore = par.pointsSnapIgnore ? par.pointsSnapIgnore : true;
    const labelsSnapIgnore = par.labelsSnapIgnore ? par.labelsSnapIgnore : true;
    const clickHandler = par.clickHandler ? par.clickHandler : false;
    const editHandler = par.editHandler ? par.editHandler : false;
    const vertexRemovedHandler = par.vertexRemovedHandler ? par.vertexRemovedHandler : false;
    const vertexAddedHandler = par.vertexAddedHandler ? par.vertexAddedHandler : false;
    const markerDragStartHandler = par.markerDragStartHandler ? par.markerDragStartHandler : false;
    const markerDragEndHandler = par.markerDragEndHandler ? par.markerDragEndHandler : false;
    const pointDragStart = par.pointDragStart ? par.pointDragStart : false;
    const pointDragEnd = par.pointDragEnd ? par.pointDragEnd : false;

    // Create feature and labels layer and add to map
    const features = L.featureGroup().addTo(map, { name: layerName, type: 'features', snapIgnore: featSnapIgnore });
    const labels = L.featureGroup().addTo(map, { name: layerName, type: 'labels', snapIgnore: labelsSnapIgnore });
    const points = L.featureGroup().addTo(map, { name: layerName, type: 'points', snapIgnore: pointsSnapIgnore });
    const pointsLabels = L.featureGroup().addTo(map, { name: layerName, type: 'pointsLabels', snapIgnore: labelsSnapIgnore });


    // Add event handler to features layer
    if (clickHandler) {
        features.on('click', e => clickHandler(e));
        points.on('click', e => clickHandler(e));
        labels.on('click', e => clickHandler(e));
        pointsLabels.on('click', e => clickHandler(e));
    }
    if (editHandler) {
        features.on('pm:edit', e => {
            let coords = e.sourceTarget._latlngs;
            if (e.sourceTarget.options.new && e.sourceTarget.options.type === 'Line') {
                coords = [e.sourceTarget._latlngs];
            }
            const geo = convertNewCellToGeoJson(coords, e.sourceTarget.options.type);
            editHandler(e, geo)
        });
    }
    if (vertexAddedHandler) {
        features.on('pm:vertexadded', e => vertexAddedHandler(e));
    }
    if (vertexRemovedHandler) {
        features.on('pm:vertexremoved', e => vertexRemovedHandler(e));
    }
    if (markerDragStartHandler) {
        features.on('pm:markerdragstart', e => markerDragStartHandler(e));
    }
    if (markerDragEndHandler) {
        features.on('pm:markerdragend', e => markerDragEndHandler(e));
    }
    // Add event handlers to point layer
    if (pointDragStart) {
        points.on('dragstart', e => pointDragStart(e));
    }
    if (pointDragEnd) {
        points.on('dragend pm:markerdragend', e => pointDragEnd(e));
    }

    // Remove layers from map before returning (layer visibility will be handled else where)
    map.removeLayer(features);
    map.removeLayer(labels);
    map.removeLayer(points);
    map.removeLayer(pointsLabels);

    // Return layers
    return { features, labels, points, pointsLabels };
}

export const resetLayer = (layer, par) => {
    const type = par.type ? par.type : 'default';
    try {
        if (type === 'all') {
            layer.features.clearLayers();
            layer.points.clearLayers();
            layer.labels.clearLayers();
        } if (type === 'points') {
            layer.points.clearLayers();
            layer.pointsLabels.clearLayers();
        } else {
            layer.features.clearLayers();
            layer.labels.clearLayers();
        }
    } catch (error) {
        console.error("Error in reset layer! ", error)
    }
}

export const toggleLayerVisibility = (map, layer, state) => {
    if (state) {
        map.addLayer(layer.features.bringToFront());
        map.addLayer(layer.points.bringToFront());
        map.addLayer(layer.pointsLabels.bringToFront());
        map.addLayer(layer.labels.bringToFront());
    } else {
        map.removeLayer(layer.features);
        map.removeLayer(layer.points);
        map.removeLayer(layer.pointsLabels);
        map.removeLayer(layer.labels);
    }
}

export const mapFeatureToLayer = (feat) => {
    // Define layers array
    let layers = [];
    // First check for information in the species specifik content
    let staticCat = null;
    if (feat.cellData) {
        staticCat = getSpecieCategories(feat.cellData.species);
    } else {
        // TODO : Create point get static parameters
        // staticCat = getPointCategories(feat.pointType)
    }

    if (staticCat && staticCat.includes('hydro')) {
        layers.push('hydroLayer');
    }
    if (staticCat && staticCat.includes('roadsTrails')) {
        layers.push('roadsTrails');
    }
    if (staticCat && staticCat.includes('biotopePlan')) {
        layers.push('biotopePlan');
    }
    // Map from feature data
    if (feat.categories && feat.categories.includes('biodiversityArea')) {
        layers.push('biodiversityArea');
    }
    if (feat.categories && feat.categories.includes('intensiveArea')) {
        layers.push('intensiveArea');
    }
    if (feat.categories && feat.categories.includes('primevalForest')) {
        layers.push('primevalForest');
    }
    if (feat.categories && feat.categories.includes('protectedNature')) {
        layers.push('protectedNature');
    }
    if (feat.categories && feat.categories.includes('keyBiotope')) {
        layers.push('keyBiotope');
    }
    if (feat.categories && feat.categories.includes('naturalValuableForest')) {
        layers.push('naturalValuableForest');
    }
    if (feat.categories && feat.categories.includes('keyElements')) {
        layers.push('keyElements');
    }
    if (feat.categories && feat.categories.includes('public')) {
        layers.push('public');
    }
    if (feat.categories && feat.categories.includes('other')) {
        layers.push('other');
    }

    // If layers array is empty, that means that the layer must be standard forest
    if (layers.length === 0) {
        layers.push('production');
    }

    // Return layers array
    return layers;
}

export const addNewFeatureToLayer = (feat, layer, par, options) => {
    // Unpack parameters and options
    const pane = par.pane ? par.pane : 'pointsPane';
    const color = par.color ? par.color : feat.shape === 'Polygon' ? '#009CDF' : '#13b8ff';
    const weight = par.weight ? par.weight : 3;
    const allowSelfIntersection = par.allowSelfIntersection ? par.allowSelfIntersection : feat.shape === 'Polygon' ? false : true;

    const enquireSnackBar = options.enquireSnackBar ? options.enquireSnackBar : false;
    const environment = options.environment ? options.environment : 'forest';
    const dragEndEventListner = options.dragEndEventListner ? options.dragEndEventListner : false;

    // Variables
    let area = '';
    let lineLength = '';
    let geo = null;
    let latLngs = feat.layer._latlngs;

    // For polygons check if last line of the feature overlaps other polygon features in the layer
    if (feat.shape === 'Polygon') {
        if (environment === 'forest') {
            const lineSegment = [feat.layer._latlngs[0].slice(-1)[0], feat.layer._latlngs[0][0]];
            Object.values(layer._map._layers).forEach(lay => {
                if (lay.options.type && lay.options.type === 'Polygon' && lineIntersectsPoly(lineSegment, lay._latlngs)) {
                    if (enquireSnackBar) {
                        enquireSnackBar({
                            message: 'Tjek om der er polygon overlap.',
                            options: { key: new Date().getTime() + Math.random(), variant: 'info' }
                        })
                    }
                }
            })
        }
        // Insert polygon on layer
        L.polygon(feat.layer._latlngs[0], {
            new: true,
            type: 'Polygon',
            color: color,
            fillColor: color,
            weight: weight,
            allowSelfIntersection: allowSelfIntersection
        }).addTo(layer);
        // Set variables
        geo = convertNewCellToGeoJson(latLngs, 'Polygon');
        area = Math.round(geojsonArea.geometry(geo) / 10000 * 100) / 100;
    } else if (feat.shape === 'Line') {
        // Insert poly line
        L.polyline(feat.layer._latlngs, {
            new: true,
            type: 'Line',
            color: color,
            fillColor: color,
            weight: weight,
            allowSelfIntersection: allowSelfIntersection
        }).addTo(layer);
        // Set variables for return
        const geoJsonFeat = convertToGeoJsonFeature([latLngs], 'Line');
        lineLength = getLineLength(geoJsonFeat, { units: 'kilometers' }) * 1000;
        geo = geoJsonFeat.geometry;
    } else if (feat.shape === 'Marker') {
        // Insert point/marker
        let point = L.marker(feat.layer._latlng, {
            new: true,
            type: "Point",
            pane: pane,
            snapIgnore: true,
            snappable: false,
            draggable: true,
        }).addTo(layer);
        if (dragEndEventListner) {
            point.on('dragEnd', e => dragEndEventListner(e));
        }
        layer.pm.disable();
        latLngs = feat.layer._latlng;
    }

    // Return data for routing
    return { latLngs, area, lineLength, geo }

}

export const addFeatureToLayer = (feat, map, layer, labelsLayer, par, options) => {
    // Input checking

    // Unpack parameters
    let featPane = par.featPane ? par.featPane : null;
    const lineBufferPane = par.lineBufferPane ? par.lineBufferPane : null;
    const linePolyPadding = par.linePolyPadding ? par.linePolyPadding : 0;
    const toolTipPane = par.toolTipPane ? par.toolTipPane : null;
    const environment = par.environment ? par.environment : 'forest';
    // Unpack options
    const withToolTip = options.withToolTip ? options.withToolTip : false;
    const withLineLabels = options.withLineLabels ? options.withLineLabels : false;
    const scheme = options.scheme ? options.scheme : "skovkortet";
    const pointDragEnd = options.pointDragEnd ? options.pointDragEnd : false;

    // Get specie parameters
    // TODO : check for category (forest/Hunting/...)
    let spcFullPar = null;
    let spcPar = null;
    let coords = null;
    if (feat.pointType) {
        // TODO : Get point parameters (icon, etc)
    } else {
        spcFullPar = getFullSpecieParameterList(feat.cellData.species)
        spcPar = spcFullPar[scheme];
        if (spcPar.fill && spcPar.fill === 'transparent') {
            featPane = "polygonsTransparentPane";
        }
        // Convert latlng array to leaflet format
        coords = convertCoordinatesToLeafletFormat(feat.cellData.lnglat, feat.cellData.holes);
    }

    // Check for feature type (line/polygon)
    if (feat.pointType) {
        // Get point icon
        const ptrIcon = getPointIcon(feat);
        // Insert point feature
        let point = L.marker(feat.latlng, {
            pane: featPane,
            id: feat.id,
            environment: environment,
            snappable: false,
            snapIgnore: true,
            icon: ptrIcon,
            pointType: feat.pointType,
            title: feat.title,
            type: 'Point',
            category: (feat.categories && feat.categories.length > 0) ? feat.categories[0] : 'production',
        })
        // Check for tooltip
        if (withToolTip) {
            const tooltipText = createToolTipText(feat);
            point.bindTooltip(tooltipText, { pane: toolTipPane, sticky: true });
        }
        // Add point to layer
        point.addTo(layer)
        if (pointDragEnd) {
            point.on('dragend', e => pointDragEnd(e));;
        }
        // Insert labels
        insertPointLabel(feat, labelsLayer, { ...par, parentLeafletId: point._leaflet_id })
    } else if (feat.cellData.type === 'Line') {
        const styleProps = getLineStyleProps(feat, { ...par, spcPar }, options)
        let line = L.corridor(coords, {
            pane: featPane,
            color: styleProps.color,
            fillColor: styleProps.color,
            opacity: styleProps.opacity,
            id: feat.cellData.id,
            litra: feat.cellData.litra,
            mainSpecie: feat.cellData.species,
            type: "Line",
            lineCap: 'butt',
            snapIgnore: true,
            corridor: styleProps.weight,
            dashArray: styleProps.dashStyle,
            constColor: styleProps.color,
            category: (feat.categories && feat.categories.length > 0) ? feat.categories[0] : 'production',
        })
        // Check if there should be added a tooltip
        if (withToolTip) {
            const toolTipText = createToolTipText(feat);
            line.bindTooltip(toolTipText, { pane: toolTipPane, sticky: true });
        }
        // Add line to layer
        line.addTo(layer);
        // Create buffer polygon around line
        const geoJsonFeat = convertToGeoJsonFeature(line.getLatLngs(), 'Line');
        const buffered = buffer(geoJsonFeat, (0.001 * spcPar.weight * 1.15 + linePolyPadding), { units: 'kilometers', steps: 1 });
        let bufferPoly = L.geoJson(buffered, {
            pane: lineBufferPane,
            style: {
                "color": 'transparent',
                "weight": 1,
                "fillOpacity": styleProps.opacity,
                "opacity": 0,
                "fillColor": '#F5F5F5',
            },
            snapIgnore: false,
            lineCap: 'butt',
            type: 'linePoly',
        })
        // Add buffer to layer
        bufferPoly.addTo(layer);
        // Insert line labels
        if (withLineLabels) {
            insertLineLabel(feat, coords, labelsLayer, { ...par, parentLeafletId: line._leaflet_id }, { resolution: 10, offset: 4 })
        }
    } else if (feat.cellData.type === "Polygon") {
        // Get line color, dash array, weight and pattern style based on parameter settings and options
        const styleProps = getPolygonStyleProps(feat, map, { ...par, spcPar }, options)
        // Create polygon and insert on layer
        let poly = L.polygon(coords, {
            pane: featPane,
            color: styleProps.lineColor,
            dashArray: styleProps.lineStyle,
            fillColor: styleProps.fillColor,
            fillOpacity: styleProps.fillOpacity,
            weight: styleProps.lineWeight,
            id: feat.cellData.id,
            mainSpecie: feat.cellData.species,
            type: "Polygon",
            fillPattern: styleProps.pattern,
            category: (feat.categories && feat.categories.length > 0) ? feat.categories[0] : 'production',
        })
        // Check if there should be added a tooltip
        if (withToolTip) {
            const toolTipText = createToolTipText(feat);
            poly.bindTooltip(toolTipText, { pane: toolTipPane, sticky: true });
        }
        // Add polygon to layer
        poly.addTo(layer)

        // Insert polygon labels
        // TODO : BP HACK
        if (feat.cellData.species !== 'Afgrænsning') {
            insertPolygonLabel(feat, coords, labelsLayer, { ...par, spcPar, abbreviation: spcFullPar.abbreviation, parentLeafletId: poly._leaflet_id }, options)
        }
    } else {
        // Throw error type not supported
        throw Error("Type not supported!");
    }
}

export const leafletFeatureClickHandler = (feat, prevFeatId, par, options) => {
    // Unpack parameters and options
    const selectedWeight = par.selectedWeight ? par.selectedWeight : 3;
    const notSelectedWeight = par.notSelectedWeight ? par.notSelectedWeight : 1;
    const notSelectedTransparentWeight = par.notSelectedTransparentWeight ? par.notSelectedTransparentWeight : 1.5;
    const selectedFillOpacity = par.selectedFillOpacity ? par.selectedFillOpacity : 0;
    const notSelectedFillOpacity = par.notSelectedFillOpacity ? par.notSelectedFillOpacity : 0.9;
    const selectedDashArray = par.selectedDashArray ? par.selectedDashArray : '1,0';
    const notSelectedDashArray = par.notSelectedDashArray ? par.notSelectedDashArray : '5,5';
    const notSelectedColor = par.notSelectedColor ? par.notSelectedColor : '#5a5a5a';
    const notInFilterOpacity = par.notInFilterOpacity ? par.notInFilterOpacity : 0.3;


    const transparent = options.transparent ? options.transparent : false;
    const darkTheme = options.darkTheme ? options.darkTheme : false;
    const filterActive = options.filterActive ? options.filterActive : false;
    const filterIds = options.filterIds ? options.filterIds : false;

    // Set style of clicked feature
    if (prevFeatId !== feat.layer._leaflet_id) {
        if (feat.layer.options.type === 'point') {

        } else if (feat.layer.options.type === 'Line') {
            feat.layer.setStyle({ opacity: 1, color: LightenDarkenColor(feat.layer.options.constColor, -30) });

        } else if (feat.layer.options.type === 'Polygon') {
            // Set line color according to fill color of polygon
            const color = feat.layer.options.fillColor;
            feat.layer.setStyle({ weight: selectedWeight, fillOpacity: selectedFillOpacity, color: color, dashArray: selectedDashArray }).bringToFront();
        }
        if (prevFeatId && feat.layer._map._layers[prevFeatId]) {
            if (feat.layer._map._layers[prevFeatId].options.type === 'Line') {
                // Check if filter is active and if feature is in the filter
                let lineOpacity = notSelectedFillOpacity;
                if (filterActive) {
                    if (filterIds && !filterIds.includes(feat.layer._map._layers[prevFeatId].options.id)) {
                        lineOpacity = notInFilterOpacity;
                    }
                }
                feat.layer._map._layers[prevFeatId].setStyle({ opacity: lineOpacity, color: feat.layer._map._layers[prevFeatId].options.constColor })
            } else if (feat.layer._map._layers[prevFeatId].options.type === 'Polygon') {
                // Set style according to options and filter
                let polyOpacity = transparent ? selectedFillOpacity : notSelectedFillOpacity;
                if (feat.layer._map._layers[prevFeatId].options.fillPattern && polyOpacity === 0) {
                    polyOpacity = notSelectedFillOpacity;
                }
                let polyLineColor = transparent ? (darkTheme ? 'black' : 'white') : notSelectedColor;
                let polyLineDashArray = transparent ? selectedDashArray : notSelectedDashArray;
                let polyWeight = transparent ? notSelectedTransparentWeight : notSelectedWeight;
                if (filterActive) {
                    if (filterIds && filterIds.includes(feat.layer._map._layers[prevFeatId].options.id)) {
                        polyOpacity = 0.0;//notSelectedFillOpacity;
                        polyLineColor = feat.layer._map._layers[prevFeatId].options.fillColor;
                        polyWeight = 3;
                        feat.layer._map._layers[prevFeatId].bringToFront()
                    } else {
                        polyOpacity = (transparent && !feat.layer._map._layers[prevFeatId].options.fillPattern) ? 0 : notInFilterOpacity;
                    }
                }
                // TODO : BP HACK
                if (feat.layer._map._layers[prevFeatId].options.mainSpecie === 'Afgrænsning') {
                    polyWeight = 2.5;
                    polyOpacity = 0;
                    polyLineDashArray = '1,0';
                    polyLineColor = feat.layer._map._layers[prevFeatId].options.fillColor;

                } else if (feat.layer._map._layers[prevFeatId].options.mainSpecie === "14: Stubmark og efterafgrøder") {
                    polyWeight = 2.5;
                    polyOpacity = 0.1;
                    polyLineDashArray = '1,0';
                    polyLineColor = feat.layer._map._layers[prevFeatId].options.fillColor;
                }
                feat.layer._map._layers[prevFeatId].setStyle({ weight: polyWeight, fillOpacity: polyOpacity, color: polyLineColor, dashArray: polyLineDashArray })
            }
        }
    }
    // Return leaflet id of clicked feature (used to reset style of previously clicked element)
    return feat.layer._leaflet_id;
}

export const resetFeatureStyle = (featId, map, par, options) => {
    // Unpack parameters and options
    const selectedFillOpacity = par.selectedFillOpacity ? par.selectedFillOpacity : 0;
    const notSelectedFillOpacity = par.notSelectedFillOpacity ? par.notSelectedFillOpacity : 0.9;
    const notInFilterOpacity = par.notInFilterOpacity ? par.notInFilterOpacity : 0.3;
    const selectedDashArray = par.electedDashArray ? par.electedDashArray : '1,0';
    const notSelectedDashArray = par.notSelectedDashArray ? par.notSelectedDashArray : '5,5';
    const notSelectedColor = par.notSelectedColor ? par.notSelectedColor : '#5a5a5a';
    const notSelectedWeight = par.notSelectedWeight ? par.notSelectedWeight : 1;
    const notSelectedTransparentWeight = par.notSelectedTransparentWeight ? par.notSelectedTransparentWeight : 1.5;

    const transparent = options.transparent ? options.transparent : false;
    const darkTheme = options.darkTheme ? options.darkTheme : false;
    const filterActive = options.filterActive ? options.filterActive : false;
    const filterIds = options.filterIds ? options.filterIds : null;

    // Check for feature type
    if (map._layers[featId].options.type === "Point") {

    } else if (map._layers[featId].options.type === "Line") {
        let lineOpacity = notSelectedFillOpacity;
        if (filterActive) {
            if (filterIds && !filterIds.includes(map._layers[featId].options.id)) {
                lineOpacity = notInFilterOpacity;
            }
        }
        map._layers[featId].setStyle({ opacity: lineOpacity, color: map._layers[featId].options.constColor })
    } else if (map._layers[featId].options.type === "Polygon") {
        // Set style according to options and filter
        let polyOpacity = transparent ? selectedFillOpacity : notSelectedFillOpacity;
        if (map._layers[featId].options.fillPattern && polyOpacity === 0) {
            polyOpacity = notSelectedFillOpacity;
        }
        let polyLineColor = transparent ? (darkTheme ? 'black' : 'white') : notSelectedColor;
        let polyLineDashArray = transparent ? selectedDashArray : notSelectedDashArray;
        let polyWeight = transparent ? notSelectedTransparentWeight : notSelectedWeight;
        if (filterActive) {
            if (filterIds && filterIds.includes(map._layers[featId].options.id)) {
                polyOpacity = 0.0;//notSelectedFillOpacity;
                polyLineColor = map._layers[featId].options.fillColor;
                polyWeight = 3;
                map._layers[featId].bringToFront()
            } else {
                polyOpacity = (transparent && !map._layers[featId].options.fillPattern) ? 0 : notInFilterOpacity;
            }
        }
        // TODO : BP HACK
        if (map._layers[featId].options.mainSpecie === 'Afgrænsning') {
            polyWeight = 2.5;
            polyOpacity = 0;
            polyLineDashArray = '1,0';
            polyLineColor = map._layers[featId].options.fillColor;

        } else if (map._layers[featId].options.mainSpecie === "14: Stubmark og efterafgrøder") {
            polyWeight = 2.5;
            polyOpacity = 0.1;
            polyLineDashArray = '1,0';
            polyLineColor = map._layers[featId].options.fillColor;
        }
        map._layers[featId].setStyle({ weight: polyWeight, fillOpacity: polyOpacity, color: polyLineColor, dashArray: polyLineDashArray })
    }
}


const convertCoordinatesToLeafletFormat = (lnglats, holes) => {
    let full = [];
    let outer = [];
    // Convert outer polygon coordinates
    for (let i = 0; i < lnglats.length / 2; i++) {
        outer[i] = [lnglats[i * 2 + 1], lnglats[i * 2]];
    }
    full[0] = outer;
    // Insert holes
    if (holes) {
        const len = Object.keys(holes).length;
        for (let i = 0; i < len; i++) {
            let hole = [];
            const holeLnglats = holes[i].map(el => el);
            for (let j = 0; j < holeLnglats.length / 2; j++) {
                hole[j] = [holeLnglats[j * 2 + 1], holeLnglats[j * 2]]
            }
            full[i + 1] = hole;
        }
    }
    return full;
}

const convertToGeoJsonFeature = (lineLatLngs, type) => {
    const geo = convertNewCellToGeoJson(lineLatLngs, type);
    return {
        type: 'Feature',
        geometry: {
            type: geo.type,
            coordinates: geo.coordinates,
        },
        properties: {}
    }
}

const createToolTipText = (feat) => {
    // If feature is point format accordingly
    if (feat.pointType) {
        return "<b>" + feat.title + "</b><br>" + ntsText(feat.text)
    }
    // Create tooltip text based on feature data
    const header = "<b>" + feat.cellData.litra + "</b><br>";
    const label = feat.cellData.type === 'Line' ? "<b>Type </b>" : "<b>Art </b>";
    let areaText = feat.cellData.area ? "<b>Areal </b>" + feat.cellData.area.toString().split('.').join(',') + " ha<br>" : "";
    if (feat.cellData.type === 'Line') {
        areaText = feat.cellData.lineLength ? "<b>Længde </b>" + feat.cellData.lineLength.toFixed(0).toString().split('.').join(',') + " m<br>" : "";
    }
    const multiSpc = Object.keys(feat.cellData.speciesObjs).length > 1 ? " ..." : "";
    const speciesText = feat.cellData.species ? label + feat.cellData.species + multiSpc + "<br>" : "";
    const yearText = (feat.cellData.year && feat.cellData.year !== 9999 && feat.cellData.year !== 0) ? "<b>Årstal (alder) </b>" + feat.cellData.year + " (" + (moment().format("YYYY") - feat.cellData.year) + ")<br>" : "";
    const massText = feat.cellData.mass ? "<b>Vedmasse </b>" + feat.cellData.mass.toString().split('.')[0] + " m3<br>" : "";
    const toolTipText = header + areaText + speciesText + yearText + massText;

    return toolTipText;
}

const ntsText = (text) => {
    let wordArray = text.split(" ");
    let wordCount = wordArray.length;

    let lineOne = [];
    let lineTwo = [];

    if (wordCount <= 9) {
        for (let i = 0; i < wordCount; i++) {
            lineOne.push(wordArray[i])
        }
        return (lineOne.join(" "))
    } else if (wordCount <= 17) {
        for (let i = 0; i < wordCount; i++) {
            if (i <= 9) {
                lineOne.push(wordArray[i])
            } else {
                lineTwo.push(wordArray[i])
            }
        }
        return (lineOne.join(" ") + "<br>" + lineTwo.join(" "))
    }
    for (let i = 0; i < wordCount; i++) {
        if (i <= 9) {
            lineOne.push(wordArray[i])
        } else if (i <= 17) {
            lineTwo.push(wordArray[i])
        }
    }
    return (lineOne.join(" ") + "<br>" + lineTwo.join(" ") + "...")
}

const insertPointLabel = (feat, layer, par) => {
    const labelPane = par.labelPane ? par.labelPane : 'polygonsLabelsPane';
    const environment = par.environment ? par.environment : null;
    const parentLeafletId = par.parentLeafletId ? par.parentLeafletId : null;
    // Label content
    let labelContent = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>"
        +
        "<span style=white-space:nowrap>" + feat.title + "</span><br>";
    if (feat.title !== feat.pointType) {
        labelContent = labelContent + "<b><span style=white-space:nowrap>" + feat.pointType + "</span></b>";
    }
    labelContent = labelContent + "</span>";

    // Insert label
    L.labelsMarker(feat.latlng, {
        type: 'label',
        pane: labelPane,
        id: feat.id,
        parentLeafletId: parentLeafletId,
        environment: environment,
        category: (feat.categories && feat.categories.length > 0) ? feat.categories[0] : 'production',
        snapIgnore: true,
        title: feat.title,
        pointType: feat.pointType,
        zoomContent: [{
            type: 'point',
            level: 15,
            text: labelContent,
            fontSize: 10.5
        }]
    }).addTo(layer)
}

const insertLineLabel = (feat, latlngs, layer, par, options) => {
    // Unpack parameters
    const labelPane = par.labelPane ? par.labelPane : null;
    const parentLeafletId = par.parentLeafletId ? par.parentLeafletId : null;
    // Unpack options
    const res = options.resolution ? options.resolution : 6;
    let offset = options.offset ? options.offset : 0;
    // Dynamically check offset value based on line segments number
    if (latlngs[0].length < 2 * (offset + 1)) {
        offset = Math.ceil((latlngs[0].length) / 2 - 1) < 0 ? 0 : Math.ceil((latlngs[0].length) / 2 - 1);
    }
    // Set line labels
    for (let i = offset; i < latlngs[0].length - 1; i = i + res) {
        const tempLat = (latlngs[0][i][0] + latlngs[0][i + 1][0]) / 2;
        const tempLng = (latlngs[0][i][1] + latlngs[0][i + 1][1]) / 2;
        L.labelsMarker([tempLat, tempLng], {
            type: 'label',
            pane: labelPane,
            id: feat.cellData.id,
            parentLeafletId: parentLeafletId,
            category: (feat.categories && feat.categories.length > 0) ? feat.categories[0] : 'production',
            litraId: feat.cellData.litra,
            spcLabel: feat.cellData.species,
            snapIgnore: true,
            zoomContent: [{
                level: 15,
                text: "<span style=white-space:nowrap>" + feat.cellData.litra + "</span>",
                fontSize: 10.5
            }]
        }).addTo(layer)
    }
}

const insertPolygonLabel = (feat, latlngs, layer, par, options) => {
    // Unpack parameters
    const labelPane = par.labelPane ? par.labelPane : null;
    const parentLeafletId = par.parentLeafletId ? par.parentLeafletId : null;
    // const labelsZoomLevel = par.labelsZoomLevel ? par.labelsZoomLevel : 16;
    // const zoomLevel = par.zoomLevel ? par.zoomLevel : 0;
    const spcPar = par.spcPar ? par.spcPar : null;
    const abbreviation = par.abbreviation ? par.abbreviation : feat.cellData.species;

    // Unpack options
    const transparent = options.transparent ? options.transparent : false;

    // Initialize label content
    let labelContentZoomLevel1 = "<span style=position:absolute;left:-2px;top:8px><span style=white-space:nowrap>" + feat.cellData.litra + "</span>"
    // Check for transparent state
    if (transparent) {
        labelContentZoomLevel1 = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;><span style=white-space:nowrap>" + feat.cellData.litra + "</span>"
    }
    // Create other label input
    let spcLabel = abbreviation;
    // Check for multiple species in litra
    if (Object.keys(feat.cellData.speciesObjs).length > 1) {
        spcLabel = spcLabel + ' ...';
    }
    spcLabel = feat.cellData.litra !== spcLabel ? spcLabel : '';
    const yearLabel = (feat.cellData.year && feat.cellData.year !== 0 && feat.cellData.year !== 9999) ? feat.cellData.year : '';

    // Check for zoom level
    let labelContentZoomLevel2 = "<span style=position:absolute;left:-4px;top:-4px><span style=white-space:nowrap>" + feat.cellData.litra + "</span><br>"
        +
        "<b style=white-space:nowrap>" + spcLabel + "</b><br>"
        +
        yearLabel + "</span>";
    // Check for transparent
    if (transparent) {
        labelContentZoomLevel2 = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>"
            +
            "<span style=white-space:nowrap>" + feat.cellData.litra + "</span><br>"
            +
            "<b style=white-space:nowrap>" + spcLabel + "</b><br>"
            +
            yearLabel + "</span>"
        if (spcLabel === '') {
            labelContentZoomLevel2 = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>"
                +
                "<span style=white-space:nowrap>" + feat.cellData.litra + "</span><br>"
                +
                yearLabel + "</span>"
        }
    }

    // Find label placement
    const labelPlacement = placeLabelInsidePoly(latlngs, feat.cellData.bbox);
    L.labelsMarker(labelPlacement, {
        type: 'label',
        pane: labelPane,
        id: feat.cellData.id,
        parentLeafletId: parentLeafletId,
        litraId: feat.cellData.litra,
        category: (feat.categories && feat.categories.length > 0) ? feat.categories[0] : 'production',
        year: yearLabel,
        spcLabel: spcLabel,
        spcColor: spcPar.color,
        snapIgnore: true,
        zoomContent: [{
            level: 15,
            text: labelContentZoomLevel1,
            fontSize: 10.5
        },
        {
            level: 16,
            text: labelContentZoomLevel2,
            fontSize: 10.5
        }]
    }).addTo(layer)
}

const getPointIcon = (feat) => {
    if (feat.pointType) {
        switch (feat.pointType) {
            case 'Leve, yngle, eller rasteområde': return mapIcons.pointLeveYngleRasteIcon;
            case 'Natur': return mapIcons.pointNaturIcon;
            case 'Store træer': return mapIcons.pointStoreTraerIcon;
            case 'Særlige træer': return mapIcons.pointSaerligeTraerIcon;
            case 'Dødt ved': return mapIcons.pointDoedtVedIcon;
            case 'Vand': return mapIcons.pointVandIcon;
            case 'Fortid og kultur': return mapIcons.pointFortidsKulturIcon;
            case 'Stak': return mapIcons.pointStakkeIcon;
            case 'Udsigtspost': return mapIcons.pointUdsigtsIcon;
            case 'Legeplads': return mapIcons.pointLegepladsIcon;
            case 'Shelterplads': return mapIcons.pointShelterIcon;
            case 'Bålsted': return mapIcons.pointBaalstedIcon;
            case '11: Lærkepletter': return mapIcons.laerkIcon;
            case '12: Vibelavninger': return mapIcons.vibeIcon;
            case '23: Stenbunker': return mapIcons.stoneIcon;
            case '24: Redekassetype 1 - museædere': return mapIcons.birdhouse1Icon;
            case '24: Redekassetype 2 - eng- & agerfugle': return mapIcons.birdhouse2Icon;
            default: return mapIcons.pointOtherIcon
        }
    } else {
        return mapIcons.pointOtherIcon;
    }
}

const getLineStyleProps = (feat, par, options) => {
    let color = par.spcPar.color;
    let weight = par.spcPar.weight;
    let dashStyle = par.spcPar.dashStyle;
    let opacity = par.lineOpacity ? par.lineOpacity : 1;
    const notInFilterOpacity = par.notInFilterOpacity ? par.notInFilterOpacity : 0.3;

    const filterActive = options.filterActive ? options.filterActive : false;
    const filterIds = options.filterIds ? options.filterIds : null;
    if (filterActive) {
        if (filterIds && filterIds.includes(feat.cellData.id)) {
            // opacity = opacity;
        } else {
            opacity = notInFilterOpacity;
        }
    }

    return { color, weight, dashStyle, opacity }
}

const getPolygonStyleProps = (feat, map, par, options) => {
    let lineColor = par.polygonLineDefaultColor ? par.polygonLineDefaultColor : '#5a5a5a';
    let lineStyle = par.polygonLineDefaultStyle ? par.polygonLineDefaultStyle : '5,5';
    let fillOpacity = par.fillOpacity ? par.fillOpacity : 0.9;
    let fillColor = par.spcPar.fill ? par.spcPar.fill : par.spcPar.color;
    let lineWeight = feat.cellData.weight ? feat.cellData.weight : 1;
    let pattern = null;
    const notInFilterOpacity = par.notInFilterOpacity ? par.notInFilterOpacity : 0.3;

    const transparent = options.transparent ? options.transparent : false;
    const filterActive = options.filterActive ? options.filterActive : false;
    const filterIds = options.filterIds ? options.filterIds : null;

    // Get pattern inside polygon
    pattern = getPattern(feat, map, par, options)

    // Set polygon fill opacity
    fillOpacity = transparent ? (pattern ? 0.7 : 0) : par.fillOpacity;
    if (filterActive) {
        if (filterIds && filterIds.includes(feat.cellData.id)) {
            fillOpacity = transparent ? 0.0 : par.fillOpacity; //notSelectedFillOpacity
        } else {
            fillOpacity = transparent ? (pattern ? 0.2 : 0) : notInFilterOpacity;
        }
    }
    // Check to see if transparent state is true
    if (transparent) {
        // Set line color based on dark vs. light theme (depends on background layer)
        lineColor = 'black';
        if (!options.darkTheme) {
            lineColor = 'white';
        }
        if (filterActive && filterIds && filterIds.includes(feat.cellData.id)) {
            lineColor = par.spcPar.color;
            lineWeight = 3; //LineWeight
        } else {
            // Increase line weight
            lineWeight = 1.5;
        }
        // Set dasharray style
        lineStyle = '1,0';
    }
    // TODO : BP HACK
    if (feat.cellData.species === "Afgrænsning") {
        lineColor = par.spcPar.color;
        lineWeight = 2;
        lineStyle = '1,0';
        fillColor = par.spcPar.color;
        fillOpacity = 0;
    } else if (feat.cellData.species === "14: Stubmark og efterafgrøder") {
        lineColor = par.spcPar.color;
        lineWeight = 2;
        lineStyle = '1,0';
        fillColor = par.spcPar.color;
        fillOpacity = 0.15;
    }
    // return styles
    return { lineColor, lineStyle, lineWeight, fillColor, fillOpacity, pattern }
}

const getPattern = (feat, map, par, options) => {
    // Unpact parameters/options
    const transparent = options.transparent ? options.transparent : false;
    const categories = feat.categories ? feat.categories : null;
    // Check if feature should have any category
    if (!categories || categories.includes('production')) {
        return null;
    }

    let pattern = null;
    // Check if a transparent pattern should be created
    if (transparent) {
        // Check if pattern already exist
        pattern = Object.values(map._patterns).filter(pattern => (pattern.options.id === feat.cellData.id && pattern.options.transparent && (feat.categories && feat.categories[0] === pattern.options.type)));
        if (pattern.length === 0) {
            // Create new pattern
            pattern = createNewPattern(feat, par, options);
            if (pattern) {
                pattern.addTo(map);
            }
        } else {
            pattern = pattern[0];
        }
    } else {
        // Check if pattern already exist
        pattern = Object.values(map._patterns).filter(pattern => (pattern.options.id === feat.cellData.id && !pattern.options.transparent && (feat.categories && feat.categories[0] === pattern.options.type) && (feat.cellData.species === pattern.options.species) && (pattern.options.tempColor === par.spcPar.color)));
        if (pattern.length === 0) {
            // Create new pattern
            pattern = createNewPattern(feat, par, options, map);
            if (pattern) {
                pattern.addTo(map);
            }
        } else {
            pattern = pattern[0];
        }
    }
    return pattern;
}

const createNewPattern = (feat, par, options, map) => {
    const transparent = options.transparent ? options.transparent : false;
    const spaceOpacity = transparent ? 0 : 1;
    const outerFillOpacity = transparent ? 0 : 1;
    const color = transparent ? '#7f7f7f' : LightenDarkenColor(par.spcPar.color, -30);
    // Get feature categories
    const categories = feat.categories;
    if (categories && categories.includes('intensiveArea')) {
        return L.boxPattern({
            id: feat.cellData.id,
            transparent: transparent,
            type: 'intensiveArea',
            species: feat.cellData.species,
            tempColor: par.spcPar.color,
            innerWeight: 0,
            outerWeight: 0,
            innerFillColor: LightenDarkenColor(par.spcPar.color, -30),
            outerFillColor: par.spcPar.color,
            outerFillOpacity: outerFillOpacity,
            width: 24,
            height: 24,
        })
    } else if (categories && categories.includes('primevalForest')) {
        return new L.StripePattern({
            id: feat.cellData.id,
            transparent: transparent,
            type: 'primevalForest',
            species: feat.cellData.species,
            tempColor: par.spcPar.color,
            angle: 45,
            weight: 2,
            spaceWeight: 9,
            color: color,
            spaceColor: par.spcPar.color,
            opacity: 1,
            spaceOpacity: spaceOpacity
        })
    } else if (categories && categories.includes('biodiversityArea')) {
        return new L.dashedStripePattern({
            id: feat.cellData.id,
            transparent: transparent,
            type: 'biodiversityArea',
            species: feat.cellData.species,
            tempColor: par.spcPar.color,
            angle: 45,
            weight: 2,
            spaceWeight: 9,
            color: color,
            spaceColor: par.spcPar.color,
            opacity: 1,
            spaceOpacity: spaceOpacity,
            dashArray: '2,5'
        })
    } else {
        return null;
    }
    // TODO : insert other patterns
}



