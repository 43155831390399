import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    Notes: null,
    error: false,
    loading: false,
    successNote: false,
    deleteNote: false,
    insertNotes: false,
    insertPoints: false,
    noteEditable: false,
    noteEditableId: null,
    noteEditableLatLng: null,
    pointEditable: false,
    pointEditableId: null,
    pointEditableLatLng: null,
    noteToolTipActive: true,
    flyToNoteData: null,
    filterTag: '',
    filterTodoStatus: '',
    filterTodoMyTasks: false,
    filterTodoDistanceToMe: false,
};

const fetchNotesStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchNotesSuccess = ( state, action ) => {
    return updateObject( state, {
        Notes: {...state.Notes, [action.id]: action.Notes},
        loading: false,
        insertNotes: true,
    } );
};

const fetchNotesFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const saveNoteSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        successNote: true,
        // insertNotes: true,
        // insertPoints: true,
    } );
};

const saveNoteFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const saveNoteStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const unmountNote = ( state, action ) => {
    return updateObject( state, {
        error: null,
        successNote: false,
        deleteNote: false,
    } );
};

const deleteNoteSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        deleteNote: true,
    } );
};

const deleteNoteFail = ( state, action ) => {
    return updateObject( state, { loading: false, error: action.error } );
};

const deleteNoteStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const deleteNotesForest = ( state, action ) => {
    const updatedNotes = { ...state.Notes };
    delete updatedNotes[action.customerId];
    return updateObject(state, {
        Notes: updatedNotes,
    });
};

const setInsertNotesState = ( state, action ) => {
    return updateObject( state, { insertNotes: action.insertNotesState } );
};

const setInsertPointsState = ( state, action ) => {
    return updateObject( state, { insertPoints: action.insertPointsState } );
};

const setNoteEditable = ( state, action ) => {
    return updateObject( state, { 
        noteEditable: action.noteEditable,
        noteEditableId: action.noteEditableId
    } );
};

const setNoteEditableLatLng = ( state, action ) => {
    return updateObject( state, { 
        noteEditableLatLng: action.noteEditableLatLng, 
    } );
};

const setPointEditable = ( state, action ) => {
    return updateObject( state, { 
        pointEditable: action.pointEditable,
        pointEditableId: action.pointEditableId 
    } );
};

const setPointEditableLatLng = ( state, action ) => {
    return updateObject( state, { 
        pointEditableLatLng: action.pointEditableLatLng, 
    } );
};

const setNoteToolTipActive = ( state, action ) => {
    return updateObject( state, { 
        noteToolTipActive: action.noteToolTipActive,
        insertNotes: true,
    } );
};

const setFilterTag = ( state, action) => {
    return updateObject (state, {
        filterTag: action.filterTag,
        insertNotes: true,
    } );
}

const setFilterTodoMyTasks = ( state, action ) => {
    return updateObject (state, {
        filterTodoMyTasks: action.filterTodoMyTasks,
        insertNotes: true,
    } );
}

const setFilterTodoDistanceToMe = ( state, action ) => {
    return updateObject (state, {
        filterTodoDistanceToMe: action.filterTodoDistanceToMe,
    } );
}

const setFilterTodoStatus = ( state, action ) => {
    return updateObject (state, {
        filterTodoStatus: action.filterTodoStatus,
        insertNotes: true,
    } );
}

const setFlyToNoteData = ( state, action ) => {
    return updateObject (state, {
        flyToNoteData: action.flyToNoteData,
    } );
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NOTES_START: return fetchNotesStart(state, action);
        case actionTypes.FETCH_NOTES_SUCCESS: return fetchNotesSuccess(state, action);
        case actionTypes.FETCH_NOTES_FAIL: return fetchNotesFail(state, action);

        case actionTypes.SAVE_NOTE_START: return saveNoteStart(state, action);
        case actionTypes.SAVE_NOTE_SUCCESS: return saveNoteSuccess(state, action);
        case actionTypes.SAVE_NOTE_FAIL: return saveNoteFail(state, action);

        case actionTypes.UNMOUNT_NOTE: return unmountNote(state, action);

        case actionTypes.DELETE_NOTE_START: return deleteNoteStart(state, action);
        case actionTypes.DELETE_NOTE_SUCCESS: return deleteNoteSuccess(state, action);
        case actionTypes.DELETE_NOTE_FAIL: return deleteNoteFail(state, action);
        case actionTypes.DELETE_NOTES_FOREST: return deleteNotesForest(state, action);

        case actionTypes.SET_INSERT_NOTES: return setInsertNotesState(state, action);

        case actionTypes.SET_INSERT_POINTS: return setInsertPointsState(state, action);

        case actionTypes.SET_NOTE_EDITABLE: return setNoteEditable(state, action);
        case actionTypes.SET_NOTE_EDITABLE_LATLNG: return setNoteEditableLatLng(state, action);

        case actionTypes.SET_POINT_EDITABLE: return setPointEditable(state, action);
        case actionTypes.SET_POINT_EDITABLE_LATLNG: return setPointEditableLatLng(state, action);

        case actionTypes.SET_NOTE_TOOL_TIP_ACTIVE: return setNoteToolTipActive(state, action);

        case actionTypes.SET_FILTER_TAG: return setFilterTag(state, action);
        case actionTypes.SET_FILTER_TODO_MY_TASKS: return setFilterTodoMyTasks(state, action);
        case actionTypes.SET_FILTER_TODO_DISTANCE_TO_ME: return setFilterTodoDistanceToMe(state, action);
        case actionTypes.SET_FILTER_TODO_STATUS: return setFilterTodoStatus(state, action);

        case actionTypes.SET_FLY_TO_NOTE_DATA: return setFlyToNoteData(state, action);

        default: return state;
    }
};

export default reducer;