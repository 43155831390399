import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { SnackbarProvider } from 'notistack';

import App from './App';
import * as serviceWorker from './serviceWorker';
import notesReducer from './store/reducers/notesReducer';
import mapsReducer from './store/reducers/mapsReducer';
import userReducer from './store/reducers/userReducer';
import featureReducer from './store/reducers/featureReducer';
import onboardingReducer from './store/reducers/onboardingReducer';
import customerReducer from './store/reducers/customerReducer';
import orgReducer from './store/reducers/orgReducer';
import notifierReducer from './store/reducers/notifierReducer';
import appLayoutReducer from './store/reducers/appLayoutReducer';
import accountReducer from './store/reducers/accountReducer';
import huntingReducer from './store/reducers/huntingReducer';
import inventoryReducer from './store/reducers/inventoryReducer';
import activityReducer from './store/reducers/activityReducer';
import appConfigReducer from './store/reducers/appConfigReducer';
import biotopePlansReducer from './store/reducers/biotopePlansReducer';
import documentsReducer from './store/reducers/documentsReducer';

// Icons for snackbar
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    notes: notesReducer,
    maps: mapsReducer,
    user: userReducer,
    feature: featureReducer,
    onboarding: onboardingReducer,
    customer: customerReducer,
    org: orgReducer,
    notifications: notifierReducer,
    layout: appLayoutReducer,
    account: accountReducer,
    hunting: huntingReducer,
    inventory: inventoryReducer,
    activity: activityReducer,
    appConfig: appConfigReducer,
    biotopePlans: biotopePlansReducer,
    documents: documentsReducer,
});

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

const app = (
    <Provider store={store}>
        <SnackbarProvider
            dense={true}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            iconVariant={{
                success: <CheckCircleIcon style={{ marginRight: 6 }} />,
                error: <ErrorIcon style={{ marginRight: 6 }} />,
                warning: <WarningIcon style={{ marginRight: 6 }} />,
                info: <InfoIcon style={{ marginRight: 6 }} />,
            }}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
