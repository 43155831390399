import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Logo
// import logo from './skovkortet_logo_square_tagline.png';
// import logo from '../../assets/logos/logo.png';
import logo from '../../assets/logos/Skovkortet-logo.png'
// import logo from './skovkortet_xmas_logo_square.png';

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    },
    logo: {
        // height: '110px',
    },
});

export class Start extends React.Component {
    render() {
        const { classes } = this.props;
        
        return (
            <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={logo}
                        alt={'logo'}
                        className={classes.logo}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size='30px' className={classes.progress} />
                </div>

                <Button
                    onClick={() => window.location.reload()}
                    style={{ textTransform: 'none', color: '#2E2F30' }}
                >
                    <Typography style={{ fontSize: 13 }}>
                        Siden hentes… Klik her hvis det tager for lang tid!
                    </Typography>
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
}

Start.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Start)));