import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#7eca64',
      main: '#5EBD3E',
      dark: '#41842b',
    },
    secondary: {
      light: '#61cdff',
      main: '#009CDF',
      dark: '#006ead',
    },
    info: {
      light: '#61cdff',
      main: '#009CDF',
      dark: '#006ead',
    },
    warning: {
      light: '#ffeb4d',
      main: '#FFB900',
      dark: '#c78900',
    },
    error: {
      light: '#ff6e63',
      main: '#E23838',
      dark: '#e75f5f',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
