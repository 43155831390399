// Functionality for searching through a list of data objects and return thoese
// that live up to the search criterium

export const searchStringData = (searchString, dataArray, searchFields, options) => {
    // Input checking
    if (typeof searchString !== "string") throw new Error("Input <searchString> must be a string!");
    if (!Array.isArray(dataArray)) throw new Error("Input <dataArray> must be an array of objects!");
    if (!Array.isArray(searchFields)) throw new Error("Input <searchFields> must be an array of strings corresponding to entries in the dataArray objects!");

    // Unpack options
    let numCharForSearch = 1;
    if (options) {
        numCharForSearch = options.numChar ? options.numChar : 1;
    }
    
    // Initialize list to original list
    let foundList = [...dataArray];
    // Perform search filtering
    try {
        if (searchString !== '' && searchString.length >= numCharForSearch) {
            const srcStr = searchString.toLowerCase();
            foundList = foundList.filter( el => {
                return searchFields.some( field => {
                    const text = el[field].toLowerCase();
                    return text.indexOf(srcStr) !== -1;
                })
            })
        }   
    } catch (error) {
        console.warn("It was not possible to perform the search! Probably because the search fields does not match entries in the dataArray objects. Returning the original dataArray!")
    }
    return foundList;
}