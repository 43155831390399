import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'date-fns';
import moment from 'moment';
import ReactGA from 'react-ga';

// Chart
import { Line } from 'react-chartjs-2';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import Slider from '@material-ui/core/Slider';
import Chip from '@material-ui/core/Chip';

// Material UI Icons
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import IconCloudDownloadRounded from '@material-ui/icons/CloudDownloadRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import SaveIcon from '@material-ui/icons/SaveRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import TimelineIcon from '@material-ui/icons/TimelineRounded';

// Components
import OutlinedButton from '../Buttons/OutlinedButton';
import DeleteButton from '../Buttons/DeleteButton';
import TestEstimation from '../Modal/testEstimationModal';
import HistoryList from '../../HistoryList/HistoryList';
import UpgradeMenu from '../Menus/UIElements/UpgradeMenu';
import { getSpeciesList, getSpecieParameters, getSpeciesListBasedOnCategory } from '../../Inventory/Utilities/speciesUtil';
import * as actions from '../../../store/actions/index';

// Estimation
import { standAssessment } from '../../Inventory/Utilities/standAssesment';
import { getSpecieVolume, getSpecieCO2Equiv, getAreaCO2Equiv, simulate } from '../../Inventory/Utilities/VolumeFunctionsDK';
import { formatEstInput } from '../../Inventory/Utilities/utils';

// Auxillary functions
//import volumeFunction from '../../Inventory/VolumeFunctionsDK';
const cellColors = require('../../../maps/treeColors.json');
const yieldTable = require('../../Inventory/Utilities/yieldTables/YieldTable.json')
const _ = require("underscore");

// Other stuff
let regex = /^[0-9]*([,.][0-9]*)?$/;

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: '0px',
        paddingBottom: '0px',
        marginBottom: 8,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    extendedIcon: {
        marginLeft: theme.spacing.unit,
    },
    extendedIconAdd: {
        marginRight: theme.spacing.unit,
    },
    textField: {
        width: '100%',
    },
    nonEditTextStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    formControl: {
        width: '100%',
    },
    speciesRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    speciesItem: {
        height: 14,
        width: 14,
        marginRight: 6,
        marginBottom: 2,
        borderRadius: 3,
    },
    lineItem: {
        height: 4,
        width: 14,
        border: '1px solid #808080',
        marginRight: 6,
        marginBottom: 2,
        borderRadius: 3,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 8px -8px 8px',
    },
    buttonProgress: {
        color: '#5EBD3E',
        position: 'absolute',
        left: '50%',
        marginLeft: -12,
    },
    expansionPanelDetailsStyle: {
        paddingTop: 0,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 0,
        marginBottom: 0,
        position: 'relative',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '430px',
        overflow: 'auto',
    },
    expansionPanelSummaryRoot: {
        minHeight: 16,
    },
    expansionPanelSummaryContent: {
        margin: 0,
    },
    expansionPanelSummaryExpandIcon: {
        height: 2,
        width: 2,
        marginRight: -6,
    },
    menuContainer: {
        padding: '4px 20px',
    },
    // Label
    valueLabel: {
        top: -16,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    expandMoreContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '8px 16px 16px 16px',
    },
});

const initialState = {
    expanded: null,
    submit: false,
    saveLoading: false,
    deleteLoading: false,
    disabled: false,
    standAssessmentLoading: false,
    userRole: 'free',
    species: '',
    color: '',
    info: '',
    main: false,
    areaBearing: true,
    year: '',
    thinningArr: {},
    mix: '100',
    coverage: '',
    quality: '',
    provenance: '',
    source: '',
    assessmentMethod: '',
    height: '',
    diameter: '',
    treeCount: '',
    groundArea: '',
    mass: '',
    formNum: '',
    weight: 1,
    type: '',
    id: '',
    showWarning: false,
    warningIgnore: false,
    showStandAssesmentWarning: false,
    simData: null,
    tempSetSimData: true,
    deleteThinningItemId: null,
    showDeleteThinningItemWarning: false,
    estimationSteps: [0, 10],
    groundAreaPlotData: null,
    tempCO2Area_e: 0,
    openFoliage: false,
    openNeedle: false,
    openOther: false,
}

class ControlledExpansionPanels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
            submit: false,
            saveLoading: false,
            deleteLoading: false,
            disabled: false,
            standAssessmentLoading: false,
            userRole: this.props.userRole,
            species: ("species" in this.props.species) ? (this.props.species.species === 'Krat' ? 'Krat - KRA' : this.props.species.species === 'Anden anvendelse - ANN' ? 'Anden anvendelse - AAN' : this.props.species.species) : '',
            color: ("color" in this.props.species) ? this.props.species.color : '',
            info: ("info" in this.props.species) ? this.props.species.info : '',
            preInfo: ("info" in this.props.species) ? this.props.species.info : '',
            main: ("main" in this.props.species) ? this.props.species.main : false,
            areaBearing: ("areaBearing" in this.props.species) ? this.props.species.areaBearing : true,
            year: ("year" in this.props.species) ? this.props.species.year : (this.props.environment === 'biotopePlan' ? moment().format('YYYY') : ''),
            thinningArr: ("thinningArr" in this.props.species) ? this.props.species.thinningArr : {},
            images: ("images" in this.props) ? this.props.images : [],
            mix: ("mix" in this.props.species) ? this.props.species.mix : '100',
            coverage: ("coverage" in this.props.species) ? this.props.species.coverage : '',
            quality: ("quality" in this.props.species) ? this.props.species.quality : '',
            provenance: ("provenance" in this.props.species) ? this.props.species.provenance : '',
            source: ("source" in this.props.species) ? this.props.species.source : '',
            assessmentMethod: ("assessmentMethod" in this.props.species) ? this.props.species.assessmentMethod : '',
            height: ("height" in this.props.species) ? this.props.species.height : '',
            diameter: ("diameter" in this.props.species) ? this.props.species.diameter : '',
            treeCount: ("treeCount" in this.props.species) ? this.props.species.treeCount : '',
            groundArea: ("groundArea" in this.props.species) ? this.props.species.groundArea : '',
            mass: ("mass" in this.props.species) ? this.props.species.mass : '',
            formNum: ("formNum" in this.props.species) ? this.props.species.formNum : '',
            weight: ("weight" in this.props.species) ? this.props.species.weight : 1,
            type: ("type" in this.props) ? this.props.type : '',
            id: ("id" in this.props.species) ? (typeof this.props.species.id !== 'number' ? this.props.species.id : this.props.species.id.toString()) : '',
            showWarning: false,
            showStandAssesmentWarning: false,
            warningIgnore: false,
            estOpen: false,
            simData: null,
            tempSetSimData: true,
            deleteThinningItemId: null,
            showDeleteThinningItemWarning: false,
            anchorEl: null,
            estimationSteps: [0, 10],
            groundAreaPlotData: null,
            tempCO2Area_e: 0,
            openFoliage: false,
            openNeedle: false,
            openOther: false,
        };
    }

    componentDidMount() {
        if (this.props.new) {
            this.setState({ expanded: 'panel1' });
        }
    }

    componentDidUpdate() {
        // TO DO
        // Check if specie is complete. When complete, add one more specie row
        // for next specie
        if (this.state.deleteLoading) {
            this.setState({
                change: false,
                deleteLoading: false,
                disabled: false
            })
        }

        // Check if new and preload som parameters
        if (this.props.environment === 'biotopePlan' && this.props.new && this.state.year === '') {
            this.setState({ year: moment().format("YYYY") })
        }

        // Estimation
        const treeCountCheck = typeof this.state.treeCount !== 'number' ? parseFloat(this.state.treeCount.replace(',', '.')) : this.state.treeCount;
        if (this.state.species !== '' && this.state.mass !== '' && 
            this.state.mass > 0 && this.props.area !== '' && 
            this.state.height !== '' && this.state.diameter !== '' && 
            this.state.groundArea !== '' && this.state.groundArea > 0 && 
            this.state.treeCount !== '' && treeCountCheck > 0 && 
            this.state.year !== '' && this.state.tempSetSimData && 
            getSpecieParameters(this.state.species, this.props.speciesScheme).estModel) {
            // Set input for estimation step
            setTimeout(() => {
                try {
                    const initCond = {
                        area: formatEstInput(this.props.area),
                        V: formatEstInput(this.state.mass) / formatEstInput(this.props.area),
                        H: formatEstInput(this.state.height),
                        D: formatEstInput(this.state.diameter) / 100,
                        G: formatEstInput(this.state.groundArea),
                        N: formatEstInput(this.state.treeCount) * 100,
                        age: parseInt(moment().format("YYYY")) - this.state.year
                    }
                    // Simulation
                    const simOut = simulate(this.props.speciesScheme, this.state.species, this.state.estimationSteps, initCond, this.state.thinningArr);
                    // Check for zero crossing and fire snackbar
                    if (simOut.zeroCrossing) {
                        this.props.onEnqueueSnackbar({
                            message: "OBS! En eller flere af fremskrivningsværdierne er nul!",
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'info',
                            },
                        });
                    }
                    // Create x-axis labels and set simulation data in state and set groundArea graph
                    let xlabels = new Array(simOut.H.length).fill(0);
                    let gfGraphData = new Array(simOut.H.length).fill(NaN);
                    let gfGraphData30 = new Array(simOut.H.length).fill(NaN);
                    const gfAge = yieldTable[getSpecieParameters(this.state.species, this.props.speciesScheme).yieldTable].b2.age;
                    const gfData = yieldTable[getSpecieParameters(this.state.species, this.props.speciesScheme).yieldTable].b2.groundArea;
                    for (let i = 0; i < xlabels.length; i++) {
                        xlabels[i] = (parseInt(moment().format("YYYY")) - this.state.estimationSteps[0] + i).toString();
                        const age = parseInt(moment().format("YYYY")) - this.state.year - this.state.estimationSteps[0] + i;
                        const idx = gfAge.indexOf(age);
                        if (idx !== -1) {
                            gfGraphData[i] = gfData[idx] === undefined ? NaN : gfData[idx];//.toFixed(1);
                            gfGraphData30[i] = (gfData[idx] * 1.3);//.toFixed(1);
                        }
                    }

                    this.setState({ simData: { ...simOut, xlabels: xlabels, gfGraph: gfGraphData, gfGraph30: gfGraphData30 }, tempSetSimData: false });
                } catch (err) {
                    console.error("Error in simulaton", err)
                    // if (err.type === 'zeroCrossing') {
                    //     this.props.onEnqueueSnackbar({
                    //         message: "OBS! En eller flere af fremskrivningsværdierne har krydset nul!",
                    //         options: {
                    //             key: new Date().getTime() + Math.random(),
                    //             variant: 'info',
                    //         },
                    //     });
                    // }
                }

            }, 200);

        }
    }

    handleChange = panel => (event, expanded) => {
        this.setState({ expanded: expanded ? panel : false, });
    };

    resetState() {
        const keys = Object.keys(this.state)
        const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: '' }), {})
        this.setState({ ...stateReset, ...initialState })
        this.setState({ userRole: this.props.userRole })
    }

    handleSelectChange = (event, item) => {
        // Set state
        this.setState({
            [event.target.name]: event.target.value,
            info: item.props.info,
            color: getSpecieParameters(event.target.value, this.props.speciesScheme).color
        });

        this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id });
    };

    upgradeButtonHandler = () => {
        // Open account handling in main menu
        this.props.onMainMenuOpen(true);
        this.props.onAccountMenuOpen(true);

        // Send event to analytics
        ReactGA.event({
            category: 'Sidebar',
            action: 'Upgrade button clicked',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    getInitialParameterValues = async () => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Species',
            action: 'Auto stand assessment clicked',
            label: `User: ${this.props.currentAuthUser.name}`
        });
        // Properly create geoJson feature
        this.setState({ standAssessmentLoading: true, showStandAssesmentWarning: false })
        const feat = {
            "type": "Feature",
            "geometry": this.props.geoJsonFeature
        }
        try {
            const param = await standAssessment(this.props.speciesScheme, feat, {
                ...this.state,
                lidarHeight: this.props.lidarHeight,
                area: this.props.area,
                numPoints: 50,
                bufsize: 10,
                pointDistType: 'random',
                sortPointsType: 'hdom',
                sortTruncVal: 0.2
            });
            // Set new values in state
            this.setState({
                height: param.height,
                diameter: param.diameter,
                // groundArea: param.groundAreaHa * this.props.area,
                year: param.year,
                treeCount: param.treeCountHa / 100,
                mix: this.state.mix !== '' ? this.state.mix : 100,
                coverage: this.state.coverage !== '' ? this.state.coverage : 100,
                assessmentMethod: 'Auto, ' + moment().format('DD[-]MM[-]YYYY'),
                quality: this.state.quality !== '' ? this.state.quality : 2,
                standAssessmentLoading: false
            })
        } catch (err) {
            if (err.errorCode === 1) {
                this.props.onEnqueueSnackbar({
                    message: "Det er ikke muligt at vurdere området da højden er under 1,3 m.",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'info',
                    },
                });
            } else {
                this.props.onEnqueueSnackbar({
                    message: "Det er ikke muligt at vurdere området på nuværende tidspunkt. Prøv igen senere.",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'info',
                    },
                });
            }
            // Send event to analytics
            ReactGA.event({
                category: 'Species',
                action: 'Auto stand assessment failed because points was not fetched properly.',
                label: `User: ${this.props.currentAuthUser.name}`
            });
            this.setState({ standAssessmentLoading: false })
        }
    }

    renderMainIcon(main, type) {
        const { classes } = this.props;

        const { species } = this.state;
        if (getSpecieParameters(species, this.props.speciesScheme).color) {
            // Set color and check if color is already set
            if (this.state.color !== getSpecieParameters(species, this.props.speciesScheme).color) {
                this.setState({ color: getSpecieParameters(species, this.props.speciesScheme).color })
            }

            return (
                <div style={{
                    backgroundColor: main ? '#707070' : '#B0B0B0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 30,
                    width: 30,
                    borderRadius: 15,
                    marginRight: 10,
                    marginTop: -2
                }}>
                    {(type !== 'LineString') ? <div className={classes.speciesItem} style={{ marginRight: 0, marginBottom: 0, backgroundColor: species === 'Såt' ? 'white' : getSpecieParameters(species, this.props.speciesScheme).color }} /> : <div className={classes.lineItem} style={{ marginRight: 0, marginBottom: 0, border: '1px solid transparent', backgroundColor: getSpecieParameters(species, this.props.speciesScheme).color }} />}
                </div>
            );
        }
    }

    speciesList = (type, currentSpecie, scheme, extendedState, classes) => {
        // Get species list from full list based on type
        const spcList = getSpeciesList(type, scheme);
        // Format each entry and return list
        const newList = spcList.map(spc => {
            return this.speciesMenuItem(type, currentSpecie, spc, scheme, extendedState, classes);
        })
        return newList;
    }

    speciesMenuItem = (type, currentSpecie, specie, scheme, extendedState, classes) => {
        // Get current specie data
        const curSpcPar = getSpecieParameters(currentSpecie, scheme);
        // Set info data
        let info = '';
        if (type === 'foliage' || type === 'needle') {
            info = 'trees';
        } else if (type === 'line') {
            info = 'line'
        }
        // Set default menu item to current specie
        let menuItem = currentSpecie === '' ? null : <MenuItem key={currentSpecie} info={info} value={currentSpecie}><div className={classes.speciesRow}><div className={type === 'line' ? classes.lineItem : classes.speciesItem} style={{ backgroundColor: curSpcPar.color }} />{currentSpecie}</div></MenuItem>
        // Check for all cases where the specie form the list is not the same as the current specie
        if (currentSpecie !== specie[0]) {
            if (!extendedState && specie[1].availability !== 'simple') {
                menuItem = null;
            } else {
                menuItem = <MenuItem key={specie[0]} info={info} value={specie[0]}><div className={classes.speciesRow}><div className={type === 'line' ? classes.lineItem : classes.speciesItem} style={{ backgroundColor: specie[1][scheme].color }} />{specie[0]}</div></MenuItem>
            }
        }
        return menuItem;
    }


    species() {
        const { classes, type, parentSubmit, environment } = this.props;
        const { species, submit } = this.state;
        let items = null;
        let label = environment === 'forest' ? "Træart" : "Polygontype";

        // Check if species should be disabled, e.g., free user
        const disableSpcBtn = this.props.configForest.polygon.extendedSpecies === 'disabled';
        const disableSpc = true;
        // Set environment to menu items
        if (environment === 'forest') {
            // Get species lists
            const foliageList = this.speciesList('foliage', species, this.props.speciesScheme, this.state.openFoliage, classes);
            const needleList = this.speciesList('needle', species, this.props.speciesScheme, this.state.openNeedle, classes);
            const otherList = this.speciesList('other', species, this.props.speciesScheme, this.state.openOther, classes);
            const lineList = this.speciesList('line', species, this.props.speciesScheme, true, classes);
            // Set menu items for selector based on type polygon/line
            if (type === 'Polygon') {
                items = <Select
                    value={this.state.species}
                    onChange={this.handleSelectChange}
                    inputProps={{
                        name: 'species',
                        id: this.state.id + 'specie-simple',
                    }}
                >
                    {/* Løv */}
                    {foliageList}
                    <div className={classes.expandMoreContainer}>
                        <Button
                            onClick={(e) => {
                                this.setState({ openFoliage: !this.state.openFoliage })
                                e.stopPropagation()
                            }}
                            disabled={disableSpcBtn}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            {this.state.openFoliage ? "Se færre løvtræsarter" : disableSpcBtn ? "Opgrader for adgang til flere arter" : "Se flere løvtræsarter"}
                            {!disableSpcBtn && <ExpandMoreIcon style={{ transform: this.state.openFoliage ? 'rotate(180deg)' : null }} />}
                        </Button>
                    </div>
                    {/* Nål */}
                    {needleList}
                    <div className={classes.expandMoreContainer}>
                        <Button
                            onClick={(e) => {
                                this.setState({ openNeedle: !this.state.openNeedle })
                                e.stopPropagation()
                            }}
                            disabled={disableSpcBtn}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            {this.state.openNeedle ? "Se færre nåletræsarter" : disableSpcBtn ? "Opgrader for adgang til flere arter" : "Se flere nåletræsarter"}
                            {!disableSpcBtn && <ExpandMoreIcon style={{ transform: this.state.openNeedle ? 'rotate(180deg)' : null }} />}
                        </Button>
                    </div>
                    {/* Andet */}
                    {otherList}
                    <div className={classes.expandMoreContainer}>
                        <Button
                            onClick={(e) => {
                                this.setState({ openOther: !this.state.openOther })
                                e.stopPropagation()
                            }}
                            disabled={disableSpcBtn}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            {this.state.openOther ? "Se færre muligheder" : disableSpcBtn ? "Opgrader for adgang til flere arter" : "Se flere muligheder"}
                            {!disableSpcBtn && <ExpandMoreIcon style={{ transform: this.state.openOther ? 'rotate(180deg)' : null }} />}
                        </Button>
                    </div>
                </Select>

            } else if (type === 'LineString') {
                items = <Select
                    value={this.state.species}
                    onChange={this.handleSelectChange}
                    inputProps={{
                        name: 'species',
                        id: this.state.id + 'specie-simple',
                    }}
                >
                    {lineList}
                </Select>
                label = "Linjetype";
            }
        } else if (environment === 'hunting') {
            // Set menu items for selector based on type polygon/line
            if (type === 'Polygon') {
                items = <Select
                    value={this.state.species}
                    onChange={this.handleSelectChange}
                    inputProps={{
                        name: 'species',
                        id: this.state.id + 'specie-simple',
                    }}
                >
                    <MenuItem info={''} value={'Såt'} disabled={this.props.configHunting.polygon.expansionPanel.huntingArea !== 'enabled'}><div className={classes.speciesRow}><div className={classes.speciesItem} style={{ backgroundColor: 'white', border: '1px solid #dfdfdf' }} />Såt</div></MenuItem>
                    <MenuItem info={''} value={'Tykning'} disabled={this.props.configHunting.polygon.expansionPanel.denseForest !== 'enabled'}><div className={classes.speciesRow}><div className={classes.speciesItem} style={{ backgroundColor: cellColors['Tykning'] }} />Tykning</div></MenuItem>
                    <MenuItem info={''} value={'Vildtager'} disabled={this.props.configHunting.polygon.expansionPanel.wildFields !== 'enabled'}><div className={classes.speciesRow}><div className={classes.speciesItem} style={{ backgroundColor: cellColors['Vildtager'] }} />Vildtager</div></MenuItem>
                    {(this.props.configHunting.polygon.expansionPanel.huntingArea !== 'enabled' || this.props.configHunting.polygon.expansionPanel.denseForest !== 'enabled' || this.props.configHunting.polygon.expansionPanel.wildFields !== 'enabled') && <div>
                        <Divider />
                        <div style={{ margin: '16px 16px 8px 16px' }}>
                            <UpgradeMenu
                                title={this.props.configHunting.polygon.upgradeMenu.title}
                                content={this.props.configHunting.polygon.upgradeMenu.text}
                                cta='Opgrader'
                                onPress={() => this.upgradeButtonHandler()}
                            />
                        </div>
                    </div>}
                </Select>
            } else if (type === 'LineString') {
                items = <Select
                    value={this.state.species}
                    onChange={this.handleSelectChange}
                    inputProps={{
                        name: 'species',
                        id: this.state.id + 'specie-simple',
                    }}
                >
                    <MenuItem info="line" value={'Driverkæde'}><div className={classes.speciesRow}><div className={classes.lineItem} style={{ backgroundColor: cellColors['Driverkæde'] }} />Driverkæde</div></MenuItem>
                    <MenuItem info="line" value={'Pürschsti'}><div className={classes.speciesRow}><div className={classes.lineItem} style={{ backgroundColor: cellColors['Pürschsti'] }} />Pürschsti</div></MenuItem>
                </Select>
                label = "Linjetype";
            }
        } else if (environment === 'biotopePlan') {
            // Get biotop plan species from list
            const list = getSpeciesListBasedOnCategory('biotopePlan', 'skovkortet', this.props.biotopePlanType);
            if (type === 'Polygon') {
                const polyList = list.filter(el => el[1].geometryType === 'polygon');
                items = <Select
                    value={this.state.species}
                    onChange={this.handleSelectChange}
                    inputProps={{
                        name: 'species',
                        id: this.state.id + 'specie-simple',
                    }}
                >
                    {polyList.map(el => {
                        return <MenuItem key={el[0]} info={'biotopeFeat'} value={el[0]}><div className={classes.speciesRow}><div className={classes.speciesItem} style={{ backgroundColor: el[1][this.props.speciesScheme].color }} />{el[0]}</div></MenuItem>
                    })}
                </Select>

            } else if (type === 'LineString') {
                let lineList = list.filter(el => el[1].geometryType === 'line');
                if (this.props.biotopeCombi) {
                    lineList = lineList.filter(el => {
                        return [3, 4, 5, 6, 7].includes(el[1].actionNumber);
                    })
                } else if (this.props.biotopeAction18) {
                    lineList = lineList.filter(el => {
                        return [2, 3, 4, 5, 6, 7].includes(el[1].actionNumber);
                    })
                }
                items = <Select
                    value={this.state.species}
                    onChange={this.handleSelectChange}
                    inputProps={{
                        name: 'species',
                        id: this.state.id + 'specie-simple',
                    }}
                >
                    {lineList.map(el => {
                        return <MenuItem key={el[0]} info={'biotopeFeat'} value={el[0]}><div className={classes.speciesRow}><div className={classes.speciesItem} style={{ backgroundColor: el[1][this.props.speciesScheme].color }} />{el[0]}</div></MenuItem>
                    })}
                </Select>
                label = "Linjetype";
            }
        }

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <form className={classes.root} autoComplete="off">
                    <FormControl
                        className={classes.formControl}
                        error={(species === "" && parentSubmit) || (species === "" && submit)}
                        fullWidth
                        required
                    >
                        <InputLabel htmlFor="specie-simple">{label}</InputLabel>
                        {items}
                    </FormControl>
                </form>
            );
        }
    };

    main() {
        const { classes } = this.props;

        if (this.props.change) {
            return (
                <FormControlLabel
                    className={classes.formControl}
                    label="Hovedtræart"
                    control={
                        <Checkbox
                            checked={this.state.main}
                            onChange={this.setMain.bind(this)}
                            color="primary"
                        />
                    }
                    style={{ marginTop: 36, marginBottom: -4 }}
                />
            );
        }
    }

    areaBearing() {
        const { classes } = this.props;

        if (this.props.change) {
            return (
                <FormControlLabel
                    className={classes.formControl}
                    label="Arealbærende"
                    control={
                        <Checkbox
                            checked={this.state.areaBearing}
                            onChange={this.setAreaBearing.bind(this)}
                            color="primary"
                        />
                    }
                    style={{ marginTop: 36, marginBottom: -4 }}
                />
            );
        } else {
            return (
                <div >
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Arealbærende
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {this.state.areaBearing ? "Arealbærende" : "Ikke-arealbærende"}
                        </Typography >
                    </div>
                </div>
            );
        }
    }

    year(label, required) {
        const { classes, parentSubmit } = this.props;
        const { year, submit } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "year"}
                    label={label ? label : "Årstal"}
                    className={classes.textField}
                    value={year}
                    type="number"
                    onWheelCapture={e => { e.target.blur() }}
                    onChange={event => { this.setState({ year: parseInt(event.target.value), tempSetSimData: true }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) }}
                    margin="normal"
                    style={{ marginTop: 26 }}
                    required={required === 'required' ? true : false}
                    error={required ? (year === "" && parentSubmit) || (year === "" && submit) : false}
                />
            );
        }

        // When inventory is in non-editable state
        if (year !== '' && year !== 0 && year !== 9999) {
            return (
                <div >
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {label ? label : "Årstal"}
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {year}
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    mix() {
        const { classes } = this.props;
        const { mix, treeCount } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "mix"}
                    label="Indblandingsprocent"
                    className={classes.textField}
                    value={typeof mix === 'number' ? mix.toFixed(0).split('.').join(',') : mix.split('.').join(',')}
                    onWheelCapture={e => { e.target.blur() }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{`%`}</InputAdornment>,
                    }}
                    onChange={event => {
                        if ((regex.test(event.target.value) && (parseFloat(event.target.value.split(',').join('.')) <= 100 && parseFloat(event.target.value.split(',').join('.')) > 0))) {
                            if (treeCount !== '' && event.target.value !== '') {
                                const fMixOld = isNaN(parseFloat(mix)) ? 0 : parseFloat(mix.toString().split(',').join('.')) / 100;
                                const fMixNew = parseFloat(event.target.value.split(',').join('.')) / 100;
                                const fTreeCountOld = isNaN(parseFloat(treeCount)) ? 0 : parseFloat(treeCount.toString().split(',').join('.'));
                                const fTreeCountNew = fMixNew * fTreeCountOld / fMixOld;
                                this.setState({ treeCount: fTreeCountNew })
                            }
                            this.setState({ mix: event.target.value, tempSetSimData: true }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id })
                        }
                    }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (mix !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Indblandingsprocent
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {typeof mix === 'number' ? mix.toFixed(0).split('.').join(',') : mix.split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            %
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    area() {
        const { classes, area } = this.props;
        const { mix } = this.state;

        const fMix = isNaN(parseFloat(mix)) ? 0 : parseFloat(mix.toString().split(',').join('.'));

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    disabled
                    id={this.state.id + "area"}
                    label="Areal"
                    className={classes.textField}
                    value={(area > 0 && fMix > 0) ? (area * fMix / 100).toFixed(2).toString().split('.').join(',') : ""}
                    onWheelCapture={e => { e.target.blur() }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">ha</InputAdornment>,
                    }}
                    onChange={event => { if (regex.test(event.target.value)) { this.setState({ area: event.target.value }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) } }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (area !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Areal
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {(area * ((fMix === 0 ? 100 : fMix) / 100)).toFixed(2).toString().split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            ha
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    coverage() {
        const { classes } = this.props;
        const { coverage } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "coverage"}
                    label="Bevoksningskvotient"
                    className={classes.textField}
                    value={typeof coverage === 'number' ? coverage.toFixed(0).split('.').join(',') : coverage.split('.').join(',')}
                    onWheelCapture={e => { e.target.blur() }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{`%`}</InputAdornment>,
                    }}
                    onChange={event => { if ((regex.test(event.target.value) && (parseFloat(event.target.value.split(',').join('.')) <= 100 && parseFloat(event.target.value.split(',').join('.')) >= 0)) || event.target.value === '') { this.setState({ coverage: event.target.value }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) } }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (coverage !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Bevoksningskvotient
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {typeof coverage === 'number' ? coverage.toFixed(0).split('.').join(',') : coverage.split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            %
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    quality() {
        const { classes } = this.props;
        const { quality } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "quality"}
                    label="Bonitet"
                    className={classes.textField}
                    value={typeof quality === 'number' ? quality.toFixed(0).split('.').join(',') : quality.split('.').join(',')}
                    onWheelCapture={e => { e.target.blur() }}
                    onChange={event => { if (regex.test(event.target.value)) { this.setState({ quality: event.target.value }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) } }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (quality !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Bonitet
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {typeof quality === 'number' ? quality.toFixed(0).split('.').join(',') : quality.split('.').join(',')}
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    provenance() {
        const { classes } = this.props;
        const { provenance } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "provenance"}
                    label="Proveniens"
                    className={classes.textField}
                    value={provenance}
                    onChange={event => { this.setState({ provenance: event.target.value }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) }}
                    margin="normal"
                />
            );
        }
        // When inventory is in non-editable state
        if (provenance !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Proveniens
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {provenance}
                        </Typography >

                    </div>
                </div>
            );
        }
    }

    source() {
        const { classes } = this.props;
        const { source } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <FormControl
                    margin="normal"
                    fullWidth
                >
                    <InputLabel htmlFor="select-content">
                        {/* {props.inputLabel} */}
                        Oprindelse
                    </InputLabel>

                    <Select
                        value={source}
                        onChange={event => { this.setState({ source: event.target.value }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) }}
                        inputProps={{
                            name: 'Oprindelse-content',
                            id: 'Oprindelse-select'
                        }}
                    >
                        {source !== '' && <MenuItem key={'default'}
                            value={''}
                        >
                            <i>Ingen</i>
                        </MenuItem>}
                        <MenuItem
                            value='Plantet'
                        >
                            Plantet
                        </MenuItem>

                        <MenuItem
                            value='Selvforyngelse'
                        >
                            Selvforyngelse
                        </MenuItem>
                    </Select>
                </FormControl>
            );
        }

        // When inventory is in non-editable state
        if (source !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Oprindelse
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {source}
                        </Typography >
                    </div>
                </div>
            );
        }
    }

    assessmentMethod() {
        const { classes } = this.props;
        const { assessmentMethod } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "assessmentMethod"}
                    label="Taksationsmetode"
                    className={classes.textField}
                    value={assessmentMethod}
                    onChange={event => { this.setState({ assessmentMethod: event.target.value }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (assessmentMethod !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Taksationsmetode
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {assessmentMethod}
                        </Typography >

                    </div>
                </div>
            );
        }
    };

    height() {
        const { classes } = this.props;
        const { height } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "height"}
                    label="Højde"
                    className={classes.textField}
                    value={typeof height === 'number' ? height.toFixed(1).split('.').join(',') : height.split('.').join(',')}
                    onWheelCapture={e => { e.target.blur() }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    onChange={event => { if (regex.test(event.target.value)) { this.setState({ height: event.target.value, tempSetSimData: true }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) } }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (height !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Højde
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {typeof height === 'number' ? height.toFixed(1).split('.').join(',') : height.split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            m
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    diameter() {
        const { classes } = this.props;
        const { diameter } = this.state;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "diameter"}
                    label="Diameter (DBH)"
                    className={classes.textField}
                    value={typeof diameter === 'number' ? diameter.toFixed(1).split('.').join(',') : diameter.split('.').join(',')}
                    //type="number"
                    onWheelCapture={e => { e.target.blur() }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                    onChange={event => { if (regex.test(event.target.value)) { this.setState({ diameter: event.target.value, tempSetSimData: true }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) } }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (diameter !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Diameter (DBH)
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {typeof diameter === 'number' ? diameter.toFixed(1).split('.').join(',') : diameter.split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            cm
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    treeCount() {
        const { classes, area } = this.props;
        const { treeCount, mix } = this.state;

        const fTreeCount = isNaN(parseFloat(treeCount)) ? 0 : parseFloat(treeCount.toString().split(',').join('.'));
        const fMix = isNaN(parseFloat(mix)) ? 0 : parseFloat(mix.toString().split(',').join('.'));
        const fArea = isNaN(parseFloat(area)) ? 0 : parseFloat(area.toString().split(',').join('.'));

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    id={this.state.id + "treeCount"}
                    label="Stamtal"
                    className={classes.textField}
                    value={typeof treeCount === 'number' ? treeCount.toFixed(1).split('.').join(',') : treeCount}
                    //type="number"
                    onWheelCapture={e => { e.target.blur() }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">antal/100m2</InputAdornment>,
                    }}
                    onChange={event => { if (regex.test(event.target.value)) { this.setState({ treeCount: event.target.value, tempSetSimData: true }); this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id }) } }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (treeCount !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Stamtal
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {/* {((fTreeCount * 100) * (fArea * ((fMix === 0 ? 100 : fMix) / 100)) ).toFixed(0).split('.').join(',')} */}
                            {((fTreeCount * 100) * (fArea)).toFixed(0).split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            antal
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    treeCountHA() {
        const { classes, area } = this.props;
        const { treeCount } = this.state;

        const fTreeCount = isNaN(parseFloat(treeCount)) ? 0 : parseFloat(treeCount.toString().split(',').join('.'));
        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    disabled
                    id={this.state.id + "treeCountHA"}
                    label="Stamtal pr. ha"
                    className={classes.textField}
                    value={(fTreeCount > 0) ? (fTreeCount * 100).toFixed(0).split('.').join(',') : ""}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">antal/ha</InputAdornment>,
                    }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (treeCount !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Stamtal pr. ha
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {/* {(fTreeCount * 100).toFixed(0).split('.').join(',')} */}
                            {(fTreeCount > 0) ? (fTreeCount * 100).toFixed(0).split('.').join(',') : ""}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            antal/ha
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    groundArea() {
        const { classes } = this.props;
        const { mix, diameter, treeCount, groundArea } = this.state;

        // Calculate ground area
        const fDiameter = isNaN(parseFloat(diameter)) ? 0 : parseFloat(diameter.toString().split(',').join('.'));
        const fTreeCount = isNaN(parseFloat(treeCount)) ? 0 : parseFloat(treeCount.toString().split(',').join('.'));
        const fMix = isNaN(parseFloat(mix)) ? 0 : parseFloat(mix.toString().split(',').join('.'));
        let fGroundArea = 0;
        if (fDiameter > 0 && fTreeCount > 0) {
            fGroundArea = Math.pow((fDiameter / 100) / 2, 2) * Math.PI * fTreeCount * 100;// * (fMix / 100);
        }
        if (fGroundArea !== groundArea) {
            this.setState({ groundArea: fGroundArea })
        }
        if (this.props.configForest.polygon.expansionPanel.groundArea === 'disabled') return null;
        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    disabled
                    id={this.state.id + "groundArea"}
                    label="Grundfladeareal pr. ha"
                    className={classes.textField}
                    value={fGroundArea.toFixed(1).split('.').join(',')}
                    margin="normal"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m2/ha</InputAdornment>,
                    }}
                />
            );
        }
        // When inventory is in non-editable state
        if (fGroundArea !== 0) {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Grundfladeareal pr. ha
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {fGroundArea.toFixed(1).split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            m2/ha
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    formNum() {
        const { classes } = this.props;
        const { mass, height, groundArea, formNum } = this.state;

        // Calculate formNum
        const fMass = isNaN(parseFloat(mass)) ? 0 : parseFloat(mass.toString().split(',').join('.'));
        const fHeight = isNaN(parseFloat(height)) ? 0 : parseFloat(height.toString().split(',').join('.'));
        const fGroundArea = isNaN(parseFloat(groundArea)) ? 0 : parseFloat(groundArea.toString().split(',').join('.'));
        let fNum = 0;
        if (fMass > 0 && fHeight > 0 && fGroundArea > 0) {
            fNum = fMass / (fHeight * fGroundArea);
        }
        if (fNum !== formNum) {
            this.setState({ formNum: fNum })
        }

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    disabled
                    id={this.state.id + "formNum"}
                    label="Formtal"
                    className={classes.textField}
                    value={fNum.toFixed(2).split('.').join(',')}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (fNum !== 0) {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Formtal
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {fNum.toFixed(2).split('.').join(',')}
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    mass() {
        const { classes, area } = this.props;
        const { diameter, treeCount, height, mass, mix, year } = this.state;
        // Calculate volume
        const fDiameter = isNaN(parseFloat(diameter)) ? 0 : parseFloat(diameter.toString().split(',').join('.'));
        const fTreeCount = isNaN(parseFloat(treeCount)) ? 0 : parseFloat(treeCount.toString().split(',').join('.'));
        const fHeight = isNaN(parseFloat(height)) ? 0 : parseFloat(height.toString().split(',').join('.'));
        const fMix = isNaN(parseFloat(mix)) ? 0 : parseFloat(mix.toString().split(',').join('.'));
        const fArea = isNaN(parseFloat(area)) ? 0 : parseFloat(area.toString().split(',').join('.'));

        let vol;
        if (fHeight > 0 && fDiameter > 0 && fTreeCount > 0) {
            let vOneTree = { vb: 0 };
            try {
                vOneTree = getSpecieVolume(this.props.speciesScheme, this.state.species, { d: fDiameter / 100, h: fHeight, T: moment().format("YYYY") - year });
            } catch (err) {
                console.error(err)
            }
            vol = vOneTree.vb * fTreeCount * 100 * fArea; // * (fMix / 100);
            if ("volCorrectionFactor" in getSpecieParameters(this.state.species, this.props.speciesScheme)) {
                vol = vol * getSpecieParameters(this.state.species, this.props.speciesScheme).volCorrectionFactor;
            }
        } else {
            vol = 0;
        }
        if (vol !== mass) {
            this.setState({ mass: vol })
        }
        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    disabled
                    id={this.state.id + "mass"}
                    label="Vedmasse"
                    className={classes.textField}
                    value={vol.toFixed(0).split('.').join(',')}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m3</InputAdornment>,
                    }}
                    margin="normal"
                />
            );
        }
        // When inventory is in non-editable state
        if (mass !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Vedmasse
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {vol.toFixed(0).split('.').join(',')}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            m3
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    massHA() {
        const { classes, area } = this.props;
        const { mass, mix } = this.state;
        // Calculate based on mix percentage
        const fMix = isNaN(parseFloat(mix)) ? 0 : parseFloat(mix.toString().split(',').join('.'));
        const fAreaHA = area * (fMix / 100);
        let number = '';
        if (mass > 0 && fAreaHA > 0) {
            // number = (mass / fAreaHA).toFixed(0).toString().split('.').join(',');
            number = (mass / area).toFixed(0).toString().split('.').join(',');
        }

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    disabled
                    id={this.state.id + "massHA"}
                    label="Vedmasse pr. ha"
                    className={classes.textField}
                    value={number}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m3/ha</InputAdornment>,
                    }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (mass !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Vedmasse pr. ha
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {number}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            m3/ha
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    CO2Equiv() {
        const { classes, area } = this.props;
        const { mix, mass, diameter, height } = this.state;
        // Calculate based on mix percentage
        const fMix = isNaN(parseFloat(mix)) ? 100 : parseFloat(mix.toString().split(',').join('.'));
        const fDiameter = isNaN(parseFloat(diameter)) ? 0 : parseFloat(diameter.toString().split(',').join('.'));
        const fHeight = isNaN(parseFloat(height)) ? 0 : parseFloat(height.toString().split(',').join('.'));
        const fAreaMix = area * (fMix / 100);
        let number = '';
        // const CO2area_e = getAreaCO2Equiv(fAreaMix,{});
        // if (CO2area_e !== this.state.tempCO2Area_e) {
        //     this.setState({tempCO2Area_e: CO2area_e })
        // }
        if (mass > 0 && fDiameter > 0 && fHeight > 0) {
            // Because we input total mass and not mass/ha we do not need to go from CO2-e/Ha to total CO2-e
            const CO2trees_e = getSpecieCO2Equiv(this.props.speciesScheme, this.state.species, { Dg: fDiameter / 100, Hg: fHeight, V: mass });
            // number = (CO2area_e + CO2trees_e).toFixed(0).split('.').join(',');
            number = CO2trees_e.toFixed(0).split('.').join(',');
        }
        // else {
        //     number = CO2area_e.toFixed(0).split('.').join(',');
        // }

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <TextField
                    disabled
                    id={this.state.id + "CO2Equiv"}
                    label="CO2-ækvivalenter"
                    className={classes.textField}
                    value={number}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">ton</InputAdornment>,
                    }}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (number !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        CO2-ækvivalenter
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {number}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            ton
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    estimationPlot(title, label, xLabels, data, secondaryLabel, secondaryData, hyst) {
        const { classes } = this.props;
        // Setup data sets
        let plotData = {
            labels: xLabels,
            datasets: [
                {
                    label: label,
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#41842b',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 2,
                    pointRadius: 2,
                    pointHitRadius: 0,
                    data: data,
                }
            ]
        };

        if (secondaryData) {
            plotData.datasets.push(
                {
                    label: secondaryLabel ? secondaryLabel : '0',
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#1E1E1E',
                    borderCapStyle: 'butt',
                    borderDash: [5],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 0,
                    pointHoverRadius: 0,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: secondaryData.map(el => el.toFixed(1)),
                    spanGaps: true
                },
                {
                    label: secondaryLabel ? secondaryLabel + '1' : '1',
                    fill: false,
                    lineTension: 0.01,
                    borderColor: '#CECECE',
                    borderCapStyle: 'butt',
                    borderDash: [2.5],
                    borderWidth: 2,
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 0,
                    pointHoverRadius: 0,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: secondaryData.map(el => (el * (1 + hyst)).toFixed(1)),
                    spanGaps: true
                },
                {
                    label: secondaryLabel ? secondaryLabel + '3' : '3',
                    fill: 2,
                    backgroundColor: 'rgba(94, 189, 62, 0.2)',
                    lineTension: 0.01,
                    borderColor: '#CECECE',
                    borderCapStyle: 'butt',
                    borderDash: [2.5],
                    borderWidth: 2,
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 0,
                    pointHoverRadius: 0,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: secondaryData.map(el => (el * (1 - hyst)).toFixed(1)),
                    spanGaps: true
                },
            )
        }

        const option = {
            tooltips: {
                backgroundColor: 'white',
                titleFontColor: '#1B1A1A',
                borderColor: '#CFCFCF',
                borderWidth: 1,
                bodyFontColor: '#1B1A1A',
            },
            plugins: {
                filler: {
                    propagate: false
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: 1,
                    }
                }]
            }
        };

        return (
            <div style={{ minHeight: 24, marginBottom: 4, marginTop: this.props.change ? 8 : 0 }}>
                <ExpansionPanel
                    style={{ marginBottom: 4, marginTop: -8, borderRadius: 0, boxShadow: 'none' }}
                >
                    <ExpansionPanelSummary
                        style={{ paddingLeft: 0, paddingRight: 0, marginBottom: -12 }}
                        // expandIcon={<TimelineIcon style={{ color: '#808080', height: 18, marginRight: 2, marginLeft: -2 }} />}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{
                            root: classes.expansionPanelSummaryRoot,
                            content: classes.expansionPanelSummaryContent,
                            expandIcon: classes.expansionPanelSummaryExpandIcon,
                        }}
                    // IconButtonProps={<TimelineIcon style={{ color: '#808080', height: 18, marginRight: 2, marginLeft: -2 }} />}
                    >
                        <TimelineIcon style={{ color: '#5EBD3E', height: 22, marginRight: 4, marginTop: -2 }} />
                        <Typography
                            variant="caption"
                            color="primary"
                        >
                            {title}
                        </Typography>

                        <Chip size="small" variant="outlined" color="primary" label="beta" style={{ marginLeft: 7, marginTop: -3 }} />
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={classes.expansionPanelDetailsStyle}>
                        <div onClick={this.handleMenuClick} style={{ margin: '3px -6px 40px -4px' }}>
                            <Line data={plotData} legend={null} options={option} />
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div >
        )
    }

    menu() {
        const { classes } = this.props;
        // Set year range values
        const minVal = parseInt(moment().format("YYYY"));// - 10;
        const maxVal = parseInt(moment().format("YYYY")) + 40;
        let val = [parseInt(moment().format("YYYY")) - this.state.estimationSteps[0], parseInt(moment().format("YYYY")) + this.state.estimationSteps[1]];
        val = parseInt(moment().format("YYYY")) + this.state.estimationSteps[1];
        return (
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                classes={{
                    paper: classes.menuContainer,
                }}
            >
                <div>
                    <Typography align='left' variant="body1" style={{ margin: '0px 12px 14px 0px' }}>
                        Fremskrivning
                    </Typography>

                    <div style={{ display: 'flex', alignItems: 'center', width: 180 }}>
                        <Slider
                            value={val}
                            onChange={this.onChangeEstimationSteps}
                            // disabled={this.props.configForest.polygon.expansionPanel.estimestimationPlotChangeTimeline !== 'enabled'}
                            valueLabelDisplay="on"
                            //valueLabelFormat={this.valueLabelFormat}
                            aria-labelledby="range-slider"
                            step={1}
                            min={minVal}
                            max={maxVal}
                            classes={{ valueLabel: classes.valueLabel }}
                        />
                    </div>
                </div>
            </Menu>
        );
    }

    onChangeEstimationSteps = (event, newValue) => {
        // let newEstSteps = [parseInt(moment().format("YYYY")) - newValue[0], newValue[1] - parseInt(moment().format("YYYY"))];
        // if (newEstSteps[0] < 0) {
        //     newEstSteps[0] = 0;
        // }
        // if (newEstSteps[1] < 0) {
        //     newEstSteps[1] = 0;
        // }
        let newEstSteps = [0, newValue - parseInt(moment().format("YYYY"))]
        this.setState({ estimationSteps: newEstSteps, tempSetSimData: true });
    }

    handleMenuClick = event => {
        // if (this.props.configForest.polygon.expansionPanel.menu !== 'hidden') {
        this.setState({ anchorEl: event.currentTarget });
        // }
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    // Thinning
    onAddThinningItem = () => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Species',
            action: 'Added thinning event',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        // Generate id
        const id = moment().format() + "," + Math.floor(Math.random() * 1000).toString();
        const updatedThinningArr = { ...this.state.thinningArr };
        updatedThinningArr[id] = {
            id: id,
            from: this.props.currentAuthUser.name,
            date: new Date(moment().format()).toString(),
            note: '',
            volumeThinning: '',
            treeCountThinning: '',
            percentageThinning: '',
            images: [],
            creationDate: new Date(moment().format()).toString(),
        }
        this.setState({ thinningArr: updatedThinningArr });
        this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id })
    }

    onDeleteThinningItem = (id) => {
        this.setState({ deleteThinningItemId: id, showDeleteThinningItemWarning: true })
    }

    thinningListinputChangedHandler = (value, inputIdentifier, type) => {
        const updatedThinningArr = { ...this.state.thinningArr };
        const updatedThinningElement = {
            ...updatedThinningArr[inputIdentifier]
        };
        if (type === "date") {
            updatedThinningElement.date = value.toString();
        } else if (type === "note") {
            updatedThinningElement.note = value;
        } else if (type === "addImage") {
            const imgs = this.updateImages(value);
            updatedThinningElement.images = updatedThinningElement.images ? updatedThinningElement.images.concat(imgs) : imgs;
        } else if (type === "deleteImage") {
            const imgs = this.deleteImages(value, updatedThinningElement);
            updatedThinningElement.images = imgs;
        } else if (type === "volumeThinning") {
            updatedThinningElement.volumeThinning = value;
        } else if (type === "treeCountThinning") {
            updatedThinningElement.treeCountThinning = value;
            // // give estimate on volume if not already provided
            // if (this.state.mass > 0 && this.props.area > 0 && this.state.treeCount > 0) {
            //     // v_thin = v_ha / N_ha * N_thin;
            //     let volEst = (this.state.mass / this.props.area) / (this.state.treeCount * 100) * value;
            //     updatedThinningElement.volumeThinning = volEst;
            // }
        } else if (type === "percentageThinning") {
            let setValue = value;
            if (setValue > 100) {
                setValue = 100;
            } else if (setValue < 0) {
                setValue = 0;
            }
            updatedThinningElement.percentageThinning = setValue;
        }
        updatedThinningArr[inputIdentifier] = updatedThinningElement;
        this.setState({ thinningArr: updatedThinningArr, tempSetSimData: true });
        this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id })
    }

    ignoreDeleteThinningItemWarning = () => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Species',
            action: 'Thinning item deleted',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        const updatedThinningArray = { ...this.state.thinningArr };
        delete updatedThinningArray[this.state.deleteThinningItemId];
        this.setState({ thinningArr: updatedThinningArray, deleteThinningItemId: null, showDeleteThinningItemWarning: false, tempSetSimData: true });
    }

    updateImages = (event) => {
        let files = []
        for (var i = 0; i < event.target.files.length; i++) {
            files[i] = event.target.files.item(i);
        }
        return files;
    }

    deleteImages = (deleteData, originalData) => {
        let arr = originalData;
        arr = originalData.images.filter(item => {
            return item !== deleteData;
        })
        return arr;
    }

    childHandler(dataFromChild) {
        var arr = this.state.images;
        // Delete selected image
        this.setState({
            images: arr.filter(function (item) {
                return item !== dataFromChild
            })
        })
    }

    thinning() {
        const { thinningArr } = this.state;

        let histListTitle = 'Tyndinger';
        let histListAddButtonLabel = 'Tilføj tynding';
        let histListNoChangeTitle = `Tyndinger (${Object.keys(this.state.thinningArr).length})`;

        // When inventory is in editable state
        if (this.props.change) {
            return (
                <HistoryList
                    variant="thinning"
                    items={this.state.thinningArr}
                    type={histListTitle}
                    notChangeTitle={histListNoChangeTitle}
                    change={true}
                    addButtonLabel={histListAddButtonLabel}
                    addItem={this.onAddThinningItem}
                    onDeleteHandler={this.onDeleteThinningItem}
                    inputChangedHandler={this.thinningListinputChangedHandler}
                    imageActionHandler={this.childHandler}
                    updateImages={this.updateImages}
                    disabled={this.props.networkStatus === 'offline'}
                    networkStatus={this.props.networkStatus}
                />
            );
        }

        // When inventory is in non-editable state
        if (!_.isEmpty(thinningArr)) {
            return (
                <HistoryList
                    variant="thinning"
                    items={this.state.thinningArr}
                    type={histListTitle}
                    notChangeTitle={histListNoChangeTitle}
                    change={false}
                    addButtonLabel={histListAddButtonLabel}
                />
            )
        }
    };

    setMain = () => {
        const { main } = this.state;
        this.setState({ main: main === true ? false : true });
        this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id, main: !main });
    }

    setAreaBearing = () => {
        const { areaBearing } = this.state;
        this.setState({ areaBearing: !areaBearing });
        this.props.callbackFromParent({ unSavedSpecie: true, idBack: this.state.id });
    }

    onDeleteSpecie = () => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Species',
            action: 'Species deleted',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        this.props.callbackFromParent({ unSavedSpecie: true, deleteSpecieId: this.state.id });
        this.setState({
            deleteLoading: true,
            disabled: true,
        })
    }

    setLineData(species) {
        switch (species) {
            case "Vej": return ([3, null]);
            case "Skovvej": return ([2.2, null]);
            case "Kørespor": return ([1.2, '20,6']);
            case "Sti": return ([0.6, '20,6']);
            case "Pürschsti": return ([0.6, '20,6']);
            case "Driverkæde": return ([1, null]);
            case "Vandløb": return ([2, null]);
            case "Grøft": return ([1, '20,6']);
            case "Læbælte": return ([4, null]);
            case "Skovhegn": return ([0.4, null])
            case "Fredskovsgrænse": return ([0.8, null])
            default: return ([1, null]);
        }
    }

    onButtonPress = () => {
        const { info, preInfo, warningIgnore } = this.state;

        // Send event to Analytics 
        ReactGA.event({
            category: 'Species',
            action: 'Species added',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        if (preInfo === 'trees' && info === '' && !warningIgnore) {
            this.setState({ showWarning: true })
        } else {
            // Send data to parent 
            if (this.state.info === 'biotopeFeat' && this.state.year === '') {
                this.setState({ submit: true });
            } else {
                this.sendDataToParent();
            }

        }
    }

    sendDataToParent = () => {
        const { species, color, info, main, provenance, source, areaBearing, year, mix, coverage, quality, assessmentMethod, height, diameter, groundArea, treeCount, formNum, mass, thinningArr } = this.state;

        let weightTemp = null;
        let dashStyle = null;

        // Set line weight and dash array dependent on line type (road, track, ...)
        if (info === 'line') {
            let lineData = this.setLineData(species);
            weightTemp = lineData[0];
            dashStyle = lineData[1];
        }
        // Send species data to parent (Inventory)
        this.props.callbackFromParent({
            species,
            color,
            info,
            main,
            areaBearing,
            provenance: (info === 'trees') ? provenance : '',
            source: (info === 'trees') ? source : '',
            year: year,
            thinningArr: (info === 'trees') ? thinningArr : {},
            mix: (info === 'trees' && mix === 0) ? 100 : mix,
            coverage: (info === 'trees') ? coverage : '',
            quality: (info === 'trees') ? quality : '',
            assessmentMethod: (info === 'trees') ? assessmentMethod : '',
            height: (info === 'trees') ? height : '',
            diameter: (info === 'trees') ? diameter : '',
            groundArea: (info === 'trees') ? groundArea : '',
            treeCount: (info === 'trees') ? treeCount : '',
            formNum: (info === 'trees') ? formNum : '',
            mass: (info === 'trees') ? mass : '',
            unSavedSpecie: false,
            weight: weightTemp,
            dashStyle: dashStyle,
            new: this.props.new,
            idBack: this.state.id,
        });

        if (info !== 'trees') {
            this.setState({
                provenance: '',
                source: '',
                thinningArr: {},
                mix: '',
                coverage: '',
                quality: '',
                assessmentMethod: '',
                height: '',
                diameter: '',
                treeCount: '',
                formNum: '',
                groundArea: '',
                mass: '',
            })
        }

        this.setState({ submit: true, showWarning: false })

        // Check if species is selected (only mandatory element)
        if (this.state.species !== "" && (this.props.environment === 'biotopePlan' ? this.state.year !== "" : true)) {
            // Imitate a save with spinner
            if (!this.state.saveLoading) {
                this.setState({ saveLoading: true },
                    () => {
                        this.timer = setTimeout(() => {
                            this.setState({
                                //disabled: false,
                                saveLoading: false,
                                expanded: false,
                            });

                            // Reset state for new specie field
                            if (this.props.new) {
                                this.resetState();
                            }

                        }, 700);
                    },
                );
            }
        }
    }

    renderSection() {
        if (this.state.info === 'trees') {
            // Check input values for graphs
            let mix = this.state.mix;
            if (mix > 100) {
                mix = 100;
            } else if (mix < 0) {
                mix = 0;
            }
            mix = mix / 100;
            // Check user role
            return (
                <div>
                    <Typography variant="h6" style={{ marginTop: this.props.change ? 28 : 10, marginBottom: this.props.change ? -28 : 8 }}>
                        Bevoksningsdata
                    </Typography>
                    {this.props.configForest.polygon.expansionPanel.main !== 'hidden' && this.main()}
                    {this.props.configForest.polygon.expansionPanel.provenance !== 'hidden' && this.provenance()}
                    {this.props.configForest.polygon.expansionPanel.source !== 'hidden' && this.source()}
                    {this.props.configForest.polygon.expansionPanel.year !== 'hidden' && this.year()}
                    {this.props.configForest.polygon.expansionPanel.mix !== 'hidden' && this.mix()}
                    {this.props.configForest.polygon.expansionPanel.area !== 'hidden' && this.area()}
                    {/* this.props.configForest.polygon.expansionPanel.coverage !== 'hidden' && {this.coverage()} */}
                    {this.props.configForest.polygon.expansionPanel.assessmentMethod !== 'hidden' && this.assessmentMethod()}
                    {this.props.configForest.polygon.expansionPanel.quality !== 'hidden' && this.quality()}
                    {this.props.configForest.polygon.expansionPanel.height !== 'hidden' && this.height()}
                    {(this.state.simData && this.props.configForest.polygon.expansionPanel.heightEstimationPlot !== 'hidden') ? this.estimationPlot('Højdetilvækst', ' Højde [m]', this.state.simData.xlabels, this.state.simData.H.map(el => { return typeof el === 'number' ? el.toFixed(1) : '0' })) : null}
                    {this.props.configForest.polygon.expansionPanel.diameter !== 'hidden' && this.diameter()}
                    {(this.state.simData && this.props.configForest.polygon.expansionPanel.diameterEstimationPlot !== 'hidden') ? this.estimationPlot('Diametertilvækst', ' Diameter [cm]', this.state.simData.xlabels, this.state.simData.D.map(el => { return typeof (el * 100) === 'number' ? (el * 100).toFixed(1) : '0' })) : null}
                    {this.props.configForest.polygon.expansionPanel.treeCount !== 'hidden' && this.treeCount()}
                    {(this.state.simData && this.props.configForest.polygon.expansionPanel.treeCountEstimationPlot !== 'hidden') ? this.estimationPlot('Stamtalstilvækst', ' Stamtal [-]', this.state.simData.xlabels, this.state.simData.N.map(el => { return typeof (el * this.props.area) === 'number' ? (el * this.props.area).toFixed(1) : '0' })) : null}
                    {this.props.configForest.polygon.expansionPanel.treeCountHA !== 'hidden' && this.treeCountHA()}
                    {this.props.configForest.polygon.expansionPanel.groundArea !== 'hidden' && this.groundArea()}
                    {(this.state.simData && this.props.configForest.polygon.expansionPanel.groundAreaEstimationPlot !== 'hidden') ? this.estimationPlot('Grundfladetilvækst', ' Grundfladeareal [m2/ha]', this.state.simData.xlabels, this.state.simData.G.map(el => { return typeof el === 'number' ? el.toFixed(1) : '0' }), 'Opt. grundflade', this.state.simData.gfGraph, 0.15) : null}
                    {/* {this.formNum()} */}
                    {this.props.configForest.polygon.expansionPanel.mass !== 'hidden' && this.mass()}
                    {(this.state.simData && this.props.configForest.polygon.expansionPanel.massEstimationPlot !== 'hidden') ? this.estimationPlot('Vedmassetilvækst', ' Vedmasse [m3]', this.state.simData.xlabels, this.state.simData.V.map(el => { return typeof (el * this.props.area) === 'number' ? (el * this.props.area).toFixed(1) : '0' })) : null}
                    {this.props.configForest.polygon.expansionPanel.massHA !== 'hidden' && this.massHA()}
                    {(this.props.configForest.polygon.expansionPanel.CO2Equiv !== 'hidden' && this.props.selectedForest && this.props.customerList[this.props.selectedForest].hasPayedSubscription) && this.CO2Equiv()}
                    {(this.props.configForest.polygon.expansionPanel.CO2EquivEstimationPlot !== 'hidden' && this.props.selectedForest && this.props.customerList[this.props.selectedForest].hasPayedSubscription && this.state.simData) ? this.estimationPlot('CO2-ækvivalenter', 'CO2-e [ton]', this.state.simData.xlabels, this.state.simData.CO2Equiv.map(el => { return typeof (el * this.props.area) === 'number' ? (el * this.props.area).toFixed(0) : '0' })) : null}
                    {this.props.configForest.polygon.expansionPanel.thinning !== 'hidden' && <Typography variant="h6" style={{ marginTop: this.props.change ? 28 : 10, marginBottom: this.props.change ? -2 : -6 }}>
                        Aktiviteter
                    </Typography>}
                    {this.props.configForest.polygon.expansionPanel.thinning !== 'hidden' && this.thinning()}
                    {this.props.configForest.polygon.expansionPanel.menu !== 'hidden' && this.menu()}
                </div>
            )
        } else if (this.state.info === 'line' && this.props.environment === 'forest') {
            return (
                < div >
                    {this.props.configForest.line.expansionPanel.areaBearing !== 'hidden' && this.areaBearing()}
                    {this.props.configForest.line.expansionPanel.year !== 'hidden' && this.year()}
                </div >
            )
        } else if (this.state.info === '' && this.props.environment === 'forest' && this.state.species !== '') {
            return (
                < div >
                    {this.mix()}
                    {/* {this.year()} */}
                </div >
            )
        } else if (this.state.info === 'biotopeFeat') {
            return (
                <div>
                    {/* <Typography variant="h6" style={{ marginTop: this.props.change ? 28 : 10, marginBottom: this.props.change ? -28 : 8 }}>
                        Tiltagsdata
                    </Typography> */}
                    {this.year("Etableringsår", "required")}
                </div>
            )
        }
    }

    // ONLY FOR TESTING ---
    onEstimateVolumeClick = () => {
        // Open modal
        this.setState({ estOpen: true })
    }

    onEstimateVolumeCloseClick = () => {
        // Open modal
        this.setState({ estOpen: false })
    }
    // ---

    renderAction(placement) {
        const { classes } = this.props;
        // Set action menu
        const items = [
            {
                name: "Slet",
                text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.onDeleteSpecie,
                buttonIcon: true
            },
        ]
        // When inventory is in editfable state
        if (this.props.change) {
            // When it is an existing one 
            if ("species" in this.props.species) {
                return (
                    <div>
                        {(this.props.environment === 'forest' && (getSpecieParameters(this.state.species, this.props.speciesScheme) && "yieldTable" in getSpecieParameters(this.state.species, this.props.speciesScheme))) && <div>
                            {placement === 'top' && <div>
                                <div style={{ marginLeft: 8, marginRight: 8, marginTop: 46, marginBottom: 16 }}>
                                    <Typography
                                        variant="body1"
                                    >
                                        Tip
                                    </Typography >

                                    <div style={{ display: 'flex' }}>
                                        <Typography
                                            variant="body2"
                                            style={{ color: '#6A6A6A', marginLeft: 4, marginRight: 8 }}
                                        >
                                            •
                                        </Typography >

                                        <Typography
                                            variant="body2"
                                            style={{ color: '#6A6A6A' }}
                                        >
                                            Med "VURDER OMRÅDE" får du nemt en vurdering af områdets bevoksningsdata.
                                        </Typography >
                                    </div>
                                </div>

                                <div className={classes.button}>
                                    <Button
                                        onClick={() => this.setState({ showStandAssesmentWarning: true })}
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                    >
                                        <IconCloudDownloadRounded className={classes.extendedIconAdd} />

                                        Vurder område
                                    </Button>
                                    {this.state.standAssessmentLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </div>}
                        </div>}

                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: placement === 'top' ? 50 : 22, marginBottom: 6 }}>
                            <OutlinedButton
                                buttonLabel={'Gem'}
                                buttonIcon={<SaveIcon className={classes.extendedIconAdd} />}
                                iconLeft={true}
                                onPress={this.onButtonPress.bind(this)}
                                loading={this.state.saveLoading}
                                color="primary"
                            />

                            <DeleteButton
                                items={items}
                                deleteLoading={this.state.deleteLoading}
                                disabled={this.state.unSavedSpecie ? true : this.state.disabled}
                            />
                        </div>
                    </div>
                );
            }

            // When it is a new
            return (
                <div>
                    {(this.props.environment === 'forest' && (getSpecieParameters(this.state.species, this.props.speciesScheme) && "yieldTable" in getSpecieParameters(this.state.species, this.props.speciesScheme))) && <div>
                        {placement === 'top' && <div>
                            <div style={{ marginLeft: 8, marginRight: 8, marginTop: 46, marginBottom: 16 }}>
                                <Typography
                                    variant="body1"
                                >
                                    Tip
                                </Typography >

                                <div style={{ display: 'flex' }}>
                                    <Typography
                                        variant="body2"
                                        style={{ color: '#6A6A6A', marginLeft: 4, marginRight: 8 }}
                                    >
                                        •
                                    </Typography >

                                    <Typography
                                        variant="body2"
                                        style={{ color: '#6A6A6A' }}
                                    >
                                        Med "VURDER OMRÅDE" får du nemt en vurdering af områdets bevoksningsdata.
                                    </Typography >
                                </div>
                            </div>

                            <div className={classes.button}>
                                <Button
                                    onClick={this.getInitialParameterValues}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                >
                                    <IconCloudDownloadRounded className={classes.extendedIconAdd} />

                                    Vurder område
                                </Button>
                                {this.state.standAssessmentLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </div>}
                    </div>}

                    {this.state.species && <div style={{ marginTop: placement === 'top' ? 50 : 22, marginBottom: 6 }}>
                        <OutlinedButton
                            buttonLabel={'Tilføj'}
                            buttonIcon={<AddIcon className={classes.extendedIconAdd} />}
                            iconLeft={true}
                            onPress={this.onButtonPress.bind(this)}
                            loading={this.state.saveLoading}
                            color="primary"
                        />
                    </div>}
                </div>
            );
        }
    }

    render() {
        const { classes, type, environment } = this.props;
        const { expanded, main, species, showWarning, info } = this.state;
        let labelMain = "Træart";
        let labelSub = "Tilføj art";
        if (environment === 'hunting' || environment === 'biotopePlan') {
            labelMain = "Polygontype";
            labelSub = "Tilføj type";
        }
        if (type === 'LineString') {
            labelMain = "Linjetype";
            labelSub = "Tilføj type";
        }

        return (
            <div style={{ marginBottom: 12 }}>
                <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                    <ExpansionPanelSummary
                        style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 3 }}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <div>
                            {this.renderMainIcon(main, type)}
                        </div>

                        <Typography className={classes.heading}>
                            {labelMain}
                        </Typography>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }} >
                            <Typography className={classes.secondaryHeading}>
                                {(species === "") ? labelSub : species}
                            </Typography >
                        </div>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                        style={{ paddingLeft: 16, paddingRight: 16 }}
                    >
                        <div style={{ flexDirection: 'column', width: '100%' }} >
                            {this.species()}

                            {info === 'trees' && species !== "" && <div style={{ marginRight: -8, marginLeft: -8, marginBottom: 0, marginTop: 0 }}>
                                {this.renderAction('top')}
                            </div>}

                            <div style={{ marginBottom: -8 }}>
                                {this.renderSection()}
                            </div>
                        </div>
                    </ExpansionPanelDetails>

                    <ExpansionPanelActions
                        style={{ justifyContent: 'flex-start', paddingLeft: 8, paddingBottom: 0, paddingTop: 0, marginTop: 0 }}
                    >
                        {this.renderAction('bottom')}
                    </ExpansionPanelActions>

                    <Dialog open={showWarning}>
                        <DialogTitle>
                            Du har skiftet art
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Bevoksningsdata gemt under den tidligere art, vil gå tabt hvis der forsættes.
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <OutlinedButton
                                    buttonLabel='Tilbage'
                                    onPress={() => this.setState({ showWarning: false })}
                                    variant='text'
                                    discrete={true}
                                />
                                <OutlinedButton
                                    buttonLabel='Ok, jeg skal ikke bruge data'
                                    buttonIcon={<IconChevronRight />}
                                    iconLeft={false}
                                    onPress={() => this.sendDataToParent()}
                                    color='primary'
                                />
                            </div>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.showStandAssesmentWarning}>
                        <DialogTitle>
                            OBS!
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Tidligere gemt bevoksningsdata vil blive overskrevet.
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <OutlinedButton
                                    buttonLabel='Tilbage'
                                    onPress={() => this.setState({ showStandAssesmentWarning: false })}
                                    variant='text'
                                    discrete={true}
                                />
                                <OutlinedButton
                                    buttonLabel='Ok, jeg skal ikke bruge data'
                                    buttonIcon={<IconChevronRight />}
                                    iconLeft={false}
                                    onPress={this.getInitialParameterValues}
                                    color='primary'
                                />
                            </div>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.showDeleteThinningItemWarning}>
                        <DialogTitle>
                            Bekræft handling
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <OutlinedButton
                                    buttonLabel='Tilbage'
                                    onPress={() => this.setState({ showDeleteThinningItemWarning: false })}
                                    variant='text'
                                    discrete={true}
                                />
                                <OutlinedButton
                                    buttonLabel='Ok, jeg skal ikke bruge data'
                                    buttonIcon={<IconChevronRight />}
                                    iconLeft={false}
                                    onPress={this.ignoreDeleteThinningItemWarning}
                                    color='primary'
                                />
                            </div>
                        </DialogActions>
                    </Dialog>
                </ExpansionPanel>

                {this.state.estOpen && <TestEstimation
                    open={this.state.estOpen}
                    onExit={this.onEstimateVolumeCloseClick}
                    species={this.state.species}
                    area={this.props.area}
                    year={this.state.year}
                    height={this.state.height}
                    diameter={this.state.diameter}
                    mix={this.state.mix}
                    coverage={this.state.coverage}
                    treeCount={this.state.treeCount}
                />}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        speciesScheme: state.layout.speciesScheme,
        currentAuthUser: state.user.currentAuthUser,
        networkStatus: state.layout.networkStatus,
        inTrial: state.account.inTrial,
        inTrialOperationActive: state.account.inTrialOperationActive,
        customerList: state.customer.customerList,
        selectedForest: state.customer.currentlySelectedForestId,
        natureActive: state.account.natureActive,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        configForest: state.appConfig.inventorySidebar,
        configHunting: state.appConfig.huntingSidebar,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onMainMenuOpen: (state) => dispatch(actions.mainMenuOpen(state)),
        onAccountMenuOpen: (state) => dispatch(actions.accountMenuOpen(state)),
    };
}

ControlledExpansionPanels.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ControlledExpansionPanels));