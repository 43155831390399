import * as actionTypes from './actionTypes';
import firebase from 'firebase';
import reepayConfig from '../../backend/configReepay';
import moment from 'moment';

import { checkThirdPartySubscriptionHandling } from '../../components/UI/Menus/MainMenu/Subscription/utility';

// Parameters
const skovdyrkerneRefDate = {
    month: 5,
    day: 30
}

export const setInTrialOperationActive = (state) => {
    return {
        type: actionTypes.SET_IN_TRIAL_OPERATION_ACTIVE,
        inTrialOperationActive: state
    };
};

export const setTaskPlanningActive = (state) => {
    return {
        type: actionTypes.SET_TASK_PLANNING_ACTIVE,
        taskPlanningActive: state
    };
};

export const setKwIntegrationActive = (state) => {
    return {
        type: actionTypes.SET_KW_INTEGRATION_ACTIVE,
        kwIntegrationActive: state
    };
};

export const setSubscriptionState = (sub, state) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_STATE,
        sub: sub,
        state: state,
    };
};


export const setInTrialHuntingActive = (state) => {
    return {
        type: actionTypes.SET_IN_TRIAL_HUNTING_ACTIVE,
        inTrialHuntingActive: state
    };
};

const fetchReepayStateSuccess = (reepayState, user) => {
    // Check if basic subscription is active
    const basicSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.trialPlan || sub.plan === reepayConfig.freePlan || sub.plan === reepayConfig.naturePlanYear || sub.plan === reepayConfig.naturePlanMonth || sub.plan === reepayConfig.natureOperationPlusPlanYear || sub.plan === reepayConfig.natureOperationPlusPlanMonth)
    })
    // Nature subscription
    const natureSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.naturePlanYear || sub.plan === reepayConfig.naturePlanMonth)
    })
    // Operations subscription
    const operationSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.operationPlanYear || sub.plan === reepayConfig.operationPlanMonth)
    })
    // Operation+ subscription
    const operationPlusSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.operationPlusPlanYear || sub.plan === reepayConfig.operationPlusPlanMonth)
    })
    // Hunting subscription
    const huntingSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.huntingPlanYear || sub.plan === reepayConfig.huntingPlanMonth)
    })
    // Hunting plus subscription
    const huntingPlusSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.huntingPlusPlanYear || sub.plan === reepayConfig.huntingPlusPlanMonth)
    })
    // Owner up to five forests subscription
    const ownerUpToFiveForestsSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.ownerUpToFiveForestsPlanYear || sub.plan === reepayConfig.ownerUpToFiveForestsPlanMonth)
    })
    // Unlimited user access
    const unlimitedAccessSub = reepayState.filter(sub => {
        return sub.state === 'active' && (sub.plan === reepayConfig.unlimitedUserAccessPlanYear || sub.plan === reepayConfig.unlimitedUserAccessPlanMonth)
    })
    // DS 14 days free full system trial
    const dsTrialSub = reepayState.filter(sub => {
        return sub.state === 'active' && sub.plan === reepayConfig.dsTrialPlan;
    })
    // Check logic on plan states based on the different subscriptions, such as trial
    let opState = false;
    let hunState = false;
    let hunPlusState = false;
    let opPlusState = false;
    let ownerFiveForestsState = false;
    let unlimitedAccessState = false;
    let inTrialState = false;
    if (dsTrialSub.length > 0) {
        inTrialState = true;
    }
    if (user.userRole === 'forester' && (dsTrialSub.length > 0 || operationPlusSub.length > 0)) {
        opPlusState = true;
    } else if (user.userRole === 'forestOwner' && (dsTrialSub.length > 0 || operationSub.length > 0)) {
        opState = true;
    }
    if (dsTrialSub.length > 0 || huntingSub.length > 0) {
        hunState = true;
    }
    if (huntingPlusSub.length > 0) {
        hunPlusState = true;
    }
    if (ownerUpToFiveForestsSub.length > 0) {
        ownerFiveForestsState = true;
    }
    if (unlimitedAccessSub.length > 0) {
        unlimitedAccessState = true;
    }

    return {
        type: actionTypes.FETCH_REEPAY_STATE_SUCCESS,
        reepayState: reepayState,
        subscription: basicSub.length > 0 ? basicSub[0] : '',
        inTrial: inTrialState,
        trialSub: dsTrialSub.length > 0 ? dsTrialSub[0] : '',
        subscriptionActive: basicSub.length > 0 ? true : false,
        natureSub: natureSub.length > 0 ? natureSub[0] : '',
        operationSub: operationSub.length > 0 ? operationSub[0] : '',
        operationPlusSub: operationPlusSub.length > 0 ? operationPlusSub[0] : '',
        huntingSub: huntingSub.length > 0 ? huntingSub[0] : '',
        huntingPlusSub: huntingPlusSub.length > 0 ? huntingPlusSub[0] : '',
        ownerUpToFiveForestsSub: ownerUpToFiveForestsSub.length > 0 ? ownerUpToFiveForestsSub[0] : '',
        unlimitedUserAccessSub: unlimitedAccessSub.length > 0 ? unlimitedAccessSub[0] : '',
        natureActive: natureSub.length > 0 ? true : false,
        operationActive: opState,
        operationPlusActive: opPlusState,
        huntingActive: hunState,
        huntingPlusActive: hunPlusState,
        ownerUpToFiveForestsActive: ownerFiveForestsState,
        unlimitedUserAccessActive: unlimitedAccessState,
    };
};

const fetchReepayStateFail = (error) => {
    return {
        type: actionTypes.FETCH_REEPAY_STATE_FAIL,
        error: error
    };
};

const fetchReepayStateStart = () => {
    return {
        type: actionTypes.FETCH_REEPAY_STATE_START
    };
};

export const fetchReepayState = () => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    // Subsequent queries will use persistence, if it was enabled successfully
    dispatch(fetchReepayStateStart());
    try {
        // Fetch firebase user data
        let userData = await db.collection("users").doc(user.uid).get()
        userData = userData.data();
        // Check for third party subscription handling (e.g., skovdyrkerne)
        if (checkThirdPartySubscriptionHandling(userData)) {
            let subs = userData.subscriptions ? userData.subscriptions : [];
            dispatch(fetchReepayStateSuccess(subs, userData));

        } else {
            // Fetch reepay data
            const reepayState = await fetch('https://api.reepay.com/v1/subscription?search=state:active,customer.handle:' + user.uid, {
                method: 'get',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': reepayConfig.auth
                },
            });
            if (reepayState.ok) {
                let reepayStateJson = await reepayState.json();
                if (reepayStateJson.content.length > 0) {
                    dispatch(fetchReepayPlan(reepayStateJson.content[0].plan))
                    dispatch(fetchReepayStateSuccess(reepayStateJson.content, userData));
                } else {
                    dispatch(fetchReepayStateFail("WARNING: the user has no subscription yet!"));
                }
            } else {
                let reepayStateJson = await reepayState.json();
                dispatch(fetchReepayStateFail(reepayStateJson.error));
            }
        }
    } catch (err) {
        dispatch(fetchReepayStateFail(err));
    }
};

const fetchReepayPlanSuccess = (plan) => {
    return {
        type: actionTypes.FETCH_REEPAY_PLAN_SUCCESS,
        plan: plan
    };
};

const fetchReepayPlanFail = (error) => {
    return {
        type: actionTypes.FETCH_REEPAY_PLAN_FAIL,
        error: error
    };
};

const fetchReepayPlanStart = () => {
    return {
        type: actionTypes.FETCH_REEPAY_PLAN_START
    };
};

export const fetchReepayPlan = (plan) => async (dispatch) => {
    dispatch(fetchReepayPlanStart());
    try {
        const reepayState = await fetch('https://api.reepay.com/v1/plan/' + plan + '/current', {
            method: 'get',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': reepayConfig.auth
            },
        });
        let reepayStateJson = await reepayState.json();
        dispatch(fetchReepayPlanSuccess(reepayStateJson));
    } catch (err) {
        console.error("Fetch Reepay plan Error", err)
        dispatch(fetchReepayPlanFail(err));
    }
};

const fetchReepayPlanListSuccess = (planList) => {
    return {
        type: actionTypes.FETCH_REEPAY_PLAN_LIST_SUCCESS,
        planList: planList
    };
};

const fetchReepayPlanListFail = (error) => {
    return {
        type: actionTypes.FETCH_REEPAY_PLAN_LIST_FAIL,
        error: error
    };
};

const fetchReepayPlanListStart = () => {
    return {
        type: actionTypes.FETCH_REEPAY_PLAN_LIST_START
    };
};

export const fetchReepayPlanList = () => async (dispatch) => {
    dispatch(fetchReepayPlanListStart());
    try {
        const reepayState = await fetch('https://api.reepay.com/v1/plan?only_active=true', {
            method: 'get',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': reepayConfig.auth
            },
        });
        let reepayStateJson = await reepayState.json();
        dispatch(fetchReepayPlanListSuccess(reepayStateJson));
    } catch (err) {
        console.error("Fetch Reepay plan Error", err)
        dispatch(fetchReepayPlanListFail(err));
    }
};

const setSubscriptionOnHoldSuccess = () => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_ON_HOLD_SUCCESS,
    };
};

const setSubscriptionOnHoldFail = (error) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_ON_HOLD_FAIL,
        error: error
    };
};

const setSubscriptionOnHoldStart = () => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_ON_HOLD_START
    };
};

export const setSubscriptionOnHold = (sub) => async (dispatch) => {
    dispatch(setSubscriptionOnHoldStart());
    try {
        const reepayState = await fetch('https://api.reepay.com/v1/subscription/' + sub + '/on_hold', {
            method: 'post',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': reepayConfig.auth
            },
        });
        let reepayStateJson = await reepayState.json();
        if (reepayState.ok) {
            dispatch(setSubscriptionOnHoldSuccess());
        } else {
            throw reepayStateJson.error;
        }

    } catch (err) {
        console.error("Fetch Reepay customer Error", err)
        dispatch(setSubscriptionOnHoldFail(err));
    }
};

const attachReepayStart = () => {
    return {
        type: actionTypes.ATTACH_REEPAY_START
    };
};

const attachReepayFail = (error) => {
    return {
        type: actionTypes.ATTACH_REEPAY_FAIL,
        error: error
    };
};

const attachReepaySuccess = (data) => {
    let trialSub = '';
    if (data.plan && data.plan === reepayConfig.dsTrialPlan) trialSub = data;
    return {
        type: actionTypes.ATTACH_REEPAY_SUCCESS,
        trialSub: trialSub,
    };
};

export const attachReepay = (userData) => async (dispatch) => {
    // Create Reepay subscription
    dispatch(attachReepayStart());
    const nameSplit = userData.name.split(' ');
    let firstName = '';
    let lastName = '';
    let reepayUser = null;
    try {
        firstName = nameSplit[0];
        lastName = nameSplit.length > 1 ? nameSplit.slice(1).join(' ') : '';
        if (userData.dsStatus) {
            reepayUser = await fetch('https://api.reepay.com/v1/subscription', {
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': reepayConfig.auth
                },
                body: JSON.stringify({
                    "plan": reepayConfig.dsTrialPlan,//reepayConfig.trialPlan,
                    "create_customer": {
                        "email": userData.email,
                        "address": userData.address,
                        "city": userData.city,
                        "country": "DK",
                        "phone": userData.phone,
                        "company": userData.orgName,
                        "first_name": firstName,
                        "last_name": lastName,
                        "postal_code": userData.zip,
                        "generate_handle": false,
                        "handle": userData.id,
                        "metadata": {},
                        "vat": userData.cvr,
                    },
                    "generate_handle": true,
                    "no_setup_fee": true,
                    "signup_method": "link",
                })
            });
        } else {
            reepayUser = await fetch('https://api.reepay.com/v1/customer', {
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': reepayConfig.auth
                },
                body: JSON.stringify({
                    "email": userData.email,
                    "address": userData.address,
                    "city": userData.city,
                    "country": "DK",
                    "phone": userData.phone,
                    "company": userData.orgName,
                    "first_name": firstName,
                    "last_name": lastName,
                    "postal_code": userData.zip,
                    "generate_handle": false,
                    "handle": userData.id,
                    "metadata": {},
                    "vat": userData.cvr,
                })
            });
        }

        let userJson = await reepayUser.json();
        if (!reepayUser.ok) {
            dispatch(attachReepayFail(userJson.error))
        }
        dispatch(attachReepaySuccess(userJson))
    } catch (error) {
        console.error("Attach Reepay Failed : ", error);
        dispatch(attachReepayFail(error))
    }
}

const reactivateSubscriptionSuccess = () => {
    return {
        type: actionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS,
    };
};

const reactivateSubscriptionFail = (error) => {
    return {
        type: actionTypes.REACTIVATE_SUBSCRIPTION_FAIL,
        error: error
    };
};

const reactivateSubscriptionStart = () => {
    return {
        type: actionTypes.REACTIVATE_SUBSCRIPTION_START
    };
};

export const reactivateSubscription = (sub) => async (dispatch) => {
    dispatch(reactivateSubscriptionStart());
    try {
        const reepayState = await fetch('https://api.reepay.com/v1/subscription/' + sub + '/reactivate', {
            method: 'post',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': reepayConfig.auth
            },
        });
        let reepayStateJson = await reepayState.json();
        if (reepayState.ok) {
            dispatch(reactivateSubscriptionSuccess());
        } else {
            throw reepayStateJson.error;
        }
    } catch (err) {
        console.error("Fetch Reepay customer Error", err)
        dispatch(reactivateSubscriptionFail(err));
    }
};


const cancelSubscriptionSuccess = (subObject) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
        subObject: subObject,
    };
};

const cancelSubscriptionFail = (error) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION_FAIL,
        error: error
    };
};

const cancelSubscriptionStart = () => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION_START
    };
};

const updateThirdPartySubscription = async (subName, user, upgrade) => {
    const db = firebase.firestore();
    try {
        let subs = user.subscriptions ? [...user.subscriptions] : [];
        let nonUpdateSubs = subs.filter(sub => sub.name !== subName);
        let updateSub = subs.filter(sub => sub.name === subName);
        let updatedSub = updateSub[0];
        // Get time now
        const timeNow = moment().format();
        // Check if subscription has been upgraded (basis->udvidet). Then expire basis
        if (upgrade) {
            updatedSub.is_cancelled = true;
            updatedSub.expires = timeNow;
            updatedSub.cancel_date = timeNow;
            updatedSub.state = 'inactive';
        } else {
            // Check if subscription is uncancelled
            if (updatedSub.is_cancelled) {
                updatedSub.is_cancelled = false;
                updatedSub.expires = null;
                updatedSub.cancel_date = null;
            } else {
                // --- Calculate expire date of sub --- //
                // Get current year
                let year = moment(timeNow).year();
                // Check if we have passed the reference date and + 1 to year if we have
                year = moment(timeNow).isBefore([year, skovdyrkerneRefDate.month, skovdyrkerneRefDate.day]) ? year : year+1;
                // Find difference between reference date and subscription start date (used to check if it is first time)
                const refDate = moment([year, skovdyrkerneRefDate.month, skovdyrkerneRefDate.day]).format();
                const diffYear = moment(refDate).diff(moment(updatedSub.startDate), 'year', true);
                let expDate = refDate;
                // If difference between start and reference date is less than one year, push expire date one year into the future
                if (diffYear < 1) {
                    expDate = moment([year + 1, skovdyrkerneRefDate.month, skovdyrkerneRefDate.day]).format()
                }
                // Update data
                updatedSub.is_cancelled = true;
                updatedSub.cancel_date = timeNow;
                updatedSub.expires = expDate;
            }
        }

        // Cat new subscription array
        const newSubs = [...nonUpdateSubs, updatedSub];
        const refId = db.collection("users").doc(user.id);
        await refId.update({
            subscriptions: newSubs
        })
        return updatedSub;
    } catch (error) {
        console.error(error)
        throw error;
    }
}

export const cancelSubscription = (sub, user) => async (dispatch) => {
    dispatch(cancelSubscriptionStart());
    try {
        // Check for third party subscription handling (e.g., skovdyrkerne)
        if (checkThirdPartySubscriptionHandling(user)) {
            const updatedSub = await updateThirdPartySubscription(sub, user);
            dispatch(cancelSubscriptionSuccess(updatedSub));
        } else {
            const reepayState = await fetch('https://api.reepay.com/v1/subscription/' + sub + '/cancel', {
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': reepayConfig.auth
                },
            });
            const reepayStateJson = await reepayState.json();
            dispatch(cancelSubscriptionSuccess(reepayStateJson));
        }
    } catch (err) {
        console.error("Cancel subscription error: ", err)
        dispatch(cancelSubscriptionFail(err));
    }
};

const uncancelSubscriptionSuccess = (subObject) => {
    return {
        type: actionTypes.UNCANCEL_SUBSCRIPTION_SUCCESS,
        subObject: subObject,
    };
};

const uncancelSubscriptionFail = (error) => {
    return {
        type: actionTypes.UNCANCEL_SUBSCRIPTION_FAIL,
        error: error
    };
};

const uncancelSubscriptionStart = () => {
    return {
        type: actionTypes.UNCANCEL_SUBSCRIPTION_START
    };
};

export const uncancelSubscription = (sub, user) => async (dispatch) => {
    dispatch(uncancelSubscriptionStart());
    try {
        // Check for third party subscription handling (e.g., skovdyrkerne)
        if (checkThirdPartySubscriptionHandling(user)) {
            const updatedSub = await updateThirdPartySubscription(sub, user);
            dispatch(cancelSubscriptionSuccess(updatedSub));
        } else {
            const reepayState = await fetch('https://api.reepay.com/v1/subscription/' + sub + '/uncancel', {
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': reepayConfig.auth
                },
            });
            let reepayStateJson = await reepayState.json();
            dispatch(uncancelSubscriptionSuccess(reepayStateJson));
        }
    } catch (err) {
        console.error("Uncancel subscription error: ", err)
        dispatch(uncancelSubscriptionFail(err));
    }
};

const createSubscriptionSuccess = (subObject, packageType) => {
    return {
        type: actionTypes.CREATE_SUBSCRIPTION_SUCCESS,
        subObject: subObject,
        packageType: packageType,
    };
};

const createSubscriptionFail = (error) => {
    return {
        type: actionTypes.CREATE_SUBSCRIPTION_FAIL,
        error: error
    };
};

const createSubscriptionStart = () => {
    return {
        type: actionTypes.CREATE_SUBSCRIPTION_START
    };
};

const createThirdPartySubscription = (data, affiliation) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    try {
        const refId = db.collection("users").doc(user.uid);
        await refId.update({
            subscriptions: firebase.firestore.FieldValue.arrayUnion({ ...data }),
        })
        // check for upgrade
        let refData = await refId.get();
        refData = refData.data();
        if (refData.subscriptions && refData.subscriptions.length > 0) {
            refData.subscriptions.forEach( async sub => {
                // Check if the user currently has an operation sub and cancel it
                if (data.name === 'nature' && sub.name === 'operation') {
                    const updatedSub = await updateThirdPartySubscription(sub.name, refData, true);
                    dispatch(cancelSubscriptionSuccess(updatedSub));
                }
                if (data.name === 'operation' && sub.name === 'nature') {
                    const updatedSub = await updateThirdPartySubscription(sub.name, refData, true);
                    dispatch(cancelSubscriptionSuccess(updatedSub));
                }
                if (data.name === 'hunting' && sub.name === 'huntingPlus') {
                    const updatedSub = await updateThirdPartySubscription(sub.name, refData, true);
                    dispatch(cancelSubscriptionSuccess(updatedSub));
                }
                if (data.name === 'huntingPlus' && sub.name === 'hunting') {
                    const updatedSub = await updateThirdPartySubscription(sub.name, refData, true);
                    dispatch(cancelSubscriptionSuccess(updatedSub));
                }
            })
        }
    } catch (error) {
        console.error(error)
        throw error;
    }
}

export const createSubscription = (packageType, interval, discountObj, cardId, thirdParty) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    dispatch(createSubscriptionStart());
    try {
        let reepayState = null;
        // Find plan according to type
        let plan = null;
        switch (packageType) {
            case 'nature': plan = interval === 'year' ? reepayConfig.naturePlanYear : reepayConfig.naturePlanMonth; break;
            case 'operation': plan = interval === 'year' ? reepayConfig.operationPlanYear : reepayConfig.operationPlanMonth; break;
            case 'operationPlus': plan = interval === 'year' ? reepayConfig.operationPlusPlanYear : reepayConfig.operationPlusPlanMonth; break;
            case 'hunting': plan = interval === 'year' ? reepayConfig.huntingPlanYear : reepayConfig.huntingPlanMonth; break;
            case 'huntingPlus': plan = interval === 'year' ? reepayConfig.huntingPlusPlanYear : reepayConfig.huntingPlusPlanMonth; break;
            case 'ownerUpToFiveForests': plan = interval === 'year' ? reepayConfig.ownerUpToFiveForestsPlanYear : reepayConfig.ownerUpToFiveForestsPlanMonth; break;
            case 'unlimitedUserAccess': plan = interval === 'year' ? reepayConfig.unlimitedUserAccessPlanYear : reepayConfig.unlimitedUserAccessPlanMonth; break;
            default: const errorMessage = { errorText: "Package error: the package does not exist" }; throw errorMessage;
        }
        // Setup input data
        // Discounts
        let subDiscount = [];
        if (discountObj.length === 1) {
            // Check if discount is given on the yearly or monthly subscription
            if (interval === 'year' && discountObj[0].description.indexOf('år') !== -1) {
                subDiscount = [
                    {
                        "handle": "discount_" + discountObj[0].handle,
                        "discount": discountObj[0].handle,
                    }
                ];
            } else if (interval === 'month' && discountObj[0].description.indexOf('måned') !== -1) {
                subDiscount = [
                    {
                        "handle": "discount_" + discountObj[0].handle,
                        "discount": discountObj[0].handle,
                    }
                ];
            } else {
                subDiscount = [
                    {
                        "handle": "discount_" + discountObj[0].handle,
                        "discount": discountObj[0].handle,
                    }
                ];
            }
        } else if (discountObj.length > 1) {
            subDiscount = discountObj.map(discount => {
                return {
                    "handle": "discount_" + discount.handle,
                    "discount": discount.handle,
                }
            })
        }
        // Check for third party subscription handeling (e.g., skovdyrkerne)
        if (thirdParty) {
            // Form data object
            const data = {
                name: packageType,
                plan: plan,
                startDate: moment().format(),
                state: "active",
                is_cancelled: false,
                expires: null,
                cancel_date: null,
            }
            await dispatch(createThirdPartySubscription(data, "skovdyrkerne"));
            dispatch(createSubscriptionSuccess(data, data.name))
        } else {
            // Create subscription on Reepay
            // Check if card is available. If so place on card instead of email
            if (cardId !== null) {
                reepayState = await fetch('https://api.reepay.com/v1/subscription', {
                    method: 'post',
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json',
                        'Authorization': reepayConfig.auth
                    },
                    body: JSON.stringify({
                        "plan": plan,
                        "customer": user.uid,
                        "source": cardId,
                        "generate_handle": true,
                        "no_trial": true,
                        "no_setup_fee": true,
                        "signup_method": "source",
                        "subscription_discounts": subDiscount
                    })
                });
            } else {
                reepayState = await fetch('https://api.reepay.com/v1/subscription', {
                    method: 'post',
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json',
                        'Authorization': reepayConfig.auth
                    },
                    body: JSON.stringify({
                        "plan": plan,
                        "customer": user.uid,
                        "generate_handle": true,
                        "no_trial": true,
                        "no_setup_fee": true,
                        "signup_method": 'email',
                        "subscription_discounts": subDiscount
                    })
                });
            }
            // Check returned reepayState
            let reepayStateJson = await reepayState.json();
            if (reepayState.ok) {
                dispatch(fetchReepayPlan(plan));
                dispatch(createSubscriptionSuccess(reepayStateJson, packageType));
                // Send email to us about new subscription
                const txt = 'Bruger med email: ' + user.email + ' og uid: ' + user.uid + ', har oprettet abonnement. \n\nAbonnement: ' + plan;
                const dis = discountObj.length > 0 ? '\n\nRabat kode: ' + discountObj.name : '';
                db.collection('mail').add({
                    to: 'app@skovkortet.dk',
                    message: {
                        subject: 'Bruger har oprettet abonnement',
                        text: txt + dis,
                    }
                })
            } else {
                throw reepayStateJson.error;
            }
        }
    } catch (err) {
        console.error("Create subscription error: ", err)
        dispatch(createSubscriptionFail(err));
    }
};

export const setNewNotifications = (newNotifications) => {
    return {
        type: actionTypes.SET_NEW_NOTIFICATIONS,
        newNotifications: newNotifications
    };
};

export const fetchDiscountCodesListSuccess = (codesList) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_CODES_LIST_SUCCESS,
        codesList: codesList
    };
};

export const fetchDiscountCodesListFail = (error) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_CODES_LIST_FAIL,
        error: error
    };
};

export const fetchDiscountCodesListStart = () => {
    return {
        type: actionTypes.FETCH_DISCOUNT_CODES_LIST_START
    };
};

export const fetchDiscountCodesList = () => async (dispatch) => {
    dispatch(fetchDiscountCodesListStart());
    try {
        const reepayState = await fetch('https://api.reepay.com/v1/discount?size=50', {
            method: 'get',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': reepayConfig.auth
            },
        });
        let reepayStateJson = await reepayState.json();
        dispatch(fetchDiscountCodesListSuccess(reepayStateJson));
    } catch (err) {
        console.error("Fetch Reepay discount list Error", err)
        dispatch(fetchDiscountCodesListFail(err));
    }
};

// Firebase discount codes
export const fetchFbDiscountCodesListSuccess = (codesList) => {
    return {
        type: actionTypes.FETCH_FB_DISCOUNT_CODES_LIST_SUCCESS,
        codesList: codesList
    };
};

export const fetchFbDiscountCodesListFail = (error) => {
    return {
        type: actionTypes.FETCH_FB_DISCOUNT_CODES_LIST_FAIL,
        error: error
    };
};

export const fetchFbDiscountCodesListStart = () => {
    return {
        type: actionTypes.FETCH_FB_DISCOUNT_CODES_LIST_START
    };
};

export const fetchFbDiscountCodesList = () => async (dispatch) => {
    dispatch(fetchFbDiscountCodesListStart());
    const db = firebase.firestore();
    try {
        db.collection("discountCodes")
            .onSnapshot(function (querySnapshot) {
                let fetchedCodes = [];

                querySnapshot.forEach(function (doc) {
                    fetchedCodes.push(doc.data());
                });

                // Dispatch codes
                dispatch(fetchFbDiscountCodesListSuccess(fetchedCodes));
            });
    } catch (err) {
        console.error("Fetch Reepay discount list Error", err)
        dispatch(fetchFbDiscountCodesListFail(err));
    }
};

export const fetchPaymentDetailsSuccess = (paymentDetails) => {
    return {
        type: actionTypes.FETCH_PAYMENT_DETAILS_SUCCESS,
        paymentDetails: paymentDetails
    };
};

export const fetchPaymentDetailsFail = (error) => {
    return {
        type: actionTypes.FETCH_PAYMENT_DETAILS_FAIL,
        error: error
    };
};

export const fetchPaymentDetailsStart = () => {
    return {
        type: actionTypes.FETCH_PAYMENT_DETAILS_START
    };
};

export const fetchPaymentDetails = () => async (dispatch) => {
    let user = firebase.auth().currentUser;
    dispatch(fetchPaymentDetailsStart());
    try {
        const reepayState = await fetch('https://api.reepay.com/v1/customer/' + user.uid + '/payment_method', {
            method: 'get',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': reepayConfig.auth
            },
        });
        let reepayStateJson = await reepayState.json();
        dispatch(fetchPaymentDetailsSuccess(reepayStateJson));
    } catch (err) {
        console.error("Fetch Reepay discount list Error", err)
        dispatch(fetchPaymentDetailsFail(err));
    }
};

