import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';

// Components
import MicrosoftButton from '../../components/UI/Buttons/MicrosoftButton';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: '0px 0px',
        width: '100%'
    },
    formHelperText: {
        marginBottom: 22
    },
    divider: {
        backgroundColor: theme.palette.divider,
        height: 1,
        flexGrow: 1,
    },
    link: {
        fontSize: 16,
    },
}));

export default function SignInHDWindow(props) {
    const classes = useStyles();

    return (
        <div style={{ backgroundColor: 'white', padding: 28, borderRadius: 21, width: '100%' }}>
            <div className={classes.root}>
                <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                    error={props.loginError || props.loginThirdPartyError}
                >
                    <Typography style={{ fontSize: 18, fontWeight: 500, marginBottom: 22 }}>Login fra Skovportalen</Typography>

                    <MicrosoftButton
                        text='Login med Microsoft'
                        onClick={props.handleThirdPartyLogin}
                        loading={props.loadingThirdParty}
                        disabled={props.loadingThirdParty}
                    />
                    {props.loginThirdPartyError && <FormHelperText style={{ marginTop: 5 }}>{props.loginErrorText}</FormHelperText>}

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 22 }}>
                        <Link href="https://ddh4.sharepoint.com/sites/Skovportalen" underline='always' className={classes.link}>
                            Klik her, hvis du ønsker at komme tilbage til Skovportalen
                        </Link>
                    </div>
                </FormControl>
            </div>
        </div>
    );
}