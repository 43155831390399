import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/CreateRounded';
import SaveIcon from '@material-ui/icons/SaveRounded';

// Styles
const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

// Component
class ChangeSaveButton extends React.Component {
  state = {
    loading: false,
    success: false,
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleButtonClick = () => {
    if (!this.state.loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          this.timer = setTimeout(() => {
            this.setState({
              loading: false,
              success: true,
            });
          }, 2000);
        },
      );
    }
  };

  buttonState() {
    const { classes, saveLoading, disabled } = this.props;
    //const { loading } = this.state;

    if (this.props.change === true) {
      return(
        <div className={classes.wrapper}>
          <Button
            variant="outlined"
            color="primary"
            disabled={disabled}
            //onClick={this.handleButtonClick}
            onClick={this.props.onPress}
          >
            <CreateIcon className={classes.extendedIcon} />
            Ændre
          </Button>
          {saveLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      );
    }
    return(
      <div className={classes.wrapper}>
          <Button
            variant="outlined"
            color="primary"
            disabled={disabled}
            onClick={this.props.onPress}
            //onClick={this.handleButtonClick}
          >
            <SaveIcon className={classes.extendedIcon} />
            Gem
          </Button>
          {saveLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.buttonState()}
      </div>
    );
  }
}

ChangeSaveButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangeSaveButton);