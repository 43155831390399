import * as actionTypes from './actionTypes';
import firebase from 'firebase';
import moment from 'moment';

export const newFeatureRequestSuccess = () => {
    return {
        type: actionTypes.NEW_FEATURE_REQUEST_SUCCESS,
    };
};

export const newFeatureRequestFail = (error) => {
    return {
        type: actionTypes.NEW_FEATURE_REQUEST_FAIL,
        error: error
    };
};

export const unmountNewFeatureRequest = () => {
    return {
        type: actionTypes.UNMOUNT_NEW_FEATURE_REQUEST,
    };
};


export const saveNewFeature = (headline, wish, userName, userEmail) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();

    // check for auth user
    if (user != null) {
        return dispatch => {
            db.collection("newFeatureRequests").add({
                headline,
                wish,
                user: user.uid,
                userName: userName,
                userEmail: userEmail,
                date: moment().format(), 
            })
            .then(function(docRef) {
                // Online
                dispatch(newFeatureRequestSuccess());
            })
            .catch(function(error) {
                console.error("Error: ", error);

                dispatch(newFeatureRequestFail(error));
            });
            // Offline
            dispatch(newFeatureRequestSuccess());
        }
    }

    // when not auth
    return dispatch => {

    }
}

export const unmountNewFeature = () => {
    return dispatch => {
        dispatch(unmountNewFeatureRequest());
    }
}
