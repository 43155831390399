import React from "react";
import Papa from 'papaparse';

import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SvgIcon from '@material-ui/core/SvgIcon';

// Material UI Icons
import DownloadCSV from '@material-ui/icons/GetAppRounded';

// Other icons
function DownloadCSVDisabled(props) {
   return (
      <SvgIcon {...props}>
         <path d="M9,6.17 L9,4 C9,3.45 9.45,3 10,3 L14,3 C14.55,3 15,3.45 15,4 L15,9 L16.59,9 C17.48,9 17.92,10.08 17.29,10.71 L15.41,12.59 L9,6.17 Z M20.49,20.49 L3.51,3.51 C3.12,3.12 2.49,3.12 2.1,3.51 C1.71,3.9 1.71,4.53 2.1,4.92 L6.6,9.42 C6.34,9.79 6.32,10.33 6.7,10.7 L11.29,15.29 C11.64,15.64 12.17,15.66 12.56,15.38 L15.17,18 L6,18 C5.45,18 5,18.45 5,19 C5,19.55 5.45,20 6,20 L17.17,20 L19.07,21.9 C19.46,22.29 20.09,22.29 20.48,21.9 C20.88,21.51 20.88,20.88 20.49,20.49 Z"></path>
      </SvgIcon>
   );
}

const styles = () => ({});

class Download extends React.Component {
   state = {};

   onDownload = (title, columns, data) => {
      // If download is disabled just return
      if (this.props.disabled) return;

      // Prepare data for download csv
      const downloadColumns = [];
      const downloadData = [];

      for (const item of columns) {
         if (item.checked) {
            if (item.id === 'area') {
               downloadColumns.push('Areal [ha]')
               downloadColumns.push('Areal (indb.%) [ha]')
            } else {
               downloadColumns.push(item.label)
            }
         }
      }

      for (const n of data) {
         let row = [];
         for (const r of columns) {
            if (r.checked) {
               if (r.id === 'note') {
                  row.push(n[r.id].replace(/(\r\n|\n|\r)/gm, " "))
               } else if (r.id === 'area') {
                  row.push(n.polyArea)
                  if (n.areaBearing) {
                     row.push(n.area)
                  } else {
                     row.push('i.a.b.')
                  }
               } else if (r.id === 'thinning') {
                  let cellThinning = '';

                  if (n.thinning === '-') {
                     cellThinning = '-';
                  } else {
                     cellThinning = n.thinning.map(el => {
                        const date = moment(new Date(el.date)).format('DD[-]MM[-]YYYY');
                        let tt = el.treeCountThinning ? (!isNaN(parseInt(el.treeCountThinning)) ? parseInt(el.treeCountThinning) : 0) : 0;
                        let vt = el.volumeThinning ? (!isNaN(parseInt(el.volumeThinning)) ? parseInt(el.volumeThinning) : 0) : 0;
                        let pt = el.percentageThinning ? (!isNaN(parseInt(el.percentageThinning)) ? parseInt(el.percentageThinning) : 0) : 0;

                        if (moment(el.date).isSameOrBefore(moment().endOf("day"))) {
                           if (tt === 0 && vt === 0) {
                              return date + ': ' + pt + ' % (Erstat hugstprocent med reelle tal.)';
                           } else if (tt !== 0 && vt === 0) {
                              return date + ': ' + tt + ' stk';
                           } if (tt === 0 && vt !== 0) {
                              return date + ': ' + vt + ' m3';
                           } else {
                              return date + ': ' + tt + ' stk - ' + vt + ' m3';
                           }
                        } else {
                           return date + ': ' + pt + ' %';
                        }
                     })
                     cellThinning = cellThinning.filter(el => el !== null).join(', ');
                  }
                  row.push(cellThinning)
               } else {
                  row.push(n[r.id])
               }
            }
         }

         downloadData.push(row);
      }

      const csvData = [downloadColumns, ...downloadData];

      const csv = Papa.unparse(csvData);

      const BOM = "\uFEFF"; // UTF-8 BOM
      const csvWithBOM = BOM + csv;
      const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = title;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // const options = {
      //    filename: `${title}`,
      //    showTitle: true,
      //    title: `${title}`,
      //    showLabels: true,
      //    headers: [downloadColumns]
      // };
      // const csvExporter = new ExportToCsv(options);
      // const test = csvExporter.generateCsv(downloadData, false);
   }

   render() {
      const { title, columns, data } = this.props;
      let toolTipTitle = this.props.disabled ? "Det er ikke muligt at downloade bevoksningslisten med en gratis profil" : "Download bevoksningsliste"
      let icon = this.props.disabled ? <DownloadCSVDisabled /> : <DownloadCSV />;
      return (
         <div>
            <Tooltip title={toolTipTitle}>
               <IconButton
                  aria-label="Download liste"
                  onClick={() => this.onDownload(title, columns, data)}
               >
                  {icon}
               </IconButton>
            </Tooltip>
         </div>
      )
   }
}

export default withStyles(styles)(Download);
