import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import Cookies from 'universal-cookie';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

// Material UI icons
import LayersIcon from '@material-ui/icons/LayersRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Checkbox from '@material-ui/core/Checkbox';
import FormatColorIcon from '@material-ui/icons/FormatColorFillRounded';

// Other
import * as actions from '../../../../store/actions/index';
import EmbeddedExpansionList from '../../EmbeddedExpansionList/EmbeddedExpansionList';
import HeaderMenu from '../UIElements/HeaderMenu';
import SpaceMenu from '../UIElements/SpaceMenu';
import InfoMenu from '../UIElements/InfoMenu';

// Own
import { getSpecieCategories } from '../../../Inventory/Utilities/speciesUtil';
import * as mapLayers from '../../../../maps/Utility/mapLayers';

const cookies = new Cookies();

const styles = theme => ({
    fabBottom: {
        position: 'absolute',
        zIndex: 1,
        right: 420 - 410,
        bottom: 10,
        backgroundColor: 'white'
    },
    fabMenuBottom: {
        position: 'absolute',
        zIndex: 101,
        width: '200px',
        right: 420 - 410,
        bottom: 78,
        backgroundColor: theme.palette.background.paper,
    },
    fabMenuMobile: {
        position: 'absolute',
        zIndex: 1,
        width: '200px',
        right: 68,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
    },
    checklist: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 0,
        paddingTop: 0,
    },
    moveIn: {
        transform: 'translate3d(-410px, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    moveOut: {
        transform: 'translate3d(0, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    secondary: {
        fontSize: 12
    },
});

const exportForestLayers = [ 1, 12, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29 ];
const exportHuntingLayers = [4];
const exportBiotopePlansLayers = [30];
const exportNotesLayers = [3];
const exportLayerName = {
    "Drift": "production",
    "Beskyttet natur": "protectedNature",
    "Søer og vandløb": "hydroLayer",
    "Veje og stier": "roadsTrails",
    "Intensivt drevet": "intensiveArea",
    "Biodiversitet": "biodiversityArea",
    "Urørt skov": "primevalForest",
    "Nøglebiotoper": "keyBiotope",
    "Nøgleelementer" : "keyElements",
    "§ 25-skov": "naturalValuableForest",
    "Publikum": "public",
    "Øvrige": "other"
}

const mapLayersTitle = [
    'Landkort',
    'Drift',
    'Linjer',
    'Noter',
    'Jagtkort',
    'Google Maps',
    'Ortokort forår 2022',
    'Ortokort forår 2022 (midlertidig)',
    'Højdekurver',
    'Skygge terræn',
    'Skygge overflade',
    'Lavningskort',
    'Beskyttet natur',
    'Fredskov',
    'Matrikelkort',
    'Ejerlav',
    'Markblokke',
    'Fortidsminder',
    'Natura 2000',
    'Punkter',
    'Søer og vandløb',
    'Veje og stier',
    'Intensivt drevet',
    'Biodiversitet',
    'Urørt skov',
    'Nøglebiotoper',
    'Nøgleelementer',
    '§ 25-skov',
    'Publikum',
    'Øvrige',
    'Biotopplaner',
    'Ortokort forår 2015',
    'Ortokort sommer 2008',
    "Skovrejsning",
    'Rødlistede arter',
    'HNV Skov',
    'Bevaringsværdigt egekrat',
    'Habitatdirektivets bilagsarter',
    'Boringsnære beskyttelsesområder',
];
const tooltipDescription = [
    'Landkort der ses bag skovkort',
    'Driftskov afbilledet ovenpå landkort',
    'Linjer afbilledet ovenpå landkort',
    'Noter afbilledet ovenpå landkort',
    'Jagtkort afbilledet ovenpå landkort',
    'Ortofotos fra Google Maps',
    'Ortofotos fra datafordeleren',
    'Midlertidig ortofotos fra kortforsyningen',
    'Højdekurver fra datafordeleren',
    'Terræn skyggekort fra datafordeleren',
    'Overflade skyggekort fra datafordeleren',
    'Lavningsdata fra Kortforsyningen',
    'Fredede områder, beskyttet naturtyper, beskyttet sten/jorddiger og beskyttede vandløb. Fra Miljøportalen',
    'Fredskov fra datafordeleren',
    'Matrikelkort fra datafordeleren',
    'Ejerlav fra datafordeleren',
    'Markblokke fra Landbrugsstyrelsen',
    'Fredet fortidsminder fra Slots- og Kulturstyrrelsen',
    'Natura2000, fuglebeskyttelse, habitatområder, ramsarområder. Fra Miljøportalen',
    'Punkter afbilledet ovenpå landkort',
    'Søer og vandløb afbilledet ovenpå landkort',
    'Veje og stier i skovkortet',
    'Arealer med intensiv produktion, så som juletrær',
    'Arealer prioriteret til biodiversitet',
    'Arealer som er lagt urørt',
    'Nøglebiotoper afbilledet i kortet',
    'Nøgleelementer afbilledet i kortet',
    'Naturmæssig særlig værdifuld skov områder afbilledet i kortet',
    'Publikumskort',
    'Øvrige arealer der ikke er relateret til skovkortet',
    'Biotopplaner',
    'Ortofotos fra datafordeleren',
    'Ortofotos fra dataforsyningen',
    'Ønsket og uønsket skovrejsningsområde',
    'Rødlistede arter: Regionalt uddøde, kritisk truet, truet, sårbar, næsten truet eller utilstrækkelige data.',
    'Høj natur værdi (HNV) for skov',
    'Bevaringsværdigt egekrat',
    'Lokaliteter hvor der er registreret bilag II-arter, bilag IV-arter eller bilag V-arter.',
    'Boringsnære beskyttelsesområder',
];

class MapLayersMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            checked: [1, 3, 5, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
            tempCountActiveLayers: 0,
            zIdx: 99,
            tempChosenEnvironment: '',
            forestActiveLayers: 0,
            natureActiveLayers: 0,
            audienceActiveLayers: 0,
            otherActiveLayers: 0,
            huntingActiveLayers: 0,
            backgroundActiveLayers: 0,
            specialActiveLayers: 0,
            filterValues: [],
            updateFilter: true,
            panel: false
        };
    }

    componentDidMount() {
        const layers = cookies.get('mapLayers');
        const environment = cookies.get('mapSelector');
        this.setState({ tempChosenEnvironment: environment })
        // Set mapLayers (actions)
        if (layers) {
            layers.forEach(item => {
                switch (item) {
                    case 0: this.props.onSetSimpleMapState(true); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 1: this.props.toggleMapLayer('production', true); break;
                    case 2: this.props.onSetLinesMapState(true); break;
                    case 3: this.props.onSetNotesMapState(true); break;
                    case 4: this.props.onSetMapHuntingLayerState(true); break;
                    case 5: this.props.onSetOrtoMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 6: this.props.onSetOrtoKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 7: this.props.onSetOrtoKfTempMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 8: this.props.onSetContourKfMapState(true); break;
                    case 9: this.props.onSetShadowKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 10: this.props.onSetShadowSurfKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 11: this.props.onSetBlueSpotMapState(true); break;
                    case 12: this.props.onSetProtectedNatureMapState(true); this.props.toggleMapLayer('protectedNature', true); break;
                    case 13: this.props.onSetFredskovMapState(true); break;
                    case 14: this.props.onSetMapCadastralState(true); break;
                    case 15: this.props.onSetMapCadastralOwnerState(true); break;
                    case 16: this.props.onSetMapMarkblokkeState(true); break;
                    case 17: this.props.onSetMapProtectedAncientAreasState(true); break;
                    case 18: this.props.onSetNatura2000MapState(true); break;
                    case 19: this.props.onSetPointsMapState(true); break;
                    case 20: this.props.toggleMapLayer('hydroLayer', true); break;
                    case 21: this.props.toggleMapLayer('roadsTrails', true); break;
                    case 22: this.props.toggleMapLayer('intensiveArea', true); break;
                    case 23: this.props.toggleMapLayer('biodiversityArea', true); break;
                    case 24: this.props.toggleMapLayer('primevalForest', true); break;
                    case 25: this.props.toggleMapLayer('keyBiotope', true); break;
                    case 26: this.props.toggleMapLayer('keyElements', true); break;
                    case 27: this.props.toggleMapLayer('naturalValuableForest', true); break;
                    case 28: this.props.toggleMapLayer('public', true); break;
                    case 29: this.props.toggleMapLayer('other', true); break;
                    case 30: this.props.toggleMapLayer('biotopePlan', true); break;
                    case 31: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 32: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); break;
                    case 33: this.props.toggleMapWMSLayer(mapLayers.forestErection, true); break;
                    case 34: this.props.toggleMapWMSLayer(mapLayers.redListSpeciesLayer, true); break;
                    case 35: this.props.toggleMapWMSLayer(mapLayers.hnvForestLayer, true); break;
                    case 36: this.props.toggleMapWMSLayer(mapLayers.protectedOakGrove, true); break;
                    case 37: this.props.toggleMapWMSLayer(mapLayers.appendixSpeciesLayer, true); break;
                    case 38: this.props.toggleMapWMSLayer(mapLayers.bnboLayer, true); break;
                    default: this.props.onSetOrtoMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                }
            });

            // Set mapLayers (internal state)
            this.setState({ checked: layers })
        } else {
            // Set mapLayers (internal state)
            // this.setState({ checked: this.state.checked })
            this.state.checked.forEach(item => {
                switch (item) {
                    case 0: this.props.onSetSimpleMapState(true); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 1: this.props.toggleMapLayer('production', true); break;
                    case 2: this.props.onSetLinesMapState(true); break;
                    case 3: this.props.onSetNotesMapState(true); break;
                    case 4: this.props.onSetMapHuntingLayerState(true); break;
                    case 5: this.props.onSetOrtoMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 6: this.props.onSetOrtoKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 7: this.props.onSetOrtoKfTempMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 8: this.props.onSetContourKfMapState(true); break;
                    case 9: this.props.onSetShadowKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 10: this.props.onSetShadowSurfKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                    case 11: this.props.onSetBlueSpotMapState(true); break;
                    case 12: this.props.onSetProtectedNatureMapState(true); this.props.toggleMapLayer('protectedNature', true); break;
                    case 13: this.props.onSetFredskovMapState(true); break;
                    case 14: this.props.onSetMapCadastralState(true); break;
                    case 15: this.props.onSetMapCadastralOwnerState(true); break;
                    case 16: this.props.onSetMapMarkblokkeState(true); break;
                    case 17: this.props.onSetMapProtectedAncientAreasState(true); break;
                    case 18: this.props.onSetNatura2000MapState(true); break;
                    case 19: this.props.onSetPointsMapState(true); break;
                    case 20: this.props.toggleMapLayer('hydroLayer', true); break;
                    case 21: this.props.toggleMapLayer('roadsTrails', true); break;
                    case 22: this.props.toggleMapLayer('intensiveArea', true); break;
                    case 23: this.props.toggleMapLayer('biodiversityArea', true); break;
                    case 24: this.props.toggleMapLayer('primevalForest', true); break;
                    case 25: this.props.toggleMapLayer('keyBiotope', true); break;
                    case 26: this.props.toggleMapLayer('keyElements', true); break;
                    case 27: this.props.toggleMapLayer('naturalValuableForest', true); break;
                    case 28: this.props.toggleMapLayer('public', true); break;
                    case 29: this.props.toggleMapLayer('other', true); break;
                    case 30: this.props.toggleMapLayer('biotopePlan', true); break;
                    case 31: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); break;
                    case 32: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); break;
                    case 33: this.props.toggleMapWMSLayer(mapLayers.forestErection, true); break;
                    case 34: this.props.toggleMapWMSLayer(mapLayers.redListSpeciesLayer, true); break;
                    case 35: this.props.toggleMapWMSLayer(mapLayers.hnvForestLayer, true); break;
                    case 36: this.props.toggleMapWMSLayer(mapLayers.protectedOakGrove, true); break;
                    case 37: this.props.toggleMapWMSLayer(mapLayers.appendixSpeciesLayer, true); break;
                    case 38: this.props.toggleMapWMSLayer(mapLayers.bnboLayer, true); break;
                    default: this.props.onSetOrtoMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                }
            });
        }
    }

    componentDidUpdate() {
        const { checked } = this.state;
        let value = null;

        // Set export layers in reducer
        if (this.state.checked.length !== this.state.tempCountActiveLayers) {
            let activeForestLayers = [];
            let activeHuntingLayers = [];
            let activeBiotopePlanLayers = [];
            let activeNotesLayers = [];
            this.state.checked.forEach(el => {
                if (el) {
                    if (exportForestLayers.includes(el)) {
                        const num = el;
                        const layerTitle = mapLayersTitle[el];
                        const layerDescription = tooltipDescription[el];
                        const layerName = exportLayerName[layerTitle];
                        activeForestLayers.push({ num, layerName, layerTitle, layerDescription })
                    }
                }
            })
            this.props.onSetExportLayers('forest', activeForestLayers);
            this.setState({ tempCountActiveLayers: this.state.checked.length })
        }

        // Bring map layer menu to front
        if (this.props.menuBringToFrontId === 'mapLayer') {
            if (this.state.zIdx !== 101) {
                this.setState({ zIdx: 101 });
            }
        } else {
            if (this.state.zIdx !== 99) {
                this.setState({ zIdx: 99 });
            }
        }

        // Update layers according to map selector
        if (this.props.chosenWorkEnvironment !== this.state.tempChosenEnvironment) {
            if (this.props.chosenWorkEnvironment === 'forest' && !this.state.checked.includes(1)) {
                this.props.toggleMapLayer('production', true);
                value = 1;
            } else if (this.props.chosenWorkEnvironment === 'hunting' && !this.state.checked.includes(4)) {
                this.props.onSetMapHuntingLayerState(true);
                value = 4;
            } else if (this.props.chosenWorkEnvironment === 'biotopePlan' && !this.state.checked.includes(30)) {
                this.props.toggleMapLayer('biotopePlan', true);
                value = 30;
            }
            // Set checked array in state
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);
                this.setState({
                    checked: newChecked,
                });
            }
            this.setState({ tempChosenEnvironment: this.props.chosenWorkEnvironment })
        }

        // Set point layer active if new point is added to the map
        if (!this.props.pointsState && this.props.chosenWorkEnvironment === 'forest' && this.props.newPoint) {
            this.props.toggleMapLayer('production', true);
            value = 1;
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);

                this.setState({
                    checked: newChecked,
                });
            }
        }
        // Set line layer active if new line is added to the map
        if (!this.props.linesState && this.props.chosenWorkEnvironment === 'forest' && this.props.newLine) {
            this.props.toggleMapLayer('production', true);
            value = 1;
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);

                this.setState({
                    checked: newChecked,
                });
            }
        }
        // Set cells layer active if new line is added to the map
        if (!this.state.checked.includes(1) && this.props.chosenWorkEnvironment === 'forest' && this.props.newPoly) {
            this.props.toggleMapLayer('production', true);
            value = 1;
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
            if (currentIndex === -1) {
                newChecked.push(value);

                this.setState({
                    checked: newChecked,
                });
            }
        }
    }

    handleClick = () => {
        this.setState({ open: (this.state.open ? false : true) })
        this.props.onSetMapMenuBringToFrontId('mapLayer');

        // Onboarding action if active
        if (this.props.onboardingActive && this.props.onboardingState === 'changeMap') {
            this.props.onSetOnboardingState('newCustomerIntro');
        }

        // Send event to Analytics
        ReactGA.event({
            category: 'Map layer',
            action: `Map layer open: ${!this.state.open}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    };

    cardClickHandler() {
        this.props.onSetMapMenuBringToFrontId('mapLayer');
    }

    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
            // If background layer remove other background layers
            if (value === 0) {
                let idx = newChecked.indexOf(5);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(6);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(7);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(9);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(10);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(31);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(32);
                if (idx !== -1) newChecked.splice(idx, 1);
            } else if (value === 5) {
                let idx = newChecked.indexOf(0);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(6);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(7);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(9);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(10);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(31);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(32);
                if (idx !== -1) newChecked.splice(idx, 1);
            } else if (value === 6) {
                let idx = newChecked.indexOf(0);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(5);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(7);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(9);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(10);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(31);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(32);
                if (idx !== -1) newChecked.splice(idx, 1);
            } else if (value === 7) {
                let idx = newChecked.indexOf(0);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(5);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(6);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(9);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(10);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(31);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(32);
                if (idx !== -1) newChecked.splice(idx, 1);
            } else if (value === 9) {
                let idx = newChecked.indexOf(0);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(5);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(6);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(7);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(10);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(31);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(32);
                if (idx !== -1) newChecked.splice(idx, 1);
            } else if (value === 10) {
                let idx = newChecked.indexOf(0);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(5);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(6);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(7);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(9);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(31);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(32);
                if (idx !== -1) newChecked.splice(idx, 1);
            } else if (value === 31) {
                let idx = newChecked.indexOf(0);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(5);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(6);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(7);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(9);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(10);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(32);
                if (idx !== -1) newChecked.splice(idx, 1);
            } else if (value === 32) {
                let idx = newChecked.indexOf(0);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(5);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(6);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(7);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(9);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(10);
                if (idx !== -1) newChecked.splice(idx, 1);
                idx = newChecked.indexOf(31);
                if (idx !== -1) newChecked.splice(idx, 1);
            }

            // Dispatch layer state to reducer
            switch (value) {
                case 0: this.props.onSetSimpleMapState(true); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 1: this.props.toggleMapLayer('production', true); break;
                case 2: this.props.onSetLinesMapState(true); break;
                case 3: this.props.onSetNotesMapState(true); break;
                case 4: this.props.onSetMapHuntingLayerState(true); break;
                case 5: this.props.onSetOrtoMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 6: this.props.onSetOrtoKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 7: this.props.onSetOrtoKfTempMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 8: this.props.onSetContourKfMapState(true); break;
                case 9: this.props.onSetShadowKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 10: this.props.onSetShadowSurfKfMapState(true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 11: this.props.onSetBlueSpotMapState(true); break;
                case 12: this.props.onSetProtectedNatureMapState(true); this.props.toggleMapLayer('protectedNature', true); break;
                case 13: this.props.onSetFredskovMapState(true); break;
                case 14: this.props.onSetMapCadastralState(true); break;
                case 15: this.props.onSetMapCadastralOwnerState(true); break;
                case 16: this.props.onSetMapMarkblokkeState(true); break;
                case 17: this.props.onSetMapProtectedAncientAreasState(true); break;
                case 18: this.props.onSetNatura2000MapState(true); break;
                case 19: this.props.onSetPointsMapState(true); break;
                case 20: this.props.toggleMapLayer('hydroLayer', true); break;
                case 21: this.props.toggleMapLayer('roadsTrails', true); break;
                case 22: this.props.toggleMapLayer('intensiveArea', true); break;
                case 23: this.props.toggleMapLayer('biodiversityArea', true); break;
                case 24: this.props.toggleMapLayer('primevalForest', true); break;
                case 25: this.props.toggleMapLayer('keyBiotope', true); break;
                case 26: this.props.toggleMapLayer('keyElements', true); break;
                case 27: this.props.toggleMapLayer('naturalValuableForest', true); break;
                case 28: this.props.toggleMapLayer('public', true); break;
                case 29: this.props.toggleMapLayer('other', true); break;
                case 30: this.props.toggleMapLayer('biotopePlan', true); break;
                case 31: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 32: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, true); this.props.onSetSimpleMapState(false); this.props.onSetOrtoMapState(false); this.props.onSetOrtoKfMapState(false); this.props.onSetShadowSurfKfMapState(false); this.props.onSetOrtoKfTempMapState(false); this.props.onSetShadowKfMapState(false); this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); break;
                case 33: this.props.toggleMapWMSLayer(mapLayers.forestErection, true); break;
                case 34: this.props.toggleMapWMSLayer(mapLayers.redListSpeciesLayer, true); break;
                case 35: this.props.toggleMapWMSLayer(mapLayers.hnvForestLayer, true); break;
                case 36: this.props.toggleMapWMSLayer(mapLayers.protectedOakGrove, true); break;
                case 37: this.props.toggleMapWMSLayer(mapLayers.appendixSpeciesLayer, true); break;
                case 38: this.props.toggleMapWMSLayer(mapLayers.bnboLayer, true); break;
                default:
            }
        } else {
            newChecked.splice(currentIndex, 1);
            switch (value) {
                case 0: this.props.onSetSimpleMapState(false); break;
                case 1: this.props.toggleMapLayer('production', false); break;
                case 2: this.props.onSetLinesMapState(false); break;
                case 3: this.props.onSetNotesMapState(false); break;
                case 4: this.props.onSetMapHuntingLayerState(false); break;
                case 5: this.props.onSetOrtoMapState(false); break;
                case 6: this.props.onSetOrtoKfMapState(false); break;
                case 7: this.props.onSetOrtoKfTempMapState(false); break;
                case 8: this.props.onSetContourKfMapState(false); break;
                case 9: this.props.onSetShadowKfMapState(false); break;
                case 10: this.props.onSetShadowSurfKfMapState(false); break;
                case 11: this.props.onSetBlueSpotMapState(false); break;
                case 12: this.props.onSetProtectedNatureMapState(false); this.props.toggleMapLayer('protectedNature', false); break;
                case 13: this.props.onSetFredskovMapState(false); break;
                case 14: this.props.onSetMapCadastralState(false); break;
                case 15: this.props.onSetMapCadastralOwnerState(false); break;
                case 16: this.props.onSetMapMarkblokkeState(false); break;
                case 17: this.props.onSetMapProtectedAncientAreasState(false); break;
                case 18: this.props.onSetNatura2000MapState(false); break;
                case 19: this.props.onSetPointsMapState(false); break;
                case 20: this.props.toggleMapLayer('hydroLayer', false); break;
                case 21: this.props.toggleMapLayer('roadsTrails', false); break;
                case 22: this.props.toggleMapLayer('intensiveArea', false); break;
                case 23: this.props.toggleMapLayer('biodiversityArea', false); break;
                case 24: this.props.toggleMapLayer('primevalForest', false); break;
                case 25: this.props.toggleMapLayer('keyBiotope', false); break;
                case 26: this.props.toggleMapLayer('keyElements', false); break;
                case 27: this.props.toggleMapLayer('naturalValuableForest', false); break;
                case 28: this.props.toggleMapLayer('public', false); break;
                case 29: this.props.toggleMapLayer('other', false); break;
                case 30: this.props.toggleMapLayer('biotopePlan', false); break;
                case 31: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2015, false); break;
                case 32: this.props.toggleMapWMSLayer(mapLayers.ortoKfLayer2008, false); break;
                case 33: this.props.toggleMapWMSLayer(mapLayers.forestErection, false); break;
                case 34: this.props.toggleMapWMSLayer(mapLayers.redListSpeciesLayer, false); break;
                case 35: this.props.toggleMapWMSLayer(mapLayers.hnvForestLayer, false); break;
                case 36: this.props.toggleMapWMSLayer(mapLayers.protectedOakGrove, false); break;
                case 37: this.props.toggleMapWMSLayer(mapLayers.appendixSpeciesLayer, false); break;
                case 38: this.props.toggleMapWMSLayer(mapLayers.bnboLayer, false); break;
                default:
            }
        }

        cookies.set('mapLayers', JSON.stringify(newChecked), { path: '/' });

        this.setState({
            checked: newChecked,
        });
    };

    togglePolygonOpacityHandler = () => {
        this.props.onSetMapCellsOpacityState(!this.props.cellsOpacityState)
    }

    handlePanel = (panel) => {
        this.setState({ panel: panel === this.state.panel ? false : panel })
    }

    setFilterIds = (cells, points, filterValues) => {
        if (filterValues.length === 0) {
            this.props.onSetFilterCellIds(null);
            this.props.onSetShowFilterInMap(false);
            this.props.onRedrawCells();
            return;
        }
        let cellIds = [];
        let pointIds = [];
        // Run through cells and points
        cells.forEach(cell => {
            if (this.applyFilterOnElement(cell, filterValues)) {
                cellIds.push(cell.cellData.id)
            }

        })
        points.forEach(point => {
            if (this.applyFilterOnElement(point, filterValues)) {
                pointIds.push(point.id)
            }
        })
        this.props.onSetFilterCellIds(cellIds);
        this.props.onSetShowFilterInMap(true);
        this.props.onRedrawCells();
    }
    // Apply filter on one data point. returns true/false, true if data should be shown in the map
    applyFilterOnElement = (element, filterValues) => {
        // First check data against species specifik parameters
        // Fetch species parameters based on point or cell
        let categories = [];
        if (element.pointType) {
            categories = getSpecieCategories(element.pointType);
        } else {
            categories = getSpecieCategories(element.cellData.species);
        }
        // Check if any element of categories are in filterValues
        if (categories && categories.some(el => filterValues.includes(el))) {
            return true;
        }

        // Next check for values in element data
        return false;
    }

    listAudience() {
        const { classes } = this.props;
        let arr = [28]

        // Find number of active layers
        const notChecked = arr.filter(value => {
            return this.state.checked.indexOf(value) === -1;
        })
        const badgeNum = arr.length - notChecked.length;
        if (badgeNum !== this.state.audienceActiveLayers) {
            this.setState({ audienceActiveLayers: badgeNum })
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: 500 }}>
                    {arr.map(value => (
                        <Tooltip
                            key={value}
                            title={tooltipDescription[value] === undefined ? '' : tooltipDescription[value]}
                            aria-label={tooltipDescription[value]}
                        >
                            <ListItem
                                key={value}
                                role={undefined}
                                dense
                                button onClick={this.handleToggle(value)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={mapLayersTitle[value]}
                                    secondary={null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>
            </List>
        )
    }

    listOther() {
        const { classes } = this.props;
        let arr = [29]

        // Find number of active layers
        const notChecked = arr.filter(value => {
            return this.state.checked.indexOf(value) === -1;
        })
        const badgeNum = arr.length - notChecked.length;
        if (badgeNum !== this.state.otherActiveLayers) {
            this.setState({ otherActiveLayers: badgeNum })
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: 500 }}>
                    {arr.map(value => (
                        <Tooltip
                            key={value}
                            title={tooltipDescription[value] === undefined ? '' : tooltipDescription[value]}
                            aria-label={tooltipDescription[value]}
                        >
                            <ListItem
                                key={value}
                                role={undefined}
                                dense
                                button onClick={this.handleToggle(value)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={mapLayersTitle[value]}
                                    secondary={null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>
            </List>
        )
    }

    listForest() {
        const { classes } = this.props;
        let arr = [1, 21, 20, 22, 23, 24, 3];
        if (this.props.currentAuthUser.userRole === "forestOwner" && !this.props.natureActive && !this.props.operationActive) {
            arr = [1, 21, 20, 3];
        }

        // Find number of active layers
        const notChecked = arr.filter(value => {
            return this.state.checked.indexOf(value) === -1;
        })
        const badgeNum = arr.length - notChecked.length;
        if (badgeNum !== this.state.forestActiveLayers) {
            this.setState({ forestActiveLayers: badgeNum })
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: 500 }}>
                    {arr.map(value => (
                        <Tooltip
                            key={value}
                            title={tooltipDescription[value] === undefined ? '' : tooltipDescription[value]}
                            aria-label={tooltipDescription[value]}
                        >
                            <ListItem
                                key={value}
                                role={undefined}
                                dense
                                button onClick={this.handleToggle(value)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={mapLayersTitle[value]}
                                    secondary={null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>
            </List>
        )
    }

    listNature() {
        const { classes } = this.props;
        let arr = [12, 36, 38, 17, 13, 37, 35, 18, 25, 26, 34, 27]
        if (this.props.currentAuthUser.userRole === "forestOwner" && !this.props.operationActive && !this.props.natureActive) {
            arr = [12, 18];
        } else if (this.props.currentAuthUser.userRole === "forestOwner" && this.props.natureActive) {
            arr = [12, 17, 13, 18, 25, 26, 27];
        } 
        // if (this.props.currentAuthUser.userRole === "forestOwner") {
        //     if (this.props.natureActive) {
        //         arr = [12, 17, 13, 18, 25, 26, 27]
        //     } else if (this.props.operationActive) {
        //         arr = [12, 36, 38, 17, 13, 37, 35, 18, 25, 26, 34, 27];
        //     } else {
        //         arr = [12, 18];
        //     }   
        // }

        // Find number of active layers
        const notChecked = arr.filter(value => {
            return this.state.checked.indexOf(value) === -1;
        })
        const badgeNum = arr.length - notChecked.length;
        if (badgeNum !== this.state.natureActiveLayers) {
            this.setState({ natureActiveLayers: badgeNum })
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: 500 }}>
                    {arr.map(value => (
                        <Tooltip
                            key={value}
                            title={tooltipDescription[value] === undefined ? '' : tooltipDescription[value]}
                            aria-label={tooltipDescription[value]}
                        >
                            <ListItem
                                key={value}
                                role={undefined}
                                dense
                                button onClick={this.handleToggle(value)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={mapLayersTitle[value]}
                                    secondary={null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>
            </List>
        )
    }


    listBackground() {
        const { classes } = this.props;
        let arr = [0, 5, 6, 31, 32, 9, 10]
        if (this.props.currentAuthUser.userRole === "forestOwner" && !this.props.operationActive) {
            arr = [0, 5, 6, 10];
        } else if (this.props.currentAuthUser.userRole === "forestOwner" && this.props.operationActive) {
            arr = [0, 5, 6, 9, 10];
        }

        // Find number of active layers
        const notChecked = arr.filter(value => {
            return this.state.checked.indexOf(value) === -1;
        })
        const badgeNum = arr.length - notChecked.length;
        if (badgeNum !== this.state.backgroundActiveLayers) {
            this.setState({ backgroundActiveLayers: badgeNum })
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: 500 }}>
                    {arr.map(value => (
                        <Tooltip
                            key={value}
                            title={tooltipDescription[value] === undefined ? '' : tooltipDescription[value]}
                            aria-label={tooltipDescription[value]}
                        >
                            <ListItem
                                key={value}
                                role={undefined}
                                dense
                                button onClick={this.handleToggle(value)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={mapLayersTitle[value]}
                                    secondary={null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>
            </List>
        )
    }

    listHunting() {
        const { classes } = this.props;
        let arr = [4];
        if (this.props.huntingPlusActive) {
            arr = [4, 30];
        }

        // Find number of active layers
        const notChecked = arr.filter(value => {
            return this.state.checked.indexOf(value) === -1;
        })
        const badgeNum = arr.length - notChecked.length;
        if (badgeNum !== this.state.huntingActiveLayers) {
            this.setState({ huntingActiveLayers: badgeNum })
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: 500 }}>
                    {arr.map(value => (
                        <Tooltip
                            key={value}
                            title={tooltipDescription[value] === undefined ? '' : tooltipDescription[value]}
                            aria-label={tooltipDescription[value]}
                        >
                            <ListItem
                                key={value}
                                role={undefined}
                                dense
                                button onClick={this.handleToggle(value)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={mapLayersTitle[value]}
                                    secondary={null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>
            </List>
        )
    }

    listSpecial() {
        const { classes } = this.props;
        let arr = [15, 8, 11, 16, 14, 33];
        if (this.props.currentAuthUser.userRole === "forestOwner" && !this.props.operationActive) {
            arr = [11, 14];
        }

        // Find number of active layers
        const notChecked = arr.filter(value => {
            return this.state.checked.indexOf(value) === -1;
        })
        const badgeNum = arr.length - notChecked.length;
        if (badgeNum !== this.state.specialActiveLayers) {
            this.setState({ specialActiveLayers: badgeNum })
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: 500 }}>
                    {arr.map(value => (
                        <Tooltip
                            key={value}
                            title={tooltipDescription[value] === undefined ? '' : tooltipDescription[value]}
                            aria-label={tooltipDescription[value]}
                        >
                            <ListItem
                                key={value}
                                role={undefined}
                                dense
                                button onClick={this.handleToggle(value)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={mapLayersTitle[value]}
                                    secondary={null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>
            </List>
        )
    }

    cardContent() {
        const { screenHeight, mobile } = this.props;

        return (
            <div>
                <div style={{ overflow: 'auto', minHeight: 165, maxHeight: screenHeight - (mobile ? 166 : 233) }}>
                    <EmbeddedExpansionList
                        badgeCount={this.state.forestActiveLayers}
                        title='Skovkort'
                        onPress={this.handlePanel}
                        expanded={this.state.panel === 'Skovkort' && true}
                    >
                        {this.listForest()}
                    </EmbeddedExpansionList>

                    <EmbeddedExpansionList
                        badgeCount={this.state.natureActiveLayers}
                        title='Naturkort'
                        onPress={this.handlePanel}
                        expanded={this.state.panel === 'Naturkort' && true}
                    >
                        {this.listNature()}
                    </EmbeddedExpansionList>

                    <EmbeddedExpansionList
                        badgeCount={this.state.huntingActiveLayers}
                        title='Jagtkort'
                        onPress={this.handlePanel}
                        expanded={this.state.panel === 'Jagtkort' && true}
                    >
                        {this.listHunting()}
                    </EmbeddedExpansionList>

                    <EmbeddedExpansionList
                        badgeCount={this.state.audienceActiveLayers}
                        title='Publikum'
                        onPress={this.handlePanel}
                        expanded={this.state.panel === 'Publikum' && true}
                    >
                        {this.listAudience()}
                    </EmbeddedExpansionList>

                    <EmbeddedExpansionList
                        badgeCount={this.state.otherActiveLayers}
                        title='Øvrige kort'
                        onPress={this.handlePanel}
                        expanded={this.state.panel === 'Øvrige kort' && true}
                    >
                        {this.listOther()}
                    </EmbeddedExpansionList>

                    <EmbeddedExpansionList
                        badgeCount={this.state.backgroundActiveLayers}
                        title='Baggrundskort'
                        onPress={this.handlePanel}
                        expanded={this.state.panel === 'Baggrundskort' && true}
                    >
                        {this.listBackground()}
                    </EmbeddedExpansionList>

                    <EmbeddedExpansionList
                        badgeCount={this.state.specialActiveLayers}
                        title='Specialkort'
                        onPress={this.handlePanel}
                        expanded={this.state.panel === 'Specialkort' && true}
                    >
                        {this.listSpecial()}
                    </EmbeddedExpansionList>
                </div>

                <SpaceMenu />

                <Tooltip title="Til- eller fravalg af polygonfarver" aria-label="">
                    <Button
                        aria-label="opacity"
                        onClick={this.togglePolygonOpacityHandler}
                        fullWidth
                        variant="outlined"
                        style={{ color: !this.props.cellsOpacityState ? '#BEBEBE' : '#5EBD3E' }}
                    >
                        Polygonfarver

                        <FormatColorIcon fontSize="small" style={{ marginLeft: 6, color: !this.props.cellsOpacityState ? '#BEBEBE' : '#5EBD3E' }} />
                    </Button>
                </Tooltip>

                <SpaceMenu />

                <InfoMenu
                    content='Menuerne er scrollbar'
                    margin='4px 0px 0px 0px'
                />

                <SpaceMenu />
            </div>
        )
    }

    render() {
        const { classes } = this.props;

        // Onboarding: Set z-index and close drawing menu if not at correct step
        let impZidx = this.props.onboardingActive ? this.props.onboardingMapLayerZidx : this.state.zIdx;
        if (this.props.onboardingActive && (this.props.onboardingState === 'welcome' || this.props.onboardingState === 'newCustomerEnd' || this.props.onboardingState === 'drawCellIntro')) {
            if (this.state.open) {
                this.setState({ open: false })
            }
        }

        if (this.props.onboardingActive && this.props.onboardingState === 'drawCellCreate') {
            impZidx = this.state.zIdx;
        }

        const fab = classNames(classes.fabBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);
        const fabMenu = classNames(classes.fabMenuBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);

        return (
            <div>
                {!this.props.mobile && <Tooltip title="Kortdetaljer" aria-label="Kortdetaljer">
                    <Fab
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick.bind(this)}
                        className={fab}
                        style={{ zIndex: this.props.onboardingMapLayerZidx }}
                    >
                        {this.state.open ? <CloseIcon style={{ color: '#808080' }} /> : <LayersIcon style={{ color: '#41842b' }} />}
                    </Fab>
                </Tooltip>}

                {/* NORMAL VERSION --- */}
                {this.state.open && <Card
                    className={fabMenu}
                    style={{ zIndex: impZidx }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Kortdetaljer'
                            onPress={this.handleClick.bind(this)}
                        />

                        <SpaceMenu />

                        {this.cardContent()}
                    </CardContent>
                </Card>}

                {/* MOBILE VERSION */}
                {((this.props.mapLayerShouldOpen && !this.props.onboardingActive) || (this.props.mapLayerShouldOpen && this.props.onboardingActive && (this.props.onboardingState === 'changeMap' || this.props.onboardingState === 'newCustomerIntro'))) && <Card
                    className={classes.fabMenuMobile}
                    style={{ zIndex: 102 }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Kortdetaljer'
                            onPress={this.props.onPress}
                        />

                        <SpaceMenu />

                        {this.cardContent()}
                    </CardContent>
                </Card>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        selectedForest: state.customer.currentlySelectedForestId,
        cellsState: state.maps.cellsState,
        linesState: state.maps.linesState,
        pointsState: state.maps.pointsState,
        pointEditableLatLng: state.notes.pointEditableLatLng,
        pointEditable: state.notes.pointEditable,
        pointEditableId: state.notes.pointEditableId,
        cellsOpacityState: state.maps.cellsOpacityState,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        onboardingMapLayerZidx: state.onboarding.mapLayerZidx,
        menuBringToFrontId: state.maps.mapMenuBringToFrontId,
        sidebar: state.layout.sidebarOpen,
        huntingActive: state.account.huntingActive,
        huntingPlusActive: state.account.huntingPlusActive,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
        natureActive: state.account.natureActive,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        cells: state.maps.cells,
        points: state.maps.points,
        filterCellIds: state.inventory.filterCellIds,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetExportLayers: (lay, arr) => dispatch(actions.setExportLayers(lay, arr)),
        onSetSimpleMapState: (s) => dispatch(actions.setMapSimpleState(s)),
        onSetOrtoMapState: (s) => dispatch(actions.setMapOrtoState(s)),
        onSetOrtoKfMapState: (s) => dispatch(actions.setMapOrtoKfState(s)),
        onSetOrtoKfTempMapState: (s) => dispatch(actions.setMapOrtoKfTempState(s)),
        onSetContourKfMapState: (s) => dispatch(actions.setMapContourKfState(s)),
        onSetShadowKfMapState: (s) => dispatch(actions.setMapShadowKfState(s)),
        onSetShadowSurfKfMapState: (s) => dispatch(actions.setMapShadowSurfKfState(s)),
        onSetBlueSpotMapState: (s) => dispatch(actions.setMapBlueSpotState(s)),
        onSetProtectedNatureMapState: (s) => dispatch(actions.setMapProtectedNatureState(s)),
        onSetFredskovMapState: (s) => dispatch(actions.setMapFredskovState(s)),
        onSetNatura2000MapState: (s) => dispatch(actions.setMapNatura2000State(s)),
        onSetMapHuntingLayerState: (s) => dispatch(actions.setMapHuntingLayerState(s)),
        onSetCellsMapState: (s) => dispatch(actions.setMapCellsState(s)),
        onSetMapCellsOpacityState: (s) => dispatch(actions.setMapCellsOpacityState(s)),
        onSetLinesMapState: (s) => dispatch(actions.setMapLinesState(s)),
        onSetPointsMapState: (s) => dispatch(actions.setMapPointsState(s)),
        onSetNotesMapState: (s) => dispatch(actions.setMapNotesState(s)),
        onSetMapCadastralState: (s) => dispatch(actions.setMapCadastralState(s)),
        onSetMapCadastralOwnerState: (s) => dispatch(actions.setMapCadastralOwnerState(s)),
        onSetMapMarkblokkeState: (s) => dispatch(actions.setMapMarkblokkeState(s)),
        onSetMapProtectedAncientAreasState: (s) => dispatch(actions.setMapProtectedAncientAreasState(s)),
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSetMapMenuBringToFrontId: (id) => dispatch(actions.setMapMenuBringToFrontId(id)),
        onSetFilterCellIds: (s) => dispatch(actions.setFilterCellIds(s)),
        onSetShowFilterInMap: (s) => dispatch(actions.setShowFilterInMap(s)),
        onRedrawCells: () => dispatch(actions.redrawCells()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapLayersMenu));