export const profPayed = [
    { id: "section", checked: true, numeric: true, disablePadding: true, label: "Afd.", initRange: [], newRange: [] },
    { id: "litra", checked: true, numeric: false, disablePadding: true, label: "Litra" },
    { id: "category", checked: true, numeric: false, disablePadding: true, label: "Anvendelse" },
    { id: "species", checked: true, numeric: false, disablePadding: true, label: "Træart / Linjetype" },
    { id: "year", checked: true, numeric: true, disablePadding: true, label: "Årstal / (alder)", initRange: [], newRange: [] },
    { id: "thinning", checked: true, numeric: false, disablePadding: true, label: "Udtyndet", initRange: [], newRange: [] },
    { id: "lineLength", checked: true, numeric: true, disablePadding: true, label: "Længde [m]", initRange: [], newRange: [] },
    { id: "area", checked: true, numeric: true, disablePadding: true, label: "Areal / (Indb.%) [ha]", initRange: [], newRange: [] },
    { id: "mix", checked: true, numeric: true, disablePadding: true, label: "Indb. [%]", initRange: [], newRange: [] },
    { id: "quality", checked: true, numeric: true, disablePadding: true, label: "Bonitet", initRange: [], newRange: [] },
    { id: "height", checked: true, numeric: true, disablePadding: true, label: "Højde [m]", initRange: [], newRange: [] },
    { id: "diameter", checked: true, numeric: true, disablePadding: true, label: "Diameter [cm]", initRange: [], newRange: [] },
    { id: "treeCount", checked: true, numeric: true, disablePadding: true, label: "Stamtal", initRange: [], newRange: [] },
    { id: "groundArea", checked: true, numeric: true, disablePadding: true, label: "Grundflade [m2/ha]", initRange: [], newRange: [] },
    { id: "volume", checked: true, numeric: true, disablePadding: true, label: "Vedm. [m3]", initRange: [], newRange: [] },
    { id: "CO2_e", checked: true, numeric: true, disablePadding: true, label: "CO2-e [ton]", initRange: [], newRange: [] },
    { id: "note", checked: true, numeric: false, disablePadding: true, label: "Bemærk." },
];

export const profUnpayed = [
    { id: "section", checked: true, numeric: true, disablePadding: true, label: "Afd.", initRange: [], newRange: [] },
    { id: "litra", checked: true, numeric: false, disablePadding: true, label: "Litra" },
    { id: "category", checked: true, numeric: false, disablePadding: true, label: "Anvendelse" },
    { id: "species", checked: true, numeric: false, disablePadding: true, label: "Træart / Linjetype" },
    { id: "year", checked: true, numeric: true, disablePadding: true, label: "Årstal / (alder)", initRange: [], newRange: [] },
    { id: "thinning", checked: true, numeric: false, disablePadding: true, label: "Udtyndet", initRange: [], newRange: [] },
    { id: "lineLength", checked: true, numeric: true, disablePadding: true, label: "Længde [m]", initRange: [], newRange: [] },
    { id: "area", checked: true, numeric: true, disablePadding: true, label: "Areal / (Indb.%) [ha]", initRange: [], newRange: [] },
    { id: "mix", checked: true, numeric: true, disablePadding: true, label: "Indb. [%]", initRange: [], newRange: [] },
    // { id: "coverage", checked: true, numeric: true, disablePadding: true, label: "Bev. kvo. [%]", initRange: [], newRange: [] },
    { id: "quality", checked: true, numeric: true, disablePadding: true, label: "Bonitet", initRange: [], newRange: [] },
    { id: "height", checked: true, numeric: true, disablePadding: true, label: "Højde [m]", initRange: [], newRange: [] },
    { id: "diameter", checked: true, numeric: true, disablePadding: true, label: "Diameter [cm]", initRange: [], newRange: [] },
    { id: "treeCount", checked: true, numeric: true, disablePadding: true, label: "Stamtal", initRange: [], newRange: [] },
    { id: "groundArea", checked: true, numeric: true, disablePadding: true, label: "Grundflade [m2/ha]", initRange: [], newRange: [] },
    { id: "volume", checked: true, numeric: true, disablePadding: true, label: "Vedm. [m3]", initRange: [], newRange: [] },
    { id: "CO2_e", checked: true, numeric: true, disablePadding: true, label: "CO2-e [ton]", initRange: [], newRange: [] },
    { id: "note", checked: true, numeric: false, disablePadding: true, label: "Bemærk." },
];

export const naturePayed = [
    { id: "section", checked: true, numeric: true, disablePadding: true, label: "Afd.", initRange: [], newRange: [] },
    { id: "litra", checked: true, numeric: false, disablePadding: true, label: "Litra" },
    { id: "category", checked: true, numeric: false, disablePadding: true, label: "Anvendelse" },
    { id: "species", checked: true, numeric: false, disablePadding: true, label: "Træart / Linjetype" },
    { id: "year", checked: true, numeric: true, disablePadding: true, label: "Årstal / (alder)", initRange: [], newRange: [] },
    { id: "lineLength", checked: true, numeric: true, disablePadding: true, label: "Længde [m]", initRange: [], newRange: [] },
    { id: "area", checked: true, numeric: true, disablePadding: true, label: "Areal / (Indb.%) [ha]", initRange: [], newRange: [] },
    { id: "mix", checked: true, numeric: true, disablePadding: true, label: "Indb. [%]", initRange: [], newRange: [] },
    { id: "height", checked: true, numeric: true, disablePadding: true, label: "Højde [m]", initRange: [], newRange: [] },
    { id: "diameter", checked: true, numeric: true, disablePadding: true, label: "Diameter [cm]", initRange: [], newRange: [] },
    { id: "treeCount", checked: true, numeric: true, disablePadding: true, label: "Stamtal", initRange: [], newRange: [] },
    { id: "volume", checked: true, numeric: true, disablePadding: true, label: "Vedm. [m3]", initRange: [], newRange: [] },
    { id: "CO2_e", checked: true, numeric: true, disablePadding: true, label: "CO2-e [ton]", initRange: [], newRange: [] },
    { id: "note", checked: true, numeric: false, disablePadding: true, label: "Bemærk." },
];

export const natureUnpayed = [
    { id: "section", checked: true, numeric: true, disablePadding: true, label: "Afd.", initRange: [], newRange: [] },
    { id: "litra", checked: true, numeric: false, disablePadding: true, label: "Litra" },
    { id: "category", checked: true, numeric: false, disablePadding: true, label: "Anvendelse" },
    { id: "species", checked: true, numeric: false, disablePadding: true, label: "Træart / Linjetype" },
    { id: "year", checked: true, numeric: true, disablePadding: true, label: "Årstal / (alder)", initRange: [], newRange: [] },
    { id: "lineLength", checked: true, numeric: true, disablePadding: true, label: "Længde [m]", initRange: [], newRange: [] },
    { id: "area", checked: true, numeric: true, disablePadding: true, label: "Areal / (Indb.%) [ha]", initRange: [], newRange: [] },
    { id: "mix", checked: true, numeric: true, disablePadding: true, label: "Indb. [%]", initRange: [], newRange: [] },
    { id: "height", checked: true, numeric: true, disablePadding: true, label: "Højde [m]", initRange: [], newRange: [] },
    { id: "diameter", checked: true, numeric: true, disablePadding: true, label: "Diameter [cm]", initRange: [], newRange: [] },
    { id: "treeCount", checked: true, numeric: true, disablePadding: true, label: "Stamtal", initRange: [], newRange: [] },
    { id: "volume", checked: true, numeric: true, disablePadding: true, label: "Vedm. [m3]", initRange: [], newRange: [] },
    { id: "CO2_e", checked: true, numeric: true, disablePadding: true, label: "CO2-e [ton]", initRange: [], newRange: [] },
    { id: "note", checked: true, numeric: false, disablePadding: true, label: "Bemærk." },
];
