/* eslint-disable no-undef */
// CSS
import './style.css';

import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import L from "leaflet";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
//import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Fade from '@material-ui/core/Fade';

// Icons
import SearchIcon from '@material-ui/icons/SearchRounded';
//import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';

// Import utility functions
import { isNumeric } from './utility';

// Import actions 
import * as actions from '../../../store/actions/index';

// Use google maps functionality from script loaded in index.html
const google = window.google = window.google ? window.google : {}

const styles = () => ({
    fab: {
        position: 'absolute',
        zIndex: 2,
        left: '331px',
        top: '10px',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    searchBoxContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingRight: 6,
    },
});

// Search Icon
let searchIcon = L.icon({
    iconUrl: require('./location_small.png'),
    iconSize: [36, 46],
    iconAnchor: [16, 30],
});

class AddressSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zIdx: 99,
            searchOpen: false,
            address: '',
            searchSuggestions: null,
        };
    }

    componentDidMount() {
        // Load google maps places
        const options = {
            language: 'da', // render results in DK
            region: 'dk', // prioritize matches within DK
            componentRestrictions: { country: "dk" },
            strictBounds: false,
            fields: ["geometry", "name"],
        };  

        try {
            this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), options)
            this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
        } catch (err) {
            console.error("Error: creating google places autocomplete instance", err)
        }
    }

    componentDidUpdate() {
        if (this.props.mobile) {
            // If search is closed clear all
            if (!this.props.findAddressShouldOpen) {
                try {
                    this.autocomplete.set('place', null);
                } catch (err) {
                    console.error("Set autocomplete error", err)
                }
                
                this.removeSearchMarker()
                this.props.onSetFilterCellIds(null);
                this.props.onSetShowFilterInMap(false);
                this.props.onRedrawCells();
            }
        }
    }


    handlePlaceSelect = () => {
        // Get place data from the search input
        let placeObject = this.autocomplete.getPlace();

        // Check if place object has been cleared and clear text value in input
        if (placeObject === null) {
            document.getElementById('autocomplete').value = '';
            return;
        }

        if (!placeObject.geometry || !placeObject.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.

            // Check if input is lat/lng
            let tokStr = placeObject.name.replace(/\s/g, '').split(',');
            if (tokStr.length === 2 && isFinite(tokStr[0]) && Math.abs(tokStr[0]) <= 90 && isFinite(tokStr[1]) && Math.abs(tokStr[1]) <= 180) {
                // If forest is selected, deselect it
                if (this.props.selectedForest) {
                    this.props.onSetCurrentlySelectedForestId(null)
                }
                // Get latitude and longitude and fly to location
                const lat = parseFloat(tokStr[0]);
                const lng = parseFloat(tokStr[1]);
                this.flyToLocationAndSetMarker(null, { lat: lat, lng: lng });
                // Send event to Analytics
                ReactGA.event({
                    category: 'Search',
                    action: `LatLng search`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
                return;
            }

            // Check if input is section and litra
            tokStr = placeObject.name.replace(/\s/g, '').split(';');
            tokStr = tokStr.map(t => t.toLowerCase());
            if (tokStr.length === 1 && tokStr[0].match(/^\d/)) {
                // Check if forest has been selected, else fire snack bar
                if (!this.props.selectedForest) {
                    this.props.onEnqueueSnackbar({
                        message: 'Du skal vælge en skov før du kan søge på litra.',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'info',
                        },
                    });
                    return;
                }
                // check if litra exist in data, else fire snack bar
                // Split string in numbers and letters
                const secLitraArr = tokStr[0].match(/[a-zA-Z]+|[0-9]+/g);
                let foundCells = [];
                if (this.props.cells && this.props.cells[this.props.selectedForest]) {
                    if (secLitraArr.length === 1 && isNumeric(secLitraArr[0])) {
                        foundCells = this.props.cells[this.props.selectedForest].filter(cell => {
                            const cellSecLitArr = cell.cellData.litra.match(/[a-zA-Z]+|[0-9]+/g);
                            if (cellSecLitArr) {
                                return  secLitraArr[0] === cellSecLitArr[0];
                            }
                        })
                    } else {
                        foundCells = this.props.cells[this.props.selectedForest].filter(cell => {
                            return tokStr[0] === cell.cellData.litra.toLowerCase();
                        })
                    }
                }
                const cellIds = foundCells.map(cell => cell.cellData.id);
                this.props.onSetFilterCellIds(cellIds);
                this.props.onSetShowFilterInMap(true);
                this.props.onRedrawCells();

                // Send event to Analytics
                ReactGA.event({
                    category: 'Search',
                    action: `Litra search`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
                return;
            } else if (tokStr.length > 1) {
                // Check if forest has been selected, else fire snack bar
                if (!this.props.selectedForest) {
                    this.props.onEnqueueSnackbar({
                        message: 'Du skal vælge en skov før du kan søge på litra nr.',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'info',
                        },
                    });
                    return;
                }
                // Find litras that are being searched for
                if (this.props.cells && this.props.cells[this.props.selectedForest]) {
                    const foundCells = this.props.cells[this.props.selectedForest].filter(cell => {
                        // Check for just section number search
                        const cellSecLitArr = cell.cellData.litra.match(/[a-zA-Z]+|[0-9]+/g);
                        if (cellSecLitArr) {
                            return tokStr.includes(cell.cellData.litra.toLowerCase()) || tokStr.includes(cellSecLitArr[0]);
                        }
                    })
                    const cellIds = foundCells.map(cell => cell.cellData.id);
                    this.props.onSetFilterCellIds(cellIds);
                    this.props.onSetShowFilterInMap(true);
                    this.props.onRedrawCells();
                }
                // Send event to Analytics
                ReactGA.event({
                    category: 'Search',
                    action: `Litra search`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
                return;
            }

            // If all fails fire snackbar
            this.props.onEnqueueSnackbar({
                message: 'Din søgning gav ingen resultater.',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                },
            });
            // Send event to Analytics
            ReactGA.event({
                category: 'Search',
                action: `No search results`,
                label: `User: ${this.props.currentAuthUser.name}`
            });
            return;
        }

        // If search object exist get location and fly to location
        this.flyToLocationAndSetMarker(null, { lat: placeObject.geometry.location.lat(), lng: placeObject.geometry.location.lng() });
        // Send event to Analytics
        ReactGA.event({
            category: 'Search',
            action: `Address search`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        return;
    }



    handleClick() {
        // If search is already open clear all and close
        if (this.state.searchOpen) {
            this.autocomplete.set('place', null);
            this.removeSearchMarker()
            this.props.onSetFilterCellIds(null);
            this.props.onSetShowFilterInMap(false);
            this.props.onRedrawCells();
        }

        this.setState({ searchOpen: (this.state.searchOpen ? false : true) })

        // Send event to Analytics
        ReactGA.event({
            category: 'Address search',
            action: `Address search open: ${!this.state.searchOpen}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    removeSearchMarker = () => {
        const searchMarkerId = Object.values(this.props.map._layers).filter(el => {
            return el.options.id === 'searchMarker'
        })
        if (searchMarkerId.length > 0) {
            this.props.map.removeLayer(searchMarkerId[0])
        }
    }

    onSearch() {
        // Deselecte forest if org and forest selected
        if (this.props.org) {
            this.props.onSetCurrentlySelectedForestId(null)
        }
        // Check search string to see if it is lat/lng
        let tokStr = this.state.address.split(',');
        // Check if input is lat,lng
        if (tokStr.length === 2 && isFinite(tokStr[0]) && Math.abs(tokStr[0]) <= 90 && isFinite(tokStr[1]) && Math.abs(tokStr[1]) <= 180) {
            // Search string is a valid lat/lng
            const lat = parseFloat(tokStr[0]);
            const lng = parseFloat(tokStr[1]);
            const boundConst = 0.0001;
            const bounds = [[lat - boundConst, lng - boundConst], [lat + boundConst, lng + boundConst]];
            this.flyToLocationAndSetMarker(bounds, [lat, lng]);
        } else {
            provider.search({ query: this.state.address }).then(response => {
                if (response.length > 1) {
                    this.props.onEnqueueSnackbar({
                        message: 'Din søgning gav flere resultater. Du føres til første fundne adresse.',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'info',
                        },
                    });
                } else if (response < 1) {
                    this.props.onEnqueueSnackbar({
                        message: 'Din søgning gav ingen resultater.',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'info',
                        },
                    });
                } else {
                    this.flyToLocationAndSetMarker(response[0].bounds, [response[0].y, response[0].x]);
                }

            });
        }

        // Send event to Analytics
        ReactGA.event({
            category: 'Address search',
            action: `Address search`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    flyToLocationAndSetMarker = (bounds, latLng) => {
        // Set bounds if not present
        let locBounds = bounds;
        const boundConst = 0.0001;
        if (!locBounds && !latLng) {
            // Fire snackbar
            return;
        } else if (!locBounds) {
            locBounds = [[latLng.lat - boundConst, latLng.lng - boundConst], [latLng.lat + boundConst, latLng.lng + boundConst]];
        }
        // Fly to bounds
        try {
            this.props.map.flyToBounds(locBounds, {
                animate: true,
                duration: 2,
                maxZoom: 18,
            });
            // Remove existing search marker
            this.removeSearchMarker();

            // Place new marker
            L.marker(latLng, {
                icon: searchIcon,
                id: "searchMarker",
                snappable: false,
                snapIgnore: true,
                riseOnHover: true,
            }).addTo(this.props.map)
        }
        catch (err) {
            console.warn("No address found, error : ", err)
        }
    }

    renderInput() {
        const { classes } = this.props;

        return (
            <div
                className={classes.searchBoxContent}
                style={{ paddingLeft: this.props.mobile ? 52 : 48 }}
            >
                <InputBase
                    id='autocomplete'
                    inputProps={{ 'aria-label': 'naked' }}
                    placeholder="Adresse eller litra nr."
                    fullWidth={true}
                    noWrap={true}
                    style={{ minWidth: this.props.mobile ? 180 : 260 }}
                />
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        // Onboarding logic
        if (this.props.onboardingActive && this.state.searchOpen) {
            this.setState({ searchOpen: false });
            this.removeSearchMarker()
        }

        const searchBtnColor = (this.props.onboardingActive) ? '#F5F5F5' : 'white';
        const searchBtnDisable = (this.props.onboardingActive) ? true : false

        return (
            <div>
                {!this.props.mobile && <Tooltip title="Find adresse eller litra" aria-label="Find adresse">
                    <Fab
                        size="small"
                        onClick={this.handleClick.bind(this)}
                        className={classes.fab}
                        style={{
                            backgroundColor: searchBtnColor,
                            zIndex: this.props.searchBtnZidx,
                        }}
                        disabled={searchBtnDisable}
                    >
                        {this.state.searchOpen ? <CloseIcon style={{ color: '#808080' }} /> : <SearchIcon style={{ color: '#41842b' }} />}
                    </Fab>
                </Tooltip>}

                {/* BOTH VERSIONS */}
                <Fade in={this.state.searchOpen || this.props.findAddressShouldOpen}>
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 3,
                            height: this.props.mobile ? 48 : 40,
                            borderRadius: this.props.mobile ? 24 : 20,
                            left: this.props.mobile ? 70 : 331,
                            top: 10,
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: this.props.mobile ? 62 : null,
                        }}
                    >
                        {this.renderInput()}
                    </div>
                </Fade>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        searchBtnZidx: state.onboarding.searchBtnZidx,
        org: state.org.org,
        selectedForest: state.customer.currentlySelectedForestId,
        cells: state.maps.cells
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetCurrentlySelectedForestId: (forestId) => dispatch(actions.setCurrentlySelectedForestId(forestId)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onSetFilterCellIds: (s) => dispatch(actions.setFilterCellIds(s)),
        onSetShowFilterInMap: (s) => dispatch(actions.setShowFilterInMap(s)),
        onRedrawCells: () => dispatch(actions.redrawCells()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddressSearchBox));