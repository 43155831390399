import React, { Component } from 'react';
import { connect } from 'react-redux';

import Aux from '../hoc/Aux/Aux';
//import OverviewMap from '../maps/OverviewMap';
import SideBar from '../components/SideBar/SideBar';
import ForestWidget from '../components/UI/Widgets/Forest/ForestWidget';
import EconomyWidget from '../components/UI/Widgets/EconomyWidget/EconomyWidget';
import DocumentsWidget from '../components/UI/Widgets/DocumentsWidget/DocumentsWidget';
import CustomerWidget from '../components/UI/Widgets/Customer/CustomerWidget';
import PeopleWidget from '../components/UI/Widgets/People/PeopleWidget';
import NotesWidget from '../components/Notes/NotesWidget';
import WeatherWidget from '../components/UI/Widgets/WeatherWidget/NewWeatherWidget';
import NewsWidget from '../components/UI/Widgets/NewsWidget/NewsWidget';
import BiotopeplansWidget from '../components/UI/Widgets/BiotopePlan/BiotopePlansWidget';
//import MapWidget from '../components/UI/Widgets/MapWidget/MapWidget';

class overviewMap extends Component {
    render() {
        // Check if customer widget should be displayed based on user tags
        let customerShowUserTags = true;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))) {
            customerShowUserTags = true;
        }
        // Set content
        const forest = this.props.onboardingNotesWidgetActive ? <div style={{ marginBottom: 10 }}><ForestWidget />
        </div> : <div></div>;
        const documents = this.props.onboardingNotesWidgetActive ? <div style={{ marginBottom: 10 }}><DocumentsWidget />
        </div> : <div></div>;
        const economy = this.props.onboardingNotesWidgetActive ? <div style={{ marginBottom: 10 }}><EconomyWidget />
        </div> : <div></div>;
        const cust = this.props.onboardingCustomerWidgetActive ? <div style={{ marginBottom: 10 }}><CustomerWidget showBackButton={false} />
        </div> : <div></div>;
        const peop = this.props.onboardingNewsWidgetActive ? <div style={{ marginBottom: 10 }}><PeopleWidget showBackButton={false} />
        </div> : <div></div>;
        const biotope = this.props.onboardingNewsWidgetActive ? <div style={{ marginBottom: 10 }}><BiotopeplansWidget showBackButton={false} />
        </div> : <div></div>;
        const nts = this.props.onboardingNotesWidgetActive ? <div style={{ marginBottom: 10 }}><NotesWidget numNotesShown={3} showBackButton={false} />
        </div> : <div></div>;
        const weat = this.props.onboardingWeatherWidgetActive ? <div style={{ marginBottom: 10 }}> <WeatherWidget />
        </div> : <div></div>;
        const news = this.props.onboardingNewsWidgetActive ? <div><NewsWidget /></div> : <div></div>;
        // Set widget order based on user role (default owner)
        let content = <div>
            {this.props.config.overviewWidget === 'enabled' && forest}
            {this.props.config.economyWidget === 'enabled' && economy}
            {this.props.config.notesWidget === 'enabled' && nts}
            {(this.props.config.estatesWidget === 'enabled' && this.props.chosenWorkEnvironment !== 'biotopePlan') && cust}
            {(this.props.config.biotopePlansWidget === 'enabled' && this.props.chosenWorkEnvironment === 'biotopePlan') && biotope}
            {this.props.config.documentsWidget === 'enabled' && documents}
            {this.props.config.weatherWidget === 'enabled' && weat}
            {this.props.config.newsWidget === 'enabled' && news}
        </div>
        if (this.props.currentAuthUser && this.props.currentAuthUser.userRole === 'forester') {
            content = <div>
                {this.props.config.overviewWidget === 'enabled' && forest}
                {(this.props.config.estatesWidget === 'enabled' && this.props.chosenWorkEnvironment !== 'biotopePlan') && cust}
                {(this.props.config.biotopePlansWidget === 'enabled' && this.props.chosenWorkEnvironment === 'biotopePlan') && biotope}
                {this.props.config.notesWidget === 'enabled' && nts}
                {this.props.config.economyWidget === 'enabled' && economy}
                {this.props.config.documentsWidget === 'enabled' && documents}
                {this.props.config.peopleWidget === 'enabled' && peop}
                {this.props.config.weatherWidget === 'enabled' && weat}
                {this.props.config.newsWidget === 'enabled' && news}
            </div>
        }

        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        {content}
                    </div>
                } />
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        onboardingCustomerWidgetActive: state.onboarding.customerWidgetActive,
        onboardingNotesWidgetActive: state.onboarding.notesWidgetActive,
        onboardingWeatherWidgetActive: state.onboarding.weatherWidgetActive,
        onboardingNewsWidgetActive: state.onboarding.newsWidgetActive,
        config: state.appConfig.sideBar,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(overviewMap);

