import React from "react";
import firebase from "firebase";
import configFirebase from '../../../../backend/configFirebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material UI Icons
import NoteAddIcon from '@material-ui/icons/CloudUploadRounded';

// Store
import * as actions from '../../../../store/actions/index';

// Components
import StatLine from '../../Display/StatLine';
import SpaceMenu from '../../Menus/UIElements/SpaceMenu';
import FilesList from '../../Lists/FilesList';

const cookies = new Cookies();

const styles = () => ({
    cardContent: {
        paddingTop: '4px',
        position: 'relative',
        margin: 'auto',
        width: '100%',
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    statIcon: {
        height: 20,
        width: 20,
        borderRadius: 3
    },
    addDocumentBtnIcon: {
        marginRight: 6
    },
    buttonProgress: {
        color: '5EBD3E',
        position: 'absolute',
    },
});

class EconomyWidget extends React.Component {
    constructor(props) {
        super(props);
        const economyWidgetState = cookies.get('economyWidgetState');
        this.state = {
            open: economyWidgetState === 'close' ? false : true,
            economyDataLoading: false,
            fileError: null,
            noEconomyData: false,
            tempSelectedForest: null,
            tempEconomyDocId: null,
            updateData: false,
            administration: null,
            fieldNature: null,
            finance: null,
            forest: null,
            generalExpenditures: null,
            houses: null,
            total: null,
            dataTimestamp: null,
            forestOpen: false,
            fieldNatureOpen: false,
            housesOpen: false,
            administrationOpen: false,
            generalExpendituresOpen: false,
            financeOpen: false,
            totalOpen: false,
            financialStats: {
                name: "financialStats",
                folderName: "financialStatsDocuments",
                open: false,
                unread: false,
            },
        };
    }

    componentDidMount() {
        this.updateData()
    }

    componentDidUpdate() {
        if (this.state.tempSelectedForest !== this.props.selectedForest) {
            this.updateData();
            this.setState({ tempSelectedForest: this.props.selectedForest })
        }
        if (this.props.selectedForest && this.props.customerList[this.props.selectedForest].economyDocId && this.state.tempEconomyDocId !== this.props.customerList[this.props.selectedForest].economyDocId) {
            this.updateData();
            this.setState({ tempEconomyDocId: this.props.customerList[this.props.selectedForest].economyDocId })
        }
    }

    updateData = () => {
        if (this.props.selectedForest && this.props.customerList[this.props.selectedForest].economyDocId) {
            this.fetchEconomyData(this.props.selectedForest, this.props.customerList[this.props.selectedForest].economyDocId);
        } else {
            this.setState({
                fileError: null,
                noEconomyData: true,
                administration: null,
                fieldNature: null,
                finance: null,
                forest: null,
                generalExpenditures: null,
                houses: null,
                total: null,
                dataTimestamp: null,
            })
        }
    }

    fetchEconomyData = async (forestId, docId) => {
        const db = firebase.firestore();
        this.setState({ economyDataLoading: true })
        try {
            const ecoDoc = await db.collection('forests').doc(forestId).collection('economy').doc(docId).get();
            const docData = ecoDoc.data();
            this.setState({
                fileError: docData.fileError ? docData.fileError : null,
                administration: (docData.administration && docData.administration.yearToDate && docData.administration.year && (docData.administration.yearToDate.DB !== 0 || docData.administration.year.budget !== 0) ) ? docData.administration : null,
                fieldNature: (docData.fieldNature && docData.fieldNature.yearToDate && docData.fieldNature.year && (docData.fieldNature.yearToDate.DB !== 0 || docData.fieldNature.year.budget !== 0) ) ? docData.fieldNature : null,
                finance: (docData.finance && docData.finance.yearToDate && docData.finance.year && (docData.finance.yearToDate.DB !== 0 || docData.finance.year.budget !== 0) ) ? docData.finance : null,
                forest: (docData.forest && docData.forest.yearToDate && docData.forest.year && (docData.forest.yearToDate.DB !== 0 || docData.forest.year.budget !== 0) ) ? docData.forest : null,
                generalExpenditures: (docData.generalExpenditures && docData.generalExpenditures.yearToDate && docData.generalExpenditures.year && ( docData.generalExpenditures.yearToDate.DB !== 0 || docData.generalExpenditures.year.budget !== 0) ) ? docData.generalExpenditures : null,
                houses: (docData.houses && docData.houses.yearToDate && docData.houses.year && (docData.houses.yearToDate.DB !== 0 || docData.houses.year.budget !== 0) ) ? docData.houses : null,
                total: (docData.total && docData.total.yearToDate && docData.total.year && (docData.total.yearToDate.DB !== 0 || docData.total.year.budget !== 0) ) ? docData.total : null,
                dataTimestamp: docData.timestamp ? docData.timestamp : null,
                noEconomyData: false,
                economyDataLoading: false,
            })

        } catch (error) {
            this.setState({ economyDataLoading: false })
            console.error("Error fetching economy data", error)
        }
    }

    mapFileErrorToText = (fileError) => {
        switch(fileError) {
            case "File is not excel": return "Økonomital kan ikke beregnes. Filen er ikke xlsx (excel)!";
            case "File is empty": return "Økonomital kan ikke beregnes. Filen er tom!"; 
            default: return "Økonomital kan ikke beregnes. Fejl i fil.";
        }
    }

    onAddDocument = documentState => event => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Economywidget',
            action: 'Add document',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        // Add new documents
        let files = []
        for (var i = 0; i < event.target.files.length; i++) {
            files[i] = event.target.files.item(i);
        }
        this.props.onUploadDocument(files, configFirebase.economyDocsBucket, this.props.selectedForest, documentState);
        // Reset error
        this.setState({fileError: null})
    }

    onOpenDocument = (doc) => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Economywidget',
            action: 'Opened document',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        // Create link and download file in new window
        const link = document.createElement("a");
        link.href = doc.url;
        link.target = "_blank";
        const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        link.dispatchEvent(evt);

        // Set file read state on firestore
        this.props.onSetDocumentReadState(doc.forestId, doc.id)
    }

    onDeleteDocument = (doc) => {
        // Send event to Analytics
        ReactGA.event({
            category: 'Economywidget',
            action: 'Deleted document',
            label: `User: ${this.props.currentAuthUser.name}`
        });
        // Delete document
        this.props.onDeleteDocument(doc.forestId, doc.id)
    }

    expandStat(value) {
        this.setState({ [value]: !this.state[value] })
    }

    expandAction() {
        this.setState({ open: this.state.open ? false : true });

        cookies.set('economyWidgetState', this.state.open ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'Economywidget',
            action: `Economywidget: ${this.state.open ? 'close' : 'open'}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    commify = (n) => {
        if (typeof n !== 'number') return n;
        // Commify number
        const parts = n.toFixed(0).split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
    }

    renderExpansionContent = (obj) => {
        return (
            <div style={{ paddingLeft: 10 }}>
                <SpaceMenu />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='body2'>
                        Indtægter
                    </Typography>

                    <Typography variant='body2' style={{ fontWeight: 500 }}>
                        {this.commify(obj.yearToDate.revenue) + ' kr'}
                    </Typography>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='body2'>
                        Udgifter
                    </Typography>

                    <Typography variant='body2' style={{ color: 'red', fontWeight: 500 }}>
                        {this.commify(obj.yearToDate.expenditure) + ' kr'}
                    </Typography>
                </div>

                <SpaceMenu multiplier={2} />
            </div>
        )
    }

    renderDocumentSection = (documents, docObj) => {
        const { classes } = this.props;
        const documentList = documents.filter(doc => doc.folderName === docObj.folderName);
        const unread = documentList.filter(el => !el.readState).length > 0;
        if (unread !== docObj.unread) {
            let updatedObj = { ...this.state[docObj.name] };
            updatedObj.unread = unread;
            this.setState({ [docObj.name]: updatedObj })
        }
        return (
            <div style={{ paddingLeft: 10 }}>
                <SpaceMenu multiplier={2} />

                <input
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    id={docObj.folderName}
                    type="file"
                    disabled={!this.props.selectedForest}
                    onChange={this.onAddDocument(docObj.folderName)}
                />
                <label htmlFor={docObj.folderName}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                component="span"
                                disabled={this.props.loading}
                                color="primary"
                                variant="outlined"
                            >
                                <NoteAddIcon className={classes.addDocumentBtnIcon} />

                                Tilføj beregningsdokument
                            </Button>

                            {this.props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </label>

                <SpaceMenu multiplier={2} />

                <Divider />

                <FilesList
                    documents={documentList}
                    loading={this.props.loading}
                    onDeleteDocument={this.onDeleteDocument}
                    onOpenDocument={this.onOpenDocument}
                />

                <Divider />

                <SpaceMenu multiplier={2} />
            </div>
        )
    }

    renderDate = (title, timestamp) => {
        return (
            <Typography
                variant="caption"
                style={{ color: '#747474' }}
            >
                {title + moment(timestamp).format('DD[-]MM[-]YYYY HH:mm')}
            </Typography>
        )
    }

    render() {
        const { classes } = this.props;
        // Check that the forest has an economy doc
        // if (!this.props.selectedForest || !this.props.customerList[this.props.selectedForest].economyDocId) return null;
        if (!this.props.selectedForest) return null;
        // If document exist
        return (
            <div>
                <ExpansionPanel
                    expanded={this.state.open}
                    onChange={() => this.expandAction()}
                >
                    <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">
                            Økonomi
                        </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={classes.cardContent}>
                        <Divider />

                        {(this.props.config.documents === 'enabled' && this.props.fileList[this.props.selectedForest]) && <div>
                            {this.renderDocumentSection(this.props.fileList[this.props.selectedForest], this.state.financialStats)}

                            <Divider />
                        </div>}

                        <SpaceMenu multiplier={2} />

                        {this.state.economyDataLoading && <div style={{ height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        </div>}

                        {!this.state.economyDataLoading && this.state.fileError && <Typography style={{ padding: '0px 8px', color: '#747474' }}>
                            {this.mapFileErrorToText(this.state.fileError)}
                        </Typography>}

                        {!this.state.economyDataLoading && !this.state.fileError && this.state.noEconomyData && <Typography style={{ padding: '0px 8px', color: '#747474' }}>
                            Økonomital ikke tilgængelige.
                        </Typography>}

                        {!this.state.economyDataLoading && !this.state.fileError && !this.state.noEconomyData && <div>
                            {this.state.forest && <StatLine
                                icon={<div className={classes.statIcon} style={{ border: '2px solid #A9D08E' }} />}
                                title="Skovdrift"
                                stat={this.commify((this.state.forest.yearToDate.revenue + this.state.forest.yearToDate.expenditure))}
                                statAdornment="kr"
                                progress={this.commify(this.state.forest.year.index)}
                                expansionContent={this.renderExpansionContent(this.state.forest)}
                                onExpandStat={() => this.expandStat('forestOpen')}
                                open={this.state.forestOpen}
                            />}

                            <SpaceMenu />

                            {this.state.fieldNature && <StatLine
                                icon={<div className={classes.statIcon} style={{ border: '2px solid #FFE698' }} />}
                                title="Markbrug/Naturpleje"
                                stat={this.commify((this.state.fieldNature.yearToDate.revenue + this.state.fieldNature.yearToDate.expenditure))}
                                statAdornment="kr"
                                progress={this.commify(this.state.fieldNature.year.index)}
                                expansionContent={this.renderExpansionContent(this.state.fieldNature)}
                                onExpandStat={() => this.expandStat('fieldNatureOpen')}
                                open={this.state.fieldNatureOpen}
                            />}

                            {this.state.fieldNature && <SpaceMenu />}

                            {this.state.houses && <StatLine
                                icon={<div className={classes.statIcon} style={{ border: '2px solid #F4B084' }} />}
                                title="Huse"
                                stat={this.commify((this.state.houses.yearToDate.revenue + this.state.houses.yearToDate.expenditure))}
                                statAdornment="kr"
                                progress={this.commify(this.state.houses.year.index)}
                                expansionContent={this.renderExpansionContent(this.state.houses)}
                                onExpandStat={() => this.expandStat('housesOpen')}
                                open={this.state.housesOpen}
                            />}

                            {this.state.houses && <SpaceMenu />}

                            {this.state.administration && <StatLine
                                icon={<div className={classes.statIcon} style={{ border: '2px solid #D0CECE' }} />}
                                title="Administration"
                                stat={this.commify((this.state.administration.yearToDate.revenue + this.state.administration.yearToDate.expenditure))}
                                statAdornment="kr"
                                progress={this.commify(this.state.administration.year.index)}
                                expansionContent={this.renderExpansionContent(this.state.administration)}
                                onExpandStat={() => this.expandStat('administrationOpen')}
                                open={this.state.administrationOpen}
                            />}

                            {this.state.administration && <SpaceMenu />}

                            {this.state.generalExpenditures && <StatLine
                                icon={<div className={classes.statIcon} style={{ border: '2px solid #AEAAAA' }} />}
                                title="Generalomkostninger"
                                stat={this.commify((this.state.generalExpenditures.yearToDate.revenue + this.state.generalExpenditures.yearToDate.expenditure))}
                                statAdornment="kr"
                                progress={this.commify(this.state.generalExpenditures.year.index)}
                                expansionContent={this.renderExpansionContent(this.state.generalExpenditures)}
                                onExpandStat={() => this.expandStat('generalExpendituresOpen')}
                                open={this.state.generalExpendituresOpen}
                            />}

                            {this.state.generalExpenditures && <SpaceMenu />}

                            {this.state.finance && <StatLine
                                icon={<div className={classes.statIcon} style={{ border: '2px solid #8EA9DB' }} />}
                                title="Finans"
                                stat={this.commify((this.state.finance.yearToDate.revenue + this.state.finance.yearToDate.expenditure))}
                                statAdornment="kr"
                                progress={this.commify(this.state.finance.year.index)}
                                expansionContent={this.renderExpansionContent(this.state.finance)}
                                onExpandStat={() => this.expandStat('financeOpen')}
                                open={this.state.financeOpen}
                            />}

                            {this.state.finance && <SpaceMenu />}

                            {this.state.total && <StatLine
                                icon={<div className={classes.statIcon} style={{ border: '2px solid black' }} />}
                                title="I alt"
                                stat={this.commify((this.state.total.yearToDate.revenue + this.state.total.yearToDate.expenditure))}
                                statAdornment="kr"
                                progress={this.commify(this.state.total.year.index)}
                                expansionContent={this.renderExpansionContent(this.state.total)}
                                onExpandStat={() => this.expandStat('totalOpen')}
                                open={this.state.totalOpen}
                            />}

                            {this.state.total && <SpaceMenu />}

                            <Typography
                                variant="caption"
                                style={{ color: '#747474' }}
                            >
                                (alle poster er ÅTD)
                            </Typography>
                        </div>}

                        <SpaceMenu multiplier={2} />

                        <Divider />

                        <SpaceMenu />

                        {this.state.dataTimestamp && this.renderDate("Opdateret: ", this.state.dataTimestamp)}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        mapDataUpdated: state.maps.mapDataUpdated,
        customerList: state.customer.customerList,
        selectedForest: state.customer.currentlySelectedForestId,
        currentAuthUser: state.user.currentAuthUser,
        org: state.org.org,
        loading: state.documents.loading,
        error: state.documents.error,
        fileList: state.documents.fileList,
        config: state.appConfig.economyWidget,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetTotalForestArea: (area) => dispatch(actions.setTotalForestArea(area)),
        onSetMapDataUpdated: (state) => dispatch(actions.setMapDataUpdated(state)),
        onUploadDocument: (docs, bucket, forestId, folderName) => dispatch(actions.uploadDocument(docs, bucket, forestId, folderName)),
        onSetDocumentReadState: (forestId, docId) => dispatch(actions.setDocumentReadState(forestId, docId)),
        onDeleteDocument: (forestId, docId) => dispatch(actions.deleteDocument(forestId, docId)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EconomyWidget)));