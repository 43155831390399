import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Graphics
import logo from './Microsoft_logo.svg';

// Fonts
import './MSstyle.css';

const useStyles = makeStyles((theme) => ({
    mainButton: {
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#FFFFFF',
        borderColor: '#8C8C8C',
        color: '#5E5E5E',
        padding: 12,
        '&:hover': {
            backgroundColor: '#f4f5f6',
            borderColor: '#AFAFAF',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#f4f5f6',
            borderColor: '#AFAFAF',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
        fontFamily: 'Segoe',
        fontWeight: 600,
        textTransform: 'none',
        height: 40,
        borderRadius: 0,
    },
    buttonProgress: {
        color: '#5E5E5E',
        position: 'absolute',
    },
}));

const MicrosoftButton = (props) => {
    const classes = useStyles();

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
                onClick={props.onClick}
                variant={props.variant}
                color={props.color}
                className={classes.mainButton}
                disabled={props.disabled}
            >
                <img src={logo} style={{ width: 20, height: 20, marginRight: 12 }} alt="MS Logo" />

                {props.text}
            </Button>

            {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
}
export default MicrosoftButton;