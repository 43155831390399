import React, { PureComponent } from "react";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Other Components
import Task from './Task';

const styles = () => ({

});

class InnerList extends PureComponent {
    render() {
        return (
            this.props.tasks.map((task, index) => {
                if (task && task.display) {
                    return <Task
                        key={task.id}
                        task={task}
                        index={index}
                        dragDisabled={this.props.dragDisabled}
                        navigateToTaskOnClickHandler={this.props.navigateToTaskOnClickHandler}
                        deleteTaskOnClickHandler={this.props.deleteTaskOnClickHandler}
                    />
                } else {
                    return null;
                }
            })
        )
    }
}

InnerList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InnerList);