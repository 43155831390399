import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loading: false,
    loadingList: false,
    error: null,
    fileList: null,
};

// Fetch documents
const fetchDocumentListStart = (state, action) => {
    return updateObject(state, { loadingList: true });
}

const fetchDocumentListSuccess = (state, action) => {
    return updateObject(state, {
        loadingList: false,
        fileList: { ...state.fileList, [action.id]: action.fileList }
    });
}

const fetchDocumentListFail = (state, action) => {
    return updateObject(state, { loadingList: false, error: action.error });
}

// Remove document list from reducer
const removeDocumentList = (state, action) => {
    const updatedList = { ...state.fileList };
    delete updatedList[action.id];
    return updateObject(state, {
        fileList: updatedList,
    });
}

// Upload documents
const uploadDocumentStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const uploadDocumentSuccess = (state, action) => {
    return updateObject(state, { loading: false });
}

const uploadDocumentFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
}

// Delete documents
const deleteDocumentStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const deleteDocumentSuccess = (state, action) => {
    return updateObject(state, { loading: false });
}

const deleteDocumentFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
}

// List documents
const listDocumentsStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const listDocumentsSuccess = (state, action) => {
    return updateObject(state, { loading: false, [action.listName]: action.list });
}

const listDocumentsFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
}

// Set document read state
const setDocumentReadStateStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const setDocumentReadStateSuccess = (state, action) => {
    return updateObject(state, { loading: false });
}

const setDocumentReadStateFail = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
}

// Reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DOCUMENT_LIST_START: return fetchDocumentListStart(state, action);
        case actionTypes.FETCH_DOCUMENT_LIST_SUCCESS: return fetchDocumentListSuccess(state, action);
        case actionTypes.FETCH_DOCUMENT_LIST_FAIL: return fetchDocumentListFail(state, action);

        case actionTypes.REMOVE_DOCUMENT_LIST: return removeDocumentList(state, action);

        case actionTypes.UPLOAD_DOCUMENT_START: return uploadDocumentStart(state, action);
        case actionTypes.UPLOAD_DOCUMENT_SUCCESS: return uploadDocumentSuccess(state, action);
        case actionTypes.UPLOAD_DOCUMENT_FAIL: return uploadDocumentFail(state, action);

        case actionTypes.DELETE_DOCUMENT_START: return deleteDocumentStart(state, action);
        case actionTypes.DELETE_DOCUMENT_SUCCESS: return deleteDocumentSuccess(state, action);
        case actionTypes.DELETE_DOCUMENT_FAIL: return deleteDocumentFail(state, action);

        case actionTypes.LIST_DOCUMENTS_START: return listDocumentsStart(state, action);
        case actionTypes.LIST_DOCUMENTS_SUCCESS: return listDocumentsSuccess(state, action);
        case actionTypes.LIST_DOCUMENTS_FAIL: return listDocumentsFail(state, action);

        case actionTypes.SET_DOCUMENT_READ_STATE_START: return setDocumentReadStateStart(state, action);
        case actionTypes.SET_DOCUMENT_READ_STATE_SUCCESS: return setDocumentReadStateSuccess(state, action);
        case actionTypes.SET_DOCUMENT_READ_STATE_FAIL: return setDocumentReadStateFail(state, action);
        

        default: return state;
    }
};

export default reducer;