import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

// Components
import SignUpStepWindowContent from './SignUpStepWindowContent';
import ButtonWithSpinner from '../../components/UI/Buttons/ButtonWithSpinner';

const useStyles = makeStyles((theme) => ({
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    stepWindowContainer: {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 21,
        [theme.breakpoints.down('sm')]: {
            padding: 8,
        },
        [theme.breakpoints.up('sm')]: {
            padding: 28,
        },
    }
}));

export default function SignUpStepWindow(props) {
    const classes = useStyles();
    return (
        <div className={classes.stepWindowContainer} style={{}}>
            <Stepper activeStep={props.activeStep} style={{ padding: 0 }}>
                {props.steps.map(label => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <div>
                <div>
                    <SignUpStepWindowContent
                        step={props.step}
                        loading={props.loading}
                        openTerms={props.openTerms}
                        terms={props.terms}
                        termsText={props.termsText}
                        solution={props.solution}
                        handleSolution={props.handleSolution}
                        handleTermsView={props.handleTermsView}
                        handleTerms={props.handleTerms}
                        handleTextInput={props.handleTextInput}
                        signupEmail={props.signupEmail}
                        signupPassword={props.signupPassword}
                        signupPasswordRepeat={props.signupPasswordRepeat}
                        passwordError={props.passwordError}
                        passwordErrorString={props.passwordErrorString}
                        handleThirdPartyLogin={props.handleThirdPartyLogin}
                        MSButtonShown={props.MSButtonShown}
                        MSButtonDisabled={props.MSButtonDisabled}
                    />

                    <div className={classes.actionContainer}>
                        <Button disabled={props.activeStep === 0} onClick={props.handleBack}>
                            Tilbage
                        </Button>

                        <ButtonWithSpinner
                            onClick={props.handleNext}
                            variant="contained"
                            color="primary"
                            text={props.activeStep === props.steps.length - 1 ? 'Opret konto' : 'Næste'}
                            disabled={props.nextButtonDisabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}