import React from 'react';
import moment from 'moment';
import 'moment/locale/da';

// Material UI
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// Material UI Icons
import PlayIcon from '@material-ui/icons/PlayArrowRounded';
import TvIcon from '@material-ui/icons/LiveTvRounded';

const VideoTile = props => {
    const { i, item, length } = props;

    if (i + 1 === length) {
        return (
            <div style={{ width: 200, paddingRight: 10 }}>
                <Button
                    variant="outlined"
                    color="primary"
                    style={{ height: 40, width: 130, color: '#41842B' }}
                    onClick={() => {
                        window.open(`https://www.youtube.com/channel/UCpoWr-fXWLqu5FwwWY1xJNw`)
                    }}
                    endIcon={<TvIcon />}
                >
                    Se mere
                </Button>
            </div>
        )
    } else {
        return (
            // <div key={i} style={{ width: 322, border: '1px solid #e4e4e4', borderRadius: 5, marginRight: 10 }}>
            <div key={i} style={{ border: '1px solid #e4e4e4', borderRadius: 5, marginBottom: 16 }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <img
                            style={{ borderRadius: '4px 4px 0px 0px', width: '100%', cursor: 'pointer' }}
                            src={item.snippet.thumbnails.medium.url}
                            alt="YouTube video fra Skovkortet"
                            onClick={() => {
                                window.open(`https://youtu.be/${item.id.videoId}`)
                            }}
                        />

                        <div style={{ position: 'absolute' }}>
                            <IconButton
                                style={{ backgroundColor: 'white' }}
                                onClick={() => {
                                    window.open(`https://youtu.be/${item.id.videoId}`)
                                }}
                            >
                                <PlayIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div style={{ padding: '8px 12px 12px 12px', height: 180, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 500 }}>
                                {item.snippet.title}
                            </Typography>

                            <Typography variant="body1" style={{ fontSize: 14, color: '#6f6f6f' }}>
                                {`${item.snippet.description.substring(0, 120)}...`}
                            </Typography>
                        </div>

                        <div style={{ marginTop: 6 }}>
                            <Typography variant="body1" display="inline" style={{ fontWeight: 500, marginRight: 5 }}>
                                YouTube
                            </Typography>

                            <Typography variant="body1" display="inline" style={{ fontSize: 13, color: '#6f6f6f' }}>
                                - {moment(item.snippet.publishedAt).fromNow()}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VideoTile;