// function buildMap(rows) {
//     return rows.reduce((accum, { dataIndex }) => {
//       accum[dataIndex] = true;
//       return accum;
//     }, {});
//   }

//   function warnDeprecated(warning) {
//     if (process.env.NODE_ENV === 'development') {
//       console.error(`Deprecation Notice:  ${warning}`);
//     }
//   }

//   function getPageValue(count, rowsPerPage, page) {
//     const totalPages = count <= rowsPerPage ? 1 : Math.ceil(count / rowsPerPage);

//     // `page` is 0-indexed
//     return page >= totalPages ? totalPages - 1 : page;
//   }

//   function getCollatorComparator() {
//     if (!!Intl) {
//       const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
//       return collator.compare;
//     }

//     const fallbackComparator = (a, b) => a.localeCompare(b);
//     return fallbackComparator;
//   }

//   function sortCompare(order) {
//     return (a, b) => {
//       var aData = a.data === null || typeof a.data === 'undefined' ? '' : a.data;
//       var bData = b.data === null || typeof b.data === 'undefined' ? '' : b.data;
//       return (
//         (typeof aData.localeCompare === 'function' ? aData.localeCompare(bData) : aData - bData) *
//         (order === 'asc' ? 1 : -1)
//       );
//     };
//   }


// Import
const species = require('./updatedSpeciesList.json');

// format input for estimation
export const formatEstInput = (input) => {
  let formatedInput = input;
  switch(typeof input) {
    case 'string': formatedInput = isNaN(parseFloat(input.replace(',','.'))) ? 0 : parseFloat(input.replace(',','.')); break;
    default: formatedInput = input
  }
  return formatedInput;
}

// Sorting
export const sortNumAlpha = (a, b) => {
  const reA = /[^a-zA-Z]/g;
  //const reN = /[^0-9]/g;

  const aN = a.section;
  const bN = b.section;
  // const aN = parseInt(a.section.replace(reN, ""), 10);
  // const bN = parseInt(b.section.replace(reN, ""), 10);
  if (aN === bN) {
    const aA = a.litra.replace(reA, "");
    const bA = b.litra.replace(reA, "");
    return aA === bA ? 0 : aA > bA ? 1 : -1;
  } else {
    return aN > bN ? 1 : -1;
  }
}

// Sorting 
const sortNumAlphaGen = (a,b) => {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;

  const aN = a.replace(reN, "");
  const bN = b.replace(reN, "");

  if (aN !== '' && bN !== '') {
    const aa = parseInt(aN);
    const bb = parseInt(bN);
    return aa > bb ? 1 : -1;
  } else {
    const aA = a.replace(reA, "");
    const bA = b.replace(reA, "");
    return aA === bA ? 0 : aA > bA ? 1 : -1;
  }
}

// Sort columns
function desc(a, b, orderBy, numeric) {
  let aa = a[orderBy];
  let bb = b[orderBy];
  if (orderBy === 'year') {
    aa = a[orderBy].slice(a[orderBy].indexOf('(') +1,a[orderBy].indexOf(')'));
    bb = b[orderBy].slice(b[orderBy].indexOf('(') +1,b[orderBy].indexOf(')'));
  }

  if (orderBy === 'title') {
    return sortNumAlphaGen(a[orderBy],b[orderBy]);
  }
  
  if (numeric) {
    aa = aa === '-' ? 0 : parseFloat(aa.split(',').join('.'));
    bb = bb === '-' ? 0 : parseFloat(bb.split(',').join('.'));
  }
  if (bb < aa) {
    return -1;
  }
  if (bb > aa) {
    return 1;
  }
  return 0;
}

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const getSorting = (order, orderBy, numeric) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy, numeric) : (a, b) => -desc(a, b, orderBy, numeric);
}

// Sort species
export const sortSpecies = (data) => {
  let foliage = [];
  let needle = [];
  let line = [];
  let other = [];
  // Catagorize species
  const N = data.length;
  for (let i = 0; i < N; i++) {
    if (species[data[i].label] === undefined) {
      other.push(data[i])
    } else {
      switch (species[data[i].label].type) {
        case 'foliage': foliage.push(data[i]); break;
        case 'needle': needle.push(data[i]); break;
        case 'other': other.push(data[i]); break;
        case 'line': line.push(data[i]); break;
        default: other.push(data[i]); break;
      }
    }
  }
  foliage.sort((a, b) => a.label.localeCompare(b.label, 'da-DK'));
  needle.sort((a, b) => a.label.localeCompare(b.label, 'da-DK'));
  other.sort((a, b) => a.label.localeCompare(b.label, 'da-DK'));
  line.sort((a, b) => a.label.localeCompare(b.label, 'da-DK'));
  return [...foliage, ...needle, ...other, ...line];
}

// Create csv file
function escapeDangerousCSVCharacters(data) {
  if (typeof data === 'string') {
    // Places single quote before the appearance of dangerous characters if they
    // are the first in the data string. 
    return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
  }

  return data;
}

function buildCSV(columns, data, options) {
  const replaceDoubleQuoteInString = columnData =>
    typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

  const buildHead = columns => {
    return (
      columns
        .reduce(
          (soFar, column) =>
            column.download
              ? soFar +
              '"' +
              escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
              '"' +
              options.downloadOptions.separator
              : soFar,
          '',
        )
        .slice(0, -1) + '\r\n'
    );
  };
  const CSVHead = buildHead(columns);

  const buildBody = data => {
    if (!data.length) return '';
    return data
      .reduce(
        (soFar, row) =>
          soFar +
          '"' +
          row.data
            .filter((_, index) => columns[index].download)
            .map(columnData => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
            .join('"' + options.downloadOptions.separator + '"') +
          '"\r\n',
        '',
      )
      .trim();
  };
  const CSVBody = buildBody(data);

  const csv = options.onDownload
    ? options.onDownload(buildHead, buildBody, columns, data)
    : `${CSVHead}${CSVBody}`.trim();

  return csv;
}

function downloadCSV(csv, filename) {
  const blob = new Blob([csv], { type: 'text/csv' });

  // taken from react-csv
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function createCSVDownload(columns, data, options, downloadCSV) {
  const csv = buildCSV(columns, data, options);

  if (options.onDownload && csv === false) {
    return;
  }

  downloadCSV(csv, options.downloadOptions.filename);
}

export {
  //buildMap,
  //getPageValue,
  //getCollatorComparator,
  //sortCompare,
  createCSVDownload,
  buildCSV,
  downloadCSV,
  //warnDeprecated,
  escapeDangerousCSVCharacters,
};