import * as actionTypes from './actionTypes';
import firebase from 'firebase';
import moment from 'moment';

export const setCurrentlySelectedBiotopePlanId = (id) => {
    return {
        type: actionTypes.SET_CURRENTLY_SELECTED_BIOTOPE_PLAN_ID,
        currentlySelectedBiotopePlanId: id
    };
}

export const setBiotopePlanListActive = (state) => {
    return {
        type: actionTypes.SET_BIOTOPE_PLAN_LIST_ACTIVE,
        state: state
    };
};

export const setUpdateBiotopePlanFlag = (state) => {
    return {
        type: actionTypes.SET_UPDATE_BIOTOPE_PLAN_FLAG,
        updateBiotopePlanFlag: state
    };
};

export const unmountBiotopePlan = () => {
    return dispatch => {
        dispatch(unmountBiotopePlanAction());
    }
}

const unmountBiotopePlanAction = () => {
    return {
        type: actionTypes.UNMOUNT_BIOTOPE_PLAN,
    };
}

export const setRedrawBiotopePlanFeatures = (state) => {
    return {
        type: actionTypes.SET_REDRAW_BIOTOPE_PLANS_FEATURES,
        state: state,
    }
}

export const setRedrawBiotopePlanPoints = (state) => {
    return {
        type: actionTypes.SET_REDRAW_BIOTOPE_PLAN_POINTS,
        redrawPointsState: state
    };
};

// Fetch biotope plan features
const fetchBiotopePlanFeaturesStart = () => {
    return {
        type: actionTypes.FETCH_BIOTOPE_FEATURES_START
    };
}

const fetchBiotopePlanFeaturesFail = (error) => {
    return {
        type: actionTypes.FETCH_BIOTOPE_FEATURES_FAIL,
        error: error
    };
}

const fetchBiotopePlanFeaturesSuccess = (features, id) => {
    return {
        type: actionTypes.FETCH_BIOTOPE_FEATURES_SUCCESS,
        features: features,
        id: id
    };
}

export const fetchBiotopePlanFeatures = (id, revId) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    dispatch(fetchBiotopePlanFeaturesStart());
    // check for auth user
    if (user != null) {
        try {
            db.collection("biotopePlans").doc(id).collection("content").doc("rev: " + revId).collection("cells")
                .onSnapshot(function (querySnapshot) {
                    var fetchedFeatures = [];

                    querySnapshot.forEach(function (doc) {
                        fetchedFeatures.push(doc.data());
                    });

                    // Dispatch featurs to reducer
                    dispatch(fetchBiotopePlanFeaturesSuccess(fetchedFeatures, id));
                });
        } catch (error) {
            dispatch(fetchBiotopePlanFeaturesFail(error));
        }
    } else {
        dispatch(fetchBiotopePlanFeaturesFail("Error! User not authenticated"));
    }
}

// Fetch points
const fetchBiotopePlanPointsStart = () => {
    return {
        type: actionTypes.FETCH_BIOTOPE_POINTS_START
    };
};

const fetchBiotopePlanPointsFail = (error) => {
    return {
        type: actionTypes.FETCH_BIOTOPE_POINTS_FAIL,
        error: error
    };
};

const fetchBiotopePlanPointsSuccess = (points, planId) => {
    return {
        type: actionTypes.FETCH_BIOTOPE_POINTS_SUCCESS,
        points: points,
        id: planId
    };
};

export const fetchBiotopePlanPoints = (planId, revId) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    dispatch(fetchBiotopePlanPointsStart());
    // check for auth user
    if (user != null) {
        try {
            db.collection("biotopePlans").doc(planId).collection("content").doc("rev: " + revId).collection("points")
                .onSnapshot(function (querySnapshot) {
                    var fetchedPoints = {};
                    querySnapshot.forEach(function (doc) {
                        fetchedPoints = { ...fetchedPoints, [doc.data().id]: doc.data() };
                    });
                    // Dispatch points to reducer
                    dispatch(fetchBiotopePlanPointsSuccess(fetchedPoints, planId));
                });
        } catch (error) {
            dispatch(fetchBiotopePlanPointsFail(error));
        }
    } else {
        dispatch(fetchBiotopePlanPointsFail("Error! User not authenticated"));
    }
};

// Fetch plans
const fetchBiotopePlanStart = () => {
    return {
        type: actionTypes.FETCH_BIOTOPE_PLAN_START
    };
}

const fetchBiotopePlanUserSuccess = (plan, id) => {
    return {
        type: actionTypes.FETCH_BIOTOPE_PLAN_USER_SUCCESS,
        biotopePlan: plan,
        id: id,
    };
}

const fetchBiotopePlanOrgSuccess = (plan, id) => {
    return {
        type: actionTypes.FETCH_BIOTOPE_PLAN_ORG_SUCCESS,
        biotopePlan: plan,
        id: id,
    };
}

const fetchBiotopePlanFail = (error) => {
    return {
        type: actionTypes.FETCH_BIOTOPE_PLAN_FAIL,
        error: error
    };
}

export const fetchBiotopePlan = (id, org) => async (dispatch) => {
    var user = firebase.auth().currentUser;
    var db = firebase.firestore();
    dispatch(fetchBiotopePlanStart());
    // check for auth user
    if (user !== null) {
        let fetchedPlan = null;
        try {
            db.collection("biotopePlans").doc(id).onSnapshot(function (doc) {
                fetchedPlan = doc.data();
                if (fetchedPlan) {
                    if (org) {
                        dispatch(fetchBiotopePlanOrgSuccess(fetchedPlan, id));
                    } else {
                        dispatch(fetchBiotopePlanUserSuccess(fetchedPlan, id));
                    }
                }
            });
        } catch (err) {
            console.error("Fetch Biotope plan Error", err)
            dispatch(fetchBiotopePlanFail(err))
        }
    }
}

// Save actions
const saveBiotopePlanStart = () => {
    return {
        type: actionTypes.SAVE_BIOTOPE_PLAN_START
    };
}

const saveBiotopePlanSuccess = () => {
    return {
        type: actionTypes.SAVE_BIOTOPE_PLAN_SUCCESS
    };
}

const saveBiotopePlanFail = (error) => {
    return {
        type: actionTypes.SAVE_BIOTOPE_PLAN_FAIL,
        error: error
    };
}

export const saveBiotopePlan = (plan, orgId, originId, filter) => async (dispatch) => {
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    dispatch(saveBiotopePlanStart());
    try {
        // Check if it is a new biotope plan
        if (typeof plan.id === 'undefined') {
            const planId = db.collection("biotopePlans").doc();
            await planId.set({
                ...plan,
                id: planId.id,
                creationDate: moment().format(),
            });
            // Append plan id to current user's list of biotope plans
            const userId = db.collection("users").doc(user.uid);
            await userId.update({
                biotopePlans: firebase.firestore.FieldValue.arrayUnion(planId.id)
            });
            // Append plan id to users affiliated org's list of biotope plans (if ordId is given)
            if (orgId) {
                const refId = db.collection("org").doc(orgId);
                await refId.update({
                    biotopePlans: firebase.firestore.FieldValue.arrayUnion(planId.id)
                });
            }
            // Check if the plan is a duplicate
            if (originId) {
                await duplicatePlan(planId.id, originId, filter, 0);
            }
        } else {
            // Existing biotope plan
            const planId = db.collection("biotopePlans").doc(plan.id);
            await planId.update({
                ...plan,
            })
        }
        dispatch(saveBiotopePlanSuccess());

    } catch (error) {
        dispatch(saveBiotopePlanFail(error));
    }
}

const duplicatePlan = async (newId, originId, filter, revId) => {
    const db = firebase.firestore();
    try {
        // Check for filter on duplication
        if (filter === 'Afgrænsning') {
            // Get only filter specific features and dublicate
            const fDocs = await db.collection("biotopePlans").doc(originId).collection("content").doc("rev: " + revId).collection("cells").where("cellData.species", "==", "Afgrænsning").get();
            fDocs.forEach(async doc => {
                const newRef = db.collection("biotopePlans").doc(newId).collection("content").doc("rev: " + revId).collection("cells").doc();
                let newData = doc.data();
                newData.cellData.id = newRef.id;
                newData.cellData.biotopePlanId = newId;
                await newRef.set({ ...newData })
            })
        } else {
            // Duplicate points
            const pDocs = await db.collection("biotopePlans").doc(originId).collection("content").doc("rev: " + revId).collection("points").get();
            pDocs.forEach(async doc => {
                const newRef = db.collection("biotopePlans").doc(newId).collection("content").doc("rev: " + revId).collection("points").doc();
                let newData = doc.data();
                newData.id = newRef.id;
                newData.planId = newId;
                await newRef.set({ ...newData })
            })
            // Duplicate features
            const fDocs = await db.collection("biotopePlans").doc(originId).collection("content").doc("rev: " + revId).collection("cells").get();
            fDocs.forEach(async doc => {
                const newRef = db.collection("biotopePlans").doc(newId).collection("content").doc("rev: " + revId).collection("cells").doc();
                let newData = doc.data();
                newData.cellData.id = newRef.id;
                newData.cellData.biotopePlanId = newId;
                await newRef.set({ ...newData })
            })
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// Delete actions
const deleteBiotopePlanStart = () => {
    return {
        type: actionTypes.DELETE_BIOTOPE_PLAN_START
    };
}

const deleteBiotopePlanSuccess = (id) => {
    return {
        type: actionTypes.DELETE_BIOTOPE_PLAN_SUCCESS,
        planId: id,
    }
}

const deleteBiotopePlanFail = (error) => {
    return {
        type: actionTypes.DELETE_BIOTOPE_PLAN_FAIL,
        error: error
    };
};

export const deleteBiotopePlan = (planId, orgId) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();

    dispatch(deleteBiotopePlanStart());
    try {
        // Remove plan id from user's list
        const userId = db.collection("users").doc(user.uid);
        await userId.update({
            biotopePlans: firebase.firestore.FieldValue.arrayRemove(planId)
        });
        // Remove plan id from org's list
        if (orgId) {
            const org = db.collection("org").doc(orgId);
            await org.update({
                biotopePlans: firebase.firestore.FieldValue.arrayRemove(planId)
            });
        }
        dispatch(deleteBiotopePlanSuccess(planId))
    } catch (error) {
        dispatch(deleteBiotopePlanFail(error));
    }
}

// Attach/Remove plan from user's list
const attachBiotopePlanToUserSuccess = () => {
    return {
        type: actionTypes.ATTACH_BIOTOPE_PLAN_TO_USER
    };
}

export const attachBiotopePlanToUser = (planId) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();

    try {
        // Append plan id to current user's list
        const userId = db.collection("users").doc(user.uid);
        await userId.update({
            biotopePlans: firebase.firestore.FieldValue.arrayUnion(planId)
        });
        dispatch(attachBiotopePlanToUserSuccess());
    } catch (error) {
        console.error("Attach biotope plan to user error: ", error);
    }
}

const removeBiotopePlanFromUserSuccess = (id) => {
    return {
        type: actionTypes.REMOVE_BIOTOPE_PLAN_FROM_USER,
        planId: id,
    };
}

export const removeBiotopePlanFromUser = (planId) => async (dispatch) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();

    try {
        // Remove plan id to current user's list
        const userId = db.collection("users").doc(user.uid);
        await userId.update({
            biotopePlans: firebase.firestore.FieldValue.arrayRemove(planId)
        });
        dispatch(removeBiotopePlanFromUserSuccess(planId));

    } catch (error) {
        console.error("Remove biotope plan from user error: ", error)
    }
}

export const deleteBiotopePlanFeatures = (planId) => {
    return {
        type: actionTypes.DELETE_BIOTOPE_PLAN_FEATURES,
        planId: planId
    };
};


