import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';

const CautionMenu = (props) => {
    return (
        <div style={{ backgroundColor: '#9A9A9A', padding: '4px 8px', borderRadius: 5 }}>
            <Typography
                style={{ fontSize: 13, fontWeight: 500, color: 'white' }}
            >
                {props.title}
            </Typography>

            <Typography
                variant="caption"
                style={{ color: 'white' }}
            >
                {props.content}
            </Typography>
        </div>
    );
}

export default CautionMenu;