import React from 'react';
import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Customer from '../components/Customer/Customer';

const overviewMapNewNote = (props) => {
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <Customer
                        />
                    </div>
                } />
            </Aux>
        );

}

export default overviewMapNewNote;