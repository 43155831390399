import * as actionTypes from './actionTypes';
import firebase from 'firebase';
import moment from 'moment';


// Fetch hunting features
export const fetchHuntingFeaturesStart = () => {
    return {
        type: actionTypes.FETCH_HUNTING_FEATURES_START
    };
};

export const fetchHuntingFeaturesFail = (error) => {
    return {
        type: actionTypes.FETCH_HUNTING_FEATURES_FAIL,
        error: error
    };
};

export const fetchHuntingFeaturesSuccess = (features, forestId) => {
    return {
        type: actionTypes.FETCH_HUNTING_FEATURES_SUCCESS,
        features: features,
        id: forestId
    };
};

export const fetchHuntingFeatures = (forestId, revId) => async (dispatch) => {
    var user = firebase.auth().currentUser; 
    var db = firebase.firestore();

    dispatch(fetchHuntingFeaturesStart());

    // check for auth user
    if (user != null) {
        try {
            db.collection("forests").doc(forestId).collection("hunting").doc("rev: " + revId).collection("cells")
            .onSnapshot(function (querySnapshot) {
                var fetchedFeatures = [];

                querySnapshot.forEach(function (doc) {
                    fetchedFeatures.push(doc.data());
                });

                // Dispatch featurs to reducer
                dispatch(fetchHuntingFeaturesSuccess(fetchedFeatures, forestId));
            });
        } catch (error) {
            dispatch(fetchHuntingFeaturesFail(error));
        }
    } else {
        dispatch(fetchHuntingFeaturesFail("Error! User not authenticated"));
    }
};

// Fetch hunting points
export const fetchHuntingPointsStart = () => {
    return {
        type: actionTypes.FETCH_HUNTING_POINTS_START
    };
};

export const fetchHuntingPointsFail = (error) => {
    return {
        type: actionTypes.FETCH_HUNTING_POINTS_FAIL,
        error: error
    };
};

export const fetchHuntingPointsSuccess = (points, forestId) => {
    return {
        type: actionTypes.FETCH_HUNTING_POINTS_SUCCESS,
        points: points,
        id: forestId
    };
};

export const fetchHuntingPoints = (forestId, revId) => async (dispatch) => {
    var user = firebase.auth().currentUser; 
    var db = firebase.firestore();

    dispatch(fetchHuntingPointsStart());

    // check for auth user
    if (user != null) {
        try {
            db.collection("forests").doc(forestId).collection("hunting").doc("rev: " + revId).collection("points")
            .onSnapshot(function (querySnapshot) {
                var fetchedPoints = {};
                querySnapshot.forEach(function (doc) {
                    // fetchedPoints.splice(doc.data().id, 0, doc.data());
                    fetchedPoints = {...fetchedPoints, [doc.data().id]: doc.data()};
                });
                // Dispatch featurs to reducer
                dispatch(fetchHuntingPointsSuccess(fetchedPoints, forestId));
            });
        } catch (error) {
            dispatch(fetchHuntingPointsFail(error));
        }
    } else {
        dispatch(fetchHuntingPointsFail("Error! User not authenticated"));
    }
};

// Save hunting feature
export const saveHuntingFeatureStart = () => {
    return {
        type: actionTypes.SAVE_HUNTING_FEATURE_START,
    };
};

export const saveHuntingFeatureFail = (error) => {
    return {
        type: actionTypes.SAVE_HUNTING_FEATURE_FAIL,
        error: error
    };
};

export const saveHuntingFeatureSuccess = () => {
    return {
        type: actionTypes.SAVE_HUNTING_FEATURE_SUCCESS,
    };
};

export const saveHuntingFeature = (forestId, revId, featureData) => async (dispatch) => {
    var db = firebase.firestore();
    // Save new change to current version of forest
    try {
        if (featureData.pointType) {
            if (typeof featureData.id === 'undefined' || featureData.id === null) {
                // Save new feature
                featureData.creationDate = moment().format();
                const refId = db.collection("forests").doc(forestId).collection("hunting").doc("rev: " + revId).collection("points").doc();
                featureData.id = refId.id;
                refId.set({
                    featureData,
                })
            } else {
                const refId = db.collection("forests").doc(forestId).collection("hunting").doc("rev: " + revId).collection("points").doc(featureData.id);
                refId.update({
                    featureData,
                })
            }
        } else {
            if (typeof featureData.id === 'undefined' || featureData.id === null) {
                // Save new feature
                featureData.creationDate = moment().format();
                const refId = db.collection("forests").doc(forestId).collection("hunting").doc("rev: " + revId).collection("features").doc();
                featureData.id = refId.id;
                refId.set({
                    featureData,
                })
            } else {
                const refId = db.collection("forests").doc(forestId).collection("hunting").doc("rev: " + revId).collection("features").doc(featureData.id);
                refId.update({
                    featureData,
                })
            }
        }
        dispatch(saveHuntingFeatureSuccess());
    } catch (error) {
        console.error(error);
        dispatch(saveHuntingFeatureFail(error));
    }
}

export const setRedrawHuntingPoints = (state) => {
    return {
        type: actionTypes.REDRAW_HUNTING_POINTS,
        redrawPointsState: state
    };
};

export const setRedrawHuntingFeatures = (state) => {
    return {
        type: actionTypes.SET_REDRAW_HUNTING_FEATURES,
        redrawFeaturesState: state
    };
};

export const setForestOwnerChangeAllowedHunting = (changeAllowedState) => {
    return {
        type: actionTypes.SET_HUNTING_ALLOW_CHANGE,
        forestOwnerAllowChange: changeAllowedState
    };
};

export const deleteAllHuntingFeatures = (forestId) => {
    return {
        type: actionTypes.DELETE_ALL_HUNTING_FEATURES,
        customerId: forestId
    };
};

export const deleteAllHuntingPoints = (forestId) => {
    return {
        type: actionTypes.DELETE_ALL_HUNTING_POINTS,
        customerId: forestId
    };
};

