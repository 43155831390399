import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';

// RSS Parser
import Parser from 'rss-parser';

// Moment
import moment from 'moment';

// Components
import CircularSpinner from '../../CircularSpinner/CircularSpinner';

// Hjælp til tjek
// https://www.coderedcorp.com/tools/rss-preview/

const cookies = new Cookies();

const styles = () => ({
    cardContent: {
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        marginBottom: 0,
        position: 'relative',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '400px',
        overflow: 'auto',
    },
    divider: {
        marginTop: 0,
        marginBottom: 0,
    },
    button: {
        margin: '8px',
    },
});

class NewsWidget extends React.Component {
    constructor() {
        super();
        const newsWidgetState = cookies.get('newsWidgetState');
        this.state = {
            news: [],
            timeouted: false,
            open: newsWidgetState === 'close' ? false : true,
            internalEnvState: cookies.get('mapSelector'),
        }
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        // Load initial news
        this.mounted && this.loadNews(this.state.internalEnvState);
    }

    componentWillUnmount() {
        this.mounted = false;
        clearTimeout(this.timer);
    }

    componentDidUpdate() {
        // Load news if environment changes
        if (this.state.internalEnvState !== this.props.chosenWorkEnvironment) {
            this.loadNews(this.props.chosenWorkEnvironment);
            this.setState({ internalEnvState: this.props.chosenWorkEnvironment });
        }
    }

    loadNews = (environment) => {
        let parser = new Parser();
        const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";
        var newsArr = [];
        let newsUrl = '';

        switch (environment) {
            case 'forest': newsUrl = 'https://www.skovforeningen.dk/feed/'; break;
            case 'hunting': newsUrl = 'https://netnatur.dk/feed/'; break;
            default:
        }

        (async () => {
            try {
                let feed = await parser.parseURL(CORS_PROXY + newsUrl);
                feed.items.forEach(item => {
                    newsArr.push(item);
                    newsArr.splice(10);
                    this.mounted && this.setState({
                        news: newsArr
                    });
                });
            } catch (error) {
            }
        })();
    }

    handleTimeout = () => {
        //this.loadNews();
        clearTimeout(this.timer);
        this.mounted && this.setState({ timeouted: true });
    }

    removeText(text) {
        var newText = text.replace('LÆS MERE - Klik på overskriften ...', '');
        var index = newText.search('The post');
        var string = newText.slice(0, index);
        return (
            <Typography
                variant="body1"
                gutterBottom
                style={{ whiteSpace: 'pre-line' }}
            >
                {string}
            </Typography>
        );
    }

    linkToPage(item) {
        window.open(item.link);

        // Send event to Analytics
        ReactGA.event({
            category: 'NewsWidget',
            action: 'Navigated to site',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    renderNewsContent() {
        const { classes } = this.props;

        if (this.state.news.length > 0) {
            clearTimeout(this.timer);
            if (this.state.timeouted) {
                this.setState({ timeouted: false })
            }

            return (
                this.state.news.map((item, index) => (
                    <div key={index} style={{ paddingTop: 10 }}>
                        <Typography
                            variant="caption"
                            gutterBottom
                            style={{ color: '#747474' }}
                        >
                            {moment(item.pubDate).format('DD[-]MM[-]YYYY HH:mm')}
                        </Typography>

                        <Typography variant="h6">
                            {item.title}
                        </Typography>

                        {this.removeText(item.contentSnippet)}

                        <Typography
                            variant="subtitle2"
                            gutterBottom
                        >
                            {item.creator}
                        </Typography>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => this.linkToPage(item)}
                            >
                                Se mere
                            </Button>
                        </div>
                        {(index < this.state.news.length - 1) && <Divider className={classes.divider} />}
                    </div>
                )))
        } else if (this.state.timeouted) {
            return (
                <div style={{ height: 60 }}>
                    <Typography variant="body2" style={{ color: '#717171' }}>
                        Nyheder kunne ikke hentes
                    </Typography>
                </div>
            )
        } else {
            this.timer = setTimeout(() => this.handleTimeout(), 5000)
            return (
                <div style={{ height: 60, display: 'flex', justifyContent: 'center', }}>
                    <CircularSpinner />
                </div>
            )
        }
    }

    expandAction() {
        this.setState({ open: this.state.open ? false : true });

        cookies.set('newsWidgetState', this.state.open ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'NewsWidget',
            action: `NewsWidget: ${this.state.open ? 'close' : 'open'}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <ExpansionPanel
                    expanded={this.state.open}
                    onChange={() => this.expandAction()}
                >
                    <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">
                            Nyheder
                        </Typography>
                    </ExpansionPanelSummary>

                    {(this.state.news.length > 1) && <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />}

                    <ExpansionPanelDetails className={classes.cardContent}>
                        <div>
                            {this.renderNewsContent()}
                        </div>
                    </ExpansionPanelDetails>

                    {(this.state.news.length > 1) && <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />}

                    {(this.state.news.length > 1) &&
                        <Typography
                            variant="body2"
                            align="center"
                            style={{ color: '#6A6A6A', marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 16 }}
                        >
                            Listen er scrollbar <InfoIcon style={{ marginBottom: -8, color: '#9A9A9A' }} />
                        </Typography>}
                </ExpansionPanel>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewsWidget));