import React from 'react';
import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import TextLine from '../components/TextLine/TextLine';

const overviewTextLine = (props) => {
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <TextLine/>
                    </div>
                } />
            </Aux>
        );

}

export default overviewTextLine;