import React from 'react';

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Inventory from '../components/Inventory/Inventory';

const overviewMapInventory = (props) => {
    return (
        <Aux>
            <SideBar cardContent={
                <div>
                    <Inventory 
                        area = {props.location.state.area}
                        lineLength = {props.location.state.lineLength}
                        latlngs = {props.location.state.latlngs}
                        GeoJsonFeature = {props.location.state.geo}
                        />
                </div>
            } />
        </Aux>
    );
}

export default overviewMapInventory;