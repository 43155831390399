var L = window.L;
L.BoxPattern = L.Pattern.extend({

	options: {
		innerWeight: 4,
		outerWeight: 4,
		innerColor: '#000000',
		outerColor: '#ffffff',
        innerFillColor: '#000000',
        outerFillColor: '#ffffff',
		inneropacity: 1,
		outerOpacity: 1,
        innerFillOpacity: 1,
        outerFillOpacity: 1,
	},

	_addShapes: function () {
		this._outer = new L.PatternPath({
			// stroke: true,
			weight: this.options.outerWeight,
			color: this.options.outerColor,
            fillColor: this.options.outerFillColor, 
			opacity: this.options.outerOpacity,
            fillOpacity: this.options.outerFillOpacity,
            fill: true,
		});

		this._inner = new L.PatternPath({
			// stroke: true,
			weight: this.options.innerWeight,
			color: this.options.innerColor,
            fillColor: this.options.innerFillColor, 
			opacity: this.options.innerOpacity,
            fillOpacity: this.options.innerFillOpacity,
            fill: true,
		});

		this.addShape(this._outer);
		this.addShape(this._inner);

		this._update();
	},

	_update: function () {
		this._inner.options.d = 'M17.4783268,7.69782732 L17.4783268,16.2681287 C17.3096069,16.4820894 17.1264879,16.6884012 16.9289703,16.8857589 C16.7834076,17.0312037 16.6330216,17.1688947 16.4783268,17.2988309 L16.4783268,6.65795417 C16.6057327,6.76752803 16.730114,6.88248319 16.8511735,7.00282052 L16.9162728,7.06753141 C16.9218383,7.07306374 16.9273944,7.07860552 16.9329411,7.08415672 C17.1289451,7.28031955 17.3107401,7.48530269 17.4783268,7.69782733 Z M18.4783268,9.43714795 C19.1218301,11.0694342 19.1217775,12.8966076 18.4783268,14.5287891 L18.4783268,9.43714795 Z M6.47832678,7.65715467 C6.64231958,7.4479004 6.81996938,7.24582734 7.01128267,7.05213214 L7.01540534,7.04795814 C7.02060202,7.04269676 7.0258071,7.03744369 7.03102056,7.03219894 C7.17546182,6.88689086 7.32473517,6.74930825 7.47832678,6.61945267 L7.47832678,17.2818044 C7.33821089,17.1632211 7.20165313,17.0382406 7.06903745,16.9068619 L7.06483203,16.9026957 C6.85305552,16.6928946 6.65755092,16.4728712 6.47832678,16.2441965 L6.47832678,7.65715467 Z M5.47832678,9.40806564 L5.47832678,14.4930251 C4.84090528,12.8626564 4.8402103,11.0398271 5.47832678,9.40806564 Z M9.47832678,5.43882022 L9.47832678,18.4666483 C9.13555907,18.3350078 8.80105328,18.1753743 8.47832678,17.9877402 L8.47832678,5.91514398 C8.80099501,5.72825535 9.13551607,5.56947721 9.47832678,5.43882022 Z M10.4783268,5.14181362 C10.8083913,5.07027315 11.1426762,5.0229991 11.4783268,5 L11.4783268,18.9125223 C11.1426382,18.8881112 10.8083653,18.8395619 10.4783268,18.7668682 L10.4783268,5.14181362 Z M14.4783268,5.45615935 C14.821304,5.58964856 15.1558328,5.75143086 15.4783268,5.94151697 L15.4783268,17.9994282 C15.1555371,18.1856377 14.8210341,18.3439411 14.4783268,18.4743307 L14.4783268,5.45615935 Z M13.4783268,5.15170135 L13.4783268,18.7712455 C13.1482498,18.8429298 12.8139775,18.8904985 12.4783268,18.9139456 L12.4783268,5.00321677 C12.8140809,5.02839599 13.1483676,5.07788799 13.4783268,5.15170135 Z';

		this._outer.options.d = "M 0 0 H 24 V 24 H -100 Z";
	},

	setStyle: L.Pattern.prototype.setStyle
});

L.boxPattern = function (options) {
	return new L.BoxPattern(options);
};