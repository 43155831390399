import * as actionTypes from './actionTypes';

export const enqueueSnackbar = notification => {
    const key = notification.options && notification.options.key;
    
    return {
        type: actionTypes.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};
