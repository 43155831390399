import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';

// Moment
import moment from 'moment';

// Components
import OutlinedButton from '../../../Buttons/OutlinedButton';
import TipMenu from '../../UIElements/TipMenu';
// import cancelPackageDialog from './cancelPackageDialog';

// Utility
import { checkThirdPartySubscriptionHandling } from './utility';
// Config
import reepayConfig from '../../../../../backend/configReepay';
import configMailChimp from '../../../../../backend/configMailChimp';

// Store
import * as actions from '../../../../../store/actions/index';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    extendedIconAdd: {
        marginRight: theme.spacing.unit,
    },
    formControl: {
        margin: 12,
        width: 500,
    },
    textField: {
        width: '100%',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 18,
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: '#5EBD3E',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

class Subscription2 extends React.Component {
    state = {
        displayPackageType: 'year',
        naturePlanMonth: null,
        naturePlanYear: null,
        operationPlanMonth: null,
        operationPlanYear: null,
        operationPlusPlanMonth: null,
        operationPlusPlanYear: null,
        huntingPlanMonth: null,
        huntingPlanYear: null,
        huntingPlusPlanMonth: null,
        huntingPlusPlanYear: null,
        ownerUpToFiveForestsPlanMonth: null,
        ownerUpToFiveForestsPlanYear: null,
        unlimitedUserAccessPlanMonth: null,
        unlimitedUserAccessPlanYear: null,
        plansLoaded: false,
        natureCheck: false,
        operationCheck: false,
        operationPlusCheck: false,
        huntingCheck: false,
        huntingPlusCheck: false,
        ownerUpToFiveForestsCheck: false,
        unlimitedUserAccessCheck: false,
        discountObj: [],
        giveDiscount: false,
        discountCodeInserted: false,
        discountLabel: '',
        error: '',
        errorFlag: false,
        togglerNatureChecked: true,
        togglerOperationChecked: true,
        togglerOperationPlusChecked: true,
        togglerHuntingChecked: true,
        togglerHuntingPlusChecked: true,
        togglerOwnerUpToFiveForestsChecked: true,
        togglerUnlimitedUserAccess: true,
        tempLoading: false,
        addedPackage: false,
        showCancelSubscriptionBox: false,
        showUncancelSubscriptionBox: false,
        subToBeCancledUncancled: null,
        reasonCancel: '',
        reasonUncancel: '',
        comment: '',
        cancelText: '',
        uncancelText: '',
        tempSetForestHasSubState: false,
        tempNatureActive: this.props.natureActive,
        tempOperationActive: this.props.operationActive,
        tempConfigDiscountsGiven: false,
        buyModalOpen: false,
        totalPrice: 0,
        discountLabel: '',
    };

    componentDidMount() {
        // Set functionality according to config
        if (this.props.config.monthly !== 'enabled') {
            this.setState({ displayPackageType: 'year' })
        }
        this.props.onFetchDiscountCodesList();
        this.props.onFetchFbDiscountCodesList();
        // Set subscription state based on affiliation state
        // Ex.: Wefri have users that are payed through other channels, so they need full functionality, but the user does not have a subscription on Reepay
        if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.wefri && this.props.currentAuthUser.affiliation.wefri.status === 'active') {
            this.props.onSetSubscriptionState('operationPlus', true);
            this.props.onSetSubscriptionState('hunting', true);
            if (this.props.currentAuthUser.userTags && this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skytte"].includes(tag))) {
                this.props.onSetSubscriptionState('huntingPlus', true);
            }
        }
    }

    componentDidUpdate() {
        // Find plans
        if (this.props.planList && !this.state.plansLoaded) {
            // Nature
            const naturePlanMonth = this.props.planList.filter(el => {
                return el.handle === reepayConfig.naturePlanMonth;
            })
            const naturePlanYear = this.props.planList.filter(el => {
                return el.handle === reepayConfig.naturePlanYear;
            })
            // Operation
            const operationPlanMonth = this.props.planList.filter(el => {
                return el.handle === reepayConfig.operationPlanMonth;
            })
            const operationPlanYear = this.props.planList.filter(el => {
                return el.handle === reepayConfig.operationPlanYear;
            })
            // Operation+
            const operationPlusPlanMonth = this.props.planList.filter(el => {
                return el.handle === reepayConfig.operationPlusPlanMonth;
            })
            const operationPlusPlanYear = this.props.planList.filter(el => {
                return el.handle === reepayConfig.operationPlusPlanYear;
            })
            // Hunting
            const huntingPlanMonth = this.props.planList.filter(el => {
                return el.handle === reepayConfig.huntingPlanMonth;
            })
            const huntingPlanYear = this.props.planList.filter(el => {
                return el.handle === reepayConfig.huntingPlanYear;
            })
            // Hunting Plus
            const huntingPlusPlanMonth = this.props.planList.filter(el => {
                return el.handle === reepayConfig.huntingPlusPlanMonth;
            })
            const huntingPlusPlanYear = this.props.planList.filter(el => {
                return el.handle === reepayConfig.huntingPlusPlanYear;
            })
            // Owner extra five forests
            const ownerUpToFiveForestsPlanMonth = this.props.planList.filter(el => {
                return el.handle === reepayConfig.ownerUpToFiveForestsPlanMonth;
            })
            const ownerUpToFiveForestsPlanYear = this.props.planList.filter(el => {
                return el.handle === reepayConfig.ownerUpToFiveForestsPlanYear;
            })
            // Unlimited user access
            const unlimitedUserAccessPlanMonth = this.props.planList.filter(el => {
                return el.handle === reepayConfig.unlimitedUserAccessPlanMonth;
            })
            const unlimitedUserAccessPlanYear = this.props.planList.filter(el => {
                return el.handle === reepayConfig.unlimitedUserAccessPlanYear;
            })
            // Set in state
            this.setState({
                naturePlanMonth: naturePlanMonth[0],
                naturePlanYear: naturePlanYear[0],
                operationPlanMonth: operationPlanMonth[0],
                operationPlanYear: operationPlanYear[0],
                operationPlusPlanMonth: operationPlusPlanMonth[0],
                operationPlusPlanYear: operationPlusPlanYear[0],
                huntingPlanMonth: huntingPlanMonth[0],
                huntingPlanYear: huntingPlanYear[0],
                huntingPlusPlanMonth: huntingPlusPlanMonth[0],
                huntingPlusPlanYear: huntingPlusPlanYear[0],
                ownerUpToFiveForestsPlanMonth: ownerUpToFiveForestsPlanMonth[0],
                ownerUpToFiveForestsPlanYear: ownerUpToFiveForestsPlanYear[0],
                unlimitedUserAccessPlanMonth: unlimitedUserAccessPlanMonth[0],
                unlimitedUserAccessPlanYear: unlimitedUserAccessPlanYear[0],
                plansLoaded: true,
            })
        }
        if (this.state.tempLoading !== this.props.loadingSub) {
            if (!this.props.loadingSub && this.state.addedPackage) {
                // Fire snackbar
                this.props.onEnqueueSnackbar({
                    message: 'Pakke er blevet tilføjet dit abonnement!',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                });
                this.setState({ addedPackage: false });
            }
            this.setState({ tempLoading: this.props.loadingSub })
        }

        // Check if KW Integration is active (requires active payed subscription)
        if (this.props.natureActive || this.props.operationActive || this.props.operationPlusActive) {
            this.props.onSetKwIntegrationActive(true);
        }

        // If forest owner set forest has payed subscription state (used to control CO2 functionality)
        if (this.props.currentAuthUser.userRole === 'forestOwner' && !this.state.tempSetForestHasSubState) {
            if (this.props.natureActive || this.props.operationActive) {
                this.props.currentAuthUser.forests.forEach(el => {
                    this.props.onSetForestHasPayedSubscription(el, true);
                })
            } else {
                this.props.currentAuthUser.forests.forEach(el => {
                    this.props.onSetForestHasPayedSubscription(el, false);
                })
            }
            this.setState({ tempSetForestHasSubState: true });
        }

        // Check if nature or operations subscription has changed state
        if (this.state.tempNatureActive !== this.props.natureActive || this.state.tempOperationActive !== this.props.operationActive) {
            this.setState({ tempNatureActive: this.props.natureActive, tempOperationActive: this.props.operationActive, tempSetForestHasSubState: false })
        }
    }

    commify = (n, decimals) => {
        var parts = n.toFixed(decimals).toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
    }

    monthlyYearlyClickHandler = (type) => {
        this.setState({ displayPackageType: type })
    }

    checkDiscountCode = e => {
        // Check if code already present
        if (this.state.discountCodeInserted) {
            this.setState({ discountLabel: "Rabatkode kan kun indtastes én gang!" })
            return;
        }
        // First check for DS affiliation and discount code on Firebase
        let fbMatch = [];
        let rpMatch = [];
        if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.ds.status === 'member' || (this.props.currentAuthUser.affiliation.sls && this.props.currentAuthUser.affiliation.sls.status === 'active')) {
            fbMatch = this.props.fbDiscountCodes.filter(code => {
                return code.affiliation === 'DS' && code.state === 'active' && e.target.value.toLowerCase() === code.name.toLowerCase();
            })
        }
        // Check for other discount codes on Reepay
        rpMatch = this.props.discountCodes.content.filter(code => {
            return code.state === 'active' && e.target.value.toLowerCase() === code.name.toLowerCase();
        })
        // If codes matches the DS code apply it
        if (fbMatch.length > 0) {
            // Check if pre-set discounts should be removed
            let reepayDisObjs = [...this.state.discountObj];
            reepayDisObjs = reepayDisObjs.filter(el => {
                return el.removedBy !== "DS";
            })
            // Find Reepay DS codes from discountCodesList
            fbMatch[0].reepayDisHandles.forEach(el => {
                const match = this.props.discountCodes.content.filter(code => {
                    return code.handle === el;
                });
                if (match.length > 0) {
                    reepayDisObjs.push(match[0]);
                }
            })
            // Set labels etc and set discount objects in state
            let discountLabel = '';
            if (fbMatch[0].timeApplicable === 'year') {
                discountLabel = 'Obs! Rabatten gælder kun årligt abonnement.'
            } else if (fbMatch[0].timeApplicable === 'month') {
                discountLabel = 'Obs! Rabatten gælder kun månedlig abonnement.'
            }
            this.setState({ discountObj: reepayDisObjs, discountCodeInserted: true, error: '', errorFlag: false, discountLabel: discountLabel })
        } else if (rpMatch.length > 0) {
            let discountLabel = '';
            if (rpMatch[0].description.indexOf('år') !== -1) {
                discountLabel = 'Obs! Rabatten gælder kun årligt abonnement.'
            } else if (rpMatch[0].description.indexOf('måned') !== -1) {
                discountLabel = 'Obs! Rabatten gælder kun månedlig abonnement.'
            }
            this.setState({ discountObj: [...this.state.discountObj, rpMatch[0]], discountCodeInserted: true, error: '', errorFlag: false, discountLabel: discountLabel })
        } else if (e.target.value !== '') {
            this.setState({ error: "wrong discount code", giveDiscount: false, discountLabel: '' })
        }
        // Reset error
        if (this.state.error !== '' && e.target.value === '') {
            this.setState({ error: '', errorFlag: false, discountLabel: '' })
        }
    }

    renderDiscountError = () => {
        const { error, errorFlag } = this.state;

        if (errorFlag && error !== '') {
            return (this.errorMessage("Ugyldig eller udløbet rabatkode"))
        }
    }

    errorMessage(text) {
        return (
            <Typography variant="body2" style={{ color: '#E23838', marginTop: 8, marginLeft: 22 }} gutterBottom>
                {text}
            </Typography>
        )
    }

    onAddPackageSub = () => {
        if (this.state.error === '') {
            // Get payment card id
            let cardId = null;
            let activeCards = null;
            let thirdParty = checkThirdPartySubscriptionHandling(this.props.currentAuthUser);
            // Check for payment details and cards
            if (this.props.paymentDetails && this.props.paymentDetails.cards) {
                activeCards = this.props.paymentDetails.cards.filter(card => {
                    return card.state === "active";
                })
                if (activeCards.length > 0) {
                    cardId = activeCards[0].id;
                }
            }
            // Check which subscriptions to add
            // Nature package
            if (this.state.natureCheck && this.props.natureSub === '') {
                this.props.onCreateSubscription('nature', this.state.displayPackageType, this.state.discountObj, cardId, thirdParty);
            }
            // Operation package
            if (this.state.operationCheck && this.props.operationSub === '') {
                this.props.onCreateSubscription('operation', this.state.displayPackageType, this.state.discountObj, cardId, thirdParty);
            }
            // Operation+ package
            if (this.state.operationPlusCheck && this.props.operationPlusSub === '') {
                this.props.onCreateSubscription('operationPlus', this.state.displayPackageType, this.state.discountObj, cardId, thirdParty);
            }
            // Hunting package
            if (this.state.huntingCheck && this.props.huntingSub === '') {
                this.props.onCreateSubscription('hunting', this.state.displayPackageType, this.state.discountObj, cardId, thirdParty);
            }
            // Hunting plus package
            if (this.state.huntingPlusCheck && this.props.huntingPlusSub === '') {
                this.props.onCreateSubscription('huntingPlus', this.state.displayPackageType, this.state.discountObj, cardId, thirdParty);
            }
            // Forest owner extra forests
            if (this.state.ownerUpToFiveForestsCheck && this.props.ownerUpToFiveForestsSub === '') {
                this.props.onCreateSubscription('ownerUpToFiveForests', this.state.displayPackageType, this.state.discountObj, cardId, thirdParty);
            }
            // Unlimited user access
            if (this.state.unlimitedUserAccessCheck && this.props.unlimitedUserAccessSub === '') {
                this.props.onCreateSubscription('unlimitedUserAccess', this.state.displayPackageType, this.state.discountObj, cardId, thirdParty);
            }
            // TODO : incorporate when better known
            // // Remove user from get-started mail chimp audience list if user buys package
            // if ("mailChimpData" in this.props.currentAuthUser) {
            //     this.props.onRemoveUserfromMailChimpAudienceList(configMailChimp.audKeyGetStarted, this.props.currentAuthUser.mailChimpData.id);
            // }
            // Add "bought" tag to user in get-started audience if they buy a package
            this.props.onAddTagToUserfromMailChimpAudienceList(configMailChimp.audKeyGetStarted, this.props.currentAuthUser.email, "bought")

            this.setState({ addedPackage: true })

        } else {
            this.setState({ errorFlag: true })
        }
    }

    cancelSubButtonHandler = (sub) => {
        this.props.onCancelSubscription(sub, this.props.currentAuthUser);
        this.setState({ showCancelSubscriptionBox: false })
        // Send email
        let content = {
            title: this.state.reasonCancel,
            type: sub
        };
        this.props.onSendEmail('cancelSubscription', this.props.currentAuthUser.id, '', content);
        // Send event to Analytics
        ReactGA.event({
            category: 'Subscription',
            action: `User terminated subscription: ${this.state.reasonCancel}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        // Fire snackbar
        this.props.onEnqueueSnackbar({
            message: 'Pakke er nu blevet opsagt! ',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        });

    }

    uncancelSubButtonHandler = (sub) => {
        this.props.onUncancelSubscription(sub, this.props.currentAuthUser);
        this.setState({ showUncancelSubscriptionBox: false })
        // Send email
        let content = {
            title: this.state.reasonUncancel,
            type: sub
        };
        this.props.onSendEmail('uncancelSubscription', this.props.currentAuthUser.id, '', content);
        // Send event to Analytics
        ReactGA.event({
            category: 'Subscription',
            action: `User uncancelled subscription: ${this.state.reasonUncancel}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        // Fire snackbar
        this.props.onEnqueueSnackbar({
            message: 'Annullering af opsigelse gennemført!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        });
    }

    openCancelUncancelDialog = (packageType) => {
        let subToBeCancledUncancled = null;
        let cancelText = '';
        let uncancelText = '';
        if (packageType === 'nature') {
            if (checkThirdPartySubscriptionHandling(this.props.currentAuthUser)) {
                subToBeCancledUncancled = 'nature';
            } else {
                subToBeCancledUncancled = this.props.natureSub.handle;
            }
            cancelText = 'Du er ved at opsige Skovejeren Basis pakken. Opsiges pakken vil du miste din optjente anciennitet og ikke længere kunne tilgå den funktionalitet som pakken tilbyder.\n\nVi ser gerne, at du knytter en kommentar til din opsigelse af pakken. Således kan vi forbedre vores service og glæde alle vores andre brugere.';
            uncancelText = 'Du er ved at annullere din opsigelse af Skovejeren Basis pakken. Godt nyt, du beholder din pris og optjente anciennitet.\n\nVi ser gerne, at du knytter en kommentar til annulleringen af din opsigelse. Velkommen tilbage.';
            if (this.props.natureSub.is_cancelled) {
                this.setState({ showUncancelSubscriptionBox: true });
            } else {
                this.setState({ showCancelSubscriptionBox: true });
            }
        } else if (packageType === 'operation') {
            if (checkThirdPartySubscriptionHandling(this.props.currentAuthUser)) {
                subToBeCancledUncancled = 'operation';
            } else {
                subToBeCancledUncancled = this.props.operationSub.handle;
            }
            cancelText = 'Du er ved at opsige Skovejeren Udvidet pakken. Opsiges pakken vil du miste din optjente anciennitet og ikke længere kunne tilgå den funktionalitet som pakken tilbyder.\n\nVi ser gerne, at du knytter en kommentar til din opsigelse af pakken. Således kan vi forbedre vores service og glæde alle vores andre brugere.';
            uncancelText = 'Du er ved at annullere din opsigelse af Skovejeren Udvidet pakken. Godt nyt, du beholder din pris og optjente anciennitet.\n\nVi ser gerne, at du knytter en kommentar til annulleringen af din opsigelse. Velkommen tilbage.';
            if (this.props.operationSub.is_cancelled) {
                this.setState({ showUncancelSubscriptionBox: true });
            } else {
                this.setState({ showCancelSubscriptionBox: true });
            }
        } else if (packageType === 'operationPlus') {
            if (checkThirdPartySubscriptionHandling(this.props.currentAuthUser)) {
                subToBeCancledUncancled = 'operationPlus';
            } else {
                subToBeCancledUncancled = this.props.operationPlusSub.handle;
            }
            cancelText = 'Du er ved at opsige Skovforvalteren Udvidet pakken. Opsiges pakken vil du miste din optjente anciennitet og ikke længere kunne tilgå den funktionalitet som pakken tilbyder.\n\nVi ser gerne, at du knytter en kommentar til din opsigelse af pakken. Således kan vi forbedre vores service og glæde alle vores andre brugere.';
            uncancelText = 'Du er ved at annullere din opsigelse af Skovforvalteren Udvidet pakken. Godt nyt, du beholder din pris og optjente anciennitet.\n\nVi ser gerne, at du knytter en kommentar til annulleringen af din opsigelse. Velkommen tilbage.';
            if (this.props.operationPlusSub.is_cancelled) {
                this.setState({ showUncancelSubscriptionBox: true });
            } else {
                this.setState({ showCancelSubscriptionBox: true });
            }
        } else if (packageType === 'hunting') {
            if (checkThirdPartySubscriptionHandling(this.props.currentAuthUser)) {
                subToBeCancledUncancled = 'hunting';
            } else {
                subToBeCancledUncancled = this.props.huntingSub.handle;
            }
            cancelText = 'Du er ved at opsige Jagt pakken. Opsiges pakken vil du miste din optjente anciennitet og ikke længere kunne tilgå den funktionalitet som pakken tilbyder.\n\nVi ser gerne, at du knytter en kommentar til din opsigelse af pakken. Således kan vi forbedre vores service og glæde alle vores andre brugere.';
            uncancelText = 'Du er ved at annullere din opsigelse af Jagt pakken. Godt nyt, du beholder din pris og optjente anciennitet.\n\nVi ser gerne, at du knytter en kommentar til annulleringen af din opsigelse. Velkommen tilbage.';
            if (this.props.huntingSub.is_cancelled) {
                this.setState({ showUncancelSubscriptionBox: true });
            } else {
                this.setState({ showCancelSubscriptionBox: true });
            }
        } else if (packageType === 'huntingPlus') {
            if (checkThirdPartySubscriptionHandling(this.props.currentAuthUser)) {
                subToBeCancledUncancled = 'huntingPlus';
            } else {
                subToBeCancledUncancled = this.props.huntingPlusSub.handle;
            }
            cancelText = 'Du er ved at opsige Jagt Udvidet pakken. Opsiges pakken vil du miste din optjente anciennitet og ikke længere kunne tilgå den funktionalitet som pakken tilbyder.\n\nVi ser gerne, at du knytter en kommentar til din opsigelse af pakken. Således kan vi forbedre vores service og glæde alle vores andre brugere.';
            uncancelText = 'Du er ved at annullere din opsigelse af Jagt Udvidet pakken. Godt nyt, du beholder din pris og optjente anciennitet.\n\nVi ser gerne, at du knytter en kommentar til annulleringen af din opsigelse. Velkommen tilbage.';
            if (this.props.huntingPlusSub.is_cancelled) {
                this.setState({ showUncancelSubscriptionBox: true });
            } else {
                this.setState({ showCancelSubscriptionBox: true });
            }
        } else if (packageType === 'ownerUpToFiveForests') {
            if (checkThirdPartySubscriptionHandling(this.props.currentAuthUser)) {
                subToBeCancledUncancled = 'ownerUpToFiveForests';
            } else {
                subToBeCancledUncancled = this.props.ownerUpToFiveForestsSub.handle;
            }
            cancelText = 'Du er ved at opsige Flere skovkort pakken. Opsiges pakken vil du miste din optjente anciennitet og ikke længere kunne tilgå den funktionalitet som pakken tilbyder.\n\nVi ser gerne, at du knytter en kommentar til din opsigelse af pakken. Således kan vi forbedre vores service og glæde alle vores andre brugere.';
            uncancelText = 'Du er ved at annullere din opsigelse af Flere skovkort pakken. Godt nyt, du beholder din pris og optjente anciennitet.\n\nVi ser gerne, at du knytter en kommentar til annulleringen af din opsigelse. Velkommen tilbage.';
            if (this.props.ownerUpToFiveForestsSub.is_cancelled) {
                this.setState({ showUncancelSubscriptionBox: true });
            } else {
                this.setState({ showCancelSubscriptionBox: true });
            }
        } else if (packageType === 'unlimitedUserAccess') {
            if (checkThirdPartySubscriptionHandling(this.props.currentAuthUser)) {
                subToBeCancledUncancled = 'unlimitedUserAccess';
            } else {
                subToBeCancledUncancled = this.props.unlimitedUserAccessSub.handle;
            }
            cancelText = 'Du er ved at opsige Deling af skovkort pakken. Opsiges pakken vil du miste din optjente anciennitet og ikke længere kunne tilgå den funktionalitet som pakken tilbyder.\n\nVi ser gerne, at du knytter en kommentar til din opsigelse af pakken. Således kan vi forbedre vores service og glæde alle vores andre brugere.';
            uncancelText = 'Du er ved at annullere din opsigelse af Deling af skovkort pakken. Godt nyt, du beholder din pris og optjente anciennitet.\n\nVi ser gerne, at du knytter en kommentar til annulleringen af din opsigelse. Velkommen tilbage.';
            if (this.props.unlimitedUserAccessSub.is_cancelled) {
                this.setState({ showUncancelSubscriptionBox: true });
            } else {
                this.setState({ showCancelSubscriptionBox: true });
            }
        }
        this.setState({ subToBeCancledUncancled: subToBeCancledUncancled, cancelText: cancelText, uncancelText: uncancelText })
    }

    newPackageNote = (labelVal, chipLabel, newPlan, style, showChip) => {
        return (
            <div>
                <Typography style={{ marginBottom: 6 }}>
                    {labelVal}
                </Typography>

                {showChip && <div style={{ display: 'flex' }}>
                    <Chip size="small" color="primary" label={chipLabel} style={{ marginRight: 5, marginTop: -3 }} />

                    <Typography style={{ marginRight: 4, fontSize: style === 'strong' ? 14 : 12, color: style === 'strong' ? 'black' : '#5ABF34', fontWeight: style === 'strong' ? 500 : 400 }}>
                        {newPlan}
                    </Typography>
                </div>}
            </div>
        )
    }

    newPackages = () => {
        const { classes } = this.props;
        let naturePackContent = null;
        let operationPackContent = null;
        let operationPlusPackContent = null;
        let huntingPackContent = null;
        let huntingPlusPackContent = null;
        let ownerFiveForestsPackContent = null;
        let unlimitedUserAccessPackContent = null;

        // --- If forester they need to contact us for price --- //
        if (this.props.currentAuthUser.userRole === 'forester') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TipMenu
                        title={this.props.config.tipTitle}
                        content={this.props.config.tipText}
                    />
                </div>
            )
        }

        // --- Other user profiles --- //
        // Force owner extra forests if user has more than 1 forest in the list
        let forceExtraForests = false;
        if (this.props.currentAuthUser.userRole === 'forestOwner' && !this.props.ownerUpToFiveForestsActive && (this.state.natureCheck || this.state.operationCheck)) {
            if (this.props.currentAuthUser.affiliation &&
                this.props.currentAuthUser.affiliation.skovdyrkerne &&
                this.props.currentAuthUser.affiliation.skovdyrkerne.status === 'active' &&
                this.props.currentAuthUser.affiliation.skovdyrkerne.forestList &&
                this.props.currentAuthUser.affiliation.skovdyrkerne.forestList.length > 1) {
                forceExtraForests = true;
            } else {
                if (this.props.currentAuthUser.forests.length > 1) {
                    forceExtraForests = true;
                }
            }
        }
        if (forceExtraForests && !this.state.ownerUpToFiveForestsCheck) {
            this.setState({ ownerUpToFiveForestsCheck: true })
        }
        // Set some packages back to deselected
        if (!forceExtraForests && !this.state.natureCheck && !this.state.operationCheck && !this.props.natureActive && !this.props.operationActive && this.state.ownerUpToFiveForestsCheck) {
            this.setState({ ownerUpToFiveForestsCheck: false })
        }
        if (!this.state.natureCheck && !this.state.operationCheck && !this.props.natureActive && !this.props.operationActive && !this.props.operationPlusActive && this.state.unlimitedUserAccessCheck) {
            this.setState({ unlimitedUserAccessCheck: false })
        }
        // Set nature pack content
        if (this.props.natureSub === '' && this.props.operationSub === '' && this.state.naturePlanMonth && this.state.naturePlanYear) {
            const labelVal = this.state.displayPackageType === 'month' ?
                this.state.naturePlanMonth.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.naturePlanMonth.amount / 100) + ',- pr. måned' :
                this.state.naturePlanYear.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.naturePlanYear.amount / 100) + ',- pr. år';
            const discountLabelVal = this.props.config.discountFixed !== 0 ? this.props.config.discountFixedText + this.commify((this.state.naturePlanYear.amount / 100) * (1 - (this.props.config.discountFixed / 100))) + ',- pr. år' : "";
            naturePackContent = <div style={{ marginBottom: 12 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.natureCheck}
                            onChange={() => this.setState({ natureCheck: !this.state.natureCheck, operationCheck: false })}
                            color="primary"
                        />
                    }
                    style={{ whiteSpace: 'pre-line' }}
                    //label={labelVal}
                    label={this.newPackageNote(labelVal, 'Rabat', discountLabelVal, 'strong', discountLabelVal !== "")}
                />
            </div>
        }
        // Set Operation pack content
        if (this.props.currentAuthUser.userRole === 'forestOwner' && this.props.operationSub === '' && this.state.operationPlanMonth && this.state.operationPlanYear) {
            const labelVal = this.state.displayPackageType === 'month' ?
                this.state.operationPlanMonth.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.operationPlanMonth.amount / 100) + ',- pr. måned' :
                this.state.operationPlanYear.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.operationPlanYear.amount / 100) + ',- pr. år';
            const discountLabelVal = this.props.config.discountFixed !== 0 ? this.props.config.discountFixedText + this.commify((this.state.operationPlanYear.amount / 100) * (1 - (this.props.config.discountFixed / 100))) + ',- pr. år' : "";
            operationPackContent = <div style={{ marginBottom: 12 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.operationCheck}
                            onChange={() => this.setState({ operationCheck: !this.state.operationCheck, natureCheck: false })}
                            color="primary"
                        />
                    }
                    style={{ whiteSpace: 'pre-line' }}
                    // label={labelVal}
                    label={this.newPackageNote(labelVal, 'Rabat', discountLabelVal, 'strong', discountLabelVal !== "")}
                />
            </div>
        }
        // Set Operation+ pack content
        if (this.props.currentAuthUser.userRole === 'forester' && this.props.operationPlusSub === '' && this.state.operationPlusPlanMonth && this.state.operationPlusPlanYear) {
            const labelVal = this.state.displayPackageType === 'month' ?
                this.state.operationPlusPlanMonth.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.operationPlusPlanMonth.amount / 100) + ',- pr. måned' :
                this.state.operationPlusPlanYear.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.operationPlusPlanYear.amount / 100) + ',- pr. år';
            const discountLabelVal = this.props.config.discountFixed !== 0 ? this.props.config.discountFixedText + this.commify((this.state.operationPlusPlanYear.amount / 100) * (1 - (this.props.config.discountFixed / 100))) + ',- pr. år' : "";
            operationPlusPackContent = <div style={{ marginBottom: 12 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.operationPlusCheck}
                            onChange={() => this.setState({ operationPlusCheck: !this.state.operationPlusCheck })}
                            color="primary"
                        />
                    }
                    style={{ whiteSpace: 'pre-line' }}
                    // label={labelVal}
                    label={this.newPackageNote(labelVal, 'Rabat', discountLabelVal, 'strong', discountLabelVal !== "")}
                />
            </div>
        }
        // // Hunting pack content
        // if (this.props.huntingSub === '' && this.props.huntingPlusSub === '' && this.state.huntingPlanMonth && this.state.huntingPlanYear) {
        //     const labelVal = this.state.displayPackageType === 'month' ?
        //         this.state.huntingPlanMonth.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.huntingPlanMonth.amount / 100) + ',- pr. måned' :
        //         this.state.huntingPlanYear.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.huntingPlanYear.amount / 100) + ',- pr. år';
        //     const discountLabelVal = this.props.config.discountFixed !== 0 ? this.props.config.discountFixedText + this.commify((this.state.huntingPlanYear.amount / 100) * (1 - (this.props.config.discountFixed / 100))) + ',- pr. år' : "";
        //     huntingPackContent = <div style={{ marginBottom: 12 }}>
        //         <FormControlLabel
        //             control={
        //                 <Switch
        //                     checked={this.state.huntingCheck}
        //                     onChange={() => this.setState({ huntingCheck: !this.state.huntingCheck, huntingPlusCheck: false })}
        //                     color="primary"
        //                 />
        //             }
        //             style={{ whiteSpace: 'pre-line' }}
        //             // label={labelVal}
        //             label={this.newPackageNote(labelVal, 'Rabat', discountLabelVal, 'strong', discountLabelVal !== "")}
        //         />
        //     </div>
        // }
        // // Hunting plus pack content
        // if (this.props.huntingPlusSub === '' && this.state.huntingPlusPlanMonth && this.state.huntingPlusPlanYear) {
        //     const labelVal = this.state.displayPackageType === 'month' ?
        //         this.state.huntingPlusPlanMonth.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.huntingPlusPlanMonth.amount / 100) + ',- pr. måned' :
        //         this.state.huntingPlusPlanYear.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.huntingPlusPlanYear.amount / 100) + ',- pr. år';
        //     const discountLabelVal = this.props.config.discountFixed !== 0 ? this.props.config.discountFixedText + this.commify((this.state.huntingPlusPlanYear.amount / 100) * (1 - (this.props.config.discountFixed / 100))) + ',- pr. år' : "";
        //     huntingPlusPackContent = <div style={{ marginBottom: 12 }}>
        //         <FormControlLabel
        //             control={
        //                 <Switch
        //                     checked={this.state.huntingPlusCheck}
        //                     onChange={() => this.setState({ huntingPlusCheck: !this.state.huntingPlusCheck, huntingCheck: false })}
        //                     color="primary"
        //                 />
        //             }
        //             style={{ whiteSpace: 'pre-line' }}
        //             // label={labelVal}
        //             label={this.newPackageNote(labelVal, 'Rabat', discountLabelVal, 'strong', discountLabelVal !== "")}
        //         />
        //     </div>
        // }
        // Owner up to five forests package content
        if (this.props.ownerUpToFiveForestsSub === '' && this.state.ownerUpToFiveForestsPlanMonth && this.state.ownerUpToFiveForestsPlanYear) {
            const labelVal = this.state.displayPackageType === 'month' ?
                this.state.ownerUpToFiveForestsPlanMonth.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.ownerUpToFiveForestsPlanMonth.amount / 100) + ',- pr. måned' :
                this.state.ownerUpToFiveForestsPlanYear.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.ownerUpToFiveForestsPlanYear.amount / 100) + ',- pr. år';
            const discountLabelVal = this.props.config.discountFixed !== 0 ? this.props.config.discountFixedText + this.commify((this.state.ownerUpToFiveForestsPlanYear.amount / 100) * (1 - (this.props.config.discountFixed / 100))) + ',- pr. år' : "";
            ownerFiveForestsPackContent = <div style={{ marginBottom: 12 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.ownerUpToFiveForestsCheck}
                            onChange={() => this.setState({ ownerUpToFiveForestsCheck: !this.state.ownerUpToFiveForestsCheck })}
                            disabled={forceExtraForests || (!this.state.natureCheck && !this.state.operationCheck && !this.props.natureActive && !this.props.operationActive)}
                            color="primary"
                        />
                    }
                    style={{ whiteSpace: 'pre-line' }}
                    // label={labelVal}
                    label={this.newPackageNote(labelVal, 'Rabat', discountLabelVal, 'strong', discountLabelVal !== "")}
                />
            </div>
        }
        // Unlimited user access package content
        if (this.props.unlimitedUserAccessSub === '' && this.state.unlimitedUserAccessPlanMonth && this.state.unlimitedUserAccessPlanYear) {
            const labelVal = this.state.displayPackageType === 'month' ?
                this.state.unlimitedUserAccessPlanMonth.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.unlimitedUserAccessPlanMonth.amount / 100) + ',- pr. måned' :
                this.state.unlimitedUserAccessPlanYear.name.split('(')[0].slice(0, -1) + '\n' + this.commify(this.state.unlimitedUserAccessPlanYear.amount / 100) + ',- pr. år';
            const discountLabelVal = this.props.config.discountFixed !== 0 ? this.props.config.discountFixedText + this.commify((this.state.unlimitedUserAccessPlanYear.amount / 100) * (1 - (this.props.config.discountFixed / 100))) + ',- pr. år' : "";
            unlimitedUserAccessPackContent = <div style={{ marginBottom: 12 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.unlimitedUserAccessCheck}
                            onChange={() => this.setState({ unlimitedUserAccessCheck: !this.state.unlimitedUserAccessCheck })}
                            disabled={!this.state.natureCheck && !this.state.operationCheck && !this.props.natureActive && !this.props.operationActive && !this.props.operationPlusActive}
                            color="primary"
                        />
                    }
                    style={{ whiteSpace: 'pre-line' }}
                    // label={labelVal}
                    label={this.newPackageNote(labelVal, 'Rabat', discountLabelVal, 'strong', discountLabelVal !== "")}
                />
            </div>
        }

        // See if any discounts should be given
        // TODO : Fix this discount shit. More to some other configuration setup
        if (!this.state.tempConfigDiscountsGiven && this.props.config.discountPlans && this.props.config.discountPlans.length > 0 && this.props.discountCodes) {
            // Find discount plans and add to state
            let discounts = [];
            this.props.config.discountPlans.forEach(plan => {
                let reepayDis = [];
                if (reepayConfig.env === 'production') {
                    reepayDis = this.props.discountCodes.content.filter(rpd => {
                        return rpd.state === 'active' && rpd.handle === plan.prodHandle;
                    })
                } else {
                    reepayDis = this.props.discountCodes.content.filter(rpd => {
                        return rpd.state === 'active' && rpd.handle === plan.devHandle;
                    })
                }
                if (reepayDis.length > 0) {
                    discounts.push({ ...reepayDis[0], removedBy: plan.removedBy ? plan.removedBy : '' });
                }
            })
            this.setState({ tempConfigDiscountsGiven: true, discountObj: [...this.state.discountObj, ...discounts], giveDiscount: true })
        }

        // Calculate total price
        let totalPrice = 0;
        let discountLabel = '';
        if (this.state.displayPackageType === 'year') {
            if (this.state.natureCheck && this.state.naturePlanYear) {
                totalPrice = totalPrice + this.state.naturePlanYear.amount / 100;
            }
            if (this.state.operationCheck && this.state.operationPlanYear) {
                totalPrice = totalPrice + this.state.operationPlanYear.amount / 100;
            }
            if (this.state.operationPlusCheck && this.state.operationPlusPlanYear) {
                totalPrice = totalPrice + this.state.operationPlusPlanYear.amount / 100;
            }
            if (this.state.huntingCheck && this.state.huntingPlanYear) {
                totalPrice = totalPrice + this.state.huntingPlanYear.amount / 100;
            }
            if (this.state.huntingPlusCheck && this.state.huntingPlusPlanYear) {
                totalPrice = totalPrice + this.state.huntingPlusPlanYear.amount / 100;
            }
            if (this.state.ownerUpToFiveForestsCheck && this.state.ownerUpToFiveForestsPlanYear) {
                totalPrice = totalPrice + this.state.ownerUpToFiveForestsPlanYear.amount / 100;
            }
            if (this.state.unlimitedUserAccessCheck && this.state.unlimitedUserAccessPlanYear) {
                totalPrice = totalPrice + this.state.unlimitedUserAccessPlanYear.amount / 100;
            }
            // Subtract discount
            if (this.state.discountObj.length === 1 && (this.state.discountObj[0].description.indexOf('år') !== -1 || this.state.discountObj[0].name.indexOf('SLS') !== -1)) {
                totalPrice = totalPrice * (1 - this.state.discountObj[0].percentage / 100);
                discountLabel = ' med ' + this.state.discountObj[0].percentage + ' % rabat!'
            } else if (this.state.discountObj.length > 1) {
                let perc = 0;
                this.state.discountObj.forEach(el => {
                    perc = perc + el.percentage;
                })
                totalPrice = totalPrice * (1 - perc / 100);
                discountLabel = ' med ' + perc + ' % rabat!';
            } else if (this.props.config.discountFixed > 0) {
                totalPrice = totalPrice * (1 - this.props.config.discountFixed / 100);
                discountLabel = ' (inkl.' + this.props.config.discountFixed + ' % rabat)'
            }
        } else {
            if (this.state.natureCheck && this.state.naturePlanMonth) {
                totalPrice = totalPrice + this.state.naturePlanMonth.amount / 100;
            }
            if (this.state.operationCheck && this.state.operationPlanMonth) {
                totalPrice = totalPrice + this.state.operationPlanMonth.amount / 100;
            }
            if (this.state.operationPlusCheck && this.state.operationPlusPlanMonth) {
                totalPrice = totalPrice + this.state.operationPlusPlanMonth.amount / 100;
            }
            if (this.state.huntingCheck && this.state.huntingPlanMonth) {
                totalPrice = totalPrice + this.state.huntingPlanMonth.amount / 100;
            }
            if (this.state.huntingPlusCheck && this.state.huntingPlusPlanMonth) {
                totalPrice = totalPrice + this.state.huntingPlusPlanMonth.amount / 100;
            }
            if (this.state.ownerUpToFiveForestsCheck && this.state.ownerUpToFiveForestsPlanMonth) {
                totalPrice = totalPrice + this.state.ownerUpToFiveForestsPlanMonth.amount / 100;
            }
            if (this.state.unlimitedUserAccessCheck && this.state.unlimitedUserAccessPlanMonth) {
                totalPrice = totalPrice + this.state.unlimitedUserAccessPlanMonth.amount / 100;
            }
            // Subtract discount
            if (this.state.discountObj.length === 1 && this.state.discountObj[0].description.indexOf('måned') !== -1) {
                totalPrice = totalPrice * (1 - this.state.discountObj[0].percentage / 100);
                discountLabel = ' med ' + this.state.discountObj[0].percentage + ' % rabat!'
            }
        }
        if (totalPrice !== this.state.totalPrice) {
            this.setState({ totalPrice: totalPrice });
        }
        if (discountLabel !== this.state.discountLabel) {
            this.setState({ discountLabel: discountLabel });
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.skovdyrkerne && this.props.currentAuthUser.affiliation.skovdyrkerne.status && this.props.currentAuthUser.affiliation.skovdyrkerne.status === 'active') && <TipMenu
                    title={this.props.config.tipTitle}
                    content={this.props.config.tipText}
                />}

                {/* {(this.props.config.yearly === 'enabled' && this.props.config.monthly === 'enabled') && <div style={{ margin: '22px 0px 16px 0px' }}>
                    <Button
                        variant={this.state.displayPackageType === 'month' ? 'outlined' : 'text'}
                        onClick={() => this.monthlyYearlyClickHandler('month')}
                        style={{ color: this.state.displayPackageType === 'month' ? '#5EBD3E' : '#808080', paddingLeft: 4, paddingRight: 4 }}
                        disabled={this.props.config.monthly !== 'enabled'}
                    >
                        Månedlig
                    </Button>

                    <Button
                        variant={this.state.displayPackageType === 'year' ? 'outlined' : 'text'}
                        onClick={() => this.monthlyYearlyClickHandler('year')}
                        style={{ color: this.state.displayPackageType === 'year' ? '#5EBD3E' : '#808080', paddingLeft: 4, paddingRight: 4 }}
                        disabled={this.props.config.yearly !== 'enabled'}
                    >
                        Årlig
                    </Button>
                </div>} */}

                {!(this.props.operationActive || this.props.operationPlusActive) && <Typography variant="caption" color="textSecondary" style={{ marginBottom: 12, marginTop: (this.props.config.yearly === 'enabled' && this.props.config.monthly === 'enabled') ? 0 : 12 }}>
                    Mulige abonnement til din konto.
                </Typography>}
                {this.props.currentAuthUser.userRole === 'forestOwner' && naturePackContent}
                {this.props.currentAuthUser.userRole === 'forestOwner' && operationPackContent}
                {this.props.currentAuthUser.userRole === 'forester' && operationPlusPackContent}

                <Typography variant="caption" color="textSecondary" style={{ marginBottom: 12, marginTop: 18 }}>
                    Mulige tilkøb til din konto.
                </Typography>
                {this.props.currentAuthUser.userRole === 'forestOwner' && ownerFiveForestsPackContent}
                {forceExtraForests && <Typography variant="caption" color="textSecondary" style={{ marginBottom: 12 }}>
                    Tilkøb nødvendigt, da din konto indeholder mere end én skov.
                </Typography>}
                {(this.props.config.unlimitedUserAccessSubscription !== 'hidden' && this.props.currentAuthUser.userRole === 'forestOwner') && unlimitedUserAccessPackContent}
                {huntingPackContent}
                {huntingPlusPackContent}

                {this.props.config.discounts !== 'hidden' && <div>
                    <Typography variant="caption" color="textSecondary" style={{ marginTop: 18 }}>
                        Indtast rabatkode.
                    </Typography>

                    <TextField
                        id="discountCode"
                        label="Rabatkode"
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 12 }}
                        onChange={e => this.checkDiscountCode(e)}
                        name="discountCode"
                    />

                    <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ marginTop: 8 }}
                    >
                        {this.state.discountLabel}
                    </Typography>
                </div>}

                {this.renderDiscountError()}

                <div className={classes.button}>
                    <div className={classes.wrapper}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => this.setState({ buyModalOpen: true })}
                            disabled={(!this.state.natureCheck && !this.state.operationCheck && !this.state.operationPlusCheck && !this.state.huntingCheck && !this.state.huntingPlusCheck && !this.state.ownerUpToFiveForestsCheck && !this.state.unlimitedUserAccessCheck)}
                        >
                            Køb
                            <IconChevronRight />
                        </Button>
                        {this.props.loadingSub && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>



                {(this.state.natureCheck || this.state.operationCheck || this.state.operationPlusCheck || this.state.huntingCheck || this.state.huntingPlusCheck || this.state.ownerUpToFiveForestsCheck || this.state.unlimitedUserAccessCheck) && <Typography
                    style={{ marginTop: 12 }}
                >
                    {"Samlet pris: " + this.commify(totalPrice) + ",-" + (this.state.displayPackageType === 'year' ? " pr. år" : " pr. måned") + discountLabel}
                </Typography>}

                <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ marginTop: 12 }}
                >
                    Alle priser er ekskl. moms.
                </Typography>
            </div>
        )
    }

    currentPackages = (renderNewPackages) => {
        let currentPackContent = [];
        // Check for active packages
        if (this.props.planList) {
            if (this.props.natureActive && this.props.natureSub && this.props.natureSub.state === 'active') {
                // Set toggle switch back to false
                if (this.state.natureCheck) {
                    this.setState({ natureCheck: false })
                }
                // Retrive nature plan
                const natPlan = this.props.planList.filter(plan => {
                    return plan.handle === this.props.natureSub.plan;
                })
                // Set stuff if subscription has been cancelled
                let subTermText = '';
                if (this.props.natureSub.is_cancelled) {
                    // Set expire text if subscription has been cancelled
                    subTermText = "Skovejeren Basis pakke udløber " + moment(this.props.natureSub.expires).format('DD[-]MM[-]YYYY')
                    // Set toggle button state to false
                    if (this.state.togglerNatureChecked) {
                        this.setState({ togglerNatureChecked: false })
                    }
                } else {
                    if (!this.state.togglerNatureChecked) {
                        this.setState({ togglerNatureChecked: true })
                    }
                }
                // Push package content for render
                currentPackContent.push(
                    <div key={natPlan[0].name}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.togglerNatureChecked}
                                    onChange={() => this.openCancelUncancelDialog('nature')}
                                    color="primary"
                                />
                            }
                            label={natPlan[0].name}
                        />
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ paddingTop: 10 }}
                            >
                                {subTermText}
                            </Typography>
                        </div>
                    </div>
                )
            }
            if (this.props.operationActive && this.props.operationSub && this.props.operationSub.state === 'active') {
                // Set toggle switch back to false
                if (this.state.operationCheck) {
                    this.setState({ operationCheck: false })
                }
                // Retrive operation plan
                const opPlan = this.props.planList.filter(plan => {
                    return plan.handle === this.props.operationSub.plan;
                })
                // Set stuff if subscription has been cancelled
                let subTermText = '';
                if (this.props.operationSub.is_cancelled) {
                    // Set expire text if subscription has been cancelled
                    subTermText = "Skovejeren Udvidet pakke udløber " + moment(this.props.operationSub.expires).format('DD[-]MM[-]YYYY')
                    // Set toggle button state to false
                    if (this.state.togglerOperationChecked) {
                        this.setState({ togglerOperationChecked: false })
                    }
                } else {
                    if (!this.state.togglerOperationChecked) {
                        this.setState({ togglerOperationChecked: true })
                    }
                }
                // Push package content for render
                currentPackContent.push(
                    <div key={opPlan[0].name}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.togglerOperationChecked}
                                    onChange={() => this.openCancelUncancelDialog('operation')}
                                    color="primary"
                                />
                            }
                            label={opPlan[0].name}
                        />
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ paddingTop: 10 }}
                            >
                                {subTermText}
                            </Typography>
                        </div>
                    </div>
                )
            }
            if (this.props.operationPlusActive && this.props.operationPlusSub && this.props.operationPlusSub.state === 'active') {
                // Set toggle switch back to false
                if (this.state.operationPlusCheck) {
                    this.setState({ operationPlusCheck: false })
                }
                // Retrive operation+ plan
                const oppPlan = this.props.planList.filter(plan => {
                    return plan.handle === this.props.operationPlusSub.plan;
                })
                // Set stuff if subscription has been cancelled
                let subTermText = '';
                if (this.props.operationPlusSub.is_cancelled) {
                    // Set expire text if subscription has been cancelled
                    subTermText = "Skovforvalteren Udvidet pakke udløber " + moment(this.props.operationPlusSub.expires).format('DD[-]MM[-]YYYY')
                    // Set toggle button state to false
                    if (this.state.togglerOperationPlusChecked) {
                        this.setState({ togglerOperationPlusChecked: false })
                    }
                } else {
                    if (!this.state.togglerOperationPlusChecked) {
                        this.setState({ togglerOperationPlusChecked: true })
                    }
                }
                // Push package content for render
                currentPackContent.push(
                    <div key={oppPlan[0].name}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.togglerOperationPlusChecked}
                                    onChange={() => this.openCancelUncancelDialog('operationPlus')}
                                    color="primary"
                                />
                            }
                            label={oppPlan[0].name}
                        />
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ paddingTop: 10 }}
                            >
                                {subTermText}
                            </Typography>
                        </div>
                    </div>
                )
            }
            if (this.props.huntingActive && this.props.huntingSub && this.props.huntingSub.state === 'active') {
                // Set toggle switch back to false
                if (this.state.huntingCheck) {
                    this.setState({ huntingCheck: false })
                }
                // Retrive hunting plan
                const hunPlan = this.props.planList.filter(plan => {
                    return plan.handle === this.props.huntingSub.plan;
                })
                // Set stuff if subscription has been cancelled
                let subTermText = '';
                if (this.props.huntingSub.is_cancelled) {
                    // Set expire text if subscription has been cancelled
                    subTermText = "Jagtpakke udløber " + moment(this.props.huntingSub.expires).format('DD[-]MM[-]YYYY')
                    // Set toggle button state to false
                    if (this.state.togglerHuntingChecked) {
                        this.setState({ togglerHuntingChecked: false })
                    }
                } else {
                    if (!this.state.togglerHuntingChecked) {
                        this.setState({ togglerHuntingChecked: true })
                    }
                }
                // Push package content for render
                currentPackContent.push(
                    <div key={hunPlan[0].name}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.togglerHuntingChecked}
                                    onChange={() => this.openCancelUncancelDialog('hunting')}
                                    color="primary"
                                />
                            }
                            label={hunPlan[0].name}
                        />
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ paddingTop: 10 }}
                            >
                                {subTermText}
                            </Typography>
                        </div>
                    </div>
                )
            }
            if (this.props.huntingPlusActive && this.props.huntingPlusSub && this.props.huntingPlusSub.state === 'active') {
                // Set toggle switch back to false
                if (this.state.huntingPlusCheck) {
                    this.setState({ huntingPlusCheck: false })
                }
                // Retrive hunting plus plan
                const hunPlan = this.props.planList.filter(plan => {
                    return plan.handle === this.props.huntingPlusSub.plan;
                })
                // Set stuff if subscription has been cancelled
                let subTermText = '';
                if (this.props.huntingPlusSub.is_cancelled) {
                    // Set expire text if subscription has been cancelled
                    subTermText = "Jagt Udvidet pakke udløber " + moment(this.props.huntingPlusSub.expires).format('DD[-]MM[-]YYYY')
                    // Set toggle button state to false
                    if (this.state.togglerHuntingPlusChecked) {
                        this.setState({ togglerHuntingPlusChecked: false })
                    }
                } else {
                    if (!this.state.togglerHuntingPlusChecked) {
                        this.setState({ togglerHuntingPlusChecked: true })
                    }
                }
                // Push package content for render
                currentPackContent.push(
                    <div key={hunPlan[0].name}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.togglerHuntingPlusChecked}
                                    onChange={() => this.openCancelUncancelDialog('huntingPlus')}
                                    color="primary"
                                />
                            }
                            label={hunPlan[0].name}
                        />
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ paddingTop: 10 }}
                            >
                                {subTermText}
                            </Typography>
                        </div>
                    </div>
                )
            }
            if (this.props.ownerUpToFiveForestsActive && this.props.ownerUpToFiveForestsSub && this.props.ownerUpToFiveForestsSub.state === 'active') {
                // Set toggle switch back to false
                if (this.state.ownerUpToFiveForestsCheck) {
                    this.setState({ ownerUpToFiveForestsCheck: false })
                }
                // Retrive hunting plus plan
                const plan = this.props.planList.filter(plan => {
                    return plan.handle === this.props.ownerUpToFiveForestsSub.plan;
                })
                // Set stuff if subscription has been cancelled
                let subTermText = '';
                if (this.props.ownerUpToFiveForestsSub.is_cancelled) {
                    // Set expire text if subscription has been cancelled
                    subTermText = "Flere skovkort pakke udløber " + moment(this.props.ownerUpToFiveForestsSub.expires).format('DD[-]MM[-]YYYY')
                    // Set toggle button state to false
                    if (this.state.togglerOwnerUpToFiveForestsChecked) {
                        this.setState({ togglerOwnerUpToFiveForestsChecked: false })
                    }
                } else {
                    if (!this.state.togglerOwnerUpToFiveForestsChecked) {
                        this.setState({ togglerOwnerUpToFiveForestsChecked: true })
                    }
                }
                // Push package content for render
                currentPackContent.push(
                    <div key={plan[0].name}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.togglerOwnerUpToFiveForestsChecked}
                                    onChange={() => this.openCancelUncancelDialog('ownerUpToFiveForests')}
                                    color="primary"
                                />
                            }
                            label={plan[0].name}
                        />
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ paddingTop: 10 }}
                            >
                                {subTermText}
                            </Typography>
                        </div>
                    </div>
                )
            }
            if (this.props.unlimitedUserAccessActive && this.props.unlimitedUserAccessSub && this.props.unlimitedUserAccessSub.state === 'active') {
                // Set toggle switch back to false
                if (this.state.unlimitedUserAccessCheck) {
                    this.setState({ unlimitedUserAccessCheck: false })
                }
                // Retrive hunting plus plan
                const plan = this.props.planList.filter(plan => {
                    return plan.handle === this.props.unlimitedUserAccessSub.plan;
                })
                // Set stuff if subscription has been cancelled
                let subTermText = '';
                if (this.props.unlimitedUserAccessSub.is_cancelled) {
                    // Set expire text if subscription has been cancelled
                    subTermText = "Deling af skovkort pakke udløber " + moment(this.props.unlimitedUserAccessSub.expires).format('DD[-]MM[-]YYYY')
                    // Set toggle button state to false
                    if (this.state.togglerUnlimitedUserAccess) {
                        this.setState({ togglerUnlimitedUserAccess: false })
                    }
                } else {
                    if (!this.state.togglerUnlimitedUserAccess) {
                        this.setState({ togglerUnlimitedUserAccess: true })
                    }
                }
                // Push package content for render
                currentPackContent.push(
                    <div key={plan[0].name}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.togglerUnlimitedUserAccess}
                                    onChange={() => this.openCancelUncancelDialog('unlimitedUserAccess')}
                                    color="primary"
                                />
                            }
                            label={plan[0].name}
                        />
                        <div>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ paddingTop: 10 }}
                            >
                                {subTermText}
                            </Typography>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div>
                {currentPackContent.length > 0 && <div style={{ marginTop: renderNewPackages ? 24 : 0 }}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Dine abonnement
                    </Typography >
                </div>}

                {currentPackContent}
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        // Check if subscription details should be shown
        let showSubDetails = true;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skovejer"].includes(tag))) {
            showSubDetails = false;
        }

        let renderNewPackages = false;
        if (((this.props.currentAuthUser.userRole === 'forestOwner' && ((this.props.natureSub === '' && this.props.operationSub === '') || this.props.huntingPlusSub === '')) || (this.props.currentAuthUser.userRole === 'forester' && (this.props.operationPlusSub === '' || this.props.huntingPlusSub === '')))) {
            renderNewPackages = true;
        }

        return (
            <div>
                <div className={classes.root}>
                    <ExpansionPanel expanded={this.props.accountMenuOpen} style={{ borderRadius: 0, borderWidth: 0, borderColor: 'white', boxShadow: 'none', }}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => { this.props.onAccountMenuOpen(!this.props.accountMenuOpen) }}
                            style={{ paddingLeft: 16, paddingRight: 16 }}
                        >
                            <Typography className={classes.heading}>
                                Abonnement
                            </Typography>
                        </ExpansionPanelSummary>

                        {(this.props.networkStatus === 'online' && showSubDetails) && <ExpansionPanelDetails style={{ paddingLeft: 16, paddingRight: 16, flexDirection: 'column' }}>
                            {renderNewPackages && this.newPackages()}
                            {this.currentPackages(renderNewPackages)}
                        </ExpansionPanelDetails>}

                        {!showSubDetails && <ExpansionPanelDetails style={{ paddingLeft: 16, paddingRight: 16, flexDirection: 'column' }}>
                            <Typography
                                variant="caption"
                                className={classes.textField}
                                style={{ color: '#717171' }}
                            >
                                Abonnement styres af den tilknyttede organisation.
                            </Typography >
                        </ExpansionPanelDetails>}

                        {this.props.networkStatus === 'offline' && <ExpansionPanelDetails style={{ paddingLeft: 16, paddingRight: 16, flexDirection: 'column' }}>
                            <Typography
                                variant="caption"
                                className={classes.textField}
                                style={{ color: '#717171' }}
                            >
                                Abonnementsdetaljer kan ikke hentes. Ingen forbindelse til internet.
                            </Typography >
                        </ExpansionPanelDetails>}
                    </ExpansionPanel>
                </div>

                {/*Buy modal */}
                <Dialog open={this.state.buyModalOpen}>
                    <DialogTitle style={{ marginBottom: 0, paddingBottom: 0, }}>
                        <div style={{ marginBottom: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'top', justifyContent: 'space-between' }}>
                            {this.props.config.buyTitle}
                            <IconButton aria-label="Close" size="medium" style={{ marginTop: -4, marginRight: -12 }} onClick={() => this.setState({ buyModalOpen: false })}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Typography style={{ whiteSpace: 'pre-line', marginBottom: 16 }}>
                            {this.props.config.buyAbstract}
                        </Typography>
                        <Typography style={{ whiteSpace: 'pre-line', marginBottom: 16, fontWeight: 500 }}>
                            {"Din opgradering vil koste: " + this.commify(this.state.totalPrice) + ",-" + (this.state.displayPackageType === 'year' ? " pr. år" : " pr. måned") + this.state.discountLabel}
                        </Typography>
                        <Typography style={{ whiteSpace: 'pre-line', marginBottom: 16 }}>
                            {this.props.config.buyPaymentText}
                        </Typography>
                        {this.props.config.buyExplanationTitle && <TipMenu
                            title={this.props.config.buyExplanationTitle}
                            content={this.props.config.buyExplanationText}
                        />}

                    </DialogContent>

                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Annuller'
                                onPress={() => this.setState({ buyModalOpen: false })}
                                variant='text'
                                discrete={true}
                            />
                            <OutlinedButton
                                buttonLabel='Køb'
                                buttonIcon={<IconChevronRight />}
                                iconLeft={false}
                                onPress={() => { this.onAddPackageSub(); this.setState({ buyModalOpen: false }) }}
                                color='primary'
                            />
                        </div>
                    </DialogActions>
                </Dialog>

                {/* Cancel subscription bbox */}
                <Dialog open={this.state.showCancelSubscriptionBox}>
                    <DialogTitle style={{ marginBottom: 0, paddingBottom: 0, }}>
                        <div style={{ marginBottom: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'top', justifyContent: 'space-between' }}>
                            Opsig pakke
                            <IconButton aria-label="Close" size="medium" style={{ marginTop: -4, marginRight: -12 }} onClick={() => this.setState({ showCancelSubscriptionBox: false })}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                            {this.state.cancelText}
                        </DialogContentText>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Grund til opsigelse</FormLabel>

                            <RadioGroup aria-label="opsig" name="opsig_abbo" value={this.state.reasonCancel} onChange={(event) => this.setState({ reasonCancel: event.target.value })}>
                                <FormControlLabel value="Jeg bruger det ikke nok" control={<Radio color="primary" />} label="Jeg bruger det ikke nok" />
                                <FormControlLabel value="Det levede ikke op til mine forventninger" control={<Radio color="primary" />} label="Det levede ikke op til mine forventninger" />
                                <FormControlLabel value="Jeg har fundet et andet og bedre alternativ" control={<Radio color="primary" />} label="Jeg har fundet et andet og bedre alternativ" />
                                <FormControlLabel value="Jeg syntes at det er for dyrt" control={<Radio color="primary" />} label="Jeg syntes at det er for dyrt" />
                                {this.props.currentAuthUser.userRole === 'forester' && <FormControlLabel value="Min kunder ønsker ikke skovkort" control={<Radio color="primary" />} label="Min kunder ønsker ikke skovkort" />}
                                {this.props.currentAuthUser.userRole === 'forester' && <FormControlLabel value="Jeg har ikke længere min forretning" control={<Radio color="primary" />} label="Jeg har ikke længere min forretning" />}
                                {this.props.currentAuthUser.userRole === 'forestOwner' && <FormControlLabel value="Jeg har ikke længere min skov" control={<Radio color="primary" />} label="Jeg har ikke længere min skov" />}
                                <FormControlLabel value="Andet" control={<Radio color="primary" />} label="Andet" />
                            </RadioGroup>

                            {this.state.reasonCancel === "Andet" && <TextField
                                label="Kommentar"
                                multiline
                                className={classes.textField}
                                value={this.state.comment}
                                onChange={event => this.setState({ comment: event.target.value })}
                                disabled={this.state.reasonCancel !== "Andet" ? true : false}
                                required={this.state.reasonCancel === "Andet" ? true : false}
                            />}
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Annuller'
                                onPress={() => this.setState({ showCancelSubscriptionBox: false })}
                                variant='text'
                                discrete={true}
                            />
                            <OutlinedButton
                                buttonLabel='Opsig abonnement'
                                buttonIcon={<IconChevronRight />}
                                iconLeft={false}
                                onPress={() => this.cancelSubButtonHandler(this.state.subToBeCancledUncancled)}
                                color='primary'
                                disabled={(this.state.reasonCancel === "" ? true : false) || (this.state.reasonCancel === "Andet" && this.state.comment === "" ? true : false)}
                            />
                        </div>
                    </DialogActions>
                </Dialog>

                {/* Uncancel subscription bbox */}
                <Dialog open={this.state.showUncancelSubscriptionBox}>
                    <DialogTitle style={{ marginBottom: 0, paddingBottom: 0, }}>
                        <div style={{ marginBottom: 0, paddingBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'top', justifyContent: 'space-between' }}>
                            Annuller opsigelse af pakke

                            <IconButton aria-label="Close" size="medium" style={{ marginTop: -4, marginRight: -12 }} onClick={() => this.setState({ showUncancelSubscriptionBox: false })}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                            {this.state.uncancelText}
                        </DialogContentText>

                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Grund til annullering af opsigelse</FormLabel>

                            <RadioGroup aria-label="opsig" name="opsig_abbo" value={this.state.reasonCancel} onChange={(event) => this.setState({ reasonCancel: event.target.value })}>
                                <FormControlLabel value="Jeg havde alligevel brug for pakken" control={<Radio color="primary" />} label="Jeg havde alligevel brug for pakken" />
                                <FormControlLabel value="Den seneste opdatering var lige det der manglede" control={<Radio color="primary" />} label="Den seneste opdatering var lige det der manglede" />
                                <FormControlLabel value="Andet" control={<Radio color="primary" />} label="Andet" />
                            </RadioGroup>

                            {this.state.reasonCancel === "Andet" && <TextField
                                label="Kommentar"
                                multiline
                                className={classes.textField}
                                value={this.state.comment}
                                onChange={event => this.setState({ comment: event.target.value })}
                                disabled={this.state.reasonCancel !== "Andet" ? true : false}
                                required={this.state.reasonCancel === "Andet" ? true : false}
                            />}
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Annuller'
                                onPress={() => this.setState({ showUncancelSubscriptionBox: false })}
                                variant='text'
                                discrete={true}
                            />

                            <OutlinedButton
                                buttonLabel='Genoptag abonnement'
                                buttonIcon={<IconChevronRight />}
                                iconLeft={false}
                                onPress={() => this.uncancelSubButtonHandler(this.state.subToBeCancledUncancled)}
                                color='primary'
                                disabled={(this.state.reasonCancel === "" ? true : false) || (this.state.reasonCancel === "Andet" && this.state.comment === "" ? true : false)}
                            />
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        config: state.appConfig.mainMenu.subscription,
        currentAuthUser: state.user.currentAuthUser,
        networkStatus: state.layout.networkStatus,
        plan: state.account.plan,
        reepayState: state.account.reepayState,
        planList: state.account.planList,
        loadingSub: state.account.loading,
        paymentDetails: state.account.paymentDetails,
        inTrial: state.account.inTrial,
        trialSub: state.account.trialSub,
        natureActive: state.account.natureActive,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        huntingActive: state.account.huntingActive,
        huntingPlusActive: state.account.huntingPlusActive,
        ownerUpToFiveForestsActive: state.account.ownerUpToFiveForestsActive,
        unlimitedUserAccessActive: state.account.unlimitedUserAccessActive,
        natureSub: state.account.natureSub,
        operationSub: state.account.operationSub,
        operationPlusSub: state.account.operationPlusSub,
        huntingSub: state.account.huntingSub,
        huntingPlusSub: state.account.huntingPlusSub,
        ownerUpToFiveForestsSub: state.account.ownerUpToFiveForestsSub,
        unlimitedUserAccessSub: state.account.unlimitedUserAccessSub,
        discountCodes: state.account.discountCodesList,
        fbDiscountCodes: state.account.fbDiscountCodesList,
        accountMenuOpen: state.layout.accountMenuOpen,
        org: state.org.org,
        customerList: state.customer.customerList,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onCancelSubscription: (sub, user) => dispatch(actions.cancelSubscription(sub, user)),
        onUncancelSubscription: (sub, user) => dispatch(actions.uncancelSubscription(sub, user)),
        onFetchDiscountCodesList: () => dispatch(actions.fetchDiscountCodesList()),
        onFetchFbDiscountCodesList: () => dispatch(actions.fetchFbDiscountCodesList()),
        onCreateSubscription: (packageType, interval, discountObj, cardId, thirdParty) => dispatch(actions.createSubscription(packageType, interval, discountObj, cardId, thirdParty)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onSendEmail: (type, userId, customerEmail, content) => dispatch(actions.sendEmail(type, userId, customerEmail, content)),
        onAccountMenuOpen: (state) => dispatch(actions.accountMenuOpen(state)),
        onRemoveUserfromMailChimpAudienceList: (listId, mailChimpUserId) => dispatch(actions.removeUserfromMailChimpAudienceList(listId, mailChimpUserId)),
        onAddTagToUserfromMailChimpAudienceList: (listId, email, tag) => dispatch(actions.addTagToUserfromMailChimpAudienceList(listId, email, tag)),
        onSetKwIntegrationActive: (state) => dispatch(actions.setKwIntegrationActive(state)),
        onSetSubscriptionState: (sub, state) => dispatch(actions.setSubscriptionState(sub, state)),
        onFetchOrgUsers: (userIds) => dispatch(actions.fetchOrgUsers(userIds)),
        onSetForestHasPayedSubscription: (forestId, state) => dispatch(actions.setForestHasPayedSubscription(forestId, state)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Subscription2));