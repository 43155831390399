import React from 'react';

// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// Material UI Icons
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';

const UpgradeMenu = (props) => {
    return (
        <div style={{ backgroundColor: '#2c2d36', padding: '4px 8px', borderRadius: 5 }}>
            <Typography
                style={{ fontSize: 13, fontWeight: 500, color: 'white' }}
            >
                {props.title}
            </Typography>

            <Typography
                variant="caption"
                style={{ color: 'white' }}
            >
                {props.content}
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={props.onPress}
                    style={{ color: 'white' }}
                >
                    {props.cta}

                    <ChevronRightIcon style={{ color: 'white' }} />
                </Button>
            </div>
        </div>
    );
}

export default UpgradeMenu;