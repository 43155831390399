// Imports
import buffer from '@turf/buffer';
import moment from 'moment';
import configDataFordeleren from '../../../backend/configDataFordeleren';
import { getSpecieParamFromHeight } from './yieldTableLookup';

const speciesTable = require('./updatedSpeciesList.json');
const earcut = require('earcut');
const randomPointsOnPolygon = require('random-points-on-polygon');
const geojsonArea = require('@mapbox/geojson-area');

// Stand Assesment
// Input: polygon feature, parameters (specie, height?, diameter?,...)
// Output: height, year, diameter, tree count, ground area, form number, site index, volume
export async function standAssessment(scheme, geoJsonPolygonFeature, param) {
    // Check input and set variables accordingly
    let par = {
        species: param.species ? param.species : "", // NEEDS BETTER CHECK
        height: param.height ? (param.height !== "" ? parseFloat(param.height) : "") : "",
        lidarHeight: await param.lidarHeight,
        area: param.area ? (param.area !== "" ? parseFloat(param.area) : "") : "",
        year: param.year ? (param.year !== "" ? parseInt(param.year) : "") : "",
        diameter: param.diameter ? (param.diameter !== "" ? parseFloat(param.diameter) : "") : "",
        treeCount: param.treeCount ? (param.treeCount !== "" ? parseInt(param.treeCount) : "") : "",
        groundArea: param.groundArea ? (param.groundArea !== "" ? parseFloat(param.groundArea) : "") : "",
    }
    // Check lidar height. if null something failed the first time
    if (par.lidarHeight === 'error') {
        try {
            const lidarHeight = await getStandHeight(geoJsonPolygonFeature, param);
            par.lidarHeight = lidarHeight;
        } catch (err) {
            throw err
        }
        // const lidarHeight = await getStandHeight(geoJsonPolygonFeature, param);
        // if (lidarHeight !== 'error') {
        //     par.lidarHeight = lidarHeight;
        // } else {
        //     throw { errText: "Could not fetch lidar heights try again later" }
        // }
    }

    // Based on lidar height and specie set other parameters.
    // Find diameter [cm], age [years], treeCount [#/Ha], groundArea [m2/Ha], formNum [-] and volume [m3/Ha] from table lookup
    // Check if specie has specified yield table quality (bonitet). Default is "b2"
    let qual = 'b2';
    if ("yieldQuality" in speciesTable[par.species]) {
        qual = speciesTable[par.species].yieldQuality;
    }
    const spcPar = getSpecieParamFromHeight(scheme, par.species, par.lidarHeight.mean, qual)
    if (spcPar.warning.type !== 0) {
        console.warn("Species parameters warning", spcPar.warning)
    }
    const height = par.lidarHeight.mean;
    const diameter = spcPar.diameter;
    const year = moment().format("YYYY") - spcPar.age;
    const treeCountHa = spcPar.treeCount;
    const groundAreaHa = spcPar.groundArea;
    // Return relevant values
    return {
        height: height,
        diameter: diameter,
        year: year,
        age: spcPar.age,
        treeCountHa: treeCountHa,
        groundAreaHa: groundAreaHa,
    }

}

export async function getStandHeight(geoJsonPolygonFeature, param) {
    // Check input
    let updatedFeat = { ...geoJsonPolygonFeature }
    // TODO : Make sure it still works - ring check has been moved to convertNewFeature to geojson in utilityFunctions in the maps folder
    // Check if first and last coordinate is the same. Needed by some packages. Check outer polygon and all holes
    // for (let i = 0; i < updatedFeat.geometry.coordinates.length; i++) {
    //     const firstCoor = updatedFeat.geometry.coordinates[i][0];
    //     const lastCoor = updatedFeat.geometry.coordinates[i][updatedFeat.geometry.coordinates[i].length - 1];
    //     if (firstCoor[0] !== lastCoor[0] || firstCoor[1] !== lastCoor[1]) {
    //         updatedFeat.geometry.coordinates[i].push(firstCoor)
    //     }
    // }

    try {
        // Create points inside polygon
        const { points } = createPointsInsidePolygon(updatedFeat, param.numPoints, param.bufsize, param.pointDistType);

        // Get height of points
        const pointHeight = await getHeightOfPoints(points);

        // Sort height measurements
        const sortedHeights = sortHeights(pointHeight, { type: param.sortPointsType, truncVal: param.sortTruncVal });

        // Get mean and standard deviation of heights
        const meanStd = getHeightMeanAndStd(sortedHeights)

        // return mean and std height
        return { ...meanStd, measYear: moment().format("YYYY") }
    } catch (err) {
        // console.error("Error in fetching point heights", err)
        throw err
    }
}

// Create points
// Input: geojson polygon feature
// Output: list of points as string, "x0,y0;x1,y1;..."
export const createPointsInsidePolygon = (geoJsonPolygonFeature, numberOfPoints, bufferSize, type) => {
    // Prebuffer polygon to reduce size. We don't what the polygon edges to count.
    // Check if buffered poly comes back as undefined (caused by buffering small polygons)
    let bufSize = -(bufferSize ? bufferSize : 10);
    let bufferedPoly = null;
    for (let i = 0; i < 5; i++) {
        bufferedPoly = buffer(geoJsonPolygonFeature, (bufSize / (i + 1)) / 1000, { units: 'kilometers', steps: 1 });
        if (bufferedPoly !== undefined) {
            break;
        }
    }
    // Check one last time and remove buffer if neccessary
    if (bufferedPoly === undefined) {
        bufferedPoly = geoJsonPolygonFeature;
    }

    // Algorithm for placing points
    const numPoints = numberOfPoints ? numberOfPoints : 50;
    let points = null;
    let triPolys = null;
    if (type === 'earcut') {
        // Ear cut polygon
        const out = getPointsInTriangles(numPoints, bufferedPoly);
        points = out.points;
        triPolys = out.triPolys;
    } else {
        // Place random points inside buffered polygon
        points = randomPointsOnPolygon(numPoints, bufferedPoly)
    }

    // Return data
    return { points, bufferedPoly, triPolys };
}

// Place point according to earcut trinagles
// Input: original polygon geojson feature, triangle indexes
// Output: points
const getPointsInTriangles = (numberOfPoints, geoJsonPolygonFeature) => {
    // Triangulate the polygon using Ear cut
    let featCoor = geoJsonPolygonFeature.geometry.coordinates;
    if (geoJsonPolygonFeature.geometry.type === 'MultiPolygon') {
        featCoor = geoJsonPolygonFeature.geometry.coordinates[0];
    }
    const data = earcut.flatten(featCoor);
    const triangles = earcut(data.vertices, data.holes, data.dimensions);
    // Calcualte total area of polygon
    const totArea = geojsonArea.geometry(geoJsonPolygonFeature.geometry);

    // Find coordinates according to triangle indexing for each triangle
    // Preprocess coordinates to fit with earcut format
    let coords = [];
    for (let i = 0; i < data.vertices.length; i += 2) {
        coords.push([data.vertices[i], data.vertices[i + 1]]);
    }

    let triPolys = [];
    for (let i = 0; i < triangles.length; i += 3) {
        const triCoords = [coords[triangles[i]], coords[triangles[i + 1]], coords[triangles[i + 2]]];
        const geometry = {
            "type": "Polygon",
            "coordinates": [triCoords]
        }
        const triFeat = {
            "type": "Feature",
            "geometry": geometry,
            "properties": {
                "area": geojsonArea.geometry(geometry),
                "areaPercent": 100 * (geojsonArea.geometry(geometry) / totArea)
            }
        }
        // if (triFeat.properties.areaPercent > 1) {
        triPolys.push(triFeat)
        // }
    }

    // Sort polys from largest to smallest
    triPolys = triPolys.sort((a, b) => b.properties.areaPercent - a.properties.areaPercent);

    // distribute points inside triangles
    let points = [];
    triPolys.forEach(tri => {
        // Calculate number of points
        const numPoints = Math.floor(numberOfPoints * (tri.properties.areaPercent / 100));
        // Place point inside triangle
        if (numPoints > 0) {
            const p = randomPointsOnPolygon(numPoints, tri);
            points.push(p)
        }
    })
    points = points.flat();

    return { points, triPolys };
}


// Get height of points as surface height minus terrain height. REST call to kortforsyningen
// Input: list of points as a an array of geojson point features
// Output: list of heights as array, [h1, h2, ...]
async function getHeightOfPoints(pointList) {
    // Preprocess list of geojson features to form string of points
    // let pointStr = "";
    // let N = pointList.length > 50 ? 50 : pointList.length;
    // for (let i = 0; i < N; i++) {
    //     pointStr = pointStr + pointList[i].geometry.coordinates[0].toString() + ',' + pointList[i].geometry.coordinates[1].toString() + ';';
    // }
    // Create points search string
    let pointStr = "";
    let N = pointList.length > 50 ? 50 : pointList.length;
    for (let i = 0; i < N; i++) {
        if ( i === N-1) {
            pointStr = pointStr + 'POINT(' + pointList[i].geometry.coordinates[0].toString() + ' ' + pointList[i].geometry.coordinates[1].toString() + ')';
        } else {
            pointStr = pointStr + 'POINT(' + pointList[i].geometry.coordinates[0].toString() + ' ' + pointList[i].geometry.coordinates[1].toString() + ')|';
        }
    }

    // Get surface height of points
    const heightsSurf = await getHeightsDF(pointStr,'dsm','EPSG:4326');
    // Get terrain height of points
    const heightsTerrain = await getHeightsDF(pointStr,'dtm','EPSG:4326');
    // Check if service returns null on all elements (Error at Datafordeleren)
    if (heightsSurf.every(element => element === null) || heightsTerrain.every(element => element === null)) {
        throw { errorCode: 2, errText: "Service is down!"}
    }
    // Substract terrain height from surface height
    let treeHeights = [];
    let maxHeight = 0;
    for (let i = 0; i < heightsSurf.length; i++) {
        // Calculate height of object
        if (heightsSurf[i] !== "-1.00000" && heightsTerrain[i] !== "-1.00000") {
            treeHeights.push(parseFloat(heightsSurf[i]) - parseFloat(heightsTerrain[i]));
            if (parseFloat(heightsSurf[i]) - parseFloat(heightsTerrain[i]) > maxHeight) {
                maxHeight = parseFloat(heightsSurf[i]) - parseFloat(heightsTerrain[i]);
            }
        }
    }
    // Return list of heights
    if (treeHeights.length < 10) {
        const errorMessage = { errorCode: 0, errText: "Not enough points, response from kortforsyningen failing", points: treeHeights };
        throw errorMessage
    } else if (maxHeight < 1.3) {
        const errorMessage = { errorCode: 1, errText: "Maximum recorded height is below minimum height of 1.3 m. The maximum measured height is: ", maxHeight };
        throw errorMessage
    } else {
        return treeHeights;
    }
}

// Get mean height and standard deviation of polygon points
// Input: list of heights as array, [h1, h2, ...]
// Output: mean height and standard deviation
export const getHeightMeanAndStd = (heightList) => {
    // Calculate mean
    const mean = heightList.reduce((a, b) => a + b) / heightList.length;
    // Calculate standard deviation
    const std = Math.sqrt(
        heightList.reduce((acc, val) => acc.concat(Math.pow(val - mean, 2)), []).reduce((acc, val) => acc + val, 0) /
        (heightList.length - 1)
    );
    return { mean, std }
}

export const sortHeights = (heightsList, param) => {
    let newHeightsList = [];
    // Chose based on type
    if (param.type === "hdom") {
        // Set truncation value as percentage from maximum value
        const truncVal = param.truncVal ? param.truncVal : 0.2;
        const highToLow = heightsList.sort((a, b) => b - a);
        const maxHeight = highToLow[0];
        highToLow.forEach(height => {
            if (height > maxHeight * (1 - truncVal)) {
                newHeightsList.push(height);
            }
        })
    } else {
        // No filter
        newHeightsList = [...heightsList];
    }

    return newHeightsList;
}

// Fetch the heights via kortforsyningen REST api
// async function getHeights(pointsStr, token, model, crf) {
//     // request options
//     const requestOptions = {
//         method: 'GET',
//         redirect: 'follow',
//         headers: {
//             "Access-Control-Allow-Origin": '*',
//         }
//     };
//     // Create query for url string
//     const params = {
//         //servicename: 'RestGeokeys_v2',
//         token: token,
//         elevationmodel: model,
//         georef: crf,
//         geop: pointsStr,
//         method: 'geopmulti'
//     }

//     let query = Object.keys(params)
//         .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
//         .join('&');
//     // url
//     const url = 'https://api.dataforsyningen.dk/RestGeokeys_v2?' + query;
//     // fetch heights
//     const response = await fetch(url, requestOptions);
//     const respJson = await response.json();
//     const heights = respJson.geopmulti.map(point => {
//         const splitStr = point.geop.split(',');
//         return splitStr[2];
//     })
//     return heights;
// }

// Fetch the heights via kortforsyningen REST api
async function getHeightsDF(pointStr, model, crf) {
    // Check input

    // // Create points search string
    // let pointStr = "";
    // let N = pointsArray.length > 50 ? 50 : pointsArray.length;
    // for (let i = 0; i < N; i++) {
    //     if ( i === N-1) {
    //         pointStr = pointStr + 'POINT('+pointsArray[i].geometry.coordinates[0].toString() + ' ' + pointsArray[i].geometry.coordinates[1].toString() + ')';
    //     } else {
    //         pointStr = pointStr + 'POINT('+pointsArray[i].geometry.coordinates[0].toString() + ' ' + pointsArray[i].geometry.coordinates[1].toString() + ')|';
    //     }
    // }
    // request options
    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
    };
    // Create query for url string
    const params = {
        username: configDataFordeleren.username,
        password: configDataFordeleren.password,
        format: 'json',
        geop: pointStr,
        georef: crf,
        elevationmodel: model
    }
    let query = Object.keys(params)
        // .map(k => k + '=' + params[k]) // Now this fails... 2022-01-05-15:00 (Note: seems to be datafordeleren that is down)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])) // Suddenly began to fail because of encodeURIComponent 2022-01-05-10:00
        .join('&');
        
    // url
    const url = 'https://services.datafordeler.dk/DHMTerraen/DHMKoter/1.0.0/GEOREST/HentKoter?' + query;
    // fetch heights
    const response = await fetch(url, requestOptions);
    const respJson = await response.json();
    const heights = respJson.HentKoterRespons.data.map(point => {
        return point.kote;
    })
    return heights;
}