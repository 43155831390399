import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Material UI icons
import GoBackIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

// Moment
import moment from 'moment';

// Components
import ChangeSaveButton from '../UI/Buttons/ChangeSaveButton';
import OutlinedButton from '../UI/Buttons/OutlinedButton';
import { getTaskGroupList } from '../TaskPlanning/Utility/tasksUtils';
import * as actions from '../../store/actions/index';

const styles = theme => ({
    root: {
        textAlign: 'left',
    },
    textField: {
        width: '100%',
    },
    card: {
        position: 'relative',
        margin: 'auto',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    chip: {
        margin: 2,
    },
});

export class Person extends React.Component {
    constructor(props) {
        super(props);
        // Fields are pre-loaded and note is made editable if it
        // is a new one - e.g. accessed without props
        this.state = {
            open: false,
            change: false,
            name: ("name" in this.props) ? this.props.name : '',
            userId: ("id" in this.props) ? this.props.id : null,
            orgAccess: ("orgAccess" in this.props) ? (this.props.orgAccess === 'granted' ? true : false) : true,
            userTags: ("userTags" in this.props) ? this.props.userTags : [],
            saveLoading: false,
            deleteLoading: false,
            disabled: false,
        };
    }

    componentDidUpdate() {
        // Save navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.successUser) {
            this.setState({
                change: false,
                saveLoading: false,
                disabled: false
            })

            // Route back
            let path = "/"
            if (this.props.location.state.prevPage === "/People") {
                path = "/People"
            }
            this.props.history.replace({
                pathname: path,
                state: {
                    prevPage: this.props.location.state.prevPage,
                }
            });
        }
    }

    componentWillUnmount() {
        // Show snackbar if customer is saved
        if (this.props.successUser) {
            this.props.onEnqueueSnackbar({
                message: 'Ansat er nu gemt!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            // Set reducer to initial state
            this.props.onUnmountSaveUser();
        }
    }

    goBackClickHandler = () => {
        // Choose which route to go back to
        let path = "/"
        if (this.props.location.state.prevPage === "/People") {
            path = "/People"
        }
        this.props.history.replace({
            pathname: path,
            state: {
                prevPage: this.props.location.state.prevPage,
            }
        });
    }

    changeSaveButton = () => {
        const { orgAccess, userTags, name, userId, change } = this.state;

        // Upload customer to cloud 
        if (change) {
            // Set submit state
            this.setState({
                submit: true
            })

            // Check if customer is incomplete
            if (name !== '') {
                this.setState({
                    saveLoading: true,
                    disabled: true,
                })

                // Upload action
                const userData = {
                    name: name,
                    orgAccess: orgAccess ? 'granted' : 'denied',
                    userTags,
                    date: moment().format(),
                }

                this.props.onUpdateUserData(userData, userId);

                // Send event to Analytics
                ReactGA.event({
                    category: 'People',
                    action: 'Updated person',
                    label: `User: ${this.props.currentAuthUser.name}`
                });

                // Switch states
                this.setState({
                    submit: false,
                })
            }

        } else {
            this.setState({
                change: true
            })
        }
    };

    personChangeSave() {
        // When customer is in editable state
        if (this.state.change) {
            return (
                <div style={{ display: 'flex' }}>
                    <OutlinedButton
                        buttonLabel='Annuller'
                        onPress={this.goBackClickHandler}
                        variant='text'
                        discrete={true}
                    />

                    <ChangeSaveButton
                        change={false}
                        onPress={this.changeSaveButton.bind(this)}
                        saveLoading={this.state.saveLoading}
                        disabled={this.state.disabled}
                    />
                </div>
            );
        }

        // When customer is in non-editable state
        return (
            <ChangeSaveButton
                change={true}
                onPress={this.changeSaveButton.bind(this)}
                saveLoading={this.state.saveLoading}
                disabled={this.state.disabled}
            />
        );
    }

    handleChangeUserTags = (event) => {
        this.setState({ userTags: event.target.value, unSavedData: true });
    }

    handleDeleteChip = (value) => {
        // Check if person is current auth user and chip is Skovfoged
        if (this.props.currentAuthUser.id === this.state.userId && value === 'Skovfoged') {
            this.props.onEnqueueSnackbar({
                message: 'Det er ikke muligt at fravælge dig selv som skovfoged!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                },
            });
            return;
        }
        // Create new task list
        let newUserTags = [...this.state.userTags];
        newUserTags = newUserTags.filter(el => el !== value);
        this.setState({ userTags: newUserTags, unSavedData: true });
    }

    handleChangeOrgAccess = () => {
        this.setState({ orgAccess: !this.state.orgAccess })
    }

    title() {
        // When note is in editable state 
        if (this.state.change) {
            return (
                <div>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
                    >
                        <IconButton
                            onClick={this.goBackClickHandler}
                            style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                        >
                            <GoBackIcon />
                        </IconButton>

                        <Typography
                            variant="h5"
                            gutterBottom
                        >
                            Ansat
                        </Typography >
                    </div>
                </div>
            );
        }

        // When note is in non-editable state
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
            >
                <IconButton
                    onClick={this.goBackClickHandler}
                    style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                >
                    <GoBackIcon />
                </IconButton>

                <Typography
                    variant="h5"
                    gutterBottom
                >
                    {this.state.name}
                </Typography >
            </div>
        );
    };

    textField(key, label, content, errorEnabled, required, changeDisabled) {
        const { classes } = this.props;

        // When profile is in editable state
        if (this.state.change && !changeDisabled) {
            return (
                <TextField
                    id={label}
                    label={label}
                    multiline
                    className={classes.textField}
                    value={content}
                    onChange={event => this.setState({ [key]: event.target.value })}
                    error={this.state[key] === "" && this.state.submit && errorEnabled}
                    required={required === 'required' ? true : false}
                    margin="normal"
                />
            );
        }

        // When profile is in non-editable state
        if (key !== 'name') {
            if (content !== '') {
                return (
                    <div style={{marginTop: (this.state.change && changeDisabled) ? 18 : null}}>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            {label}
                        </Typography >
                        <div>
                            <Typography
                                variant="body1"
                                gutterBottom
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {content}
                            </Typography >
                        </div>
                    </div>
                );
            }
        } 
    };

    orgAccess = () => {
        // In editable state
        if (this.state.change) {
            // Check if person is current user and disable accordingly
            let disable = false;
            if (this.props.currentAuthUser.id === this.state.userId) {
                disable = true;
            }
            if (this.props.networkStatus === 'offline') {
                disable = true;
            }
            return (
                <div style={{ marginTop: 24 }}>
                    <InputLabel id="mutiple-chip-usertype-label">Adgang</InputLabel>

                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                style={{ marginLeft: 8 }}
                                checked={this.state.orgAccess}
                                onClick={this.handleChangeOrgAccess}
                                disabled={disable}
                            />
                        }
                        label="Tildel adgang"
                    />
                </div>
            )
        }

        // In non-editable state
        if (this.state.orgAccess) {
            return this.textField('orgAccess', 'Adgang', 'Godkendt')
        } else {
            return this.textField('orgAccess', 'Adgang', 'Afventer godkendelse')
        }
    }

    userTags = (userTags) => {
        const { classes } = this.props;
        const groupList = getTaskGroupList();
        if (this.state.change) {
            return (
                <div style={{ marginTop: 4 }}>
                    <FormControl margin="normal" fullWidth>
                        <InputLabel id="mutiple-chip-usertype-label">Gruppe(r)</InputLabel>

                        <Select
                            labelid="mutiple-chip-usertype-label"
                            id="mutiple-chip-usertype"
                            multiple
                            value={userTags}
                            onChange={this.handleChangeUserTags}
                            input={<Input id="select-multiple-chip-usertype" />}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map((value, index) => (
                                        <Chip
                                            key={index}
                                            label={value}
                                            className={classes.chip}
                                            onMouseDown={(event) => { event.stopPropagation(); }}
                                            onDelete={() => this.handleDeleteChip(value)}
                                        />
                                    ))}
                                </div>
                            )}
                        >
                            {groupList.map(type => (
                                <MenuItem key={type[0]} value={type[0]}>
                                    {type[0]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )
        }
        if (userTags.length > 0) {
            return this.textField('group', 'Gruppe(r)', userTags.join(', '))
        } else {
            return this.textField('group', 'Gruppe(r)', 'Tilhører endnu ikke en gruppe')
        }
    }

    renderContent() {
        const { name, userTags } = this.state;
        let errorEnabled = true;

        return (
            <div>
                {this.title()}
                {this.textField('name', 'Navn', name, errorEnabled, 'required')}
                {this.textField('email', 'Email', this.props.email, '', false, true)}
                {this.orgAccess()}
                {this.userTags(userTags)}
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        {this.renderContent()}
                    </CardContent>

                    <CardActions>
                        <div style={{ display: 'flex' }}>
                            {this.personChangeSave()}
                        </div>
                    </CardActions>
                </Card>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        networkStatus: state.layout.networkStatus,
        currentAuthUser: state.user.currentAuthUser,
        successUser: state.user.successUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (data, id) => dispatch(actions.updateUserData(data, id)),
        onUnmountSaveUser: () => dispatch(actions.unmountSaveUser()),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
    };
}

Person.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Person)));