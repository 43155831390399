export const updateObject = (oldObject, updatedProperties) => {
   return {
      ...oldObject,
      ...updatedProperties
   };
};

export function replaceUndefinedWithEmptyString(obj) {
   for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === undefined) {
         obj[key] = '';
      }
   }
}