import React from 'react';
import { connect } from 'react-redux';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';

// Components
import ExportForestData from './ExportForestData';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    extendedIconAdd: {
        marginRight: theme.spacing.unit,
    },
    formControl: {
        margin: 12,
        width: 500,
    },
    textField: {
        width: '100%',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: '#5EBD3E',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

class ExportMain extends React.Component {
    state = {
        integrationMenuOpen: false,
    };

    componentDidMount() {
    }

    componentDidUpdate() {    
    }

    render() {
        const { classes } = this.props;
        const disabled = this.props.config === 'disabled';
        return (
            <div>
                <div className={classes.root}>
                    <ExpansionPanel disabled={disabled} expanded={this.state.integrationMenuOpen} style={{ borderRadius: 0, borderWidth: 0, borderColor: 'white', boxShadow: 'none', backgroundColor: disabled ? '#F5F5F5' : null}}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => { this.setState({integrationMenuOpen: !this.state.integrationMenuOpen}) }}
                            style={{ paddingLeft: 16, paddingRight: 16 }}
                        >
                            <Typography className={classes.heading}>
                                Eksporter data
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails style={{ paddingLeft: 16, paddingRight: 16, flexDirection: 'column' }}>
                            <ExportForestData />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        config: state.appConfig.mainMenu.export,
        currentAuthUser: state.user.currentAuthUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExportMain));