import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Point from '../components/Point/NewPoint';

class overviewMapPoint extends Component {
    render() {
        const img = this.props.location.state.images ? this.props.location.state.images : [];
        const userUID = this.props.location.state.userUID !== undefined ? this.props.location.state.userUID : '';
        const radius = this.props.location.state.radius ? this.props.location.state.radius : 0;
        const histList = this.props.location.state.historyList ? this.props.location.state.historyList : {};
        const categories = this.props.location.state.categories ? this.props.location.state.categories : [];
        const registrations = this.props.location.state.registrations ? this.props.location.state.registrations : [];
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <Point
                            images={img}
                            userUID={userUID}
                            title={this.props.location.state.title}
                            date={this.props.location.state.date}
                            text={this.props.location.state.text}
                            from={this.props.location.state.from}
                            id={this.props.location.state.id}
                            latlng={this.props.location.state.latlng}
                            markerId={this.props.location.state.markerId}
                            forestId={this.props.location.state.forestId}
                            planId={this.props.location.state.planId}
                            litraNr={this.props.location.state.litraNr}
                            pointType={this.props.location.state.pointType}
                            year={this.props.location.state.year}
                            radius={radius}
                            environment={this.props.location.state.environment}
                            historyList={histList}
                            categories={categories}
                            registrations={registrations}
                        />
                    </div>
                } />
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(overviewMapPoint));