import { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

class Notifier extends Component {
    displayed = [];

    storeDisplayed = (id) => {
        this.displayed = [...this.displayed, id];
    };

    shouldComponentUpdate({ notifications: newSnacks = [] }) {
        if (!newSnacks.length) {
            this.displayed = [];
            return false;
        }

        const { notifications: currentSnacks } = this.props;
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i += 1) {
            const newSnack = newSnacks[i];

            if (notExists) continue;
            notExists = notExists || !currentSnacks.filter(({ key }) => newSnack.key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [] } = this.props;
        
        notifications.forEach(({ key, message, options = {} }) => {
            // Do nothing if snackbar is already displayed
            if (this.displayed.includes(key)) return;
            
            // Display snackbar using notistack
            this.props.enqueueSnackbar(message, {
                ...options,
            });

            // Keep track of snackbars that we've displayed
            this.storeDisplayed(key);
        });
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications.notifications,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    };
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Notifier));
