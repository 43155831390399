import L from "leaflet";
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
// import 'leaflet-easyprint';
import 'leaflet-wfst';          // TEST
import 'leaflet-editable/src/Leaflet.Editable.js';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet.pm/dist/leaflet.pm.css';
import 'leaflet.pm/dist/leaflet.pm.min.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geometryutil';
import 'leaflet.browser.print/dist/leaflet.browser.print.min.js';
import moment from 'moment';
import ReactGA from 'react-ga';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import polylabel from 'polylabel';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import 'leaflet.pattern/dist/leaflet.pattern-src.js';

// Turf
import buffer from '@turf/buffer';
import getLineLength from '@turf/length';

// Material UI components
import Card from '@material-ui/core/Card';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Typography from '@material-ui/core/Typography';

// Material UI icons
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/AddRounded';
import CreateIcon from '@material-ui/icons/CreateRounded';
import ListIcon from '@material-ui/icons/FormatListBulletedRounded';
import RemoveIcon from '@material-ui/icons/RemoveRounded';
import SchoolIcon from '@material-ui/icons/SchoolRounded';
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import NearMeIcon from '@material-ui/icons/NearMeRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/SearchRounded';
import LayersIcon from '@material-ui/icons/LayersRounded';
import NaturePeopleIcon from '@material-ui/icons/NaturePeopleRounded';
import MapIcon from '@material-ui/icons/MapRounded';
import StraightenIcon from '@material-ui/icons/StraightenRounded';
import GestureIcon from '@material-ui/icons/GestureRounded';
import MoreIcon from '@material-ui/icons/AppsRounded';
import AssignmentIcon from '@material-ui/icons/EventAvailableRounded';

// Components
import DialogBox from '../components/Onboarding/DialogBox';
import AddressSearchBox from '../components/UI/Box/AddressSearchBox';
import Legend from '../components/UI/Box/Legend';
import MainMenu from '../components/UI/Menus/MainMenu/MainMenu';
import MapPrintMenu from '../components/UI/Menus/MapPrintMenu/MapPrintMenu';
import MapSelectorMenu from '../components/UI/Menus/MapSelectorMenu/MapSelectorMenu';
import InventoryList from '../components/Inventory/InventoryList';
import BiotopePlanList from '../components/Inventory/BiotopePlanList';
import KanbanBoard from '../components/TaskPlanning/KanbanBoard';
import Onboarding from '../components/Onboarding/Onboarding';
import Profile from '../components/Profile/Profile';
import NotSyncedModal from "../components/UI/Modal/NotSyncedModal";
import MapDrawingMenu from '../components/UI/Menus/MapDrawingMenu/MapDrawingMenu';
import MapMeasurementMenu from '../components/UI/Menus/MapMeasurementMenu/MapMeasurementMenu';
import MapLayersMenu from '../components/UI/Menus/MapLayersMenu/MapLayersMenu';
import MapCustomersMenu from '../components/UI/Menus/MapCustomersMenu/MapCustomersMenu';
import MapBiotopePlansMenu from '../components/UI/Menus/MapBiotopePlansMenu/MapBiotopePlansMenu';
import * as actions from '../store/actions/index';

import './Utility/leaflet-corridor';
import './Utility/leafletExtensions/LabelsMarker';
import 'leaflet-measure-path';
import 'leaflet.measure';
import {
   findMinMax,
   distPointToBox,
   lineIntersectsPoly,
   convertNewCellToGeoJson,
   LightenDarkenColor,
   scaleFactor,
   placeLabelInsidePoly
} from './Utility/UtilityFunctions';
import {
   createLayer,
   resetLayer,
   toggleLayerVisibility,
   mapFeatureToLayer,
   addNewFeatureToLayer,
   addFeatureToLayer,
   leafletFeatureClickHandler,
   resetFeatureStyle
} from "./Utility/featureFunctions";
import { printHtmlToPdf, printLabelParam } from './Utility/printMapFunctions'
import { handlePosData } from './Utility/LocationTools';
// import * as Layers from './Layer/LayerCollection';
import * as mapIcons from './Utility/mapIcons';
import * as mapLayers from './Utility/mapLayers';

// Other utilities
const species = require('../components/Inventory/Utilities/updatedSpeciesList.json');
const _ = require("underscore");
let geojsonArea = require('@mapbox/geojson-area');

// For address search
const provider = new OpenStreetMapProvider();

const style = {
   position: 'absolute',
   right: '0px',
   left: '0px',
   top: '0px',
   bottom: '0px',
   zIndex: 0,
   backgroundColor: '#fafafa',
   //overflow: 'hidden',
};

const styles = theme => ({
   fabSpeeddial: {
      backgroundColor: 'white',
      '&:hover': {
         backgroundColor: 'white',
      },
   },
   fabTop: {
      zIndex: 1,
      position: 'absolute',
      right: 420 - 410 + 67,
      top: 10,
   },
   fabTopMoveIn: {
      transform: 'translate3d(-343px, 0, 0)',
      transition: theme.transitions.create('transform', {
         duration: theme.transitions.duration.enteringScreen,
         easing: theme.transitions.easing.easeOut,
      }),
   },
   fabMoveOut: {
      transform: 'translate3d(0, 0, 0)',
      transition: theme.transitions.create('transform', {
         duration: theme.transitions.duration.leavingScreen,
         easing: theme.transitions.easing.sharp,
      }),
   },
   buttonProgress: {
      color: green[500]
   },
   speeddialButtonProgress: {
      color: green[500]
   },
   speedDialActionButtonsDisableBG: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: 20,
      backgroundColor: '#F5F5F5'
   },
});

///////////////////////////////////////////
// Parameters
///////////////////////////////////////////
const isMobile = 760;
const isTablet = 1080;
const polygonNotSelectedColor = '#5a5a5a';
const polygonSelectedDashArray = '1,0';
const polygonNotSelectedDashArray = '5,5';
const huntingPolygonSelectedFillOpacity = 0.0;
const huntingPolygonNotSelectedFillOpacity = 0.6;
const huntingSaatLineWeight = 3;
const debounceTimeLimit = 200;
const lineSelectedOpacity = 1.0;
const lineNotSelectedOpacity = 1.0;
const cellLabelsZoomLevel = 15;
const cellLabelsZoomLevelSpcYear = 16;
const notesLabelsZoomLevel = 12;
const linePolyPadding = 0.00045;
const findPosThreshold = 0.00004;
const layerZidxOffset = 300;    // Used to control the z-index of drawn items such as polygons and markers
const printPaperSize = "A1";    // Paper size when printing map (A1)
const minFontSize = 6;
const maxFontSize = 30;
const minLineWeight = 1;
const maxLineWeight = 10;

// Print legends
const beskyttetNaturtyper = [{ id: 'beskyttetNaturtyper', name: 'Ingen', color: '#E1E1E1', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Eng', color: '#3DB501', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Fredet områder', color: '#52D3FF', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Hede', color: '#A900E6', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Mose', color: '#A93000', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Overdrev', color: '#FFA900', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Strandeng', color: '#74E1FF', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Sø', color: '#005BE6', type: 'area' }, { id: 'beskyttetNaturtyper', name: 'Sten og jorddiger', color: '#E69A00', type: 'line' }, { id: 'beskyttetNaturtyper', name: 'Vandløb', color: '#00C5FF', type: 'dotted-line-wide' }];
const natura2000 = [{ id: 'natura2000', name: 'NATURA 2000 Fuglebeskyttelse', color: '#AE70D1', type: '0deglines' }, { id: 'natura2000', name: 'NATURA 2000 Habitatområder', color: '#37A800', type: 'diagonalX' }, { id: 'natura2000', name: 'Ramsar områder', color: '#FFA900', type: 'diagonalX' }];
const fredskov = [{ id: 'fredskov', name: 'Fredskov', color: '#7DBE52', type: 'area' }];
const ejerlav = [{ id: 'ejerlav', name: 'Ejerlav', color: '#85537C', type: 'line' }];
const matrikel = [{ id: 'matrikel', name: 'Matrikel', color: '#F3301D', type: 'line' }];
const markblokke = [{ id: 'markblokke', name: 'Markblokke', color: '#E0F552', type: 'area' }];
const fortidsminder = [{ id: 'fortidsminder', name: 'Fortidsminder', color: '#B71919', type: 'area' }];
const skovrejsning = [{ id: 'skovrejsning', name: 'Ønsket skovrejsningsområde', color: '#78FA68', type: 'solidArea' }, { id: 'skovrejsning', name: 'Uønsket skovrejsningsområde', color: '#CAC95D', type: 'solidArea' }]


////////////////////////
// Class start
////////////////////////
class map extends Component {
   constructor(props) {
      super(props);
      this.state = {
         screenWidth: window.innerWidth,
         screenHeight: window.innerHeight,
         scaleFactor: '...',
         drawingPolygon: false,
         drawingLine: false,
         drawingPoint: false,
         cuttingPolygon: false,
         polygonReadySave: false,
         noteMarkersInserted: false,
         pointsInserted: false,
         huntingPointsInserted: false,
         tempNotesLength: 0,
         tempNoteLatLng: null,
         tempFlyToNoteData: null,
         cellsInserted: false,
         cellsInserted2: false,
         huntingFeaturesInserted: false,
         lastCellClickedId: 0,
         tempCellsLength: 0,
         mapFittet: false,
         cancelFitMapToForest: false,
         mapFitTimeout: 6000,
         mapFitTimerStart: false,
         mapFitStartTime: 0,
         lastBrowserLocation: "",
         tutorial: false,
         componentMounted: false,
         leafletLayerRemoveId: null,
         tempCellLatLngs: null,
         measuringInProgress: false,
         measVertexId: null,
         measTotalLength: 0,
         measLastVertexLatLng: null,
         measId: null,
         measDelete: false,
         drawingTextLine: false,
         tempSelectedForest: this.props.selectedForest,
         firstPointInCell: false,
         lineSegmentInCell: [],
         farAwayWarning: false,
         tempCellLayerState: true,
         tempHuntingLayerState: false,
         tempCellsOpacityState: false,
         tempLineLayerState: true,
         tempPointsLayerState: true,
         tempNotesState: true,
         tempCadastralState: false,
         tempCadastralOwnerState: false,
         tempMarkblokkeState: false,
         tempProtectedAncientAreasState: false,
         tempSimpleState: this.props.simpleState,
         tempOrtoState: false,
         tempOrtoKfState: false,
         tempOrtoKfTempState: false,
         tempContourKfState: false,
         tempShadowKfState: false,
         tempShadowSurfKfState: false,
         tempBlueSpotLayer: false,
         tempProtectedNatureLayer: false,
         tempFredskovLayer: false,
         tempNatura2000Layer: false,
         tempLineLabelsShow: true,
         forestMarkersInserted: false,
         tempNotDisabledForestId: null,
         tempForestsLength: 0,
         overlapDetected: false,
         findPositionActive: false,
         introActive: false,
         tempFindPositionCoords: { lat: 0, lng: 0 },
         findPosLoading: false,
         tempPolyToolTip: false,
         drawHelpCircles: false,
         helpCirclesRadius: 20,
         tempCellEditable: false,
         typeEditable: '',
         cutWorkLayerId: null,
         speedDialTopOpen: false,
         speedDialBottomOpen: false,
         mapLayerOpen: false,
         mapCustomerOpen: false,
         mapMeasurementOpen: false,
         mapDrawingOpen: false,
         findAddressOpen: false,
         walkPolygonActive: false,
         walkLineActive: false,
         walkLoading: false,
         walkCoords: [],
         walkCount: 0,
         walkTempTime: 0,
         walkTempLastPos: { lat: 0, lng: 0 },
         printOrientation: 'Auto',
         printOrientationGenerated: 'Auto',
         printScale: {},
         printHighQuality: false,
         printSectionAndNumberLabelsActive: true,
         printSpeciesLabelsActive: true,
         printSpeciesLabelsWhiteBackground: false,
         printYearLabelsActive: true,
         printLineLabelsActive: true,
         printFontSize: 6,
         printLineWeight: 1,
         anchorOrientationMenu: null,
         speciesArray: [],
         linesArray: [],
         pointsArray: [],
         tempChosenWorkEnvironment: null,
         previousVertex: null,
         tempDraggedPoint: null,
         tempPointEditable: false,
         tempNoteEditable: false,
         lastDebounceTime: null,
         featClickId: null,
         biotopePlanMarkersInserted: false,
         biotopePlanFeaturesInserted: false,
         biotopePlanPointsInserted: false,
         tempSelectedBiotopePlan: null,
         tempBiotopePlansLength: 0,
         tempPointsLength: 0,
         tempHuntingPointsLength: 0,
         tempBiotopePlanPointsLength: 0,
      };
   }

   isPointInsidePolygon = (point, poly) => {
      let inside = false;
      let x = point.lat, y = point.lng;
      for (let ii = 0; ii < poly.length; ii++) {
         let polyPoints = poly[ii];
         for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            let xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            let xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            let intersect = ((yi > y) !== (yj > y))
               && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
         }
      }
      return inside;
   };

   ///////////////////////////////////////////
   // Map specific methods
   //////////////////////////////////////////
   // Fit the zoomed map view to the forest
   fitMapToForest = () => {
      if (this.props.chosenWorkEnvironment === 'biotopePlan') {
         if (this.props.biotopePlansUser) {
            if (!this.state.mapFittet) {
               if (this.props.selectedBiotopePlan) {
                  if (this.props.biotopePlansUser[this.props.selectedBiotopePlan]) {
                     const bbox = this.props.biotopePlansUser[this.props.selectedBiotopePlan].bbox;
                     // Fit map to bounding box if present
                     if (bbox.length > 0) {
                        let polygon = [];
                        for (let i = 0; i < bbox.length / 2; i++) {
                           polygon[i] = { lat: bbox[i * 2 + 1], lng: bbox[i * 2] }
                        }
                        // Remove forest markers
                        this.map.removeLayer(this.forestMarkersLayer);
                        let poly = L.polygon(polygon);
                        let bounds = poly.getBounds();
                        const offsetLng = this.props.sidebar ? Math.abs((bounds._northEast.lng - bounds._southWest.lng) / 2.8) : 0;
                        const offsetLat = this.props.sidebar ? Math.abs((bounds._northEast.lat - bounds._southWest.lat) / 4) : 0;
                        bounds._northEast.lng = bounds._northEast.lng + offsetLng;
                        bounds._southWest.lng = bounds._southWest.lng + offsetLng;
                        bounds._northEast.lat = bounds._northEast.lat + offsetLat;
                        bounds._southWest.lat = bounds._southWest.lat - offsetLat;
                        this.map.flyToBounds(bounds, {
                           animate: true,
                           duration: 2
                        });
                     } else {
                        // Fit to address
                        if (this.props.selectedBiotopePlan !== null) {
                           const str = this.props.biotopePlansUser[this.props.selectedBiotopePlan].address + ", " + this.props.biotopePlansUser[this.props.selectedBiotopePlan].city;
                           provider.search({ query: str }).then(response => {
                              try {
                                 this.map.flyToBounds(response[0].bounds, {
                                    animate: true,
                                    duration: 2,
                                    maxZoom: 16,
                                 });
                              }
                              catch (err) {
                                 console.warn("No address found, error : ", err)
                              }
                           });
                        }
                     }
                  }
               } else {
                  // Fit map to all biotope plans
                  // If all forests are selected create overall boundingbox
                  let bbox = [];
                  Object.values(this.props.biotopePlansUser).forEach(el => {
                     if (bbox.length === 0 && el.bbox.length > 0) {
                        bbox = [...el.bbox];
                     } else {
                        if (el.bbox.length > 0) {
                           bbox[0] = el.bbox[0] < bbox[0] ? el.bbox[0] : bbox[0];
                           bbox[1] = el.bbox[1] < bbox[1] ? el.bbox[1] : bbox[1];
                           bbox[2] = el.bbox[2] > bbox[2] ? el.bbox[2] : bbox[2];
                           bbox[3] = el.bbox[3] > bbox[3] ? el.bbox[3] : bbox[3];

                        }
                     }
                  })
                  if (bbox.length > 0) {
                     let bounds = L.latLngBounds(L.latLng(bbox[1], bbox[0]), L.latLng(bbox[3], bbox[2]));
                     const offsetLng = this.props.sidebar ? Math.abs((bounds._northEast.lng - bounds._southWest.lng) / 2.8) : 0;
                     const offsetLat = this.props.sidebar ? Math.abs((bounds._northEast.lat - bounds._southWest.lat) / 4) : 0;
                     bounds._northEast.lng = bounds._northEast.lng + offsetLng;
                     bounds._southWest.lng = bounds._southWest.lng + offsetLng;
                     bounds._northEast.lat = bounds._northEast.lat + offsetLat;
                     bounds._southWest.lat = bounds._southWest.lat - offsetLat;
                     this.map.flyToBounds(bounds, {
                        animate: true,
                        duration: 2,
                     })

                     this.setState({ biotopePlanMarkersInserted: false });
                     this.insertForestMarkers()
                  }
               }
               // Set state
               this.setState({ mapFittet: true, mapFitTimerStart: false });
            }
         }
      } else {
         if (this.props.forestBbox) {
            if (!this.state.mapFittet) {
               let bboxArray = [];
               if (this.props.selectedForest) {
                  if (this.props.forestBbox[this.props.selectedForest]) {
                     bboxArray = Object.values(this.props.forestBbox[this.props.selectedForest]).map(el => {
                        return el;
                     }).flat()
                  }
                  // Remove forest markers
                  this.map.removeLayer(this.forestMarkersLayer);
               } else {
                  // If all forests are selected create overall boundingbox
                  let tempBboxArray = Object.values(this.props.forestBbox).map(el => {
                     try {
                        return el.bbox;
                     } catch (err) {
                        //console.warn("Forest has no bounding box yet: ", err)
                     }
                  }).flat()
                  // Create bounding box
                  if (tempBboxArray.length > 0 && tempBboxArray[0] !== undefined) {
                     tempBboxArray = tempBboxArray.filter(el => { return el !== undefined })
                     const lng = tempBboxArray.filter((element, index) => {
                        return index % 2 === 0;
                     })
                     const lat = tempBboxArray.filter((element, index) => {
                        return index % 2 !== 0;
                     })
                     const lngMinMax = findMinMax(lng);
                     const latMinMax = findMinMax(lat);
                     bboxArray = [lngMinMax[0], latMinMax[0], lngMinMax[1], latMinMax[1]];

                     // Insert forest markers
                     this.setState({ forestMarkersInserted: false });
                     this.insertForestMarkers()
                  }
               }
               // Fit selected map to boundingbox
               if (bboxArray.length > 0) {
                  let polygon = [];
                  const lnglats = bboxArray;

                  for (let i = 0; i < lnglats.length / 2; i++) {
                     polygon[i] = { lat: lnglats[i * 2 + 1], lng: lnglats[i * 2] }
                  }
                  let poly = L.polygon(polygon);
                  let bounds = poly.getBounds();
                  const offsetLng = this.props.sidebar ? Math.abs((bounds._northEast.lng - bounds._southWest.lng) / 2.8) : 0;
                  const offsetLat = this.props.sidebar ? Math.abs((bounds._northEast.lat - bounds._southWest.lat) / 4) : 0;
                  bounds._northEast.lng = bounds._northEast.lng + offsetLng;
                  bounds._southWest.lng = bounds._southWest.lng + offsetLng;
                  bounds._northEast.lat = bounds._northEast.lat + offsetLat;
                  bounds._southWest.lat = bounds._southWest.lat - offsetLat;
                  this.map.flyToBounds(bounds, {
                     animate: true,
                     duration: 2
                  });
               } else {
                  // Fit to address
                  if (this.props.selectedForest !== null && this.props.customerList[this.props.selectedForest] && this.props.customerList[this.props.selectedForest].address) {
                     let str = this.props.customerList[this.props.selectedForest].address + ", " + this.props.customerList[this.props.selectedForest].city;
                     provider.search({ query: str }).then(response => {
                        try {
                           this.map.flyToBounds(response[0].bounds, {
                              animate: true,
                              duration: 2,
                              maxZoom: 16,
                           });
                        }
                        catch (err) {
                           console.warn("No address found, error : ", err)
                        }
                     });
                  }
               }
               this.setState({ mapFittet: true, mapFitTimerStart: false });
            }
         }
      }
   }

   // Insert forest markers
   insertForestMarkers = () => {
      if (this.props.chosenWorkEnvironment !== 'biotopePlan') {
         if (!this.state.forestMarkersInserted) {
            // Remove forest markers before inserting them again
            Object.values(this.forestMarkersLayer._layers).forEach(mrk => {
               this.forestMarkersLayer.removeLayer(mrk._leaflet_id);
            })
            // Insert forest markers
            if (this.props.forestBbox !== null) {
               Object.keys(this.props.forestBbox).forEach(async key => {
                  try {
                     // Location of marker
                     const forest = this.props.customerList[key];
                     // Get location from bounding box
                     let mrkLat = null;
                     let mrkLng = null;
                     if (this.props.forestBbox[key] && this.props.forestBbox[key].bbox && this.props.forestBbox[key].bbox.length === 4) {
                        const tempLatLng = this.props.forestBbox[key].bbox;
                        mrkLat = (tempLatLng[1] + tempLatLng[3]) / 2;
                        mrkLng = (tempLatLng[0] + tempLatLng[2]) / 2;
                     } else {
                        // Try to get location from address (TODO : better way of inserting - they are inserted many times because of the async operation)
                        const response = await provider.search({ query: forest.address + ", " + forest.city });
                        if (Object.values(this.forestMarkersLayer._layers).filter(el => el.options.id === forest.id).length === 0) {
                           if (response.length > 0) {
                              mrkLng = response[0].x ? response[0].x : null;
                              mrkLat = response[0].y ? response[0].y : null;
                           }
                        } else {
                           throw ": Marker already inserted - caused på async/await!";
                        }
                     }
                     // Throw error if it is not possible to find marker placement
                     if (mrkLat === null || mrkLng === null) throw "not possible to place forest marker!";
                     // --- Else style and place marker --- //
                     // Set icon based on todo status
                     let fIcon = mapIcons.ForestIcon;
                     const ntsCheck = this.props.notes[key] ? this.props.notes[key] : [];
                     for (let i = 0; i < ntsCheck.length; i++) {
                        if (!ntsCheck[i].private || (ntsCheck[i].private && ntsCheck[i].userUID === this.props.currentAuthUser.id)) {
                           if (ntsCheck[i].tag === 'todo') {
                              if (ntsCheck[i].todoStatus === 'Ikke planlagt' || ntsCheck[i].todoStatus === 'Ikke startet') {
                                 fIcon = mapIcons.ForestIconGrey;
                                 break;
                              } else if (ntsCheck[i].todoStatus === 'I gang') {
                                 fIcon = mapIcons.ForestIconBlue;
                                 break;
                              }
                           }
                        }
                     }
                     // Check if forest is disabled
                     if (this.props.notDisabledForestId && this.props.notDisabledForestId !== key) {
                        fIcon = mapIcons.ForestDisabled;
                     }

                     // Insert marker in forest markers layer
                     L.marker([mrkLat, mrkLng], {
                        pane: "forestMarkerPane",
                        icon: fIcon,
                        id: forest.id,
                        snappable: false,
                        snapIgnore: true,
                        riseOnHover: true,
                     }).addTo(this.forestMarkersLayer).bindTooltip(
                        "<b>" + forest.forestName + "</b> <br>" + forest.name,
                        { pane: "toolTipPane", sticky: true }
                     );
                  } catch (err) {
                     console.warn("Forest has no bounding box yet", err)
                  }
               })
               this.map.addLayer(this.forestMarkersLayer.bringToFront());
               this.setState({ forestMarkersInserted: true });
            }
         }
      } else if (this.props.chosenWorkEnvironment === 'biotopePlan' && this.props.selectedBiotopePlan === null) {
         if (!this.state.biotopePlanMarkersInserted) {
            Object.values(this.forestMarkersLayer._layers).map(mrk => {
               this.forestMarkersLayer.removeLayer(mrk._leaflet_id);
               return 0;
            })
            // get bbox of biotopeplans
            if (this.props.biotopePlansUser) {
               Object.values(this.props.biotopePlansUser).forEach(plan => {
                  const bbox = plan.bbox ? plan.bbox : [];
                  if (bbox.length > 0) {
                     // Insert marker
                     const lat = (bbox[1] + bbox[3]) / 2;
                     const lng = (bbox[0] + bbox[2]) / 2;
                     L.marker([lat, lng], {
                        pane: "forestMarkerPane",
                        icon: mapIcons.ForestIcon,
                        id: plan.id,
                        snappable: false,
                        snapIgnore: true,
                        riseOnHover: true,
                     }).addTo(this.forestMarkersLayer).bindTooltip(
                        "<b>" + plan.planName + "</b> <br>" + plan.name,
                        { pane: "toolTipPane", sticky: true }
                     );
                  }
               })
               this.map.addLayer(this.forestMarkersLayer.bringToFront());
               this.setState({ biotopePlanMarkersInserted: true })
            }
         }
      }

   }

   onForestMarkerClickHandler = (data) => {
      if (this.props.chosenWorkEnvironment === 'biotopePlan') {
         this.props.onSetCurrentlySelectedBiotopePlanId(data.layer.options.id)
      } else {
         this.props.onSetForestMarkerClickId(data.layer.options.id);
      }
   }

   ///////////////////////
   // Notes
   ///////////////////////
   onInsertNote = (data) => {
      if (this.props.newNoteFinished) {
         // Onboarding action if active
         if (this.props.onboardingActive && this.props.onboardingState === 'newNoteCreate') {
            this.props.onSetOnboardingState('newNoteEnd');
         }
         this.setState({ tempNoteLatLng: data.layer._latlng });
         L.marker(data.layer._latlng, { pane: "notesPane", snapIgnore: true, new: true }).addTo(this.markersLayer).on("dragend", e => {
            this.noteDragEnd(e);
         });

         // Set flag indicating that new note has not been finished
         this.props.onSetNewNoteFinished(false);

         // Route to new destination and pass on props
         const { lat, lng } = data.layer._latlng; // <- changes made to remove LatLng name of object
         this.props.history.push({
            pathname: '/Notes/NewNote',
            state: {
               latlng: { lat, lng },
            },
         });
         this.props.onSidebarOpen(true)
      } else {
         // TODO : Insert dialog box to inform user that note needs to be finished
         // this.props.onEnqueueSnackbar({
         //     message: 'Færdiggør igangværende registrering!',
         //     options: {
         //         key: new Date().getTime() + Math.random(),
         //         variant: 'info',
         //     },
         // });
         // if (!this.props.sidebarOpen) this.props.onSidebarOpen(true);
      }
   }

   onDeleteMarker = (markerLatLng) => {
      const markerIdLayer = Object.values(this.markersLayer._layers).filter(layer => {
         return layer._latlng.lat === markerLatLng.lat && layer._latlng.lng === markerLatLng.lng;
      })
      if (markerIdLayer.length > 0) {
         this.markersLayer.removeLayer(markerIdLayer[0]._leaflet_id);
      } else {
         this.map.eachLayer(lay => {
            if (lay.options.type === "Point" && lay._latlng.lat === markerLatLng.lat && lay._latlng.lng === markerLatLng.lng) {
               if (this.production.points.hasLayer(lay)) this.production.points.removeLayer(lay);
               if (this.huntingPointsLayer.hasLayer(lay)) this.huntingPointsLayer.removeLayer(lay);
               if (this.biotopePlan.points.hasLayer(lay)) this.biotopePlan.points.removeLayer(lay);
            }
         })
         // Object.values(this.map._layers).forEach(lay => {
         //     if (lay.options.type === "Point" && lay._latlng.lat === markerLatLng.lat && lay._latlng.lng === markerLatLng.lng) {
         //         // this.map.removeLayer(lay);
         //     }
         // })

         // if (this.props.chosenWorkEnvironment === 'forest') {
         //     const pointIdLayer = Object.values(this.production.points._layers).filter(layer => {
         //         return layer._latlng.lat === markerLatLng.lat && layer._latlng.lng === markerLatLng.lng;
         //     })
         //     if (pointIdLayer.length > 0) {
         //         this.production.points.removeLayer(pointIdLayer[0]._leaflet_id);
         //     }
         // } else if (this.props.chosenWorkEnvironment === 'hunting') {
         //     const pointIdLayer = Object.values(this.huntingPointsLayer._layers).filter(layer => {
         //         return layer._latlng.lat === markerLatLng.lat && layer._latlng.lng === markerLatLng.lng;
         //     })
         //     if (pointIdLayer.length > 0) {
         //         this.huntingPointsLayer.removeLayer(pointIdLayer[0]._leaflet_id);
         //     }
         // } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
         //     const pointIdLayer = Object.values(this.biotopePlan.points._layers).filter(layer => {
         //         return layer._latlng.lat === markerLatLng.lat && layer._latlng.lng === markerLatLng.lng;
         //     })
         //     if (pointIdLayer.length > 0) {
         //         this.biotopePlan.points.removeLayer(pointIdLayer[0]._leaflet_id);
         //     }
         // } else {
         //     console.error('Environment not supported!!!')
         // }
      }
      this.props.onSetDeleteMarker(false);
   }

   onMarkerClickHandler = (data, latlng) => {
      if (!this.props.cellEditable && !this.props.noteEditable && !this.props.pointEditable) {
         if (data.length > 0) {
            this.props.history.replace({
               pathname: `/Notes/${data[0].id}`,
               state: {
                  title: data[0].title,
                  date: data[0].date,
                  creationDate: data[0].creationDate,
                  text: data[0].text,
                  id: data[0].id,
                  userUID: data[0].userUID,
                  from: data[0].from,
                  images: data[0].images,
                  tag: data[0].tag,
                  todoStatus: data[0].todoStatus,
                  todoTeam: data[0].todoTeam,
                  tasks: data[0].tasks,
                  responsible: data[0].responsible,
                  taskTypes: data[0].taskTypes,
                  taskDeadline: data[0].taskDeadline,
                  private: data[0].private,
                  priority: data[0].priority,
                  latlng: data[0].latlng,
                  markerId: data[0].markerId,
                  forestId: data[0].forestId,
                  litraNr: data[0].litraNr,
               }
            });
         } else {
            // Set flag indicating that new note has not been finished
            this.props.onSetNewNoteFinished(false);
            const { lat, lng } = latlng;
            this.props.history.push({
               pathname: '/Notes/NewNote',
               state: {
                  latlng: { lat, lng },
               },
            });
         }
         if (!this.props.sidebar) {
            this.props.onSidebarOpen(true)
         }
      }
   }

   // Insert all markers from backend on the map and associated marker id to note
   insertAllMarkersOnMap = () => {
      if (this.props.notes) {
         if (!this.state.noteMarkersInserted) {
            // Get array of database cells ids and map cells layer and remove layers from map no more in database
            // Form notes array
            let ntsArray = [];
            if (this.props.notes[this.props.selectedForest]) {
               Object.values(this.props.notes[this.props.selectedForest]).forEach(el => {
                  if (this.props.filterShowTasksInMap && this.props.filterTaskIds) {
                     if (this.props.filterTaskIds.includes(el.id)) {
                        ntsArray.push(el);
                     }
                  } else {
                     ntsArray.push(el);
                  }
               })
            } else {
               Object.values(this.props.notes).forEach(forestEl => {
                  forestEl.forEach(el => {
                     if (this.props.filterShowTasksInMap && this.props.filterTaskIds) {
                        if (this.props.filterTaskIds.includes(el.id)) {
                           ntsArray.push(el);
                        }
                     }
                  })
               })
            }

            // Remove previous elements of the layer
            Object.values(this.markersLayer._layers).forEach(mrk => {
               this.markersLayer.removeLayer(mrk._leaflet_id);
            })

            // Go through array of notes and insert
            ntsArray.map(nts => {
               // Set note marker icon
               // Check if note should be displayed
               if (this.props.filterTodoMyTasks && nts.responsible && !nts.responsible.includes(this.props.currentAuthUser.name)) {
                  return 0;
               } else if (nts.tag && nts.tag === 'todo' && nts.todoStatus === 'Arkiveret') {
                  return 0;
               } else if (this.props.filterTag === 'privat') {
                  if (!nts.private) {
                     return 0;
                  }
               } else if (nts.tag && nts.tag !== this.props.filterTag && this.props.filterTag !== '' && !this.props.filterTag.startsWith("20")) {
                  return 0;
               } else {
                  if (this.props.filterTag === 'todo' && this.props.filterTodoStatus !== '') {
                     if (nts.todoStatus && nts.todoStatus !== this.props.filterTodoStatus) {
                        return 0;
                     }
                  } else if (this.props.filterTag.startsWith("20") && this.props.filterTag !== ((new Date(nts.creationDate ? nts.creationDate : nts.date)).getFullYear()).toString()) {
                     return 0;
                  }
               }
               let ntIcon = mapIcons.markerNoteIcon;
               if (nts.tag) {
                  switch (nts.tag) {
                     case "jagt": ntIcon = mapIcons.markerHuntingIcon; break;
                     case "todo": if (nts.todoStatus === 'Ikke planlagt') { ntIcon = mapIcons.markerTodoIconNotPlanned } else if (nts.todoStatus === 'Ikke startet') { ntIcon = mapIcons.markerTodoIcon } else if (nts.todoStatus === 'I gang') { ntIcon = mapIcons.markerTodoIconInProgress } else { ntIcon = mapIcons.markerTodoIconDone }; break;
                     case "skoven": ntIcon = mapIcons.markerForestIcon; break;
                     case "billeder": ntIcon = mapIcons.markerImagesIcon; break;
                     case "andet": ntIcon = mapIcons.markerNoteIcon; break;
                     case "privat": ntIcon = mapIcons.markerNoteIcon; break;
                     case "skovejere": ntIcon = mapIcons.ownersIcon; break;
                     case "hededanmark": ntIcon = mapIcons.HDIcon; break;
                     default: ntIcon = mapIcons.markerNoteIcon; break;
                  }
               }
               // Insert note on map
               if (nts.private && nts.userUID !== this.props.currentAuthUser.id) {
               } else if (nts.tag === 'skovejere' && this.props.currentAuthUser.userRole === 'forester') {
               } else {
                  if (this.props.noteToolTipActive) {
                     L.marker(nts.latlng, {
                        pane: "notesPane",
                        type: 'Note',
                        icon: ntIcon,
                        id: nts.id,
                        snappable: false,
                        snapIgnore: true,
                     }).addTo(this.markersLayer).bindTooltip(
                        "<b>" + this.ntsTitle(nts.title) + "</b><br>" +
                        "<i style=font-size:11px>" + moment(nts.date).format('DD[-]MM[-]YYYY HH:mm') + "</i><br>" +
                        this.ntsText(nts.text),
                        { pane: "toolTipPane", sticky: true }
                     ).on("dragend", e => {
                        this.noteDragEnd(e);
                     });;
                  } else {
                     L.marker(nts.latlng, {
                        pane: "notesPane",
                        type: 'Note',
                        icon: ntIcon,
                        id: nts.id,
                        snappable: false,
                        snapIgnore: true,
                     }).addTo(this.markersLayer).on("dragend", e => {
                        this.noteDragEnd(e);
                     });
                  }
               }
               return 0;
            })
            this.props.onSetNumberOfNotes(ntsArray.length)
            this.setState({ noteMarkersInserted: true });
         }
      }
   }

   ntsTitle(text) {
      let wordArray = text.split(" ");
      let wordCount = wordArray.length;

      let lineOne = [];

      if (wordCount <= 9) {
         for (let i = 0; i < wordCount; i++) {
            lineOne.push(wordArray[i])
         }
         return (lineOne.join(" "))
      }

      for (let i = 0; i < wordCount; i++) {
         if (i <= 9) {
            lineOne.push(wordArray[i])
         }
      }
      return (lineOne.join(" ") + " ...")
   }

   ntsText(text) {
      let wordArray = text.split(" ");
      let wordCount = wordArray.length;

      let lineOne = [];
      let lineTwo = [];

      if (wordCount <= 9) {
         for (let i = 0; i < wordCount; i++) {
            lineOne.push(wordArray[i])
         }
         return (lineOne.join(" "))
      } else if (wordCount <= 17) {
         for (let i = 0; i < wordCount; i++) {
            if (i <= 9) {
               lineOne.push(wordArray[i])
            } else {
               lineTwo.push(wordArray[i])
            }
         }
         return (lineOne.join(" ") + "<br>" + lineTwo.join(" "))
      }
      for (let i = 0; i < wordCount; i++) {
         if (i <= 9) {
            lineOne.push(wordArray[i])
         } else if (i <= 17) {
            lineTwo.push(wordArray[i])
         }
      }
      return (lineOne.join(" ") + "<br>" + lineTwo.join(" ") + " ...")
   }

   // Drag end event handler
   noteDragEnd = (event) => {
      // Set editable latlng
      const { lat, lng } = event.target._latlng; // <- changes made to remove LatLng name of object
      this.props.onSetNoteEditableLatLng({ lat, lng })
   }
   ////////////////////////////////
   // Points
   ////////////////////////////////
   onInsertPoint = (data) => {
      if (this.props.newNoteFinished) {
         this.setState({ tempNoteLatLng: data.layer._latlng, drawingPoint: false });
         // Insert feature into correct layer based on work environment
         // Default layer is forest
         let layer = this.production.points;
         if (this.props.chosenWorkEnvironment === 'hunting') {
            layer = this.huntingPointsLayer;
         } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
            layer = this.biotopePlan.points;
         }
         const routeProps = addNewFeatureToLayer(data, layer, {}, {
            pane: "pointsPane",
            environment: this.props.chosenWorkEnvironment,
            dragEndEventListner: this.pointDragEnd,
         })

         // Set flag indicating that new note has not been finished
         this.props.onSetNewNoteFinished(false);
         // Route to new destination and pass on props
         const { lat, lng } = data.layer._latlng; // <- changes made to remove LatLng name of object
         this.props.history.push({
            pathname: '/Points/NewPoint',
            state: {
               latlng: { lat, lng },
            },
         });
         this.props.onSidebarOpen(true)
      } else {
         // TODO : Insert dialog box to inform user that note needs to be finished
      }
   }
   // Points clickHandler
   onPointClickHandler = (data) => {
      if (!this.props.cellEditable && !this.props.noteEditable && !this.props.pointEditable) {
         if (data) {
            this.props.history.replace({
               pathname: `/Points/${data.id}`,
               state: {
                  ...data,
               }
            });
         }
         if (!this.props.sidebar) {
            this.props.onSidebarOpen(true)
         }
      }
   }

   // Insert all points on map
   insertAllPointsOnMap = () => {
      if (this.props.points) {
         // Set number of hunting points in reducer
         if (this.props.points[this.props.selectedForest]) {
            const len = Object.keys(this.props.points[this.props.selectedForest]).length;
            if (this.props.numPoints !== len) {
               this.props.onSetNumberOfPoints(len);
            }
         }
         if (!this.state.pointsInserted) {
            // Get array of database points ids and map cells layer and remove layers from map no more in database
            // Form points array
            let pointArray = [];
            if (this.props.points[this.props.selectedForest]) {
               pointArray = Object.values(this.props.points[this.props.selectedForest]).map(el => {
                  return el;
               }).flat()
            }
            // Remove all elements from layers
            resetLayer(this.production, { type: 'points' })
            resetLayer(this.hydroLayer, { type: 'points' });
            resetLayer(this.roadsTrails, { type: 'points' });
            resetLayer(this.biodiversityArea, { type: 'points' });
            resetLayer(this.intensiveArea, { type: 'points' });
            resetLayer(this.primevalForest, { type: 'points' });
            resetLayer(this.protectedNature, { type: 'points' });
            resetLayer(this.keyBiotope, { type: 'points' });
            resetLayer(this.naturalValuableForest, { type: 'points' });
            resetLayer(this.keyElements, { type: 'points' });
            resetLayer(this.public, { type: 'points' });
            resetLayer(this.other, { type: 'points' });

            pointArray.forEach(pnt => {
               // Find layer to insert into
               const layersArr = mapFeatureToLayer(pnt);
               // Run through all layers
               layersArr.forEach(lay => {
                  addFeatureToLayer(pnt, this.map, this[lay].points, this[lay].pointsLabels, {
                     featPane: "pointsPane",
                     labelPane: "pointsPane",
                     toolTipPane: "toolTipPane",
                     environment: pnt.environment,
                  }, {
                     withToolTip: this.props.polyToolTipActive,
                     pointDragEnd: this.pointDragEnd
                  })
               })
            })
            this.setState({ pointsInserted: true });
         }
      }
   }
   // Insert all hunting points on map
   insertAllHuntingPointsOnMap = () => {
      if (this.props.huntingPoints) {
         // Set number of hunting points in reducer
         if (this.props.huntingPoints[this.props.selectedForest]) {
            const len = Object.keys(this.props.huntingPoints[this.props.selectedForest]).length;
            if (this.props.numHuntingPoints !== len) {
               this.props.onSetNumberOfHuntingPoints(len);
            }
         }
         if (!this.state.huntingPointsInserted && this.props.huntingLayerState) {
            // Get array of database points ids and map cells layer and remove layers from map no more in database
            // Form points array
            let pointArray = [];
            if (this.props.huntingPoints[this.props.selectedForest]) {
               pointArray = Object.values(this.props.huntingPoints[this.props.selectedForest]).map(el => {
                  return el;
               }).flat()
            }
            // Remove existing point and labels
            Object.values(this.huntingPointsLayer._layers).forEach(pnt => {
               this.huntingPointsLayer.removeLayer(pnt._leaflet_id);
            })
            Object.values(this.huntingPointsLayerLabels._layers).forEach(pnt => {
               this.huntingPointsLayerLabels.removeLayer(pnt._leaflet_id);
            })
            // Insert points
            pointArray.forEach(pnt => {
               // Check if point should be displayed
               let pntIcon = mapIcons.pointIcon;
               if (pnt.pointType) {
                  switch (pnt.pointType) {
                     case 'Jordpost': pntIcon = mapIcons.huntingGroundIcon; break;
                     case 'Jagtstige': pntIcon = mapIcons.huntingLatterIcon; break;
                     case 'Jagttårn': pntIcon = mapIcons.huntingTowerIcon; break;
                     case 'Foderkasse': pntIcon = mapIcons.huntingBoxIcon; break;
                     case 'Fodertønde': pntIcon = mapIcons.huntingBucketIcon; break;
                     case 'Saltsliksten': pntIcon = mapIcons.huntingSaltStoneIcon; break;
                     case 'Vildtkamera': pntIcon = mapIcons.huntingCamIcon; break;
                     case 'Jagthytte': pntIcon = mapIcons.huntingHouseIcon; break;
                     case 'Samlingsplads': pntIcon = mapIcons.huntingGroupIcon; break;
                     default: pntIcon = mapIcons.pointIcon; break;
                  }
               }
               // Insert points on map
               if (this.props.polyToolTipActive) {
                  L.marker(pnt.latlng, {
                     pane: "pointsPane",
                     icon: pntIcon,
                     id: pnt.id,
                     environment: pnt.environment,
                     snappable: false,
                     snapIgnore: true,
                     type: 'Point',
                     pointType: pnt.pointType,
                     // draggable: true,
                  }).addTo(this.huntingPointsLayer).bindTooltip(
                     "<b>" + pnt.title + "</b><br>" +
                     this.ntsText(pnt.text),
                     { pane: "toolTipPane", sticky: true }
                  ).on("dragstart", e => {
                     this.huntingPointDragStart(e);
                  }).on("dragend", e => {
                     this.huntingPointDragEnd(e);
                  })
               } else {
                  L.marker(pnt.latlng, {
                     pane: "pointsPane",
                     icon: pntIcon,
                     id: pnt.id,
                     environment: pnt.environment,
                     snappable: false,
                     snapIgnore: true,
                     type: 'Point',
                     pointType: pnt.pointType,
                     // draggable: true,
                  }).addTo(this.huntingPointsLayer).on("dragstart", e => {
                     this.huntingPointDragStart(e);
                  }).on("dragend", e => {
                     this.huntingPointDragEnd(e);
                  })
               }
               // Insert circle if there
               if (pnt.radius && parseFloat(pnt.radius.toString().split(',').join('.')) > 0) {
                  L.circle(pnt.latlng, {
                     radius: parseFloat(pnt.radius.toString().split(',').join('.')),
                     color: 'black',
                     fillColor: '#009CDF',
                     fillOpacity: 0.3,
                     weight: 0.5,
                     pane: "pointsCirclePane",
                     id: pnt.id,
                     environment: pnt.environment,
                  }).addTo(this.huntingPointsLayer);
               }
               // Insert label
               let labelContent = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>"
                  +
                  "<span style=white-space:nowrap>" + pnt.title + "</span><br>"
               if (pnt.title !== pnt.pointType) {
                  labelContent = labelContent + "<b>" + pnt.pointType + "</b>";
               }
               labelContent = labelContent + "</span>";

               L.marker(pnt.latlng, {
                  pane: "polygonsLabelsPane",
                  icon: this.createLabelIconPoint(labelContent, 10.5),
                  id: pnt.id,
                  snapIgnore: true,
                  environment: pnt.environment,
                  title: pnt.title,
                  pointType: pnt.pointType,
                  type: 'label',
               }).addTo(this.huntingPointsLayerLabels)
            })
            // Set total number of hunting points in reducer
            this.setState({ huntingPointsInserted: true });
         }
      }
   }
   // Insert all biotope plan points on map
   insertAllBiotopePlanPointsOnMap = () => {
      if (this.props.biotopePlanPoints && !_.isEmpty(this.props.biotopePlanPoints)) {
         if (!this.state.biotopePlanPointsInserted) {
            // Get array of database points ids and map cells layer and remove layers from map no more in database
            // Form points array
            let pointArray = [];
            if (this.props.biotopePlanPoints[this.props.selectedBiotopePlan]) {
               pointArray = Object.values(this.props.biotopePlanPoints[this.props.selectedBiotopePlan]).map(el => {
                  return el;
               }).flat()
            }
            // Remove all elements from layers
            resetLayer(this.biotopePlan, { type: 'points' });

            pointArray.forEach(pnt => {
               addFeatureToLayer(pnt, this.map, this.biotopePlan.points, this.biotopePlan.pointsLabels, {
                  featPane: "pointsPane",
                  labelPane: "pointsPane",
                  toolTipPane: "toolTipPane",
                  environment: pnt.environment,
               }, {
                  withToolTip: this.props.polyToolTipActive,
                  pointDragEnd: this.pointDragEnd
               })
            })
            // Set total number of points in reducer
            this.props.onSetNumberOfPoints(pointArray.length);
            this.setState({ biotopePlanPointsInserted: true });
         }
      }
   }
   // Drag end event handler
   pointDragEnd = (event) => {
      // Move label
      const label = Object.values(this.map._layers).filter(lay => (lay.options.id === event.target.options.id && lay.options.type === 'label'))
      if (label.length > 0) {
         label[0].setLatLng(event.target._latlng)
      }
      // Set editable latlng
      const { lat, lng } = event.target._latlng; // <- changes made to remove LatLng name of object
      this.props.onSetPointEditableLatLng({ lat, lng })
   }
   // Hunting points drag end event handler
   huntingPointDragEnd = (event) => {
      // Move circle to 
      const circ = Object.values(this.huntingPointsLayer._layers).filter(lay => {
         return (lay.options.id === event.target.options.id && lay._mRadius);
      })
      if (circ.length > 0) {
         circ[0].setLatLng(event.target._latlng)
      }
      // Move label
      const label = Object.values(this.huntingPointsLayerLabels._layers).filter(lay => {
         return (lay.options.id === event.target.options.id);
      })
      if (label.length > 0) {
         label[0].setLatLng(event.target._latlng)
      }
      // Set editable latlng
      const { lat, lng } = event.target._latlng; // <- changes made to remove LatLng name of object
      this.props.onSetPointEditableLatLng({ lat, lng })
   }
   // Drag start event handler
   pointDragStart = (event) => {
   }
   huntingPointDragStart = (event) => {
   }

   ////////////////////////////////
   // Polygons and lines
   ////////////////////////////////
   onCellsClickHandler = (data, envType) => {
      if (!this.props.cellEditable && !this.props.noteEditable && !this.props.pointEditable && !this.state.drawingPolygon && !this.state.drawingLine && !this.state.measuringInProgress && !this.state.cuttingPolygon) {
         if (envType !== 'hunting') {
            // Feature click handler
            const featClickId = leafletFeatureClickHandler(data, this.state.featClickId, {
            }, {
               transparent: !this.props.cellsOpacityState,
               darkTheme: !(this.props.ortoKfState || this.props.ortoKfTempState || this.props.ortoState || this.props.shadowSurfKfState),
               filterActive: this.props.filterShowFilterInMap,
               filterIds: this.props.filterCellIds
            });
            // Set clicked feature leaflet id and latlngs in state
            this.setState({ featClickId: featClickId, tempCellLatLngs: data.layer._latlngs })
            // -------------------- TODO : Make Nicer ------------------ //
            let feat = null;
            if (this.props.chosenWorkEnvironment === 'biotopePlan') {
               feat = this.props.biotopePlanFeatures[this.props.selectedBiotopePlan].filter(el => el.cellData.id === data.layer.options.id);
            } else {
               feat = this.props.cells[this.props.selectedForest].filter(el => el.cellData.id === data.layer.options.id);
            }
            // Compute parameters
            let geo = null;
            let area = '';
            let lineLength = '';
            if (feat[0].cellData.type === 'Line') {
               geo = convertNewCellToGeoJson(data.layer._latlngs, 'Line');
               area = feat[0].cellData.area;
               const geoJsonFeat = {
                  type: 'Feature',
                  geometry: {
                     type: geo.type,
                     coordinates: geo.coordinates,
                  },
                  properties: {}
               }
               lineLength = getLineLength(geoJsonFeat, { units: 'kilometers' }) * 1000;
               if (!("lineLength" in feat[0].cellData)) {
                  feat[0].cellData = { ...feat[0].cellData, lineLength: lineLength };
               }
               feat[0].cellData.lineLength = parseFloat(feat[0].cellData.lineLength);
            } else {
               geo = convertNewCellToGeoJson(data.layer._latlngs, 'Polygon');
               area = Math.round(geojsonArea.geometry(geo) / 10000 * 100) / 100;
            }
            // Load cell relevant data in sidebar and route
            if (feat[0]) {
               this.props.history.replace({
                  pathname: `/Cells/${feat[0].cellData.id}`,
                  state: {
                     data: feat[0].cellData,
                     geo: geo,
                     id: feat[0].cellData.id,
                     categories: feat[0].categories ? feat[0].categories : [],
                     registrations: feat[0].registrations ? feat[0].registrations : [],
                     previousSpecies: feat[0].previousSpecies ? feat[0].previousSpecies : '',
                     futureSpecies: feat[0].futureSpecies ? feat[0].futureSpecies : '',
                  }
               });
            } else {
               // Route to new destination and pass on props
               this.props.history.push({
                  pathname: '/Cells/NewCell',
                  state: {
                     latlngs: data.layer._latlngs,
                     area: area,
                     geo: geo,
                  },
               });
            }
            // Reset hunting layers
            Object.values(this.huntingCellsLayer._layers).forEach(cell => {
               let cellOpacity = huntingPolygonNotSelectedFillOpacity;
               let cellLineColor = polygonNotSelectedColor;
               let cellsDashArray = polygonNotSelectedDashArray;
               let cellWeight = 1;
               if (cell.options.cellType === 'Såt') {
                  cellLineColor = 'white';
                  cellsDashArray = polygonSelectedDashArray;
                  cellWeight = huntingSaatLineWeight;
                  cellOpacity = 0;
               }
               cell.setStyle({ weight: cellWeight, fillOpacity: cellOpacity, color: cellLineColor, dashArray: cellsDashArray });
            })
            Object.values(this.huntingLinesLayer._layers).forEach(line => {
               line.setStyle({ opacity: lineNotSelectedOpacity, color: line.options.constColor })
            })
            // -------------------- TODO END ------------------ //
         } else {
            // Check if last feature was from another layer
            if (this.state.featClickId) {
               resetFeatureStyle(this.state.featClickId, this.map, {}, {
                  transparent: !this.props.cellsOpacityState,
                  darkTheme: !(this.props.ortoKfState || this.props.ortoKfTempState || this.props.ortoState || this.props.shadowSurfKfState),
                  filterActive: this.props.filterShowFilterInMap,
                  filterIds: this.props.filterCellIds
               });
               this.setState({ featClickId: null })
            }
            // Change opacity and line weight on clicked cell
            let clickedCellId = null;
            // Polygons
            if (data.layer.options.type === 'Polygon') {
               // Set all hunting lines and polygons to normal
               Object.values(this.huntingLinesLayer._layers).forEach(line => {
                  line.setStyle({ opacity: lineNotSelectedOpacity, color: line.options.constColor });
               })

               if (envType === 'hunting') {
                  Object.values(this.huntingCellsLayer._layers).forEach(cell => {
                     if (cell.options.id === data.layer.options.id) {
                        if (cell.options.cellType === 'Såt') {
                           cell.setStyle({ weight: huntingSaatLineWeight + 1.5, fillOpacity: huntingPolygonSelectedFillOpacity, color: 'white', dashArray: polygonSelectedDashArray });
                        } else {
                           cell.setStyle({ weight: 3, fillOpacity: huntingPolygonSelectedFillOpacity, color: cell.options.fillColor, dashArray: polygonSelectedDashArray });
                        }
                        cell.bringToFront()
                     } else {
                        let cellOpacity = huntingPolygonNotSelectedFillOpacity;
                        let cellLineColor = polygonNotSelectedColor;
                        let cellsDashArray = polygonNotSelectedDashArray;
                        let cellWeight = 1;
                        if (cell.options.cellType === 'Såt') {
                           cellLineColor = 'white';
                           cellsDashArray = polygonSelectedDashArray;
                           cellWeight = huntingSaatLineWeight;
                           cellOpacity = 0;
                        }
                        cell.setStyle({ weight: cellWeight, fillOpacity: cellOpacity, color: cellLineColor, dashArray: cellsDashArray });
                     }
                  })
               } else {
                  Object.values(this.huntingCellsLayer._layers).forEach(cell => {
                     let cellOpacity = huntingPolygonNotSelectedFillOpacity;
                     let cellLineColor = polygonNotSelectedColor;
                     let cellsDashArray = polygonNotSelectedDashArray;
                     let cellWeight = 1;
                     if (cell.options.cellType === 'Såt') {
                        cellLineColor = 'white';
                        cellsDashArray = polygonSelectedDashArray;
                        cellWeight = huntingSaatLineWeight;
                     }
                     cell.setStyle({ weight: cellWeight, fillOpacity: cellOpacity, color: cellLineColor, dashArray: cellsDashArray });
                  })
               }
               clickedCellId = data.layer.options.id;


               // Lines
            } else if (data.layer.options.type === 'Line') {
               // Hunting cells layer
               Object.values(this.huntingCellsLayer._layers).forEach(cell => {
                  let cellOpacity = huntingPolygonNotSelectedFillOpacity;
                  let cellLineColor = polygonNotSelectedColor;
                  let cellsDashArray = polygonNotSelectedDashArray;
                  let cellWeight = 1;
                  if (cell.options.cellType === 'Såt') {
                     cellLineColor = 'white';
                     cellsDashArray = polygonSelectedDashArray;
                     cellWeight = huntingSaatLineWeight;
                     cellOpacity = 0;
                  }
                  cell.setStyle({ weight: cellWeight, fillOpacity: cellOpacity, color: cellLineColor, dashArray: cellsDashArray })
                  return 0;
               })
               if (envType === 'hunting') {
                  // Lines
                  Object.values(this.huntingLinesLayer._layers).forEach(line => {
                     if (line.options.id === data.layer.options.id) {
                        // Set focus style parameters                        
                        line.setStyle({ opacity: lineSelectedOpacity, color: LightenDarkenColor(line.options.constColor, -30) });
                     } else {
                        line.setStyle({ opacity: lineNotSelectedOpacity, color: line.options.constColor })
                     }
                  })
               } else {
                  Object.values(this.huntingLinesLayer._layers).forEach(line => {
                     line.setStyle({ opacity: lineNotSelectedOpacity, color: line.options.constColor })
                  })
               }
               clickedCellId = data.layer.options.id;
            } else {
               return;
            }
            // Get relevant cell data from cells props
            let cData = [];
            if (envType === 'hunting') {
               cData = Object.values(this.props.huntingFeatures[this.props.selectedForest]).filter(cell => {
                  return cell.cellData.id === clickedCellId;
               })
            } else {
               cData = Object.values(this.props.cells[this.props.selectedForest]).filter(cell => {
                  return cell.cellData.id === clickedCellId;
               })
            }

            this.setState({ tempCellLatLngs: data.layer._latlngs });

            let geo = null;
            let area = '';
            let lineLength = '';
            if (cData[0].cellData.type === 'Line') {
               geo = convertNewCellToGeoJson(data.layer._latlngs, 'Line');
               area = cData[0].cellData.area;
               const geoJsonFeat = {
                  type: 'Feature',
                  geometry: {
                     type: geo.type,
                     coordinates: geo.coordinates,
                  },
                  properties: {}
               }
               lineLength = getLineLength(geoJsonFeat, { units: 'kilometers' }) * 1000;
               if (!("lineLength" in cData[0].cellData)) {
                  cData[0].cellData = { ...cData[0].cellData, lineLength: lineLength };
               }
               cData[0].cellData.lineLength = parseFloat(cData[0].cellData.lineLength);
            } else {
               geo = convertNewCellToGeoJson(data.layer._latlngs, 'Polygon');
               area = Math.round(geojsonArea.geometry(geo) / 10000 * 100) / 100;
            }
            // Load cell relevant data in sidebar and route
            if (cData[0]) {
               this.props.history.replace({
                  pathname: `/Cells/${clickedCellId}`,
                  state: {
                     data: cData[0].cellData,
                     geo: geo,
                     id: clickedCellId,
                  }
               });
            } else {
               // Route to new destination and pass on props
               this.props.history.push({
                  pathname: '/Cells/NewCell',
                  state: {
                     latlngs: data.layer._latlngs,
                     area: area,
                     geo: geo,
                  },
               });
            }
         }
         if (!this.props.sidebar) {
            this.props.onSidebarOpen(true)
         }
      } else {
         // TODO : Insert dialog box to inform user that the cell needs to be saved before proceeding

      }
   }

   onInsertCells = (data) => {
      if (this.props.newCellFinished) {
         // Onboarding action if active
         if (this.props.onboardingActive && this.props.onboardingState === 'drawCellCreate') {
            this.props.onSetOnboardingState('drawCellEnd');
         }
         // Insert feature into correct layer based on work environment
         // Default layer is forest
         let layer = this.production.features;
         if (this.props.chosenWorkEnvironment === 'hunting') {
            layer = this.huntingCellsLayer;
         } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
            layer = this.biotopePlan.features;
         }
         // Add feature to layer
         const routProps = addNewFeatureToLayer(data, layer, {}, { environment: this.props.chosenWorkEnvironment, enquireSnackBar: this.props.onEnqueueSnackbar })

         // Set flag indicating that new cell or line has not been finished
         this.props.onSetNewCellFinished(false);
         // Route to new destination and pass on props
         this.props.history.push({
            pathname: '/Cells/NewCell',
            state: {
               latlngs: routProps.latLngs,
               area: routProps.area,
               lineLength: routProps.lineLength,
               geo: routProps.geo,
            },
         });
         this.props.onSidebarOpen(true)
         this.setState({ tempCellLatLngs: routProps.latLngs });
      } else {
         // TODO : Insert dialog box to inform user that cell needs to be finished
      }
   }

   // General feature click handler
   featureClickHandler = (e) => {
      if (e.layer.options.type === 'Polygon' || e.layer.options.type === 'Line' || (e.layer.options.type === 'label' && e.layer.options.pane !== 'pointsPane')) {
         let d = { ...e };
         // If label refere the click to the parent polygon or line
         if (e.layer.options.parentLeafletId && e.layer.options.parentLeafletId !== '') {
            // Find feature
            d = { layer: e.layer._map._layers[e.layer.options.parentLeafletId] };
         }
         this.onCellsClickHandler(d);
      } else if (e.layer.options.type === 'Point' || (e.layer.options.type === 'label' && e.layer.options.pane === 'pointsPane')) {
         let point = null;
         if (e.layer.options.environment === 'forest') {
            point = this.props.points[this.props.selectedForest][e.layer.options.id];
         } else if (e.layer.options.environment === 'biotopePlan') {
            point = this.props.biotopePlanPoints[this.props.selectedBiotopePlan][e.layer.options.id];
         } else {
            console.error("Point environment not defined!")
            return;
         }
         this.onPointClickHandler(point);
      }
   }

   // Generl feature edit handler
   featureEditHandler = (e, geo) => {
      this.props.onSetCellEditableLatLng(geo);
      this.setState({ tempCellLatLngs: e.sourceTarget._latlngs });
   }

   // Insert all cells from backend on the map and associated polygon id to cell
   insertAllCellsOnMap = () => {
      if (this.props.cells) {
         if (!this.state.cellsInserted2) {
            // Reset arrays
            let speciesArray = [];
            let linesArray = [];

            let cellsArray = [];
            if (this.props.cells[this.props.selectedForest]) {
               cellsArray = Object.values(this.props.cells[this.props.selectedForest]).map(el => {
                  return el;
               }).flat()
            }

            // Remove polygons from tempLayer
            Object.values(this.tempLayer._layers).forEach(cell => {
               this.tempLayer.removeLayer(cell._leaflet_id);
            })
            // Remove all elements of the help layer
            Object.values(this.drawHelpLayer._layers).forEach(cell => {
               this.drawHelpLayer.removeLayer(cell._leaflet_id);
            })
            // Reset all layers
            resetLayer(this.production, {})
            resetLayer(this.hydroLayer, {});
            resetLayer(this.roadsTrails, {});
            resetLayer(this.biodiversityArea, {});
            resetLayer(this.intensiveArea, {});
            resetLayer(this.primevalForest, {});
            resetLayer(this.protectedNature, {});
            resetLayer(this.keyBiotope, {});
            resetLayer(this.naturalValuableForest, {});
            resetLayer(this.keyElements, {});
            resetLayer(this.public, {});
            resetLayer(this.other, {});
            // Insert cells on map
            cellsArray.forEach((cell) => {
               if (cell.cellData.type === 'Line') {
                  // Set lines array in state (used for e.g. print)
                  if (!linesArray.includes(cell.cellData.species)) {
                     linesArray.push(cell.cellData.species)
                  }
                  // Find layer to insert into
                  const layersArr = mapFeatureToLayer(cell);
                  // Run through all layers
                  layersArr.forEach(lay => {
                     addFeatureToLayer(cell, this.map, this[lay].features, this[lay].labels, {
                        featPane: "linesPane",
                        labelPane: "linesLabelsPane",
                        lineBufferPane: "linesBufferPane",
                        linePolyPadding: linePolyPadding,
                        toolTipPane: "toolTipPane",
                        lineOpacity: lineNotSelectedOpacity,
                        // bufferOpacity: lineOpacity === lineFilterNotSelectedOpacity ? lineFilterNotSelectedOpacity : 1,
                     }, {
                        withToolTip: this.props.polyToolTipActive,
                        withLineLabels: this.props.lineLabelsActive,
                        scheme: this.props.speciesScheme,
                        filterActive: this.props.filterShowFilterInMap,
                        filterIds: this.props.filterCellIds
                     })
                  })
               } else if (cell.cellData.type === 'Polygon') {
                  // Set species array in state (used for e.g. print)
                  if (!speciesArray.includes(cell.cellData.species)) {
                     speciesArray.push(cell.cellData.species)
                  }
                  // Find layers to insert feature into
                  const layersArr = mapFeatureToLayer(cell);
                  // Run through all layers and add feature
                  layersArr.forEach(lay => {
                     addFeatureToLayer(cell, this.map, this[lay].features, this[lay].labels, {
                        featPane: "polygonsPane",
                        labelPane: "polygonsLabelsPane",
                        toolTipPane: "toolTipPane",
                        fillOpacity: 0.9,
                        polygonLineDefaultColor: polygonNotSelectedColor,
                        polygonLineDefaultStyle: polygonNotSelectedDashArray,
                        labelsZoomLevel: cellLabelsZoomLevelSpcYear,
                     }, {
                        withToolTip: this.props.polyToolTipActive,
                        scheme: this.props.speciesScheme,
                        transparent: !this.props.cellsOpacityState,
                        darkTheme: !(this.props.ortoKfState || this.props.ortoKfTempState || this.props.ortoState || this.props.shadowSurfKfState),
                        filterActive: this.props.filterShowFilterInMap,
                        filterIds: this.props.filterCellIds
                     })
                  })
               }
            })
            // Check for in filter and bring to front
            if (this.props.filterCellIds) {
               this.map.eachLayer(lay => {
                  if (this.props.filterCellIds.includes(lay.options.id) && lay.options.type !== "label") {
                     lay.bringToFront();
                  }
               })
            }
            this.setState({ cellsInserted2: true, featClickId: null });
            this.props.onCellsRedraw();
         }
      }
   }

   // Insert all hunting features on map
   insertAllHuntingFeaturesOnMap = () => {
      if (this.props.huntingFeatures) {
         if (!this.state.huntingFeaturesInserted && this.props.huntingLayerState) {
            let featArray = [];
            if (this.props.huntingFeatures[this.props.selectedForest]) {
               featArray = Object.values(this.props.huntingFeatures[this.props.selectedForest]).map(el => {
                  return el;
               }).flat()
            }
            // Remove cells from the map before inserting them again
            Object.values(this.huntingCellsLayer._layers).forEach(cell => {
               this.huntingCellsLayer.removeLayer(cell._leaflet_id);
            })
            // Remove lines from the map before inserting them again
            Object.values(this.huntingLinesLayer._layers).forEach(line => {
               this.huntingLinesLayer.removeLayer(line._leaflet_id);
            })
            // Remove polygons from tempLayer
            Object.values(this.tempLayer._layers).forEach(cell => {
               this.tempLayer.removeLayer(cell._leaflet_id);
            })
            // Remove cell and line labels before inserting them again
            if (this.huntingCellsLayerLabels) {
               Object.values(this.huntingCellsLayerLabels._layers).forEach(cellLabel => {
                  this.huntingCellsLayerLabels.removeLayer(cellLabel._leaflet_id);
               })
            }
            if (this.huntingLinesLayerLabels) {
               Object.values(this.huntingLinesLayerLabels._layers).forEach(lineLabel => {
                  this.huntingLinesLayerLabels.removeLayer(lineLabel._leaflet_id);
               })
            }
            // Remove all elements of the help layer
            Object.values(this.drawHelpLayer._layers).forEach(cell => {
               this.drawHelpLayer.removeLayer(cell._leaflet_id);
            })
            // Insert cells on map
            featArray.forEach((cell, j) => {
               let polyCoords = [];
               let labelCoords = [];
               const lnglats = cell.cellData.lnglat.map((entry) => {
                  return entry;
               })

               let polygon = [];
               let polyForPoint = []
               for (let i = 0; i < lnglats.length / 2; i++) {
                  polygon[i] = { lat: lnglats[i * 2 + 1], lng: lnglats[i * 2] }
                  polyForPoint[i] = [lnglats[i * 2 + 1], lnglats[i * 2]];
               }
               polyCoords[0] = polygon;
               labelCoords[0] = polyForPoint;
               // Insert holes
               if (cell.cellData.holes) {
                  // Convert data to leaflet format
                  let len = Object.keys(cell.cellData.holes).length;
                  for (let i = 0; i < len; i++) {
                     let hole = [];
                     let holeForPoint = [];
                     const holeLngLats = cell.cellData.holes[i].map((entry) => {
                        return entry;
                     })
                     for (let j = 0; j < holeLngLats.length / 2; j++) {
                        hole[j] = { lat: holeLngLats[j * 2 + 1], lng: holeLngLats[j * 2] }
                        holeForPoint[j] = [holeLngLats[j * 2 + 1], holeLngLats[j * 2]];
                     }
                     polyCoords[i + 1] = hole;
                     labelCoords[i + 1] = holeForPoint;
                  }
               }
               // Form tooltip text
               const header = cell.cellData.type === 'Line' ? "<b>" + cell.cellData.litra + "</b><br>" : "<b>" + cell.cellData.litra + "</b><br>"
               const label = cell.cellData.type === 'Line' ? "<b>Type </b>" : "<b>Type </b>";
               let areaText = cell.cellData.area ? "<b>Areal </b>" + cell.cellData.area.toString().split('.').join(',') + " ha<br>" : "";
               if (cell.cellData.type === 'Line') {
                  areaText = cell.cellData.lineLength ? "<b>Længde </b>" + cell.cellData.lineLength.toFixed(0).toString().split('.').join(',') + " m<br>" : "";
               }
               const speciesText = cell.cellData.species ? label + cell.cellData.species + "<br>" : "";
               const yearText = cell.cellData.year ? "<b>Årstal (alder) </b>" + cell.cellData.year + " (" + (moment().format("YYYY") - cell.cellData.year) + ")<br>" : "";
               const toolTipText =
                  header +
                  areaText +
                  speciesText +
                  yearText;
               const type = cell.cellData.type === "Line" ? 'Line' : 'Polygon';
               let line;
               if (type === 'Line') {
                  if (this.props.polyToolTipActive) {
                     line = L.corridor(polyCoords, {
                        pane: "huntingLinesPane",
                        color: cell.cellData.color,
                        fillColor: cell.cellData.fillColor,
                        opacity: lineNotSelectedOpacity,
                        id: cell.cellData.id,
                        type: "Line",
                        lineCap: 'butt',
                        snapIgnore: true,
                        corridor: cell.cellData.weight,
                        dashArray: cell.cellData.dashStyle,
                        constColor: cell.cellData.color,
                     }).addTo(this.huntingLinesLayer).bindTooltip(
                        toolTipText, { pane: "toolTipPane", sticky: true }
                     );
                  } else {
                     line = L.corridor(polyCoords, {
                        pane: "huntingLinesPane",
                        color: cell.cellData.color,
                        fillColor: cell.cellData.fillColor,
                        opacity: lineNotSelectedOpacity,
                        id: cell.cellData.id,
                        type: "Line",
                        lineCap: 'butt',
                        snapIgnore: true,
                        corridor: cell.cellData.weight,
                        dashArray: cell.cellData.dashStyle,
                        constColor: cell.cellData.color,
                     }).addTo(this.huntingLinesLayer)
                  }
                  // Insert snappable polygon around line
                  const lineLatLngs = line.getLatLngs();
                  const geo = convertNewCellToGeoJson(lineLatLngs, 'Line');
                  const geoJsonFeat = {
                     type: 'Feature',
                     geometry: {
                        type: geo.type,
                        coordinates: geo.coordinates,
                     },
                     properties: {}
                  }
                  let buffered = buffer(geoJsonFeat, (0.001 * cell.cellData.weight * 1.15 + linePolyPadding), { units: 'kilometers', steps: 20 });
                  L.geoJson(buffered, {
                     pane: "linesBufferPane",
                     style: {
                        "color": 'transparent',
                        "weight": 1,
                        "fillOpacity": 1,
                        "opacity": 0,
                        "fillColor": '#F5F5F5',
                     },
                     snapIgnore: false,
                     lineCap: 'butt',
                     type: 'linePoly',
                  }).addTo(this.huntingLinesLayer);

                  // Insert line labels
                  let tempLabelCoordsLat = 0;
                  let tempLabelCoordsLng = 0;
                  for (let i = 0; i < polyCoords[0].length - 1; i = i + 6) {
                     tempLabelCoordsLat = (polyCoords[0][i].lat + polyCoords[0][i + 1].lat) / 2;
                     tempLabelCoordsLng = (polyCoords[0][i].lng + polyCoords[0][i + 1].lng) / 2;
                     L.marker([tempLabelCoordsLat, tempLabelCoordsLng], {
                        type: 'label',
                        pane: "linesLabelsPane",
                        icon: this.createLabelIcon("<span style=white-space:nowrap>" + cell.cellData.litra + "</span>"),
                        id: cell.cellData.id,
                        litraId: cell.cellData.litra,
                        snapIgnore: true,
                     }).addTo(this.huntingLinesLayerLabels)
                  }
               } else if (type === 'Polygon') {
                  // Check if polygons are transparent
                  let cellOpacity = huntingPolygonNotSelectedFillOpacity;
                  let cellLineColor = polygonNotSelectedColor;
                  let cellsDashArray = polygonNotSelectedDashArray;
                  let cellWeight = cell.cellData.weight;
                  let pane = "huntingPolygonsPane";
                  // Check label content
                  const idx = cell.cellData.species.indexOf("-");
                  let spcLabel = idx !== -1 ? cell.cellData.species.slice(-3) : cell.cellData.species;
                  if (spcLabel === "Såt") {
                     cellLineColor = "white";
                     cellsDashArray = polygonSelectedDashArray;
                     cellWeight = huntingSaatLineWeight;
                     cellOpacity = 0;
                     pane = "huntingSaatPane";
                  }
                  const yearLabel = idx !== -1 ? cell.cellData.year : "";
                  let labelContent = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>"
                     +
                     "<span style=white-space:nowrap>" + cell.cellData.litra + "</span><br>";
                  if (cell.cellData.litra !== spcLabel) {
                     labelContent = labelContent + "<b style=white-space:nowrap>" + spcLabel + "</b><br>";
                  }
                  labelContent = labelContent + "</span>";
                  // Check if polygon tooltip should be active
                  if (this.props.polyToolTipActive) {
                     L.polygon(polyCoords, {
                        pane: pane,
                        color: cellLineColor,
                        dashArray: cellsDashArray,
                        fillColor: cell.cellData.fillColor,
                        fillOpacity: cellOpacity,
                        weight: cellWeight,
                        id: cell.cellData.id,
                        type: "Polygon",
                        cellType: cell.cellData.species,
                     }).addTo(this.huntingCellsLayer).bindTooltip(
                        toolTipText, { pane: "toolTipPane", sticky: true }
                     );
                  } else {
                     L.polygon(polyCoords, {
                        pane: pane,
                        color: cellLineColor,
                        dashArray: cellsDashArray,
                        fillColor: cell.cellData.fillColor,
                        fillOpacity: cellOpacity,
                        weight: cellWeight,
                        id: cell.cellData.id,
                        type: "Polygon",
                        cellType: cell.cellData.species,
                     }).addTo(this.huntingCellsLayer)
                  }
                  // Insert cell labels and place labels
                  const labelPlacement = placeLabelInsidePoly(labelCoords, cell.cellData.bbox);
                  L.marker(labelPlacement, {
                     type: 'label',
                     environment: 'hunting',
                     pane: "polygonsLabelsPane",
                     icon: this.createLabelIcon(labelContent, 10.5),
                     id: cell.cellData.id,
                     litraId: cell.cellData.litra,
                     year: yearLabel,
                     spcLabel: spcLabel,
                     spcColor: cell.cellData.fillColor,
                     snapIgnore: true,
                  }).addTo(this.huntingCellsLayerLabels)
               }
            })
            this.setState({ huntingFeaturesInserted: true });
            this.props.onCellsRedraw();
         }
      }
   }

   insertAllBiotopePlanFeaturesOnMap = () => {
      if (this.props.biotopePlanFeatures) {
         if (!this.state.biotopePlanFeaturesInserted) {
            let cellsArray = [];
            if (this.props.biotopePlanFeatures[this.props.selectedBiotopePlan]) {
               cellsArray = Object.values(this.props.biotopePlanFeatures[this.props.selectedBiotopePlan]).map(el => {
                  return el;
               }).flat()
            }

            // Remove polygons from tempLayer
            Object.values(this.tempLayer._layers).forEach(cell => {
               this.tempLayer.removeLayer(cell._leaflet_id);
            })
            // Remove all elements of the help layer
            Object.values(this.drawHelpLayer._layers).forEach(cell => {
               this.drawHelpLayer.removeLayer(cell._leaflet_id);
            })
            // Remove all elements from layers 
            resetLayer(this.biotopePlan, {})

            // Insert cells on map
            cellsArray.forEach((cell) => {
               if (cell.cellData.type === 'Line') {
                  addFeatureToLayer(cell, this.map, this.biotopePlan.features, this.biotopePlan.labels, {
                     featPane: "linesPane",
                     labelPane: "linesLabelsPane",
                     lineBufferPane: "linesBufferPane",
                     linePolyPadding: linePolyPadding,
                     toolTipPane: "toolTipPane",
                     lineOpacity: lineNotSelectedOpacity,
                  }, {
                     withToolTip: this.props.polyToolTipActive,
                     withLineLabels: this.props.lineLabelsActive,
                     scheme: this.props.speciesScheme,
                     filterActive: this.props.filterShowFilterInMap,
                     filterIds: this.props.filterCellIds
                  })
               } else if (cell.cellData.type === 'Polygon') {
                  addFeatureToLayer(cell, this.map, this.biotopePlan.features, this.biotopePlan.labels, {
                     featPane: cell.cellData.species === "Afgrænsning" ? "polygonsTransparentPane" : "polygonsPane",
                     labelPane: "polygonsLabelsPane",
                     toolTipPane: "toolTipPane",
                     fillOpacity: 0.9,
                     polygonLineDefaultColor: polygonNotSelectedColor,
                     polygonLineDefaultStyle: polygonNotSelectedDashArray,
                     labelsZoomLevel: cellLabelsZoomLevelSpcYear,
                  }, {
                     withToolTip: this.props.polyToolTipActive,
                     scheme: this.props.speciesScheme,
                     transparent: !this.props.cellsOpacityState,
                     darkTheme: !(this.props.ortoKfState || this.props.ortoKfTempState || this.props.ortoState || this.props.shadowSurfKfState),
                     filterActive: this.props.filterShowFilterInMap,
                     filterIds: this.props.filterCellIds
                  })
               }
            })
            this.setState({ biotopePlanFeaturesInserted: true, featClickId: null });
            this.props.onCellsRedraw();
         }
      }
   }

   createLabelIcon = (labelText, fontSize) => {
      let fs = 10.5;
      if (fontSize) {
         fs = fontSize;
      }
      return L.divIcon({
         className: {},
         html: `<p style=color:#252525;font-size:${fs}px>` + labelText + "</p>",
         iconAnchor: [6, 18],
      })
   }
   createLabelIconMeas = (labelText) => {
      return L.divIcon({
         className: {},
         html: "<p style=color:#252525;font-size:10.5px;white-space:nowrap>" + labelText + "</p>",
         iconAnchor: [8, 8],
      })
   }
   createLabelIconPoint = (labelText, fontSize) => {
      let fs = 10.5;
      if (fontSize) {
         fs = fontSize;
      }
      return L.divIcon({
         className: {},
         html: `<p style=color:#252525;font-size:${fs}px>` + labelText + "</p>",
         iconAnchor: [10, -22],
      })
   }

   onEnableCellEditable = (id) => {
      // Find cell in cells layer
      let feat = [];
      if (id === undefined) { return }
      if (id === 'new') {
         feat = Object.values(this.map._layers).filter(layer => (layer.options.new === true));
      } else {
         feat = Object.values(this.map._layers).filter(layer => (layer.options.id === id && layer.options.type !== 'label'));
      }
      if (feat[0]) {
         feat[0].pm.enable({ allowSelfIntersection: feat[0].options.type === 'Polygon' ? false : true })
         // feat[0].pm.enable({ allowSelfIntersection: true })
         this.setState({ typeEditable: feat[0].options.type.toLowerCase() })
      }
   }

   onDisableCellEditable = (id) => {
      if (id === undefined) { return };
      // Find feature
      const feat = Object.values(this.map._layers).filter(layer => (layer.options.id === id && layer.options.type !== 'label'));
      if (feat[0]) {
         feat[0].pm.disable();
      }
      // Remove help layer
      Object.values(this.drawHelpLayer._layers).forEach(cell => {
         this.drawHelpLayer.removeLayer(cell._leaflet_id);
      })
      if (this.state.drawHelpCircles) {
         this.setState({ drawHelpCircles: false })
      }
      this.map.removeLayer(this.drawHelpLayer)
   }

   onEnablePointEditable = (id) => {
      let point = [];
      if (id === undefined) { return };
      if (id === 'new') {
         point = Object.values(this.map._layers).filter(layer => (layer.options.new === true));
      } else {
         point = Object.values(this.map._layers).filter(layer => (layer.options.id === id && layer.options.type !== 'label'));
      }
      if (point[0]) {
         // point[0].dragging.enable();
         point[0].pm.enable({ draggable: true })
         this.setState({ typeEditable: "point" });
      }
   }

   onDisablePointEditable = (id) => {
      const point = Object.values(this.map._layers).filter(layer => (layer.options.id === id && layer.options.type !== 'label'));
      if (point[0]) {
         point[0].pm.disable();
      }
   }

   onEnableNoteEditable = (id) => {
      let note = [];
      if (id === undefined) { console.warn("Editable note ID not defined"); return };
      if (id === "new") {
         note = Object.values(this.markersLayer._layers).filter(c => {
            return c.options.new === true;
         })
      } else {
         note = Object.values(this.markersLayer._layers).filter(c => {
            return c.options.id === id;
         })
      }
      if (note[0]) {
         if (!this.map.hasLayer(this.markersLayer)) {
            this.map.addLayer(this.markersLayer);
         }
         note[0].dragging.enable();
         this.setState({ typeEditable: "note" });
      }
   }

   onDisableNoteEditable = (id) => {
      let note = [];
      if (id === undefined) { console.warn("Editable note ID not defined"); return };
      if (id === "new") {
         note = Object.values(this.markersLayer._layers).filter(c => {
            return c.options.new === true;
         })
      } else {
         note = Object.values(this.markersLayer._layers).filter(c => {
            return c.options.id === id;
         })
      }
      if (note[0]) {
         if (!this.map.hasLayer(this.markersLayer)) {
            this.map.addLayer(this.markersLayer);
         }
         note[0].dragging.disable();
      }
   }

   // Walk polygon
   walkPosSuccess = (pos) => {
      // Apply filter to position
      const posOut = handlePosData(pos);
      // const lng = pos.coords.longitude;
      // const lat = pos.coords.latitude;
      if (this.state.walkLoading && this.state.walkCount > 5) {
         // Fit map to position
         this.map.flyToBounds([[posOut.lat, posOut.lng]], {
            animate: true,
            duration: 2,
            maxZoom: 18,
         });
         // Place first marker
         L.marker([posOut.lat, posOut.lng], {
            pane: "posMarkerPane",
            icon: mapIcons.posIconWalk,
            id: "walkMarker",
            snappable: false,
            snapIgnore: true,
         }).addTo(this.map)
         // Set loading finished
         this.setState({ walkLoading: false, walkCoords: [...this.state.walkCoords, posOut], walkTempLastPos: posOut });
      }

      // Calculate distance between last saved point and current position
      const pOne = L.latLng(posOut.lat, posOut.lng);
      const pTwo = L.latLng(this.state.walkTempLastPos.lat, this.state.walkTempLastPos.lng);
      const distToLastPoint = L.GeometryUtil.length([pOne, pTwo]);

      // --- ONLY FOR TESTING --- //
      // const timeDifSec = (pos.timestamp - this.state.walkTempTime)/1000;
      // const strTest = "Timestamp: " + pos.timestamp + "<br>Lat: " + lat + ", Lng: " + lng + "<br>Accuracy: " +
      //     pos.coords.accuracy + "<br>Altitude: " + pos.coords.altitude + ", Alt accuracy: " +
      //     pos.coords.altitudeAccuracy + "<br>Heading: " + pos.coords.heading + "<br>Speed: " + pos.coords.speed +
      //     "<br>Mov. Avg. Lat: " + posOut.lat + "<br>Mov. Avg. Lng: " + posOut.lng +
      //     "<br>Time dif in sec: " + timeDifSec +
      //     "<br>Distance to last point: " + distToLastPoint;
      // this.walkMarker.setLatLng([lat, lng]).setTooltipContent(strTest)
      // --- ONLY FOR TESTING --- //

      // Save new point to array of points
      if ((this.state.walkPolygonActive || this.state.walkLineActive) && !this.state.walkLoading && distToLastPoint > 4) {
         L.marker([posOut.lat, posOut.lng], {
            pane: "posMarkerPane",
            icon: mapIcons.posIconWalk,
            id: "walkMarker",
            snappable: false,
            snapIgnore: true,
         }).addTo(this.map)
         this.setState({ walkCoords: [...this.state.walkCoords, posOut], walkTempLastPos: posOut });
      }
      // Update state
      this.setState({ walkCount: this.state.walkCount + 1, walkTempTime: pos.timestamp })
   }

   walkPosError = (err) => {
      this.setState({ walkLoading: false, walkPolygonActive: false, walkLineActive: false })
      navigator.geolocation.clearWatch(this.walkId);
      this.props.onEnqueueSnackbar({
         message: 'Kunne ikke finde din position på nuværende tidspunkt, prøv igen senere!',
         options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
         },
      });
      console.warn('Find position error(' + err.code + '): ' + err.message);
   }

   handleClickWalk = (type) => {
      // Set options for geolocation
      const options = {
         enableHighAccuracy: true,
         timeout: 30000,
         maximumAge: 0
      };
      // Action if walk already active
      if (this.state.walkPolygonActive) {
         // Save new polygon
         if (this.state.walkCoords.length > 2) {
            const data = {
               shape: "Polygon",
               layer: {
                  options: {
                     color: "#009CDF",
                     fillColor: "#009CDF",
                     weight: 1.5,
                     type: "poly",
                  },
                  _latlngs: [this.state.walkCoords]
               }
            }
            this.onInsertCells(data);
         } else {
            this.props.onEnqueueSnackbar({
               message: 'Der er ikke registreret nok punkter til at forme en polygon!',
               options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'warning',
               },
            });
         }
         // Reset state
         navigator.geolocation.clearWatch(this.walkId);
         this.removeWalkMarkers()
         this.setState({
            walkPolygonActive: false,
            walkLineActive: false,
            walkCount: 0,
            walkCoords: [],
         })
      } else if (this.state.walkLineActive) {
         if (this.state.walkCoords.length > 1) {
            const data = {
               shape: "Line",
               layer: {
                  options: {
                     color: "#009CDF",
                     fillColor: "#009CDF",
                     weight: 1.5,
                     type: "line",
                  },
                  _latlngs: this.state.walkCoords
               }
            }
            this.onInsertCells(data);
         } else {
            this.props.onEnqueueSnackbar({
               message: 'Der er ikke registreret nok punkter til at forme en linje!',
               options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'warning',
               },
            });
         }
         // Reset state
         navigator.geolocation.clearWatch(this.walkId);
         this.removeWalkMarkers()
         this.setState({
            walkLineActive: false,
            walkPolygonActive: false,
            walkCount: 0,
            walkCoords: [],
         })
      } else {
         // Setup geolocation listner
         this.walkId = navigator.geolocation.watchPosition(this.walkPosSuccess, this.walkPosError, options);

         // --- ONLY FOR TESTING --- //
         // this.walkMarker = L.marker([0, 0], {
         //     icon: this.createLabelIcon(""),
         //     snapIgnore: true,
         //     id: "walkMarker"
         // }).addTo(this.map).bindTooltip("start", {
         //     pane: "toolTipPane",
         //     permanent: true,
         //     offset: L.point(0, 10),
         //     opacity: 0.8,
         // }).openTooltip()
         // --- ONLY FOR TESTING --- //

         // Throw snackbar
         this.props.onEnqueueSnackbar({
            message: 'Opmåling med GPS igangsat, vent et øjeblik.',
            options: {
               key: new Date().getTime() + Math.random(),
               variant: 'info',
            },
         });
         // Initiate state variables
         if (type === 'polygon') {
            this.setState({ walkPolygonActive: true, walkLoading: true, walkCount: 0, walkCoords: [], })
         } else {
            this.setState({ walkLineActive: true, walkLoading: true, walkCount: 0, walkCoords: [], })
         }
      }
   }

   removeWalkMarkers = () => {
      const walkMarkerId = Object.values(this.map._layers).filter(el => {
         return el.options.id === 'walkMarker'
      })
      if (walkMarkerId.length > 0) {
         walkMarkerId.map(mID => this.map.removeLayer(mID))
      }
   }

   //////////////////////////////////
   // componentDidMount()
   //////////////////////////////////
   componentDidMount() {
      if (!this.state.componentMounted) {
         this.setState({ componentMounted: true })
      }

      //  Workaround for 1px lines appearing in some browsers due to fractional transforms
      //  and resulting anti-aliasing. https://github.com/Leaflet/Leaflet/issues/3575
      //  INCREASED from 1 TO 2 px (2022-02-09)
      if (window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || window.navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
         const corPx = 2;
         var originalInitTile = L.GridLayer.prototype._initTile;
         L.GridLayer.include({
            _initTile: function (tile) {
               originalInitTile.call(this, tile);
               var tileSize = this.getTileSize();
               tile.style.width = tileSize.x + corPx + 'px';
               tile.style.height = tileSize.y + corPx + 'px';
            }
         });
      }
      // create map
      this.map = L.map("map", {
         tap: false,
         zoomControl: false,
         center: [56.26, 11.5],
         zoom: 7,
         editable: true,
         attributionControl: false,
         zoomDelta: 0.25,
         zoomSnap: 0.05,//0.25
         wheelPxPerZoomLevel: 25,
      });

      // Create panes for layers
      this.map.createPane("cadastralPane");
      // Create panes for markers, polygons, lines and other stuff - needed to control z-index of them
      this.map.createPane("polygonsPane");
      this.map.createPane("polygonsLabelsPane");
      this.map.createPane("polygonsTransparentPane");
      this.map.createPane("linesPane");
      this.map.createPane("linesLabelsPane");
      this.map.createPane("linesBufferPane");
      this.map.createPane("cellEditPane");
      // Hunting panes
      this.map.createPane("huntingSaatPane");
      this.map.createPane("huntingPolygonsPane");
      this.map.createPane("huntingLinesPane");
      this.map.createPane("toolTipPane");
      this.map.createPane("notesPane");
      this.map.createPane("forestMarkerPane");
      this.map.createPane("posMarkerPane");
      this.map.createPane("pointsPane");
      this.map.createPane("pointsCirclePane");
      // Set z index offset
      this.map.getPane("cadastralPane").style.zIndex = layerZidxOffset + 500;
      this.map.getPane("linesBufferPane").style.zIndex = layerZidxOffset + 48;
      this.map.getPane("polygonsTransparentPane").style.zIndex = layerZidxOffset + 49;
      this.map.getPane("polygonsPane").style.zIndex = layerZidxOffset + 50;
      this.map.getPane("linesPane").style.zIndex = layerZidxOffset + 51;
      this.map.getPane("huntingSaatPane").style.zIndex = layerZidxOffset + 52;
      this.map.getPane("huntingPolygonsPane").style.zIndex = layerZidxOffset + 53;
      this.map.getPane("huntingLinesPane").style.zIndex = layerZidxOffset + 54;
      this.map.getPane("pointsCirclePane").style.zIndex = layerZidxOffset + 55;
      this.map.getPane("polygonsLabelsPane").style.zIndex = layerZidxOffset + 56;
      this.map.getPane("linesLabelsPane").style.zIndex = layerZidxOffset + 57;
      this.map.getPane("notesPane").style.zIndex = layerZidxOffset + 58;
      this.map.getPane("pointsPane").style.zIndex = layerZidxOffset + 59;
      this.map.getPane("forestMarkerPane").style.zIndex = layerZidxOffset + 60;
      this.map.getPane("toolTipPane").style.zIndex = layerZidxOffset + 61;
      this.map.getPane("posMarkerPane").style.zIndex = layerZidxOffset + 62;
      this.map.getPane("cellEditPane").style.zIndex = layerZidxOffset + 63;

      // Browser Print --------------------------------------
      const printContainer = L.control.browserPrint({
         position: 'bottomleft',
         closePopupsOnPrint: false,
         printModes: ["Portrait", "Landscape", "Auto", "Custom"],
         manualMode: false
      }).addTo(this.map).getContainer();
      printContainer.style.display = 'none';

      // L.Control.BrowserPrint.Utils.registerLayer(
      //     L.StripePattern,
      //     'L.StripePattern',
      //     function (layer, utils) {
      //         return new L.StripePattern(layer.options);
      //     }
      // );

      window.print = () => {
         const forestData = {
            company: this.props.org ? this.props.org.name : '',
            forest: this.props.selectedForest !== null ? this.props.customerList[this.props.selectedForest].forestName : null,
            forestOwner: this.props.selectedForest !== null ? this.props.customerList[this.props.selectedForest].name : null,
            biotopePlan: this.props.selectedBiotopePlan !== null ? this.props.biotopePlansUser[this.props.selectedBiotopePlan].name : null,
            biotopePlanName: this.props.selectedBiotopePlan !== null ? this.props.biotopePlansUser[this.props.selectedBiotopePlan].planName : null
         }
         let quality = 1;
         if (this.state.printHighQuality) {
            quality = 3;
         }
         // Set print legend
         let legend = [];
         if (this.state.speciesArray.length > 0) {
            // Sort species array
            const sortedArr = [...this.state.speciesArray.sort()];
            let polyLabels = [];
            sortedArr.forEach(spc => {
               polyLabels.push({ name: spc, color: species[spc][this.props.speciesScheme].color, type: 'area' })
            })
            legend.push(polyLabels);
         }
         if (this.state.linesArray.length > 0) {
            // Sort lines array
            const sortedArr = [...this.state.linesArray.sort()];
            let lineLabels = [];
            sortedArr.forEach(line => {
               lineLabels.push({ name: line, color: species[line][this.props.speciesScheme].color, type: species[line][this.props.speciesScheme].style })
            })
            legend.push(lineLabels);
         }
         if (this.state.pointsArray.length > 0) {
            // Sort point array
            const sortedArr = [...this.state.pointsArray.sort()];
            let pointLabels = [];
            sortedArr.forEach(point => {
               if (species[point] && species[point].iconPath) {
                  pointLabels.push({ name: point, path: species[point].iconPath, type: "point" })
               }
            })
            if (pointLabels.length > 0) {
               legend.push(pointLabels);
            }
         }
         if (this.props.protectedNatureState) {
            legend.push(beskyttetNaturtyper);
         }
         if (this.props.natura2000State) {
            legend.push(natura2000);
         }
         if (this.props.fredskovState) {
            legend.push(fredskov);
         }
         if (this.props.cadastralOwnerState) {
            legend.push(ejerlav);
         }
         if (this.props.cadastralState) {
            legend.push(matrikel);
         }
         if (this.props.markblokkeState) {
            legend.push(markblokke);
         }
         if (this.props.protectedAncientAreasState) {
            legend.push(fortidsminder);
         }
         if (this.map && this.map.hasLayer(mapLayers.forestErection)) {
            legend.push(skovrejsning);
         }

         printHtmlToPdf(this.state.printOrientationGenerated, forestData, this.state.printScale, quality, legend, this.props.cellsOpacityState, this.props.config.printMap.printProfile, this.props.chosenWorkEnvironment);
      };

      // Print
      this.map.on("browser-print-init", e => {
         this.setState({ printOrientationGenerated: e.mode.Mode })
      })

      this.map.on("browser-pre-print", e => {
      })

      this.map.on("browser-print-start", e => {
         // Wait for tile layers to load
         if (e.printObjects["L.TileLayer.WMS"]) {
            Object.values(e.printObjects["L.TileLayer.WMS"]).forEach(async tileLayer => {
               await tileLayer.on('load', this.tileLoadHandling)
            })
         }

         // Find species, lines and points for legend
         let spcList = [];
         let lineList = [];
         let pointList = [];
         if (e.printObjects["L.Polygon"]) {
            Object.values(e.printObjects["L.Polygon"]).forEach(poly => {
               if (poly.options.mainSpecie && !spcList.includes(poly.options.mainSpecie)) {
                  spcList.push(poly.options.mainSpecie);
               }
               // Check if line weight is different from standard and remove line polygons
               if (this.state.printLineWeight !== minLineWeight) {
                  if (poly.options.type === "linePoly") {
                     e.printMap.removeLayer(poly);
                  }
               }
            });
         }
         // Add line species to legend and remove lines. We need to insert them again
         if (e.printObjects["L.Polyline"]) {
            Object.values(e.printObjects["L.Polyline"]).forEach(line => {
               if (line.options.mainSpecie && !lineList.includes(line.options.mainSpecie)) {
                  lineList.push(line.options.mainSpecie)
               }
               // Remove lines from print object
               e.printMap.removeLayer(line)
            })
         }
         // Run through all points/labels
         if (e.printObjects["L.Marker"]) {
            Object.values(e.printObjects["L.Marker"]).forEach(mrk => {
               // Set point type in print legend
               if (mrk.options.type === "Point" && !pointList.includes(mrk.options.pointType)) {
                  pointList.push(mrk.options.pointType);
               }
               // Remove labels if they are there. We need new ones
               if (mrk.options.type === 'label') {
                  e.printMap.removeLayer(mrk)
               }
            })
         }
         this.setState({ speciesArray: spcList, linesArray: lineList, pointsArray: pointList });

         // Remove tooltips
         Object.values(e.printMap._layers).forEach(lay => {
            lay.unbindTooltip();
         })
         // Moves lines in front of polygons and notes to the front
         e.printMap.getPane("linesBufferPane").style.zIndex = 499;
         e.printMap.getPane("polygonsPane").style.zIndex = 500;
         e.printMap.getPane("linesPane").style.zIndex = 505;
         e.printMap.getPane("huntingLinesPane").style.zIndex = 505;
         e.printMap.getPane("pointsCirclePane").style.zIndex = 506;
         e.printMap.getPane("polygonsLabelsPane").style.zIndex = 510;
         e.printMap.getPane("linesLabelsPane").style.zIndex = 510;
         e.printMap.getPane("pointsPane").style.zIndex = 511;
         e.printMap.getPane("notesPane").style.zIndex = 512;

         // Set font-size and position parameters based on zoom level
         e.printMap.on("zoomend", () => {
            // Check for selected poly
            let selectedPoly = '';
            const tokenizedString = this.props.history.location.pathname.split("/")
            if (tokenizedString[1] && tokenizedString[1] === "Cells") {
               selectedPoly = tokenizedString[2];
            }
            if (selectedPoly !== '') {
               Object.values(e.printMap._layers).forEach(lay => {
                  if (lay.options.id === selectedPoly) {
                     lay.bringToFront();
                  }
               })
            }
            // If line weight different from standard
            if (this.state.printLineWeight !== minLineWeight) {
               Object.values(e.printMap._layers).forEach(lay => {
                  if (lay.options.type === "Polygon") {
                     if (this.props.chosenWorkEnvironment === 'biotopePlan') {
                        if (lay.options.mainSpecie === "Afgrænsning" || !this.props.cellsOpacityState) {
                           lay.setStyle({ weight: this.state.printLineWeight })
                        }
                     } else {
                        lay.setStyle({ weight: this.state.printLineWeight })
                     }
                  }
               })
            }
            // If transparent buffer up polygon lines
            if (!this.props.cellsOpacityState) {
               Object.values(e.printMap._layers).forEach(lay => {
                  if (lay.options.type === "Polygon" && this.state.printLineWeight === minLineWeight) {
                     lay.setStyle({ weight: 3 })
                  }
                  // TODO : Hack for making polygons with patterns transparent. Solve when patterns on print is solved
                  if (lay.options.category === 'intensiveArea' || lay.options.category === 'biodiversityArea' || lay.options.category === 'primevalForest') {
                     lay.setStyle({ fillOpacity: 0 })
                  }
               })
            }
            // Make points larger
            if (e.printObjects['L.Marker']) {
               Object.values(e.printMap._layers).forEach(lay => {
                  if (lay.options.type === 'Point' || lay.options.type === 'Note') {
                     // Correct icon size and position based on zoom level
                     const zl = e.printMap.getZoom();
                     const iconSizeCor = zl / 10; // Before 1.4
                     const iconAnchorCor = zl / 10; // Before 1.9
                     // remove original layer
                     e.printMap.removeLayer(lay);
                     // Create new marker with new icon
                     L.marker(lay._latlng, {
                        icon: new L.Icon({
                           iconUrl: lay.options.icon.options.iconUrl,
                           iconSize: [lay.options.icon.options.iconSize[0] * iconSizeCor, lay.options.icon.options.iconSize[1] * iconSizeCor],
                           iconAnchor: [lay.options.icon.options.iconAnchor[0] * iconAnchorCor, lay.options.icon.options.iconAnchor[1] * iconAnchorCor]
                        }),
                        pane: lay.options.pane,
                     }).addTo(e.printMap)
                  }
               })
            }

            // Get label parameters
            const labelParam = printLabelParam(e.printMap.getZoom(), minFontSize, this.state.printFontSize);
            if (labelParam.fSize >= 2) {
               if (this.state.printSectionAndNumberLabelsActive || this.state.printSpeciesLabelsActive || this.state.printYearLabelsActive) {
                  // Set polygon labels if polygons are visible
                  if (e.printObjects["L.Marker"]) {
                     Object.values(e.printObjects["L.Marker"]).forEach(el => {
                        if (el.options.type === 'label' && el.options.pane === 'polygonsLabelsPane' && !el.options.environment) {
                           // Create label content
                           let labelTxt = `<span style=position:absolute;left:${labelParam.posLeft}px;top:${labelParam.posTop}px>`;
                           if (this.state.printSpeciesLabelsWhiteBackground) {
                              labelTxt = `<span style=position:absolute;left:${labelParam.posLeft}px;top:${labelParam.posTop}px;background-color:white;padding:3px;border-radius:5px;>`
                           }
                           if (this.state.printSectionAndNumberLabelsActive) {
                              labelTxt = labelTxt + "<span style=white-space:nowrap>" + el.options.litraId + "</span><br>";
                           }
                           if (this.state.printSpeciesLabelsActive && el.options.spcLabel !== '') {
                              labelTxt = labelTxt + "<b style=white-space:nowrap>" + el.options.spcLabel + "</b><br>";
                              if (el.options.category === 'intensiveArea') {
                                 labelTxt = labelTxt + "<span style=white-space:nowrap;font-style:italic>Intensiv drift</span><br>";
                              } else if (el.options.category === 'biodiversityArea') {
                                 labelTxt = labelTxt + "<span style=white-space:nowrap;font-style:italic>Biodiversitet</span><br>";
                              } else if (el.options.category === 'primevalForest') {
                                 labelTxt = labelTxt + "<span style=white-space:nowrap;font-style:italic>Urørt</span><br>";
                              }
                           }
                           if (this.state.printYearLabelsActive) {
                              labelTxt = labelTxt + el.options.year;
                           }
                           labelTxt = labelTxt + "</span>";
                           // Insert label on print 
                           L.marker(el._latlng, {
                              icon: this.createLabelIcon(labelTxt, labelParam.fSize),
                              pane: "polygonsLabelsPane",
                           }).addTo(e.printMap)
                        }
                     })
                  }
                  // Set hunting polygon labels if polygons are visible
                  if (this.props.huntingLayerState) {
                     // Insert labels for hunting points
                     Object.values(this.huntingPointsLayerLabels._layers).forEach(label => {
                        const labelContent = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>"
                           +
                           "<span style=white-space:nowrap>" + label.options.title + "</span><br>"
                           +
                           "<b style=white-space:nowrap>" + label.options.pointType + "</b></span>"

                        L.marker(label._latlng, {
                           pane: "polygonsLabelsPane",
                           icon: this.createLabelIconPoint(labelContent, labelParam.fSize),
                        }).addTo(e.printMap)
                     })
                     // Insert labels hunting polygons
                     Object.values(this.huntingCellsLayerLabels._layers).forEach(label => {
                        //let labelTxt = `<span style=position:absolute;left:${labelParam.posLeft}px;top:${labelParam.posTop}px>` + label.options.litraId + "<br><b>" + label.options.spcLabel + "</b><br>" + label.options.year + "</span>";
                        let labelTxt = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>"
                           +
                           "<span style=white-space:nowrap>" + label.options.litraId + "</span><br>"
                           +
                           "<b style=white-space:nowrap>" + label.options.spcLabel + "</b><br>"
                           +
                           label.options.year + "</span>"
                        L.marker(label._latlng, {
                           icon: this.createLabelIcon(labelTxt, labelParam.fSize),
                           pane: "polygonsLabelsPane",
                        }).addTo(e.printMap)
                     })
                     // Move selected polygon to top
                     let selectedPoly = '';
                     const tokenizedString = this.props.history.location.pathname.split("/")
                     if (tokenizedString[1] && tokenizedString[1] === "Cells") {
                        selectedPoly = tokenizedString[2];
                     }
                     if (selectedPoly !== '') {
                        Object.values(e.printMap._layers).forEach(lay => {
                           if (lay.options.id === selectedPoly) {
                              lay.bringToFront();
                           }
                        })
                     }

                  }
               }
               // Set point labels if layer is visible
               if (e.printObjects["L.Marker"]) {
                  Object.values(e.printObjects["L.Marker"]).forEach(point => {
                     if (point.options.type === 'Point' && point.options.pointType && point.options.title) {
                        let labelContent = "<span style=position:absolute;left:-4px;top:-4px;>" +
                           "<span style=white-space:nowrap>" + point.options.title + "</span><br>";
                        if (this.state.printSpeciesLabelsWhiteBackground) {
                           labelContent = "<span style=position:absolute;left:-4px;top:-4px;background-color:white;padding:3px;border-radius:5px;>" +
                              "<span style=white-space:nowrap>" + point.options.title + "</span><br>";
                        }
                        if (this.state.printSpeciesLabelsActive && point.options.pointType) {
                           labelContent = labelContent + "<b style=white-space:nowrap>" + point.options.pointType + "</b></span>"
                        }
                        labelContent = labelContent + "</span>";
                        L.marker(point._latlng, {
                           pane: "linesLabelsPane",
                           icon: this.createLabelIconPoint(labelContent, labelParam.fSize),
                        }).addTo(e.printMap)
                     }
                  })
               }

               // Insert lines and labels again
               if (e.printObjects["L.Polyline"]) {
                  // Lines related to forest
                  Object.values(e.printObjects["L.Polyline"]).forEach(line => {
                     let opt = { ...line.options }
                     if (this.state.printLineWeight === minLineWeight) {
                        delete opt.weight;
                     } else {
                        // Set line weight according to print setting
                        opt.corridor = this.state.printLineWeight;
                        // Also, insert new polygon under line
                        const geo = convertNewCellToGeoJson(line.getLatLngs(), 'Line');
                        const geoJsonFeat = {
                           type: 'Feature',
                           geometry: {
                              type: geo.type,
                              coordinates: geo.coordinates,
                           },
                           properties: {}
                        }
                        const buffered = buffer(geoJsonFeat, (0.001 * this.state.printLineWeight * 1.15 + linePolyPadding), { units: 'kilometers', steps: 20 });
                        const bufferPoly = L.geoJson(buffered, {
                           pane: "linesBufferPane",
                           style: {
                              "color": 'transparent',
                              "weight": 1,
                              "fillOpacity": 1,
                              "opacity": 0,
                              "fillColor": '#F5F5F5',
                           },
                           snapIgnore: false,
                           lineCap: 'butt',
                           type: 'linePoly',
                        })
                        // Add buffer to layer
                        bufferPoly.addTo(e.printMap);

                     }
                     L.corridor(line._latlngs, opt).addTo(e.printMap);
                  })
                  // Set line labels
                  if (this.state.printLineLabelsActive && e.printObjects["L.Marker"]) {
                     Object.values(e.printObjects["L.Marker"]).forEach(label => {
                        if (label.options.pane === "linesLabelsPane" && label.options.litraId) {
                           let labelTxt = `<span style=position:absolute;left:${labelParam.posLeft}px;top:${15 + labelParam.posTop}px;>` + label.options.litraId;
                           if (this.state.printSpeciesLabelsWhiteBackground) {
                              labelTxt = `<span style=position:absolute;left:${labelParam.posLeft}px;top:${15 + labelParam.posTop}px;background-color:white;padding:3px;border-radius:5px;>` + label.options.litraId;
                           }
                           if (this.props.chosenWorkEnvironment === 'biotopePlan' && this.state.printSpeciesLabelsActive && label.options.spcLabel !== '') {
                              labelTxt = labelTxt + "<br><b style=white-space:nowrap>" + label.options.spcLabel + "</b>";
                           }
                           labelTxt = labelTxt + "</span>";
                           L.marker(label._latlng, {
                              icon: this.createLabelIcon(labelTxt, labelParam.fSize),
                              pane: "linesLabelsPane",
                           }).addTo(e.printMap)
                        }
                     })
                  }
                  // Lines related to hunting
                  if (this.props.huntingLayerState) {
                     if (this.state.printLineLabelsActive) {
                        Object.values(this.huntingLinesLayerLabels._layers).forEach(label => {
                           const labelTxt = `<span style=position:absolute;left:${labelParam.posLeft}px;top:${15 + labelParam.posTop}px;> <span style=white-space:nowrap>` + label.options.litraId + '</span> </span>'
                           L.marker(label._latlng, {
                              icon: this.createLabelIcon(labelTxt, labelParam.fSize),
                              pane: "linesLabelsPane",
                           }).addTo(e.printMap)
                        })
                     }
                  }
               }
               // Lines and polygons related to measurement
               if (Object.values(this.measLayer._layers).length > 0) {
                  if (e.printObjects["L.Polyline"]) {
                     Object.values(e.printObjects["L.Polyline"]).forEach(lay => {
                        if (lay.options.type === 'measLine') {
                           let opt = { ...lay.options };
                           opt.pane = "linesPane";
                           L.polyline(lay._latlngs, opt).addTo(e.printMap)
                        }
                     })
                  }
                  if (e.printObjects["L.Polygon"]) {
                     Object.values(e.printObjects["L.Polygon"]).forEach(lay => {
                        if (lay.options.type === 'measPolygon') {
                           e.printMap.removeLayer(lay)
                           let opt = { ...lay.options };
                           opt.pane = "linesPane";
                           L.polygon(lay._latlngs, opt).addTo(e.printMap)
                           // lay.bringToFront();
                        }
                     })
                  }

               }
            }
            // Add other visual stuff
            // Scale
            const sc = L.control.scale({ metric: true, imperial: false }).addTo(e.printMap);
            const container = sc.getContainer();

            container.style.display = 'none';
            this.setState({ printScale: { rWidth: sc._mScale.style.width, rulerText: sc._mScale.innerHTML, sfText: scaleFactor(e.printMap, sc) } })
         })

      });

      this.map.on("browser-print-end", e => {
         this.props.onEnqueueSnackbar({
            message: 'Genererer PDF fil, vent venligst.',
            options: {
               key: new Date().getTime() + Math.random(),
               variant: 'info',
            },
         });
      });

      // Add control layer, scale, etc
      const scaleControl = L.control.scale({ metric: true, imperial: false }).addTo(this.map);
      const container = scaleControl.getContainer();
      container.style.position = 'absolute';
      container.style.bottom = '10px';
      container.style.fontSize = '11px';
      container.style.left = this.state.screenWidth <= (isTablet + 1) ? '10px' : '120px';
      container.style.padding = this.state.screenWidth <= (isTablet + 1) ? '4px' : '4px 4px 4px 0px';
      container.style.margin = '0px';
      container.style.borderRadius = this.state.screenWidth <= (isTablet + 1) ? '4px' : '0px 4px 4px 0px';
      container.style.backgroundColor = 'white';
      container.style.height = '25px';

      // Move relevant layer to top
      this.map.on("layeradd", e => {
         if (this.props.cadastralState) {
            mapLayers.cadastralLayer.bringToFront();
         }
         if (this.props.cadastralOwnerState) {
            mapLayers.cadastralOwnerGroupLayer.bringToFront()
         }
         if (this.props.markblokkeState) {
            mapLayers.markblokke.bringToFront()
         }
         if (this.props.protectedAncientAreasState) {
            mapLayers.protectedAncientAreas.bringToFront()
         }
         if (this.props.contourKfState) {
            if (this.map.getZoom() <= 17.5) {
               mapLayers.contourKfLayer_2_5_m.bringToFront();
            } else if (this.map.getZoom() > 17.5 && this.map.getZoom() < 18.5) {
               mapLayers.contourKfLayer_0_5_m.bringToFront();
            } else if (this.map.getZoom() >= 18.5) {
               mapLayers.contourKfLayer_0_25_m.bringToFront();
            }
         }
         if (this.map.hasLayer(mapLayers.forestErection)) mapLayers.forestErection.bringToFront();
      });

      // ---------------- NEW LAYER FORMAT ----------------- //
      // Forest production layer
      this.production = createLayer(this.map, {
         layerName: 'production',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Hydro (lake, spring, ...) layer
      this.hydroLayer = createLayer(this.map, {
         layerName: 'hydro',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Roads and trails layer
      this.roadsTrails = createLayer(this.map, {
         layerName: 'roadTrails',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Intensive managed areas (christmas trees) layer
      this.intensiveArea = createLayer(this.map, {
         layerName: 'intensiveArea',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Biodiversity areas layer
      this.biodiversityArea = createLayer(this.map, {
         layerName: 'biodiversityArea',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Primavel forest areas layer
      this.primevalForest = createLayer(this.map, {
         layerName: 'primevalForest',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Protected nature areas layer
      this.protectedNature = createLayer(this.map, {
         layerName: 'protectedNature',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Key biotpes layer
      this.keyBiotope = createLayer(this.map, {
         layerName: 'keyBiotope',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Natural valuable forest areas layer
      this.naturalValuableForest = createLayer(this.map, {
         layerName: 'naturalValuableForest',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Key elements layer
      this.keyElements = createLayer(this.map, {
         layerName: 'keyElements',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Public areas layer
      this.public = createLayer(this.map, {
         layerName: 'public',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Other ares (fields, parks, ...)
      this.other = createLayer(this.map, {
         layerName: 'other',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });
      // Biotoplans layer
      this.biotopePlan = createLayer(this.map, {
         layerName: 'biotopePlan',
         featSnapIgnore: false,
         labelsSnapIgnore: true,
         clickHandler: this.featureClickHandler,
         editHandler: this.featureEditHandler,
         vertexAddedHandler: this.onAddVLayerVertex,
         vertexRemovedHandler: this.onRemoveVertex,
         markerDragStartHandler: this.addCircleDragEventListner,
         markerDragEndHandler: this.removeCircleDragEventListner,
         pointDragStart: this.pointDragStart,
         pointDragEnd: this.pointDragEnd,
      });


      // Add forest markers layer
      this.forestMarkersLayer = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.forestMarkersLayer.on("click", e => {
         this.onForestMarkerClickHandler(e);
      })
      this.map.removeLayer(this.forestMarkersLayer)

      // Add markers feature layer
      this.markersLayer = L.featureGroup().addTo(this.map, { snapIgnore: true });
      // Add marker onClick handler
      this.markersLayer.on("click", e => {
         const ntsArray = Object.values(this.props.notes).map(el => {
            return el;
         }).flat();
         let noteMarker = ntsArray;
         noteMarker = noteMarker.filter((note) => {
            return (note.latlng.lat === e.latlng.lat && note.latlng.lng === e.latlng.lng)
         });
         this.onMarkerClickHandler(noteMarker, e.latlng);
      });
      this.map.removeLayer(this.markersLayer)

      // Add points feature layer
      this.pointsLayer = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.pointsLayerLabels = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.huntingPointsLayer = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.huntingPointsLayerLabels = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.huntingPointsLayer.on("click", e => {
         // Remove handler from circle click
         if (!e.layer._mRadius) {
            const point = this.props.huntingPoints[this.props.selectedForest][e.layer.options.id];
            this.onPointClickHandler(point);
         }
      })
      // this.map.removeLayer(this.pointsLayer)
      // this.map.removeLayer(this.pointsLayerLabels)
      this.map.removeLayer(this.huntingPointsLayer)
      this.map.removeLayer(this.huntingPointsLayerLabels)

      // Add cells, lines and measurement layers to the map
      this.cellsLayer = L.featureGroup().addTo(this.map);
      this.linesLayer = L.featureGroup().addTo(this.map);
      this.cellsLayerLabels = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.cellsLayerLabelsSpcYear = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.linesLayerLabels = L.featureGroup().addTo(this.map, { snapIgnore: true });

      // Hunting layers
      this.huntingCellsLayer = L.featureGroup().addTo(this.map);
      this.huntingLinesLayer = L.featureGroup().addTo(this.map);
      this.huntingCellsLayerLabels = L.featureGroup().addTo(this.map, { snapIgnore: true });
      this.huntingLinesLayerLabels = L.featureGroup().addTo(this.map, { snapIgnore: true });
      // Other layers
      this.measLayer = L.featureGroup().addTo(this.map);
      this.drawHelpLayer = L.featureGroup().addTo(this.map);
      this.tempLayer = L.featureGroup().addTo(this.map);
      this.convexHull = L.featureGroup().addTo(this.map);
      // Remove layers first time
      this.map.removeLayer(this.cellsLayer)
      this.map.removeLayer(this.linesLayer)

      this.map.removeLayer(this.huntingCellsLayer)
      this.map.removeLayer(this.huntingLinesLayer)
      this.map.removeLayer(this.huntingCellsLayerLabels)
      this.map.removeLayer(this.huntingLinesLayerLabels)
      this.map.removeLayer(this.drawHelpLayer)
      this.map.removeLayer(this.tempLayer)
      this.map.removeLayer(this.convexHull)
      // Forest layers
      this.measLayer.on("click", e => {
         this.onMeasClickHandler(e);
      })
      // Hunting layers
      this.huntingCellsLayer.on("click", e => {
         this.onCellsClickHandler(e, 'hunting');
      })
      this.huntingLinesLayer.on("click", e => {
         this.onCellsClickHandler(e, 'hunting');
      })
      // First add layers when zoomed to forest has finished
      this.map.once("zoomend", e => {
         if (this.props.notesState) {
            this.map.addLayer(this.markersLayer);
         }
         if (this.props.huntingLayerState) {
            this.map.addLayer(this.huntingCellsLayer);
            this.map.addLayer(this.huntingLinesLayer.bringToFront());
         }
         this.map.addLayer(this.forestMarkersLayer.bringToFront());
         this.forestMarkersLayer.bringToFront()

         // Other layers
         if (this.map.hasLayer(mapLayers.forestErection)) mapLayers.forestErection.bringToFront();
      });

      // Add remove layers dependent on zoom level
      this.map.on('zoomend', e => {
         // Set labels according to zoom level
         if (this.map.getZoom() > cellLabelsZoomLevel) {
            // Hunting layer
            if (this.props.huntingLayerState) {
               this.map.addLayer(this.huntingCellsLayerLabels.bringToFront());
               this.map.addLayer(this.huntingLinesLayerLabels.bringToFront());
            }
         } else {
            this.map.removeLayer(this.huntingCellsLayerLabels);
            this.map.removeLayer(this.huntingLinesLayerLabels);
         }

         // Note markers and points
         if (this.map.getZoom() > notesLabelsZoomLevel) {
            // Points
            if (this.map.hasLayer(this.production.features)) this.map.addLayer(this.production.points);
            if (this.map.hasLayer(this.hydroLayer.features)) this.map.addLayer(this.hydroLayer.points);
            if (this.map.hasLayer(this.roadsTrails.features)) this.map.addLayer(this.roadsTrails.points);
            if (this.map.hasLayer(this.biodiversityArea.features)) this.map.addLayer(this.biodiversityArea.points);
            if (this.map.hasLayer(this.intensiveArea.features)) this.map.addLayer(this.intensiveArea.points);
            if (this.map.hasLayer(this.primevalForest.features)) this.map.addLayer(this.primevalForest.points);
            if (this.map.hasLayer(this.protectedNature.features)) this.map.addLayer(this.protectedNature.points);
            if (this.map.hasLayer(this.keyBiotope.features)) this.map.addLayer(this.keyBiotope.points);
            if (this.map.hasLayer(this.naturalValuableForest.features)) this.map.addLayer(this.naturalValuableForest.points);
            if (this.map.hasLayer(this.keyElements.features)) this.map.addLayer(this.keyElements.points);
            if (this.map.hasLayer(this.public.features)) this.map.addLayer(this.public.points);
            if (this.map.hasLayer(this.other.features)) this.map.addLayer(this.other.points);
            if (this.map.hasLayer(this.biotopePlan.features)) this.map.addLayer(this.biotopePlan.points);

            // Notes
            if (this.props.notesState) {
               this.map.addLayer(this.markersLayer);
               this.forestMarkersLayer.bringToFront()
            }
            // Hunting
            if (this.props.huntingLayerState) {
               this.map.addLayer(this.huntingPointsLayer);
               if (this.map.getZoom() > cellLabelsZoomLevel) {
                  this.map.addLayer(this.huntingPointsLayerLabels.bringToFront());
               } else {
                  this.map.removeLayer(this.huntingPointsLayerLabels);
               }
            }
         } else {
            // Remove point layers
            this.map.removeLayer(this.production.points);
            this.map.removeLayer(this.hydroLayer.points);
            this.map.removeLayer(this.roadsTrails.points);
            this.map.removeLayer(this.biodiversityArea.points);
            this.map.removeLayer(this.intensiveArea.points);
            this.map.removeLayer(this.primevalForest.points);
            this.map.removeLayer(this.protectedNature.points);
            this.map.removeLayer(this.keyBiotope.points);
            this.map.removeLayer(this.naturalValuableForest.points);
            this.map.removeLayer(this.keyElements.points);
            this.map.removeLayer(this.public.points);
            this.map.removeLayer(this.other.points);
            // Remove notes and hunting point layers
            this.map.removeLayer(this.markersLayer);
            this.map.removeLayer(this.huntingPointsLayer);
            this.map.removeLayer(this.huntingPointsLayerLabels);
            // Remove biotope plan
            this.map.removeLayer(this.biotopePlan.points);
         }

         // Set coutour level
         if (this.props.contourKfState) {
            if (this.map.getZoom() <= 17.5) {
               this.map.addLayer(mapLayers.contourKfLayer_2_5_m);
               this.map.removeLayer(mapLayers.contourKfLayer_0_5_m);
               this.map.removeLayer(mapLayers.contourKfLayer_0_25_m);
            } else if (this.map.getZoom() > 17.5 && this.map.getZoom() < 18.5) {
               this.map.addLayer(mapLayers.contourKfLayer_0_5_m);
               this.map.removeLayer(mapLayers.contourKfLayer_2_5_m);
               this.map.removeLayer(mapLayers.contourKfLayer_0_25_m);
            } else if (this.map.getZoom() >= 18.5) {
               this.map.addLayer(mapLayers.contourKfLayer_0_25_m);
               this.map.removeLayer(mapLayers.contourKfLayer_2_5_m);
               this.map.removeLayer(mapLayers.contourKfLayer_0_5_m);
            }
         }

         // Scale factor
         this.setState({ scaleFactor: scaleFactor(this.map, scaleControl) })
      });

      // Add leaflet.pm controls with some options to the map
      this.map.pm.addControls({
         position: 'bottomleft',
         drawMarker: false,
         drawPolyline: false,
         drawRectangle: false,
         drawPolygon: false,
         drawCircle: false,
         editMode: false,
         dragMode: false,
         cutPolygon: false,
         removalMode: false,
         drawCircleMarker: false,
      });
      // Add custom language to text
      const customTranslation = {
         tooltips: {
            placeMarker: 'Indsæt ny note/punkt',
            firstVertex: "Klik for at placere første punkt",
            continueLine: "Klik for at placere næste punkt",
            finishPoly: "Klik på første punkt for at færdiggøre",
            finishLine: "Klik på vilkårligt punkt for at færdiggøre"
         },
      };

      this.map.pm.setLang('customName', customTranslation, 'en');

      // PM Event listener
      this.map.on('pm:create', e => {
         // Place note event listener
         if (e.shape === 'Marker' && !e.layer.options.measure && !this.state.drawingPoint) {
            // Fetch new note and add to markers layer
            this.map.removeLayer(e.layer) // Remove the marker inserted by pm
            this.onInsertNote(e);
            // Only set one marker a time
            this.map.pm.disableDraw('Marker');
         }
         if (e.shape === 'Marker' && this.state.drawingPoint) {
            // Remove pm marker and call insert point function
            this.map.removeLayer(e.layer) // Remove the marker inserted by pm
            this.onInsertPoint(e);
            // Only set one marker a time
            this.map.pm.disableDraw('Marker');
         }
         // Finished drawing event listener
         if ((e.shape === 'Poly' || e.shape === 'Polygon') && !e.layer.options.measure) {
            this.map.removeLayer(e.layer)
            this.onInsertCells(e);
            // Documentation says 'Polygon', but it is 'Poly'
            this.map.pm.disableDraw('Poly');
         }
         // Finished drawing event listener
         if (e.shape === 'Line' && !e.layer.options.measure) {
            this.map.removeLayer(e.layer)
            this.onInsertCells(e);
            this.map.pm.disableDraw('Line');
         }
         // Finished cut event listener
         if (e.shape === 'Cut') {
            this.map.pm.Draw.Cut.disable();
         }
         // Measurement finished event listner
         if (e.layer.options.measure) {
            this.map.removeEventListener('mousemove');
            this.measLayer.removeLayer(this.state.measVertexId);
            if (e.shape === "Line") {
               L.polyline(e.layer._latlngs,
                  {
                     color: '#13b8ff',
                     fillColor: '#13b8ff',
                     weight: 3,
                     id: this.state.measId,
                     type: 'measLine'
                  }).addTo(this.measLayer);
               this.map.removeLayer(e.layer)
            }
            else if (e.shape === "Polygon") {
               // Insert last line segment label
               L.polygon(e.layer._latlngs[0],
                  {
                     color: '#009CDF',
                     fillColor: '#009CDF',
                     weight: 3,
                     id: this.state.measId,
                     type: 'measPolygon'
                  }).addTo(this.measLayer);
               this.map.removeLayer(e.layer)
               const lineSegDist = L.GeometryUtil.length([e.layer._latlngs[0][0], e.layer._latlngs[0].slice(-1)[0]]);
               let labelText = "<span style=background-color:white;padding:3px;border-radius:5px;>" + lineSegDist.toFixed(2).toString().split('.').join(',') + " m</span>";
               const tempLat = (e.layer._latlngs[0][0].lat + e.layer._latlngs[0].slice(-1)[0].lat) / 2;
               const tempLng = (e.layer._latlngs[0][0].lng + e.layer._latlngs[0].slice(-1)[0].lng) / 2;
               L.marker([tempLat, tempLng], {
                  icon: this.createLabelIconMeas(labelText),
                  snapIgnore: true,
                  id: this.state.measId,
                  type: "measLabel",
               }).addTo(this.measLayer)
               // Insert area measurement label
               const geo = convertNewCellToGeoJson(e.layer._latlngs, 'Polygon');
               const area = Math.round(geojsonArea.geometry(geo) / 10000 * 100) / 100;
               const labelStr = "<span style=background-color:white;padding:3px;border-radius:5px;><b>Areal: </b>" + area.toFixed(2).toString().split('.').join(',') + " ha</span>"
               const areaLabelCoord = e.layer._latlngs[0].map(c => {
                  return [c.lat, c.lng];
               })
               L.marker(polylabel([areaLabelCoord], 0.5), {
                  icon: this.createLabelIconMeas(labelStr),
                  snapIgnore: true,
                  id: this.state.measId,
                  measure: true,
                  type: "measLabel",
               }).addTo(this.measLayer)

            }
            this.setState({
               measVertexId: null,
               measTotalLength: 0,
               measLastVertexLatLng: null,
               measuringInProgress: false
            });

            // Onboarding action if active
            if (this.props.onboardingActive && this.props.onboardingState === 'measCreate') {
               this.props.onSetOnboardingState('measEnd');
            }
         }
      });

      // Drawing ended event listner
      this.map.on('pm:drawend', e => {
         if (e.shape === 'Poly' || e.shape === 'Polygon') {
            this.setState({
               drawingPolygon: false,
               polygonReadySave: false
            })
            // Remove help layer event listner and circle
            this.map.removeEventListener('mousemove', this.circleFollowDrawEventListFcn);
            if (this.drawCirc) {
               this.map.removeLayer(this.drawCirc)
            }
            if (Object.keys(this.drawHelpLayer._layers).length < 3) {
               this.map.removeLayer(this.drawHelpLayer)
               this.setState({ drawHelpCircles: false })
            }
            // Documentation says 'Polygon', but it is 'Poly'
            this.map.pm.disableDraw('Poly');
         } else if (e.shape === 'Line') {
            this.setState({
               drawingLine: false,
               polygonReadySave: false
            })
            this.map.pm.disableDraw('Line');
         } else if (e.shape === 'Cut') {
            this.setState({
               cuttingPolygon: false,
               polygonReadySave: false,
               cutWorkLayerId: null
            })
            this.map.removeEventListener('mousemove', this.circleFollowDrawEventListFcn);

            if (this.drawCirc) {
               this.map.removeLayer(this.drawCirc)
            }
            if (Object.keys(this.drawHelpLayer._layers).length < 3) {
               this.map.removeLayer(this.drawHelpLayer)
               this.setState({ drawHelpCircles: false })
            }
            // this.map.pm.Draw.Cut.disable();
         }
         this.setState({ previousVertex: null })
      })

      this.huntingCellsLayer.on('pm:edit', e => {
         const geo = convertNewCellToGeoJson(e.sourceTarget._latlngs, 'Polygon');
         this.props.onSetCellEditableLatLng(geo);
         this.setState({ tempCellLatLngs: e.sourceTarget._latlngs });
      });

      this.huntingLinesLayer.on('pm:edit', e => {
         let geo;
         if (e.sourceTarget.options.id) {
            geo = convertNewCellToGeoJson(e.sourceTarget._latlngs, 'Line');
         } else {
            geo = convertNewCellToGeoJson([e.sourceTarget._latlngs], 'Line');
         }
         this.props.onSetCellEditableLatLng(geo);
         this.setState({ tempCellLatLngs: e.sourceTarget._latlngs });
      });

      // Act on cutting event
      this.map.on('pm:cut', e => {
         this.setState({
            cuttingPolygon: false,
         })
         if (e.layer.options.id === this.props.cellEditableId || (!e.layer.options.id && this.props.cellEditableId === 'new')) {
            Object.values(e.layer._layers).map(layer => {
               const geo = convertNewCellToGeoJson(layer._latlngs, 'Polygon');
               this.props.onSetCellEditableLatLng(geo);
               this.setState({ tempCellLatLngs: layer._latlngs });
               return 0;
            })
            // Remove old layer and add new remove id
            // try { this.cellsLayer.removeLayer(this.state.leafletLayerRemoveId) } catch { }
            this.setState({ leafletLayerRemoveId: e.layer._leaflet_id })
            // Make new layer editable and set eventlistners
            e.layer.pm.enable({ allowSelfIntersection: false });
            e.layer.on('pm:edit', e => {
               const geo = convertNewCellToGeoJson(e.sourceTarget._latlngs, 'Polygon');
               this.props.onSetCellEditableLatLng(geo);
               this.setState({ tempCellLatLngs: e.sourceTarget._latlngs });
            })
            e.layer.on('pm:markerdragstart', e => {
               this.addCircleDragEventListner(e);
            });
            e.layer.on('pm:markerdragend', e => {
               this.removeCircleDragEventListner(e);
            })
            e.layer.on('pm:vertexremoved', e => {
               this.onRemoveVertex(e);
            });
            // Ensure that layer is in front
         } else {
            // Remove the additional layer with hole
            e.layer.remove();
            // Re-insert the original polygon and push to back
            const tempPoly = L.polygon(e.originalLayer._latlngs, { ...e.originalLayer.options, pmIgnore: true }).addTo(this.tempLayer);
            tempPoly.bringToBack();
            this.map.addLayer(this.tempLayer);
         }
      })

      // Draw start events    
      this.map.on('pm:drawstart', ({ workingLayer }) => {
         if (workingLayer.options.measure) {
            this.setState({ measId: Math.floor(Math.random() * 1000) })
         }
         // Set flag indicating that it is the first point in a cell
         if (this.state.firstPointInCell) {
            this.setState({ firstPointInCell: false })
         }
         workingLayer.on('pm:vertexadded', e => {
            // Apply debouncing
            if (Date.now() - this.state.lastDebounceTime > debounceTimeLimit) {
               this.onAddVertex(e);
               this.setState({ lastDebounceTime: Date.now() })
            } else {
               switch (e.shape) {
                  case 'Polygon': this.map.pm.Draw.Polygon._removeLastVertex(); break;
                  case 'Line': this.map.pm.Draw.Line._removeLastVertex(); break;
                  case 'Cut': this.map.pm.Draw.Cut._removeLastVertex(); break;
               }
            }
         });
      });
   }

   // On add vertex function
   onAddVertex = (e) => {
      // Cutting only possible inside "focused" polygon 
      if (e.shape === "Cut") {
         if (!this.isPointInsidePolygon(e.latlng, this.state.tempCellLatLngs)) {
            this.map.pm.Draw.Cut._removeLastVertex();
            this.props.onEnqueueSnackbar({
               message: 'Det er ikke muligt at klippe uden for polygon!',
               options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'info',
               },
            });
         } else {
            // Add helping circle to drawing
            this.setState({ cutWorkLayerId: e.workingLayer._leaflet_id })
            L.circle(e.latlng, {
               color: 'black',
               fillColor: '#009CDF',
               fillOpacity: 0.3,
               weight: 0.5,
               radius: this.state.helpCirclesRadius,
            }).addTo(this.drawHelpLayer);
         }
      } else if (e.workingLayer.options.measure) {
         // Measurement events
         let lineSegDist = 0;
         let totalDist = 0;
         if (this.state.measLastVertexLatLng) {
            lineSegDist = L.GeometryUtil.length([this.state.measLastVertexLatLng, e.latlng]);
            totalDist = this.state.measTotalLength + lineSegDist;
            let labelText = "<span style=background-color:white;padding:3px;border-radius:5px 5px 5px 5px;>" + lineSegDist.toFixed(2).toString().split('.').join(',') + " m</span><br><span style=background-color:white;padding:3px;border-radius:5px 5px 5px 5px;>" + totalDist.toFixed(2).toString().split('.').join(',') + " m</span>";
            if (lineSegDist === totalDist || e.shape === "Polygon") {
               labelText = "<span style=background-color:white;padding:3px;border-radius:5px;>" + lineSegDist.toFixed(2).toString().split('.').join(',') + " m</span>"
            }
            // Add marker on center of line segment to map
            const tempLabelCoordLat = (this.state.measLastVertexLatLng.lat + e.latlng.lat) / 2;
            const tempLabelCoordLng = (this.state.measLastVertexLatLng.lng + e.latlng.lng) / 2;
            L.marker([tempLabelCoordLat, tempLabelCoordLng], {
               icon: this.createLabelIconMeas(labelText),
               snapIgnore: true,
               id: this.state.measId,
               type: "measLabel",
            }).addTo(this.measLayer)
         }
         // Add "fake" marker for dynamic tooltip
         let markTest = L.marker(e.latlng, {
            zIndexOffset: -100,
            icon: this.createLabelIcon(''),
            snapIgnore: true,
            snapDistance: 10,
            snappable: true,
            tooltips: false,
         }).addTo(this.measLayer).bindTooltip("<b>Længde: </b>0,00 m<br> <b>Total: </b>" + totalDist.toFixed(2).toString().split('.').join(',') + " m", {
            pane: "toolTipPane",
            permanent: true,
            offset: L.point(0, 10),
            opacity: 0.8,
         }).openTooltip()
         // Remove the marker from the previous vertex
         try { this.measLayer.removeLayer(this.state.measVertexId); }
         catch { }
         // Set state
         this.setState({
            measTotalLength: totalDist,
            measVertexId: markTest._leaflet_id,
            measLastVertexLatLng: e.latlng
         });
         this.map.addEventListener('mousemove', ev => {
            let newLineSegLen = L.GeometryUtil.length([e.latlng, ev.latlng]);
            let newTotalLineLen = this.state.measTotalLength + newLineSegLen;
            const strTest = "<b>Længde: </b>" + newLineSegLen.toFixed(2).toString().split('.').join(',') + " m<br> <b>Total: </b>" + newTotalLineLen.toFixed(2).toString().split('.').join(',') + " m";
            markTest.setLatLng(ev.latlng).setTooltipContent(strTest)
         });
      } else {
         // Drawing new cell or line
         if (e.shape === 'Polygon') {
            // Add helping circle to drawing
            L.circle(e.latlng, {
               color: 'black',
               fillColor: '#009CDF',
               fillOpacity: 0.3,
               weight: 0.5,
               radius: this.state.helpCirclesRadius,
            }).addTo(this.drawHelpLayer);
         }
         // Check if line segment of new polygon is going to intersect other polygons
         if (e.shape === 'Polygon' && e.workingLayer._latlngs.length > 1 && this.props.chosenWorkEnvironment === 'forest') {
            const lineSeg = e.workingLayer._latlngs.slice(-2);
            Object.values(this.map._layers).some(poly => {
               if (poly.options.type && poly.options.type === 'Polygon' && lineIntersectsPoly(lineSeg, poly._latlngs)) {
                  this.props.onEnqueueSnackbar({
                     message: 'Tjek om der er polygon overlap.',
                     options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'info',
                     },
                  });
                  return 0;
               }
            })
         }
         // Check if new cell or line is drawn faraway from selected forest
         if (this.props.forestBbox[this.props.selectedForest]) {
            // Check if distance is far away from forest bounding box
            const bbox = {
               minLng: this.props.forestBbox[this.props.selectedForest].bbox[0],
               maxLng: this.props.forestBbox[this.props.selectedForest].bbox[2],
               minLat: this.props.forestBbox[this.props.selectedForest].bbox[1],
               maxLat: this.props.forestBbox[this.props.selectedForest].bbox[3]
            }
            // Distance above 0.03 tricker warning
            const dist = distPointToBox(bbox, e.latlng)

            if (!this.state.firstPointInCell && dist > 0.03) {
               this.props.onEnqueueSnackbar({
                  message: 'Du er ved at tegne ny polygon over 2 km fra valgte skov!',
                  options: {
                     key: new Date().getTime() + Math.random(),
                     variant: 'warning',
                  },
               });
               this.setState({ firstPointInCell: true })
            }
         }
      }
   }

   onAddVLayerVertex = (e) => {
      L.circle(e.latlng, {
         color: 'black',
         fillColor: '#009CDF',
         fillOpacity: 0.3,
         weight: 0.5,
         radius: this.state.helpCirclesRadius,
      }).addTo(this.drawHelpLayer);
   }

   // On remove vertex function
   onRemoveVertex = (e) => {
      if (e.layer.options.type === 'Polygon') {
         if (this.state.drawHelpCircles) {
            // Set flag indicating if a ring of the polygon is empty, eg a hole. Used for removing additional help circles
            let checkCircs = false;
            e.layer._latlngs.forEach(latlngSet => {
               if (latlngSet.length === 0) {
                  checkCircs = true;
               }
            })
            // Remove circle from clicked vertex
            Object.values(this.drawHelpLayer._layers).forEach(circ => {
               let keepOnClick = true;
               let keepOnLast = true;
               const tol = 10e-10;
               const diffLat = Math.abs(e.marker._latlng.lat - circ._latlng.lat);
               const diffLng = Math.abs(e.marker._latlng.lng - circ._latlng.lng);
               if (diffLat < tol && diffLng < tol) {
                  keepOnClick = false;
               }
               // Check if a hole has been removed to remove the last help circle
               if (checkCircs) {
                  keepOnLast = false;
                  e.layer._latlngs.forEach(latlngSet => {
                     latlngSet.forEach(latlng => {
                        const _diffLat = Math.abs(latlng.lat - circ._latlng.lat);
                        const _diffLng = Math.abs(latlng.lng - circ._latlng.lng);
                        if (_diffLat < tol && _diffLng < tol) {
                           keepOnLast = true;
                        }
                     })
                  })
               }
               // Check if circle should be removed
               if (!keepOnClick || !keepOnLast) {
                  this.drawHelpLayer.removeLayer(circ._leaflet_id)
               }
            })
         }
      }
   }

   // Event listeners associated with circles drag, etc.
   addCircleDragEventListner = (e) => {
      // Find circle that fits with coordinates
      if (this.state.drawHelpCircles) {
         this.dragCirc = Object.values(this.drawHelpLayer._layers).filter(circ => {
            return circ._latlng.lat === e.markerEvent.target._latlng.lat && circ._latlng.lng === e.markerEvent.target._latlng.lng;
         })
         if (this.dragCirc.length > 0) {
            this.map.addEventListener('mousemove', this.dragCircleEventListener)
         }
      }
   }

   removeCircleDragEventListner = (e) => {
      this.setState({ tempCellLatLngs: e.sourceTarget._latlngs })
      this.map.removeEventListener('mousemove', this.dragCircleEventListener);
      // Remove circles and insert them again, to capture if something went wrong
      Object.values(this.drawHelpLayer._layers).forEach(cell => {
         this.drawHelpLayer.removeLayer(cell._leaflet_id);
      })
      // Insert circles again
      for (let i = 0; i < this.state.tempCellLatLngs.length; i++) {
         for (let j = 0; j < this.state.tempCellLatLngs[i].length; j++) {
            L.circle(this.state.tempCellLatLngs[i][j], {
               color: 'black',
               fillColor: '#009CDF',
               fillOpacity: 0.3,
               weight: 0.5,
               radius: this.state.helpCirclesRadius,
            }).addTo(this.drawHelpLayer);
         }
      }
   }

   dragCircleEventListener = (e) => {
      if (this.dragCirc[0]) {
         this.dragCirc[0].setLatLng(e.latlng)
      }
   }

   componentDidUpdate() {
      if (this.props.selectedBiotopePlan || this.props.selectedForest && this.map.hasLayer(this.forestMarkersLayer)) {
         this.map.removeLayer(this.forestMarkersLayer)
      }
      // Check if work environment is changed
      if (this.state.tempChosenWorkEnvironment !== this.props.chosenWorkEnvironment) {
         this.cancelDrawNewPolygon()
         if (this.props.chosenWorkEnvironment === 'forest' || this.props.chosenWorkEnvironment === 'hunting') {
            this.setState({ forestMarkersInserted: false, mapFittet: false })
         } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
            this.setState({ biotopePlanMarkersInserted: false, mapFittet: false })
         }
         this.setState({ tempChosenWorkEnvironment: this.props.chosenWorkEnvironment })
      }
      // Check if fly to note data has changed
      if (this.props.flyToNoteData !== this.state.tempFlyToNoteData) {
         if (this.props.flyToNoteData !== null) {
            this.navigateToNoteClickHandler(this.props.flyToNoteData);
         }
         this.setState({ tempFlyToNoteData: this.props.flyToNoteData })
      }
      // Set map layers
      if (this.props.simpleState !== this.state.tempSimpleState) {
         if (this.props.simpleState) {
            this.map.addLayer(mapLayers.simpleLayer);
            if (!this.props.cellsOpacityState) {
               this.setState({ cellsInserted2: false });
            }
         } else {
            this.map.removeLayer(mapLayers.simpleLayer);
         }
         this.setState({ tempSimpleState: this.props.simpleState })
      }
      // Orto layer visibility
      if (this.props.ortoState !== this.state.tempOrtoState) {
         if (this.props.ortoState) {
            this.map.addLayer(mapLayers.satelliteLayer);
            if (!this.props.cellsOpacityState) {
               this.setState({ cellsInserted2: false });
            }
         } else {
            this.map.removeLayer(mapLayers.satelliteLayer);
         }
         this.setState({ tempOrtoState: this.props.ortoState })
      }
      // Orto KF layer visibility
      if (this.props.ortoKfState !== this.state.tempOrtoKfState) {
         if (this.props.ortoKfState) {
            this.map.addLayer(mapLayers.ortoKfLayer);
            if (!this.props.cellsOpacityState) {
               this.setState({ cellsInserted2: false });
            }
         } else {
            this.map.removeLayer(mapLayers.ortoKfLayer);
         }
         this.setState({ tempOrtoKfState: this.props.ortoKfState })
      }
      // Orto KF temp layer visibility
      if (this.props.ortoKfTempState !== this.state.tempOrtoKfTempState) {
         if (this.props.ortoKfTempState) {
            this.map.addLayer(mapLayers.ortoKfTempLayer);
            if (!this.props.cellsOpacityState) {
               this.setState({ cellsInserted2: false });
            }
         } else {
            this.map.removeLayer(mapLayers.ortoKfTempLayer);
         }
         this.setState({ tempOrtoKfTempState: this.props.ortoKfTempState })
      }
      // Contour KF layer visibility
      if (this.props.contourKfState !== this.state.tempContourKfState) {
         if (this.props.contourKfState) {
            if (this.map.getZoom() <= 17.5) {
               this.map.addLayer(mapLayers.contourKfLayer_2_5_m);
            } else if (this.map.getZoom() > 17.5 && this.map.getZoom() < 18.5) {
               this.map.addLayer(mapLayers.contourKfLayer_0_5_m);
            } else if (this.map.getZoom() >= 18.5) {
               this.map.addLayer(mapLayers.contourKfLayer_0_25_m);
            }
         } else {
            this.map.removeLayer(mapLayers.contourKfLayer_2_5_m);
            this.map.removeLayer(mapLayers.contourKfLayer_0_5_m);
            this.map.removeLayer(mapLayers.contourKfLayer_0_25_m);
         }
         this.setState({ tempContourKfState: this.props.contourKfState })
      }
      // Shadow KF layer visibility
      if (this.props.shadowKfState !== this.state.tempShadowKfState) {
         if (this.props.shadowKfState) {
            this.map.addLayer(mapLayers.shadowKfLayer);
            if (!this.props.cellsOpacityState) {
               this.setState({ cellsInserted2: false });
            }
         } else {
            this.map.removeLayer(mapLayers.shadowKfLayer);
         }
         this.setState({ tempShadowKfState: this.props.shadowKfState })
      }
      if (this.props.shadowSurfKfState !== this.state.tempShadowSurfKfState) {
         if (this.props.shadowSurfKfState) {
            this.map.addLayer(mapLayers.shadowKfLayerSurf);
            if (!this.props.cellsOpacityState) {
               this.setState({ cellsInserted2: false });
            }
         } else {
            this.map.removeLayer(mapLayers.shadowKfLayerSurf);
         }
         this.setState({ tempShadowSurfKfState: this.props.shadowSurfKfState })
      }
      // Blue spot KF layer visibility
      if (this.props.blueSpotState !== this.state.tempBlueSpotLayer) {
         if (this.props.blueSpotState) {
            this.map.addLayer(mapLayers.blueSpotLayer);
            mapLayers.blueSpotLayer.bringToFront()
         } else {
            this.map.removeLayer(mapLayers.blueSpotLayer);
         }
         this.setState({ tempBlueSpotLayer: this.props.blueSpotState })
      }
      // Protected nature layer visibility
      if (this.props.protectedNatureState !== this.state.tempProtectedNatureLayer) {
         if (this.props.protectedNatureState) {
            this.map.addLayer(mapLayers.protectedNatureLayer);
            mapLayers.protectedNatureLayer.bringToFront()
         } else {
            this.map.removeLayer(mapLayers.protectedNatureLayer);
         }
         this.setState({ tempProtectedNatureLayer: this.props.protectedNatureState })
      }
      // Fredskov layer visibility
      if (this.props.fredskovState !== this.state.tempFredskovLayer) {
         if (this.props.fredskovState) {
            this.map.addLayer(mapLayers.fredskovLayer);
            mapLayers.fredskovLayer.bringToFront()
         } else {
            this.map.removeLayer(mapLayers.fredskovLayer);
         }
         this.setState({ tempFredskovLayer: this.props.fredskovState })
      }
      // Natura2000 layer visibility
      if (this.props.natura2000State !== this.state.tempNatura2000Layer) {
         if (this.props.natura2000State) {
            this.map.addLayer(mapLayers.natura2000HabitatLayer);
            mapLayers.natura2000HabitatLayer.bringToFront()
         } else {
            this.map.removeLayer(mapLayers.natura2000HabitatLayer);
         }
         this.setState({ tempNatura2000Layer: this.props.natura2000State })
      }
      // Set Note layer visibility
      if (this.props.notesState !== this.state.tempNotesState) {
         if (this.props.notesState && this.map.getZoom() > notesLabelsZoomLevel) {
            this.map.addLayer(this.markersLayer)
         } else {
            this.map.removeLayer(this.markersLayer)
         }
         this.setState({ tempNotesState: this.props.notesState })
      }
      // Set line layer visibility
      // if (this.props.lineLabelsActive !== this.state.tempLineLabelsShow) {
      //     this.setState({ tempLineLabelsShow: this.props.lineLabelsActive, printLineLabelsActive: this.props.lineLabelsActive, cellsInserted2: false })
      // }
      // Set point layer visibility
      // if (this.props.pointsState !== this.state.tempPointsLayerState) {
      //     if (this.props.pointsState) {
      //         this.map.addLayer(this.pointsLayer.bringToFront())
      //         if (this.map.getZoom() > cellLabelsZoomLevel) {
      //             this.map.addLayer(this.pointsLayerLabels.bringToFront())
      //         }
      //     } else {
      //         this.map.removeLayer(this.pointsLayer)
      //         this.map.removeLayer(this.pointsLayerLabels)
      //     }
      //     this.setState({ tempPointsLayerState: this.props.pointsState })
      // }
      // Set hunting layer visibality
      if (this.props.huntingLayerState !== this.state.tempHuntingLayerState) {
         // Insert points
         if (this.props.huntingLayerState && this.map.getZoom() > notesLabelsZoomLevel) {
            this.map.addLayer(this.huntingPointsLayer.bringToFront())
            if (this.map.getZoom() > cellLabelsZoomLevel) {
               this.map.addLayer(this.huntingPointsLayerLabels.bringToFront());
            }
         } else {
            this.map.removeLayer(this.huntingPointsLayer)
            this.map.removeLayer(this.huntingPointsLayerLabels)
         }
         if (this.props.huntingLayerState) {
            this.map.addLayer(this.huntingCellsLayer)
            this.map.addLayer(this.huntingLinesLayer.bringToFront())
            this.map.addLayer(this.huntingCellsLayerLabels.bringToFront())
            this.map.addLayer(this.huntingLinesLayerLabels.bringToFront())
         } else {
            this.map.removeLayer(this.huntingCellsLayer)
            this.map.removeLayer(this.huntingLinesLayer)
            this.map.removeLayer(this.huntingCellsLayerLabels)
            this.map.removeLayer(this.huntingLinesLayerLabels)
         }
         this.setState({ tempHuntingLayerState: this.props.huntingLayerState })
      }
      // Set Cadastral layer visibility
      if (this.props.cadastralState !== this.state.tempCadastralState) {
         if (this.props.cadastralState) {
            this.map.addLayer(mapLayers.cadastralLayer.setParams({ pane: "cadastralPane" }));
         } else {
            this.map.removeLayer(mapLayers.cadastralLayer);
         }
         this.setState({ tempCadastralState: this.props.cadastralState })
      }
      // Set Cadastral owner layer visibility
      if (this.props.cadastralOwnerState !== this.state.tempCadastralOwnerState) {
         if (this.props.cadastralOwnerState) {
            this.map.addLayer(mapLayers.cadastralOwnerGroupLayer);
         } else {
            this.map.removeLayer(mapLayers.cadastralOwnerGroupLayer);
         }
         this.setState({ tempCadastralOwnerState: this.props.cadastralOwnerState })
      }
      // Set Markblokke layer visibility
      if (this.props.markblokkeState !== this.state.tempMarkblokkeState) {
         if (this.props.markblokkeState) {
            this.map.addLayer(mapLayers.markblokke)
         } else {
            this.map.removeLayer(mapLayers.markblokke)
         }
         this.setState({ tempMarkblokkeState: this.props.markblokkeState })
      }
      // Set Proteced Ancient Areas layer visibility
      if (this.props.protectedAncientAreasState !== this.state.tempProtectedAncientAreasState) {
         if (this.props.protectedAncientAreasState) {
            this.map.addLayer(mapLayers.protectedAncientAreas)
         } else {
            this.map.removeLayer(mapLayers.protectedAncientAreas)
         }
         this.setState({ tempProtectedAncientAreasState: this.props.protectedAncientAreasState })
      }
      // Check if marker should be deleted
      if (this.props.deleteMarker) {
         this.onDeleteMarker(this.props.deleteMarkerLatLng);
      }
      // Check if cell should be deleted
      if (this.props.deleteCell) {
         this.setState({ cellsInserted2: false })
         this.setState({ biotopePlanFeaturesInserted: false })
         this.setState({ huntingFeaturesInserted: false })
         this.props.onSetDeleteCell(false);
      }
      // Check if note is made editable
      if (this.props.noteEditable !== this.state.tempNoteEditable) {
         if (this.props.noteEditable) {
            this.onEnableNoteEditable(this.props.noteEditableId);
         } else {
            this.onDisableNoteEditable(this.props.noteEditableId);
         }
         this.setState({ tempNoteEditable: this.props.noteEditable });
      }
      // Check if cell is made editable
      if (this.props.cellEditable !== this.state.tempCellEditable) {
         if (this.props.cellEditable) {
            this.onEnableCellEditable(this.props.cellEditableId, this.props.chosenWorkEnvironment);
         } else {
            this.onDisableCellEditable(this.props.cellEditableId, this.props.chosenWorkEnvironment);
         }
         this.setState({ tempCellEditable: this.props.cellEditable });
      }
      // Check if point is made editable
      if (this.props.pointEditable !== this.state.tempPointEditable) {
         if (this.props.pointEditable) {
            this.onEnablePointEditable(this.props.pointEditableId, this.props.chosenWorkEnvironment);
         } else {
            this.onDisablePointEditable(this.props.pointEditableId, this.props.chosenWorkEnvironment);
         }
         this.setState({ tempPointEditable: this.props.pointEditable });
      }
      // Check if markers should be inserted again or if forest has been selected
      if (this.props.selectedForest !== this.state.tempSelectedForest) {
         this.cancelDrawNewPolygon()
         // Applied when forest marker is selected from e.g., Kanban board
         if (this.state.cancelFitMapToForest) {
            this.map.removeLayer(this.forestMarkersLayer);
         }
         this.setState({
            noteMarkersInserted: false,
            pointsInserted: false,
            huntingPointsInserted: false,
            tempSelectedForest: this.props.selectedForest,
            cellsInserted2: false,
            huntingFeaturesInserted: false,
            mapFittet: this.state.cancelFitMapToForest ? true : false,
            cancelFitMapToForest: false,
         })
      }
      // Check on biotope plan
      if (this.props.selectedBiotopePlan !== this.state.tempSelectedBiotopePlan) {
         this.cancelDrawNewPolygon()
         // Applied when forest marker is selected from e.g., Kanban board
         if (this.state.cancelFitMapToForest) {
            this.map.removeLayer(this.forestMarkersLayer);
         }
         this.setState({
            noteMarkersInserted: false,
            biotopePlanPointsInserted: false,
            tempSelectedBiotopePlan: this.props.selectedBiotopePlan,
            biotopePlanFeaturesInserted: false,
            mapFittet: false,
            cancelFitMapToForest: false,
         })
      }
      if (this.props.notes) {
         const ntsArray = Object.values(this.props.notes).map(el => {
            return el;
         }).flat();
         if (ntsArray.length !== this.state.tempNotesLength) {
            this.setState({ noteMarkersInserted: false, tempNotesLength: ntsArray.length })
         }
         if (this.props.insertNotes) {
            this.setState({ noteMarkersInserted: false })
            this.props.onSetInsertNotesState(false);
         }
      }
      // Check if points should be inserted again or if forest has been selected
      if (this.props.points && this.props.points[this.props.selectedForest]) {
         const len = Object.keys(this.props.points[this.props.selectedForest]).length;
         if (len !== this.state.tempPointsLength) {
            this.setState({ pointsInserted: false, tempPointsLength: len })
         }
         if (this.props.redrawPoints) {
            this.setState({ pointsInserted: false })
            this.props.onSetRedrawPoints(false);
         }
      }
      if (this.props.biotopePlanPoints && this.props.biotopePlanPoints[this.props.selectedBiotopePlan]) {
         const len = Object.keys(this.props.biotopePlanPoints[this.props.selectedBiotopePlan]).length;
         if (len !== this.state.tempBiotopePlanPointsLength) {
            this.setState({ biotopePlanPointsInserted: false, tempBiotopePlanPointsLength: len })
         }
         if (this.props.redrawBiotopePlanPoints) {
            this.setState({ biotopePlanPointsInserted: false })
            this.props.onSetRedrawBiotopePlanPoints(false);
         }
      }
      // Check if hunting points should be inserted again or if forest has been selected
      if (this.props.huntingPoints && this.props.huntingPoints[this.props.selectedForest]) {
         const len = Object.keys(this.props.huntingPoints[this.props.selectedForest]).length
         if (len !== this.state.tempHuntingPointsLength) {
            this.setState({ huntingPointsInserted: false, tempHuntingPointsLength: len })
         }
         if (this.props.redrawHuntingPoints) {
            this.setState({ huntingPointsInserted: false })
            this.props.onSetRedrawHuntingPoints(false);
         }
      }
      if (this.props.huntingFeatures && this.props.selectedForest) {
         if (this.props.redrawHuntingFeatures) {
            this.setState({ huntingFeaturesInserted: false });
            this.props.onSetRedrawHuntingFeatures(false);
         }
      }
      if (this.props.biotopePlanFeatures && this.props.selectedBiotopePlan) {
         if (this.props.redrawBiotopePlanFeatures) {
            this.setState({ biotopePlanFeaturesInserted: false })
            this.props.onSetRedrawBiotopePlanFeatures(false);
         }
      }
      // Check if forest markers should be inserted again
      if (this.props.forestBbox) {
         if (Object.keys(this.props.forestBbox).length !== this.state.tempForestsLength) {
            this.setState({ forestMarkersInserted: false, tempForestsLength: Object.keys(this.props.forestBbox).length });
         }
         if (this.state.tempNotDisabledForestId !== this.props.notDisabledForestId) {
            this.setState({ forestMarkersInserted: false, tempNotDisabledForestId: this.props.notDisabledForestId });
         }
      }
      // Check if biotope plan markers should be inserted again
      if (Object.keys(this.props.biotopePlansUser)) {
         if (Object.keys(this.props.biotopePlansUser).length !== this.state.tempBiotopePlansLength) {
            this.setState({ biotopePlanMarkersInserted: false, tempBiotopePlansLength: Object.keys(this.props.biotopePlansUser).length });
         }
      }

      // Check if cells should be inserted again
      if (this.props.cells) {
         if (this.props.redrawCells && this.state.cellsInserted2) {
            this.setState({ cellsInserted2: false, biotopePlanFeaturesInserted: false })
         }
      }
      if (this.biotopePlanFeatures) {
         if (this.props.redrawCells) {
            this.setState({ cellsInserted2: false, biotopePlanFeaturesInserted: false })
         }
      }
      // Check if leaflet layer should be removed
      if (this.props.leafletLayerRemove) {
         if (this.state.leafletLayerRemoveId) {
            const layer = Object.values(this.map._layers).filter(lay => {
               return lay._leaflet_id === this.state.leafletLayerRemoveId
            })
            try {
               this.map.removeLayer(layer[0])
            } catch {
            }
         }
         this.props.onSetLeafletLayerRemove(false);
      }
      // Change cell opacity and focus if no cell selected
      if (this.state.lastBrowserLocation !== this.props.history.location.pathname) {
         const tokenizedString = this.props.history.location.pathname.split("/")
         if (tokenizedString[1] !== "Cells") {
            if (this.state.featClickId) {
               try {
                  // TODO : Fix this hack smartere
                  // Issue: When e.g. cutting a layer a new layer is added to map and the old is removed
                  // then when trying to resetStyle on the old layer it fails. Right now this is handled af all layers
                  // are inserted again instead.
                  resetFeatureStyle(this.state.featClickId, this.map, {}, {
                     transparent: !this.props.cellsOpacityState,
                     darkTheme: !(this.props.ortoKfState || this.props.ortoKfTempState || this.props.ortoState || this.props.shadowSurfKfState),
                     filterActive: this.props.filterShowFilterInMap,
                     filterIds: this.props.filterCellIds
                  });
               } catch (error) {
               }
               this.setState({ featClickId: null });
            }

            // Hunting layer
            Object.values(this.huntingCellsLayer._layers).map(cell => {
               let cellOpacity = huntingPolygonNotSelectedFillOpacity;
               let cellLineColor = polygonNotSelectedColor;
               let cellsDashArray = polygonNotSelectedDashArray;
               let cellWeight = 1;
               if (cell.options.cellType === "Såt") {
                  cellLineColor = "white";
                  cellsDashArray = polygonSelectedDashArray;
                  cellOpacity = 0;
                  cellWeight = huntingSaatLineWeight;
               }
               // Change style back to normal
               cell.setStyle({ weight: cellWeight, fillOpacity: cellOpacity, color: cellLineColor, dashArray: cellsDashArray })
               return 0;
            })
            Object.values(this.huntingLinesLayer._layers).map(line => {
               // Change style back to normal
               if (line.options.pane !== "linesBufferPane") {
                  line.setStyle({ opacity: lineNotSelectedOpacity, color: line.options.constColor })
               }
               return 0;
            })
         }
         this.setState({ lastBrowserLocation: this.props.history.location.pathname })
      }

      // Initiate onboarding if new user
      if (this.props.currentAuthUser != null) {
         if (!this.props.currentAuthUser.onboarded && !this.props.skipOnboarding) {
            this.props.onSetOnboardingActive(true)
         }
      }
   }

   handleClickOpen = () => {
      // Onboarding action if active
      if (this.props.onboardingActive && this.props.onboardingState === 'newNoteIntro') {
         this.props.onSetOnboardingState('newNoteCreate');
      }
      L.Marker.prototype.options.icon = mapIcons.DefaultIcon;
      this.map.pm.enableDraw('Marker', { pane: "notesPane", snappable: false });
      //this.map.pm.disableDraw('Polygon');
      this.setState({
         speedDialTopOpen: false,
      })
   };

   handleClickOpenList = () => {
      if (this.props.chosenWorkEnvironment === 'forest' || this.props.chosenWorkEnvironment === 'hunting') {
         this.props.onSetInventoryListActive(true);
      } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
         this.props.onSetBiotopePlanListActive(true);
      }
   };

   handleClickOpenKanbanBoard = () => {
      this.props.onSetKanbanBoardActive(true);
   }
   handleClickTutorial = () => {
      this.props.onSetOnboardingActive(true);
   }

   handleClickPrint = () => {
      let modeToUse = null

      switch (this.state.printOrientation) {
         case "Auto": modeToUse = L.control.browserPrint.mode.auto("TEST", printPaperSize); break;
         case "Custom": modeToUse = L.control.browserPrint.mode.custom("TEST", printPaperSize); break;
         case "Portrait": modeToUse = L.control.browserPrint.mode.portrait("TEST", printPaperSize); break;
         case "Landscape": modeToUse = L.control.browserPrint.mode.landscape("TEST", printPaperSize); break;
         default: modeToUse = L.control.browserPrint.mode.auto("TEST", printPaperSize); break;
      }

      this.map.printControl.print(modeToUse);

      // Send event to Analytics 
      ReactGA.event({
         category: 'Map',
         action: `printMap - ${this.state.printOrientation}`,
         label: `User: ${this.props.currentAuthUser.name}`
      });
   }

   removePositionMarker = () => {
      const posMarkerId = Object.values(this.map._layers).filter(el => {
         return el.options.id === 'posMarker'
      })
      if (posMarkerId.length > 0) {
         this.map.removeLayer(posMarkerId[0])
      }
   }

   posSuccess = (pos) => {
      // Check change in position
      const lng = pos.coords.longitude;
      const lat = pos.coords.latitude;
      // Check tolerance on new posiiton
      const diffLat = Math.abs(this.state.tempFindPositionCoords.lat - lat);
      const diffLng = Math.abs(this.state.tempFindPositionCoords.lng - lng);
      // Insert new marker and zoom to position if differences is above threshold
      if (diffLat > findPosThreshold || diffLng > findPosThreshold) {
         // Remove existing position marker
         this.removePositionMarker();
         // Place new marker
         L.marker([lat, lng], {
            pane: "posMarkerPane",
            icon: mapIcons.posIcon,
            id: "posMarker",
            snappable: false,
            snapIgnore: true,
            riseOnHover: true,
         }).addTo(this.map)

         if (this.state.findPosLoading) {
            // Fit map to position
            this.map.flyToBounds([[lat, lng]], {
               animate: true,
               duration: 2,
               maxZoom: 18,
            });
         }
         this.setState({ findPosLoading: false, tempFindPositionCoords: { lat: lat, lng: lng } })
      }
   }

   posError = (err) => {
      ReactGA.event({
         category: 'Map',
         action: `Find position error(${err.code}): ${err.message}`,
         label: `User: ${this.props.currentAuthUser.name}`
      });
      // Clear the position watch
      navigator.geolocation.clearWatch(this.id);
      this.setState({ findPosLoading: false, findPositionActive: false })
      this.props.onEnqueueSnackbar({
         message: 'Kunne ikke finde din position på nuværende tidspunkt, prøv igen senere!',
         options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
         },
      });
      console.warn('Find position error(' + err.code + '): ' + err.message);
   }

   handleClickFindMyPosition = () => {
      const options = {
         enableHighAccuracy: true,
         timeout: 30000,
         maximumAge: 0
      };
      this.setState({ speedDialTopOpen: false })

      if (this.state.findPositionActive) {
         ReactGA.event({
            category: 'Map',
            action: `Find position deactivated`,
            label: `User: ${this.props.currentAuthUser.name}`
         });
         navigator.geolocation.clearWatch(this.id);
         this.removePositionMarker();
         this.setState({
            findPositionActive: false,
            tempFindPositionCoords: { lat: 0, lng: 0 },
         })
      } else {
         ReactGA.event({
            category: 'Map',
            action: `Find position activated`,
            label: `User: ${this.props.currentAuthUser.name}`
         });
         this.id = navigator.geolocation.watchPosition(this.posSuccess, this.posError, options);
         this.setState({ findPositionActive: true, findPosLoading: true })
      }
   }

   handleClickFindAddress = () => {
      this.setState({
         speedDialTopOpen: false,
         findAddressOpen: this.state.findAddressOpen ? false : true,
      })
   }

   handleClickIntro = () => {
      this.setState({
         speedDialTopOpen: false,
         introActive: this.state.introActive ? false : true,
      })

      if (!this.props.onboardingActive) {
         this.handleClickTutorial()
      }
   }

   // Speeddial top functions
   onSpeedDialTopOpen = () => {
      this.setState({
         speedDialTopOpen: true,
         speedDialBottomOpen: false,

         mapLayerOpen: false,
         mapCustomerOpen: false,
         mapMeasurementOpen: false,
         mapDrawingOpen: false,
      })
   }

   onSpeedDialTopClose = () => {
      this.setState({
         speedDialTopOpen: false,
      })
   }

   // Speeddial bottom functions
   onSpeedDialBottomOpen = () => {
      this.setState({
         speedDialBottomOpen: true,
         speedDialTopOpen: false,
      })
   }

   onSpeedDialBottomClose = () => {
      this.setState({
         speedDialBottomOpen: false,
      })
   }

   handleClickMapLayer = () => {
      // Onboarding action if active
      if (this.props.onboardingActive && this.props.onboardingState === 'changeMap') {
         this.props.onSetOnboardingState('newCustomerIntro');
      }

      this.setState({
         speedDialBottomOpen: false,
         mapLayerOpen: this.state.mapLayerOpen ? false : true,

         mapCustomerOpen: false,
         mapSelectorOpen: false,
         mapMeasurementOpen: false,
         mapDrawingOpen: false,
      })
   }

   handleClickMapSelector = () => {
      this.setState({
         speedDialBottomOpen: false,
         mapSelectorOpen: this.state.mapSelectorOpen ? false : true,

         mapCustomerOpen: false,
         mapLayerOpen: false,
         mapMeasurementOpen: false,
         mapDrawingOpen: false,
      })
   }

   handleClickCustomer = () => {
      this.setState({
         speedDialBottomOpen: false,
         mapCustomerOpen: this.state.mapCustomerOpen ? false : true,

         mapLayerOpen: false,
         mapSelectorOpen: false,
         mapMeasurementOpen: false,
         mapDrawingOpen: false,
      })
   }

   handleClickMeasurement = () => {
      this.setState({
         speedDialBottomOpen: false,
         mapMeasurementOpen: this.state.mapMeasurementOpen ? false : true,

         mapLayerOpen: false,
         mapSelectorOpen: false,
         mapCustomerOpen: false,
         mapDrawingOpen: false,
      })
   }

   handleClickDrawing = () => {
      this.setState({
         speedDialBottomOpen: false,
         mapDrawingOpen: this.state.mapDrawingOpen ? false : true,

         mapLayerOpen: false,
         mapSelectorOpen: false,
         mapCustomerOpen: false,
         mapMeasurementOpen: false,
      })
   }

   zoomIn = () => {
      this.map.zoomIn();
      if (this.props.onboardingActive && this.props.onboardingState === 'changeMap') {
         this.props.onSetOnboardingState('newCustomerIntro');
      }
      // Send event to Analytics 
      ReactGA.event({
         category: 'Map',
         action: `zoomIn`,
         label: `User: ${this.props.currentAuthUser.name}`
      });
   };

   zoomOut = () => {
      this.map.zoomOut();
      if (this.props.onboardingActive && this.props.onboardingState === 'changeMap') {
         this.props.onSetOnboardingState('newCustomerIntro');
      }
      // Send event to Analytics 
      ReactGA.event({
         category: 'Map',
         action: `zoomOut`,
         label: `User: ${this.props.currentAuthUser.name}`
      });
   };

   setNewPoint = () => {
      this.setState({ drawingPoint: true })
      L.Marker.prototype.options.icon = mapIcons.pointIcon;
      this.map.pm.enableDraw('Marker', { pane: "pointsPane", snappable: false });
   }

   drawNewLine = () => {
      this.setState({
         drawingLine: true
      })

      this.map.pm.enableDraw('Line', {
         pathOptions: {
            // add leaflet options for polylines/polygons
            color: '#13b8ff',
            fillColor: '#13b8ff',
            weight: 1.5,
            type: 'line',
         },
         templineStyle: {
            // add leaflet options for polylines/polygons
            color: '#13b8ff',
            weight: 1.5,
            type: 'line',
         },
         hintlineStyle: {
            // add leaflet options for polylines/polygons
            color: '#13b8ff',
            dashArray: [5, 5],
            weight: 1.5,
            type: 'line',
         },
         // Adds tooltips
         tooltips: true,
         snapIgnore: true,
         finishOn: 'dblclick',
         allowSelfIntersection: true,
      });
      // Onboarding action if active
      if (this.props.onboardingActive && this.props.onboardingState === 'drawCellIntro') {
         this.props.onSetOnboardingState('drawCellCreate');
      }
      // Send event to Analytics        
      ReactGA.event({
         category: 'Drawing tool',
         action: `drawNewLine`,
         label: `User: ${this.props.currentAuthUser.name}`
      });

   }

   drawNewPolygon = () => {
      this.setState({
         drawingPolygon: true
      })

      // Documentation says 'Polygon', but it is 'Poly'
      this.map.pm.enableDraw('Poly', {
         pathOptions: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            fillColor: '#009CDF',
            weight: 1.5,
            type: 'poly',
         },
         templineStyle: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            weight: 1.5,
            type: 'poly',
         },
         hintlineStyle: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            dashArray: [5, 5],
            //weight: [1, 1],
            type: 'poly',
         },
         // Adds tooltips
         tooltips: true,
         snappable: true,
         snapDistance: 15,
         allowSelfIntersection: false,
      });
      // Onboarding action if active
      if (this.props.onboardingActive && this.props.onboardingState === 'drawCellIntro') {
         this.props.onSetOnboardingState('drawCellCreate');
      }
      // Send event to Analytics        
      ReactGA.event({
         category: 'Drawing tool',
         action: `drawNewCell`,
         label: `User: ${this.props.currentAuthUser.name}`
      });

   }

   cutPolygon = () => {
      this.setState({
         cuttingPolygon: true
      })
      // Documentation says 'Polygon', but it is 'Poly'
      this.map.pm.Draw.Cut.enable({
         pathOptions: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            fillColor: '#009CDF',
            weight: 1.5
         },
         templineStyle: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            weight: 1.5
         },
         hintlineStyle: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            dashArray: '5,5',
            weight: 1.5
         },
         // Adds tooltips
         tooltips: true,
         snappable: true,
         snapDistance: 5,
      });

      if (this.state.drawHelpCircles) {
         this.map.addEventListener('mouseover', this.circleStartDrawEventListFcn)
         this.map.addEventListener('mousemove', this.circleFollowDrawEventListFcn)
      }
   }

   cancelDrawNewPolygon = () => {
      // Documentation says 'Polygon', but it is 'Poly'
      this.map.pm.disableDraw('Poly');
      this.map.pm.Draw.Cut.disable();

      // Remove help layer
      Object.values(this.drawHelpLayer._layers).forEach(cell => {
         this.drawHelpLayer.removeLayer(cell._leaflet_id);
      })
      if (this.state.drawHelpCircles) {
         this.setState({ drawHelpCircles: false })
      }
      this.map.removeLayer(this.drawHelpLayer)
   }

   saveNewPolygon = () => {
      // Documentation says 'Polygon', but it is 'Poly'
      this.map.pm.disableDraw('Poly');
      this.map.pm.Draw.Cut.disable();
   }

   removeLastVertex = () => {
      // Remove last vertex
      if (this.map.pm.Draw.Polygon._enabled) {
         this.map.pm.Draw.Polygon._removeLastVertex();
         if (this.state.drawingPolygon) {
            this.drawHelpLayer.removeLayer(parseInt(Object.keys(this.drawHelpLayer._layers).slice(-1)[0]))
         }
      } else if (this.map.pm.Draw.Line._enabled) {
         this.map.pm.Draw.Line._removeLastVertex();
      } else if (this.map.pm.Draw.Cut._enabled) {
         this.map.pm.Draw.Cut._removeLastVertex();
         if (this.state.cuttingPolygon) {
            this.drawHelpLayer.removeLayer(parseInt(Object.keys(this.drawHelpLayer._layers).slice(-1)[0]))
         }
      }
   }

   measureNewDistance = () => {
      this.setState({ measuringInProgress: true })
      this.map.pm.enableDraw('Line', {
         pathOptions: {
            // add leaflet options for polylines/polygons
            color: '#13b8ff',
            fillColor: '#13b8ff',
            weight: 1.5,
            measure: true,
         },
         templineStyle: {
            // add leaflet options for polylines/polygons
            color: '#13b8ff',
            weight: 1.5,
            measure: true,
         },
         hintlineStyle: {
            // add leaflet options for polylines/polygons
            color: '#13b8ff',
            dashArray: '10',
            weight: 1.5,
            measure: true,
         },
         // Adds tooltips
         tooltips: false,
         snapDistance: 5,
         // finishOn: 'contextmenu'
      });
      // Onboarding action if active
      if (this.props.onboardingActive && this.props.onboardingState === 'measIntro') {
         this.props.onSetOnboardingState('measCreate');
      }
      // Send event to Analytics        
      ReactGA.event({
         category: 'Measurement tool',
         action: `measureNewDistance`,
         label: `User: ${this.props.currentAuthUser.name}`
      });
   }

   measureNewArea = () => {
      this.setState({ measuringInProgress: true })
      // this.map.pm.disableDraw
      this.map.pm.Draw.Polygon.disable();
      this.map.pm.enableDraw('Poly', {
         pathOptions: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            fillColor: '#009CDF',
            weight: 1.5,
            measure: true,
         },
         templineStyle: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            weight: 1.5,
            measure: true,
         },
         hintlineStyle: {
            // add leaflet options for polylines/polygons
            color: '#009CDF',
            dashArray: [5, 5],
            // weight: 1.5,
            measure: true,
         },
         // Adds tooltips
         tooltips: false,
         snapDistance: 10,
      });
      // Onboarding action if active
      if (this.props.onboardingActive && this.props.onboardingState === 'measIntro') {
         this.props.onSetOnboardingState('measCreate');
      }
      // Send event to Analytics        
      ReactGA.event({
         category: 'Measurement tool',
         action: `measureNewArea`,
         label: `User: ${this.props.currentAuthUser.name}`
      });
   }

   measureCancelNew = () => {
      this.map.removeEventListener('mousemove');
      if (this.state.measDelete) {
         this.setState({ measDelete: false });
      } else {
         this.map.pm.disableDraw('Poly');
         Object.values(this.measLayer._layers).map(l => {
            if (l.options.id === this.state.measId) {
               this.measLayer.removeLayer(l)
            }
            return 0;
         })
         this.measLayer.removeLayer(this.state.measVertexId);
         this.setState({
            measuringInProgress: false,
            measVertexId: null,
            measTotalLength: 0,
            measLastVertexLatLng: null,
         })
      }
   }

   measureRemoveLastVertex = () => {
      this.removeLastVertex()
   }

   measureDelete = () => {
      this.setState({ measDelete: true });
   }

   onMeasClickHandler = (e) => {
      if (this.state.measDelete) {
         Object.values(this.measLayer._layers).map(l => {
            if (l.options.id === e.layer.options.id) {
               this.measLayer.removeLayer(l)
            }
            return 0;
         })
         if (_.isEmpty(this.measLayer._layers)) {
            this.measureCancelNew()
         }
      }
   }

   circleFollowDrawEventListFcn = (e) => {
      if (this.drawCirc) {
         this.drawCirc.setLatLng(e.latlng)
      }
   }

   circleStartDrawEventListFcn = (e) => {
      this.drawCirc = L.circle(e.latlng, {
         color: 'black',
         fillColor: '#009CDF',
         fillOpacity: 0.3,
         weight: 0.5,
         radius: this.state.helpCirclesRadius,
      }).addTo(this.map)
      this.map.removeEventListener('mouseover', this.circleStartDrawEventListFcn)
   }

   setDrawHelpCirclesClickHandler = (radius) => {
      if (this.state.drawHelpCircles && this.state.helpCirclesRadius === radius) {
         this.map.removeLayer(this.drawHelpLayer)
         this.map.removeEventListener('mousemove', this.circleFollowDrawEventListFcn);
         if (this.drawCirc) {
            this.map.removeLayer(this.drawCirc)
         }
         this.setState({ drawHelpCircles: false });
      } else {
         // Insert circles on editable cell
         if (this.helpCirclesRadius !== radius) {
            this.setState({ helpCirclesRadius: radius });
            if (this.drawCirc) {
               this.map.removeLayer(this.drawCirc)
            }
         }
         // Add eventlistner on new polygon with help circles
         if (this.state.drawingPolygon || this.state.cuttingPolygon) {
            this.map.addEventListener('mouseover', this.circleStartDrawEventListFcn)
            this.map.addEventListener('mousemove', this.circleFollowDrawEventListFcn)
            Object.values(this.drawHelpLayer._layers).forEach(lay => {
               lay.setRadius(radius)
            })
         }
         if (this.props.cellEditable) {
            // Start by removing existing help circles
            Object.values(this.drawHelpLayer._layers).forEach(cell => {
               this.drawHelpLayer.removeLayer(cell._leaflet_id);
            })
            let cell = null;
            let tempLatLng = [];
            const tLay = Object.values(this.map._layers).filter(lay => {
               return lay._leaflet_id === this.state.leafletLayerRemoveId - 1;
            })

            if (tLay.length > 0) {
               cell = tLay[0];
               tempLatLng = [...cell._latlngs];
            } else if (this.props.cellEditableId === 'new' || this.props.cellEditableId === 'undefined') {
               cell = Object.values(this.map._layers).filter(c => {
                  return c.options.new === true;
               })
               cell = cell[0];
               tempLatLng = [...cell._latlngs];
            } else {
               cell = Object.values(this.map._layers).filter(cell => {
                  return cell.options.id === this.props.cellEditableId;
               });
               cell = cell[0];
               tempLatLng = [...cell._latlngs];
            }
            if (this.state.cuttingPolygon) {
               const cutInProgressCell = Object.values(this.map._layers).filter(lay => {
                  return lay._leaflet_id === this.state.cutWorkLayerId;
               })
               if (cutInProgressCell.length > 0) {
                  tempLatLng.push(cutInProgressCell[0]._latlngs)
               }
            }
            // insert circles
            for (let i = 0; i < this.state.tempCellLatLngs.length; i++) {
               for (let j = 0; j < this.state.tempCellLatLngs[i].length; j++) {
                  L.circle(this.state.tempCellLatLngs[i][j], {
                     color: 'black',
                     fillColor: '#009CDF',
                     fillOpacity: 0.3,
                     weight: 0.5,
                     radius: radius,
                  }).addTo(this.drawHelpLayer);
               }
            }
         }
         this.map.addLayer(this.drawHelpLayer)
         this.setState({ drawHelpCircles: true });
      }
   }

   warningAccepted = () => {
      if (this.state.farAwayWarning) {
         this.setState({ farAwayWarning: false })
      } else if (this.state.overlapDetected) {
         this.setState({ overlapDetected: false })
      }
   }

   cellDrawnFarAwayWarning = () => {
      const styleWarning = {
         backgroundColor: 'rgba(0, 0, 0, 0.75)',
         width: '100%',
         height: '100%',
         position: 'absolute',
         zIndex: 101,
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
      }
      const btns = [
         { label: 'OK', clicked: this.warningAccepted, icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
      ]
      return (
         <div style={styleWarning}>
            <DialogBox
               header={`OBS`}
               text={
                  "Du er ved at tegne et ny polygon, som er mere end 2 km væk fra de ydre rammer af den valgte skov."
               }
               onboarded={true}
               buttons={btns}
               onExit={this.warningAccepted} />
         </div>
      )
   }

   overlapDetectedWarning = () => {
      const styleWarning = {
         backgroundColor: 'rgba(0, 0, 0, 0.75)',
         width: '100%',
         height: '100%',
         position: 'absolute',
         zIndex: 101,
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
      }
      const btns = [
         { label: 'OK', clicked: this.warningAccepted, icon: <IconChevronRight />, iconLeft: false, color: 'primary' },
      ]
      return (
         <div style={styleWarning}>
            <DialogBox
               header={`OBS`}
               text={
                  "Du er ved at tegne et ny polygon, som overlapper et eller flere af de andre polygoner."
               }
               onboarded={true}
               buttons={btns}
               onExit={this.warningAccepted} />
         </div>
      )
   }

   // List row click handler
   inventoryListRowClick = (data) => {
      // Find relevant cell and call cells click handler
      let d = Object.values(this.map._layers).filter(el => el.options.id === data.id);
      // Zoom to cell
      if (d.length > 0) {
         const temp = { layer: d[0] };
         this.map.flyToBounds(d[0], {
            animate: true,
            duration: 2
         });
         this.onCellsClickHandler(temp);
      }
   }

   // Kanban board navigate to note click handler
   navigateToNoteClickHandler = (data) => {
      // Handle if forest has not been selested
      if (!this.props.selectedForest) {
         // Set state to cancel fit map to forest
         this.setState({ cancelFitMapToForest: true })
         // Select forest
         this.props.onSetForestMarkerClickId(data.forestId);
         // Open task in sidebar
         this.onMarkerClickHandler([data], data.latlng);
         // Zoom to task
         this.map.flyTo([data.latlng.lat, data.latlng.lng], 18)
      } else {
         // Open task in sidebar
         this.onMarkerClickHandler([data], data.latlng)
         // Zoom to task
         this.map.flyTo([data.latlng.lat, data.latlng.lng], 18)
      }
   }

   // Map layer toggle shown/not shown
   toggleMapLayer = (layer, state) => {
      toggleLayerVisibility(this.map, this[layer], state)
   }

   toggleMapWMSLayer = (layer, state) => {
      if (state) {
         this.map.addLayer(layer);
         layer.bringToFront()
      } else {
         this.map.removeLayer(layer);
      }
      this.setState({ ttvar: !this.state.ttvar })
   }

   // Handle click on print map high quality
   handleClickPrintHighQuality = () => {
      this.setState({ printHighQuality: !this.state.printHighQuality });
   }
   // Handle click on print map labels active
   handleClickPrintLabelsActive = (type) => {
      switch (type) {
         case "sectionAndNumber": this.setState({ printSectionAndNumberLabelsActive: !this.state.printSectionAndNumberLabelsActive }); break;
         case "species": this.setState({ printSpeciesLabelsActive: !this.state.printSpeciesLabelsActive }); break;
         case "year": this.setState({ printYearLabelsActive: !this.state.printYearLabelsActive }); break;
         case "background": this.setState({ printSpeciesLabelsWhiteBackground: !this.state.printSpeciesLabelsWhiteBackground })
      }
   }
   // Handle click on print map labels active
   handleClickPrintLineLabelsActive = () => {
      this.setState({ printLineLabelsActive: !this.state.printLineLabelsActive });
   }

   // Handle font-size on print map labels
   handleFontSize = (value) => {
      this.setState({ printFontSize: value })
   }

   // Handle print change line weight
   handleChangePrintLineWeight = (value) => {
      this.setState({ printLineWeight: value });
   }

   // Handle print orientation
   handlePrintOrientation = (event) => {
      this.setState({ anchorOrientationMenu: event.currentTarget });
   }

   handleClosePrintOrientation = () => {
      this.setState({ anchorOrientationMenu: null });
   }

   handleSelectOrientation = (orientation) => {
      this.setState({ printOrientation: orientation })

      this.handleClosePrintOrientation();
   }

   tileLoadHandling = (e) => {
      this.map.fireEvent('dataload', e);
   }

   render() {
      const { classes } = this.props;
      // Initialize map
      if (this.state.componentMounted) {
         this.insertAllMarkersOnMap();
         this.insertAllHuntingPointsOnMap();
         this.insertAllHuntingFeaturesOnMap();
         if (this.props.selectedForest === null && this.props.selectedBiotopePlan === null) {
            this.insertForestMarkers();
         }
         this.insertAllBiotopePlanFeaturesOnMap();
         this.insertAllBiotopePlanPointsOnMap();
         this.insertAllCellsOnMap();
         this.insertAllPointsOnMap();
         this.fitMapToForest();
      }

      // Check for new profile and start data collection flow
      let startProfile = false;
      if (this.props.currentAuthUser.newUser) {
         startProfile = true;
      }

      // Check for org sync on user (used by e.g., SLS)
      let showModal = false;
      let hasPayedSubscription = false;
      let mapsSynced = false;
      if (this.props.config.generalSettings.notSyncedModal === 'enabled' && this.props.reepayState && !this.props.currentAuthUser.newUser) {
         if (this.props.natureActive || this.props.operationActive) {
            hasPayedSubscription = true;
         }
         if (this.props.currentAuthUser.forests.length > 0) {
            mapsSynced = true;
         }
         if (!hasPayedSubscription || !mapsSynced) {
            showModal = true;
         }
      }

      // Check onboarding state and set zindex of components and decide state of some components
      let noteButtonColor = this.props.onboardingActive ? this.props.onboardigNewNoteBtnColor : 'white';
      let markerButtonDisable = (this.props.onboardingActive ? this.props.onboardingNewNoteDisable : false) || (this.props.config.newNote.noteNumber > 0 && this.props.numNotes >= this.props.config.newNote.noteNumber) || (this.props.chosenWorkEnvironment === 'biotopePlan');
      let noForestSelected = false;
      let noteToolTipText = "Ny note";
      const listButtonColor = (this.props.onboardingActive) ? '#F5F5F5' : 'white';
      const listButtonDisable = (this.props.onboardingActive) ? true : false
      let disableMeasDeleteIfEmpty = true;

      // Check if a forest to work on is selected
      if (this.props.selectedForest === null && this.props.selectedBiotopePlan === null || !this.props.newNoteFinished) {
         markerButtonDisable = true;
         noForestSelected = true;
         noteButtonColor = '#F5F5F5';
         noteToolTipText = "Ingen skov valgt"
      }

      // Check if some buttons should be disabled
      if (this.measLayer) {
         if (!_.isEmpty(this.measLayer._layers)) {
            disableMeasDeleteIfEmpty = false;
         }
      }

      // Check if Kanban Board icon should be visible
      let showKanban = true;
      let disableKanban = false;
      if (this.props.config.map.taskPlanningKanban === 'hidden') {
         showKanban = false;
      }

      const fabTopClassName = classNames(classes.fabTop, this.props.sidebar ? classes.fabTopMoveIn : classes.fabMoveOut);

      // Create icons and actions for mobile version
      let topActions = [
         // { icon: <ListIcon style={{ color: '#41842b' }} />, name: 'Bevoksningsliste', clickHandler: this.handleClickOpenList },
         { icon: <div className={classes.speedDialActionButtonsDisableBG}><SchoolIcon style={{ color: '#41842b' }} /></div>, name: 'Introduktion', clickHandler: this.handleClickIntro, disabled: this.props.onboardingActive },
         { icon: this.state.findPositionActive ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><NearMeIcon style={{ color: '#41842b' }} /></div>, name: 'Find min position', clickHandler: this.handleClickFindMyPosition, disabled: this.props.onboardingActive },
         { icon: (this.state.findAddressOpen && !this.props.onboardingActive) ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><SearchIcon style={{ color: '#41842b' }} /></div>, name: 'Find adresse', clickHandler: this.handleClickFindAddress, disabled: this.props.onboardingActive },
         { icon: <div className={classes.speedDialActionButtonsDisableBG}><CreateIcon style={{ color: '#41842b' }} /></div>, name: 'Ny note', clickHandler: this.handleClickOpen, disabled: markerButtonDisable || (this.props.onboardingActive && this.props.onboardingState !== 'newNoteIntro') }
      ];
      let bottomActions = [
         { icon: (this.state.mapLayerOpen && !this.props.onboardingActive) ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><LayersIcon style={{ color: '#41842b' }} /></div>, name: 'Kortdetaljer', clickHandler: this.handleClickMapLayer, disabled: this.props.onboardingActive && this.props.onboardingState !== 'changeMap' && this.props.onboardingState !== 'newCustomerIntro' },
         { icon: (this.state.mapDrawingOpen && !this.props.onboardingActive) ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><GestureIcon style={{ color: '#41842b' }} /></div>, name: 'Korttegner', clickHandler: this.handleClickDrawing, disabled: this.props.onboardingActive && this.props.onboardingState !== 'drawCellIntro' },
         { icon: this.state.mapMeasurementOpen ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><StraightenIcon style={{ color: '#41842b' }} /></div>, name: 'Måleværktøj', clickHandler: this.handleClickMeasurement, disabled: this.props.onboardingActive },
         { icon: this.state.mapSelectorOpen ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><MapIcon style={{ color: '#41842b' }} /></div>, name: 'Kortvælger', clickHandler: this.handleClickMapSelector, disabled: this.props.onboardingActive },
         { icon: (this.state.mapCustomerOpen && !this.props.onboardingActive) ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><NaturePeopleIcon style={{ color: '#41842b' }} /></div>, name: 'Skove', clickHandler: this.handleClickCustomer, disabled: this.props.onboardingActive && this.props.onboardingState !== 'customerSelectIntro' && this.props.onboardingState !== 'forestSelectIntro' },
      ];

      if (this.props.currentAuthUser.userRole === 'forestOwner') {
         topActions = [
            // { icon: <ListIcon style={{ color: '#41842b' }} />, name: 'Bevoksningsliste', clickHandler: this.handleClickOpenList },
            { icon: <div className={classes.speedDialActionButtonsDisableBG}><SchoolIcon style={{ color: '#41842b' }} /></div>, name: 'Introduktion', clickHandler: this.handleClickIntro, disabled: this.props.onboardingActive },
            { icon: this.state.findPositionActive ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><NearMeIcon style={{ color: '#41842b' }} /></div>, name: 'Find min position', clickHandler: this.handleClickFindMyPosition, disabled: this.props.onboardingActive },
            { icon: <div className={classes.speedDialActionButtonsDisableBG}><CreateIcon style={{ color: '#41842b' }} /></div>, name: 'Ny note', clickHandler: this.handleClickOpen, disabled: markerButtonDisable || (this.props.onboardingActive && this.props.onboardingState !== 'newNoteIntro') }
         ];
         bottomActions = [
            { icon: (this.state.mapLayerOpen && !this.props.onboardingActive) ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><LayersIcon style={{ color: '#41842b' }} /></div>, name: 'Kortdetaljer', clickHandler: this.handleClickMapLayer, disabled: this.props.onboardingActive && this.props.onboardingState !== 'changeMap' && this.props.onboardingState !== 'newCustomerIntro' },
            { icon: (this.state.mapDrawingOpen && !this.props.onboardingActive) ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><GestureIcon style={{ color: '#41842b' }} /></div>, name: 'Korttegner', clickHandler: this.handleClickDrawing, disabled: this.props.onboardingActive && this.props.onboardingState !== 'drawCellIntro' },
            { icon: this.state.mapMeasurementOpen ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><StraightenIcon style={{ color: '#41842b' }} /></div>, name: 'Måleværktøj', clickHandler: this.handleClickMeasurement, disabled: this.props.onboardingActive },
            { icon: this.state.mapSelectorOpen ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><MapIcon style={{ color: '#41842b' }} /></div>, name: 'Kortvælger', clickHandler: this.handleClickMapSelector, disabled: this.props.onboardingActive },
            { icon: (this.state.mapCustomerOpen && !this.props.onboardingActive) ? <CloseIcon style={{ color: '#808080' }} /> : <div className={classes.speedDialActionButtonsDisableBG}><NaturePeopleIcon style={{ color: '#41842b' }} /></div>, name: 'Skove', clickHandler: this.handleClickCustomer, disabled: this.props.onboardingActive && this.props.onboardingState !== 'customerSelectIntro' && this.props.onboardingState !== 'forestSelectIntro' },
         ];
      }

      // Check if legends should be active and form array
      let legendData = [];
      if (this.props.protectedNatureState) {
         legendData = [...beskyttetNaturtyper];
      }
      if (this.props.natura2000State) {
         legendData = [...legendData, ...natura2000];
      }
      if (this.map && this.map.hasLayer(mapLayers.forestErection)) {
         legendData = [...legendData, ...skovrejsning];
      }
      return (
         <div>
            {/* NORMAL VERSION */}
            <MediaQuery minDeviceWidth={isMobile + 1}>
               <MainMenu mobile={false} />

               <MapPrintMenu
                  environment={this.props.chosenWorkEnvironment}
                  listButtonColor={listButtonColor}
                  zIndex={this.props.onboardingPrintMapZidx}
                  highQuality={this.state.printHighQuality}
                  sectionAndNumberLabelsActive={this.state.printSectionAndNumberLabelsActive}
                  speciesLabelsActive={this.state.printSpeciesLabelsActive}
                  yearLabelsActive={this.state.printYearLabelsActive}
                  lineLabelActive={this.state.printLineLabelsActive}
                  whiteBackground={this.state.printSpeciesLabelsWhiteBackground}
                  onClickHighQuality={() => this.handleClickPrintHighQuality()}
                  onClickLabelsActive={(type) => this.handleClickPrintLabelsActive(type)}
                  onClickLineLabelsActive={() => this.handleClickPrintLineLabelsActive()}
                  onFontSize={(event, value) => this.handleFontSize(value)}
                  fontSizeValue={this.state.printFontSize}
                  minFontSize={minFontSize}
                  maxFontSize={maxFontSize}
                  onChangeLineWeight={(event, value) => this.handleChangePrintLineWeight(value)}
                  lineWeightValue={this.state.printLineWeight}
                  minLineWeight={minLineWeight}
                  maxLineWeight={maxLineWeight}
                  printOrientation={this.state.printOrientation}
                  onPrintOrientation={(event) => this.handlePrintOrientation(event)}
                  anchorEl={this.state.anchorOrientationMenu}
                  onClosePrintOrientation={() => this.handleClosePrintOrientation()}
                  onSelectOrientation={(orientation) => this.handleSelectOrientation(orientation)}
                  onPressPrint={() => this.handleClickPrint()}
                  listButtonDisable={listButtonDisable}
                  heavyLayers={this.state.tempOrtoKfState || this.state.tempOrtoKfTempState || this.state.tempShadowKfState || this.state.tempBlueSpotLayer || this.state.tempCadastralState || this.state.tempCadastralOwnerState || this.state.tempMarkblokkeState || this.state.tempProtectedNatureLayer || this.state.tempContourKfState}
               />

               <Tooltip title="Find min position" aria-label="Find min position">
                  <Fab
                     aria-label="FindPos"
                     size="small"
                     style={{
                        position: 'absolute',
                        left: '230px',
                        top: '10px',
                        backgroundColor: listButtonColor,
                        zIndex: this.props.onboardingFindPosZidx,
                     }}
                     onClick={this.handleClickFindMyPosition}
                     disabled={listButtonDisable}
                  >
                     {this.state.findPositionActive ? (this.state.findPosLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : <CloseIcon style={{ color: '#808080' }} />) : <NearMeIcon style={{ color: '#41842b' }} />}
                  </Fab>
               </Tooltip>

               <Tooltip title={noteToolTipText} aria-label="Ny note" placement="bottom">
                  <Fab
                     aria-label="Add"
                     size="small"
                     className={fabTopClassName}
                     style={{ zIndex: this.props.onboardingNewNoteBtnZidx, backgroundColor: noteButtonColor }}
                     onClick={this.handleClickOpen}
                     disabled={markerButtonDisable}
                  >
                     <CreateIcon style={{ color: '#41842b' }} />
                  </Fab>
               </Tooltip>

               {this.props.config.map.addressSearchBox === 'enabled' && <AddressSearchBox map={this.map} mobile={false} />}

               <Tooltip title="Introduktion" aria-label="Introduktion">
                  <Fab
                     aria-label="Tutorial"
                     size="small"
                     style={{
                        position: 'absolute',
                        left: '77px',
                        top: '10px',
                        backgroundColor: this.props.chosenWorkEnvironment === 'forest' ? this.props.onboardingBtnColor : '#F5F5F5',
                        zIndex: 1,
                     }}
                     onClick={this.handleClickTutorial}
                     disabled={this.props.onboardingActive}
                  >
                     <SchoolIcon style={{ color: '#41842b' }} />
                  </Fab>
               </Tooltip>

               <Tooltip title={this.props.chosenWorkEnvironment === 'biotopePlan' ? "Pointoversigt" : "Bevoksningsliste"} aria-label="Bevoksningsliste">
                  <Fab
                     aria-label="list"
                     size="small"
                     style={{
                        position: 'absolute',
                        left: '128px',
                        top: '10px',
                        backgroundColor: noteButtonColor,
                        zIndex: this.props.onboardingInvListZidx,
                     }}
                     onClick={this.handleClickOpenList}
                     disabled={listButtonDisable || noForestSelected}
                  >
                     <ListIcon style={{ color: '#41842b' }} />
                  </Fab>
               </Tooltip>

               {showKanban && <div>
                  {/* <div
                            style={{
                                position: 'absolute',
                                left: '281px',
                                top: '10px',
                                zIndex: 1,
                            }}
                        >
                            <Tooltip title="Prøv de nye funktioner til opgavestyring" aria-label="Opgavestyring-nyhed">
                                <Chip
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    label="Nyhed: Opgavetavlen"
                                    //avatar={<NewsIcon style={{ backgroundColor: 'transparent', color: 'white', marginRight: -6 }}/>}
                                    style={{ marginLeft: -51, marginTop: 50, backgroundColor: '#244C14', color: 'white' }}
                                />
                            </Tooltip>
                        </div> */}

                  <Tooltip title="Opgavetavle" aria-label="Opgavetavle">
                     <Fab
                        aria-label="tasklist"
                        size="small"
                        style={{
                           position: 'absolute',
                           left: '281px',
                           top: '10px',
                           backgroundColor: disableKanban ? '#F5F5F5' : listButtonColor,
                           zIndex: 1,
                        }}
                        onClick={this.handleClickOpenKanbanBoard}
                        disabled={disableKanban || listButtonDisable}
                     >
                        <AssignmentIcon style={{ color: '#41842b' }} />
                     </Fab>
                  </Tooltip>
               </div>}

               {this.map && <MapLayersMenu
                  newPoint={this.state.drawingPoint}
                  newLine={this.state.drawingLine}
                  newPoly={this.state.drawingPolygon}
                  mobile={false}
                  screenHeight={this.state.screenHeight}
                  toggleMapLayer={this.toggleMapLayer}
                  toggleMapWMSLayer={this.toggleMapWMSLayer}
               />}

               {this.props.currentAuthUser.forests && this.props.chosenWorkEnvironment !== 'biotopePlan' &&
                  <MapCustomersMenu
                     mobile={false}
                     tablet={this.state.screenWidth <= (isTablet + 1) ? true : false}
                     screenHeight={this.state.screenHeight}
                  />}

               {this.props.currentAuthUser.biotopePlans && this.props.chosenWorkEnvironment === 'biotopePlan' &&
                  <MapBiotopePlansMenu
                     mobile={false}
                     tablet={this.state.screenWidth <= (isTablet + 1) ? true : false}
                     screenHeight={this.state.screenHeight}
                  />}

               <MapSelectorMenu mobile={false} screenHeight={this.state.screenHeight} />

               <MapMeasurementMenu
                  onPressNewDistance={this.measureNewDistance.bind(this)}
                  onPressNewArea={this.measureNewArea.bind(this)}
                  onPressCancel={this.measureCancelNew.bind(this)}
                  onPressDelete={this.measureDelete.bind(this)}
                  onPressRemoveLastVertex={this.measureRemoveLastVertex.bind(this)}
                  disableCancel={!this.state.measuringInProgress && !this.state.measDelete}
                  undoDisabled={!this.state.measuringInProgress}
                  deleteDisabled={this.state.measDelete || disableMeasDeleteIfEmpty}
                  disabledDraw={this.state.measuringInProgress || this.state.measDelete}
                  deleteEnabled={this.state.measDelete}
                  mobile={false}
               />

               <MapDrawingMenu
                  onPressNewPoint={this.setNewPoint.bind(this)}
                  onPressNewLine={this.drawNewLine.bind(this)}
                  onPressNewPolygon={this.drawNewPolygon.bind(this)}
                  onPressCutPolygon={this.cutPolygon.bind(this)}
                  onPressCancelNewPolygon={this.cancelDrawNewPolygon.bind(this)}
                  onPressSaveNewPolygon={this.saveNewPolygon.bind(this)}
                  onPressRemoveLastVortex={this.removeLastVertex.bind(this)}
                  onPressDrawHelpCircles10={() => { this.setDrawHelpCirclesClickHandler(10) }}
                  onPressDrawHelpCircles20={() => { this.setDrawHelpCirclesClickHandler(20) }}
                  onPressWalkPolygon={() => this.handleClickWalk("polygon")}
                  onPressWalkLine={() => this.handleClickWalk("line")}
                  walkActive={this.state.walkPolygonActive || this.state.walkLineActive}
                  disabledDraw={(this.state.drawingPolygon || this.state.drawingLine || !this.props.newNoteFinished) || (this.props.cellEditable || !this.props.newCellFinished) || noForestSelected || (!this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest') || this.state.walkPolygonActive || this.state.walkLineActive || (!this.props.forestOwnerAllowChangeHunting && this.props.chosenWorkEnvironment === 'hunting') || (this.props.chosenWorkEnvironment === 'biotopePlan' && this.props.biotopePlansUser[this.props.selectedBiotopePlan] && this.props.biotopePlansUser[this.props.selectedBiotopePlan].locked)}
                  disabledWalkPoly={(this.state.drawingPolygon || this.state.drawingLine) || (this.props.cellEditable || !this.props.newCellFinished) || noForestSelected || (this.props.currentAuthUser.userRole === 'forestOwner' && !this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest') || this.state.walkLineActive || this.props.chosenWorkEnvironment !== 'forest' || (!this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest')}
                  disabledWalkLine={(this.state.drawingPolygon || this.state.drawingLine) || (this.props.cellEditable || !this.props.newCellFinished) || noForestSelected || (this.props.currentAuthUser.userRole === 'forestOwner' && !this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest') || this.state.walkPolygonActive || this.props.chosenWorkEnvironment !== 'forest' || (!this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest')}
                  disabledCut={(((!this.state.cuttingPolygon) && (this.props.cellEditable || !this.props.newCellFinished)) && this.props.newLineFinished) && this.state.typeEditable === 'polygon' && this.props.chosenWorkEnvironment !== 'hunting'}
                  disableCancel={!((this.state.drawingPolygon || this.state.drawingLine) && !this.state.polygonReadySave) && !this.state.cuttingPolygon}
                  undoDisabled={!((this.state.drawingPolygon || this.state.drawingLine) && !this.state.polygonReadySave) && !this.state.cuttingPolygon}
                  disabledDrawHelpCircles={noForestSelected || (!this.state.drawingPolygon && !this.props.cellEditable) || this.state.typeEditable === 'line'}
                  drawHelpCircles10={this.state.drawHelpCircles && this.state.helpCirclesRadius === 10}
                  drawHelpCircles20={this.state.drawHelpCircles && this.state.helpCirclesRadius === 20}
                  mobile={false}
                  selectedForest={this.props.selectedForest}
                  selectedBiotopePlan={this.props.selectedBiotopePlan}
                  biotopePlanLocked={this.props.biotopePlansUser[this.props.selectedBiotopePlan] && this.props.biotopePlansUser[this.props.selectedBiotopePlan].locked}
               />

               <MediaQuery minDeviceWidth={isTablet + 1}>
                  <Tooltip title="Zoom ind" aria-label="Zoom ind">
                     <Fab
                        size="small"
                        style={{
                           position: 'absolute',
                           left: '10px',
                           bottom: 60,
                           backgroundColor: 'white',
                           zIndex: this.props.onboardingZoomBtnZidx,
                        }}
                        aria-label="Zoomin"
                        onClick={this.zoomIn}
                     >
                        <AddIcon style={{ color: '#41842b' }} />
                     </Fab>
                  </Tooltip>

                  <Tooltip title="Zoom ud" aria-label="Zoom ud">
                     <Fab
                        size="small"
                        style={{
                           position: 'absolute',
                           left: '10px',
                           bottom: 10,
                           backgroundColor: 'white',
                           zIndex: this.props.onboardingZoomBtnZidx,
                        }}
                        aria-label="Zoomout"
                        onClick={this.zoomOut}
                     >
                        <RemoveIcon style={{ color: '#41842b' }} />
                     </Fab>
                  </Tooltip>
               </MediaQuery>

               {(this.props.onboardingActive && !startProfile && !showModal) && <Onboarding tablet={this.state.screenWidth <= (isTablet + 1) ? true : false} />}

               {startProfile && <Profile />}

               {/* {(!this.props.subscriptionActive && this.props.reepayState !== "not fetched") && <SubscriptionReactivate open={true} mobile={false} />} */}

               {/* {(!this.props.subscriptionActive && this.props.reepayState === "not fetched") && <SubscriptionUnaccesable open={true} mobile={false} />} */}

               {/* <TextLineMenu
                        mapNoteDisable={this.props.selectedForest === null ? true : false}
                        onPressNewLine={this.drawTextLineNewLine.bind(this)}
                    /> */}

               <MediaQuery minDeviceWidth={isTablet + 1}>
                  <div
                     style={{
                        position: "absolute",
                        left: 60,
                        bottom: 10,
                        zIndex: 1,
                        backgroundColor: 'white',
                        padding: '4px 0px 4px 6px',
                        borderRadius: '4px 0px 0px 4px',
                        width: 60,
                        height: 25,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                     }}
                  >
                     <div
                        style={{
                           fontSize: 11,
                           fontFamily: 'sans-serif',
                           color: '#333333',
                           fontWeight: 400,
                           paddingRight: 4,
                           marginBottom: -1
                        }}>
                        {this.state.scaleFactor}
                     </div>
                     <div style={{ paddingLeft: 6, width: '100%', height: 2, backgroundColor: '#757575' }} />
                  </div>
               </MediaQuery>
            </MediaQuery>

            {/* MOBILE VERSION */}
            <MediaQuery maxDeviceWidth={isMobile}>
               <MainMenu mobile={true} />

               <SpeedDial
                  ariaLabel="SpeedDial top button"
                  style={{
                     position: 'absolute',
                     left: '65px',
                     top: '10px',
                     zIndex: (this.props.onboardingActive &&
                        this.props.onboardingState !== 'newNoteIntro' &&
                        this.props.onboardingState !== 'findPosIntro' &&
                        this.props.onboardingState !== 'searchIntro') ? 100 : 102,
                  }}
                  FabProps={{
                     size: 'medium',
                     className: classes.fabSpeeddial
                  }}
                  icon={this.state.findPosLoading ? <CircularProgress size={24} className={classes.speeddialButtonProgress} /> : <MoreIcon style={{ color: '#41842b' }} />}
                  onClose={() => this.onSpeedDialTopClose()}
                  onOpen={() => this.onSpeedDialTopOpen()}
                  open={
                     this.state.speedDialTopOpen ||
                     this.props.onboardingActive && (this.props.onboardingState === 'newNoteIntro' || this.props.onboardingState === 'findPosIntro' || this.props.onboardingState === 'searchIntro')
                  }
                  direction={'down'}
               >
                  {topActions.map(action => (
                     <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.clickHandler}
                        disabled={action.disabled}
                     />
                  ))}
               </SpeedDial>

               <SpeedDial
                  ariaLabel="SpeedDial bottom button"
                  style={{
                     position: 'absolute',
                     right: '6px',
                     bottom: '10px',
                     zIndex: (this.props.onboardingActive &&
                        this.props.onboardingState !== 'changeMap' &&
                        this.props.onboardingState !== 'drawCellIntro' &&
                        this.props.onboardingState !== 'measIntro' &&
                        this.props.onboardingState !== 'mapSelectIntro' &&
                        this.props.onboardingState !== 'customerSelectIntro' &&
                        this.props.onboardingState !== 'forestSelectIntro') ? 100 : 102,
                  }}
                  FabProps={{
                     size: 'medium',
                     className: classes.fabSpeeddial
                  }}
                  icon={<MoreIcon style={{ color: '#41842b' }} />}
                  onClose={() => this.onSpeedDialBottomClose()}
                  onOpen={() => this.onSpeedDialBottomOpen()}
                  open={
                     this.state.speedDialBottomOpen ||
                     this.props.onboardingActive && (this.props.onboardingState === 'changeMap' || this.props.onboardingState === 'drawCellIntro' || this.props.onboardingState === 'measIntro' || this.props.onboardingState === 'mapSelectIntro' || this.props.onboardingState === 'customerSelectIntro' || this.props.onboardingState === 'forestSelectIntro')
                  }
                  direction={'up'}
               >
                  {bottomActions.map(action => (
                     <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.clickHandler}
                        disabled={action.disabled}
                     />
                  ))}
               </SpeedDial>

               {this.props.currentAuthUser.userRole === "forester" &&
                  <AddressSearchBox
                     map={this.map}
                     mobile={true}
                     findAddressShouldOpen={this.state.findAddressOpen}
                  />}

               {this.map && <MapLayersMenu
                  mobile={true}
                  mapLayerShouldOpen={this.state.mapLayerOpen}
                  onPress={this.handleClickMapLayer}
                  screenHeight={this.state.screenHeight}
                  toggleMapLayer={this.toggleMapLayer}
                  toggleMapWMSLayer={this.toggleMapWMSLayer}
               />}

               <MapSelectorMenu
                  mobile={true}
                  mapSelectorShouldOpen={this.state.mapSelectorOpen}
                  onPress={this.handleClickMapSelector}
                  screenHeight={this.state.screenHeight}
               />

               {/* {this.props.currentAuthUser.org !== undefined &&
                        <MapCustomersMenu
                            mobile={true}
                            mapCustomerShouldOpen={this.state.mapCustomerOpen}
                            onPress={this.handleClickCustomer}
                            screenHeight={this.state.screenHeight}
                        />} */}

               {this.props.currentAuthUser.forests && this.props.chosenWorkEnvironment !== 'biotopePlan' &&
                  <MapCustomersMenu
                     mobile={true}
                     mapCustomerShouldOpen={this.state.mapCustomerOpen}
                     onPress={this.handleClickCustomer}
                     screenHeight={this.state.screenHeight}
                  />}

               {this.props.currentAuthUser.biotopePlans && this.props.chosenWorkEnvironment === 'biotopePlan' &&
                  <MapBiotopePlansMenu
                     mobile={true}
                     mapCustomerShouldOpen={this.state.mapCustomerOpen}
                     onPress={this.handleClickCustomer}
                     screenHeight={this.state.screenHeight}
                  />}

               <MapMeasurementMenu
                  onPressNewDistance={this.measureNewDistance.bind(this)}
                  onPressNewArea={this.measureNewArea.bind(this)}
                  onPressCancel={this.measureCancelNew.bind(this)}
                  onPressDelete={this.measureDelete.bind(this)}
                  onPressRemoveLastVertex={this.measureRemoveLastVertex.bind(this)}
                  disableCancel={!this.state.measuringInProgress && !this.state.measDelete}
                  undoDisabled={!this.state.measuringInProgress}
                  deleteDisabled={this.state.measDelete || disableMeasDeleteIfEmpty}
                  disabledDraw={this.state.measuringInProgress || this.state.measDelete}
                  deleteEnabled={this.state.measDelete}
                  mobile={true}
                  mapMeasurementShouldOpen={this.state.mapMeasurementOpen}
                  onPress={this.handleClickMeasurement}
               />

               <MapDrawingMenu
                  onPressNewPoint={this.setNewPoint.bind(this)}
                  onPressNewLine={this.drawNewLine.bind(this)}
                  onPressNewPolygon={this.drawNewPolygon.bind(this)}
                  onPressCutPolygon={this.cutPolygon.bind(this)}
                  onPressCancelNewPolygon={this.cancelDrawNewPolygon.bind(this)}
                  onPressSaveNewPolygon={this.saveNewPolygon.bind(this)}
                  onPressRemoveLastVortex={this.removeLastVertex.bind(this)}
                  onPressDrawHelpCircles10={() => { this.setDrawHelpCirclesClickHandler(10) }}
                  onPressDrawHelpCircles20={() => { this.setDrawHelpCirclesClickHandler(20) }}
                  onPressWalkPolygon={() => this.handleClickWalk("polygon")}
                  onPressWalkLine={() => this.handleClickWalk("line")}
                  walkActive={this.state.walkPolygonActive || this.state.walkLineActive}
                  disabledDraw={(this.state.drawingPolygon || this.state.drawingLine) || (this.props.cellEditable || !this.props.newCellFinished) || noForestSelected || (!this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest') || this.state.walkPolygonActive || this.state.walkLineActive || (!this.props.forestOwnerAllowChangeHunting && this.props.chosenWorkEnvironment === 'hunting')}
                  disabledWalkPoly={(this.state.drawingPolygon || this.state.drawingLine) || (this.props.cellEditable || !this.props.newCellFinished) || noForestSelected || (this.props.currentAuthUser.userRole === 'forestOwner' && !this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest') || this.state.walkLineActive || this.props.chosenWorkEnvironment !== 'forest' || (!this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest')}
                  disabledWalkLine={(this.state.drawingPolygon || this.state.drawingLine) || (this.props.cellEditable || !this.props.newCellFinished) || noForestSelected || (this.props.currentAuthUser.userRole === 'forestOwner' && !this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest') || this.state.walkPolygonActive || this.props.chosenWorkEnvironment !== 'forest' || (!this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest')}
                  disabledCut={(((!this.state.cuttingPolygon) && (this.props.cellEditable || !this.props.newCellFinished)) && this.props.newLineFinished) && this.state.typeEditable === 'polygon'}
                  disableCancel={!((this.state.drawingPolygon || this.state.drawingLine) && !this.state.polygonReadySave) && !this.state.cuttingPolygon}
                  undoDisabled={!((this.state.drawingPolygon || this.state.drawingLine) && !this.state.polygonReadySave) && !this.state.cuttingPolygon}
                  disabledDrawHelpCircles={noForestSelected || (!this.state.drawingPolygon && !this.props.cellEditable) || this.state.typeEditable === 'line'}
                  drawHelpCircles10={this.state.drawHelpCircles && this.state.helpCirclesRadius === 10}
                  drawHelpCircles20={this.state.drawHelpCircles && this.state.helpCirclesRadius === 20}
                  mobile={true}
                  mapDrawingShouldOpen={this.state.mapDrawingOpen}
                  onPress={this.handleClickDrawing}
                  selectedForest={this.props.selectedForest}
               />

               {(this.props.onboardingActive && !startProfile && !showModal) && <Onboarding
                  mobile={true}
               />}


               {startProfile && <Profile mobile={true} />}

               {/* {(!this.props.subscriptionActive && this.props.reepayState !== "not fetched") && <SubscriptionReactivate open={true} mobile={true} />} */}

               {/* {(!this.props.subscriptionActive && this.props.reepayState === "not fetched") && <SubscriptionUnaccesable open={true} mobile={true} />} */}

               <MediaQuery orientation={'landscape'}>
                  <div style={{
                     position: 'absolute',
                     zIndex: 1000,
                     left: 0,
                     right: 0,
                     top: 0,
                     bottom: 0,
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     backgroundColor: 'rgba(0, 0, 0, 0.75)',
                  }}>
                     <Card style={{ margin: 30 }}>
                        <Typography
                           variant="body1"
                           style={{ margin: 12 }}
                        >
                           Programmet er optimeret til portræt-visning ved denne størrelse mobilenhed
                        </Typography >
                     </Card>
                  </div>
               </MediaQuery>
            </MediaQuery>

            {/* <Legend open={this.props.protectedNatureState} beskyttetNaturtyper={beskyttetNaturtyper} /> */}
            <Legend open={legendData.length > 0} legendData={legendData} />


            {this.props.inventoryListActive && <InventoryList rowClick={this.inventoryListRowClick} />}
            {this.props.biotopePlanListActive && <BiotopePlanList rowClick={this.inventoryListRowClick} />}
            {this.props.kanbanBoardActive && <KanbanBoard navigateToTaskOnClickHandler={this.navigateToNoteClickHandler} />}
            {showModal && <NotSyncedModal show={showModal} mobile={window.innerWidth <= isMobile} hasSubscription={hasPayedSubscription} forestSynced={mapsSynced} />}

            {this.state.farAwayWarning && this.cellDrawnFarAwayWarning()}
            {this.state.overlapDetected && this.overlapDetectedWarning()}

            <div id="map" style={style} />
         </div >
      );
   }
}

const mapStateToProps = state => {
   return {
      config: state.appConfig,
      natureActive: state.account.natureActive,
      operationActive: state.account.operationActive,
      notDisabledForestId: state.maps.notDisabledForestId,
      speciesScheme: state.layout.speciesScheme,
      notes: state.notes.Notes,
      filterTag: state.notes.filterTag,
      filterTodoMyTasks: state.notes.filterTodoMyTasks,
      filterTodoStatus: state.notes.filterTodoStatus,
      flyToNoteData: state.notes.flyToNoteData,
      insertNotes: state.notes.insertNotes,
      loading: state.notes.loading,
      cells: state.maps.cells,
      points: state.maps.points,
      redrawPoints: state.maps.redrawPoints,
      insertPoints: state.notes.insertPoints,
      simpleState: state.maps.simpleMapState,
      ortoState: state.maps.ortoMapState,
      ortoKfState: state.maps.ortoKfMapState,
      ortoKfTempState: state.maps.ortoKfTempMapState,
      contourKfState: state.maps.contourKfMapState,
      shadowKfState: state.maps.shadowKfMapState,
      shadowSurfKfState: state.maps.shadowSurfKfMapState,
      blueSpotState: state.maps.blueSpotMapState,
      protectedNatureState: state.maps.protectedNatureMapState,
      fredskovState: state.maps.fredskovState,
      natura2000State: state.maps.natura2000State,
      huntingLayerState: state.maps.huntingLayerMapState,
      cellsState: state.maps.cellsState,
      cellsOpacityState: state.maps.cellsOpacityState,
      linesState: state.maps.linesState,
      notesState: state.maps.notesState,
      pointsState: state.maps.pointsState,
      cadastralState: state.maps.cadastralState,
      cadastralOwnerState: state.maps.cadastralOwnerState,
      markblokkeState: state.maps.markblokkeState,
      protectedAncientAreasState: state.maps.protectedAncientAreasState,
      inventoryListActive: state.maps.inventoryListActive,
      kanbanBoardActive: state.layout.kanbanBoardActive,
      deleteMarker: state.maps.deleteMarker,
      deleteMarkerLatLng: state.maps.deleteMarkerLatLng,
      newNoteFinished: state.maps.newNoteFinished,
      newCellFinished: state.maps.newCellFinished,
      newLineFinished: state.maps.newLineFinished,
      deleteCell: state.maps.deleteCell,
      deleteCellLatLngs: state.maps.deleteCellLatLngs,
      cellEditable: state.maps.cellEditable,
      cellEditableId: state.maps.cellEditableId,
      noteEditable: state.notes.noteEditable,
      noteEditableId: state.notes.noteEditableId,
      leafletLayerRemove: state.maps.leafletLayerRemove,
      redrawCells: state.maps.redrawCells,
      forestBbox: state.maps.forestBbox,
      skipOnboarding: state.onboarding.skipOnboarding,
      onboardingActive: state.onboarding.onboardingActive,
      onboardingState: state.onboarding.onboardingState,
      onboardingZoomBtnZidx: state.onboarding.zoomBtnZidx,
      onboardingNewNoteBtnZidx: state.onboarding.newNoteBtnZidx,
      onboardingInvListZidx: state.onboarding.invListZidx,
      onboardingPrintMapZidx: state.onboarding.printMapZidx,
      onboardingFindPosZidx: state.onboarding.findPosZidx,
      onboardingBtnColor: state.onboarding.onboardingBtnColor,
      onboardingFindPosBtnColor: state.onboarding.findPosBtnColor,
      onboardigNewNoteBtnColor: state.onboarding.newNoteBtnColor,
      onboardingNewNoteDisable: state.onboarding.disableNewNote,
      currentAuthUser: state.user.currentAuthUser,
      orgId: state.org.orgId,
      org: state.org.org,
      customerIdList: state.customer.customerIdList,
      customerList: state.customer.customerList,
      biotopePlansUser: state.biotopePlans.biotopePlansUser,
      selectedForest: state.customer.currentlySelectedForestId,
      selectedBiotopePlan: state.biotopePlans.currentlySelectedBiotopePlanId,
      biotopePlanListActive: state.biotopePlans.biotopePlanListActive,
      sidebar: state.layout.sidebarOpen,
      subscriptionActive: state.account.subscriptionActive,
      reepayState: state.account.reepayState,
      forestOwnerAllowChange: state.maps.forestOwnerAllowChange,
      forestOwnerAllowChangeHunting: state.hunting.forestOwnerAllowChange,
      polyToolTipActive: state.maps.polyToolTipActive,
      noteToolTipActive: state.notes.noteToolTipActive,
      lineLabelsActive: state.maps.lineLabelsActive,
      huntingFeatures: state.hunting.features,
      huntingPoints: state.hunting.points,
      redrawHuntingPoints: state.hunting.redrawPoints,
      redrawHuntingFeatures: state.hunting.redrawFeatures,
      chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
      pointEditable: state.notes.pointEditable,
      pointEditableId: state.notes.pointEditableId,
      numNotes: state.layout.numNotes,
      numPoints: state.layout.numPoints,
      numHuntingPoints: state.layout.numHuntingPoints,
      filterCellIds: state.inventory.filterCellIds,
      filterShowFilterInMap: state.inventory.showFilterInMap,
      filterTaskIds: state.activity.filterObjectIds,
      filterShowTasksInMap: state.activity.showFilterInMap,
      biotopePlanFeatures: state.biotopePlans.features,
      biotopePlanPoints: state.biotopePlans.points,
      redrawBiotopePlanFeatures: state.biotopePlans.redrawFeatures,
      redrawBiotopePlanPoints: state.biotopePlans.redrawPoints,
   };
}

const mapDispatchToProps = dispatch => {
   return {
      onFetchCells: (forestId, revId) => dispatch(actions.fetchCells(forestId, revId)),
      onSetCellsMapState: (s) => dispatch(actions.setMapCellsState(s)),
      onSetLinesMapState: (s) => dispatch(actions.setMapLinesState(s)),
      onsetMapNotesState: (s) => dispatch(actions.setMapNotesState(s)),
      onSetForestMarkerClickId: (id) => dispatch(actions.setForestMarkerClickId(id)),
      onSetDeleteMarker: (s, latlng) => dispatch(actions.setDeleteMarker(s, latlng)),
      onSetNewNoteFinished: (s) => dispatch(actions.setNewNoteFinished(s)),
      onSetInsertNotesState: (s) => dispatch(actions.setInsertNotesState(s)),
      onSetRedrawPoints: (s) => dispatch(actions.setRedrawPoints(s)),
      onSetInsertPointsState: (s) => dispatch(actions.setInsertPointsState(s)),
      onSetDeleteCell: (s, latlngs) => dispatch(actions.setDeleteCell(s, latlngs)),
      onSetNewCellFinished: (s) => dispatch(actions.setNewCellFinished(s)),
      onSetNewLineFinished: (s) => dispatch(actions.setNewLineFinished(s)),
      onSaveCell: (forestId, revId, cellData) => dispatch(actions.saveCell(forestId, revId, cellData)),
      onSetCellEditableLatLng: (latlng) => dispatch(actions.setCellEditableLatLng(latlng)),
      onSetNoteEditableLatLng: (latlng) => dispatch(actions.setNoteEditableLatLng(latlng)),
      onSetPointEditableLatLng: (latlng) => dispatch(actions.setPointEditableLatLng(latlng)),
      onSetLeafletLayerRemove: (s) => dispatch(actions.setLeafletLayerRemove(s)),
      onCellsRedraw: () => dispatch(actions.cellsRedrawn()),
      onSetOnboardingActive: (s) => dispatch(actions.setOnboardingActive(s)),
      onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
      onSetInventoryListActive: (s) => dispatch(actions.setInventoryListActive(s)),
      onSetKanbanBoardActive: (s) => dispatch(actions.setKanbanBoardActive(s)),
      onSidebarOpen: (state) => dispatch(actions.sidebarOpen(state)),
      onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
      onSetRedrawHuntingPoints: (s) => dispatch(actions.setRedrawHuntingPoints(s)),
      onSetRedrawHuntingFeatures: (s) => dispatch(actions.setRedrawHuntingFeatures(s)),
      onSetNumberOfPoints: (num) => dispatch(actions.setNumberOfPoints(num)),
      onSetNumberOfHuntingPoints: (num) => dispatch(actions.setNumberOfHuntingPoints(num)),
      onSetNumberOfNotes: (num) => dispatch(actions.setNumberOfNotes(num)),
      onSetRedrawBiotopePlanFeatures: (state) => dispatch(actions.setRedrawBiotopePlanFeatures(state)),
      onSetRedrawBiotopePlanPoints: (state) => dispatch(actions.setRedrawBiotopePlanPoints(state)),
      onSetCurrentlySelectedBiotopePlanId: (id) => dispatch(actions.setCurrentlySelectedBiotopePlanId(id)),
      onSetBiotopePlanListActive: (state) => dispatch(actions.setBiotopePlanListActive(state)),

   };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(map)));