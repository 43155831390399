import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

// Material UI Icons
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import GoBackIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

// Moment
import moment from 'moment';

// Turf
import buffer from '@turf/buffer';

// Utility
import { findMinMax } from '../../maps/Utility/UtilityFunctions'
import getLineLength from '@turf/length';

// Components
import ControlledExpansionPanels from '../UI/Lists/ControlledExpansionPanels';
import ChangeSaveButton from '../UI/Buttons/ChangeSaveButton';
import DeleteButton from '../UI/Buttons/DeleteButton';
import OutlinedButton from '../UI/Buttons/OutlinedButton';
import DropDownMenu from '../Inventory/DropDownMenu';
import SpeciesList from '../UI/Lists/SpeciesList';
import { getStandHeight } from './Utilities/standAssesment';
import { getCategoryFromCodeName } from './Utilities/categoriesUtil';
import { getFullSpecieParameterList } from './Utilities/speciesUtil';
import * as actions from '../../store/actions/index';

const _ = require("underscore");
var geojsonArea = require('@mapbox/geojson-area');

const categories = require('./Utilities/catagories');

const styles = theme => ({
    textField: {
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit,
        width: '100%',
    },
    card: {
        position: 'relative',
        margin: 'auto',
    },
    nonEditTextStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

// Class Start
export class Inventory extends React.Component {
    constructor(props) {
        super(props);
        // Fields are pre-loaded and note is made editable if it
        // is a new one - e.g. accessed without props
        this.state = {
            screenWidth: window.innerWidth,
            open: false,
            litra: ("litra" in this.props) ? this.props.litra : '',
            area: ("area" in this.props) ? this.props.area : '',
            categories: ("categories" in this.props) ? this.props.categories : [],
            selectedCategory: (("categories" in this.props) && this.props.categories.length > 0) ? getCategoryFromCodeName(this.props.categories[0]) : 'Drift',
            selectedRegistration: (("registrations" in this.props) && this.props.registrations.length > 0) ? this.props.registrations[0] : '',
            lineLength: ("lineLength" in this.props) ? this.props.lineLength : '',
            species: ("species" in this.props) ? this.props.species : '',
            year: ("year" in this.props) ? this.props.year : '',
            mass: ("mass" in this.props) ? this.props.mass : '',
            note: ("note" in this.props) ? this.props.note : '',
            caution: ("caution" in this.props) ? this.props.caution : '',
            change: ("litra" in this.props) ? false : true,
            lidarHeight: ("lidarHeight" in this.props) ? this.props.lidarHeight : '',
            previousSpecies: ("previousSpecies" in this.props) ? this.props.previousSpecies : '',
            futureSpecies: ("futureSpecies" in this.props) ? this.props.futureSpecies : '',
            lidarData: null,
            tempChange: false,
            submit: false,
            newCellState: this.props.litra ? false : true,
            saveLoading: false,
            deleteLoading: false,
            disabled: false,
            tempCellEditableLatLng: this.props.GeoJsonFeature.coordinates[0],
            speciesCallBack: ("speciesObjs" in this.props) ? this.props.speciesObjs : {},
            unSavedSpecie: false,
            unSavedInventory: false,
            showUnsavedWarning: false,
            deleteId: null,
            saveSpecies: false,
            tempSelectedForest: this.props.selectedForest,
            tempChosenWorkEnvironment: this.props.chosenWorkEnvironment,
            environment: ("environment" in this.props) ? this.props.environment : '',
        };
    }

    componentWillMount = async () => {
        // Fetch polygon lidar heights
        this.mounted = true;
        // Check if feature is polygon and create geoJson feature
        // let compYear = 0;
        // if (this.state.lidarHeight && this.state.lidarHeight !== '') {
        //     compYear = this.state.lidarHeight.measYear;
        // }
        if (this.props.chosenWorkEnvironment === 'forest' && this.props.GeoJsonFeature.type === "Polygon") {
            try {
                const feat = {
                    "type": "Feature",
                    "geometry": this.props.GeoJsonFeature
                }
                const lH = getStandHeight(feat, {
                    numPoints: 50,
                    bufsize: 10,
                    pointDistType: 'random',
                    sortPointsType: 'hdom',
                    sortTruncVal: 0.2
                })
                this.setState({ lidarHeight: lH });
                const lhData = await lH;
                if (this.mounted) {
                    this.setState({ lidarData: lhData })
                }
            } catch (err) {
                console.error("Error in fetching height: ", err)
                this.setState({ lidarHeight: 'error' });
            }
        }
    }

    componentDidMount() {
        if (this.state.newCellState) {
            this.props.onSetCellEditable(true, 'new')
        }
        // Onboarding functionality
        if (this.props.onboardingActive && this.props.onboardingState === 'navigateMap') {
            this.props.onSetOnboardingState('litraInfo');
        }
    }

    componentDidUpdate() {
        // Handle empty species Object array
        if (_.isEmpty(this.state.speciesCallBack) && this.state.species !== "") {
            this.setState({
                species: "",
                year: "",
                mass: "",
            })
        }

        // Handle save species
        if (this.state.saveSpecies) {
            this.setState({ saveSpecies: false, unSavedInventory: true })
        }

        // Handle selected forest change when in inventory
        if (this.state.tempSelectedForest !== this.props.selectedForest) {
            if (this.state.newCellState) {
                this.props.onSetDeleteCell(true, this.props.latlngs);
                this.props.onSetLeafletLayerRemove(true);
                this.props.onSetNewCellFinished(true);
                this.props.onSetNewLineFinished(true);
            }
            // Route away from inventory
            let path = "/"
            this.props.history.replace({
                pathname: path,
            });
            // Go back
            this.goBackClickHandler();
        }

        // Handle environment change
        if (this.state.tempChosenWorkEnvironment !== this.props.chosenWorkEnvironment) {
            if (this.state.newCellState) {
                this.props.onSetDeleteCell(true, this.props.latlngs);
                this.props.onSetLeafletLayerRemove(true);
                this.props.onSetNewCellFinished(true);
                this.props.onSetNewLineFinished(true);
            }
            // Route away from inventory
            let path = "/"
            this.props.history.replace({
                pathname: path,
            });
            // Go back
            this.goBackClickHandler();
        }

        // Set cell editable
        if (this.state.change !== this.state.tempChange && this.props.id) {
            if (this.state.change) {
                this.props.onSetCellEditable(true, this.props.id);
            } else {
                this.props.onSetCellEditable(false, this.props.id);
            }
            this.setState({ tempChange: this.state.change })
        }

        // Save navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.forestSuccess) {
            this.setState({
                change: false,
                saveLoading: false,
                disabled: false
            })
            this.props.onSetNewCellFinished(true);
            this.props.onSetNewLineFinished(true);

            let path = "/"
            this.props.history.replace({
                pathname: path,
            });
        }

        // Delete navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.deleteCell2) {
            this.setState({
                change: false,
                deleteLoading: false,
                disabled: false
            })
            // Go back
            this.goBackClickHandler();
        }
    }

    componentWillUnmount() {
        // Set cell to non-editable
        this.props.onSetCellEditable(false, this.props.id)
        this.mounted = false;

        // If navigation away and cell not saved
        if (this.state.newCellState) {
            this.goBackClickHandler()
        }

        // Show snackbar if cell is saved
        if (this.props.forestSuccess) {
            if (this.props.chosenWorkEnvironment === 'forest') {
                this.props.onEnqueueSnackbar({
                    message: this.props.GeoJsonFeature.type === 'LineString' ? "Linje er nu gemt!" : "Litra er nu gemt!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                });
                // Add event to log
                const eD = {
                    event: this.props.GeoJsonFeature.type === 'LineString' ? "Linje gemt" : "Litra gemt",
                    type: "Litra",
                    reference: this.state.litra,
                    private: false,
                    forestName: this.props.customerList[this.props.selectedForest].forestName,
                    userId: this.props.currentAuthUser.id,
                    userName: this.props.currentAuthUser.name,
                }
                this.props.onAddEventToEventLog(this.props.selectedForest, eD)
            } else if (this.props.chosenWorkEnvironment === 'hunting') {
                this.props.onEnqueueSnackbar({
                    message: this.props.GeoJsonFeature.type === 'LineString' ? "Linje er nu gemt!" : "Polygon er nu gemt!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                });
                // Add event to log
                const eD = {
                    event: this.props.GeoJsonFeature.type === 'LineString' ? "Linje gemt" : "Polygon gemt",
                    type: "hunting",
                    reference: this.state.litra,
                    private: false,
                    forestName: this.props.customerList[this.props.selectedForest].forestName,
                    userId: this.props.currentAuthUser.id,
                    userName: this.props.currentAuthUser.name,
                }
                this.props.onAddEventToEventLog(this.props.selectedForest, eD)
            } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
                this.props.onEnqueueSnackbar({
                    message: this.props.GeoJsonFeature.type === 'LineString' ? "Linje er nu gemt!" : "Polygon er nu gemt!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                });
            } else {
                console.error("Environment not defined!")
            }

        }

        // Show snackbar if cell is deleted
        if (this.props.deleteCell2) {
            if (this.props.chosenWorkEnvironment === 'forest') {
                this.props.onEnqueueSnackbar({
                    message: this.props.GeoJsonFeature.type === 'LineString' ? "Linje er nu slettet!" : "Litra er nu slettet!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                });
                const eD = {
                    event: this.props.GeoJsonFeature.type === 'LineString' ? "Linje slettet" : "Litra slettet",
                    type: "Litra",
                    reference: this.state.litra,
                    private: false,
                    forestName: this.props.customerList[this.props.selectedForest].forestName,
                    userId: this.props.currentAuthUser.id,
                    userName: this.props.currentAuthUser.name,
                }
                this.props.onAddEventToEventLog(this.props.selectedForest, eD)
            } else if (this.props.chosenWorkEnvironment === 'hunting') {
                this.props.onEnqueueSnackbar({
                    message: this.props.GeoJsonFeature.type === 'LineString' ? "Linje er nu slettet!" : "Areal er nu slettet!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                });
                const eD = {
                    event: this.props.GeoJsonFeature.type === 'LineString' ? "Linje slettet" : "Areal slettet",
                    type: "Jagt - Areal",
                    reference: this.state.litra,
                    private: false,
                    forestName: this.props.customerList[this.props.selectedForest].forestName,
                    userId: this.props.currentAuthUser.id,
                    userName: this.props.currentAuthUser.name,
                }
                this.props.onAddEventToEventLog(this.props.selectedForest, eD)
                // Redraw hunting cells
                if (this.props.chosenWorkEnvironment === 'hunting') {
                    this.props.onSetRedrawHuntingFeatures(true);
                }
            } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
                this.props.onEnqueueSnackbar({
                    message: this.props.GeoJsonFeature.type === 'LineString' ? "Linje er nu slettet!" : "Areal er nu slettet!",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                });
                this.props.onRedrawCells();
            }
        }

        // If on mobile device close sidebar if cell was accessed from map
        if (this.state.screenWidth < 760) {
            this.props.onSidebarOpen(false);
        }

        // Set reducer to initial state
        this.props.onUnmountCell();

        // Onboarding action if active
        if (this.props.onboardingActive && this.props.onboardingState === 'drawCellEnd') {
            // Check if limitation on notes is reached
            let goToStage = 'newNoteIntro';
            if (this.props.config.newNote.noteNumber && this.props.numNotes >= this.props.config.newNote.noteNumber) {
                goToStage = 'measIntro';
            }
            this.props.onSetOnboardingState(goToStage);
        }
    }

    myCallback = (dataFromChild) => {
        // Delete inventory specie 
        if (dataFromChild.deleteSpecieId !== null) {
            const updatedSpeciesCallBack = { ...this.state.speciesCallBack };
            delete updatedSpeciesCallBack[dataFromChild.deleteSpecieId];
            this.setState({ speciesCallBack: updatedSpeciesCallBack });
        }

        if ("idBack" in dataFromChild) {
            if (dataFromChild.idBack !== "") {
                const updatedSpeciesCallBack = { ...this.state.speciesCallBack };
                updatedSpeciesCallBack[dataFromChild.idBack].unSavedSpecie = dataFromChild.unSavedSpecie;
                this.setState({ speciesCallBack: updatedSpeciesCallBack });
            } else {
                this.setState({
                    unSavedSpecie: dataFromChild.unSavedSpecie,
                    submit: false,
                })
            }
        }

        if ("main" in dataFromChild) {
            // Set specie to main and update the others to secondary
            if (dataFromChild.main) {
                const updatedSpeciesCallBack = { ...this.state.speciesCallBack };
                Object.values(updatedSpeciesCallBack).forEach(spc => {
                    if (spc.id !== dataFromChild.idBack) {
                        spc.main = false;
                    }
                })
                this.setState({ speciesCallBack: updatedSpeciesCallBack })
            }
        }

        if ("species" in dataFromChild) {
            // Check if specie object already exist
            const ids = Object.keys(this.state.speciesCallBack).filter(key => {
                return key === dataFromChild.idBack;
            })
            // If specie object already exist
            if (ids.length !== 0) {
                const updatedSpeciesCallBack = { ...this.state.speciesCallBack };
                let updatedSpeciesCallBackElement = { ...updatedSpeciesCallBack[ids[0]] };
                updatedSpeciesCallBackElement = { ...updatedSpeciesCallBackElement, ...dataFromChild };
                updatedSpeciesCallBack[ids[0]] = updatedSpeciesCallBackElement;
                this.setState({ speciesCallBack: updatedSpeciesCallBack });
            } else {
                // Generate id
                if (dataFromChild.species !== '') {
                    const id = moment().format() + "," + Math.floor(Math.random() * 1000).toString();
                    const updatedSpeciesCallBack = { ...this.state.speciesCallBack };
                    updatedSpeciesCallBack[id] = { id: id, ...dataFromChild };
                    this.setState({ speciesCallBack: updatedSpeciesCallBack });
                }
            }

            if (dataFromChild.main) {
                this.setState({
                    year: dataFromChild.year,
                    species: dataFromChild.species,
                    mass: dataFromChild.mass,
                })
            } else {
                const keys = Object.values(this.state.speciesCallBack).filter(spc => {
                    return spc.main === true;
                })
                if (keys.length === 0) {
                    this.setState({
                        year: dataFromChild.year,
                        species: dataFromChild.species,
                        mass: dataFromChild.mass,
                    })
                }
            }

            this.setState({
                unSavedSpecie: dataFromChild.unSavedSpecie,
            })
        } else {
            this.setState({
                unSavedSpecie: dataFromChild.unSavedSpecie,
            })
        }

        // Save on cloud if save button on specie is pushed
        if (dataFromChild.unSavedSpecie === false) {
            this.setState({ saveSpecies: true })
        }

        // Check if all spicies have been saved
        const numUnsaved = Object.values(this.state.speciesCallBack).filter(spc => {
            return spc.unSavedSpecie;
        }).length
        if (numUnsaved !== 0) {
            this.setState({ unSavedSpecie: true })
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    goBackClickHandler = () => {
        // set delete new cell state, if note is not saved
        // if (this.state.newCellState) {
        //     this.props.onSetDeleteCell(true, this.props.latlngs);
        //     this.props.onSetLeafletLayerRemove(true);
        //     this.props.onSetNewCellFinished(true);
        //     this.props.onSetNewLineFinished(true);
        // }

        if ((!this.state.unSavedSpecie && !this.state.unSavedInventory && !this.state.newCellState && this.props.cellEditableLatLng === null) || this.props.deleteCell2) {
            // Set cell to not be editable
            this.props.onSetCellEditableLatLng(null);
            // Choose which route to go back to
            let path = "/"
            this.props.history.replace({
                pathname: path,
            });
        } else {
            this.setState({ showUnsavedWarning: true })
        }
    }

    ignoreWarningClickHandler = () => {
        // Remove cell from map if it is new
        if (this.state.newCellState) {
            this.props.onSetDeleteCell(true, this.props.latlngs);
            this.props.onSetLeafletLayerRemove(true);
            this.props.onSetNewCellFinished(true);
            this.props.onSetNewLineFinished(true);
        }
        // Set cell to not be editable
        this.props.onSetCellEditableLatLng(null);
        // Redraw cells if polygon has changed but is not to be saved
        if (this.props.cellEditableLatLng !== null) {
            this.props.onSetLeafletLayerRemove(true);
            this.props.onRedrawCells();
        }

        // Route away
        this.props.history.replace({ pathname: "/" });
    }

    onDeleteClickHandler = () => {
        // Delete cell on cloud
        const cellId = this.props.id;
        this.props.onDeleteCell(
            this.props.chosenWorkEnvironment === 'biotopePlan' ? this.props.selectedBiotopePlan : this.props.selectedForest,
            this.props.chosenWorkEnvironment === 'biotopePlan' ? this.props.biotopePlanFeatures : this.props.cells,
            cellId,
            this.props.chosenWorkEnvironment
        );

        // Send event to Analytics
        if (this.props.chosenWorkEnvironment === 'forest') {
            ReactGA.event({
                category: 'Cell',
                action: 'Cell deleted',
                label: `User: ${this.props.currentAuthUser.name}`
            });
        } else if (this.props.chosenWorkEnvironment === 'hunting') {
            ReactGA.event({
                category: 'Hunting',
                action: 'Cell deleted',
                label: `User: ${this.props.currentAuthUser.name}`
            });
        } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
            ReactGA.event({
                category: 'Biotope Plans',
                action: 'Cell deleted',
                label: `User: ${this.props.currentAuthUser.name}`
            });
        }

        if (this.props.cellEditableLatLng !== null) {
            this.props.onSetLeafletLayerRemove(true);
        }

        this.setState({
            deleteLoading: true,
            disabled: true,
        })
    }

    onSelectCategories = (item) => {
        if (item === 'upgrade') {
            // Open account handling in main menu
            this.props.onMainMenuOpen(true);
            this.props.onAccountMenuOpen(true);
        } else {
            this.setState({ selectedCategory: item, unSavedInventory: true })
        }
    }

    onSelectRegistrations = (item) => {
        this.setState({ selectedRegistration: item, unSavedInventory: true })
    }

    onSelectPreviousSpecies = (item) => {
        this.setState({ previousSpecies: item, unSavedInventory: true })
    }

    onSelectFutureSpecies = (item) => {
        this.setState({ futureSpecies: item, unSavedInventory: true })
    }

    // Form data to be saved on cloud
    dataToCloud = (layerType) => {
        // Variables
        let Area = '';
        let Length = '';
        let tempLngLat = [];
        let holesTemp = {};
        // Get main specie id
        const keys = Object.values(this.state.speciesCallBack).filter(spc => {
            return spc.main === true;
        })

        let idx = '';
        if (keys.length === 0) {
            idx = Object.keys(this.state.speciesCallBack);
            idx = idx[0];
            const tempSpcCB = this.state.speciesCallBack;
            tempSpcCB[idx].main = true;
            this.setState({ speciesCallBack: tempSpcCB })
        } else {
            idx = keys[0].id !== undefined ? keys[0].id : keys[0].idBack;
        }
        // Set new latlngs and holes and recalculate area
        if (this.props.cellEditableLatLng) {
            const lenLngLat = this.props.cellEditableLatLng.coordinates.length;
            if (this.props.GeoJsonFeature.type === 'Polygon') {
                this.props.cellEditableLatLng.coordinates[0].map((rows, i) => {
                    rows.map((cell, j) => {
                        tempLngLat[i * 2 + j] = cell;
                        return 0;
                    })
                    return 0;
                });
                // Insert holes
                if (lenLngLat > 1) {
                    for (let i = 1; i < lenLngLat; i++) {
                        let hole = [];
                        this.props.cellEditableLatLng.coordinates[i].map((rows, j) => {
                            rows.map((cell, n) => {
                                hole[j * 2 + n] = cell;
                                return 0;
                            })
                            return 0;
                        });
                        holesTemp[i - 1] = hole;
                    }
                    this.props.onSetLeafletLayerRemove(true);
                }
                Area = Math.round(geojsonArea.geometry(this.props.cellEditableLatLng) / 10000 * 100) / 100;
            } else if (this.props.GeoJsonFeature.type === 'LineString') {
                this.props.cellEditableLatLng.coordinates.map((rows, i) => {
                    rows.map((cell, j) => {
                        tempLngLat[i * 2 + j] = cell;
                        return 0;
                    })
                    return 0;
                });
                // Calculate area of line
                const geoJsonFeat = {
                    type: 'Feature',
                    geometry: {
                        type: this.props.cellEditableLatLng.type,
                        coordinates: this.props.cellEditableLatLng.coordinates,
                    },
                    properties: {}
                }
                let weight = this.state.speciesCallBack[idx].weight ? this.state.speciesCallBack[idx].weight : 1
                let buffered = buffer(geoJsonFeat, (0.001 * weight * 1.15 + 0), { units: 'kilometers', steps: 20 });
                if (this.props.cellEditableLatLng.coordinates.length > 0) {
                    Area = Math.round(geojsonArea.geometry(buffered.geometry) / 10000 * 100) / 100;
                } else {
                    Area = 0;
                }

                Length = getLineLength(geoJsonFeat, { units: 'kilometers' }) * 1000;
            }
            this.props.onSetCellEditableLatLng(null);
        } else {
            if (this.props.GeoJsonFeature.type === 'Polygon') {
                const lenLngLat = this.props.GeoJsonFeature.coordinates.length;
                this.props.GeoJsonFeature.coordinates[0].map((rows, i) => {
                    rows.map((cell, j) => {
                        tempLngLat[i * 2 + j] = cell;
                        return 0;
                    })
                    return 0;
                });
                // Insert holes
                if (lenLngLat > 1) {
                    for (let i = 1; i < lenLngLat; i++) {
                        let hole = [];
                        this.props.GeoJsonFeature.coordinates[i].map((rows, j) => {
                            rows.map((cell, n) => {
                                hole[j * 2 + n] = cell;
                                return 0;
                            })
                            return 0;
                        });
                        holesTemp[i - 1] = hole;
                    }
                    this.props.onSetLeafletLayerRemove(true);
                }
                Area = this.props.area;

            } else if (this.props.GeoJsonFeature.type === 'LineString') {
                this.props.GeoJsonFeature.coordinates.map((rows, i) => {
                    rows.map((cell, j) => {
                        tempLngLat[i * 2 + j] = cell;
                        return 0;
                    })
                    return 0;
                });
                // Calculate area of line
                const geoJsonFeat = {
                    type: 'Feature',
                    geometry: {
                        type: this.props.GeoJsonFeature.type,
                        coordinates: this.props.GeoJsonFeature.coordinates,
                    },
                    properties: {}
                }
                let weight = this.state.speciesCallBack[idx].weight ? this.state.speciesCallBack[idx].weight : 1
                let buffered = buffer(geoJsonFeat, (0.001 * weight * 1.15 + 0), { units: 'kilometers', steps: 20 });
                Area = Math.round(geojsonArea.geometry(buffered.geometry) / 10000 * 100) / 100;
                Length = getLineLength(geoJsonFeat, { units: 'kilometers' }) * 1000;
            }
        }
        // Create bounding box
        const lng = tempLngLat.filter((element, index) => {
            return index % 2 === 0;
        })
        const lat = tempLngLat.filter((element, index) => {
            return index % 2 !== 0;
        })
        const lngMinMax = findMinMax(lng);
        const latMinMax = findMinMax(lat);
        const bbox = [lngMinMax[0], latMinMax[0], lngMinMax[1], latMinMax[1]];

        // Set type (Polygon or Line)
        const type = this.props.GeoJsonFeature.type === 'Polygon' ? 'Polygon' : 'Line';

        let cellData = null;
        let environment = 'forest';
        if (this.props.chosenWorkEnvironment === 'hunting') {
            environment = 'hunting';
        } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
            environment = 'biotopePlan';
        }
        if (layerType === "forest") {
            // Form data to be saved on cloud
            cellData = {
                id: this.props.id,
                forestId: this.props.selectedForest,
                litra: this.state.litra,
                area: Area,
                lineLength: Length,
                date: moment().format(),
                lnglat: tempLngLat,
                holes: holesTemp,
                bbox: bbox,
                type: type,
                species: this.state.speciesCallBack[idx].species ? this.state.speciesCallBack[idx].species : "",
                year: this.state.speciesCallBack[idx].year ? this.state.speciesCallBack[idx].year : "",
                mass: this.state.speciesCallBack[idx].mass ? this.state.speciesCallBack[idx].mass : "",
                note: this.state.note ? this.state.note : "",
                caution: this.state.caution,
                age: "??",
                height: this.state.speciesCallBack[idx].height ? this.state.speciesCallBack[idx].height : 0,
                lidarHeight: this.state.lidarData ? this.state.lidarData : '',
                diameter: this.state.speciesCallBack[idx].diameter ? this.state.speciesCallBack[idx].diameter : 0,
                mixpct: this.state.speciesCallBack[idx].mix ? this.state.speciesCallBack[idx].mix : -1,
                volume: this.state.speciesCallBack[idx].mass ? this.state.speciesCallBack[idx].mass : "",
                coverage: this.state.speciesCallBack[idx].coverage ? this.state.speciesCallBack[idx].coverage : 0,
                quality: this.state.speciesCallBack[idx].quality ? this.state.speciesCallBack[idx].quality : 0,
                treeCount: this.state.speciesCallBack[idx].treeCount ? this.state.speciesCallBack[idx].treeCount : 0,
                formNum: this.state.speciesCallBack[idx].formNum ? this.state.speciesCallBack[idx].formNum : 0,
                assesmentMethod: this.state.speciesCallBack[idx].assesmentMethod ? this.state.speciesCallBack[idx].assesmentMethod : "",
                color: this.state.speciesCallBack[idx].color ? this.state.speciesCallBack[idx].color : "#009",
                fillColor: this.state.speciesCallBack[idx].color ? this.state.speciesCallBack[idx].color : "#009",
                weight: this.state.speciesCallBack[idx].weight ? this.state.speciesCallBack[idx].weight : 1,
                dashStyle: this.state.speciesCallBack[idx].dashStyle ? this.state.speciesCallBack[idx].dashStyle : null,
                speciesObjs: this.state.speciesCallBack,
                kwData: this.props.kwData ? this.props.kwData : {},
                environment: environment,
                categories: [categories[this.state.selectedCategory].name],
                registrations: [this.state.selectedRegistration],
                previousSpecies: this.state.previousSpecies,
                futureSpecies: this.state.futureSpecies
            }
        } else if (layerType === 'hunting') {
            cellData = {
                id: this.props.id,
                forestId: this.props.selectedForest,
                litra: this.state.litra,
                area: Area,
                lineLength: Length,
                date: moment().format(),
                lnglat: tempLngLat,
                holes: holesTemp,
                bbox: bbox,
                type: type,
                year: this.state.speciesCallBack[idx].year ? this.state.speciesCallBack[idx].year : "",
                note: this.state.note ? this.state.note : "",
                color: this.state.speciesCallBack[idx].color ? this.state.speciesCallBack[idx].color : "#009",
                fillColor: this.state.speciesCallBack[idx].color ? this.state.speciesCallBack[idx].color : "#009",
                speciesObjs: this.state.speciesCallBack,
                environment: environment,
            }
        } else if (layerType === 'biotopePlan') {
            cellData = {
                id: this.props.id,
                // species: this.state.speciesCallBack[idx].species,
                species: this.state.species,
                biotopePlanId: this.props.selectedBiotopePlan,
                litra: this.state.litra,
                area: Area,
                lineLength: Length,
                date: moment().format(),
                lnglat: tempLngLat,
                holes: holesTemp,
                bbox: bbox,
                type: type,
                year: this.state.speciesCallBack[idx].year ? this.state.speciesCallBack[idx].year : "",
                note: this.state.note ? this.state.note : "",
                color: this.state.speciesCallBack[idx].color ? this.state.speciesCallBack[idx].color : "#009",
                fillColor: this.state.speciesCallBack[idx].color ? this.state.speciesCallBack[idx].color : "#009",
                speciesObjs: this.state.speciesCallBack,
                environment: environment,
            }
        } else {
            console.error("Type not found!")
        }
        return cellData;
    }

    changeSaveButton = () => {
        // Upload note to cloud 
        if (this.state.change) {
            // Set submit state
            this.setState({
                submit: true
            })
            // Check if cell is incomplete.
            if (this.state.litra !== "" && this.state.species !== "" && !this.state.unSavedSpecie) {
                // Upload action
                this.setState({
                    saveLoading: true,
                    disabled: true,
                })
                // Check for map selector (forest, hunting, activity)
                if (this.props.chosenWorkEnvironment === 'forest') {
                    this.props.onSaveForest(this.props.selectedForest, this.props.cells[this.props.selectedForest], this.dataToCloud('forest'));
                    // Send event to Analytics
                    ReactGA.event({
                        category: 'Cell',
                        action: 'Cell created',
                        label: `User: ${this.props.currentAuthUser.name}`
                    });
                } else if (this.props.chosenWorkEnvironment === 'hunting') {
                    this.props.onSaveForest(this.props.selectedForest, 0, this.dataToCloud('forest'), 'hunting')
                    // Send event to Analytics
                    ReactGA.event({
                        category: 'Hunting',
                        action: 'Cell created',
                        label: `User: ${this.props.currentAuthUser.name}`
                    });
                } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
                    this.props.onSaveForest(this.props.selectedBiotopePlan, this.props.biotopePlanFeatures[this.props.selectedBiotopePlan], this.dataToCloud('biotopePlan'), 'biotopePlan')
                    // Send event to Analytics
                    ReactGA.event({
                        category: 'BiotopePlan',
                        action: 'Cell created',
                        label: `User: ${this.props.currentAuthUser.name}`
                    });
                } else {
                    console.error("Environment not yet defined")
                }
                // TODO : Find better logic than just resetting filters on save specie
                // Reset inventory list filter states
                this.props.onSetFilterState(null)

                // Switch states
                this.setState({
                    change: false,
                    submit: false,
                    newCellState: false,
                })
            }
            // Make cell editable
        } else {
            this.setState({
                change: true,
            })
        }
    };

    inventoryLitra() {
        const { classes } = this.props;
        const { litra, submit } = this.state;

        let label = ''
        let headline = ''

        // When inventory is in editable state
        if (this.state.change) {
            if (this.props.chosenWorkEnvironment === 'forest') {
                label = this.props.GeoJsonFeature.type === 'LineString' ? "Linje nr." : "Litra nr.";
                headline = this.props.GeoJsonFeature.type === 'LineString' ? "Linje" : "Bevoksningsdata";
            } else if (this.props.chosenWorkEnvironment === 'biotopePlan') {
                label = "Navn eller nr.";
                headline = this.props.GeoJsonFeature.type === 'LineString' ? "Linje" : "Polygon";
            } else {
                label = "Navn eller nr.";
                headline = this.props.GeoJsonFeature.type === 'LineString' ? "Linje" : "Polygon";
            }
            return (
                <div>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
                    >
                        <IconButton
                            onClick={this.goBackClickHandler}
                            style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                        >
                            <GoBackIcon />
                        </IconButton>

                        <Typography
                            variant="h5"
                            gutterBottom
                        >
                            {headline}
                        </Typography >
                    </div>

                    <div>
                        <TextField
                            id="litra"
                            label={label}
                            required
                            className={classes.textField}
                            value={litra}
                            onChange={event => this.setState({ litra: event.target.value, unSavedInventory: true })}
                            error={litra === "" && submit}
                            margin="normal"
                        />
                    </div>
                </div>
            );
        }

        // When inventory is in non-editable state
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
            >
                <IconButton
                    onClick={this.goBackClickHandler}
                    style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                >
                    <GoBackIcon />
                </IconButton>

                <Typography
                    variant="h5"
                    gutterBottom
                >
                    {this.state.litra}
                </Typography >
            </div>
        );
    };

    inventorySpecies() {
        const { classes } = this.props;
        const { species, submit } = this.state;

        let label = "Træart";
        if (this.props.GeoJsonFeature.type === 'LineString') {
            label = "Linje type"
        }

        // When inventory is in editable state
        if (this.state.change) {
            return (
                <TextField
                    disabled
                    id="species"
                    label={label}
                    required
                    className={classes.textField}
                    value={species}
                    onChange={event => this.setState({ species: event.target.value })}
                    error={species === "" && submit}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        return (
            <div>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {label}
                </Typography >
                <div className={classes.nonEditTextStyle}>
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        {this.state.species}
                    </Typography >
                </div>
            </div>
        );
    }

    inventoryArea() {
        const { classes } = this.props;
        let Area = this.state.area;
        let Length = this.state.lineLength;

        if (this.props.cellEditableLatLng) {
            if (this.props.GeoJsonFeature.type === 'Polygon') {
                Area = Math.round(geojsonArea.geometry(this.props.cellEditableLatLng) / 10000 * 100) / 100;
                if (this.state.area !== Area) {
                    this.setState({ area: Area })
                }
                //value = Area.toString().split('.').join(',');
            } else {
                // Get main specie id
                const keys = Object.values(this.state.speciesCallBack).filter(spc => {
                    return spc.main === true;
                })
                let idx = null;
                if (keys.length > 0) {
                    idx = (keys[0].id !== null && keys[0].id !== undefined) ? keys[0].id : keys[0].idBack;
                }

                // Calculate area of line
                const geoJsonFeat = {
                    type: 'Feature',
                    geometry: {
                        type: this.props.cellEditableLatLng.type,
                        coordinates: this.props.cellEditableLatLng.coordinates,
                    },
                    properties: {}
                }

                if ((idx !== null && idx !== undefined) && this.props.cellEditableLatLng.coordinates.length > 0) {
                    let weight = this.state.speciesCallBack[idx].weight ? this.state.speciesCallBack[idx].weight : 1
                    let buffered = buffer(geoJsonFeat, (0.001 * weight * 1.15 + 0), { units: 'kilometers', steps: 20 });
                    Area = Math.round(geojsonArea.geometry(buffered.geometry) / 10000 * 100) / 100;
                } else {
                    Area = 0;
                }

                Length = getLineLength(geoJsonFeat, { units: 'kilometers' }) * 1000;

                if (this.state.area !== Area) {
                    this.setState({ area: Area })
                }

                if (this.state.lineLength !== Length) {
                    this.setState({ lineLength: Length })
                }
            }
        }

        // When inventory is in editable state
        if (this.state.change) {
            return (
                <div>
                    {this.props.GeoJsonFeature.type !== 'Polygon' && <div>
                        <TextField
                            disabled
                            id="lenght"
                            label="Længde"
                            className={classes.textField}
                            value={Length.toFixed(0).toString().split('.').join(',')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }}
                            onChange={event => this.setState({ area: event.target.value })}
                            margin="normal"
                        />
                    </div>}

                    <TextField
                        disabled
                        id="area"
                        label='Areal'
                        className={classes.textField}
                        value={Area.toString().split('.').join(',')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">ha</InputAdornment>,
                        }}
                        onChange={event => this.setState({ area: event.target.value })}
                        margin="normal"
                    />
                </div>
            );
        }

        // When inventory is in non-editable state
        if (this.state.area !== '') {
            return (
                <div>
                    {this.props.GeoJsonFeature.type !== 'Polygon' && <div>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            Længde
                        </Typography >

                        <div className={classes.nonEditTextStyle}>
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                {Length.toFixed(0).toString().split('.').join(',')}
                            </Typography >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ marginBottom: 8 }}
                            >
                                m
                            </Typography >
                        </div>
                    </div>}

                    <div>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            Areal
                        </Typography >

                        <div className={classes.nonEditTextStyle}>
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                {Area.toString().split('.').join(',')}
                            </Typography >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ marginBottom: 8 }}
                            >
                                ha
                            </Typography >
                        </div>
                    </div>
                </div>
            );
        }
    }

    inventoryYear() {
        const { classes } = this.props;
        const { year } = this.state;

        // When inventory is in editable state
        if (this.state.change) {
            return (
                <TextField
                    disabled
                    id="year"
                    label="Årstal"
                    className={classes.textField}
                    value={year}
                    onChange={event => this.setState({ year: event.target.value })}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (this.state.year !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Årstal
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {this.state.year}
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    inventoryMass() {
        const { classes } = this.props;
        const { mass } = this.state;

        const fMass = isNaN(parseFloat(mass)) ? 0 : parseFloat(mass.toString().split(',').join('.'));

        // When inventory is in editable state
        if (this.state.change) {
            if (this.props.GeoJsonFeature.type === 'LineString') {
                return null;
            } else {
                return (
                    <TextField
                        disabled
                        id="mass"
                        label="Vedmasse"
                        className={classes.textField}
                        value={mass ? fMass.toFixed(0).toString() : 0}
                        onChange={event => this.setState({ mass: event.target.value })}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">m3</InputAdornment>,
                        }}
                        margin="normal"
                    />
                );
            }
        }

        // When inventory is in non-editable state
        if (this.state.mass !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Vedmasse
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            {mass ? fMass.toFixed(0).toString() : 0}
                        </Typography >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 8 }}
                        >
                            m3
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    caution() {
        const { classes } = this.props;
        const { caution } = this.state;

        // When inventory is in editable state
        if (this.state.change) {
            return (
                <TextField
                    id="caution"
                    label="OBS"
                    multiline
                    className={classes.textField}
                    value={caution}
                    onChange={event => this.setState({ caution: event.target.value })}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (this.state.caution !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        OBS
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {this.state.caution}
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    note() {
        const { classes } = this.props;
        const { note } = this.state;

        // When inventory is in editable state
        if (this.state.change) {
            return (
                <TextField
                    id="note"
                    label="Bemærkninger"
                    multiline
                    className={classes.textField}
                    value={note}
                    onChange={event => this.setState({ note: event.target.value })}
                    margin="normal"
                />
            );
        }

        // When inventory is in non-editable state
        if (this.state.note !== '') {
            return (
                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Bemærkninger
                    </Typography >
                    <div className={classes.nonEditTextStyle}>
                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {this.state.note}
                        </Typography >
                    </div>
                </div>
            );
        }
    };

    noteDelete() {
        // When inventory is in non-editable state
        if (!this.state.change) {

        }
        // Set action menu items
        const items = [
            {
                name: "Slet",
                text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.onDeleteClickHandler,
                buttonIcon: true
            },
        ]
        // When note is in editable state
        return (
            <DeleteButton
                items={items}
                deleteLoading={this.state.deleteLoading}
                disabled={this.state.unSavedSpecie ? true : this.state.disabled}
            />
        );
    }

    inventoryChangeSave() {
        // When inventory is in edittable state ---------------------
        if (this.state.change) {
            return (
                <div style={{ display: 'flex' }}>
                    <OutlinedButton
                        buttonLabel='Annuller'
                        onPress={this.goBackClickHandler}
                        variant='text'
                        discrete={true}
                    />

                    <ChangeSaveButton
                        change={false}
                        onPress={this.changeSaveButton.bind(this)}
                        saveLoading={this.state.saveLoading}
                        disabled={this.state.unSavedSpecie ? true : this.state.disabled}
                    />
                </div>
            );
        }

        // When inventory is in non-editable state -------------------
        // Forest
        let text = "Litra er skrivebeskyttet. Kan ophæves via hovedmenuen.";
        let showText = false;
        if (!this.props.forestOwnerAllowChange && this.props.chosenWorkEnvironment === 'forest') {
            if (this.props.config.inventorySidebar.underManagementText !== '') {
                text = this.props.config.inventorySidebar.underManagementText;
            } else {
                if (this.props.GeoJsonFeature.type === 'LineString') {
                    text = "Linje er skrivebeskyttet. Kan ophæves via hovedmenuen.";
                }
            }
            showText = true;
        }

        // Hunting
        if (!this.props.forestOwnerAllowChangeHunting && this.props.chosenWorkEnvironment === 'hunting') {
            text = "Polygon er skrivebeskyttet. Kan ophæves via hovedmenuen.";
            if (this.props.GeoJsonFeature.type === 'LineString') {
                text = "Linje er skrivebeskyttet. Kan ophæves via hovedmenuen.";
            }
            showText = true;
        }

        // Biotope plan
        if (this.props.biotopePlansUser[this.props.selectedBiotopePlan] && this.props.biotopePlansUser[this.props.selectedBiotopePlan].locked) {
            text = "Tiltag kan ikke ændres, da biotopplanen er låst."
            showText = true;
        }

        // Other layer
        if (this.props.chosenWorkEnvironment !== this.state.environment) {
            if (this.state.environment || (!this.state.environment && this.props.chosenWorkEnvironment === 'hunting')) {
                text = "Andet kortlag er aktivt. Kan ændres i kortvælgeren.";
                showText = true;
            }
        }

        // Restriction according to user roles
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skovejer"].includes(tag))) {
            if (this.props.GeoJsonFeature.type === 'LineString') {
                text = this.props.chosenWorkEnvironment === "biotopePlan" ? "Tiltag er skrivebeskyttet." : "Linje er skrivebeskyttet.";
            } else {
                text = this.props.chosenWorkEnvironment === "biotopePlan" ? "Tiltag er skrivebeskyttet." : "Litra er skrivebeskyttet.";
            }
            showText = true;
        }

        return (
            <div>
                <ChangeSaveButton
                    change={true}
                    onPress={this.changeSaveButton.bind(this)}
                    saveLoading={this.state.saveLoading}
                    disabled={this.state.disabled || showText ||
                        (this.props.config.generalSettings.underManagement !== '' && this.props.chosenWorkEnvironment === 'forest') ||
                        (this.props.selectedForest !== this.props.forestId && this.props.selectedBiotopePlan !== this.props.selectedBiotopePlan) ||
                        (!this.props.forestOwnerAllowChange && this.state.environment === 'forest') ||
                        (!this.props.forestOwnerAllowChangeHunting && this.state.environment === 'hunting') ||
                        (this.props.chosenWorkEnvironment !== 'hunting' && this.state.environment === 'hunting') ||
                        (this.props.chosenWorkEnvironment === 'hunting' && this.state.environment !== 'hunting')}
                />

                {showText &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginLeft: 10, marginRight: 10, marginBottom: 6, marginTop: 8 }}
                        >
                            {text}
                        </Typography >
                    </div>}
            </div>
        );
    }

    renderTips() {
        const { unSavedSpecie, change, newCellState } = this.state;

        if (!unSavedSpecie && change && !newCellState) {
            return (
                <div style={{ marginLeft: 8, marginRight: 8, marginTop: 8, marginBottom: 8 }}>
                    <Typography
                        variant="body1"
                    >
                        Tip
                    </Typography >

                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="body2"
                            style={{ color: '#6A6A6A', marginLeft: 4, marginRight: 8 }}
                        >
                            •
                        </Typography >

                        <Typography
                            variant="body2"
                            style={{ color: '#6A6A6A' }}
                        >
                            Punkter kan slettes ved at højre-klikke på dem
                        </Typography >
                    </div>
                </div>
            )
        }
    }

    renderUnsavedChanges() {
        const { unSavedSpecie } = this.state;

        if (unSavedSpecie) {
            if ("species" in this.props) {
                return (
                    <Typography variant="body2" style={{ color: '#E23838', marginLeft: 8, marginTop: 8 }} gutterBottom>
                        En, eller flere, dele er ændret uden at blive gemt. Gem for at fortsætte!
                    </Typography>
                )
            }
            return (
                <Typography variant="body2" style={{ color: '#E23838', marginLeft: 8, marginTop: 8 }} gutterBottom>
                    En, eller flere, dele er ændret uden at blive gemt. Tilføj for at fortsætte!
                </Typography>
            )
        }
    }

    renderPanels() {
        const { change, area, submit } = this.state;

        // Check if user is free and only allow one specie
        const onlyOneSpc = this.props.config.inventorySidebar.polygon.onlyOneSpecie === 'enabled';

        // If biotope plan then check if extra panels should be added
        let biotopePlanExtPanels = false;
        let biotopeAction18 = false;
        let biotopePlanType = this.props.selectedBiotopePlan ? this.props.biotopePlansUser[this.props.selectedBiotopePlan].planType : null;
        // Check that feature type is line
        if (this.props.chosenWorkEnvironment === 'biotopePlan' && this.props.GeoJsonFeature.type === 'LineString') {
            // Check for type 18 line
            const type18 = Object.values(this.state.speciesCallBack).filter(el => {
                const par = getFullSpecieParameterList(el.species);
                return par.actionNumber === 18;
            })
            if (type18.length > 0) {
                biotopePlanExtPanels = true;
                biotopeAction18 = true;
                if (this.state.species !== "18: Levende hegn og linjeformede markopdelinger") {
                    this.setState({ species: "18: Levende hegn og linjeformede markopdelinger" })
                }
            }
            if (!biotopeAction18) {
                // Check for type 1 and 2 line
                const type1or2 = Object.values(this.state.speciesCallBack).filter(el => {
                    const par = getFullSpecieParameterList(el.species);
                    return par.actionNumber === 1 || par.actionNumber === 2;
                })
                // Check for length of "species" array
                const spcLen = Object.keys(this.state.speciesCallBack).length;
                if (type1or2.length > 0 && spcLen < 3) {
                    biotopePlanExtPanels = true;
                }
                // Set species type to combination if it fulfills the requirements
                if (type1or2.length > 0 && spcLen === 3) {
                    if (type1or2[0].species === "1: Vegetationsstriber i midtmark" && this.state.species !== "8: Kombination af 3 tiltag, tiltag 1 samt 3-7 samlet") {
                        this.setState({ species: "8: Kombination af 3 tiltag, tiltag 1 samt 3-7 samlet" })
                    } else if (type1or2[0].species === "2: Vegetationsstriber" && this.state.species !== "9: Kombination af 3 tiltag, tiltag 2 samt 3-7 samlet") {
                        this.setState({ species: "9: Kombination af 3 tiltag, tiltag 2 samt 3-7 samlet" })
                    }
                }
            }
        }

        const list = Object.values(this.state.speciesCallBack).map((spc) => {
            return (
                <ControlledExpansionPanels
                    key={spc.id}
                    area={area}
                    type={this.props.GeoJsonFeature.type}
                    geoJsonFeature={this.props.GeoJsonFeature}
                    lidarHeight={this.state.lidarHeight}
                    species={spc}
                    change={change}
                    userRole={this.props.currentAuthUser !== null ? this.props.currentAuthUser.userRole : "free"}
                    callbackFromParent={this.myCallback}
                    new={false}
                    biotopePlanType={biotopePlanType}
                    environment={this.props.chosenWorkEnvironment}
                />
            )
        });
        if ((change && this.props.GeoJsonFeature.type === 'Polygon' && this.props.chosenWorkEnvironment === 'forest' && !onlyOneSpc) || (change && biotopePlanExtPanels) || (change && list.length <= 0)) {
            list.push(
                <ControlledExpansionPanels
                    key={'new'}
                    area={area}
                    type={this.props.GeoJsonFeature.type}
                    geoJsonFeature={this.props.GeoJsonFeature}
                    lidarHeight={this.state.lidarHeight}
                    species={{}}
                    change={change}
                    userRole={this.props.currentAuthUser !== null ? this.props.currentAuthUser.userRole : "free"}
                    callbackFromParent={this.myCallback}
                    new={true}
                    biotopeCombi={biotopeAction18 ? false : (list.length <= 0 ? false : true)}
                    biotopeAction18={biotopeAction18}
                    biotopePlanType={biotopePlanType}
                    environment={this.props.chosenWorkEnvironment}
                    parentSubmit={submit}
                />
            )
        }
        list.sort((a, b) => b.props.species.main - a.props.species.main)
        return list;
    }

    render() {
        const { classes } = this.props;
        const { litra, change, newCellState } = this.state;

        const freeAccount = this.props.chosenWorkEnvironment === 'forest' && this.props.config.inventorySidebar.polygon.categoryExtended !== 'enabled';

        return (
            <div>
                <div>
                    <Card className={classes.card}>
                        <CardContent>
                            {this.inventoryLitra()}
                            {this.inventoryArea()}

                            {(this.props.chosenWorkEnvironment === 'forest' && (this.state.change || this.props.environment === 'forest')) && <div>
                                <DropDownMenu
                                    topic='categories'
                                    freeAccount={freeAccount}
                                    content={categories}
                                    editable={this.state.change}
                                    onPress={(item) => this.onSelectCategories(item)}
                                    selected={this.state.selectedCategory}
                                    inputLabel='Anvendelse'
                                    label='Anvendelse'
                                    type={this.props.GeoJsonFeature.type}
                                    required={true}
                                />

                                {(categories[this.state.selectedCategory].nøglebiotop || categories[this.state.selectedCategory].nøgleelement || categories[this.state.selectedCategory].beskyttetNatur) && <DropDownMenu
                                    topic='registrations'
                                    categoryType={this.state.selectedCategory}
                                    editable={this.state.change}
                                    onPress={(item) => this.onSelectRegistrations(item)}
                                    selected={this.state.selectedRegistration}
                                    inputLabel='Registrering'
                                    label='Registrering'
                                    type={this.props.GeoJsonFeature.type}
                                    required={false}
                                />}
                            </div>}

                            {(this.props.chosenWorkEnvironment === 'forest' && this.props.GeoJsonFeature.type === 'Polygon' && !freeAccount && (this.state.selectedCategory === 'Drift' || this.state.selectedCategory === 'Intensiv drift')) && <div>
                                <SpeciesList
                                    speciesScheme={this.props.speciesScheme}
                                    withDefault={true}
                                    selected={this.state.previousSpecies}
                                    label="Forudgående kultur"
                                    inputLabel="Forudgående kultur"
                                    editable={this.state.change}
                                    onChange={this.onSelectPreviousSpecies}
                                    required={false}
                                    toolTipText={"Tidligere hovedart for litra. Information anvendes blandt andet i forbindelse med certificering"}
                                />
                                <SpeciesList
                                    speciesScheme={this.props.speciesScheme}
                                    withDefault={true}
                                    selected={this.state.futureSpecies}
                                    label="Fremtidskultur"
                                    inputLabel="Fremtidskultur"
                                    editable={this.state.change}
                                    onChange={this.onSelectFutureSpecies}
                                    required={false}
                                    toolTipText={"Fremtidig hovedart for litra. Information anvendes blandt andet i forbindelse med certificering"}
                                />

                            </div>}

                            {this.note()}
                        </CardContent>

                        <CardActions>
                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                    {this.inventoryChangeSave()}
                                    {!newCellState && change && litra !== "" && this.noteDelete()}
                                </div>

                                {this.renderTips()}

                                {this.renderUnsavedChanges()}
                            </div>
                        </CardActions>
                    </Card>

                    <div style={{ marginTop: 8 }}>
                        {this.renderPanels()}
                    </div>
                </div>

                <Dialog open={this.state.showUnsavedWarning}>
                    <DialogTitle>
                        OBS
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Der findes ændringer som endnu ikke er gemte.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Tilbage'
                                onPress={() => this.setState({ showUnsavedWarning: false })}
                                variant='text'
                                discrete={true}
                            />

                            <OutlinedButton
                                buttonLabel='Ændringer skal ikke gemmes'
                                buttonIcon={<IconChevronRight />}
                                iconLeft={false}
                                //onPress={() => this.props.history.replace({ pathname: "/" })}
                                onPress={this.ignoreWarningClickHandler}
                                color='primary'
                            />
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        speciesScheme: state.layout.speciesScheme,
        forestSuccess: state.maps.forestSuccess,
        deleteCell2: state.maps.deleteCell2,
        cells: state.maps.cells,
        cellEditableLatLng: state.maps.cellEditableLatLng,
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        selectedForest: state.customer.currentlySelectedForestId,
        forestOwnerAllowChange: state.maps.forestOwnerAllowChange,
        forestOwnerAllowChangeHunting: state.hunting.forestOwnerAllowChange,
        customerList: state.customer.customerList,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
        config: state.appConfig,
        numNotes: state.layout.numNotes,
        selectedBiotopePlan: state.biotopePlans.currentlySelectedBiotopePlanId,
        biotopePlanFeatures: state.biotopePlans.features,
        biotopePlansUser: state.biotopePlans.biotopePlansUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteCell: (forestId, cells, cellId, envType) => dispatch(actions.deleteCell(forestId, cells, cellId, envType)),
        onSaveForest: (forestId, cellsData, newCellData, envType) => dispatch(actions.saveForest(forestId, cellsData, newCellData, envType)),
        onSetDeleteCell: (s, latlngs) => dispatch(actions.setDeleteCell(s, latlngs)),
        onSetNewCellFinished: (s) => dispatch(actions.setNewCellFinished(s)),
        onSetNewLineFinished: (s) => dispatch(actions.setNewLineFinished(s)),
        onRedrawCells: () => dispatch(actions.redrawCells()),
        onUnmountCell: () => dispatch(actions.unmountCell()),
        onSetCellEditable: (s, id) => dispatch(actions.setCellEditable(s, id)),
        onSaveCellEditable: (s) => dispatch(actions.setCellEditable(s)),
        onSetCellEditableLatLng: (latlng) => dispatch(actions.setCellEditableLatLng(latlng)),
        onSetLeafletLayerRemove: (s) => dispatch(actions.setLeafletLayerRemove(s)),
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onSidebarOpen: (state) => dispatch(actions.sidebarOpen(state)),
        onAddEventToEventLog: (forestId, data) => dispatch(actions.addEventToEventLog(forestId, data)),
        onSaveHuntingFeature: (forestId, revId, cellData) => dispatch(actions.saveHuntingFeature(forestId, revId, cellData)),
        onSetRedrawHuntingFeatures: (s) => dispatch(actions.setRedrawHuntingFeatures(s)),
        onSetFilterState: (s) => dispatch(actions.setFilterState(s)),
        onMainMenuOpen: (state) => dispatch(actions.mainMenuOpen(state)),
        onAccountMenuOpen: (state) => dispatch(actions.accountMenuOpen(state)),
    };
}

Inventory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Inventory)));