import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
//import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
//import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Material UI Icons
import GoBackIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';

// Components
import ImageGridList from '../UI/DisplayImages/ImageGridList';
import ChangeSaveButton from '../UI/Buttons/ChangeSaveButton';
import OutlinedButton from '../UI/Buttons/OutlinedButton';
import DeleteButton from '../UI/Buttons/DeleteButton';
import * as actions from '../../store/actions/index';

const styles = () => ({
    root: {
        textAlign: 'left',
    },
    textField: {
        width: '100%',
        marginTop: 12,
    },
    card: {
        position: 'relative',
        margin: 'auto',
    },

    colorItem: {
        height: 14,
        width: 14,
        marginRight: 6,
        marginBottom: 2,
        borderRadius: 3,
    },
    colorRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export class TextLine extends React.Component {
    constructor(props) {
        super(props);
        // Fields are pre-loaded and note is made editable if it
        // is a new one - e.g. accessed without props
        this.state = {
            type: 'text',
            color: 'black',
            dotted: false,
            wide: false,
            bigFont: false,


            open: false,
            title: ("title" in this.props) ? this.props.title : '',
            from: ("from" in this.props) ? this.props.from : '',
            date: ("date" in this.props) ? this.props.date : '',
            text: ("text" in this.props) ? this.props.text : '',
            images: ("images" in this.props) ? this.props.images : [],
            tag: ("tag" in this.props) ? this.props.tag : '',
            private: ("private" in this.props) ? this.props.private : true,
            change: ("title" in this.props) ? false : true,
            submit: false,
            newNoteState: this.props.title ? false : true,
            saveLoading: false,
            deleteLoading: false,
            disabled: false,
            tempSelectedForest: this.props.selectedForest,
            unSavedData: false,
            showUnsavedWarning: false,
        };
        this.childHandler = this.childHandler.bind(this)
    }

    componentDidUpdate() {
        // Save navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.successNote) {
            this.setState({
                change: false,
                saveLoading: false,
                disabled: false
            })

            this.props.onSetNewNoteFinished(true);

            let path = "/"
            if (this.props.location.state.prevPage === "/Notes") {
                path = "/Notes"
            }

            this.props.history.replace({
                pathname: path,
            });
        }

        // Delete navigation
        // disable spinner, navigate back, and show snackbar
        if (this.props.deleteNote) {
            this.setState({
                change: false,
                deleteLoading: false,
                disabled: false
            })

            this.goBackClickHandler();
        }

        // Handle selected forest change when in inventory
        if (this.state.tempSelectedForest !== this.props.selectedForest) {
            this.goBackClickHandler()
        }
    }

    componentWillUnmount() {
        // Show snackbar if note is saved
        if (this.props.successNote) {
            this.props.onEnqueueSnackbar({
                message: 'Note er nu gemt!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
        }

        // Show snackbar if note is deleted
        if (this.props.deleteNote) {
            this.props.onEnqueueSnackbar({
                message: 'Note er nu slettet!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
        }

        // Set reducer to initial state
        this.props.onUnmountNote();
        // Make note non-editable again
        this.props.onSetNoteEditable(false);
        // Onboarding action if active
        if (this.props.onboardingActive && this.props.onboardingState === 'newNoteEnd') {
            this.props.onSetOnboardingState('measIntro');
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    goBackClickHandler = () => {
        if (!this.state.unSavedData) {
            // set delete new marker state, if note is not saved
            if (this.state.newNoteState) {
                this.props.onSetDeleteMarker(true, this.props.latlng);
                this.props.onSetNewNoteFinished(true);
            }
            // Choose which route to go back to
            let path = "/"
            if (this.props.location.state.prevPage === "/Notes") {
                path = "/Notes"
            }
            this.props.history.replace({
                pathname: path,
            });
        } else {
            this.setState({ showUnsavedWarning: true })
        }
    }

    ignoreWarningClickHandler = () => {
        // set delete new marker state, if note is not saved
        if (this.state.newNoteState) {
            this.props.onSetDeleteMarker(true, this.props.latlng);
            this.props.onSetNewNoteFinished(true);
        }

        // Choose which route to go back to
        let path = "/"
        if (this.props.location.state.prevPage === "/Notes") {
            path = "/Notes"
        }
        this.props.history.replace({
            pathname: path,
        });
    }

    onDeleteClickHandler = () => {
        // Delete note on cloud
        // const noteData = {
        //     id: this.props.id,
        //     forestId: this.props.forestId,
        //     images: this.props.images,
        // }
        //this.props.onDeleteNote(noteData);

        // Send event to Analytics
        ReactGA.event({
            category: 'Note',
            action: 'Note deleted',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        this.setState({
            deleteLoading: true,
            disabled: true,
        })
    }

    upload = () => {
        // Upload action
        // const noteData = {
        //     title: this.state.title,
        //     text: this.state.text,
        //     from: this.props.currentAuthUser.name,
        //     images: this.state.images,
        //     tag: this.state.tag,
        //     private: this.state.private,
        //     latlng: this.props.latlng,
        //     markerId: this.props.markerId,
        //     litraNr: this.props.litraNr,
        //     id: this.props.id,
        //     date: moment().format(),
        //     forestId: this.props.selectedForest
        // }
        //this.props.onSaveNote(noteData, this.props.selectedForest);

        // Send event to Analytics
        ReactGA.event({
            category: 'Note',
            action: 'Note created',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    changeSaveButton = () => {
        // Upload note to cloud 
        if (this.state.change) {
            // Set submit state
            this.setState({
                submit: true
            })

            // Check if note is incomplete.
            //
            // Images: title, images and tag
            // All other tags: title, text, and tag

            // Images
            if (this.state.title !== "" && this.state.images.length > 0 && this.state.tag === "billeder") {
                this.setState({
                    saveLoading: true,
                    disabled: true,
                    submit: false,
                    newNoteState: false,
                })

                this.upload();
            }

            // All other
            if (this.state.title !== "" && this.state.text !== "" && this.state.tag !== "") {
                this.setState({
                    saveLoading: true,
                    disabled: true,
                    submit: false,
                    newNoteState: false,
                })

                this.upload();
            }

            // Make note editable
        } else {
            this.props.onSetNoteEditable(true);
            this.setState({
                change: true
            })
        }
    };

    updateImages = (event) => {
        let files = []
        for (var i = 0; i < event.target.files.length; i++) {
            files[i] = event.target.files.item(i);
        }

        // Add new images
        this.setState({ images: this.state.images.concat(files), unSavedData: true })
    }

    childHandler(dataFromChild) {
        var arr = this.state.images;

        // Delete selected image
        this.setState({
            images: arr.filter(function (item) {
                return item !== dataFromChild
            })
        })
    }

    noteTitle(title) {
        //const { classes } = this.props;
        //const { title, submit } = this.state;

        // When note is in editable state 
        // if (this.state.change) {
        //     return (
        //         <div>
        //             <div
        //                 style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
        //             >
        //                 <IconButton
        //                     onClick={this.goBackClickHandler}
        //                     style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
        //                 >
        //                     <GoBackIcon />
        //                 </IconButton>

        //                 <Typography
        //                     variant="h5"
        //                     gutterBottom
        //                 >
        //                     Note
        //                 </Typography >
        //             </div>

        //             <div>
        //                 <TextField
        //                     id="note-title"
        //                     label="Titel"
        //                     className={classes.textField}
        //                     value={title}
        //                     onChange={event => this.setState({ title: event.target.value, unSavedData: true })}
        //                     error={title === "" && submit}
        //                     disabled={this.state.disabled}
        //                     margin="normal"
        //                     required
        //                 />
        //             </div>
        //         </div>
        //     );
        // }

        // When note is in non-editable state
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
            >
                <IconButton
                    onClick={this.goBackClickHandler}
                    style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                >
                    <GoBackIcon />
                </IconButton>

                <Typography
                    variant="h5"
                    gutterBottom
                >
                    {title}
                </Typography >
            </div>
        );
    };

    noteImages() {
        const { images, change, submit, tag, disabled } = this.state;

        // When note is in non-editable state
        if (change === false && images.length > 0) {
            return (
                <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                    <ImageGridList
                        images={images}
                        change={change}
                    />
                </div>
            );
        }

        // When note is in editable state
        return (
            <ImageGridList
                images={images}
                change={change}
                action={this.childHandler}
                disabled={disabled}
                whenChange={this.updateImages.bind(this)}
                error={submit && change && images.length < 1 && tag === 'billeder'}
            />
        );
    };

    renderText() {
        const { classes } = this.props;
        const { text, submit, tag, bigFont } = this.state;

        // When note is in editable state
        if (this.state.change) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={bigFont}
                                onChange={() => this.setState({ bigFont: bigFont ? false : true })}
                                //value="checkedA"
                                color="primary"
                            />
                        }
                        style={{ marginTop: 12 }}
                        label="Stor skriftstørrelse"
                    />

                    <TextField
                        id="note-text"
                        label="Tekst"
                        multiline
                        className={classes.textField}
                        value={text}
                        onChange={event => this.setState({ text: event.target.value, unSavedData: true })}
                        error={text === "" && submit && tag !== 'billeder'}
                        disabled={this.state.disabled}
                        margin="normal"
                        required
                    />
                </div>
            );
        }

        // When note is in non-editable state
        return (
            <div>
                <Typography
                    variant="body1"
                    style={{ whiteSpace: 'pre-line', marginBottom: 2 }}
                    gutterBottom
                >
                    {this.state.text}
                </Typography>
            </div>
        );
    };

    renderLine() {
        const { classes } = this.props;
        const { color, dotted, wide } = this.state;

        // When line is in editable state
        if (this.state.change) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dotted}
                                onChange={() => this.setState({ dotted: dotted ? false : true })}
                                //value="checkedA"
                                color="primary"
                            />
                        }
                        style={{ marginTop: 12 }}
                        label="Stiplet"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={wide}
                                onChange={() => this.setState({ wide: wide ? false : true })}
                                //value="checkedA"
                                color="primary"
                            />
                        }
                        style={{ marginTop: 12 }}
                        label="Ekstra bred"
                    />

                    <FormControl style={{ marginTop: 14 }}>
                        <InputLabel id="line-color-select">Linjefarve</InputLabel>
                        <Select
                            id="line-color-select"
                            value={color}
                            onChange={(event) => this.setState({ color: event.target.value })}
                        >
                            <MenuItem value={'black'}><div className={classes.colorRow}><div className={classes.colorItem} style={{ backgroundColor: 'black' }} />Sort</div></MenuItem>
                            <MenuItem value={'#E23838'}><div className={classes.colorRow}><div className={classes.colorItem} style={{ backgroundColor: '#E23838' }} />Rød</div></MenuItem>
                            <MenuItem value={'#FFB900'}><div className={classes.colorRow}><div className={classes.colorItem} style={{ backgroundColor: '#FFB900' }} />Gul</div></MenuItem>
                            <MenuItem value={'#5EBD3E'}><div className={classes.colorRow}><div className={classes.colorItem} style={{ backgroundColor: '#5EBD3E' }} />Grøn</div></MenuItem>
                            <MenuItem value={'#009CDF'}><div className={classes.colorRow}><div className={classes.colorItem} style={{ backgroundColor: '#009CDF' }} />Blå</div></MenuItem>
                        </Select>
                    </FormControl>
                </div>
            );
        }

        // When note is in non-editable state
        return (
            <div>
                hej
            </div>
        );
    }

    noteCancelDelete() {
        // When note is in non-editable state
        if (!this.state.change) {

        }

        // When note is in editable state
        // Set action menu
        const items = [
            {
                name: "Slet",
                text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.onDeleteClickHandler,
                buttonIcon: true
            },
        ]
        // When note is in editable state
        return (
            <DeleteButton
                items={items}
                deleteLoading={this.state.deleteLoading}
                disabled={this.state.disabled}
            />
        );
    }

    noteChangeSave() {
        // When note is in non-editfable state
        if (this.state.change) {
            return (
                <ChangeSaveButton
                    change={false}
                    onPress={this.changeSaveButton.bind(this)}
                    saveLoading={this.state.saveLoading}
                    disabled={this.state.disabled}
                />
            );
        }

        // When note is in editable state
        return (
            <ChangeSaveButton
                change={true}
                onPress={this.changeSaveButton.bind(this)}
                saveLoading={this.state.saveLoading}
                disabled={this.state.disabled || this.props.selectedForest !== this.props.forestId}
            />
        );
    }

    render() {
        const { classes } = this.props;
        //const { title, text, change, tag, newNoteState } = this.state;
        const { text, change, newNoteState } = this.state;
        let title = this.state.type === 'text' ? "Tekst" : "Linje";

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        {this.noteTitle(title)}

                        {this.state.type === 'text' && this.renderText()}
                        {this.state.type === 'line' && this.renderLine()}

                        {/* {this.noteImages()} */}
                    </CardContent>

                    <CardActions>
                        {!newNoteState && change && title !== "" && text !== "" && this.noteCancelDelete()}
                        {this.noteChangeSave()}
                    </CardActions>
                </Card>

                <Dialog open={this.state.showUnsavedWarning}>
                    <DialogTitle>
                        OBS
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Der findes ændringer som endnu ikke er gemte.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Tilbage'
                                onPress={() => this.setState({ showUnsavedWarning: false })}
                                variant='text'
                                discrete={true}
                            />
                            <OutlinedButton
                                buttonLabel='Ændringer skal ikke gemmes'
                                buttonIcon={<IconChevronRight />}
                                iconLeft={false}
                                onPress={this.ignoreWarningClickHandler}
                                color='primary'
                            />
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        successNote: state.notes.successNote,
        deleteNote: state.notes.deleteNote,
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        selectedForest: state.customer.currentlySelectedForestId,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveNote: (noteData, forestId) => dispatch(actions.saveNote(noteData, forestId)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onUnmountNote: () => dispatch(actions.unmountNote()),
        onDeleteNote: (noteData) => dispatch(actions.deleteNote(noteData)),
        onSetDeleteMarker: (s, latlng) => dispatch(actions.setDeleteMarker(s, latlng)),
        onSetNewNoteFinished: (s) => dispatch(actions.setNewNoteFinished(s)),
        onSetNoteEditable: (s) => dispatch(actions.setNoteEditable(s)),
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
    };
}

TextLine.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TextLine)));