import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

// Material UI Icons
import ReplayIcon from '@material-ui/icons/ReplayRounded';


// Components

const styles = () => ({
    // Menu Containers
    menuContainer: {
        padding: 16,
    },
    // Label
    valueLabel: {
        top: -16,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    }
});

class Filter extends React.Component {
    valueLabelFormat(value) {
        return `${value.toString().split('.').join(',')}`;
    }

    render() {
        const {
            onFilterChangeAll,
            onFilterChange,

            allTaskStatusChecked,
            allTaskStatus,

            allTaskCategoriesChecked,
            allTaskCategories,
            
            allTasksChecked,
            allTasks,
            
            deadlineExceededChecked,

            allTaskTypesChecked,
            allTaskTypes,

            allResponsibleChecked,
            allResponsible,
            
            allForestNamesChecked,
            allForestNames,

            resetFilters,
        } = this.props;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/*Task Status*/}
                    {(this.props.taskStatus === 'enabled' && allTaskStatus.length > 0 ) && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                        <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            Opgave status
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allTaskStatusChecked}
                                    onChange={() => onFilterChangeAll('taskStatus')}
                                    name="checkedAll"
                                    color="primary"
                                />
                            }
                            label="Alle status"
                        />

                        {allTaskStatus.map((taskGroup, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={taskGroup.checked}
                                        onChange={() => onFilterChange(taskGroup, i, 'taskStatus')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={taskGroup.label}
                            />
                        ))}

                    </div>}
                    {this.props.taskStatus === 'enabled' && <Divider style={{ marginBottom: 12 }} />}
                    
                    {/* Task groups */}
                    {(this.props.taskCategories === 'enabled' && allTaskCategories.length > 0 ) && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                        <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            Kategorier
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allTaskCategoriesChecked}
                                    onChange={() => onFilterChangeAll('taskCategories')}
                                    name="checkedAll"
                                    color="primary"
                                />
                            }
                            label="Alle kategorier"
                        />

                        {allTaskCategories.map((taskGroup, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={taskGroup.checked}
                                        onChange={() => onFilterChange(taskGroup, i, 'taskCategories')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={taskGroup.label}
                            />
                        ))}

                    </div>}
                    {(this.props.taskCategories === 'enabled' && allTaskCategories.length > 0 ) && <Divider style={{ marginBottom: 12 }} />}
                    
                    {/* Tasks */}
                    {(this.props.tasksFilter === 'enabled' && allTasks.length > 0) && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                        <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            Opgaver
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allTasksChecked}
                                    onChange={() => onFilterChangeAll('tasks')}
                                    name="checkedAll"
                                    color="primary"
                                />
                            }
                            label="Alle opgaver"
                        />

                        {allTasks.map((task, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={task.checked}
                                        onChange={() => onFilterChange(task, i, 'tasks')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={task.label}
                            />
                        ))}

                    </div>}
                    {(this.props.tasksFilter === 'enabled' && allTasks.length > 0) && <Divider style={{ marginBottom: 12 }} />}

                    {/* Deadline */}
                    {this.props.deadlineFilter === 'enabled' && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                        <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            Skal gøres inden
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={deadlineExceededChecked}
                                    onChange={() => onFilterChange(null, 0, 'deadlineExceeded')}
                                    name="checkedDeadlineExceeded"
                                    color="primary"
                                />
                            }
                            label="Dato overskredet"
                        />
                    </div>}
                    {this.props.deadlineFilter === 'enabled' && <Divider style={{ marginBottom: 12 }} />}

                    {/* Task types */}
                    {(this.props.taskCategoriesFilter === 'enabled' && allTaskTypes.length > 0) && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                        <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            Grupper
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allTaskTypesChecked}
                                    onChange={() => onFilterChangeAll('taskTypes')}
                                    name="checkedAll"
                                    color="primary"
                                />
                            }
                            label="Alle grupper"
                        />

                        {allTaskTypes.map((task, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={task.checked}
                                        onChange={() => onFilterChange(task, i, 'taskTypes')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={task.label}
                            />
                        ))}

                    </div>}
                    {(this.props.taskCategoriesFilter === 'enabled' && allTaskTypes.length > 0) && <Divider style={{ marginBottom: 12 }} />}

                     {/* Responsible */}
                     {(this.props.responsibleFilter === 'enabled' && allResponsible.length > 0) && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                        <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            Ansvarlige
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allResponsibleChecked}
                                    onChange={() => onFilterChangeAll('responsible')}
                                    name="checkedAll"
                                    color="primary"
                                />
                            }
                            label="Alle ansvarlige"
                        />

                        {allResponsible.map((task, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={task.checked}
                                        onChange={() => onFilterChange(task, i, 'responsible')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={task.label}
                            />
                        ))}

                    </div>}
                    {(this.props.responsibleFilter === 'enabled' && allResponsible.length > 0) && <Divider style={{ marginBottom: 12 }} />}

                    {/* Forest Names */}
                    {this.props.forestNamesFilter === 'enabled' && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 32 }}>
                        <Typography align='left' variant="body1" style={{ marginRight: 12 }}>
                            Skov
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allForestNamesChecked}
                                    onChange={() => onFilterChangeAll('forestNames')}
                                    name="checkedAll"
                                    color="primary"
                                />
                            }
                            label="Alle skove"
                        />

                        {allForestNames.map((task, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                        checked={task.checked}
                                        onChange={() => onFilterChange(task, i, 'forestNames')}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={task.label}
                            />
                        ))}

                    </div>}
                    {this.props.forestNamesFilter === 'enabled' && <Divider style={{ marginBottom: 12 }} />}

                    <Button
                        color="primary"
                        onClick={resetFilters}
                        fullWidth={true}
                    >
                        Nulstil filter

                        <ReplayIcon style={{ marginLeft: 6 }} />
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

Filter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Filter)));
