import React from 'react';
import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Note from '../components/Note/NewNote';

const overviewMapNewNote = (props) => {
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <Note
                            latlng = {props.location.state.latlng}
                            markerId = {props.location.state.markerId}
                        />
                    </div>
                } />
            </Aux>
        );

}

export default overviewMapNewNote;