import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import moment from 'moment';

// Material UI
import { withStyles, fade } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';

// Material UI Icons
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ReplayIcon from '@material-ui/icons/ReplayRounded';
import SearchIcon from '@material-ui/icons/SearchRounded';
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';

// Components
import Filter from './Components/Filter';
import Column from './Components/Column';
import { DragDropContext } from 'react-beautiful-dnd';
import { getTaskProps } from '../TaskPlanning/Utility/tasksUtils';
import * as actions from '../../store/actions/index';
// Links
// Søg eks. https://codesandbox.io/s/eloquent-clarke-w1ehv?file=/src/index.js:2624-2634
// Material UI V3. https://v3.material-ui.com/demos/tables/#tables

const styles = theme => ({
    paper: { // ok
        width: '100%',
    },
    root: { // ok
        zIndex: 102,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        padding: 20,
    },
    table: {
        width: '100%',
        borderBottom: '1px solid #E0E0E0'
    },
    boardWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
        padding: '8px 8px',
        borderTop: '1px solid #E0E0E0',
    },
    // Toolbar
    toolbarRoot: {
        paddingRight: theme.spacing.unit,
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    title: {
        flex: '0 0 auto',
    },
    // Menu Containers
    menuContainer: {
        padding: 16,
    },
    naviContainer: {
        paddingLeft: 8,
        paddingRight: 8
    },
    // Search
    search: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0,
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    cellNoBorder: {
        borderBottom: "none",
        whiteSpace: 'pre-line'
    },
    cellStyle: {
        borderTop: '1px solid #E0E0E0',
        whiteSpace: 'pre-line'
    },
    cellStyleItalic: {
        borderTop: '1px solid #E0E0E0',
        fontStyle: 'italic',
    },
    bodyFontItalic: {
        fontStyle: 'italic',
    },
    kanbanContainer: {
        display: 'flex',
    }
});

class KanbanBoard extends Component {
    state = {
        updateContent: true,
        // Table setup
        anchorEl: null,
        anchorEb: null,
        anchorNa: {
            xPos: null,
            yPos: null,
        },
        naviData: null,
        searchText: '',
        height: window.innerHeight,

        // Column data
        columns: {
            'column-0': {
                id: 'column-0',
                title: 'Ikke planlagt',
                taskIds: []
            },
            'column-1': {
                id: 'column-1',
                title: 'Ikke startet',
                taskIds: []
            },
            'column-2': {
                id: 'column-2',
                title: 'I gang',
                taskIds: []
            },
            'column-3': {
                id: 'column-3',
                title: 'Udført',
                taskIds: []
            },
            'column-4': {
                id: 'column-4',
                title: 'Arkiveret',
                taskIds: []
            },
        },
        // Facilitate reordering of the columns
        columnOrder: ['column-0', 'column-1', 'column-2', 'column-3', 'column-4'],

        // Tasks
        tasks: [],

        // Filters
        filterTaskStatusActive: false,
        filterTaskStatus: [],
        filterDeadlineExceededActive: false,
        filterTaskCategoriesActive: false,
        filterAllTaskCategories: [],
        filterTasksActive: false,
        filterAllTasks: [], //getTaskList().map(t => { return { label: t[0], checked: true } }),
        filterTaskTypesActive: false,
        filterAllTaskTypes: this.props.orgTaskTypes ? this.props.orgTaskTypes.sort((a, b) => a.localeCompare(b, 'da-DK')).map(t => { return { label: t, checked: true } }) : this.props.defaultTasksTypes.map(t => { return { label: t, checked: true } }),
        filterResponsibleActive: false,
        filterAllResponsible: this.props.orgUsers ? Object.values(this.props.orgUsers).sort((a, b) => a.name.localeCompare(b.name, 'da-DK')).map(t => { return { label: t.name, checked: true } }) : [{ label: this.props.currentAuthUser.name, checked: true }],
        filterForestNamesActive: false,
        filterAllForestNames: [],
    };

    componentDidMount() {
        // Initialize task data object
        const t = this.createTaskList(true);
        if (this.props.filterState) {
            this.setState({
                filterTaskStatus: this.props.filterState.rFilterTaskStatus,
                filterAllTaskCategories: this.props.filterState.rFilterAllTaskCategories,
                filterAllTasks: this.props.filterState.rFilterAllTasks,
                filterAllResponsible: this.props.filterState.rFilterAllResponsible,
            })
        } else {
            this.setState({ 
                filterTaskStatus: t.taskStatus,
                filterAllTaskCategories: t.taskCategories, 
                filterAllTasks: t.tasks, 
                filterAllResponsible: t.responsible 
            })
        }
        this.initFilters(t.forestNames, t.taskStatus, t.taskList, t.taskCategories, t.tasks, t.responsible);

        // Send event to Analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Kanban Board opened`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        // Add window size event listner
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        // Set filter reducer state when unmounting
        this.props.onSetFilterState({
            rFilterTaskStatusActive: this.state.filterTaskStatusActive,
            rFilterTaskStatus: this.state.filterTaskStatus,
            rFilterDeadlineExceededActive: this.state.filterDeadlineExceededActive,
            rFilterTaskCategoriesActive: this.state.filterTaskCategoriesActive,
            rFilterAllTaskCategories: this.state.filterAllTaskCategories,
            rFilterTasksActive: this.state.filterTasksActive,
            rFilterAllTasks: this.state.filterAllTasks,
            rFilterTaskTypesActive: this.state.filterTaskTypesActive,
            rFilterAllTaskTypes: this.state.filterAllTaskTypes,
            rFilterResponsibleActive: this.state.filterResponsibleActive,
            rFilterAllResponsible: this.state.filterAllResponsible,
            rFilterForestNamesActive: this.state.filterForestNamesActive,
            rFilterAllForestNames: this.state.filterAllForestNames,
            searchText: this.state.searchText,
        })

        // Set task ids to show in reducer
        let taskIds = [];
        Object.values(this.state.tasks).forEach(el => {
            if (el.display) {
                taskIds.push(el.id);
            }
        })
        this.props.onSetFilterObjectIds(taskIds);
        // Send event to Analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Kanban board closed`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        // Remove eventlistners 
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    componentDidUpdate() {
        // Check if notes have been changed by other user
        if (this.props.insertNotes && this.props.notes) {
            setTimeout(() => {
                if (this.checkTasks(this.state.tasks, this.props.notes)) {
                    const ob = this.createTaskList();
                    this.setState({ tasks: ob.taskList });
                }
            }, 400);
        }
        if (this.state.updateContent) {
            // Apply filters
            this.applyAllFilters(
                this.state.tasks,
                this.state.filterTaskStatus,
                this.state.filterDeadlineExceededActive,
                this.state.filterAllTaskCategories,
                this.state.filterAllTasks,
                this.state.filterAllTaskTypes,
                this.state.filterAllResponsible,
                this.state.filterAllForestNames,
                []);
            this.setState({ updateContent: false })
        }
        // Reset show filter in map, if no filters are active
        if (!this.state.filterDeadlineExceededActive && !this.state.filterTasksActive && !this.state.filterTaskTypesActive && !this.state.filterResponsibleActive && !this.state.filterForestNamesActive && this.props.filterShowFilterInMap) {
            this.props.onSetShowFilterInMap(false);
        }
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight });
    };

    // Create task list for Kanban Board
    createTaskList = (updateFilterTaskList) => {
        // Initialize task data object
        let taskList = {};
        let tasks = [];
        let taskStatus = Object.values(this.state.columns).map(el => { return {label: el.title, checked: true}});
        let taskNames = [];
        let taskCategories = [];
        let taskGroupNames = [];
        let forestNames = [];
        let responsible = [];
        let responsibleNames = [];
        if (this.props.notes) {
            if (this.props.selectedForest) {
                Object.values(this.props.notes[this.props.selectedForest]).forEach(note => {
                    if ((!note.private && note.tag === 'todo') || ((note.private && note.userUID === this.props.currentAuthUser.id) && note.tag === 'todo')) {
                        // Set responsible
                        if (updateFilterTaskList && note.responsible && note.responsible.length > 0) {
                            note.responsible.forEach(resp => {
                                if (!responsibleNames.includes(resp)) {
                                    responsibleNames.push(resp);
                                    responsible.push({ label: resp, checked: true });
                                }
                            })
                        }
                        // Set task list data for filter
                        let groups = [];
                        if (updateFilterTaskList && note.tasks && note.tasks.length > 0) {
                            note.tasks.forEach(task => {
                                // Check group
                                if (getTaskProps(task)) {
                                    if (!groups.includes(getTaskProps(task).group)) groups.push(getTaskProps(task).group);
                                    // Check groups for filter
                                    if (!taskGroupNames.includes(getTaskProps(task).group)) {
                                        taskGroupNames.push(getTaskProps(task).group);
                                        taskCategories.push({ label: getTaskProps(task).group, checked: true })
                                    }
                                }
                                // Set task category
                                if (!taskNames.includes(task)) {
                                    taskNames.push(task);
                                    tasks.push({ label: task, checked: true });
                                }
                            })
                        }

                        // Set task data
                        taskList[note.id] = {
                            ...note,
                            priority: note.priority ? note.priority : 0,
                            display: true,
                            taskCategories: groups,
                            forestName: this.props.customerList[note.forestId].forestName,
                        };
                    }
                })
                forestNames.push(this.props.customerList[this.props.selectedForest].forestName);
            } else {
                Object.values(this.props.notes).forEach(forest => {
                    forest.forEach(note => {
                        if ((!note.private && note.tag === 'todo') || ((note.private && note.userUID === this.props.currentAuthUser.id) && note.tag === 'todo')) {
                            if (updateFilterTaskList && note.responsible && note.responsible.length > 0) {
                                note.responsible.forEach(resp => {
                                    if (!responsibleNames.includes(resp)) {
                                        responsibleNames.push(resp);
                                        responsible.push({ label: resp, checked: true });
                                    }
                                })
                            }
                            // Set task list data for filter
                            let groups = [];
                            if (updateFilterTaskList && note.tasks && note.tasks.length > 0) {
                                note.tasks.forEach(task => {
                                    // Check group
                                    if (getTaskProps(task)) {
                                        if (!groups.includes(getTaskProps(task).group)) groups.push(getTaskProps(task).group);
                                        // Check groups for filter
                                        if (!taskGroupNames.includes(getTaskProps(task).group)) {
                                            taskGroupNames.push(getTaskProps(task).group);
                                            taskCategories.push({ label: getTaskProps(task).group, checked: true })
                                        }
                                    }
                                    // Check task
                                    if (!taskNames.includes(task)) {
                                        taskNames.push(task);
                                        tasks.push({ label: task, checked: true });
                                    }
                                })
                            }
                            // Set task data
                            taskList[note.id] = {
                                ...note,
                                priority: note.priority ? note.priority : 0,
                                display: true,
                                taskCategories: groups,
                                forestName: this.props.customerList[note.forestId].forestName,
                            };
                        }
                    });
                })
                forestNames = Object.values(this.props.customerList).map(el => {
                    return el.forestName;
                })
            }
        }

        // Set indexes of tasks to display
        // Start by sorting the task list from lowest priority number (highest priority) to highest priority number (lowest priority)
        const sortedTasks = Object.values(taskList).sort((a, b) => (a.priority - b.priority));
        // let updatedColumns = { ...this.state.columns };
        let updatedColumns = {};
        Object.values(this.state.columns).forEach(col => {
            let taskIds = [];
            Object.values(sortedTasks).forEach(task => {
                if (task.todoStatus === col.title) {
                    // Insert task id into the column array of task id's
                    taskIds.push(task.id);
                }
            })
            updatedColumns[col.id] = { ...col, taskIds };
        })
        this.setState({ columns: updatedColumns });

        return { forestNames, taskList, tasks, taskStatus, taskCategories, responsible }
    }

    initFilters = (forestNames, taskStatus, taskList, taskCategories, tasks, responsible) => {
        // Set forest names data in state
        const fN = forestNames.sort((a, b) => a.localeCompare(b, 'da-DK')).map(f => { return { label: f, checked: true } });
        this.setState({ filterAllForestNames: fN })

        // Init filters
        if (this.props.filterState) {
            // Check if filter reducer state has all forest names, but a specific forest has been selected
            let fNames = this.props.filterState.rFilterAllForestNames;
            let fNamesActive = this.props.filterState.rFilterForestNamesActive;
            if ((this.props.selectedForest && fNames.length > 1) || (this.props.selectedForest && fNames[0] !== this.props.customerList[this.props.selectedForest].forestName)) {
                fNames = fN;
                fNamesActive = false;
            } else if (!this.props.selectedForest && fNames.length !== fN.length) {
                fNames = fN;
                fNamesActive = false;
            }
            // Check if show filters in map should be reset
            if (!this.props.filterState.rFilterTaskStatusActive && !this.props.filterState.rFilterDeadlineExceededActive && !this.props.filterState.rFilterTasksActive && !this.props.filterState.rFilterTaskTypesActive && !this.props.filterState.rFilterResponsibleActive && !fNamesActive) {
                this.props.onSetShowFilterInMap(false);
            }
            // Set filter task status
            // Set reducer filter values in state
            this.setState({
                filterTaskStatusActive: this.props.filterState.rFilterTaskStatusActive,
                filterTaskStatus: this.props.filterState.rFilterTaskStatus,
                filterDeadlineExceededActive: this.props.filterState.rFilterDeadlineExceededActive,
                filterTaskCategoriesActive: this.props.filterState.rFilterTaskCategoriesActive,
                filterAllTaskCategories: this.props.filterState.rFilterAllTaskCategories,
                filterTasksActive: this.props.filterState.rFilterTasksActive,
                filterAllTasks: this.props.filterState.rFilterAllTasks,
                filterTaskTypesActive: this.props.filterState.rFilterTaskTypesActive,
                filterAllTaskTypes: this.props.filterState.rFilterAllTaskTypes,
                filterResponsibleActive: this.props.filterState.rFilterResponsibleActive,
                filterAllResponsible: this.props.filterState.rFilterAllResponsible,
                filterForestNamesActive: fNamesActive,
                filterAllForestNames: fNames,
                searchText: this.props.filterState.searchText,
            })
            this.applyAllFilters(
                taskList,
                this.props.filterState.rFilterTaskStatus,
                this.props.filterState.rFilterDeadlineExceededActive,
                this.props.filterState.rFilterAllTaskCategories,
                this.props.filterState.rFilterAllTasks,
                this.props.filterState.rFilterAllTaskTypes,
                this.props.filterState.rFilterAllResponsible,
                fNames,
                []);
        } else {
            this.applyAllFilters(
                taskList,
                taskStatus ? taskStatus : this.state.filterTaskStatus,
                this.state.filterDeadlineExceededActive,
                taskCategories ? taskCategories : this.state.filterAllTaskCategories,
                tasks ? tasks : this.state.filterAllTasks,
                this.state.filterAllTaskTypes,
                responsible ? responsible : this.state.filterAllResponsible,
                fN, []
            );
        }
    }

    // Check if notes have been changed by other users
    // TODO : update columns directly instead of using function to call this.createTaskList()
    // Could enhance performance if many tasks are present.
    checkTasks = (tasks, notes) => {
        let update = false;
        Object.values(tasks).forEach(task => {
            let taskFound = false;
            const arr = notes[task.forestId].filter(el => {
                // Check if task has been deleted
                if (task.id === el.id) taskFound = true;
                // Check if status of task has changed by another user
                return el.id === task.id && el.todoStatus !== task.todoStatus;
            })
            if (arr.length > 0 || !taskFound) {
                update = true;
            }
        })
        return update;
    }

    // Apply filters
    applyAllFilters = (tasks, taskStatusFilter, deadlineExceeded, taskCategoriesFilter, tasksFilter, taskTypesFilter, responsibleFilter, forestNamesFilter, deadlineRangeFilter) => {
        // if (tasks.length === 0 || (taskTypesFilter.length === 0 && responsibleFilter.length === 0 && groupsFilter.length === 0 && deadlineRangeFilter.length === 0)) {
        //     return tasks;
        // }
        // Run through tasks and see any filters apply
        let newTasks = { ...tasks };
        // Run through all tasks and check if they should be displayed
        for (let key of Object.keys(newTasks)) {
            let disp = false;
            // Task status filter
            // Deadline exceeded filter
            if (deadlineExceeded) {
                if (newTasks[key].taskDeadline && newTasks[key].taskDeadline !== "") {
                    if (moment().isSameOrAfter(moment(newTasks[key].taskDeadline), 'day')) {
                        disp = true;
                    }
                }
            } else {
                disp = true;
            }
            // Task status filter
            if (disp) {
                disp = this.applyFilter([newTasks[key].todoStatus], taskStatusFilter);
            }
            // Task group filter
            if (disp) {
                disp = this.applyFilter(newTasks[key].taskCategories, taskCategoriesFilter);
            }

            // Task filter
            if (disp) {
                disp = this.applyFilter(newTasks[key].tasks, tasksFilter);
            }
            // Task type filter
            if (disp) {
                disp = this.applyFilter(newTasks[key].taskTypes, taskTypesFilter);
            }

            // Responsible filter
            if (disp) {
                disp = this.applyFilter(newTasks[key].responsible, responsibleFilter);
            }

            // Forest name filter
            if (disp) {
                disp = this.applyFilter([newTasks[key].forestName], forestNamesFilter);
            }

            // Search text filter
            if (disp && this.state.searchText.length > 1) {
                const searchTxt = this.state.searchText.toLowerCase();
                let searchFound = false;
                // run through task items and check if searct text is found
                const { display, forestId, id, images, kwSync, latlng, priority, tag, ...partielObject } = newTasks[key];
                // Run through task values and check for search text
                for (let pKey of Object.keys(partielObject)) {
                    if (typeof partielObject[pKey] === 'string') {
                        const text = partielObject[pKey].toLowerCase();
                        if (text.indexOf(searchTxt) !== -1) {
                            searchFound = true;
                            break;
                        }
                    } else if (Array.isArray(partielObject[pKey]) && partielObject[pKey].length > 0) {
                        for (let i = 0; i < partielObject[pKey].length; i++) {
                            if (typeof partielObject[pKey][i] === 'string') {
                                const text = partielObject[pKey][i].toLowerCase();
                                if (text.indexOf(searchTxt) !== -1) {
                                    searchFound = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                disp = searchFound;
            }
            newTasks[key].display = disp;
        }

        this.setState({ tasks: newTasks })
    }

    applyFilter = (taskOption, filterValues) => {
        let result = false;
        let filterLabels = [];
        for (const item of filterValues) { if (item.checked) { filterLabels.push(item.label) } };
        // Check if task should be displayed based on filter values
        if (taskOption && taskOption.length === 0 && (filterLabels.length === 0 || filterLabels.length === filterValues.length)) {
            result = true;
        } else if (taskOption && taskOption.length > 0 && filterLabels.length > 0) {
            if (taskOption.some(t => filterLabels.includes(t))) {
                result = true;
            }
        }
        return result;
    }

    onFilterChangeAll = (type) => {
        if (type === 'taskStatus') {
            let arr = [...this.state.filterTaskStatus];
            let currentFilterState = false;
            for (const item of arr) {
                if (this.state.filterTaskStatusActive) {
                    item.checked = true
                } else {
                    item.checked = false
                    currentFilterState = true
                }
            }
            this.setState({ filterTaskStatus: arr, updateContent: true, filterTaskStatusActive: currentFilterState })
        } else if (type === 'taskCategories') {
            let arr = [...this.state.filterAllTaskCategories];
            let currentFilterState = false;
            for (const item of arr) {
                if (this.state.filterTaskCategoriesActive) {
                    item.checked = true
                } else {
                    item.checked = false
                    currentFilterState = true
                }
            }
            this.setState({ filterAllTaskCategories: arr, updateContent: true, filterTaskCategoriesActive: currentFilterState })
        } else if (type === 'tasks') {
            let arr = [...this.state.filterAllTasks];
            let currentFilterState = false;
            for (const item of arr) {
                if (this.state.filterTasksActive) {
                    item.checked = true
                } else {
                    item.checked = false
                    currentFilterState = true
                }
            }
            this.setState({ filterAllTasks: arr, updateContent: true, filterTasksActive: currentFilterState })
        } else if (type === 'taskTypes') {
            let arr = [...this.state.filterAllTaskTypes];
            let currentFilterState = false;
            for (const item of arr) {
                if (this.state.filterTaskTypesActive) {
                    item.checked = true
                } else {
                    item.checked = false
                    currentFilterState = true
                }
            }
            this.setState({ filterAllTaskTypes: arr, updateContent: true, filterTaskTypesActive: currentFilterState })
        } else if (type === 'responsible') {
            let arr = [...this.state.filterAllResponsible];
            let currentFilterState = false;
            for (const item of arr) {
                if (this.state.filterResponsibleActive) {
                    item.checked = true
                } else {
                    item.checked = false
                    currentFilterState = true
                }
            }
            this.setState({ filterAllResponsible: arr, updateContent: true, filterResponsibleActive: currentFilterState })
        } else if (type === 'forestNames') {
            let arr = [...this.state.filterAllForestNames];
            let currentFilterState = false;
            for (const item of arr) {
                if (this.state.filterForestNamesActive) {
                    item.checked = true
                } else {
                    item.checked = false
                    currentFilterState = true
                }
            }
            this.setState({ filterAllForestNames: arr, updateContent: true, filterForestNamesActive: currentFilterState })
        }
        // Send event to Analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Filter select all in catagori: ${type}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    onChangeFilterHandler = (inputString, i, type) => {
        if (type === 'taskStatus') {
            let arr = [...this.state.filterTaskStatus];
            let currentFilterState = false;
            arr[i].checked = !arr[i].checked;
            for (const item of this.state.filterTaskStatus) {
                if (!item.checked) {
                    currentFilterState = true;
                }
            }
            this.setState({ filterTaskStatus: arr, updateContent: true, filterTaskStatusActive: currentFilterState })
        } else if (type === 'taskCategories') {
            let arr = [...this.state.filterAllTaskCategories];
            let currentFilterState = false;
            arr[i].checked = !arr[i].checked;
            for (const item of this.state.filterAllTaskCategories) {
                if (!item.checked) {
                    currentFilterState = true;
                }
            }
            this.setState({ filterAllTaskCategories: arr, updateContent: true, filterTaskCategoriesActive: currentFilterState })
        } else if (type === 'tasks') {
            let arr = [...this.state.filterAllTasks];
            let currentFilterState = false;
            arr[i].checked = !arr[i].checked;
            for (const item of this.state.filterAllTasks) {
                if (!item.checked) {
                    currentFilterState = true;
                }
            }
            this.setState({ filterAllTasks: arr, updateContent: true, filterTasksActive: currentFilterState })
        } else if (type === 'taskTypes') {
            let arr = [...this.state.filterAllTaskTypes];
            let currentFilterState = false;
            arr[i].checked = !arr[i].checked;
            for (const item of this.state.filterAllTaskTypes) {
                if (!item.checked) {
                    currentFilterState = true;
                }
            }
            this.setState({ filterAllTaskTypes: arr, updateContent: true, filterTaskTypesActive: currentFilterState })
        } else if (type === 'responsible') {
            let arr = [...this.state.filterAllResponsible];
            let currentFilterState = false;
            arr[i].checked = !arr[i].checked;
            for (const item of this.state.filterAllResponsible) {
                if (!item.checked) {
                    currentFilterState = true;
                }
            }
            this.setState({ filterAllResponsible: arr, updateContent: true, filterResponsibleActive: currentFilterState })
        } else if (type === 'forestNames') {
            let arr = [...this.state.filterAllForestNames];
            let currentFilterState = false;
            arr[i].checked = !arr[i].checked;
            for (const item of this.state.filterAllForestNames) {
                if (!item.checked) {
                    currentFilterState = true;
                }
            }
            this.setState({ filterAllForestNames: arr, updateContent: true, filterForestNamesActive: currentFilterState })
        } else if (type === 'deadlineExceeded') {
            this.setState({ filterDeadlineExceededActive: !this.state.filterDeadlineExceededActive, updateContent: true })
        }
    }

    applyFilters = () => {
        // Send event to analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Apply filters`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ updateContent: true })
    }


    resetFilters = () => {
        this.state.filterTaskStatus.forEach(el => el.checked = true);
        this.state.filterAllTaskCategories.forEach(el => el.checked = true);
        this.state.filterAllTasks.forEach(el => el.checked = true);
        this.state.filterAllTaskTypes.forEach(el => el.checked = true);
        this.state.filterAllResponsible.forEach(el => el.checked = true);
        this.state.filterAllForestNames.forEach(el => el.checked = true);

        // Update content
        this.setState({
            updateContent: true,
            filterTaskStatusActive: false,
            filterDeadlineExceededActive: false,
            filterTaskCategoriesActive: false,
            filterTasksActive: false,
            filterTaskTypesActive: false,
            filterResponsibleActive: false,
            filterForestNamesActive: false,
        })
        // Send event to analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Reset filters`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleResetList = () => {
        this.resetFilters();
        this.setState({ searchText: '' });
        this.props.onSetShowFilterInMap(false);
        this.props.onSetInsertNotesState(true);
    }

    handleSearchTextChange = event => {
        this.setState({ searchText: event.target.value, updateContent: true })
    };


    handleFilterClick = event => {
        // Send event to analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Filter box opened`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ anchorEb: event.currentTarget });
    };

    handleFilterClose = () => {
        // Send event to analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Filter box closed`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
        this.setState({ anchorEb: null });
    };

    handleRowClick = (event, data) => {
        event.preventDefault();
        const newAnchor = {
            xPos: event.clientX - 2,
            yPos: event.clientY - 4,
        }
        this.setState({ anchorNa: newAnchor, naviData: data });
    };

    handleRowClickAnchorButtonClose = () => {
        const newAnchor = {
            xPos: null,
            yPos: null
        }
        this.setState({ anchorNa: newAnchor, naviData: null });
    };

    handleShowFilteredInMapClick = () => {
        if (this.props.filterShowFilterInMap) {
            this.props.onSetShowFilterInMap(false);
            this.props.onSetInsertNotesState(true);
        } else {
            this.props.onSetShowFilterInMap(true);
            this.handleClose();
            this.props.onSetInsertNotesState(true);
        }
        // Send event to analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Show filter in map: ${!this.props.filterShowFilterInMap}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleClose = () => {
        this.props.onSetKanbanBoardActive(false);
    }

    renderToolbar(header) {
        const { classes } = this.props;
        const { anchorEb } = this.state;

        const filterActive = this.state.filterTaskStatusActive || this.state.filterDeadlineExceededActive || this.state.filterTasksActive || this.state.filterTaskTypesActive || this.state.filterResponsibleActive || this.state.filterForestNamesActive;

        return (
            <Toolbar
                className={classNames(classes.toolbarRoot)}
            >
                <div className={classes.title}>
                    <Typography variant="h6" id="tableTitle">
                        {header}
                    </Typography>
                    {this.props.config.underManagementText !== '' && <Typography variant="caption"
                        color="textSecondary">
                        {this.props.config.underManagementText}
                    </Typography>}
                </div>

                <div className={classes.spacer} />

                <div className={classes.actions}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>

                        <Tooltip title="Søg i opgaver">
                            <InputBase
                                placeholder="Søg"
                                onChange={this.handleSearchTextChange}
                                value={this.state.searchText}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Tooltip>
                    </div>

                    <Tooltip title="Filtrere opgaver">
                        <IconButton
                            aria-label="Filtrere opgaver"
                            onClick={this.handleFilterClick}
                            color={filterActive ? "primary" : "default"}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={(this.props.filterShowFilterInMap) ? "Fjern filtrering i kortet" : "Se resultat i kort"}>
                        <IconButton
                            aria-label="Se resultat i kort"
                            onClick={this.handleShowFilteredInMapClick}
                            disabled={
                                (
                                    !this.state.filterTaskStatusActive &&
                                    !this.state.filterDeadlineExceededActive &&
                                    !this.state.filterTaskCategoriesActive &&
                                    !this.state.filterTasksActive &&
                                    !this.state.filterTaskTypesActive &&
                                    !this.state.filterResponsibleActive &&
                                    !this.state.filterForestNamesActive &&
                                    !this.props.filterShowFilterInMap)}
                            color={(this.props.filterShowFilterInMap) ? "primary" : "default"}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Tooltip>

                    <div style={{ height: 36, width: 2, margin: "0px 6px", backgroundColor: '#5EBD3E' }} />

                    <Menu
                        id="simple-menu"
                        keepMounted
                        anchorEl={anchorEb}
                        open={Boolean(anchorEb)}
                        onClose={this.handleFilterClose}
                        classes={{
                            paper: classes.menuContainer,
                        }}
                    >
                        <Filter
                            onFilterChangeAll={this.onFilterChangeAll}
                            onFilterChange={this.onChangeFilterHandler}

                            taskStatus={this.props.config.filter.taskStatus}
                            allTaskStatus={this.state.filterTaskStatus}
                            allTaskStatusChecked={!this.state.filterTaskStatusActive}

                            taskCategories={this.props.config.filter.taskCategories}
                            allTaskCategories={this.state.filterAllTaskCategories}
                            allTaskCategoriesChecked={!this.state.filterTaskCategoriesActive}

                            tasksFilter={this.props.config.filter.tasks}
                            allTasks={this.state.filterAllTasks}
                            allTasksChecked={!this.state.filterTasksActive}

                            deadlineFilter={this.props.config.filter.deadline}
                            deadlineExceededChecked={this.state.filterDeadlineExceededActive}

                            taskCategoriesFilter={this.props.config.filter.taskCategories}
                            allTaskTypes={this.state.filterAllTaskTypes}
                            allTaskTypesChecked={!this.state.filterTaskTypesActive}

                            responsibleFilter={this.props.config.filter.responsible}
                            allResponsible={this.state.filterAllResponsible}
                            allResponsibleChecked={!this.state.filterResponsibleActive}

                            forestNamesFilter={this.props.config.filter.forestNames}
                            allForestNames={this.state.filterAllForestNames}
                            allForestNamesChecked={!this.state.filterForestNamesActive}

                            resetFilters={this.resetFilters}
                        />
                    </Menu>

                    <Menu
                        id="navi-menu"
                        keepMounted
                        open={this.state.anchorNa.yPos !== null}
                        onClose={this.handleRowClickAnchorButtonClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            this.state.anchorNa.xPos !== null && this.state.anchorNa.yPos !== null
                                ? { top: this.state.anchorNa.yPos, left: this.state.anchorNa.xPos }
                                : undefined
                        }
                        classes={{
                            paper: classes.naviContainer,
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button
                                color="primary"
                                onClick={this.handleNavigateToMapClick}
                            >
                                Naviger til kort
                                <IconChevronRight />
                            </Button>
                        </div>
                    </Menu>

                    <Tooltip title="Genload opgaver">
                        <IconButton aria-label="Reset list" onClick={this.handleResetList}>
                            <ReplayIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Luk opgavetavlen">
                        <IconButton aria-label="Close kanbanBoard" onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </Toolbar>
        )
    }

    // onDragStart = result => {
    // }

    // onDragUpdate = update => {
    // }

    navigateToTaskOnClickHandler = (taskData) => {
        this.props.navigateToTaskOnClickHandler(taskData);
        this.handleClose();
        // Send event to analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Navigate to task`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    deleteTaskOnClickHandler = (taskData) => {
        // Delete task on cloud
        this.props.onDeleteNote(taskData, 'forest', 'kanban')
        // Update tasks in state
        // let updatedTasks = {...this.state.tasks};
        // delete updatedTasks[taskData.id];
        // this.setState({tasks: updatedTasks})
        // Send event to analytics
        ReactGA.event({
            category: 'Kanban Board',
            action: `Delete task`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    onDragEnd = result => {
        const { destination, source, draggableId } = result;
        // Handle drop outside columns
        if (!destination) {
            return;
        }
        // Handle drop inside same column and index
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        // Moving inside one column
        const start = this.state.columns[source.droppableId];
        const finish = this.state.columns[destination.droppableId];
        if (start === finish) {
            const newTaskIds = [...start.taskIds];
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds,
            };

            const newState = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    [newColumn.id]: newColumn,
                }
            }
            // Set data in state
            this.setState(newState);
            // Set task data on cloud and in state
            let newTasks = { ...this.state.tasks };
            newTaskIds.forEach((id, index) => {
                newTasks[id].priority = index;
                this.props.onSaveNote(newTasks[id], newTasks[id].forestId, 'forest', 'kanban');
            })
            this.setState({ tasks: newTasks })
            return;
        }
        // Moving from one column to another
        const startTaskIds = [...start.taskIds];
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const finishTaskIds = [...finish.taskIds];
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds
        };

        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };
        // Set data in state
        this.setState(newState);

        // Save result on cloud and in state
        let newTasks = { ...this.state.tasks };
        // Update indexes/priorities of drag start column tasks
        startTaskIds.forEach((id, index) => {
            newTasks[id].priority = index;
            this.props.onSaveNote(newTasks[id], newTasks[id].forestId, 'forest', 'kanban');
        })
        // Update indexes/priorities of drop column 
        finishTaskIds.forEach((id, index) => {
            newTasks[id].priority = index;
            if (id === draggableId) {
                newTasks[id].todoStatus = this.state.columns[destination.droppableId].title;
            }
            this.props.onSaveNote(newTasks[id], newTasks[id].forestId, 'forest', 'kanban');
        })
        // Set new state
        this.setState({ tasks: newTasks, updateContent: this.state.filterTaskStatusActive});
    }

    renderKanbanBoard = () => {
        const { classes } = this.props;
        return (
            <DragDropContext
                onDragStart={this.onDragStart}
                onDragUpdate={this.onDragUpdate}
                onDragEnd={this.onDragEnd}
            >
                <div className={classes.kanbanContainer}>
                    {this.state.columnOrder.map(columnId => {
                        const column = this.state.columns[columnId];
                        const tasks = column.taskIds.map(taskId => {
                            return this.state.tasks[taskId]
                        });
                        return <Column
                            key={column.id}
                            column={column}
                            tasks={tasks}
                            dragDisabled={this.props.config.underManagementText !== ''}
                            navigateToTaskOnClickHandler={this.navigateToTaskOnClickHandler}
                            deleteTaskOnClickHandler={this.deleteTaskOnClickHandler}
                        />;
                    })}
                </div>
            </DragDropContext>
        )
    }

    render() {
        const { classes } = this.props;
        let header = "Opgavetavle for alle skove";
        if (this.props.selectedForest) {
            header = "Opgavetavle for " + this.props.customerList[this.props.selectedForest].forestName + ", " + this.props.customerList[this.props.selectedForest].name;
        }
        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {this.renderToolbar(header)}

                    <div
                        className={classes.boardWrapper}
                        style={{ height: this.state.height - 150 }}
                    >
                        {this.renderKanbanBoard()}
                    </div>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        config: state.appConfig.taskPlanningKanban,
        notes: state.notes.Notes,
        insertNotes: state.notes.insertNotes,
        currentAuthUser: state.user.currentAuthUser,
        selectedForest: state.customer.currentlySelectedForestId,
        customerList: state.customer.customerList,
        org: state.org.org,
        orgUsers: state.org.orgUsers,
        orgTasks: state.org.orgTasks,
        orgTaskTypes: state.org.orgTaskTypes,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        filterState: state.activity.filterState,
        filterShowFilterInMap: state.activity.showFilterInMap,
        filterObjectIds: state.activity.filterObjectIds,
        defaultTasks: state.activity.defaultTasks,
        defaultTasksTypes: state.activity.defaultTasksTypes,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetKanbanBoardActive: (s) => dispatch(actions.setKanbanBoardActive(s)),
        onSetFilterState: (s) => dispatch(actions.setActivityFilterState(s)),
        onSetFilterObjectIds: (s) => dispatch(actions.setActivityFilterObjectIds(s)),
        onSetShowFilterInMap: (s) => dispatch(actions.setActivityShowFilterInMap(s)),
        onSaveNote: (noteData, forestId, layerType, parentModule) => dispatch(actions.saveNote(noteData, forestId, layerType, parentModule)),
        onDeleteNote: (noteData, layerType, parentModule) => dispatch(actions.deleteNote(noteData, layerType, parentModule)),
        onSetInsertNotesState: (s) => dispatch(actions.setInsertNotesState(s)),
        onRedrawCells: () => dispatch(actions.redrawCells()),
    };
}

KanbanBoard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(KanbanBoard)));
