// https://stackoverflow.com/questions/50927165/react-nextjs-integration-of-weatherwidget-io

import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import WeatherApp from './weatherApp';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

// Address search
import { OpenStreetMapProvider } from 'leaflet-geosearch';

// Components
import CircularSpinner from '../../CircularSpinner/CircularSpinner';

// For address search
const provider = new OpenStreetMapProvider();

// Cookies
const cookies = new Cookies();


const styles = () => ({
});

class NewWeatherWidget extends Component {
    constructor(props) {
        super(props);
        const weatherWidgetState = cookies.get('weatherWidgetState');
        this.state = {
            open: weatherWidgetState === 'close' ? false : true,
            city: null,
            lat: null,
            lon: null,
            weatherLocationSet: false,
            tempSelectedForest: '',
        };
    }
    componentDidMount() {
        this.setWidgetData();
    }

    componentDidUpdate() {
        this.setWidgetData();
    }

    setWidgetData = () => {
        if (this.props.customerList && this.props.forestBbox && this.props.selectedForest && this.state.tempSelectedForest !== this.props.selectedForest) {
            // Get forest center location
            let city = 'Danmark';
            let lat = '55.709291';
            let lon = '9.548238';
            // Set label to forest name
            if (this.props.customerList[this.props.selectedForest] && this.props.customerList[this.props.selectedForest].forestName && this.props.customerList[this.props.selectedForest].forestName !== '') {
                city = this.props.customerList[this.props.selectedForest].forestName;
            }
            let bbox = [];
            if (this.props.forestBbox[this.props.selectedForest]) {
                bbox = this.props.forestBbox[this.props.selectedForest].bbox
            }
            if (city !== 'Danmark' && bbox && bbox.length === 4) {
                lat = (bbox[1] + bbox[3])/2;
                lon = (bbox[0] + bbox[2])/2;
            }
            // Set state
            this.setState({ 
                city: city,
                lat: lat,
                lon: lon,
                tempSelectedForest: this.props.selectedForest
             });
        } else if (!this.props.selectedForest && this.state.tempSelectedForest !== this.props.selectedForest) {
            if (this.props.org && this.props.org.city !== '') {
                this.searchAddress(this.props.org.city, 'denmark');
            } else if (this.props.currentAuthUser && this.props.currentAuthUser.city !== '') {
                // Find lat/lng
                this.searchAddress(this.props.currentAuthUser.city, 'denmark');
            } else {
                this.setState({ city: 'Danmark', lat: '55.709291', lon: '9.548238' })
            }
            this.setState({ tempSelectedForest: this.props.selectedForest });
        }
    }

    searchAddress = (city, country) => {
        try {
            provider.search({ query: city + ', ' + country }).then(response => {
                if (response.length > 0) {
                    this.setState({ city: city, lat: response[0].y.toString(), lon: response[0].x.toString() })
                }
            }).catch( err => {throw err});
        } catch (error) {
            this.setState({ city: 'Danmark', lat: '55.709291', lon: '9.548238' })
            console.error("Search for weather address error: ", error)
        }
    }

    renderwidget() {
        if (this.state.city && this.state.lat && this.state.lon) {
            return (
                <div style={{ position: 'relative', zIndex: 2, top: -125, marginBottom: -125, minHeight: 125 }}>
                    <WeatherApp
                        locationLabel={this.state.city}
                        lat={this.state.lat}
                        lon={this.state.lon}
                    />
                </div>
            )
        }
    }

    render() {
        return (
            <Card>
                <div>
                    <div style={{ width: '100%', height: 125 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 16 }}>
                            <Typography variant="h5">
                                Vejret
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularSpinner />
                        </div>
                    </div>

                    {this.renderwidget()}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        org: state.org.org,
        customerList: state.customer.customerList,
        selectedForest: state.customer.currentlySelectedForestId,
        forestBbox: state.maps.forestBbox,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
}

NewWeatherWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewWeatherWidget)));