
const categories = require('./catagories.json');

export const getCategoryFromCodeName = (codeName) => {
    const cat = Object.entries(categories).filter(cat => cat[1].name === codeName);
    return cat[0][0]; 
}

export const getCategorySubCategories = (category) => {
    return categories[category].subCategories;
}

// export const getCategoryCodeName = (category) => {

// }