import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    mainButton: {
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: '#5abf34',
        borderColor: '#5abf34',
        color: 'white',
        '&:hover': {
            backgroundColor: '#51ab2e',
            borderColor: '#51ab2e',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#51ab2e',
            borderColor: '#51ab2e',
        },
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: '#C5C5C5',
        },
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
    },
}));

const ButtonWithSpinner = (props) => {
    const classes = useStyles();

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
                onClick={props.onClick}
                variant={props.variant}
                color={props.color}
                className={classes.mainButton}
                fullWidth={props.fullWidth}
                disabled={props.disabled}
            >
                {props.icon}

                {props.text}
            </Button>

            {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
}
export default ButtonWithSpinner;