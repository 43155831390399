import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import classNames from 'classnames';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import NaturePeopleIcon from '@material-ui/icons/NaturePeopleRounded';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

// Icons
import CloseIcon from '@material-ui/icons/CloseRounded';

// Other
import * as actions from '../../../../store/actions/index';
import HeaderMenu from '../UIElements/HeaderMenu';
import InfoMenu from '../UIElements/InfoMenu';
import SpaceMenu from '../UIElements/SpaceMenu';

const styles = theme => ({
    fabBottom: {
        position: 'absolute',
        zIndex: 2,
        right: 772 - 410 + 5 + 10, //687 621
        bottom: 10,
        backgroundColor: 'white'
    },
    fabMenuBottom: {
        position: 'absolute',
        zIndex: 101,
        width: 210,
        right: 772 - 410 + 5 + 10,
        bottom: 78,
        backgroundColor: theme.palette.background.paper,
    },
    fabMenuMobile: {
        position: 'absolute',
        zIndex: 1,
        width: 210,
        right: 68,
        bottom: 68,
        backgroundColor: theme.palette.background.paper,
    },
    fabSelectedBottom: {
        position: 'absolute',
        zIndex: 1,
        height: '56px',
        borderRadius: '28px',
        right: 772 - 410 + 5 + 10,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center'
    },
    fabSelectedBottomMobile: {
        position: 'absolute',
        zIndex: 1,
        height: '48px',
        maxWidth: 300,
        borderRadius: '24px',
        right: 10,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center'
    },
    selectedBoxContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingRight: '64px',
    },
    moveIn: {
        transform: 'translate3d(-410px, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    moveOut: {
        transform: 'translate3d(0, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    checklist: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
    },
});

class MapBiotopePlansMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            checked: [],
            allChecked: true,
            zIdx: 99,
            tempSelectedPlan: null,
        };
    }

    componentDidMount() {
        // Sort customer list
        if (Object.keys(this.props.biotopePlansUser).length > 0) {
            const sortedList = Object.values(this.props.biotopePlansUser).sort((a, b) => a.name.localeCompare(b.name, 'da-DK')).map(el => { return el.id })
            this.populateCheckedObject(sortedList);
        }
    }

    componentDidUpdate() {
        // Populate checked array
        if (Object.keys(this.props.biotopePlansUser).length > 0) {
            if (this.state.checked.length !== Object.values(this.props.biotopePlansUser).length) {
                if (this.state.checked.length > Object.values(this.props.biotopePlansUser).length) {
                    const checkedIds = this.state.checked.map(el => {
                        return el.id;
                    })
                    const difference = checkedIds.filter(x => !this.props.biotopePlansUser[x]);
                    const deletedEl = this.state.checked.filter(el => {
                        return el.id === difference[0];
                    });
                    if (deletedEl[0].state) {
                        this.setState({ allChecked: true })
                    }
                }
                // Sort list
                const sortedList = Object.values(this.props.biotopePlansUser).sort((a, b) => a.name.localeCompare(b.name, 'da-DK')).map(el => { return el.id });
                this.populateCheckedObject(sortedList);
            }
        } else {
            if (this.state.checked.length > 0)
            this.setState({checked: []})
        }


        // Check if forest is deselected
        if (this.state.tempSelectedPlan !== this.props.selectedBiotopPlan) {
            if (this.props.selectedBiotopPlan === null) {
                if (!this.state.allChecked) {
                    const updatedChecked = this.state.checked.map(el => {
                        el.state = false;
                        return el;
                    })
                    this.setState({ checked: updatedChecked, allChecked: true })
                }
            } else {
                // Handle biotope plan marker click 
                this.setState({ allChecked: false });

                const updatedChecked = this.state.checked.map(el => {
                    if (el.id === this.props.selectedBiotopPlan) {
                        el.state = true;
                    } else {
                        el.state = false;
                    }
                    return el;
                })

                this.setState({ checked: updatedChecked })

                // Send event to Analytics
                ReactGA.event({
                    category: 'Biotope plan map layer',
                    action: `Biotope plan marker clicked`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });

            }
            // Set selected forest in component state
            this.setState({ tempSelectedPlan: this.props.selectedBiotopPlan })
            // Reset inventorylist filters
            this.props.onSetFilterState(null);
            this.props.onSetShowFilterInMap(false);

        }

        // Onboarding initiated handler
        // if (this.props.onboardingActive && this.props.onboardingState === 'welcome') {
        //     this.onExit();
        //     if (this.state.open) {
        //         this.setState({ open: false });
        //     }
        // }

        // Check if menu should be brought to front
        if (this.props.menuBringToFrontId === 'custSelect') {
            if (this.state.zIdx !== 101) {
                this.setState({ zIdx: 101 });
            }
        } else {
            if (this.state.zIdx !== 99) {
                this.setState({ zIdx: 99 });
            }
        }
    }

    populateCheckedObject = (data) => {
        const checkedObj = data.map(el => {
            const existingElements = this.state.checked.filter(exEl => {

                return exEl.id === el;
            })
            if (existingElements.length > 0) {
                return existingElements[0];
            } else {
                return (
                    { id: el, state: false }
                );
            }
        });
        this.setState({ checked: checkedObj })
    }

    handleClick() {
        this.setState({ open: (this.state.open ? false : true) })
        this.props.onSetMapMenuBringToFrontId('custSelect');

        // Send event to Analytics
        ReactGA.event({
            category: 'Biotope plans map layer',
            action: `Biotope plans layer open: ${!this.state.open}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    cardClickHandler() {
        this.props.onSetMapMenuBringToFrontId('custSelect');
    }


    handleChange = name => event => {
        if (name === "all") {
            if (!this.state.allChecked) {
                const updatedChecked = this.state.checked.map(el => {
                    el.state = false;
                    return el;
                })
                this.setState({ checked: updatedChecked })
                this.props.onSetCurrentlySelectedBiotopePlanId(null);
                this.setState({ allChecked: true })
            }
        } else {
            const updatedChecked = this.state.checked.map(el => {
                if (el.id === name) {
                    if (el.state === true) {
                        el.state = false;
                        this.props.onSetCurrentlySelectedBiotopePlanId(null);
                        this.setState({ allChecked: true })
                    } else {
                        el.state = true;
                        this.props.onSetCurrentlySelectedBiotopePlanId(el.id);
                        this.setState({ allChecked: false })
                    }
                } else {
                    el.state = false;
                }
                return el;
            })
            this.setState({ checked: updatedChecked })

            // Onboarding action if active 
            // if (this.props.onboardingActive && this.props.onboardingState === 'customerSelectIntro') {
            //     this.props.onSetOnboardingState('drawingIntro');
            //     if (!this.props.mobile) {
            //         this.handleClick();
            //     }
            // }
        }
    };

    onExit() {
        if (!this.state.allChecked) {
            const updatedChecked = this.state.checked.map(el => {
                el.state = false;
                return el;
            })
            this.setState({ checked: updatedChecked })
            this.props.onSetCurrentlySelectedBiotopePlanId(null);
            this.setState({ allChecked: true, open: false })
        }

        // Send event to Analytics
        ReactGA.event({
            category: 'Biotope plans map layer',
            action: `cancelSelectedBiotopePlan`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    list(disableMenu) {
        const { classes, screenHeight, mobile } = this.props;

        return (
            <List className={classes.checklist}>
                <ListItem
                    role={undefined}
                    dense
                    button
                    disabled={disableMenu}
                    onClick={this.handleChange('all')}
                    style={{ paddingLeft: 0 }}
                >
                    <Checkbox
                        checked={this.state.allChecked}
                        tabIndex={-1}
                        disableRipple
                        disabled={disableMenu}
                        style={{ marginLeft: 0 }}
                        color="primary"
                    />
                    <ListItemText primary={'Alle planer'} />
                </ListItem>

                <Divider />

                <div style={{ overflow: 'auto', maxHeight: screenHeight - (mobile ? 248 : 194) }}>
                    {this.props.currentAuthUser.biotopePlans.length > 0 && this.state.checked.map(el => (
                        <ListItem
                            key={el.id}
                            role={undefined}
                            dense
                            button
                            onClick={this.handleChange(el.id)}
                            style={{ paddingLeft: 0 }}
                        >
                            <Checkbox
                                checked={el.state}
                                tabIndex={-1}
                                disableRipple
                                style={{ marginLeft: 0 }}
                                color="primary"
                            />
                            <ListItemText
                                primary={this.props.biotopePlansUser[el.id] ? (this.props.biotopePlansUser[el.id].locked ? "🔒 " + this.props.biotopePlansUser[el.id].planName : this.props.biotopePlansUser[el.id].planName ) : ""}
                                secondary={this.props.biotopePlansUser[el.id] ? this.props.biotopePlansUser[el.id].name : ""}
                            />
                        </ListItem>
                    ))}
                </div>

                <Divider />

                <SpaceMenu />

                <InfoMenu
                    content='Vælg den plan som du ønsker at arbejde i.'
                />

                <SpaceMenu />
            </List>
        )
    }

    contentMenu(labelHead, label) {
        const { classes, tablet } = this.props;

        return (
            <div
                className={classes.selectedBoxContent}
                style={{
                    paddingLeft: !this.state.allChecked ? '4px' : '18px',
                    maxWidth: tablet ? '182px' : null,
                }}
            >
                <Typography
                    variant="caption"
                    noWrap
                    component={'span'}
                    style={{ color: '#747474' }}
                >
                    {labelHead}
                </Typography>

                <Typography
                    variant="body2"
                    noWrap
                    component={'span'}
                >
                    {label}
                </Typography>
            </div>
        )
    }

    closeButton() {
        return (
            <IconButton
                aria-label="Close"
                size="medium"
                style={{ marginLeft: 6 }}
                onClick={this.onExit.bind(this)}
            >
                <Tooltip title="Luk skov" aria-label="Luk skov">
                    <CloseIcon fontSize="small" />
                </Tooltip>
            </IconButton>
        )
    }

    render() {
        const { classes } = this.props;
        const checkedEl = this.state.checked.filter(el => {
            return el.state === true;
        })

        let label = "Alle";
        let labelHead = "Valgt plan";
        let disableMenu = false;

        if (checkedEl.length > 0 && this.props.currentAuthUser.biotopePlans && this.props.currentAuthUser.biotopePlans.length > 0) {
            if (this.props.biotopePlansUser[checkedEl[0].id]) {
                label = this.props.mobile ? this.props.biotopePlansUser[checkedEl[0].id].planName : this.props.biotopePlansUser[checkedEl[0].id].planName + (this.props.biotopePlansUser[checkedEl[0].id].name !== "" ? ", " : "") + this.props.biotopePlansUser[checkedEl[0].id].name;
            }
        } else if (this.props.currentAuthUser.biotopePlans && this.props.currentAuthUser.biotopePlans.length < 1) {
            label = "";
            labelHead = "Ingen planer";
            disableMenu = true;
        }

        const impZidx = this.props.onboardingActive ? this.props.onboardingDrawToolZidx : this.state.zIdx;

        const fab = classNames(classes.fabBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);
        const fabMenu = classNames(classes.fabMenuBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);
        const fabSelected = classNames(classes.fabSelectedBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);

        return (
            <div>
                {!this.props.mobile && <Tooltip title="Biotopplaner" aria-label="Biotopplaner">
                    <Fab
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick.bind(this)}
                        className={fab}
                        style={{ zIndex: this.props.onboardingForestSelecterZidx + 1, backgroundColor: this.props.onboardingCustomerSelectBtnColor }}
                        disabled={this.props.onboardingDisableCustomerSelect}
                    >
                        {this.state.open ? <CloseIcon style={{ color: '#808080' }} /> : <NaturePeopleIcon style={{ color: '#41842b' }} />}
                    </Fab>
                </Tooltip>}

                {/* NORMAL VERSION */}
                {this.state.open && <Card
                    className={fabMenu}
                    style={{ zIndex: impZidx }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Planer'
                            onPress={this.handleClick.bind(this)}
                        />

                        <SpaceMenu />

                        {this.list(disableMenu)}
                    </CardContent>
                </Card>}

                {!this.props.mobile && <div className={fabSelected} style={{ zIndex: this.props.onboardingForestSelecterZidx }}>
                    {!this.state.allChecked && this.closeButton()}

                    {(checkedEl.length > 0 || this.props.mapCustomerShouldOpen) && this.contentMenu(labelHead, label)}
                </div>}

                {/* MOBILE VERSION */}
                {((this.props.mapCustomerShouldOpen && !this.props.onboardingActive) || (this.props.mapCustomerShouldOpen && this.props.onboardingActive && this.props.onboardingState === 'customerSelectIntro')) && <Card
                    className={classes.fabMenuMobile}
                    style={{ zIndex: 102 }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Planer'
                            onPress={this.props.onPress}
                        />

                        <SpaceMenu />

                        {this.list(disableMenu)}
                    </CardContent>
                </Card>}

                {this.props.mobile && <div className={classes.fabSelectedBottomMobile} style={{ zIndex: this.props.onboardingForestSelecterZidx }}>
                    {!this.state.allChecked && this.closeButton()}

                    {(checkedEl.length > 0 || this.props.mapCustomerShouldOpen) && this.contentMenu(labelHead, label)}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        selectedForest: state.customer.currentlySelectedForestId,
        forestMarkerClickId: state.maps.forestMarkerClickId,
        biotopePlansUser: state.biotopePlans.biotopePlansUser,
        onboardingForestSelecterZidx: state.onboarding.forestSelecterZidx,
        onboardingDisableCustomerSelect: state.onboarding.disableCustomerSelect,
        onboardingCustomerSelectBtnColor: state.onboarding.customerSelectBtnColor,
        menuBringToFrontId: state.maps.mapMenuBringToFrontId,
        sidebar: state.layout.sidebarOpen,
        selectedBiotopPlan: state.biotopePlans.currentlySelectedBiotopePlanId,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetCurrentlySelectedBiotopePlanId: (id) => dispatch(actions.setCurrentlySelectedBiotopePlanId(id)),
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSetForestMarkerClickId: (id) => dispatch(actions.setForestMarkerClickId(id)),
        onSetMapMenuBringToFrontId: (id) => dispatch(actions.setMapMenuBringToFrontId(id)),
        onSetFilterState: (s) => dispatch(actions.setFilterState(s)),
        onSetShowFilterInMap: (s) => dispatch(actions.setShowFilterInMap(s)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapBiotopePlansMenu));