import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    error: null,
    successFeature: false,
};

const newFeatureRequestSuccess = ( state, action ) => {
    return updateObject( state, {
        successFeature: true,
    } );
};

const newFeatureRequestFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
    } );
};

const unmountNewFeatureRequest = ( state, action ) => {
    return updateObject( state, {
        error: null,
        successFeature: false,
    } );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEW_FEATURE_REQUEST_SUCCESS: return newFeatureRequestSuccess(state, action);
        case actionTypes.NEW_FEATURE_REQUEST_FAIL: return newFeatureRequestFail(state, action);
        case actionTypes.UNMOUNT_NEW_FEATURE_REQUEST: return unmountNewFeatureRequest(state, action);
        default: return state;
    }
};

export default reducer;