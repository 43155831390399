import React from 'react';
import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import Point from '../components/Point/NewPoint';

const overviewMapNewPoint = (props) => {
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <Point
                            latlng = {props.location.state.latlng}
                            // markerId = {props.location.state.markerId}
                        />
                    </div>
                } />
            </Aux>
        );

}

export default overviewMapNewPoint;