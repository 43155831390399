import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

// Material UI icons
import NaturePeopleIcon from '@material-ui/icons/NaturePeopleRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';

// Components
import CircularSpinner from '../../CircularSpinner/CircularSpinner';
import * as actions from '../../../../store/actions/index';
import NaturePeopleAvatar from '../../Avatars/NaturePeopleAvatar';
import OutlinedButton from '../../Buttons/OutlinedButton';

const cookies = new Cookies();

const freeMaxNumberOfCustomers = 1;

const styles = theme => ({
    cardContent: {
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
        marginBottom: 0,
        position: 'relative',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '400px',
        overflow: 'auto',
    },
    indicator: {
        backgroundColor: '#41842b'
    },
    button: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});

class BiotopePlansWidget extends Component {
    constructor(props) {
        super(props);
        const BiotopePlanWidgetState = cookies.get('BiotopePlanWidgetState');
        this.state = {
            secondary: true,
            dense: true,
            rerender: false,
            tabValue: 0,
            tempValue: 0,
            numberOfCustomers: 0,
            open: BiotopePlanWidgetState === 'close' ? false : true,
        };
    }

    onButtonPress = () => {
        // Navigate to new customer page
        this.props.history.push({
            pathname: '/BiotopePlans/NewBiotopePlan',
            state: {
                prevPage: "newBiotopPlan",
            }
        });
    };

    handleChangeItem(id) {
        // Check if biotope plan is already part of customers list
        if (Object.keys(this.props.biotopePlansUser).indexOf(id) === -1) {
            // Not part of, so attach
            this.props.onAttachBiotopePlanToUser(id);
        } else {
            // Part of, so remove
            if (id === this.props.setCurrentlySelectedBiotopePlanId) {
                this.props.onSetCurrentlySelectedBiotopePlanId(null);
            }
            this.props.onRemoveBiotopePlanFromUser(id)
        }
    }

    createListContent = (props, divider) => {
        let inList = null;
        let checked = false;
        if (this.props.biotopePlansUser && this.props.biotopePlansUser[props.id] && this.state.tabValue === 1) {
            inList = "Tilføjet: Mine aktive biotopplaner";
            checked = true;
        } else if (this.state.tabValue === 0) {
            checked = true;
        }
        const prim =
            <Typography
                variant="subtitle1"
                noWrap
            >
                {props.locked ? ("🔒 " + props.planName) : props.planName}
            </Typography>

        const sec =
            <Fragment>
                <Typography
                    variant="subtitle1"
                    noWrap
                    style={{ color: '#000000', paddingBottom: - 2 }}
                    component={'span'}
                >
                    {props.name}
                </Typography>
                <Typography
                    variant="caption"
                    noWrap
                    style={{ color: '#747474', display: 'block' }}
                    component={'span'}
                >
                    {inList}
                </Typography>
            </Fragment>

        return (
            <ListItem
                button
                divider={divider}
                component={Link}
                to={{
                    pathname: `/BiotopePlans/${props.id}`,
                    state: {
                        ...props,
                        prevPage: "biotopePlanList",
                    }
                }}
                style={{ paddingLeft: 6 }}
            >
                <ListItemAvatar>
                    <NaturePeopleAvatar />
                </ListItemAvatar>
                <ListItemText
                    primary={prim}
                    secondary={sec}
                />
                {this.props.orgId && <ListItemSecondaryAction>
                    <Checkbox
                        color="primary"
                        style={{ marginLeft: 16 }}
                        checked={checked}
                        onClick={() => this.handleChangeItem(props.id)}
                        disabled={this.props.networkStatus === 'offline'}
                    />
                </ListItemSecondaryAction>}
            </ListItem>
        );
    }

    displayList = (bList) => {
        // Check how many to show
        let N = bList.length;
        // Sort customer list by alphabetic order
        let sortedList = bList.sort((a, b) => a.name.localeCompare(b.name, 'da-DK'))

        sortedList = sortedList.slice(0, N);

        const list = sortedList.map((el, i) => {
            let divider = i < N - 1;
            if (el && el.id) {
                return (
                    <div key={i}>
                        {this.createListContent(el, divider)}
                    </div>
                )
            } else {
                return null;
            }

        });
        return list;
    }

    biotopePlansList(listDisplay) {
        const { classes, biotopePlansUser, biotopePlansOrg } = this.props;
        let arr = [];

        if (this.state.tabValue === 0 && Object.keys(biotopePlansUser).length < 1) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 53, display: 'flex', alignItems: 'center' }}>
                        <DialogContentText
                            style={{ marginTop: 8 }}
                        >
                            Der er ingen biotopplaner tilknyttet din bruger
                        </DialogContentText>
                    </div>
                </ExpansionPanelDetails>
            )
        } else if (this.state.tabValue === 1 && biotopePlansOrg !== null && Object.keys(biotopePlansOrg).length < 1) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 53, display: 'flex', alignItems: 'center' }}>
                        <DialogContentText
                            style={{ marginTop: 8 }}
                        >
                            Der er ingen biotopplaner tilknytte virksomheden
                        </DialogContentText>
                    </div>
                </ExpansionPanelDetails>
            )
        }

        if (this.state.tabValue === 0 && biotopePlansUser === null) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
                        <CircularSpinner />
                    </div>
                </ExpansionPanelDetails>
            );
        } else if (this.state.tabValue === 1 && biotopePlansOrg === null) {
            return (
                <ExpansionPanelDetails className={classes.cardContent}>
                    <div style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
                        <CircularSpinner />
                    </div>
                </ExpansionPanelDetails>
            );
        }

        if (this.state.tabValue === 0 && biotopePlansUser !== null) {
            arr = Object.values(listDisplay).map(el => {
                return el;
            }).flat();
            if (arr.length < 1) {
                return (
                    <ExpansionPanelDetails className={classes.cardContent}>
                        <div style={{ height: 53, display: 'flex', alignItems: 'center' }}>
                            <DialogContentText
                                style={{ marginTop: 8 }}
                            >
                                Der er ingen gemte biotopplaner
                            </DialogContentText>
                        </div>
                    </ExpansionPanelDetails>
                )
            }
        } else if (this.state.tabValue === 1 && biotopePlansOrg !== null) {
            arr = Object.values(listDisplay).map(el => {
                return el;
            }).flat();
        }

        return (
            <div>
                <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />

                <ExpansionPanelDetails className={classes.cardContent}>
                    <List dense={this.state.dense} style={{ padding: 0 }}>
                        {this.displayList(arr)}
                    </List>
                </ExpansionPanelDetails>

                <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 16, marginRight: 16, }} />

                {(arr.length > 5) &&
                    <Typography
                        variant="body2"
                        align="center"
                        style={{ color: '#6A6A6A', marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 0 }}
                    >
                        Listen er scrollbar <InfoIcon style={{ marginBottom: -8, color: '#9A9A9A' }} />
                    </Typography>}

                {(arr.length <= 3) && <div style={{ height: 16 }} />}
            </div>
        )
    }

    cardAction() {
        const { classes } = this.props;

        let disableAddPlan = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skovejer"].includes(tag))) {
            disableAddPlan = true;
        }

        return (
            <CardActions style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <OutlinedButton
                    buttonLabel={'Opret ny biotopplan'}
                    buttonIcon={<NaturePeopleIcon className={classes.extendedIcon} />}
                    iconLeft={true}
                    onPress={this.onButtonPress.bind(this)}
                    loading={this.state.loading}
                    color="primary"
                    disabled={disableAddPlan || this.props.networkStatus === 'offline'}
                />

                {this.props.networkStatus === 'offline' && <Typography variant="caption" color="textSecondary">
                    Ikke muligt. Ingen forbindelse til internet.
                </Typography >}
                {disableAddPlan && <Typography variant="caption" color="textSecondary">
                    Din bruger kan ikke oprette biotopplaner.
                </Typography >}
            </CardActions>
        )
    }

    expandAction() {
        this.setState({ open: this.state.open ? false : true });

        cookies.set('BiotopePlanWidgetState', this.state.open ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'BiotopePlans',
            action: `BiotopePlansWidget: ${this.state.open ? 'close' : 'open'}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleChangeTab = (event, valueInput) => {
        let tempVal = valueInput;
        // Disable in onboarding is active or user functionality is limited
        if (this.props.onboardingActive) {
            this.setState({ tabValue: 0, tempValue: 0 });
            return;
        }
        // Else continue
        if (this.state.tempValue !== tempVal) {
            if (tempVal === 1) {
                // Send event to Analytics
                ReactGA.event({
                    category: 'BiotopePlans',
                    action: `Org biotope plans tap open`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            } else {
                // Send event to Analytics
                ReactGA.event({
                    category: 'BiotopePlans',
                    action: `User biotope plans tap open`,
                    label: `User: ${this.props.currentAuthUser.name}`
                });
            }
        }
        this.setState({ tabValue: tempVal, tempValue: tempVal });
    };

    render() {
        const { classes } = this.props;

        let bList = this.props.biotopePlansUser;
        if (this.state.tabValue === 1) {
            bList = this.props.biotopePlansOrg;
        }

        return (
            <div>
                <ExpansionPanel
                    expanded={this.state.open}
                    onChange={() => this.expandAction()}
                >
                    <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="h5"
                        >
                            Biotopplaner
                        </Typography >
                    </ExpansionPanelSummary>

                    {this.props.orgId && <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChangeTab}
                        variant="fullWidth"
                        classes={{
                            indicator: classes.indicator
                        }}
                        textColor="primary"
                        aria-label="tabs biotopplaner"
                        style={{ marginLeft: 16, marginRight: 16 }}
                    >
                        <Tab
                            label="Mine aktive biotopplaner"
                        />
                        <Tab
                            label="Virksomhedens biotopplaner"
                        />
                    </Tabs>}

                    {this.biotopePlansList(bList)}

                    {this.cardAction()}
                </ExpansionPanel>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        networkStatus: state.layout.networkStatus,
        org: state.org.org,
        orgId: state.org.orgId,
        selectedForest: state.customer.currentlySelectedForestId,
        currentAuthUser: state.user.currentAuthUser,
        biotopePlansUser: state.biotopePlans.biotopePlansUser,
        biotopePlansOrg: state.biotopePlans.biotopePlansOrg,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetCurrentlySelectedBiotopePlanId: (id) => dispatch(actions.setCurrentlySelectedBiotopePlanId(id)),
        onAttachBiotopePlanToUser: (planId) => dispatch(actions.attachBiotopePlanToUser(planId)),
        onRemoveBiotopePlanFromUser: (planId) => dispatch(actions.removeBiotopePlanFromUser(planId)),
    };
}

BiotopePlansWidget.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BiotopePlansWidget)));


