import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

// Styles
const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonProgress: {
    color: '#5EBD3E',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

// Component
class OutlinedButton extends React.Component {
  render() {
    const { classes, loading, buttonLabel, buttonIcon, iconLeft, onPress, color, variant, discrete, deleteBtn, disabled } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Button
            variant={variant === 'text' ? null : "outlined"}
            color={color}
            style={{ color: discrete ? '#AAAAAA' : (deleteBtn ? '#e75f5f' : null), borderColor: discrete ? '#AAAAAA' : (deleteBtn ? '#e75f5f' : null) }}
            disabled={loading || disabled}
            onClick={onPress}
          >
            {iconLeft && buttonIcon}
            {buttonLabel}
            {!iconLeft && buttonIcon}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
    );
  }
}

OutlinedButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedButton);