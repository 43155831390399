import * as actionTypes from './actionTypes';
import firebase from 'firebase';

export const fetchOrgStart = (orgId) => {
    return {
        type: actionTypes.FETCH_ORG_START,
    };
};

export const fetchOrgSuccess = (org, orgId) => {
    return {
        type: actionTypes.FETCH_ORG_SUCCESS,
        Org: org,
        orgId: orgId,
    };
};

export const fetchOrg = (orgId) => async (dispatch) => {
    var db = firebase.firestore();
    dispatch(fetchOrgStart(orgId));

    db.collection("org").doc(orgId)
        .onSnapshot(function (doc) {
            let org = doc.data();

            // Dispatch org
            dispatch(fetchOrgSuccess(org, orgId));
        });
};

export const fetchOrgListStart = () => {
    return {
        type: actionTypes.FETCH_ORG_LIST_START,
    };
};
export const fetchOrgListFail = (error) => {
    return {
        type: actionTypes.FETCH_ORG_LIST_FAIL,
        error: error,
    };
};

export const fetchOrgListSuccess = (orgList) => {
    return {
        type: actionTypes.FETCH_ORG_LIST_SUCCESS,
        orgList: orgList
    };
};

export const fetchOrgList = () => async (dispatch) => {
    var db = firebase.firestore();
    dispatch(fetchOrgListStart());
    try {
        db.collection("org").onSnapshot(function (querySnapshot) {
            let fetchedOrgs = [];

            querySnapshot.forEach(function (doc) {
                fetchedOrgs.push(doc.data());
            })
            // Dispatch list
            dispatch(fetchOrgListSuccess(fetchedOrgs));
        });
    } catch (err) {
        dispatch(fetchOrgListFail(err));
    }
};

export const fetchOrgUsersStart = () => {
    return {
        type: actionTypes.FETCH_ORG_USERS_START,
    };
};
export const fetchOrgUsersFail = (error) => {
    return {
        type: actionTypes.FETCH_ORG_USERS_FAIL,
        error: error,
    };
};

export const fetchOrgUsersSuccess = (orgUser) => {
    return {
        type: actionTypes.FETCH_ORG_USERS_SUCCESS,
        orgUser: orgUser,
    };
};

export const fetchOrgUsers = (userIdArray) => async (dispatch) => {
    var db = firebase.firestore();
    dispatch(fetchOrgUsersStart());
    try {
        userIdArray.forEach(uid => {
            db.collection('users').doc(uid).onSnapshot(function (userData) {
                if (userData.exists) {
                    dispatch(fetchOrgUsersSuccess(userData.data()));
                }
            })
        })
    } catch (error) {
        dispatch(fetchOrgUsersFail(error));
    }
};

export const fetchOrgTeamsStart = () => {
    return {
        type: actionTypes.FETCH_ORG_TEAMS_START,
    };
};
export const fetchOrgTeamsFail = (error) => {
    return {
        type: actionTypes.FETCH_ORG_TEAMS_FAIL,
        error: error,
    };
};

export const fetchOrgTeamsSuccess = (orgTeams) => {
    return {
        type: actionTypes.FETCH_ORG_TEAMS_SUCCESS,
        orgTeams: orgTeams
    };
};

export const fetchOrgTeams = (orgId) => async (dispatch) => {
    var db = firebase.firestore();
    dispatch(fetchOrgTeamsStart());
    try {
        db.collection("org").doc(orgId).collection('teams').onSnapshot(function (querySnapshot) {
            let fetchedTeams = [];

            querySnapshot.forEach(function (doc) {
                fetchedTeams.push(doc.data());
            });
            // Dispatch list
            dispatch(fetchOrgTeamsSuccess(fetchedTeams));
        });
    } catch (err) {
        dispatch(fetchOrgTeamsFail(err));
    }

};