import React from "react";
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import Cookies from 'universal-cookie';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MapIcon from '@material-ui/icons/MapRounded';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

// Icons
import CloseIcon from '@material-ui/icons/CloseRounded';

// Other
import * as actions from '../../../../store/actions/index';
import HeaderMenu from '../UIElements/HeaderMenu';
import InfoMenu from '../UIElements/InfoMenu';
import SpaceMenu from '../UIElements/SpaceMenu';

const cookies = new Cookies();

const styles = theme => ({
    fabBottom: {
        position: 'absolute',
        zIndex: 2,
        right: 621 - 410,
        bottom: 10,
        backgroundColor: 'white'
    },
    fabMenuBottom: {
        position: 'absolute',
        zIndex: 101,
        width: '184px',
        right: 621 - 410,
        bottom: 78,
        backgroundColor: theme.palette.background.paper,
    },
    fabMenuMobile: {
        position: 'absolute',
        zIndex: 1,
        width: '184px',
        right: 68,
        bottom: 68,
        backgroundColor: theme.palette.background.paper,
    },
    fabSelectedBottom: {
        position: 'absolute',
        zIndex: 1,
        height: '56px',
        width: 156, //146
        borderRadius: '28px',
        right: 621 - 410,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center'
    },
    fabSelectedBottomMobile: {
        position: 'absolute',
        zIndex: 1,
        height: '48px',
        maxWidth: 300,
        borderRadius: '24px',
        right: 10,
        bottom: 10,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center'
    },
    selectedBoxContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingRight: '64px',
    },
    moveIn: {
        transform: 'translate3d(-410px, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    moveOut: {
        transform: 'translate3d(0, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    checklist: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        padding: 0
    },
    secondary: {
        fontSize: 12
    }
});

let mapLayers = ['Skovkort', 'Jagtkort', 'Biotopplaner'];
const tooltipDescription = ['Kort specifikt til skovbrug', 'Kort specifikt til jagt', 'Kort specifikt til biotopplaner'];

class MapSelectorMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            checked: 0,
            allChecked: true,
            zIdx: 99,
            tempEnvironment: 'forest',
        };
    }

    componentDidMount() {
        let environment = cookies.get('mapSelector');
        let value = 0;

        // Set environment
        switch (environment) {
            case 'forest': this.props.onSetChosenWorkEnvironment('forest');
                value = 0;
                if (this.props.selectedBiotopPlan) this.props.onSetCurrentlySelectedBiotopePlanId(null);
                break;
            case 'hunting': this.props.onSetChosenWorkEnvironment('hunting');
                value = 1;
                if (this.props.selectedBiotopPlan) this.props.onSetCurrentlySelectedBiotopePlanId(null);
                break;
            case 'biotopePlan': this.props.onSetChosenWorkEnvironment('biotopePlan');
                value = 2;
                if (this.props.selectedForest) this.props.onSetCurrentlySelectedForestId(null);
                break;
            default: this.props.onSetChosenWorkEnvironment('forest'); value = 0; cookies.set('mapSelector', 'forest', { path: '/' }); break;
        }

        this.setState({ checked: value })
    }

    componentDidUpdate() {
        // Onboarding initiated handler
        if (this.props.onboardingActive && this.props.onboardingState === 'welcome') {
            // Close the menu
            if (this.state.open) {
                this.setState({ open: false });
            }
            // Set environment to forest
            if (this.props.chosenWorkEnvironment !== 'forest') {
                this.handleToggle(0)
            }
        }

        // Check if menu should be brought to front
        if (this.props.menuBringToFrontId === 'environmentSelect') {
            if (this.state.zIdx !== 101) {
                this.setState({ zIdx: 101 });
            }
        } else {
            if (this.state.zIdx !== 99) {
                this.setState({ zIdx: 99 });
            }
        }

    }

    handleClick() {
        this.setState({ open: (this.state.open ? false : true) })
        this.props.onSetMapMenuBringToFrontId('environmentSelect');

        // Send event to Analytics
        ReactGA.event({
            category: 'Map layer',
            action: `Map layer open: ${!this.state.open}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    cardClickHandler() {
        this.props.onSetMapMenuBringToFrontId('environmentSelect');
    }

    handleToggle = value => {
        // Set environment
        switch (value) {
            case 0: this.props.onSetChosenWorkEnvironment('forest');
                cookies.set('mapSelector', 'forest', { path: '/' });
                // If forest owner with only one forest and one plan
                if (this.props.currentAuthUser.userRole === 'forestOwner' && this.props.currentAuthUser.forests.length === 1 && this.props.selectedForest === null) {
                    this.props.onSetCurrentlySelectedForestId(this.props.currentAuthUser.forests[0]);
                }
                if (this.props.selectedBiotopPlan) this.props.onSetCurrentlySelectedBiotopePlanId(null);
                this.props.onSetFilterState(null);
                break;
            case 1: this.props.onSetChosenWorkEnvironment('hunting');
                cookies.set('mapSelector', 'hunting', { path: '/' });
                // If forest owner with only one forest and one plan
                if (this.props.currentAuthUser.userRole === 'forestOwner' && this.props.currentAuthUser.forests.length === 1 && this.props.selectedForest === null) {
                    this.props.onSetCurrentlySelectedForestId(this.props.currentAuthUser.forests[0]);
                }
                if (this.props.selectedBiotopPlan) this.props.onSetCurrentlySelectedBiotopePlanId(null);
                this.props.onSetFilterState(null);
                break;
            case 2: this.props.onSetChosenWorkEnvironment('biotopePlan');
                cookies.set('mapSelector', 'biotopePlan', { path: '/' });
                if (this.props.selectedForest) this.props.onSetCurrentlySelectedForestId(null);
                this.props.onSetFilterState(null);
                break;
            default: this.props.onSetChosenWorkEnvironment('forest'); cookies.set('mapSelector', 'forest', { path: '/' }); break;
        }

        this.setState({ checked: value })
        // Send event to Analytics
        ReactGA.event({
            category: 'Map layer',
            action: `Map environment: ${mapLayers[this.state.checked]}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    list() {
        const { classes, screenHeight, mobile } = this.props;
        const { checked } = this.state;

        // Create check for packages
        let arr = [0, 1];
        if (this.props.huntingPlusActive) {
            arr = [0, 1, 2];
        }

        return (
            <List className={classes.checklist}>
                <div style={{ overflow: 'auto', maxHeight: screenHeight - (mobile ? 77 : 143) }}>
                    {mapLayers.map((value, i) => (
                        <Tooltip key={i} title={tooltipDescription[i]} aria-label={tooltipDescription[i]}>
                            <ListItem
                                key={i}
                                dense
                                button
                                disabled={typeof arr[i] === 'undefined' ? true : false}
                                onClick={() => this.handleToggle(i)}
                                style={{ paddingLeft: 0 }}
                            >
                                <Checkbox
                                    checked={checked === i ? true : false}
                                    disableRipple
                                    style={{ marginLeft: 0 }}
                                    color="primary"
                                />

                                <ListItemText
                                    primary={value}
                                    secondary={typeof arr[i] === 'undefined' ? "Opgrader for adgang" : null}
                                    classes={{
                                        secondary: classes.secondary
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </div>

                <Divider />

                <SpaceMenu />

                <InfoMenu
                    content='Vælg det kort som du ønsker at arbejde i.'
                />

                <SpaceMenu />
            </List>
        )
    }

    contentMenu(forestLabelHead, forestLabel) {
        const { classes } = this.props;

        return (
            <div
                className={classes.selectedBoxContent}
                style={{ paddingLeft: !this.state.allChecked ? '4px' : '16px' }}
            >
                <Typography
                    variant="caption"
                    noWrap
                    component={'span'}
                    style={{ color: '#747474' }}
                >
                    {forestLabelHead}
                </Typography>

                <Typography
                    variant="body2"
                    noWrap
                    component={'span'}
                >
                    {forestLabel}
                </Typography>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        const impZidx = this.props.onboardingActive ? this.props.onboardingMapSelecterZidx : this.state.zIdx;

        const fab = classNames(classes.fabBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);
        const fabMenu = classNames(classes.fabMenuBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);
        const fabSelected = classNames(classes.fabSelectedBottom, this.props.sidebar ? classes.moveIn : classes.moveOut);

        let selected = mapLayers[this.state.checked];

        return (
            <div>
                {!this.props.mobile && <Tooltip title="Kortvælger" aria-label="Kortvælger">
                    <Fab
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick.bind(this)}
                        className={fab}
                        style={{ zIndex: this.props.onboardingMapSelecterZidx + 1, backgroundColor: this.props.onboardingMapSelectBtnColor }}
                        disabled={this.props.onboardingDisableMapSelect}
                    >
                        {this.state.open ? <CloseIcon style={{ color: '#808080' }} /> : <MapIcon style={{ color: '#41842b' }} />}
                    </Fab>
                </Tooltip>}

                {/* NORMAL VERSION */}
                {this.state.open && <Card
                    className={fabMenu}
                    style={{ zIndex: impZidx }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Kort'
                            onPress={this.handleClick.bind(this)}
                        />

                        <SpaceMenu />

                        {this.list()}
                    </CardContent>
                </Card>}

                {!this.props.mobile && <div className={fabSelected} style={{ zIndex: this.props.onboardingMapSelecterZidx }}>
                    {this.contentMenu("Aktivt kort", selected)}
                </div>}

                {/* MOBILE VERSION */}
                {this.props.mapSelectorShouldOpen && <Card
                    className={classes.fabMenuMobile}
                    style={{ zIndex: 102 }}
                    onClick={this.cardClickHandler.bind(this)}
                >
                    <CardContent style={{ paddingBottom: 8 }}>
                        <HeaderMenu
                            title='Kort'
                            onPress={this.props.onPress}
                        />

                        <SpaceMenu />

                        {this.list()}
                    </CardContent>
                </Card>}

                {this.props.mobile && <div className={classes.fabSelectedBottomMobile} style={{ zIndex: this.props.onboardingMapSelecterZidx }}>
                    {this.contentMenu("Aktivt kort", selected)}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        onboardingMapSelecterZidx: state.onboarding.mapSelecterZidx,
        onboardingDisableMapSelect: state.onboarding.disableMapSelect,
        onboardingMapSelectBtnColor: state.onboarding.mapSelectBtnColor,
        menuBringToFrontId: state.maps.mapMenuBringToFrontId,
        sidebar: state.layout.sidebarOpen,
        chosenWorkEnvironment: state.layout.chosenWorkEnvironment,
        huntingPlusActive: state.account.huntingPlusActive,
        inTrialHuntingActive: state.account.inTrialHuntingActive,
        inTrial: state.account.inTrial,
        selectedForest: state.customer.currentlySelectedForestId,
        selectedBiotopPlan: state.biotopePlans.currentlySelectedBiotopePlanId,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSetMapMenuBringToFrontId: (id) => dispatch(actions.setMapMenuBringToFrontId(id)),
        onSetChosenWorkEnvironment: (env) => dispatch(actions.setChosenWorkEnvironment(env)),
        onSetCurrentlySelectedForestId: (forestId) => dispatch(actions.setCurrentlySelectedForestId(forestId)),
        onSetCurrentlySelectedBiotopePlanId: (id) => dispatch(actions.setCurrentlySelectedBiotopePlanId(id)),
        onSetFilterState: (s) => dispatch(actions.setFilterState(s)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapSelectorMenu));