import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMapRounded';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

// Material UI Icons
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternateRounded';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryRounded';

// Components
import NewImageCarousel from './NewImageCarousel';

const styles = theme => ({
  gridList: {
    width: '100%',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  iconButton: {
    margin: theme.spacing.unit,
    color: 'white'
  }
});

class ImageGridList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      index: 0,
    };

    this.handleExit = this.handleExit.bind(this);
  }

  handleExit() {
    this.setState({
      open: false,
    });
  }

  // Render header
  renderSubHeader() {
    const { error, disabled } = this.props;

    return (
      <ListSubheader component="div" style={{ paddingLeft: '0px' }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          disabled={this.props.disabled}
          onChange={this.props.whenChange}
        />
        <label htmlFor="raised-button-file">
          <Button
            style={{ color: error ? '#E23838' : disabled ? '#BDBDBD' : '#009CDF' }}
            component="span"
            disabled={this.props.disabled}
          >
            <AddPhotoAlternateIcon style={{ marginRight: 6 }}/>

            Tilføj billeder
          </Button>
        </label>
      </ListSubheader>
    );
  }

  // Render action bar
  renderActionBar(tile, i) {
    const { classes } = this.props;

    // Delete images
    if (this.props.change) {
      return (
        <GridListTileBar
          title={tile.title}
          classes={{
            root: classes.titleBar,
            title: classes.title,
          }}
          actionIcon={
            <IconButton
              className={classes.iconButton}
              aria-label="Delete"
              onClick={() => this.props.action(tile)}
              disabled={this.props.disabledDelete}
            >
              <DeleteIcon />
            </IconButton>
          }
        />
      );
    }

    // See larger images
    return (
      <GridListTileBar
        title={tile.title}
        classes={{
          root: classes.titleBar,
          title: classes.title,
        }}
        actionIcon={
          <IconButton
            className={classes.iconButton}
            aria-label="Zoom-out"
            onClick={() => this.setState({ open: true, index: i })}
          >
            <ZoomOutMapIcon />
          </IconButton>
        }
      />
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.open && <NewImageCarousel
          open={this.state.open}
          images={this.props.images}
          viewable={this.handleExit}
          index={this.state.index}
        />}

        {this.props.noPreview && <Tooltip title="Se billede(r)">
          <IconButton
            aria-label="Se billede(r)"
            onClick={() => this.setState({ open: true, index: 0 })}
            color="primary"
          >
            <PhotoLibraryIcon />
          </IconButton>
        </Tooltip>}

        {!this.props.noPreview && <GridList className={classes.gridList}>
          {(this.props.change) && <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
            {this.renderSubHeader()}
          </GridListTile>}

          {("images" in this.props) && this.props.images.map((tile, i) => (
            <GridListTile key={i}>
              <img
                src={tile instanceof File ? URL.createObjectURL(tile) : tile}
                alt={"billede"}
              />
              {this.renderActionBar(tile, i)}
            </GridListTile>
          ))}
        </GridList>}
      </div>
    );
  }
}

export default withStyles(styles)(ImageGridList);