import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    networkStatus: "online",
    sidebarOpen: false,
    mainMenuOpen: false,
    accountMenuOpen: false,
    chosenWorkEnvironment: 'forest',
    kanbanBoardActive: false,
    displayUpgradeBox: false,
    totalForestArea: '',
    numHuntingPoints: '',
    numPoints: '',
    numNotes: '',
    speciesScheme: 'skovkortet',
};

const setNetworkStatus = ( state, action ) => {
    return updateObject( state, { networkStatus: action.networkStatus } );
};

const sideBarOpen = ( state, action ) => {
    return updateObject( state, { sidebarOpen: action.sidebar } );
};

const mainMenuOpen = ( state, action ) => {
    return updateObject( state, { mainMenuOpen: action.mainMenuOpen } );
};

const accountMenuOpen = ( state, action ) => {
    return updateObject( state, { accountMenuOpen: action.accountMenuOpen } );
};

const setChosenWorkEnvironment = ( state, action ) => {
    return updateObject( state, { chosenWorkEnvironment: action.chosenWorkEnvironment } );
};

const setKanbanBoardActive = ( state, action ) => {
    return updateObject( state, { kanbanBoardActive: action.kanbanBoardActive } );
};

const setDisplayUpgradeBox = ( state, action ) => {
    return updateObject( state, { displayUpgradeBox: action.displayUpgradeBox } );
};

const setTotalForestArea = ( state, action ) => {
    return updateObject( state, { totalForestArea: action.totalForestArea } );
}

const setNumberOfHuntingPoints = ( state, action ) => {
    return updateObject( state, { numHuntingPoints: action.numHuntingPoints } );
}

const setNumberOfPoints = ( state, action ) => {
    return updateObject( state, { numPoints: action.numPoints } );
}

const setNumberOfNotes = ( state, action ) => {
    return updateObject( state, { numNotes: action.numNotes } );
}

const setSpeciesScheme = ( state, action ) => {
    return updateObject( state, { speciesScheme: action.speciesScheme } );
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NETWORK_STATUS: return setNetworkStatus(state, action);
        case actionTypes.SIDEBAR_OPEN: return sideBarOpen(state, action);
        case actionTypes.MAIN_MENU_OPEN: return mainMenuOpen(state, action);
        case actionTypes.ACCOUNT_MENU_OPEN: return accountMenuOpen(state, action);
        case actionTypes.SET_CHOSEN_WORK_ENVIRONMENT: return setChosenWorkEnvironment(state, action);
        case actionTypes.SET_KANBAN_BOARD_ACTIVE: return setKanbanBoardActive(state, action);
        case actionTypes.SET_DISPLAY_UPGRADE_BOX: return setDisplayUpgradeBox(state, action);
        case actionTypes.SET_TOTAL_FOREST_AREA: return setTotalForestArea(state, action);
        case actionTypes.SET_NUMBER_OF_POINTS: return setNumberOfPoints(state, action);
        case actionTypes.SET_NUMBER_OF_HUNTING_POINTS: return setNumberOfHuntingPoints(state, action);
        case actionTypes.SET_NUMBER_OF_NOTES: return setNumberOfNotes(state, action);
        case actionTypes.SET_SPECIES_SCHEME: return setSpeciesScheme(state, action);
        
        default: return state;
    }
};

export default reducer;