import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import Aux from '../hoc/Aux/Aux';

import SideBar from '../components/SideBar/SideBar';
import BiotopePlan from '../components/BiotopePlan/BiotopePlan';

class overviewMapBiotopePlan extends Component {

    render() {
        return (
            <Aux>
                <SideBar cardContent={
                    <div>
                        <BiotopePlan
                        {...this.props.location.state}
                        />
                    </div>
                } />
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(overviewMapBiotopePlan));