import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import NaturePeopleIcon from '@material-ui/icons/NaturePeopleRounded';
import GestureIcon from '@material-ui/icons/GestureRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import TimelineIcon from '@material-ui/icons/TimelineRounded';
import Menu from '@material-ui/core/Menu';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';

// Chart
import { Line, Pie } from 'react-chartjs-2';

// Store
import * as actions from '../../../../store/actions/index';

// Components
import { getSpecieCO2Equiv, getAreaCO2Equiv, simulate } from '../../../Inventory/Utilities/VolumeFunctionsDK';
import { formatEstInput } from '../../../Inventory/Utilities/utils';
import StatLine from '../../Display/StatLine';
import SpaceMenu from '../../Menus/UIElements/SpaceMenu';
const species = require('../../../Inventory/Utilities/updatedSpeciesList.json');

const cookies = new Cookies();

const styles = theme => ({
    cardContent: {
        paddingTop: '4px',
        position: 'relative',
        margin: 'auto',
        width: '100%',
        //overflow: 'auto',
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        color: theme.palette.primary.main,
    },
    menuContainer: {
        padding: '4px 20px',
    },
    // Label
    valueLabel: {
        top: -16,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    statIcon: {
        height: 20,
        width: 20,
        borderRadius: 3
    }
});

class ForestWidget extends React.Component {
    constructor(props) {
        super(props);
        const forestWidgetState = cookies.get('forestWidgetState');
        this.state = {
            tempSelectedForest: null,
            updateData: false,
            numCustomers: 0,
            numForests: 0,
            numForestCells: this.props.customerIdList.length,
            totalArea: 0,
            totalAreaForest: 0,
            totalAreaFoliage: 0,
            totalAreaNeedle: 0,
            numLitra: 0,
            totalVolume: 0,
            totalCO2: 0,
            estimationSteps: [0, 10],
            simData: null,
            anchorEl: null,
            open: forestWidgetState === 'close' ? false : true,
            totalVolumeOpen: false,
            totalCO2Open: false
        };
    }

    componentDidMount() {
        this.updateData()
    }

    componentDidUpdate() {
        // Update data summary if selected forest changes or number of forest changes

        if (this.state.tempSelectedForest !== this.props.selectedForest) {
            this.updateData()
            if (this.props.selectedForest === null) {
                this.setState({ simData: null })
            }
            this.setState({ tempSelectedForest: this.props.selectedForest })
        } else if (this.props.selectedForest !== null) {
            if (this.props.mapDataUpdated) {
                this.updateData()
                this.props.onSetMapDataUpdated(false);
            }
        } else if (this.props.cells && (this.state.numForestCells !== Object.keys(this.props.cells).length)) {
            // TODO : Dosen't work when deleting forest from user.
            this.setState({ updateData: true, numForestCells: Object.keys(this.props.cells).length });
        }

        if (this.state.updateData) {
            this.updateData()
            this.setState({ updateData: false })
        }
    }

    calculateSummaryStats = (list, otherStats) => {
        let areaTotal = 0;
        let areaTotalForest = 0;
        let areaFoliage = 0;
        let areaNeedle = 0;
        let volTotal = 0;
        let CO2Total = 0;
        let simTotalVolArr = [];
        let simTotalCO2Arr = [];
        // Run through list and calculate
        list.forEach(el => {
            // --- Calculate areas --- //
            // Total area
            // Check for area bearing
            let isBearing = true;
            if (Object.values(el.cellData.speciesObjs)[0] && Object.values(el.cellData.speciesObjs)[0].areaBearing !== undefined) {
                isBearing = Object.values(el.cellData.speciesObjs)[0].areaBearing
            }
            areaTotal = areaTotal + ( (el.cellData.area !== '' && isBearing) ? el.cellData.area : 0);
            if (el.categories && el.categories.includes('other') || !isBearing) {
                // The area is not forest
            } else {
                // Find area of polygon
                const fArea = isNaN(parseFloat(el.cellData.area)) ? 0 : parseFloat(el.cellData.area);
                // Add to total forest area
                areaTotalForest = areaTotalForest + fArea;
                // Run through species objects
                Object.values(el.cellData.speciesObjs).forEach(sObj => {
                    // Check for area bearing
                    const areaBearing = sObj.areaBearing !== undefined ? sObj.areaBearing : true;
                    if (areaBearing) {
                        // Check data and add
                        const fMix = isNaN(parseFloat(sObj.mix)) ? 100 : parseFloat(sObj.mix);
                        const fCoverage = isNaN(parseFloat(sObj.coverage)) ? 100 : parseFloat(sObj.coverage);
                        // Find specie and type of area
                        const spcArea = fArea * (fMix / 100) * (fCoverage / 100);
                        if (species[sObj.species].type === 'foliage') {
                            areaFoliage = areaFoliage + spcArea;
                        } else if (species[sObj.species].type === 'needle') {
                            areaNeedle = areaNeedle + spcArea;
                        }
                    }
                    if (otherStats) {
                        // --- Calculate volume and C02 --- //
                        let fVol = isNaN(parseFloat(sObj.mass)) ? 0 : parseFloat(sObj.mass.toString().split(',').join('.'));
                        fVol = fVol === Infinity ? 0 : fVol;
                        const fDiameter = isNaN(parseFloat(sObj.diameter)) ? 0 : parseFloat(sObj.diameter.toString().split(',').join('.'));
                        const fHeight = isNaN(parseFloat(sObj.height)) ? 0 : parseFloat(sObj.height.toString().split(',').join('.'));

                        volTotal = volTotal + fVol;
                        
                        // Calculate total CO2-e
                        if (fVol > 0 && fDiameter > 0 && fHeight > 0 && species[sObj.species].estModel) {
                            // Because we input total mass and not mass/ha we do not need to go from CO2-e/Ha to total CO2-e
                            try {
                                const CO2trees_e = getSpecieCO2Equiv(this.props.speciesScheme, sObj.species, { Dg: fDiameter / 100, Hg: fHeight, V: fVol });
                                CO2Total = CO2Total + CO2trees_e;
                            } catch {
                                console.error("ForestWidget couldn't get specie CO2-e")
                            }
                        }
                        // --- Simulate --- //
                        // Simulate chosen steps into the future
                        const simGArea = formatEstInput(sObj.groundArea);
                        const simTreeCount = formatEstInput(sObj.treeCount);
                        const simDiameter = formatEstInput(sObj.diameter);
                        const simHeight = formatEstInput(sObj.height);
                        // const simArea = formatEstInput(spcArea);
                        if (fArea > 0 && fVol > 0 && fDiameter > 0 && fHeight > 0 && simGArea > 0 && simDiameter > 0 && simHeight > 0 && sObj.year !== '' && species[sObj.species].estModel) {
                            const initCond = {
                                area: fArea,
                                V: fVol / fArea,
                                H: simHeight,
                                D: simDiameter / 100,
                                G: simGArea,
                                N: simTreeCount * 100,
                                age: parseInt(moment().format("YYYY")) - sObj.year
                            }
                            // Simulation
                            try {
                                const simOut = simulate(this.props.speciesScheme, sObj.species, this.state.estimationSteps, initCond, sObj.thinningArr);
                                const volArr = simOut.V.map(el => { return typeof (el * fArea) === 'number' ? el * fArea : 0 });
                                const CO2Arr = simOut.CO2Equiv.map(el => { return typeof (el * fArea) === 'number' ? el * fArea : 0 });
                                if (simTotalVolArr.length === 0) {
                                    simTotalVolArr = volArr;
                                    simTotalCO2Arr = CO2Arr;
                                } else {
                                    simTotalVolArr = simTotalVolArr.map((num, idx) => {
                                        return num + volArr[idx];
                                    })
                                    simTotalCO2Arr = simTotalCO2Arr.map((num, idx) => {
                                        return num + CO2Arr[idx];
                                    })
                                }
                            } catch (err) {
                                // console.error("ForestWidget estimation error: ", { error: err, specieObject: sObj, initCond: initCond, litra: el })
                            }
                        }

                    }
                })
            }

        })

        return { areaTotal, areaTotalForest, areaFoliage, areaNeedle, volTotal, CO2Total, simTotalVolArr, simTotalCO2Arr }
    }

    updateData = () => {
        if (this.props.cells !== null) {
            // let areaTotal = 0;
            // let areaTotalForest = 0;
            // let areaFoliage = 0;
            // let areaNeedle = 0;
            // let volTotal = 0;
            // let CO2Total = 0;
            // let simTotalVolArr = [];
            // let simTotalCO2Arr = [];
            if (this.props.selectedForest === null) {
                // // Calculate total area
                // Object.values(this.props.cells).forEach(forest => {
                //     forest.forEach(el => {
                //         areaTotal = areaTotal + (el.cellData.area === '' ? 0 : el.cellData.area);
                //         if (el.categories && el.categories.includes('other')) {
                //         } else {
                //             // Find area of polygon
                //             const fArea = isNaN(parseFloat(el.cellData.area)) ? 0 : parseFloat(el.cellData.area);
                //             // Set total forest area
                //             areaTotalForest = areaTotalForest + fArea;
                //             Object.values(el.cellData.speciesObjs).forEach(sObj => {
                //                 const areaBearing = sObj.areaBearing !== undefined ? sObj.areaBearing : true;
                //                 if (areaBearing) {
                //                     const fMix = isNaN(parseFloat(sObj.mix)) ? 0 : parseFloat(sObj.mix);
                //                     const fCoverage = isNaN(parseFloat(sObj.coverage)) ? 0 : parseFloat(sObj.coverage);
                //                     // Find specie and type of area
                //                     const spcArea = fArea * (fMix / 100) * (fCoverage / 100);
                //                     if (species[sObj.species].type === 'foliage') {
                //                         areaFoliage = areaFoliage + spcArea;
                //                     } else if (species[sObj.species].type === 'needle') {
                //                         areaNeedle = areaNeedle + spcArea;
                //                     }
                //                 }
                //             });
                //         }

                //     });
                // });
                const list = Object.values(this.props.cells).flat();
                const stats = this.calculateSummaryStats(list, false);

                // Calculate number of cells
                const numCellsForest = Object.values(this.props.cells).map(forest => {
                    return forest.length
                })
                // Update state
                // const tArea = areaTotal.reduce((a, b) => a + b, 0);
                this.setState({
                    numCustomers: this.props.customerIdList.length,
                    numForests: this.props.customerIdList.length,
                    //totalArea: areaTotal.toFixed(1).replace(".", ","),
                    totalArea: stats.areaTotal,
                    //totalAreaForest: areaTotalForest.toFixed(1).replace(".", ","),
                    totalAreaForest: stats.areaTotalForest,
                    //totalAreaFoliage: areaFoliage.toFixed(1).replace(".", ","),
                    totalAreaFoliage: stats.areaFoliage,
                    //totalAreaNeedle: areaNeedle.toFixed(1).replace(".", ","),
                    totalAreaNeedle: stats.areaNeedle,
                    numLitra: numCellsForest.reduce((a, b) => a + b, 0),
                })
                // Set total area in reducer
                this.props.onSetTotalForestArea(stats.areaTotal);
            } else if (this.props.cells[this.props.selectedForest]) {
                // // Caclulate total area, total volume, total CO2-e and setup plots
                // this.props.cells[this.props.selectedForest].forEach(el => {
                //     areaTotal = areaTotal + (el.cellData.area === '' ? 0 : el.cellData.area);
                //     if (el.categories && el.categories.includes('other')) {
                //     } else {
                //         // Find area of polygon
                //         const fArea = isNaN(parseFloat(el.cellData.area)) ? 0 : parseFloat(el.cellData.area);
                //         // Set total forest area
                //         areaTotalForest = areaTotalForest + fArea;
                //         Object.values(el.cellData.speciesObjs).forEach(sObj => {
                //             // Calculate area
                //             const areaBearing = sObj.areaBearing !== undefined ? sObj.areaBearing : true;
                //             if (areaBearing) {
                //                 const fMix = isNaN(parseFloat(sObj.mix)) ? 100 : parseFloat(sObj.mix);
                //                 const fCoverage = isNaN(parseFloat(sObj.coverage)) ? 100 : parseFloat(sObj.coverage);
                //                 // Find specie and type of area
                //                 const spcArea = fArea * (fMix / 100) * (fCoverage / 100);
                //                 if (species[sObj.species].type === 'foliage') {
                //                     areaFoliage = areaFoliage + spcArea;
                //                 } else if (species[sObj.species].type === 'needle') {
                //                     areaNeedle = areaNeedle + spcArea;
                //                 }
                //             }
                //             // Calculate total volume
                //             const fMass = isNaN(parseFloat(sObj.mass)) ? 0 : parseFloat(sObj.mass.toString().split(',').join('.'));
                //             volTotal = volTotal + fMass;

                //             // Calculate total CO2-e
                //             const fDiameter = isNaN(parseFloat(sObj.diameter)) ? 0 : parseFloat(sObj.diameter.toString().split(',').join('.'));
                //             const fHeight = isNaN(parseFloat(sObj.height)) ? 0 : parseFloat(sObj.height.toString().split(',').join('.'));
                //             if (fMass > 0 && fDiameter > 0 && fHeight > 0 && species[sObj.species].estModel) {
                //                 // Because we input total mass and not mass/ha we do not need to go from CO2-e/Ha to total CO2-e
                //                 try {
                //                     const CO2trees_e = getSpecieCO2Equiv(this.props.speciesScheme, sObj.species, { Dg: fDiameter / 100, Hg: fHeight, V: fMass });
                //                     CO2Total = CO2Total + CO2trees_e;
                //                 } catch {
                //                     console.error("ForestWidget couldn't get specie CO2-e")
                //                 }
                //             }
                //             // Check if estimation is active
                //             if (this.props.config.estimationPlot === 'enabled') {
                //                 // Estimate future volume and CO2
                //                 // Simulate chosen steps into the future
                //                 const simGArea = formatEstInput(sObj.groundArea);
                //                 const simTreeCount = formatEstInput(sObj.treeCount);
                //                 const simDiameter = formatEstInput(sObj.diameter);
                //                 const simHeight = formatEstInput(sObj.height);
                //                 // const simArea = formatEstInput(spcArea);
                //                 if (fArea > 0 && fMass > 0 && fDiameter > 0 && fHeight > 0 && simGArea > 0 && simDiameter > 0 && simHeight > 0 && sObj.year !== '' && species[sObj.species].estModel) {
                //                     const initCond = {
                //                         area: fArea,
                //                         V: fMass / fArea,
                //                         H: simHeight,
                //                         D: simDiameter / 100,
                //                         G: simGArea,
                //                         N: simTreeCount * 100,
                //                         age: parseInt(moment().format("YYYY")) - sObj.year
                //                     }
                //                     // Simulation
                //                     try {
                //                         const simOut = simulate(this.props.speciesScheme, sObj.species, this.state.estimationSteps, initCond, sObj.thinningArr);
                //                         const volArr = simOut.V.map(el => { return typeof (el * fArea) === 'number' ? el * fArea : 0 });
                //                         const CO2Arr = simOut.CO2Equiv.map(el => { return typeof (el * fArea) === 'number' ? el * fArea : 0 });
                //                         if (simTotalVolArr.length === 0) {
                //                             simTotalVolArr = volArr;
                //                             simTotalCO2Arr = CO2Arr;
                //                         } else {
                //                             simTotalVolArr = simTotalVolArr.map((num, idx) => {
                //                                 return num + volArr[idx];
                //                             })
                //                             simTotalCO2Arr = simTotalCO2Arr.map((num, idx) => {
                //                                 return num + CO2Arr[idx];
                //                             })
                //                         }
                //                     } catch (err) {
                //                         // console.error("ForestWidget estimation error: ", { error: err, specieObject: sObj, initCond: initCond, litra: el })
                //                     }
                //                 }
                //             }
                //         })

                //     }
                // })
                // Create x-axis labels and set simulation data in state and set groundArea graph
                const list = Object.values(this.props.cells[this.props.selectedForest]);
                const stats = this.calculateSummaryStats(list, true)
                let xlabels = new Array(stats.simTotalVolArr.length).fill(0);
                for (let i = 0; i < xlabels.length; i++) {
                    xlabels[i] = (parseInt(moment().format("YYYY")) - this.state.estimationSteps[0] + i).toString();
                }

                // Update state
                this.setState({
                    simData: { totalVolArr: stats.simTotalVolArr.map(el => el.toFixed(0)), totalCO2Arr: stats.simTotalCO2Arr.map(el => el.toFixed(0)), xlabels: xlabels },
                    numCustomers: 1,
                    numForests: 1,
                    totalArea: stats.areaTotal,
                    totalAreaForest: stats.areaTotalForest,
                    totalAreaFoliage: stats.areaFoliage,
                    totalAreaNeedle: stats.areaNeedle,
                    numLitra: list.length,
                    totalVolume: stats.volTotal,
                    totalCO2: stats.CO2Total
                })
                // Set total area in reducer
                this.props.onSetTotalForestArea(stats.areaTotal);
            }
        }
    }

    commify = (n, decimals) => {
        var parts = n.toFixed(decimals).toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
    }

    renderPiePlot(aLabel, aArea, aColor, bLabel, bArea, bColor) {
        const data = {
            labels: [aLabel, bLabel],
            datasets: [{
                data: [aArea, bArea],
                backgroundColor: [
                    aColor,
                    bColor
                ]
            }]
        };

        const option = {
            tooltips: {
                backgroundColor: 'white',
                titleFontColor: '#1B1A1A',
                borderColor: '#CFCFCF',
                borderWidth: 1,
                bodyFontColor: '#1B1A1A',
            }
        };

        return (
            <Pie data={data} options={option} legend={null} />
        )
    }

    estimationPlot(open, label, xLabels, data, secondaryLabel, secondaryData, hyst) {
        // Setup data sets
        let plotData = {
            labels: xLabels,
            datasets: [
                {
                    label: label,
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#41842b',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 2,
                    pointRadius: 2,
                    pointHitRadius: 0,
                    data: data,
                }
            ]
        };

        if (secondaryData) {
            plotData.datasets.push(
                {
                    label: secondaryLabel ? secondaryLabel : '0',
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#1E1E1E',
                    borderCapStyle: 'butt',
                    borderDash: [5],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 0,
                    pointHoverRadius: 0,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: secondaryData.map(el => el.toFixed(1)),
                    spanGaps: true
                },
                {
                    label: secondaryLabel ? secondaryLabel + '1' : '1',
                    fill: false,
                    lineTension: 0.01,
                    borderColor: '#CECECE',
                    borderCapStyle: 'butt',
                    borderDash: [2.5],
                    borderWidth: 2,
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 0,
                    pointHoverRadius: 0,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: secondaryData.map(el => (el * (1 + hyst)).toFixed(1)),
                    spanGaps: true
                },
                {
                    label: secondaryLabel ? secondaryLabel + '3' : '3',
                    fill: 2,
                    backgroundColor: 'rgba(94, 189, 62, 0.2)',
                    lineTension: 0.01,
                    borderColor: '#CECECE',
                    borderCapStyle: 'butt',
                    borderDash: [2.5],
                    borderWidth: 2,
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#41842b',
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 0,
                    pointHoverRadius: 0,
                    pointHoverBackgroundColor: 'white',
                    pointHoverBorderColor: '#1B1A1A',
                    pointHoverBorderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius: 0,
                    data: secondaryData.map(el => (el * (1 - hyst)).toFixed(1)),
                    spanGaps: true
                },
            )
        }

        const option = {
            tooltips: {
                backgroundColor: 'white',
                titleFontColor: '#1B1A1A',
                borderColor: '#CFCFCF',
                borderWidth: 1,
                bodyFontColor: '#1B1A1A',
            },
            plugins: {
                filler: {
                    propagate: false
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: 1,
                    }
                }]
            }
        };

        return (
            <div onClick={this.handleMenuClick} style={{ margin: open ? '10px -6px 20px 7px' : 0 }}>
                <Line data={plotData} legend={null} options={option} />
            </div>
        )
    }

    menu() {
        const { classes } = this.props;
        // Set year range values
        const minVal = parseInt(moment().format("YYYY"));// - 10;
        const maxVal = parseInt(moment().format("YYYY")) + 40;
        let val = [parseInt(moment().format("YYYY")) - this.state.estimationSteps[0], parseInt(moment().format("YYYY")) + this.state.estimationSteps[1]];
        val = parseInt(moment().format("YYYY")) + this.state.estimationSteps[1];
        return (
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                classes={{
                    paper: classes.menuContainer,
                }}
            >
                <div>
                    <Typography align='left' variant="body1" style={{ margin: '0px 12px 14px 0px' }}>
                        Fremskrivning
                    </Typography>

                    <div style={{ display: 'flex', alignItems: 'center', width: 180 }}>
                        <Slider
                            value={val}
                            onChange={this.onChangeEstimationSteps}
                            valueLabelDisplay="on"
                            //valueLabelFormat={this.valueLabelFormat}
                            aria-labelledby="range-slider"
                            step={1}
                            min={minVal}
                            max={maxVal}
                            classes={{ valueLabel: classes.valueLabel }}
                        />
                    </div>
                </div>
            </Menu>
        );
    }

    onChangeEstimationSteps = (event, newValue) => {
        let newEstSteps = [0, newValue - parseInt(moment().format("YYYY"))]
        this.setState({ estimationSteps: newEstSteps, updateData: true });
    }

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    expandAction() {
        this.setState({ open: this.state.open ? false : true });

        cookies.set('forestWidgetState', this.state.open ? 'close' : 'open', { path: '/' });

        // Send event to Analytics
        ReactGA.event({
            category: 'Forestwidget',
            action: `Forestwidget: ${this.state.open ? 'close' : 'open'}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    expandStat(value) {
        this.setState({ [value]: !this.state[value] })
    }

    render() {
        const { classes } = this.props;
        let forestLabel = 'Antal skove';
        if (this.props.org) {
            forestLabel = 'Antal kunder & skove';
        }
        let forestContent = this.state.numForests;
        if (this.props.selectedForest !== null && this.props.customerList && this.props.customerList[this.props.selectedForest]) {
            if (this.props.org) {
                forestLabel = 'Valgt skov & kunde'
                forestContent = this.props.customerList[this.props.selectedForest].forestName + ', ' + this.props.customerList[this.props.selectedForest].name;
            } else {
                forestLabel = ''
                forestContent = this.props.customerList[this.props.selectedForest].forestName ? this.props.customerList[this.props.selectedForest].forestName : '';
            }
        }
        
        return (
            <div>
                <ExpansionPanel
                    expanded={this.state.open}
                    onChange={() => this.expandAction()}
                >
                    <ExpansionPanelSummary style={{ paddingLeft: 16, paddingRight: 16 }} expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">
                            {this.props.currentAuthUser.userRole === "forestOwner" ? 'Skoven' : 'Overblik'}
                        </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={classes.cardContent}>

                        <StatLine
                            icon={<NaturePeopleIcon className={classes.icon} />}
                            title={this.props.selectedForest ? forestContent : forestLabel}
                            stat={this.props.selectedForest ? null : forestContent}
                        />

                        <SpaceMenu multiplier={2} />

                        <Divider />

                        <SpaceMenu multiplier={2} />

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <div style={{ width: 120, margin: '0px -10px 0px -22px' }}>
                                    {this.renderPiePlot('Øvrige', (this.state.totalArea - this.state.totalAreaForest).toFixed(1), 'lightgrey', 'Skov', this.state.totalAreaForest.toFixed(1), '#41842b')}
                                </div>

                                <div style={{ width: 120, margin: '0px -16px 0px -22px' }}>
                                    {this.renderPiePlot('Løv', this.state.totalAreaFoliage.toFixed(1), '#5EBD3E', 'Nål', this.state.totalAreaNeedle.toFixed(1), '#7eca64')}
                                </div>
                            </div>

                            <div style={{ flexGrow: 1 }}>
                                <StatLine
                                    title="Samlet areal"
                                    bold={true}
                                    stat={this.commify(this.state.totalArea, 1)}
                                    statAdornment="ha"
                                />

                                <SpaceMenu />

                                <StatLine
                                    icon={<div className={classes.statIcon} style={{ backgroundColor: '#41842b' }} />}
                                    title="Skov"
                                    stat={this.commify(this.state.totalAreaForest, 1)}
                                    statAdornment="ha"
                                />

                                <SpaceMenu />

                                <StatLine
                                    icon={<div className={classes.statIcon} style={{ backgroundColor: '#5EBD3E' }} />}
                                    title="Løv"
                                    stat={this.commify(this.state.totalAreaFoliage, 1)}
                                    statAdornment="ha"
                                />

                                <SpaceMenu />

                                <StatLine
                                    icon={<div className={classes.statIcon} style={{ backgroundColor: '#7eca64' }} />}
                                    title="Nål"
                                    stat={this.commify(this.state.totalAreaNeedle, 1)}
                                    statAdornment="ha"
                                />
                            </div>
                        </div>

                        <SpaceMenu multiplier={2} />

                        <Divider />

                        <SpaceMenu multiplier={2} />

                        <StatLine
                            icon={<GestureIcon className={classes.icon} />}
                            title={'Antal litraer'}
                            stat={this.commify(this.state.numLitra, 0)}
                        />

                        {this.props.selectedForest && <div>
                            <SpaceMenu />

                            <StatLine
                                icon={<TimelineIcon className={classes.icon} />}
                                title="Samlet vedmasse"
                                stat={this.commify(this.state.totalVolume, 0)}
                                statAdornment="m3"
                                expansionContent={this.props.config.estimationPlot !== 'hidden' ? this.state.simData ? this.estimationPlot(this.state.totalVolumeOpen, ' Vedmasse [m3]', this.state.simData.xlabels, this.state.simData.totalVolArr) : null : null}
                                onExpandStat={this.props.config.estimationPlot !== 'hidden' ? this.state.simData ? () => this.expandStat('totalVolumeOpen') : null : null}
                                open={this.state.totalVolumeOpen}
                            />
                        </div>}

                        {(this.props.config.CO2Equiv !== 'hidden' && (this.props.selectedForest && this.props.customerList[this.props.selectedForest] && this.props.customerList[this.props.selectedForest].hasPayedSubscription)) && <div>
                            <SpaceMenu />

                            <StatLine
                                icon={<TimelineIcon className={classes.icon} />}
                                title="Samlet CO2-ækvivalenter"
                                stat={this.commify(this.state.totalCO2, 0)}
                                statAdornment="ton"
                                expansionContent={this.props.config.estimationPlot !== 'hidden' ? this.state.simData ? this.estimationPlot(this.state.totalCO2Open, ' CO2-e [ton]', this.state.simData.xlabels, this.state.simData.totalCO2Arr) : null : null}
                                onExpandStat={this.props.config.estimationPlot !== 'hidden' ? this.state.simData ? () => this.expandStat('totalCO2Open') : null : null}
                                open={this.state.totalCO2Open}
                            />
                        </div>}

                        {this.props.config.estimationPlotChangeTimeline !== 'hidden' && this.menu()}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        speciesScheme: state.layout.speciesScheme,
        cells: state.maps.cells,
        mapDataUpdated: state.maps.mapDataUpdated,
        customerIdList: state.customer.customerIdList,
        customerList: state.customer.customerList,
        selectedForest: state.customer.currentlySelectedForestId,
        currentAuthUser: state.user.currentAuthUser,
        org: state.org.org,
        config: state.appConfig.overviewWidget,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetTotalForestArea: (area) => dispatch(actions.setTotalForestArea(area)),
        onSetMapDataUpdated: (state) => dispatch(actions.setMapDataUpdated(state)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForestWidget)));