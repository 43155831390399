import React from 'react';
import PropTypes from 'prop-types';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import daLocale from "date-fns/locale/da";
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';

// Material UI icons
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import DateRangeIcon from '@material-ui/icons/DateRangeRounded';

// Other components
import ImageGridList from '../UI/DisplayImages/ImageGridList';

const _ = require("underscore");

const styles = theme => ({
    textField: {
        width: '100%',
    },
    root: {
        width: '100%',
    },
    expansionPanelDetailsStyle: {
        paddingTop: 0,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 0,
        marginBottom: 0,
        position: 'relative',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '430px',
        overflow: 'auto',
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    buttonStyle: {
        borderColor: "#e75f5f",
        color: '#e75f5f'
    },
    nonEditTextStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

const HistoryList = (props) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const expansionList = (list) => {
        return (
            <div className={props.classes.root}>
                <ExpansionPanel
                    style={{ marginBottom: 8, borderRadius: 0, boxShadow: 'none' }}
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                >
                    <ExpansionPanelSummary
                        style={{ paddingLeft: 0, paddingRight: 0, marginBottom: -12 }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            variant="caption"
                            color="primary"
                        >
                            {props.notChangeTitle}
                        </Typography >
                    </ExpansionPanelSummary>

                    {props.change && <div>
                        <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0, }} />

                        <Button onClick={props.addItem} color="primary" disabled={props.disableAddButton} >
                            <AddIcon className={props.classes.extendedIcon} />

                            {props.addButtonLabel}
                        </Button>
                    </div>}

                    {(props.change && props.freeLabel) && <Typography
                        variant="body2"
                        align="left"
                        style={{ color: '#6A6A6A', marginTop: 6, marginBottom: 6 }}
                    >
                        {props.freeLabel}
                    </Typography>}

                    {list.length !== 0 && <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0, }} />}

                    <ExpansionPanelDetails className={props.classes.expansionPanelDetailsStyle}>
                        {list}
                    </ExpansionPanelDetails>

                    <Divider style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0, }} />

                    <Typography
                        variant="body2"
                        align="center"
                        style={{ color: '#6A6A6A', marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 0 }}
                    >
                        Listen er scrollbar <InfoIcon style={{ marginBottom: -8, color: '#9A9A9A' }} />
                    </Typography>
                </ExpansionPanel>
            </div>
        )
    }

    // Populate list
    let list = [];
    if (props.items && !_.isEmpty(props.items)) {
        list = Object.values(props.items).sort((a, b) => Date.parse(new Date((b.creationDate ? b.creationDate : b.date))) - Date.parse(new Date((a.creationDate ? a.creationDate : a.date))));
        list = list.map((el, i) => {
            // In edit mode
            if (props.change) {
                return (
                    <div key={i} style={{ padding: '0px 8px 8px 8px', borderRadius: 5, border: '1px solid #e4e4e4', backgroundColor: i % 2 === 0 ? 'white' : '#fafafa', marginTop: 8, marginBottom: i < list.length - 1 ? 0 : 10 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
                            <KeyboardDatePicker
                                disableToolbar
                                required
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id={"date-picker-inline-" + el.id + '-' + i}
                                label="Dato"
                                value={el.date}
                                onChange={event => { props.inputChangedHandler(event, el.id, "date") }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                keyboardIcon={<DateRangeIcon fontSize="small" style={{ color: '#5EBD3E' }} />}
                                style={{ width: '100%', marginRight: 8 }}
                            />
                        </MuiPickersUtilsProvider>

                        {props.variant === "thinning" && <div>
                            {moment(el.date).isSameOrBefore(moment().endOf("day")) && <div>
                                {el.percentageThinning !== '' && <TextField
                                    id="tyndingsprocent"
                                    label="Tyndingsprocent"
                                    required
                                    error
                                    helperText="Erstat hugstprocent med reelle tal nedenfor."
                                    multiline
                                    className={props.classes.textField}
                                    value={el.percentageThinning}
                                    onChange={event => { props.inputChangedHandler(event.target.value, el.id, "percentageThinning") }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    margin="normal"
                                />}

                                <TextField
                                    id="stamtal"
                                    label="Stamtal"
                                    required
                                    multiline
                                    className={props.classes.textField}
                                    value={typeof el.treeCountThinning === 'number' ? el.treeCountThinning.toFixed(0) : el.treeCountThinning}
                                    onChange={event => { props.inputChangedHandler(event.target.value, el.id, "treeCountThinning") }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">antal/ha</InputAdornment>,
                                    }}
                                    margin="normal"
                                />

                                <TextField
                                    id="vedmasse"
                                    label="Vedmasse"
                                    required
                                    multiline
                                    className={props.classes.textField}
                                    value={typeof el.volumeThinning === 'number' ? el.volumeThinning.toFixed(0) : el.volumeThinning}
                                    onChange={event => { props.inputChangedHandler(event.target.value, el.id, "volumeThinning") }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">m3/ha</InputAdornment>,
                                    }}
                                    margin="normal"
                                />
                            </div>}

                            {moment(el.date).isAfter(moment().add(1, 'days').startOf('day')) && <TextField
                                id="tyndingsprocent"
                                label="Tyndingsprocent"
                                required
                                multiline
                                className={props.classes.textField}
                                value={typeof el.percentageThinning === 'number' ? el.percentageThinning.toFixed(0) : el.percentageThinning}
                                onChange={event => { props.inputChangedHandler(event.target.value, el.id, "percentageThinning") }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                margin="normal"
                            />}
                        </div>}

                        <TextField
                            id="note"
                            label="Kommentar"
                            multiline
                            className={props.classes.textField}
                            value={el.note}
                            onChange={event => { props.inputChangedHandler(event.target.value, el.id, "note") }}
                            margin="normal"
                        />

                        <div style={{ marginTop: 8 }}>
                            <ImageGridList
                                images={el.images ? el.images : []}
                                change={props.change}
                                action={event => { props.inputChangedHandler(event, el.id, "deleteImage") }}
                                disabled={props.disabled || props.networkStatus === 'offline'}
                                whenChange={event => { props.inputChangedHandler(event, el.id, "addImage") }}
                            />
                            {props.networkStatus === 'offline' &&
                                <Typography
                                    variant="caption"
                                    className={props.classes.textField}
                                    style={{ color: '#717171' }}
                                >
                                    Det er ikke muligt at vedhæfte billeder. Ingen forbindelse til internet.
                            </Typography >}
                        </div>

                        <Button
                            style={{ marginTop: 22 }}
                            variant="outlined"
                            onClick={() => props.onDeleteHandler(el.id)}
                            className={props.classes.buttonStyle}
                        >
                            <DeleteIcon
                                className={props.classes.extendedIcon}
                                style={{ color: '#e75f5f' }}
                            />

                            Slet
                        </Button>
                    </div>
                );
            }

            // In non-edit mode
            else {
                const dateDisp = props.variant === "thinning" ? moment(new Date(el.date)).format('DD[-]MM[-]YYYY') : moment(new Date(el.date)).format('DD[-]MM[-]YYYY HH:mm');
                const noteDisp = el.note;

                return (
                    <div key={i} style={{ padding: 8, borderRadius: 5, border: '1px solid #e4e4e4', backgroundColor: i % 2 === 0 ? 'white' : '#fafafa', marginTop: 8, marginBottom: i < list.length - 1 ? 0 : 10 }}>
                        <Typography
                            variant="caption"
                            style={{ color: '#747474' }}
                            display='block'
                            gutterBottom
                        >
                            {dateDisp}
                        </Typography>

                        {el.from && <Typography
                            variant="caption"
                            style={{ color: '#747474' }}
                            display='block'
                            gutterBottom
                        >
                            {el.from}
                        </Typography>}
                        {props.variant === "thinning" && <div>
                            {el.percentageThinning !== '' && <div>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{ color: moment(el.date).isSameOrBefore(moment().endOf("day")) ? '#F44336' : null }}
                                >
                                    Procent
                            </Typography >

                                <div className={props.classes.nonEditTextStyle}>
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                    >
                                        {el.percentageThinning}
                                    </Typography >
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        style={{ marginBottom: 8 }}
                                    >
                                        %
                                </Typography >
                                </div>

                                {moment(el.date).isSameOrBefore(moment().endOf("day")) && <div style={{ marginTop: -8, marginBottom: 7 }}>
                                    <Typography
                                        variant="caption"
                                        style={{ color: '#F44336' }}
                                    >
                                        Erstat hugstprocent med reelle tal nedenfor.
                                </Typography >
                                </div>}
                            </div>}

                            {props.variant === "thinning" && <div>
                                {el.treeCountThinning !== '' && <div>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Stamtal
                                </Typography >

                                    <div className={props.classes.nonEditTextStyle}>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            {el.treeCountThinning}
                                        </Typography >
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            style={{ marginBottom: 8 }}
                                        >
                                            antal/ha
                                    </Typography >
                                    </div>
                                </div>}

                                {el.volumeThinning !== '' && <div>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Vedmasse
                                </Typography >

                                    <div className={props.classes.nonEditTextStyle}>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            {el.volumeThinning}
                                        </Typography >
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            style={{ marginBottom: 8 }}
                                        >
                                            m3/ha
                                    </Typography >
                                    </div>
                                </div>}
                            </div>}
                        </div>}

                        <Typography
                            variant="body1"
                            gutterBottom
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {noteDisp}
                        </Typography>

                        {(el.images && el.images.length > 0) && <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                            <ImageGridList
                                images={el.images}
                                change={props.change}
                            />
                        </div>}
                    </div>
                );
            }
        })
    }

    // If in edit mode
    if (props.change) {
        return (
            <div>
                {expansionList(list)}
            </div>
        )
    }

    // If in non-edit mode
    else {
        // If items to show
        if (list.length !== 0) {
            return (
                <div>
                    {expansionList(list)}
                </div>
            )
        }
        // If no item to show - e.g. list is empty
        else {
            return null;
        }
    }
};

HistoryList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HistoryList);