const species = require('./updatedSpeciesList.json')

export const getSpeciesList = (type, scheme) => {
    // Input checking
    const allowedTypes = ['needle', 'foliage', 'other', 'line'];
    const allowedSchemes = species.schemes;
    if (!allowedTypes.includes(type)) {
        throw new Error("Invalid Input <type>", { valid: allowedTypes });
    } else if (!allowedSchemes.includes(scheme)) {
        throw new Error("Invalid Input <scheme>", { valid: allowedSchemes });
    }

    // Return the species list according to type and scheme
    // Species that are no longer in user are removed
    return Object.entries(species).filter(spc => spc[1].type === type && spc[1].availability !== 'removed').sort((a, b) => a[0].localeCompare(b[0], 'da-DK'));
    // switch (type) {
    //     case 'needle': return Object.entries(species).filter(spc => spc[1].type === 'needle' && spc[1].availability !== 'removed').sort((a, b) => a[0].localeCompare(b[0]));
    //     case 'foliage': return Object.entries(species).filter(spc => spc[1].type === 'foliage').sort((a, b) => a[0].localeCompare(b[0]));;
    //     case 'other': return Object.entries(species).filter(spc => spc[1].type === 'other').sort((a, b) => a[0].localeCompare(b[0]));;
    //     case 'line': return Object.entries(species).filter(spc => spc[1].type === 'line').sort((a, b) => a[0].localeCompare(b[0]));;
    //     default: return Object.entries(species);
    // }
}

export const getSpeciesListBasedOnCategory = (category, scheme, planType) => {
    const allowedCategories = ['biotopePlan'];
    const allowedSchemes = species.schemes;
    const sch = scheme ? scheme : 'skovkortet';
    if (!allowedCategories.includes(category)) {
        throw new Error("Invalid Input <type>", { valid: allowedCategories });
    } else if (!allowedSchemes.includes(sch)) {
        throw new Error("Invalid Input <scheme>", { valid: allowedSchemes });
    }
    if (category === 'biotopePlan') {
        if (planType && planType === 'full') {
            return Object.entries(species).filter(spc => spc[1].categories && spc[1].categories.includes(category));
        } else if (planType && planType === '5 årig') {
            return Object.entries(species).filter(spc => spc[1].categories && spc[1].categories.includes(category) && spc[1].availability !== 'removed' && spc[1].fiveYear);
        } else {
            return Object.entries(species).filter(spc => spc[1].categories && spc[1].categories.includes(category) && spc[1].availability !== 'removed');
        }
    } else {
        return Object.entries(species).filter(spc => spc[1].categories && spc[1].categories.includes(category) && spc[1].availability !== 'removed').sort((a, b) => a[0].localeCompare(b[0], 'da-DK'));
    }
}

export const getFullSpecieParameterList = (specie) => {
    try {
        const parOut = species[specie];
        if (parOut === undefined) {
            return {};
        }
        return parOut;
    } catch (error) {
        console.error("Error in getting specie full parameter list", error)
        return {};
    }
}

export const getSpecieParameters = (specie, scheme) => {
    // Check input
    if (specie === '') return {};
    if (scheme === '') return {};
    // Try to fetch specie parameters from file
    try {
        const parOut = { ...species[specie], ...species[specie][scheme] };
        if (parOut === undefined) {
            return {};
        }
        return parOut;
    } catch (error) {
        console.error("Error in getting specie parameter", error)
        return species['default'];
    }
}

export const getSpecieParameter = (specie, scheme, par) => {
    // Input checking
    let parOut = null;
    try {
        parOut = species[specie][scheme][par];
        return parOut;
    } catch (error) {
        console.error("Error in getting specie parameter", error)
    }
}

export const getSpecieColor = (specie, scheme) => {
    // Check input
    const sch = scheme ? scheme : 'skovkortet';
    // Set default color
    let color = "#ffffff";
    try {
        color = species[specie][sch]['color'];
    } catch (error) {
        console.error("Get Specie color Error: ", error)
    }
    return color;
}

export const getSpecieLineWeight = (specie, scheme) => {
    // Check input
    const sch = scheme ? scheme : 'skovkortet';
    // Set default style
    let weight = 1;
    try {
        weight = species[specie][sch]['weight'];
    } catch (error) {
        console.error("Get specie style Error: ", error)
    }
    return weight;
}

export const getSpecieLineStyle = (specie, scheme) => {
    // Check input
    const sch = scheme ? scheme : 'skovkortet';
    // Set default style
    let style = null;
    try {
        style = species[specie][sch]['dashStyle'];
    } catch (error) {
        console.error("Get specie style Error: ", error)
    }
    return style;
}

export const getSpecieCategories = (specie) => {
    try {
        return species[specie].categories;
    } catch (error) {
        console.error("Get specie style Error: ", error)
        return [];
    }
}

