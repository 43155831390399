import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loading: false,
    successCustomer: false,
    attachCustomerSuccess: false,
    deleteCustomer: false,
    error: false,
    customerList: null,
    customerListOrg: null,
    customerIdList: null,
    currentlySelectedForestId: null,
    updateCustomerIdListFlag: false,
};

const setCustomerIdList = (state, action) => {
    return updateObject(state, { customerIdList: action.customerIdList });
};

const setUpdateCustomerIdListFlag = (state, action) => {
    return updateObject(state, { updateCustomerIdListFlag: action.updateCustomerIdListFlag });
};

const setCurrentlySelectedForestId = (state, action) => {
    return updateObject(state, { currentlySelectedForestId: action.currentlySelectedForestId });
};

const saveCustomerStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const saveCustomerSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        successCustomer: true,
        updateCustomerIdListFlag: true,
    });
};

const attachCustomerToUserSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        attachCustomerSuccess: true,
        updateCustomerIdListFlag: true,
    });
};

const removeCustomerFromUserSuccess = (state, action) => {
    const updatedCustomerList = { ...state.customerList };
    delete updatedCustomerList[action.customerId];
    return updateObject(state, {
        customerList: updatedCustomerList,
        loading: false,
        updateCustomerIdListFlag: true,
    });
};

const unmountCustomer = (state, action) => {
    return updateObject(state, {
        error: null,
        successCustomer: false,
        deleteCustomer: false,
        attachCustomerSuccess: false,
    });
};

const fetchCustomersStart = (state, action) => {
    return updateObject(state, { loading: true });
};

const fetchCustomersSuccess = (state, action) => {
    return updateObject(state, {
        customerList: { ...state.customerList, [action.id]: action.customerList },
        loading: false
    });
};

const fetchCustomersSuccessOrg = (state, action) => {
    return updateObject(state, {
        customerListOrg: { ...state.customerListOrg, [action.id]: action.customerListOrg },
        loading: false
    });
};

const fetchCustomersFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const fetchCustomersFailOrg = (state, action) => {
    return updateObject(state, { loading: false, customerListOrg: [], error: action.error });
};

const deleteCustomerSuccess = (state, action) => {
    const updatedCustomerList = { ...state.customerList };
    delete updatedCustomerList[action.customerId];

    const updatedCustomerListOrg = { ...state.customerListOrg }
    delete updatedCustomerListOrg[action.customerId];

    return updateObject(state, {
        loading: false,
        customerList: updatedCustomerList,
        customerListOrg: updatedCustomerListOrg,
        deleteCustomer: true,
        updateCustomerIdListFlag: true,
    });
};

const deleteCustomerFail = (state, action) => {
    return updateObject(state, { loading: false });
};

const deleteCustomerStart = (state, action) => {
    return updateObject(state, { loading: true });
};


const setForestHasPayedSubscriptionStart = (state, action) => {
    return updateObject(state, {loading: true});
}

const setForestHasPayedSubscriptionSuccess = (state, action) => {
    let newCustomerList = {...state.customerList};
    newCustomerList[action.forestId].hasPayedSubscription = action.payedState;
    return updateObject(state, {
        loading: false,
        customerList: newCustomerList,
    });
}

const setForestHasPayedSubscriptionFail = (state, action) => {
    return updateObject(state, {loading: false, error: action.error});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENTLY_SELECTED_FOREST_ID: return setCurrentlySelectedForestId(state, action);
        case actionTypes.SET_UPDATE_CUSTOMER_ID_LIST_FLAG: return setUpdateCustomerIdListFlag(state, action);
        case actionTypes.SET_CUSTOMER_ID_LIST: return setCustomerIdList(state, action);
        case actionTypes.SAVE_CUSTOMER_START: return saveCustomerStart(state, action);
        case actionTypes.SAVE_CUSTOMER_SUCCESS: return saveCustomerSuccess(state, action);
        case actionTypes.ATTACH_CUSTOMER_TO_USER_SUCCESS: return attachCustomerToUserSuccess(state, action);
        case actionTypes.REMOVE_CUSTOMER_FROM_USER_SUCCESS: return removeCustomerFromUserSuccess(state, action);
        case actionTypes.UNMOUNT_CUSTOMER: return unmountCustomer(state, action);
        case actionTypes.FETCH_CUSTOMERS_START: return fetchCustomersStart(state, action);
        case actionTypes.FETCH_CUSTOMERS_SUCCESS: return fetchCustomersSuccess(state, action);
        case actionTypes.FETCH_CUSTOMERS_SUCCESS_ORG: return fetchCustomersSuccessOrg(state, action);
        case actionTypes.FETCH_CUSTOMERS_FAIL: return fetchCustomersFail(state, action);
        case actionTypes.FETCH_CUSTOMERS_FAIL_ORG: return fetchCustomersFailOrg(state, action);
        case actionTypes.DELETE_CUSTOMER_SUCCESS: return deleteCustomerSuccess(state, action);
        case actionTypes.DELETE_CUSTOMER_FAIL: return deleteCustomerFail(state, action);
        case actionTypes.DELETE_CUSTOMER_START: return deleteCustomerStart(state, action);

        case actionTypes.SET_FOREST_HAS_PAYED_SUBSCRIPTION_START: return setForestHasPayedSubscriptionStart(state, action);
        case actionTypes.SET_FOREST_HAS_PAYED_SUBSCRIPTION_SUCCESS: return setForestHasPayedSubscriptionSuccess (state, action);
        case actionTypes.SET_FOREST_HAS_PAYED_SUBSCRIPTION_FAIL: return setForestHasPayedSubscriptionFail(state, action);

        default: return state;
    }
};

export default reducer;