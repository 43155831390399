import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    filterState: null,
    filterObjectIds: null,
    showFilterInMap: false,
    defaultTasksTypes: ["Skovfoged", "Skovarbejder", "Maskinfører", "Skytte"],
    defaultTasks: ["Flis", "Hegning", "Kratrydning", "Opridsning", "Nedvisning", "Sprøjtning", "Røjning", "Snudebiller", "Udrensning", "Grøfter", "Reperation sti/vej"],
};

const setFilterState = ( state, action ) => {
    return updateObject( state, { filterState: action.filterState } );
};

const setFilterObjectIds = ( state, action ) => {
    return updateObject( state, { filterObjectIds: action.filterObjectIds } );
};

const setShowFilterInMap = ( state, action ) => {
    return updateObject( state, { showFilterInMap: action.showFilterInMap } );
};

const reducer = (state = initialState, action) => {    
    switch (action.type) {
        case actionTypes.SET_ACTIVITY_FILTER_STATE: return setFilterState(state, action);
        case actionTypes.SET_ACTIVITY_FILTER_OBJECT_IDS: return setFilterObjectIds(state, action);
        case actionTypes.SET_ACTIVITY_SHOW_FILTER_IN_MAP: return setShowFilterInMap(state, action);
        
        default: return state;
    }
};

export default reducer;