import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Divider from '@material-ui/core/Divider';

// Material UI icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';

const styles = () => ({
    root: {
        width: '100%',
    },
    expansionPanelDetailsStyle: {
        padding: 0,
        margin: 0,
        position: 'relative',
        //margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});

const ExpansionPanelMenu = (props) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={props.classes.root}>
            <Divider />

            <ExpansionPanel
                style={{ borderRadius: 0, boxShadow: 'none', margin: 0 }}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <ExpansionPanelSummary
                    style={{ padding: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        Hjælpecirkler
                    </Typography >
                </ExpansionPanelSummary>

                <ExpansionPanelDetails
                    className={props.classes.expansionPanelDetailsStyle}
                >
                    {props.children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Divider />
        </div>
    );
}

export default withStyles(styles)(ExpansionPanelMenu);