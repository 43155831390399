import React from 'react';

const SpaceMenu = (props) => {
    let multiplier = 1;
    
    if (props.multiplier) {
        multiplier = props.multiplier;
    }

    return (
        <div style={{ height: 8 * multiplier }} />
    );
}

export default SpaceMenu;