import * as actionTypes from './actionTypes';

export const sidebarOpen = (state) => {
    return {
        type: actionTypes.SIDEBAR_OPEN,
        sidebar: state
    };
}

export const setNetworkStatus = (status) => {
    return {
        type: actionTypes.SET_NETWORK_STATUS,
        networkStatus: status
    };
}

export const mainMenuOpen = (state) => {
    return {
        type: actionTypes.MAIN_MENU_OPEN,
        mainMenuOpen: state
    };
} 

export const accountMenuOpen = (state) => {
    return {
        type: actionTypes.ACCOUNT_MENU_OPEN,
        accountMenuOpen: state
    };
} 

export const setChosenWorkEnvironment = (workEnvironment) => {
    return {
        type: actionTypes.SET_CHOSEN_WORK_ENVIRONMENT,
        chosenWorkEnvironment: workEnvironment
    };
}

export const setKanbanBoardActive = (state) => {
    return {
        type: actionTypes.SET_KANBAN_BOARD_ACTIVE,
        kanbanBoardActive: state
    };
};

export const setDisplayUpgradeBox = (state) => {
    return {
        type: actionTypes.SET_DISPLAY_UPGRADE_BOX,
        displayUpgradeBox: state
    };
};

export const setTotalForestArea = (area) => {
    return {
        type: actionTypes.SET_TOTAL_FOREST_AREA,
        totalForestArea: area
    };
}

export const setNumberOfPoints = (num) => {
    return {
        type: actionTypes.SET_NUMBER_OF_POINTS,
        numPoints: num
    };
}

export const setNumberOfHuntingPoints = (num) => {
    return {
        type: actionTypes.SET_NUMBER_OF_HUNTING_POINTS,
        numHuntingPoints: num
    };
}

export const setNumberOfNotes = (num) => {
    return {
        type: actionTypes.SET_NUMBER_OF_NOTES,
        numNotes: num
    };
}

export const setSpeciesScheme = (speciesScheme) => {
    return {
        type: actionTypes.SET_SPECIES_SCHEME,
        speciesScheme: speciesScheme
    };
}



