import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

// Icons
import CloseIcon from '@material-ui/icons/CloseRounded';
import CallMadeIcon from '@material-ui/icons/CallMade';

// Chart
import { Line } from 'react-chartjs-2';

// Components
import OutlinedButton from '../../UI/Buttons/OutlinedButton';

import { simulate } from '../../Inventory/Utilities/VolumeFunctionsDK';

const styles = theme => ({
    nonEditTextStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    extendedIconAdd: {
        marginRight: theme.spacing.unit,
    },
})

class testEstimationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            species: ('species' in this.props) ? this.props.species : '',
            area: ('area' in this.props) ? this.props.area : '',
            year: ('year' in this.props) ? this.props.year : '',
            height: ('height' in this.props) ? this.props.height.split(',').join('.') : '',
            diameter: ('diameter' in this.props) ? this.props.diameter.split(',').join('.') : '',
            mix: ('mix' in this.props) ? this.props.mix : '',
            coverage: ('coverage' in this.props) ? this.props.coverage : '',
            treeCount: ('treeCount' in this.props) ? this.props.treeCount : '',
            fellingDate: '',
            fellingTreeCount: '',
            fellingVol: '',
            plotDataH: null,
            plotDataG: null,
            plotDataN: null,
            plotDataD: null,
            plotDataV: null,
            plotDataF: null,
        }
    }

    onEstimateVolumeClick = () => {
        // Convert input to numbers
        const fYear = parseFloat(this.state.year);
        const fHeight = parseFloat(this.state.height);
        const fDiameter = parseFloat(this.state.diameter);
        const fTreeCountHA = parseFloat(this.state.treeCount.split(',').join('.')) * 100;
        const fTreeCountArea = fTreeCountHA * this.state.area;

        // Felling
        // const fFellingVol = 0;
        // const fFellingTreeCount = 0;

        // Set initialConditions
        const initCond = {
            specie: this.state.species,
            year: fYear,
            n_steps: 102,
            H_start: fHeight,
            D_start: fDiameter,
            N_start: fTreeCountArea,
        }
        const fellingData = {
            year: [3, 6, 9, 12, 17, 22, 27, 32, 39, 46, 55, 64, 75, 86, 97 ],
            Vf:   [41, 46, 43, 32, 42, 46, 38, 53, 71, 75, 95, 100, 102, 106],
            Nf:   [1036, 755, 470, 251, 200, 150, 87, 95, 92, 73, 68, 54, 43, 33, 22]
        }
        // Run simulation
        const data = simulate(initCond, fellingData);

        let labelsData = new Array(initCond.n_steps + 1).fill(0);
        labelsData = labelsData.map((d, i) => {
            return initCond.year + i;
        })

        const dataPlotH = {
            labels: labelsData,//['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Højde [m]',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.H,//[65, 59, 80, 81, 56, 55, 40]
                }
            ]
        };
        const dataPlotG = {
            labels: labelsData,//['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Grundfladeareal [m2]',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.G,//[65, 59, 80, 81, 56, 55, 40]
                }
            ]
        };
        const dataPlotN = {
            labels: labelsData,//['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Stamtal [-]',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.N,//[65, 59, 80, 81, 56, 55, 40]
                }
            ]
        };
        const dataPlotD = {
            labels: labelsData,//['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Diameter [m]',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.D,//[65, 59, 80, 81, 56, 55, 40]
                }
            ]
        };

        const dataPlotV = {
            labels: labelsData,//['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Volume [m3]',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.V,//[65, 59, 80, 81, 56, 55, 40]
                }
            ]
        };

        const dataPlotF = {
            labels: labelsData,//['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Formtal [-]',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data.F,//[65, 59, 80, 81, 56, 55, 40]
                }
            ]
        };

        this.setState({ plotDataH: dataPlotH, plotDataG: dataPlotG, plotDataN: dataPlotN, plotDataD: dataPlotD, plotDataV: dataPlotV, plotDataF: dataPlotF });

        //estimationStep(fHeight, fDiameter, fTreeCountArea, fYear, this.state.species, fFellingVol, fFellingTreeCount)
    }


    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'top',
                    justifyContent: 'space-between',
                    margin: 16,
                    paddingLeft: 8,
                    paddingRight: 8
                }}>
                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        Fremskrivning
                    </Typography >

                    <IconButton aria-label="Close" size="medium" style={{ }} onClick={this.props.onExit}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>

                <DialogContent style={{ width: '100%', flexDirection: 'column' }}>
                    <div>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            Art
                        </Typography >

                        <div className={classes.nonEditTextStyle}>
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                {this.state.species}
                            </Typography >
                        </div>
                    </div>

                    <div>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            Areal [ha]
                        </Typography >

                        <div className={classes.nonEditTextStyle}>
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                {this.state.area.toString().split('.').join(',')}
                            </Typography >
                        </div>
                    </div>

                    <TextField
                        id={"year"}
                        label="Årstal"
                        value={this.state.year}
                        type="number"
                        onWheelCapture={e => { e.target.blur() }}
                        onChange={event => { this.setState({ year: event.target.value }) }}
                        margin="normal"
                        style={{ marginTop: 26, marginRight: 8 }}
                    />

                    <TextField
                        id={"heigth"}
                        label="Højde [m]"
                        value={this.state.height}
                        type="number"
                        onWheelCapture={e => { e.target.blur() }}
                        onChange={event => { this.setState({ height: event.target.value }) }}
                        margin="normal"
                        style={{ marginTop: 26, marginRight: 8 }}
                    />

                    <TextField
                        id={"diameter"}
                        label="Diameter [cm]"
                        value={this.state.diameter}
                        type="number"
                        onWheelCapture={e => { e.target.blur() }}
                        onChange={event => { this.setState({ diameter: event.target.value }) }}
                        margin="normal"
                        style={{ marginTop: 26, marginRight: 8 }}
                    />

                    <TextField
                        id={"mix"}
                        label="Indb. [%]"
                        value={this.state.mix}
                        type="number"
                        onWheelCapture={e => { e.target.blur() }}
                        onChange={event => { this.setState({ mix: event.target.value }) }}
                        margin="normal"
                        style={{ marginTop: 26, marginRight: 8 }}
                    />

                    <TextField
                        id={"coverage"}
                        label="Bevk. [%]"
                        value={this.state.coverage}
                        type="number"
                        onWheelCapture={e => { e.target.blur() }}
                        onChange={event => { this.setState({ coverage: event.target.value }) }}
                        margin="normal"
                        style={{ marginTop: 26, marginRight: 8 }}
                    />

                    <TextField
                        id={"treeCount"}
                        label="Stamtal [pr. 100m2]"
                        value={this.state.treeCount}
                        onWheelCapture={e => { e.target.blur() }}
                        onChange={event => { this.setState({ treeCount: event.target.value }) }}
                        margin="normal"
                        style={{ marginTop: 26, marginRight: 8 }}
                    />

                    <div style={{ marginTop: 20, marginLeft: -8 }}>
                        <OutlinedButton
                            buttonLabel={'Frems.'}
                            buttonIcon={<CallMadeIcon className={classes.extendedIconAdd} />}
                            iconLeft={true}
                            onPress={this.onEstimateVolumeClick.bind(this)}
                            color="black"
                        />
                    </div>

                    <div style={{ padding: 10 }}>
                        {this.state.plotDataH !== null && <div style={{ marginBottom: 16 }}><Line data={this.state.plotDataH} /></div>}
                        {this.state.plotDataG !== null && <div style={{ marginBottom: 16 }}><Line data={this.state.plotDataG} /></div>}
                        {this.state.plotDataN !== null && <div style={{ marginBottom: 16 }}><Line data={this.state.plotDataN} /></div>}
                        {this.state.plotDataD !== null && <div style={{ marginBottom: 16 }}><Line data={this.state.plotDataD} /></div>}
                        {this.state.plotDataV !== null && <div style={{ marginBottom: 16 }}><Line data={this.state.plotDataV} /></div>}
                        {this.state.plotDataF !== null && <div style={{ marginBottom: 8 }}><Line data={this.state.plotDataF} /></div>}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
}

testEstimationModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(testEstimationModal));