import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';

// Components
import * as actions from '../../../../store/actions/index';
import CautionMenu from '../UIElements/CautionMenu';
import SpaceMenu from '../UIElements/SpaceMenu';

const cookies = new Cookies();

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    extendedIconAdd: {
        marginRight: theme.spacing.unit,
    },
    formControl: {
        margin: 12,
        width: 500,
    },
    textField: {
        width: '100%',
    },
})

class Settings extends React.Component {
    constructor(props) {
        // const changeAllowedState = cookies.get('changeAllowedState');
        const polyToolTipState = cookies.get('polyToolTipState');
        const lineLabelsState = cookies.get('lineLabelsState');
        const noteToolTipState = cookies.get('noteToolTipState');
        super(props);

        this.state = {
            screenWidth: window.innerWidth,
            togglerCheckedChange: true,
            togglerCheckedChangeHunting: true,
            togglerCheckedPolyToolTip: polyToolTipState === 'true' ? true : false,
            togglerCheckedLineLabels: lineLabelsState === 'true' ? true : false,
            togglerCheckedNoteToolTip: noteToolTipState === 'true' ? true : false,
            togglerCheckedSpeciesScheme: "skovkortet",
            onboardingChangeState: false,
        };
    }

    componentWillMount() {
        // Set stuff based on app config settings
        let lineLabelsShowState = true;
        if (this.props.config.lineLabelsDisplayed !== 'enabled') {
            lineLabelsShowState = false;
            this.setState({ togglerCheckedLineLabels: false }) 
            cookies.set('lineLabelsState', 'false', { path: '/' });

        } else if (this.state.togglerCheckedLineLabels !== this.props.lineLabelsActive) {
            lineLabelsShowState = this.state.togglerCheckedLineLabels;
        }

        if (this.props.config.forestDataEditable !== 'enabled' && this.state.togglerCheckedChange) {
            this.setState({togglerCheckedChange: false });
            this.props.onSetForestOwnerChangeAllowed(false);
        }
        if (this.props.config.huntingDataEditable !== 'enabled' && this.state.togglerCheckedChangeHunting) {
            this.setState({togglerCheckedChangeHunting: false });
            this.props.onSetForestOwnerChangeAllowedHunting(false);
        }
        // TODO : FIX SCHEME CHOICE
        // if (this.state.togglerCheckedSpeciesScheme !== this.props.speciesScheme) {
        //     this.props.onSetSpeciesScheme(this.state.togglerCheckedSpeciesScheme);
        // }
        if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.HD && this.props.currentAuthUser.affiliation.HD.status === 'active') {
            this.props.onSetSpeciesScheme("hededanmark");
        } else {
            // this.props.onSetSpeciesScheme("skovkortet");
        }
        // Set if changes are allowed for forest owner, according to cookie
        if (this.props.currentAuthUser.userRole !== 'forestOwner') {
            // Set functionality according to user tags [Admin, Skovfoged, Skovarbejder, Maskinfører]
            let changesAllowed = true;
            if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skovejer"].includes(tag))) {
                changesAllowed = false;
            }
            this.props.onSetForestOwnerChangeAllowed(changesAllowed);
            this.props.onSetForestOwnerChangeAllowedHunting(changesAllowed);
            this.setState({ togglerCheckedChange: changesAllowed, togglerCheckedChangeHunting: changesAllowed })
            // Reset cookies if user is forester
            cookies.set('changeAllowedState', changesAllowed ? 'true' : 'false', { path: '/' });
            cookies.set('changeAllowedStateHunting', changesAllowed ? 'true' : 'false', { path: '/' });
        } 
        // else {
        //     this.props.onSetForestOwnerChangeAllowed(this.state.togglerCheckedChange);
        //     this.props.onSetForestOwnerChangeAllowedHunting(this.state.togglerCheckedChangeHunting);
        // }

        // Check for mobile
        if (this.state.screenWidth < 760) {
            // Set if polygon tooltip is active according to cookie
            this.props.onSetPolyToolTipActive(false)
            // Set if line labels are active according to cookie
            this.props.onSetLinesLabelActive(lineLabelsShowState)
            // Set if note tooltip is active according to cookie
            this.props.onSetNoteToolTipActive(false)
            // Set state
            this.setState({ togglerCheckedPolyToolTip: true, togglerCheckedNoteToolTip: true })
        } else {
            // Set if polygon tooltip is active according to cookie
            this.props.onSetPolyToolTipActive(!this.state.togglerCheckedPolyToolTip)
            // Set if line labels are active according to cookie
            this.props.onSetLinesLabelActive(lineLabelsShowState)
            // Set if note tooltip is active according to cookie
            this.props.onSetNoteToolTipActive(!this.state.togglerCheckedNoteToolTip)
        }
    }

    renderContent() {
        if (this.props.onboardingActive) {
            if (!this.props.forestOwnerAllowChange) {
                cookies.set('changeAllowedState', 'true', { path: '/' });
                this.props.onSetForestOwnerChangeAllowed(true);
                this.setState({ togglerCheckedChange: true, onboardingChangeState: true })
            }
        } else {
            if (this.state.onboardingChangeState) {
                cookies.set('changeAllowedState', 'false', { path: '/' });
                this.props.onSetForestOwnerChangeAllowed(false);
                this.setState({ togglerCheckedChange: false, onboardingChangeState: false })
            }
        }

        const subComponentChangeSpeciesScheme =
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Artskatalog og farver
                </Typography >
                <FormControlLabel
                    disabled={this.props.config.forestDataEditable !== 'enabled'}
                    control={
                        <Switch
                            checked={this.state.togglerCheckedSpeciesScheme === 'skovkortet' ? true : false}
                            onChange={this.onButtonPressChangeSpeciesScheme}
                            color="primary"
                        />
                    }
                    label="Skovkortet.dk"
                />
                {this.state.togglerCheckedSpeciesScheme === 'skovkortet' && <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 47 }}>
                    Skovkortet optræder med Skovkortet.dks træarter og standardfarver
                </Typography>}

                <FormControlLabel
                    disabled={this.props.config.forestDataEditable !== 'enabled'}
                    control={
                        <Switch
                            checked={this.state.togglerCheckedSpeciesScheme === 'hededanmark' ? true : false}
                            onChange={this.onButtonPressChangeSpeciesScheme}
                            color="primary"
                        />
                    }
                    label="HedeDanmark"
                />
                {this.state.togglerCheckedSpeciesScheme === 'hededanmark' && <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 47 }}>
                    Skovkortet optræder med HedeDanmarks træarter og standardfarver
                </Typography>}

                {/* Under Management */}
                {this.props.config.underManagementText !== "" && <div>
                    <SpaceMenu />

                    <CautionMenu
                        title='Obs'
                        content={this.props.config.underManagementText}
                    />

                    <SpaceMenu />
                </div>}
            </div>

        const subComponentChangeMap =
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Skovkort
                </Typography >
                <FormControlLabel
                disabled={this.props.config.forestDataEditable !== 'enabled'}
                    control={
                        <Switch
                            checked={!this.state.togglerCheckedChange}
                            onChange={this.onButtonPressChange}
                            color="primary"
                        />
                    }
                    label="Skrivebeskyttet"
                />
                {this.state.togglerCheckedChange && <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 47 }}>
                    Skrivebeskyttelse er ophævet
                </Typography>}

                {/* Under Management */}
                {this.props.config.underManagementText !== "" && <div>
                    <SpaceMenu />

                    <CautionMenu
                        title='Obs'
                        content={this.props.config.underManagementText}
                    />

                    <SpaceMenu />
                </div>}
            </div>
        // Logic check on show component
        let showForestChangeAllowed = true;
        if (this.props.currentAuthUser.userRole === "forester") {
            showForestChangeAllowed = false;
        }

        const subComponentChangeHunting =
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Jagtkort
                </Typography >
                <FormControlLabel
                    control={
                        <Switch
                            checked={!this.state.togglerCheckedChangeHunting}
                            onChange={this.onButtonPressChangeHunting}
                            color="primary"
                        />
                    }
                    label="Skrivebeskyttet"
                />
                {this.state.togglerCheckedChangeHunting && <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 47 }}>
                    Skrivebeskyttelse er ophævet
                </Typography>}
            </div>
        // Logic check on show component
        let showHuntingChangeAllowed = true;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged"].includes(tag))) {
            showHuntingChangeAllowed = false;
        }

        const subComponentChangeToolTipPoly =
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Litra, linje og punkt infoboks
                </Typography >
                <FormControlLabel
                    control={
                        <Switch
                            checked={!this.state.togglerCheckedPolyToolTip}
                            onChange={this.onButtonPressPolyToolTip}
                            color="primary"
                        />
                    }
                    label="Aktiv"
                />
                {this.state.togglerCheckedPolyToolTip && <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 47 }}>
                    Infoboks til litra og linje er deaktiveret
                </Typography>}
            </div>

        const subComponentChangeToolTipNote =
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Note infoboks
                </Typography >
                <FormControlLabel
                    control={
                        <Switch
                            checked={!this.state.togglerCheckedNoteToolTip}
                            onChange={this.onButtonPressNoteToolTip}
                            color="primary"
                        />
                    }
                    label="Aktiv"
                />
                {this.state.togglerCheckedNoteToolTip && <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 47 }}>
                    Infoboks til note er deaktiveret
                </Typography>}
            </div>

        const subComponentChangeLineLabels =
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Linjelabels
                </Typography >
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.togglerCheckedLineLabels}
                            onChange={this.onButtonPressLineLabels}
                            color="primary"
                        />
                    }
                    label="Aktiv"
                />
                {!this.state.togglerCheckedLineLabels && <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 47 }}>
                    Linjelabels er deaktiveret
                </Typography>}
            </div>

        return (
            <div>
                {/* {subComponentChangeSpeciesScheme} */}
                {showForestChangeAllowed && subComponentChangeMap}
                {showHuntingChangeAllowed && subComponentChangeHunting}
                {subComponentChangeToolTipPoly}
                {subComponentChangeToolTipNote}
                {subComponentChangeLineLabels}
            </div>
        );
    }


    onButtonPressChangeSpeciesScheme = () => {
        // Set state
        this.setState({ togglerCheckedSpeciesScheme: this.state.togglerCheckedSpeciesScheme === 'hededanmark' ? 'skovkortet' : 'hededanmark' })
        // Set cookie
        cookies.set('speciesScheme', this.state.togglerCheckedSpeciesScheme === 'hededanmark' ? 'skovkortet' : 'hededanmark', { path: '/' });
        // Set reducer
        const val = this.state.togglerCheckedSpeciesScheme === 'hededanmark' ? 'skovkortet' : 'hededanmark'
        this.props.onSetSpeciesScheme(val);
        this.props.onRedrawCells();

        // Send event to Analytics
        ReactGA.event({
            category: 'Settings',
            action: `User changing species scheme: ${val}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    onButtonPressChange = () => {
        // Set state
        this.setState({ togglerCheckedChange: !this.state.togglerCheckedChange })
        // Set reducer
        this.props.onSetForestOwnerChangeAllowed(!this.state.togglerCheckedChange);

        // Send event to Analytics
        ReactGA.event({
            category: 'Settings',
            action: `User changing edit map to: ${this.state.togglerCheckedChange}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    onButtonPressChangeHunting = () => {
        // Set state
        this.setState({ togglerCheckedChangeHunting: !this.state.togglerCheckedChangeHunting })
        // Set reducer
        this.props.onSetForestOwnerChangeAllowedHunting(!this.state.togglerCheckedChangeHunting);

        // Send event to Analytics
        ReactGA.event({
            category: 'Settings',
            action: `User changing edit hunting map to: ${this.state.togglerCheckedChangeHunting}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    onButtonPressPolyToolTip = () => {
        // Set state
        this.setState({ togglerCheckedPolyToolTip: !this.state.togglerCheckedPolyToolTip })
        // Set cookie
        cookies.set('polyToolTipState', this.state.togglerCheckedPolyToolTip ? 'false' : 'true', { path: '/' });
        // Set reducer
        this.props.onSetPolyToolTipActive(this.state.togglerCheckedPolyToolTip)
        this.props.onSetRedrawHuntingFeatures(true);
        this.props.onSetRedrawHuntingPoints(true);
        this.props.onSetRedrawPoints(true);
        // this.props.onSetInsertPointsState(true);

        // Send event to Analytics
        ReactGA.event({
            category: 'Settings',
            action: `User changing polygon tooltip to: ${this.state.togglerCheckedPolyToolTip}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    onButtonPressLineLabels = () => {
        // Set state
        this.setState({ togglerCheckedLineLabels: !this.state.togglerCheckedLineLabels })
        // Set cookie
        cookies.set('lineLabelsState', this.state.togglerCheckedLineLabels ? 'false' : 'true', { path: '/' });
        // Set reducer
        this.props.onSetLinesLabelActive(!this.state.togglerCheckedLineLabels)

        // Send event to Analytics
        ReactGA.event({
            category: 'Settings',
            action: `User changing line labels to: ${this.state.togglerCheckedLineLabels}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    onButtonPressNoteToolTip = () => {
        // Set state
        this.setState({ togglerCheckedNoteToolTip: !this.state.togglerCheckedNoteToolTip })
        // Set cookie
        cookies.set('noteToolTipState', this.state.togglerCheckedNoteToolTip ? 'false' : 'true', { path: '/' });
        // Set reducer
        this.props.onSetNoteToolTipActive(this.state.togglerCheckedNoteToolTip)

        // Send event to Analytics
        ReactGA.event({
            category: 'Settings',
            action: `User changing note tooltip to: ${this.state.togglerCheckedNoteToolTip}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.root}>
                    <ExpansionPanel style={{ borderRadius: 0, borderWidth: 0, borderColor: 'white', boxShadow: 'none', }}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => this.setState({ change: false })}
                            style={{ paddingLeft: 16, paddingRight: 16 }}
                        >
                            <Typography className={classes.heading}>
                                Indstillinger
                            </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {this.renderContent()}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        speciesScheme: state.layout.speciesScheme,
        currentAuthUser: state.user.currentAuthUser,
        forestOwnerAllowChange: state.maps.forestOwnerAllowChange,
        forestOwnerAllowChangeHunting: state.hunting.forestOwnerAllowChange,
        onboardingActive: state.onboarding.onboardingActive,
        inTrialHuntingActive: state.account.inTrialHuntingActive,
        inTrial: state.account.inTrial,
        config: state.appConfig.mainMenu.settings,
        lineLabelsActive: state.maps.lineLabelsActive,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSetSpeciesScheme: (s) => dispatch(actions.setSpeciesScheme(s)),
        onSetForestOwnerChangeAllowed: (s) => dispatch(actions.setForestOwnerChangeAllowed(s)),
        onSetForestOwnerChangeAllowedHunting: (s) => dispatch(actions.setForestOwnerChangeAllowedHunting(s)),
        onSetPolyToolTipActive: (s) => dispatch(actions.setPolyToolTipActive(s)),
        onSetLinesLabelActive: (s) => dispatch(actions.setLineLabelsActive(s)),
        onSetNoteToolTipActive: (s) => dispatch(actions.setNoteToolTipActive(s)),
        onSetRedrawPoints: (s) => dispatch(actions.setRedrawPoints(s)),
        onRedrawCells: () => dispatch(actions.redrawCells()),
        onSetRedrawHuntingPoints: (s) => dispatch(actions.setRedrawHuntingPoints(s)),
        onSetRedrawHuntingFeatures: (s) => dispatch(actions.setRedrawHuntingFeatures(s)),
        onSetInsertPointsState: (s) => dispatch(actions.setInsertPointsState(s)),
        onSendEmail: (type, userId, customerEmail, content) => dispatch(actions.sendEmail(type, userId, customerEmail, content)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings));