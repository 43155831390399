import * as actionTypes from './actionTypes';

export const setSkipOnboardingState = (skipState) => {
    return {
        type: actionTypes.SET_SKIP_ONBOARDING_STATE,
        skipState: skipState
    };
};

export const setOnboardingActive = (onboardingActive) => {
    return {
        type: actionTypes.SET_ONBOARDING_ACTIVE,
        onboardingActive: onboardingActive
    };
};

export const setOnboardingState = (onboardingState) => {
    return {
        type: actionTypes.SET_ONBOARDING_STATE,
        onboardingState: onboardingState
    };
};

// Z-index toggle actions
export const zoomBtnZidxToggle = (state) => {
    return {
        type: actionTypes.ZOOM_BTN_Z_IDX_TOGGLE,
        state: state,
    };
};

export const mapLayerZidxToggle = (state) => {
    return {
        type: actionTypes.MAP_LAYER_Z_IDX_TOGGLE,
        state: state,
    };
};

export const forestSelecterZidxToggle = (state) => {
    return {
        type: actionTypes.FOREST_SELECTER_Z_IDX_TOGGLE,
        state: state,
    };
};

export const mapSelecterZidxToggle = (state) => {
    return {
        type: actionTypes.MAP_SELECTER_Z_IDX_TOGGLE,
        state: state,
    };
};

export const drawToolZidxToggle = (state) => {
    return {
        type: actionTypes.DRAW_TOOL_Z_IDX_TOGGLE,
        state: state,
    };
};

export const measToolZidxToggle = (state) => {
    return {
        type: actionTypes.MEAS_TOOL_Z_IDX_TOGGLE,
        state: state,
    };
};

export const newNoteBtnZidxToggle = (state) => {
    return {
        type: actionTypes.NEW_NOTE_BTN_Z_IDX_TOGGLE,
        state: state,
    };
};

export const sideBarZidxToggle = (state) => {
    return {
        type: actionTypes.SIDE_BAR_Z_IDX_TOGGLE,
        state: state,
    };
};

export const invListZidxToggle = (state) => {
    return {
        type: actionTypes.INV_LIST_Z_IDX_TOGGLE,
        state: state,
    };
};

export const printMapZidxToggle = (state) => {
    return {
        type: actionTypes.PRINT_MAP_Z_IDX_TOGGLE,
        state: state,
    };
};

export const findPosZidxToggle = (state) => {
    return {
        type: actionTypes.FIND_POS_Z_IDX_TOGGLE,
        state: state,
    };
};

export const searchBtnZidxToggle = (state) => {
    return {
        type: actionTypes.SEARCH_BTN_Z_IDX_TOGGLE,
        state: state,
    };
};

// Color toggle actions
export const onboardingBtnColorToggle = (state) => {
    return {
        type: actionTypes.ONBOARDING_BTN_COLOR_TOGGLE,
        state: state,
    };
};

export const mapLayerBtnColorToggle = (state) => {
    return {
        type: actionTypes.MAP_LAYER_BTN_COLOR_TOGGLE,
        state: state,
    };
};

export const drawBtnColorToggle = (state) => {
    return {
        type: actionTypes.DRAW_BTN_COLOR_TOGGLE,
        state: state,
    };
};

export const measBtnColorToggle = (state) => {
    return {
        type: actionTypes.MEAS_BTN_COLOR_TOGGLE,
        state: state,
    };
};

export const customerSelectBtnColorToggle = (state) => {
    return {
        type: actionTypes.CUSTOMER_SELECT_BTN_COLOR_TOGGLE,
        state: state,
    };
};

export const mapSelectBtnColorToggle = (state) => {
    return {
        type: actionTypes.MAP_SELECT_BTN_COLOR_TOGGLE,
        state: state,
    };
};

export const findPosBtnColorToggle = (state) => {
    return {
        type: actionTypes.FIND_POS_BTN_COLOR_TOGGLE,
        state: state,
    };
};

export const newNoteBtnColorToggle = (state) => {
    return {
        type: actionTypes.NEW_NOTE_BTN_COLOR_TOGGLE,
        state: state,
    };
};

// Disable buttons
export const setDisableMapLayer = (state) => (dispatch) => {
    if (state) {
        dispatch(mapLayerBtnColorToggle("back"));
    } else {
        dispatch(mapLayerBtnColorToggle("front"));
    }
};

export const setDisableDraw = (state) => (dispatch) => {
    if (state) {
        dispatch(drawBtnColorToggle("back"));
    } else {
        dispatch(drawBtnColorToggle("front"));
    }
};

export const setDisableMeas = (state) => (dispatch) => {
    if (state) {
        dispatch(measBtnColorToggle("back"));
    } else {
        dispatch(measBtnColorToggle("front"));
    }
};

export const setDisableCustomerSelect = (state) => (dispatch) => {
    if (state) {
        dispatch(customerSelectBtnColorToggle("back"));
    } else {
        dispatch(customerSelectBtnColorToggle("front"));
    }
};

export const setDisableMapSelect = (state) => (dispatch) => {
    if (state) {
        dispatch(mapSelectBtnColorToggle("back"));
    } else {
        dispatch(mapSelectBtnColorToggle("front"));
    }
};

export const setDisableFindPos = (state) => (dispatch) => {
    if (state) {
        dispatch(findPosBtnColorToggle("back"));
    } else {
        dispatch(findPosBtnColorToggle("front"));
    }
};

export const setDisableNewNote = (state) => (dispatch) => {
    if (state) {
        dispatch(newNoteBtnColorToggle("back"));
    } else {
        dispatch(newNoteBtnColorToggle("front"));
    }
};


// Sidebar widgets activ
export const setCustomerWidgetActive = (state) => {
    return {
        type: actionTypes.SET_CUSTOMER_WIDGET_ACTIVE,
        state: state,
    };
};

export const setNotesWidgetActive = (state) => {
    return {
        type: actionTypes.SET_NOTES_WIDGET_ACTIVE,
        state: state,
    };
};

export const setWeatherWidgetActive = (state) => {
    return {
        type: actionTypes.SET_WEATHER_WIDGET_ACTIVE,
        state: state,
    };
};

export const setNewsWidgetActive = (state) => {
    return {
        type: actionTypes.SET_NEWS_WIDGET_ACTIVE,
        state: state,
    };
};
