import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';

// Components
import MicrosoftButton from '../../components/UI/Buttons/MicrosoftButton';
import ButtonWithSpinner from '../../components/UI/Buttons/ButtonWithSpinner';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: '0px 0px',
        width: '100%'
    },
    formHelperText: {
        marginBottom: 22
    },
    divider: {
        backgroundColor: theme.palette.divider,
        height: 1,
        flexGrow: 1,
    },
    link: {
        fontSize: 16,
    },
}));

export default function SignInWindow(props) {
    const classes = useStyles();
    return (
        <div style={{ backgroundColor: 'white', padding: 28, borderRadius: 21, width: '100%' }}>
            <div className={classes.root}>
                <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                    error={props.loginError }
                >
                    <TextField
                        required
                        id="loginEmail"
                        label="E-mail"
                        type="email"
                        onChange={props.handleTextInput}
                        value={props.emailValue}
                        variant="outlined"
                        style={{ marginBottom: 22 }}
                    />

                    <TextField
                        required
                        id="loginPassword"
                        label="Adgangskode"
                        type="password"
                        autoComplete="current-password"
                        onChange={props.handleTextInput}
                        value={props.passwordValue}
                        variant="outlined"
                        style={{ marginBottom: (props.loginError && !props.loginThirdPartyError && props.loginErrorText !== "") ? 0 : 22 }}
                        fullWidth
                    />

                    {(props.loginError && !props.loginThirdPartyError && props.loginErrorText !== "") && <FormHelperText style={{ marginBottom: 22 }}>{props.loginErrorText}</FormHelperText>}

                    <ButtonWithSpinner
                        onClick={props.handleLogin}
                        text='Login'
                        loading={props.loading}
                        color='primary'
                        variant='contained'
                        fullWidth={true}
                        disabled={props.buttonDisabled}
                    />

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 22 }}>
                        <Link href="/glemt-adgangskode" underline='always' className={classes.link}>
                            Klik her, hvis du har glemt din adgangskode
                        </Link>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', margin: '24px 0px' }}>
                        <div className={classes.divider} />

                        <Typography style={{ margin: '0px 8px' }}>eller</Typography>

                        <div className={classes.divider} />
                    </div>

                    <MicrosoftButton
                        text='Login med Microsoft'
                        onClick={props.handleThirdPartyLogin}
                        loading={props.loadingThirdParty}
                        disabled={props.loadingThirdParty}
                    />
                    {props.loginThirdPartyError && <FormHelperText style={{ marginTop: 5 }}>{props.loginErrorText}</FormHelperText>}
                </FormControl>
            </div>
        </div>
    );
}